import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional
} from "$Imports/Imports";

import {
  SchemaOf
} from "$Shared/imports/Yup";

import yup from "$Shared/utilities/yupExtension";

import {
  CarrierApiFactory,
  Vendor,
  QuoteCarrier,
  QuoteCarrierCarrierStatusEnum,
  QuoteCarrierFlattened,
  CarrierMetrics,
  CarrierMetricsParameters,
  QuoteCarrierSaveParams
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

export const QuoteCarrierValidationSchema: SchemaOf<NullableOptional<QuoteCarrier>> = yup.object({
  id: yup.number().notRequired(),
  carrierStatus: yup.mixed<QuoteCarrierCarrierStatusEnum>()
    .oneOf(["Available", "Assigned", "OnHold", "Picked", "Delivered"])
    .required("Carrier Status is required"),
  vendorId: yup.number()
    .transform((value: any) => value || undefined)
    .when('carrierStatus', {
      is: (val: QuoteCarrierCarrierStatusEnum | null | undefined) => _.includes(["Assigned", "Picked", "Delivered"], val),
      then: (schema) => schema.required("Carrier is required"),
      otherwise: (schema) => schema.notRequired()
    }),
  carrierRate: yup.number()
    .transform((value: any) => value || undefined)
    .min(0).max(2000000000, "Carrier rate cannot exceed $2 billion")
    .when('carrierStatus', {
      is: (val: QuoteCarrierCarrierStatusEnum | null | undefined) => _.includes(["Assigned", "Picked", "Delivered"], val),
      then: yup.number().required("Carrier Rate is required"),
      otherwise: yup.number().notRequired()
    }),
  tripNumber: yup.number()
    .transform((value: any) => value || undefined)
    .min(0).max(2000000000, "Trip number cannot exceed 2 billion.")
    .notRequired().nullable(),
  carrierRepId: yup.number()
    .transform((value: any) => value || undefined)
    .when('carrierStatus', {
      is: (val: QuoteCarrierCarrierStatusEnum | null | undefined) => _.includes(["Assigned", "Picked", "Delivered"], val),
      then: yup.number().required("Carrier Rep is required"),
      otherwise: yup.number().notRequired()
    }),
  vendor: yup.mixed().notRequired().nullable(),
  quote: yup.mixed().notRequired().nullable(),
  carrierRep: yup.mixed().notRequired().nullable()
});

interface ICarrierServiceState {
  vendorFetchResults: IAjaxState<Vendor[]>;
  quoteCarrierFetchResults: IAjaxState<QuoteCarrierFlattened[]>;
  saveQuoteCarrierFetchResults: IAjaxState<QuoteCarrier>;
  carrierMetricsFetchResults: IAjaxState<CarrierMetrics>;
}

const InjectedPropName = "carrierService";

const initialState = {
  vendorFetchResults: managedAjaxUtil.createInitialState(),
  quoteCarrierFetchResults: managedAjaxUtil.createInitialState(),
  saveQuoteCarrierFetchResults: managedAjaxUtil.createInitialState(),
  carrierMetricsFetchResults: managedAjaxUtil.createInitialState()
} as ICarrierServiceState;

class CarrierFreezerService extends FreezerService<ICarrierServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchVendors() {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "vendorFetchResults",
      params: {
        includeInactive: false
      },
      onExecute: (apiOptions, params) => {
        const factory = CarrierApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getVendors(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch vendors");
      }
    });
  }

  public fetchCarrierInfo() {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quoteCarrierFetchResults",
      params: {},
      onExecute: (apiOptions, params) => {
        const factory = CarrierApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getCarrierAssignments(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch carrier information");
      }
    });
  }

  public fetchCarrierMetrics(filters: CarrierMetricsParameters) {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "carrierMetricsFetchResults",
      params: {
        body: filters
      },
      onExecute: (apiOptions, params) => {
        const factory = CarrierApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getCarrierMetrics(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch carrier metrics");
      }
    });
  }

  public saveCarrierInfo(carrierParams: QuoteCarrierSaveParams) {
    // QuoteCarrierFlattened and QuoteCarrier have overlapping fields so you can just
    // pass the flattened viewmodel in and it'll be treated as a QuoteCarrier and
    // save just the editable fields on the QuoteCarrier table
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "saveQuoteCarrierFetchResults",
      params: {
        body: carrierParams
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CarrierApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.saveCarrierInfo(params);
      },
      onOk: () => {
        this.fetchCarrierInfo();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save carrier information.");
      }
    });
  }
}

export const CarrierService = new CarrierFreezerService();
export type ICarrierServiceInjectedProps = ReturnType<CarrierFreezerService["getPropsForInjection"]>;
