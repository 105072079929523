import {
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  AccessorialCharge
} from "$Generated/api";

import {
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControlLabel,
  Switch
} from "$Imports/MaterialUIComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  IAccessorialChargeServiceInjectedProps,
  AccessorialChargeService,
  EMPTY_ACCESSORIAL_CHARGE
} from "$State/AccessorialChargeFreezerService";

import {
  AccessorialChargeValidationSchema
} from "$State/AccessorialChargeValidationSchema";

const styles: {
  modalContainer: string;
} = require("$Shared/styles/Modal.scss");

interface IEditAccessorialChargeBaseProps {
  isOpen: boolean;
  filterActive: boolean;
  accessorialCharge: AccessorialCharge;
  onClose: (value?: AccessorialCharge) => void;
}

interface IOwnState {
  accessorialCharge: AccessorialCharge;
  validationErrors: ValidationError | null;
  disableSave: boolean;
}

type IEditAccessorialChargeProps = IEditAccessorialChargeBaseProps &
IAccessorialChargeServiceInjectedProps;

class _EditAccessorialChargeModal extends React.PureComponent<IEditAccessorialChargeProps, IOwnState> {
  state: IOwnState = {
    accessorialCharge: { ...EMPTY_ACCESSORIAL_CHARGE, isActive: true },
    validationErrors: null,
    disableSave: false
  };

  @bind
  private async _onClose(shouldSave: boolean): Promise<void> {
    if(!shouldSave) {
      this.props.onClose();
      return;
    }
    
    const accessorialChargeErrors = await validateSchema(AccessorialChargeValidationSchema, this.state.accessorialCharge);
    this.setState({ validationErrors: accessorialChargeErrors });

    if (accessorialChargeErrors) {
      return;
    }

    this.setState({
      disableSave: true
    });

    const { accessorialCharge } = this.state;
    
    try {
      await AccessorialChargeService.saveAccessorialCharge(accessorialCharge);
      this.props.accessorialChargeService.fetchAccessorialCharges(this.props.filterActive, true);
      this.props.onClose(accessorialCharge);
    } catch (ex) {
      if (ex) {
        this.setState({
          disableSave: false
        });
      }
    }
  }

  componentDidUpdate(prev: IEditAccessorialChargeBaseProps) {
    if(prev.isOpen !== this.props.isOpen) {
      this.setState({
        disableSave: false
      });
    }

    if (this.props.accessorialCharge !== prev.accessorialCharge) {
      this.setState({
        accessorialCharge: {
          ...this.props.accessorialCharge,
        },
        validationErrors: null
      });
    }
  }

  @bind
  private _onActiveChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.setState((prev) => ({
      accessorialCharge: {
        ...prev.accessorialCharge,
        isActive: checked
      }
    }));
  }

  @bind
  private _onChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState((prev) => ({
      accessorialCharge: {
        ...prev.accessorialCharge,
        [event.target.name]: event.target.value
      }
    }));
  }

  render() {
    const {
      isOpen
    } = this.props;

    const {
      accessorialCharge,
      disableSave,
      validationErrors
    } = this.state;

    const validationsParser = new ValidationErrorParser<AccessorialCharge>(validationErrors);

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
          {accessorialCharge.id ? "Edit Accessorial Charge" : "Add Accessorial Charge"}
        </DialogTitle>
        <DialogContent
          className={styles.modalContainer}
        >
          <FormControlLabel
            control={
              (
                <Switch
                  color="primary"
                  checked={accessorialCharge.isActive ?? false}
                  onChange={this._onActiveChange}
                />
              )
            }
            label="Active"
          />
          <AdvanceTextField
            label="Description"
            name="description"
            value={accessorialCharge.description ?? ""}
            onChange={this._onChange}
            inputProps={{ maxLength: 40 }}
            error={!validationsParser.isValid("description")}
            helperText={validationsParser.validationMessage("description")}
          />
          <AdvanceTextField
            label="TruckMate Charge Code"
            name="tmChargeCode"
            value={accessorialCharge.tmChargeCode ?? ""}
            onChange={this._onChange}
            error={!validationsParser.isValid("tmChargeCode")}
            helperText={validationsParser.validationMessage("tmChargeCode")}
            inputProps={{ maxLength: 10 }}
            style={{ marginTop: "4px", width: "250px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this._onClose(false)}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => this._onClose(true)}
            disabled={disableSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export const EditAccessorialChargeModal = AccessorialChargeService.inject(_EditAccessorialChargeModal);