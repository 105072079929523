import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  CommodityApiFactory,
  Commodity
} from "$Generated/api";

import {
  directionType
} from "$Imports/CommonComponents";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

export type formModeType = "add" | "edit" | "none";

interface ICommodityState {
  formMode: formModeType;
  commodityFetchResults: IAjaxState<Commodity[]>;
  commodityDeleteResults: IAjaxState<boolean>;
  commodityAddResults: IAjaxState<Commodity>;
  commodityUpdateResults: IAjaxState<Commodity>;
  editAddCommodity: Commodity | null;
  editAddValidationErrors: ValidationError | null;
  activeCommodities: Commodity[];
  sortState: ISortState;
  filterActive: boolean;
}

interface ISortState {
  sortColumnName?: string;
  sortDirection?: directionType;
}

const InjectedPropName = "commodityService";

// AddEditCommodity
const CommodityValidationSchema: SchemaOf<NullableOptional<Commodity>> = yup.object({
  id: yup.number().notRequired(),
  commodityName: yup.string().required("Commodity Name is required.").max(50, "Commodity Name cannot be longer than 50 characters."),
  commodityDescription: yup.string().nullable().notRequired(),
  isActive: yup.boolean().required(),
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  tmCommodityId: yup.string().required("TM Commodity Id is required.").max(20, "TM Commodity ID cannot be longer than 20 characters."),
  commodityShortName: yup.string().notRequired().max(50, "Commodity Short Name can not be more than 50 characters.").nullable(true),
  isStackable: yup.boolean().notRequired(),
  isSideBySide: yup.boolean().notRequired(),
  commodityValues: yup.array().notRequired(),
  quoteFreights: yup.array().notRequired(),
  rateModelTests: yup.array().notRequired(),
  commodityQuestions: yup.array().notRequired(),
  commodityExclusions: yup.array().notRequired()
});

const initialState = {
  formMode: "none",
  editAddCommodity: null,
  editAddValidationErrors: null,
  commodityDeleteResults: managedAjaxUtil.createInitialState(),
  commodityFetchResults: managedAjaxUtil.createInitialState(),
  commodityAddResults: managedAjaxUtil.createInitialState(),
  commodityUpdateResults: managedAjaxUtil.createInitialState(),
  activeCommodities: [],
  sortState: {
    sortColumnName: "commodity-name",
    sortDirection: "asc",
  },
  filterActive: true
} as ICommodityState;

class CommodityFreezerService extends FreezerService<ICommodityState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public addCommodity() {
    this.freezer.get().set({
      editAddCommodity: {
        commodityName: "",
        isActive: true
      },
      formMode: "add"
    });
  }

  public setFilterActive(filterActive: boolean) {
    this.freezer.get().set({ filterActive });
  }

  public updateCommodity(task: Partial<Commodity>) {
    this.freezer.get().editAddCommodity?.set(task);
  }

  public editCommodity(id: number | undefined) {
    var foundTask = _.find(this.freezer.get().commodityFetchResults.data, (d) => d.id === id);

    if (foundTask) {
      this.freezer.get().set({
        editAddCommodity: foundTask.toJS(),
        formMode: "edit",
      });
    }
  }

  public clearEditAddForm() {
    this.freezer.get().set({
      editAddCommodity: null,
      editAddValidationErrors: null,
      formMode: "none"
    });
  }

  public async saveCommodity(companyId: number | undefined) {
    const editAddCommodity = this.freezer.get().editAddCommodity?.toJS();
    const errors = await validateSchema(CommodityValidationSchema, editAddCommodity);
    this.freezer.get().set({ editAddValidationErrors: errors });

    if (!companyId || errors) {
      return;
    }

    if (this.freezer.get().formMode === "add") {
      this.saveAddCommodity(companyId);
    }

    if (this.freezer.get().formMode === "edit") {
      this.saveUpdateCommodity(companyId);
    }
  }

  public setSortState(sortState: Partial<ISortState>) {
    this.freezer.get().sortState.set(sortState);
  }

  private saveUpdateCommodity(companyId: number) {
    const editAddCommodity = this.freezer.get().editAddCommodity?.toJS();

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "commodityUpdateResults",
      onExecute: (apiOptions, param, options) => {
        const factory = CommodityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CommodityPut(param);
      },
      params: {
        companyId: companyId,
        body: editAddCommodity
      },
      onOk: (data) => {
        this.clearEditAddForm();
        this.fetchCommodities(companyId, true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? `Failed to update commodity`);
      }
    });
  }

  public fetchCommodities(companyId: number | undefined, forceUpdate: boolean = false) {
    var fetchResults = this.freezer.get().commodityFetchResults;

    if ((fetchResults.hasFetched && !forceUpdate) || !companyId) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "commodityFetchResults",
      onExecute: (apiOptions, param, options) => {
        const factory = CommodityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CommodityItemsCompanyIdActiveOnlyGet({
          companyId: companyId,
          activeOnly: false
        });
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch commodities.");
      },
      onOk: (data: Commodity[]) => {
        var activeCommodities = data.filter(c => c.isActive);
        activeCommodities = _.sortBy(activeCommodities, c => c.commodityName?.toLowerCase());
        this.freezer.get().set({ activeCommodities });
      }
    });
  }

  private saveAddCommodity(companyId: number) {
    const editAddCommodity = this.freezer.get().editAddCommodity?.toJS();

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "commodityUpdateResults",
      onExecute: (apiOptions, param, options) => {
        const factory = CommodityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CommodityPost(param);
      },
      params: {
        companyId: companyId,
        body: editAddCommodity
      },
      onOk: (data) => {
        this.clearEditAddForm();
        this.fetchCommodities(companyId, true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? `Failed to add commodity`);
      }
    });
  }
}

export const CommodityService = new CommodityFreezerService();
export type ICommodityServiceInjectedProps = ReturnType<CommodityFreezerService["getPropsForInjection"]>;
