import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  CardLinedHeader,
  CompanyIcon,
  CreateReminderButton,
  DisplayFormattedNumber,
  DueDateField,
  OpportunityEditModal,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Button,
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  Edit
} from "$Imports/MaterialUIIcons";

import {
  Company,
  Customer,
  EntityLink,
  Opportunity,
  OpportunitySearchCriteria,
  Reminder
} from "$Generated/api";

import {
  CompanySelectService,
  ICompanySelectServiceInjectedProps
} from "$State/CompanySelectFreezerService";

import {
  IEmployeeServiceInjectedProps,
  EmployeeService
} from "$State/EmployeeFreezerService";

import {
  EMPTY_OPPORTUNITY,
  EMPTY_OPPORTUNITY_SEARCH,
  IOpportunityServiceInjectedProps,
  OpportunityService
} from "$State/OpportunityFreezerService";

import {
  ICustomerDetailServiceInjectedProps,
  CustomerDetailService
} from "$State/CustomerDetailFreezerService";

import {
  EMPTY_REMINDER
} from "$State/ReminderFreezerService";

import {
  CURRENCY_NO_DECIMAL_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  OpportunitySearchForm
} from "./OpportunitySearchForm";

interface IOwnProps {
  customer: Customer;
  onCreateReminder: (seed: Reminder) => void;
}

type OwnProps = IOwnProps
  & IOpportunityServiceInjectedProps
  & ICompanySelectServiceInjectedProps
  & IEmployeeServiceInjectedProps
  & ICustomerDetailServiceInjectedProps;

interface IOwnState {
  isEditModalOpen: boolean;
  salesReps: EntityLink[];
  searchCriteria: OpportunitySearchCriteria;
  selectedOpportunity?: Opportunity;
}

const styles: {
  opportunityRow: string;
  actions: string;
} = require("./OpportunitiesCard.scss");

class _OpportunitiesCard extends React.Component<OwnProps, IOwnState> {
  state: IOwnState = {
    isEditModalOpen: false,
    searchCriteria: { ...EMPTY_OPPORTUNITY_SEARCH },
    salesReps: []
  };

  private readonly _columns: GridColDef[] = [{
    headerName: "",
    field: "company",
    renderCell: (params: GridRenderCellParams<Company>) =>
      params.value && <CompanyIcon companyKey={params.value.companyKey ?? ""} />,
    width: 50,
    disableColumnMenu: true
  }, {
    headerName: "Opp. ID",
    field: "opportunityId",
    width: 80,
    filterable: false
  }, {
    headerName: "Sales Rep",
    field: "createdBy",
    valueGetter: (params: GridValueGetterParams<EntityLink>) => params.value?.linkName,
    flex: 1,
    filterable: false
  }, {
    headerName: "Opportunity Description",
    field: "description",
    flex: 2,
    filterable: false
  }, {
    headerName: "Status",
    field: "status",
    width: 100,
    filterable: false
  }, {
    headerName: "Confidence %",
    field: "confidence",
    valueFormatter: (params: GridValueFormatterParams) => `${params.value}%`,
    width: 130,
    filterable: false
  }, {
    headerName: "Target Revenue",
    field: "targetRevenue",
    renderCell: (params: GridRenderCellParams<number | undefined>) => <DisplayFormattedNumber value={params.value} emptyDisplay="-" formatString={CURRENCY_NO_DECIMAL_FORMAT} />,
    width: 150,
    filterable: false
  }, {
    headerName: "Close Date",
    field: "closeDate",
    renderCell: (params: GridRenderCellParams<Date | undefined>) => <DueDateField value={params.value} />,
    width: 120,
    filterable: false
  }, {
    headerName: "",
    field: "actions",
    renderCell: (params: GridRenderCellParams<any, Opportunity>) => (
      <div className={styles.actions}>
        <IconButton
          onClick={() => this._editOpportunity(params.row)}
          size="small"
          title="Edit opportunity"
        >
          <Edit />
        </IconButton>
      </div>
    ),
    width: 50,
    disableColumnMenu: true,
    sortable: false
  }];

  componentDidMount() {
    this.props.employeeService.fetchSalesReps();
  }

  componentDidUpdate(prev: OwnProps) {
    if (this.props.customer.id !== prev.customer.id) {
      this.setState({
        salesReps: []
      });
      this._fetchOpportunities(EMPTY_OPPORTUNITY_SEARCH);
    }
  }

  @bind
  private async _fetchOpportunities(searchCriteria: OpportunitySearchCriteria) {
    if (!this.props.customer) {
      return;
    }

    this.setState({
      searchCriteria: searchCriteria
    });

    await this.props.opportunityService.fetchOpportunities({
      ...searchCriteria,
      customerId: this.props.customer.id
    });

    // populate available sales reps from first result set
    if (!this.state.salesReps.length) {
      const { fetchResult } = this.props.opportunityService.getState();

      // would prefer ...Map(), but our TS target < ES2015 prevents using it
      const salesReps = (
        fetchResult.data?.filter(
          (x, idx, arr) => arr.findIndex(orig => orig.createdBy?.linkId === x.createdBy?.linkId) === idx
        ) ?? []
      ).map(x => x.createdBy!);

      this.setState({
        salesReps: salesReps
      });
    }
  }

  @bind
  private _addOpportunity() {
    const {
      assignedCompanies,
      companyContext
    } = this.props.companySelectService.getState();

    const { salesRepFetchResults } = this.props.employeeService.getState();

    const selfEmployee = _.find(
      salesRepFetchResults.data ?? [],
      x => x.userId === SharedSecurityContext.getUserId()
    );

    const company = assignedCompanies.find(x => x.id === companyContext.companyId);

    this.setState({
      selectedOpportunity: {
        ...EMPTY_OPPORTUNITY,
        customer: { ...this.props.customer },
        company: { ...company },
        createdBy: {
          linkId: selfEmployee?.id ?? 0
        }
      },
      isEditModalOpen: true
    });
  }

  @bind
  private _editOpportunity(model: Opportunity) {
    this.setState({
      selectedOpportunity: model,
      isEditModalOpen: true
    });
  }

  @bind
  private async _saveOpportunity(model?: Opportunity) {
    if (model) {
      if (model.id) {
        await this.props.opportunityService.updateOpportunity(model.id, model);
      }
      else {
        await this.props.opportunityService.createOpportunity(model);
      }

      this._fetchOpportunities(this.state.searchCriteria);
      if (this.props.customer.id) {
        this.props.customerDetailService.fetchAuditLogs(this.props.customer.id);
      }
    }

    this.setState({
      selectedOpportunity: {
        ...EMPTY_OPPORTUNITY
      },
      isEditModalOpen: false
    });
  }

  render() {
    const {
      isEditModalOpen,
      selectedOpportunity,
      salesReps
    } = this.state;

    const {
      fetchResult
    } = this.props.opportunityService.getState();

    const opportunities = fetchResult?.data ?? [];

    return (
      <CardLinedHeader
        titleText="Sales Opportunities"
        titleComponents={(
          <div style={{ marginBottom: "5px" }}>
            <UserAccessControl roles={["opportunity:create", "opportunity:edit"]}>
              <Button onClick={this._addOpportunity}>
                Add
              </Button>
            </UserAccessControl>
            <UserAccessControl roles={["reminder:create"]}>
              <CreateReminderButton
                seed={{
                  ...EMPTY_REMINDER,
                  type: "Sales"
                }}
                onClick={this.props.onCreateReminder}
              />
            </UserAccessControl>
          </div>
        )}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <OpportunitySearchForm
          salesReps={salesReps ?? []}
          onSubmit={this._fetchOpportunities}
        />

        <DataGridPro
          style={{ flex: "1 1 11.75rem", marginTop: "0.5rem" }}
          rows={opportunities}
          columns={this._columns}
          density="compact"
          initialState={{
            sorting: {
              sortModel: [{ field: "closeDate", sort: "asc" }]
            }
          }}
          getRowClassName={() => styles.opportunityRow}
          disableSelectionOnClick
          hideFooter
        />

        <OpportunityEditModal
          isOpen={isEditModalOpen}
          opportunity={selectedOpportunity}
          onClose={this._saveOpportunity}
        />

      </CardLinedHeader>
    );
  }
}

export const OpportunitiesCard = OpportunityService.inject(
  CompanySelectService.inject(
    EmployeeService.inject(
      CustomerDetailService.inject(
        _OpportunitiesCard
      )
    )
  )
);
