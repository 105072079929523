import {
  React,
  bind,
  moment
} from "$Imports/Imports";

import {
  AdvanceTextField,
  DateRangePicker,
  SearchControlsContainer
} from "$Imports/CommonComponents";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "$Imports/MaterialUIComponents";

import {
  QuoteQuoteTypeEnum,
  QuoteSearchCriteria,
  QuoteSearchCriteriaStatusesEnum
} from "$Generated/api";

import {
  IQuoteServiceInjectedProps,
  QuoteService
} from "$State/QuoteFreezerService";

import {
  IEmployeeServiceInjectedProps,
  EmployeeService
} from "$State/EmployeeFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

interface IOwnProps {
  companyId?: number;
  quoteType: QuoteQuoteTypeEnum;
}

type OwnProps = IOwnProps
  & IQuoteServiceInjectedProps
  & IEmployeeServiceInjectedProps;

const styles: {
  container: string;
} = require("./QuoteSearchForm.scss");

class _QuoteSearchForm extends React.Component<OwnProps, {}> {
  @bind
  private _onQuoteFreightNumChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.quoteService.onSearchModelChanged({ quoteOrFreightNumber: e.target.value });
  }

  @bind
  private _onSalegetterFilterChange(event: SelectChangeEvent<string>) {
    this.props.quoteService.onSearchModelChanged({ userId: event.target.value });
  }

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    this.props.quoteService.onSearchModelChanged({
      startDate: start ? moment(start).startOf('day').toDate() : undefined,
      endDate: end ? moment(end).endOf('day').toDate() : undefined
    });
  }

  @bind
  private _onStatusChange(e: SelectChangeEvent) {
    if (e.target.value === "") {
      this.props.quoteService.onSearchModelChanged({ statuses: [] });
    } else {
      this.props.quoteService.onSearchModelChanged({ statuses: [e.target.value as QuoteSearchCriteriaStatusesEnum] });
    }
  }

  @bind
  private _onFieldKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      this._onSubmit();
    }
  }

  @bind
  private _onSubmit() {
    this.props.quoteService.fetchQuotes(this.props.companyId, true, this.props.quoteType);
  }

  @bind
  private _onClear() {
    this.props.quoteService.onResetSearchModel();
    this._onSubmit();
  }

  render() {
    const {quoteType} = this.props;
    const {
      quotesFetchResults,
      searchCriteria,
      searchValidationErrors
    } = this.props.quoteService.getState();

    const {
      salesRepFetchResults,
      activeSalesReps
    } = this.props.employeeService.getState();

    const isFullQuote = quoteType === "Full";

    const salesReps = activeSalesReps ?? [];

    const validationsParser = new ValidationErrorParser<QuoteSearchCriteria>(searchValidationErrors);

    return (
      <SearchControlsContainer
        className={styles.container}
        onSubmit={this._onSubmit}
        onClear={this._onClear}
        disabled={quotesFetchResults.isFetching || salesRepFetchResults.isFetching}
      >
        <div style={{ flex: "0 1 8rem" }}>
          <AdvanceTextField
            label={isFullQuote ? "Quote or FB#" : "Quote #"}
            onChange={this._onQuoteFreightNumChange}
            value={searchCriteria.quoteOrFreightNumber ?? ""}
            error={!validationsParser.isValid("quoteOrFreightNumber")}
            helperText={validationsParser.validationMessage("quoteOrFreightNumber")}
            onKeyDown={this._onFieldKeyDown}
          />
        </div>

        <FormControl style={{ flex: "0 1 8rem" }}>
          <InputLabel shrink>Sales Representative</InputLabel>
          <Select
            value={searchCriteria.userId ?? ""}
            onChange={this._onSalegetterFilterChange}
            displayEmpty
          >
            <MenuItem value={""}>
              All
            </MenuItem>
            {salesReps.map((e, idx) =>
              <MenuItem value={e.userId} key={idx}>
                {`${e.firstName} ${e.lastName}`}
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <div style={{ display: "inline-flex", flex: "0 0 17rem", gap: "0.5rem" }}>
          <DateRangePicker
            startDate={searchCriteria.startDate}
            startError={validationsParser.validationMessage("startDate")}
            endDate={searchCriteria.endDate}
            endError={validationsParser.validationMessage("endDate")}
            onChange={this._onDateRangeChange}
          />
        </div>

        <FormControl style={{ flex: "0 1 12rem" }}>
          <InputLabel shrink>Status</InputLabel>
          <Select
            value={searchCriteria.statuses?.length ? searchCriteria.statuses[0] : ""}
            onChange={this._onStatusChange}
            displayEmpty
          >
            <MenuItem value="">All</MenuItem>
            {isFullQuote && <MenuItem value="AcceptanceRejected">Acceptance Rejected</MenuItem>}
            {isFullQuote && <MenuItem value="Accepted">Accepted</MenuItem>}
            {isFullQuote && <MenuItem value="ApprovalNeeded">Approval Needed</MenuItem>}
            {isFullQuote && <MenuItem value="Canceled">Canceled</MenuItem>}
            {!isFullQuote && <MenuItem value="Accepted">Converted</MenuItem>}
            <MenuItem value="Declined">Declined</MenuItem>
            {isFullQuote && <MenuItem value="Expired">Expired</MenuItem>}
            {isFullQuote && <MenuItem value="InProgress">In Progress</MenuItem>}
            <MenuItem value="Pending">Pending</MenuItem>
          </Select>
        </FormControl>
      </SearchControlsContainer>
    );
  }
}

export const QuoteSearchForm = QuoteService.inject(
  EmployeeService.inject(
    _QuoteSearchForm
  )
);
