import "./global.types";

import { 
  wrapFetchWithAuth, 
  setupBaseUrl 
} from "./startup";

import { 
  Application 
} from "./Application";

import {
  ReactDOM,
  React,
  AuthProvider,
  useState,
  useEffect  
} from "$Imports/Imports";

import {  
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";


const nonWrappedFetch = fetch;
const wrappedFetch = wrapFetchWithAuth();
const baseUrl = setupBaseUrl(wrappedFetch);

declare var __webpack_public_path__: string;
__webpack_public_path__ = baseUrl;

const el = document.getElementById("reactApp");

interface IAppAuthClientSettingsState {
  realm: string;
  clientId: string;
  url: string;
}

interface IAppAuthRootProps {
  baseUrl: string;
}

const AppAuthRoot: React.FunctionComponent<IAppAuthRootProps> = (props) => {
  const [authClientSettings, setAuthClientSettings] = useState<IAppAuthClientSettingsState>();

  useEffect(() => {
    const getConfiguration = async() =>
    {
        const data = await nonWrappedFetch(baseUrl + "api/v1/keycloak", {      
          method: "GET",
        })

        const stateData = (await data.json()) as IAppAuthClientSettingsState;

        // Store the Keycloak settings for future token parsing.
        SharedSecurityContext.setAuthSettings({
          authority: stateData.realm,
          client_id: stateData.clientId,
          url: stateData.url,
          baseUrl: baseUrl
        })
        setAuthClientSettings(stateData);
    };

    getConfiguration()
      .catch((e) => console.log(e));
  }, []); 
  
  return authClientSettings !== undefined ? (
    <AuthProvider
      userManager={SharedSecurityContext.getUserManager()}
    >
      {props.children}
    </AuthProvider>
  ) : <></>;
}

ReactDOM.render(
  <React.StrictMode>
    <AppAuthRoot
      baseUrl={baseUrl}
    >
      <Application />
    </AppAuthRoot>
  </React.StrictMode>, el
)

// When no module name is provided to module.hot.accept(), it assumes the current
// module:
//   https://github.com/webpack/webpack/blob/7871b6b3dc32425406b3938be490a87aeb6fa794/lib/HotModuleReplacement.runtime.js#L89-L99
declare var module: any;

if (module.hot) {
  module.hot.accept();
}
