import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  Question,
  Quote,
  QuoteStopFreightQuestionAnswerEnum
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button
} from "$Imports/MaterialUIComponents";

import {
  ResponseType,
  Response,
  QuestionType,
} from "$State/QuoteEntryFreezerService";

import {
  MappedUpchargeReasonTextFields
} from "./MappedUpchargeReasonTextFields";

import {
  OtherUpchargeReasonTextField
} from "./OtherUpchargeReasonTextField";

import {
  PercentUpchargeResponseSchema, 
  FlatUpchargeOtherReasonSchema,
  FlatUpchargeResponsesSchema
} from "$State/QuoteEntryValidationSchema";

import {
  PercentUpchargeReasonTextField
} from "./PercentUpchargeReasonTextField";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

const styles: {
  gridWrapper: string,
  questionText: string,
  flatResponsesError: string,
} = require("../QuoteStopEntryComponents/QuoteStopEntry.scss");

const modalStyles: {
  modalContainer: string
} = require("$Shared/styles/Modal.scss");

interface IQuestionModalProps {
  questions: Question[];
  answers: Response[];
  isOpen: boolean;
  viewOnly: boolean;
  quote: Quote;
  questionType: QuestionType | null;
  setAnswer: (questionId: number, answer: QuoteStopFreightQuestionAnswerEnum) => void;
  setOtherAnswer: (answer: ResponseType) => void;
  setReason: (reason: string) => void;
  handleDone: () => void;
}

interface IQuestionModalState {
  percentUpchargeValidationErrors: ValidationError | null;
  flatReasonValidationErrors: ValidationError | null;
  flatResponsesValidationErrors: ValidationError | null;
}

export class QuestionModal extends React.PureComponent<IQuestionModalProps, IQuestionModalState> {
  state: IQuestionModalState = {
    percentUpchargeValidationErrors: null,
    flatReasonValidationErrors: null,
    flatResponsesValidationErrors: null
  };

  @bind
  setAnswer(questionId: string, answer: string) {
    this.props.setAnswer(parseInt(questionId), answer as QuoteStopFreightQuestionAnswerEnum);
  }

  @bind
  setReason(reason: string) {
    this.props.setReason(reason);
  }

  @bind
  setOtherAnswer(value: ResponseType) {
    this.props.setOtherAnswer(value);
  }

  @bind
  private async _onDone() {
    const {
      quote,
      questionType,
      answers,
      viewOnly
    } = this.props;

    if (viewOnly) {
      this.props.handleDone();
      return;
    }

    switch (questionType) {
      case "Flat $ Upcharge": {
        if (quote.otherFlatUpchargeReason) {
          const flatReasonValidationErrors = await validateSchema(FlatUpchargeOtherReasonSchema, quote.flatUpchargeReason, { abortEarly: false });

          this.setState({
            flatResponsesValidationErrors: null,
            flatReasonValidationErrors: flatReasonValidationErrors
          });

          if (flatReasonValidationErrors) {
            return;
          }
        }
        else {
          const yesAnswers = _.filter(answers, (fur) => fur.answer === "Yes");

          const flatResponseValidationErrors = await validateSchema(FlatUpchargeResponsesSchema, yesAnswers, { abortEarly: false });

          this.setState({
            flatResponsesValidationErrors: flatResponseValidationErrors,
            flatReasonValidationErrors: null
          });

          if (flatResponseValidationErrors) {
            return;
          }
        }
        break;
      }
      case "% Upcharge": {
        const percentUpchargeValidationErrors = await validateSchema(PercentUpchargeResponseSchema, quote.percentUpchargeReason, { abortEarly: false });

        this.setState({
          percentUpchargeValidationErrors: percentUpchargeValidationErrors
        })

        if (percentUpchargeValidationErrors) {
          return;
        }

        break;
      }
    }

    this.props.handleDone();
  }

  render() {
    const {
      questions,
      questionType,
      answers,
      quote,
      isOpen,
      viewOnly
    } = this.props;

    const {
      percentUpchargeValidationErrors,
      flatReasonValidationErrors,
      flatResponsesValidationErrors
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
      >
        <DialogTitle>{questionType + ' Reason'}</DialogTitle>
        {(flatResponsesValidationErrors && questionType == "Flat $ Upcharge") ? 
          <h3 className={styles.flatResponsesError}>
            At least one question must be responded 'Yes' to
          </h3> : null
        }
        <DialogContent
          className={modalStyles.modalContainer}
        >
          <div className={styles.gridWrapper}>
            {questionType != "% Upcharge" ?
              <>
                <MappedUpchargeReasonTextFields
                  questions={questions}
                  answers={answers}
                  viewOnly={viewOnly}
                  className={styles.questionText}
                  setAnswer={this.setAnswer}
                />
                {questionType == "Flat $ Upcharge" &&
                  <OtherUpchargeReasonTextField
                    viewOnly={viewOnly}
                    quote={quote}
                    className={styles.questionText}
                    validationErrors={flatReasonValidationErrors}
                    setAnswer={this.setOtherAnswer}
                    setAnswerText={this.setReason}
                  />
                }
              </>
              :
              <PercentUpchargeReasonTextField
                viewOnly={viewOnly}
                quote={quote}
                setReason={this.setReason}
                validationErrors={percentUpchargeValidationErrors}
              />
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this._onDone}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}