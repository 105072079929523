import {
  React,
  bind,
  _,
  memoizeOne
} from "$Imports/Imports";

import {
  Question,
  Quote,
  QuoteFreight,
  QuoteStopFreightQuestion
} from "$Generated/api";

import {
  DisplayFormattedNumber,
  DisplayFormattedFeet,
  OperationTimeoutModal,
} from "$Imports/CommonComponents";

import {
  Button,
  IconButton,
  DataGridPro,
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  Icon,
  Tooltip,
} from "$Imports/MaterialUIComponents";

import {
  QuestionAnswerIcon,
  Check,
  Error,
  AutoAwesomeMotion,
  Description
} from "$Imports/MaterialUIIcons";

import {
  FreightTotalData,
  IQuoteEntryServiceInjectedProps,
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

import {
  ICommodityServiceInjectedProps,
  CommodityService
} from "$State/CommodityFreezerService";

import {
  CompanySelectService,
  ICompanySelectServiceInjectedProps
} from "$State/CompanySelectFreezerService";

import {
  SecurityContext
} from "$Utilities/Security/ApplicationSecuritySettings";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  FreightSerialModal
} from "./FreightSerialModal";

import {
  CommodityQuestionModal
} from "../CommodityQuestionModal";

import {
  addRowIndexes
} from "../QuoteView";

import {
  DEFAULT_TIMEOUT,
  getLengthContent,
  getWeightContent,
  TIMEOUT_BUFFER
} from "$Utilities/ratingUtil";

const styles: {
  freightButton: string;
  gridSummaryRow: string;
  summaryDivider: string;
  summaryCell: string;
  overdimensional: string;
  description: string;
} = require("./QuoteStopEntry.scss");

interface ICommodityDataEntryViewBaseProp {
  thisQuoteStopIndex: number;
  quote: Quote;
  viewOnly: boolean;
  freightTotalData: FreightTotalData;
  currentQuoteFreight: QuoteFreight[];
}

interface IOwnState {
  commodityName: string | undefined;
  commodityQuestions?: Question[];
  commodityResponses?: QuoteStopFreightQuestion[];
  isCommodityQuestionModalOpen: boolean;
  requireRetotal: boolean;
  isFreightSerialTimeoutModalOpen: boolean;
}

type ICommodityDataEntryViewProp = IQuoteEntryServiceInjectedProps
  & ICommodityServiceInjectedProps
  & ICommodityDataEntryViewBaseProp
  & ICompanySelectServiceInjectedProps;

class _CommodityDataEntryView extends React.Component<ICommodityDataEntryViewProp, IOwnState> {
  state: IOwnState = {
    isCommodityQuestionModalOpen: false,
    commodityName: undefined,
    requireRetotal: false,
    isFreightSerialTimeoutModalOpen: false
  };

  private readonly _addRowIndexes = memoizeOne(addRowIndexes);

  @bind
  private _onFreightClick() {
    const {
      quote
    } = this.props.QuoteEntryService.getState();

    if (quote.status === "PendingResave" || (quote.status === "Pending" && quote.quoteType !== "Quick") || quote.status === "PendingNeedsCustomers" || quote.status === "ApprovalNeeded") {
      this.props.QuoteEntryService.openFreightSerialModal();
    } else {
      this.props.QuoteEntryService.openFreightModal();
    }
  }

  @bind
  private async _onFreightSerialSave() {
    await this.props.QuoteEntryService.saveFreightSerialModal();

    this.setState({ requireRetotal: false });
  }

  @bind
  private _onFreightSerialChange(quoteFreightIndex: number, quoteFreight: Partial<QuoteFreight>) {
    const requireRetotal = this.props.QuoteEntryService.updateQuoteFreightSerial(quoteFreightIndex, quoteFreight);
    if (requireRetotal) {
      const { currentQuoteFreight } = this.props.QuoteEntryService.getState();

      if (currentQuoteFreight.length === 1) {
        this._onFreightSerialRetotal();
      }
      else if (currentQuoteFreight.length > 1) {
        this.setState({ requireRetotal: true });
      }
    }
  }

  @bind
  private _onFreightSerialCancel() {
    this.props.QuoteEntryService.cancelFreightSerialModal();

    this.setState({ requireRetotal: false });
  }

  @bind
  private async _onFreightSerialRetotal(timeout: number = DEFAULT_TIMEOUT) {
    const { currentQuoteFreight } = this.props.QuoteEntryService.getState();
    const { isFreightSerialTimeoutModalOpen } = this.state;

    let bufferTimer: number | undefined = undefined;

    if (!isFreightSerialTimeoutModalOpen) {
      bufferTimer = setTimeout(() => {
        this.setState({ isFreightSerialTimeoutModalOpen: true });
      }, TIMEOUT_BUFFER) as unknown as number; // timeout IDs in browser are _not_ NodeJS.Timeout
    }

    let keepTimeoutModal: boolean = false;

    let newTotals: Partial<FreightTotalData>;
    try {
      newTotals = await this.props.QuoteEntryService.calculateFreightTotalResults(currentQuoteFreight, undefined, timeout);
    } catch (ex) {
      // don't show timeout modal for non-timeout errors
      clearTimeout(bufferTimer);
      throw ex;
    }
    
    if (!newTotals.ratingError) {
      this.setState({ requireRetotal: false });
    }
    else if (newTotals.ratingError.indexOf("timed out") > -1) {
      keepTimeoutModal = true;
    }

    if (bufferTimer) {
      clearTimeout(bufferTimer);
    }

    this.setState({ isFreightSerialTimeoutModalOpen: keepTimeoutModal });
  }

  @bind
  private _onFreightSerialTimeoutCancel() {
    this.setState({ isFreightSerialTimeoutModalOpen: false });
  }

  @bind
  private _openCommodityQuestionModal(quoteFreight: QuoteFreight) {
    const { commodityQuestionsFetchResults } = this.props.QuoteEntryService.getState();

    const questions = _.filter(commodityQuestionsFetchResults.data ?? [],
      q => _.findIndex(q.commodityQuestions, cQ => cQ.commodityId === quoteFreight.commodityId) !== -1);

    this.setState({
      commodityName: quoteFreight.commodity?.commodityName,
      commodityQuestions: questions,
      commodityResponses: quoteFreight.quoteStopFreightQuestions,
      isCommodityQuestionModalOpen: true
    });
  }

  @bind
  private _closeCommodityQuestionModal() {
    this.setState({
      commodityName: undefined,
      commodityQuestions: undefined,
      commodityResponses: undefined,
      isCommodityQuestionModalOpen: false
    });
  }

  render() {
    const {
      quoteValidationErrors,
      freightSerialModalIsOpen,
      calculateRatingVariableResults
    } = this.props.QuoteEntryService.getState();
    const {
      commodityFetchResults
    } = this.props.commodityService.getState();
    const {
      companyContext
    } = this.props.companySelectService.getState();

    const {
      quote,
      freightTotalData,
      currentQuoteFreight,
      viewOnly,
      thisQuoteStopIndex
    } = this.props;

    const {
      commodityName,
      commodityQuestions,
      commodityResponses,
      isCommodityQuestionModalOpen,
      requireRetotal,
      isFreightSerialTimeoutModalOpen
    } = this.state;

    const isFetching = calculateRatingVariableResults.isFetching;

    const disableFreightButton = isFetching ||
      (viewOnly
        && !(quote.status === "Pending" || quote.status === "PendingNeedsCustomers")
        && !((SecurityContext.isInGroup("/Admin") || SecurityContext.isInGroup("/Manager")) && quote.status === "ApprovalNeeded"))
      || SecurityContext.isInGroup("/ViewOnly");

    const gridColumns: GridColDef[] = [
      {
        headerName: "Stop",
        description: "Stop",
        field: "quoteStop",
        flex: 1,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams<number, QuoteFreight>) => {
          return params.row.quoteStop?.stopNumber;
        }
      },
      {
        headerName: "Commodity",
        description: "Commodity",
        field: "commodity",
        flex: 4,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams<any, QuoteFreight>) => {
          return params.row.commodity?.commodityShortName ?? params.row.commodity?.commodityName ?? "";
        },
        renderCell: (params: GridRenderCellParams<any, QuoteFreight>) => {
          return (
            <>
              {!params.row.commodity?.isActive
                ? <Icon title="Commodity is inactive">
                  <Error />
                </Icon>
                : undefined
              }
              {params.value}
              {params.row.description &&
                <Description fontSize="small" titleAccess={params.row.description} className={styles.description} />
              }
            </>
          );
        }
      },
      {
        headerName: "Pcs",
        description: "# of Pieces",
        field: "numberOfPieces",
        flex: 1,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<any, QuoteFreight>) => {
          if (params.row.isGrouped) {
            var display = (
              <>
                <div>{params.value}</div>
                <Tooltip title="Grouped freight">
                  <IconButton
                    size="small"
                  >
                    <AutoAwesomeMotion />
                  </IconButton>
                </Tooltip>
              </>
            )
            return display;
          }

          return params.value;
        }
      },
      {
        headerName: "Length",
        description: "Length",
        field: "length",
        flex: 2,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<number>) => <DisplayFormattedFeet value={params.value} />
      },
      {
        headerName: "Width",
        description: "Width",
        field: "width",
        flex: 2,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<number>) => <DisplayFormattedFeet value={params.value} />
      },
      {
        headerName: "Height",
        description: "Height",
        field: "height",
        flex: 2,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<number>) => <DisplayFormattedFeet value={params.value} />
      },
      {
        headerName: "Weight",
        description: "Weight",
        field: "weight",
        flex: 3,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<number>) => <DisplayFormattedNumber value={params.value} formatString={"0"} postfix={" lbs"} />
      },
      {
        headerName: "Ref #",
        description: "Serial/Ref #",
        field: "serialRefNumber",
        flex: 2,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<string>) => params.value ? <Check fontSize="small" /> : ""
      },
      {
        headerName: "Stackable",
        description: "Stackable",
        field: "isStackable",
        flex: 1,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<boolean>) => params.value ? "Yes" : "No"
      },
      {
        headerName: "Side by Side",
        description: "Side by Side",
        field: "isSideBySide",
        flex: 1,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<boolean>) => params.value ? "Yes" : "No"
      },
      {
        headerName: "",
        field: "action",
        width: 54,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, QuoteFreight>) => {
          return (
            <IconButton
              color={params.row.commodityId ? "secondary" : "primary"}
              disabled={!params.row.commodityId}
              onClick={() => this._openCommodityQuestionModal(params.row)}
              size={"small"}
            >
              <QuestionAnswerIcon />
            </IconButton>
          );
        }
      }
    ];

    const validationsParserQuote = new ValidationErrorParser<Quote>(quoteValidationErrors);

    const quoteFreightRows = this._addRowIndexes(quote.quoteFreights ?? []);
    const lengthContent = getLengthContent(freightTotalData, requireRetotal, styles.overdimensional);
    const weightContent = requireRetotal && ((freightTotalData?.totalNumOfPieces ?? 0) > 0)
      ? undefined
      : getWeightContent(freightTotalData, styles.overdimensional);

    return (
      <>
        <div>
          {!validationsParserQuote.isValidDeep(`quoteFreights`) &&
            <h3 style={{ color: "rgb(243, 17, 17)", textAlign: "center" }}>
              {validationsParserQuote.validationMessageDeep(`quoteFreights`)}
            </h3>}
          {!validationsParserQuote.isValidDeep(`quoteStops[${thisQuoteStopIndex}].quoteFreights`) &&
            <h3 style={{ color: "rgb(243, 17, 17)", textAlign: "center" }}>
              {validationsParserQuote.validationMessageDeep(`quoteStops[${thisQuoteStopIndex}].quoteFreights`)}
            </h3>}
        </div>

        <DataGridPro
          columns={gridColumns}
          rows={quoteFreightRows}
          density="compact"
          initialState={{
            sorting: {
              sortModel: [{ field: "quoteStop", sort: "asc" }]
            }
          }}
          columnBuffer={100}  // "disable" column virtualization - modals/dialogs affect display when altering data
          columnVisibilityModel={{
            quoteStop: quote.quoteType === "Full"
          }}
          getRowId={(row) => row.rowIndex}
          autoHeight
          disableColumnFilter
          disableSelectionOnClick
          hideFooter
        />

        <div className={styles.freightButton}>
          <div className={styles.gridSummaryRow}>
            <div className={styles.summaryCell}><b>Total Pieces</b>: {freightTotalData.totalNumOfPieces ?? 0}</div>
            <div className={styles.summaryDivider}>|</div>
            <div className={styles.summaryCell}>{lengthContent}</div>
            {weightContent &&
              <>
                <div className={styles.summaryDivider}>|</div>
                <div className={styles.summaryCell}>{weightContent}</div>
              </>
            }
          </div>
          <Button
            color="primary"
            onClick={() => this._onFreightClick()}
            disabled={disableFreightButton}
          >
            Add/Edit Freight
          </Button>
        </div>
        <FreightSerialModal
          isOpen={freightSerialModalIsOpen}
          isFetching={isFetching}
          requireRetotal={requireRetotal}
          onSave={this._onFreightSerialSave}
          onCancel={this._onFreightSerialCancel}
          onChange={this._onFreightSerialChange}
          onRetotal={this._onFreightSerialRetotal}
          freightCommodities={currentQuoteFreight}
          allCommodities={commodityFetchResults.data ?? []}
          quoteStatus={quote.status}
        />
        <CommodityQuestionModal
          commodityName={commodityName}
          questions={commodityQuestions ?? []}
          responses={commodityResponses ?? []}
          isOpen={isCommodityQuestionModalOpen}
          onDone={this._closeCommodityQuestionModal}
          viewOnly
        />

        <OperationTimeoutModal
          isOpen={isFreightSerialTimeoutModalOpen}
          isFetching={isFetching}
          onRetry={this._onFreightSerialRetotal}
          onCancel={this._onFreightSerialTimeoutCancel}
          operationText={"calculate freight totals"}
        />
      </>
    );
  }
}

export const CommodityDataEntryView = QuoteEntryService.inject(
  CommodityService.inject(
    CompanySelectService.inject(
      _CommodityDataEntryView
    )
  )
);
