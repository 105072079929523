import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  Helpers,
  NullableOptional
} from "$Imports/Imports";

import {
  ValidationError,
  SchemaOf
} from "$Shared/imports/Yup";

import {
  RateModelTestApiFactory,
  RateModelTest,
  RateModelTestRequestVM,
  QuickRateModelTestResult,
  DatRate
} from "$Generated/api";

import {
  directionType
} from "$Imports/CommonComponents";

import {
  QuickRateModelTestRequest
} from "$Generated/api";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import yup from "$Shared/utilities/yupExtension";

export type formModeType = "add" | "edit" | "delete" | "none";

interface IRateModelTestState {
  formMode: formModeType;
  rateModelTestFetchResults: IAjaxState<RateModelTest[]>;
  rateModelTestDeleteResults: IAjaxState<boolean>;
  rateModelTestAddResults: IAjaxState<RateModelTest>;
  rateModelTestUpdateResults: IAjaxState<RateModelTest>;
  editAddRateModelTest: RateModelTest | null;
  sortState: ISortState;
  updateAllRateModelTestsMilesResult: IAjaxState;
  editAddValidationErrors: ValidationError | null;
  reload: boolean;
  includeFuture: boolean;
  futureDateTime: Date | null;
  futureDateValidationErrors: ValidationError | null;
  quickTestResultFetchState: IAjaxState<QuickRateModelTestResult>;
  quickTestRequest: QuickRateModelTestRequest;
  quickTestValidationErrors: ValidationError | null;
  datRateFetchResult: IAjaxState<DatRate>;
}

interface ISortState {
  sortColumnName?: string;
  sortDirection?: directionType;
}

const InjectedPropName = "rateModelTestService";

const QuickTestRequestValidationSchema: SchemaOf<NullableOptional<QuickRateModelTestRequest>> = yup.object({
  modelLevel: yup.number().required("Linear foot is required.").defined().min(3, "Number must be larger than 3").max(48, "Number must be less than 48"),
  originZipPostalCode: yup.string().required("Origin zip code is required.").zipCode(),
  destZipPostalCode: yup.string().required("Destination zip code is required.").zipCode(),
  datRate: yup.number().notRequired(),
  commodityId: yup.number().required("Commodity is required.").defined("Commodity is required."),
  companyId: yup.number().notRequired(),
  miles: yup.number().notRequired(),
  futureDate: yup.date().nullable().typeError("Invalid Date").notRequired().min(new Date(), "Future Date must be greater than now.")
});

const RateModelTestValidationSchema: SchemaOf<NullableOptional<RateModelTest>> = yup.object({
  id: yup.number().notRequired(),
  modelLevelId: yup.number().required("Linear foot is required.").defined().min(3, "Number must be larger than 3").max(48, "Number must be less than 48"),
  originZipPostalCode: yup.string().required("Origin zip code is required.").zipCode(),
  destZipPostalCode: yup.string().required("Destination zip code is required.").zipCode(),
  datRate: yup.number().notRequired(),
  datRateObject: yup.object().notRequired(),
  commodityId: yup.number().required("Commodity is required.").defined("Commodity is required."),
  companyId: yup.number().notRequired(),
  miles: yup.number().notRequired(),
  defaultHighLineHaulRate: yup.number().notRequired(),
  defaultLowLineHaulRate: yup.number().notRequired(),
  activeHighLineHaulRate: yup.number().notRequired(),
  activeLowLineHaulRate: yup.number().notRequired(),
  futureHighLineHaulRate: yup.number().notRequired(),
  futureLowLineHaulRate: yup.number().notRequired(),
  sandboxHighLineHaulRate: yup.number().notRequired(),
  sandboxLowLineHaulRate: yup.number().notRequired(),
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  minimumAmount: yup.number().notRequired(),
  commodity: yup.object().notRequired(),
  defaultCalculationInfo: yup.object().notRequired(),
  activeCalculationInfo: yup.object().notRequired(),
  sandboxCalculationInfo: yup.object().notRequired(),
  futureCalculationInfo: yup.object().notRequired()
});

const FutureDateTimeSchema = yup.object().shape({
  futureDateTime: yup.date().nullable().typeError("Invalid Date").required("Future Date is required.").min(new Date(), "Future Date must be greater than now."),
});

const initialState = {
  formMode: "none",
  editAddRateModelTest: null,
  rateModelTestDeleteResults: managedAjaxUtil.createInitialState(),
  rateModelTestFetchResults: managedAjaxUtil.createInitialState(),
  rateModelTestAddResults: managedAjaxUtil.createInitialState(),
  rateModelTestUpdateResults: managedAjaxUtil.createInitialState(),
  sortState: {
    sortColumnName: "date-modified",
    sortDirection: "desc",
  },
  updateAllRateModelTestsMilesResult: managedAjaxUtil.createInitialState(),
  editAddValidationErrors: null,
  reload: false,
  includeFuture: false,
  futureDateTime: null,
  futureDateValidationErrors: null,
  quickTestResultFetchState: managedAjaxUtil.createInitialState(),
  quickTestRequest: {},
  quickTestValidationErrors: null,
  datRateFetchResult: managedAjaxUtil.createInitialState()
} as IRateModelTestState;

class RateModelTestFreezerService extends FreezerService<IRateModelTestState, typeof InjectedPropName> {

  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }
  
  public addRateModelTest(companyId: number | undefined) {
    
    this.freezer.get().set({
      editAddRateModelTest: {
        companyId: companyId
      },
      formMode: "add"
    });
  }

  public async saveAndAdd() {
    const editAddRateModelTest = this.freezer.get().editAddRateModelTest?.toJS();
    const errors = await validateSchema(RateModelTestValidationSchema, editAddRateModelTest, {
      abortEarly: false
    });

    this.freezer.get().set({ editAddValidationErrors: errors });

    if (errors) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "rateModelTestAddResults",
      onExecute: (apiOptions, param, options) => {
        const factory = RateModelTestApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1RateModelTestPost(param);
      },
      params: {
        body: editAddRateModelTest,
      },
      onOk: (data) => {
        this.freezer.get().editAddRateModelTest?.set({
          commodityId: undefined,
          modelLevelId: undefined
        });

        this.freezer.get().set({
          reload: true
        });
      },
      onError: (err, errorMessage) => {
        const serverMessage = err?.body?.message;
        if (serverMessage && serverMessage == "PCMiler is unavailable") {
          ErrorService.pushErrorMessage("PC Miler is not available. Contact IT Support", "high", "Retry", this.saveAndAdd);
        }
        else if (serverMessage && serverMessage.includes("PCMiler")) {
          ErrorService.pushErrorMessage(serverMessage);
        }
        else {
          ErrorService.pushErrorMessage("Failed to save rate model test.");
        }
      }
    });
  }

  public updateRateModelTest(rateModelTest: Partial<RateModelTest>) {
    this.freezer.get().editAddRateModelTest?.set(rateModelTest);
  }

  public editRateModelTest(rateModelTestId: string | undefined) {
    const foundRateModelTest = _.find(this.freezer.get().rateModelTestFetchResults.data, (d) => d.id === rateModelTestId);

    if (foundRateModelTest) {
      this.freezer.get().set({
        editAddRateModelTest: foundRateModelTest.toJS(),
        formMode: "edit",
      });
    }
  }

  public confirmRateModelTestDeletion(rateModelTest: RateModelTest) {
    const foundRateModelTest = _.find(this.freezer.get().rateModelTestFetchResults.data, (d) => d.id === rateModelTest.id);

    if (foundRateModelTest) {
      this.freezer.get().set({
        editAddRateModelTest: foundRateModelTest.toJS(),
        formMode: "delete",
      });
    }
  }

  public cancel(companyId: number | undefined) {
    if (this.freezer.get().reload === true) {
      this.fetchRateModelTests(companyId, true);
    }

    this.clearEditAddForm();
  }

  public clearEditAddForm() {

    this.freezer.get().set({
      editAddRateModelTest: null,
      editAddValidationErrors: null,
      formMode: "none",
      reload: false
    });
  }

  public async saveRateModelTest(companyId: number | undefined) {
    const editAddRateModelTest = this.freezer.get().editAddRateModelTest?.toJS();
    const errors = await validateSchema(RateModelTestValidationSchema, editAddRateModelTest, {
      abortEarly: false
    });

    this.freezer.get().set({ editAddValidationErrors: errors });

    if (errors) {
      return;
    }

    if (this.freezer.get().formMode === "add") {
      this.saveAddRateModelTest(companyId);
    }

    if (this.freezer.get().formMode === "edit") {
      this.saveUpdateRateModelTest(companyId);
    }
  }

  public setSortState(sortState: Partial<ISortState>) {
    this.freezer.get().sortState.set(sortState);
  }

  private saveUpdateRateModelTest(companyId: number | undefined) {
    const editAddRateModelTest = this.freezer.get().editAddRateModelTest?.toJS();

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "rateModelTestUpdateResults",
      onExecute: (apiOptions, param, options) => {
        const factory = RateModelTestApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1RateModelTestPut(param);
      },
      params: {
        body: editAddRateModelTest,
      },
      onOk: (data) => {
        this.clearEditAddForm();
        this.fetchRateModelTests(companyId, true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch rate model tests.");
      }
    });
  }

  public deleteRateModelTest(companyId: number | undefined) {
    const editAddRateModelTest = this.freezer.get().editAddRateModelTest?.toJS();

    if (Helpers.isNullOrUndefined(editAddRateModelTest)) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "rateModelTestDeleteResults",
      onExecute: (apiOptions, param, options) => {
        const factory = RateModelTestApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1RateModelTestDelete(param);
      },
      params: {
        body: editAddRateModelTest?.id,
      },
      onOk: (data) => {
        this.clearEditAddForm();
        this.fetchRateModelTests(companyId, true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to delete rate model test.");
      }
    });
  }

  public async fetchRateModelTests(companyId: number | undefined, forceUpdate: boolean = false) {
    const { 
      rateModelTestFetchResults, 
      includeFuture, 
      futureDateTime
    } = this.freezer.get();

    if ((rateModelTestFetchResults.hasFetched && !forceUpdate) || !companyId) {
      return;
    }

    if (includeFuture) {
      const errors = await this.isValidFutureDate();

      if (errors) {
        return;
      }
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "rateModelTestFetchResults",
      params: {
        body: {
          futureDateTime: includeFuture ? futureDateTime ?? undefined : undefined,
          companyId: companyId
        } as RateModelTestRequestVM
      },
      onExecute: (apiOptions, param, options) => {
        const factory = RateModelTestApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1RateModelTestGetAllRateModelTestsPost(param);
      },
      onError: (err, errorMessage) => {
        const serverMessage = err?.body?.message;
        if (serverMessage && serverMessage == "PCMiler is unavailable") {
          ErrorService.pushErrorMessage("PC Miler is not available. Contact IT Support", "high", "Retry", () => this.fetchRateModelTests(companyId, true));
        }
        else if (serverMessage && serverMessage.includes("PCMiler")) {
          ErrorService.pushErrorMessage(serverMessage);
        }
        else {
          ErrorService.pushErrorMessage("Failed to fetch rate model tests.");
        }
      }
    });
  }

  private saveAddRateModelTest(companyId: number | undefined) {
    const editAddRateModelTest = this.freezer.get().editAddRateModelTest?.toJS();

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "rateModelTestAddResults",
      onExecute: (apiOptions, param, options) => {
        const factory = RateModelTestApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1RateModelTestPost(param);
      },
      params: {
        body: editAddRateModelTest,
      },
      onOk: (data) => {
        this.clearEditAddForm();
        this.fetchRateModelTests(companyId, true);
      },
      onError: (err, errorMessage) => {
        const serverMessage = err?.body?.message;
        if (serverMessage && serverMessage == "PCMiler is unavailable") {
          ErrorService.pushErrorMessage("PC Miler is not available. Contact IT Support", "high", "Retry", () => this.saveAddRateModelTest(companyId));
        }
        else if (serverMessage && serverMessage.includes("PCMiler")) {
          ErrorService.pushErrorMessage(serverMessage);
        }
        else {
          ErrorService.pushErrorMessage("Error getting mileage");
        }
      }
    });
  }

  public updateAllRateModelTestsMiles(companyId: number | undefined) {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "updateAllRateModelTestsMilesResult",
      onExecute: (apiOptions, param, options) => {
        const factory = RateModelTestApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1RateModelTestUpdateAllTestCasesMilesGet();
      },
      onOk: () => {
        this.clearEditAddForm();
        this.fetchRateModelTests(companyId, true);
      },
      onError: (err, errorMessage) => {
        const serverMessage = err?.body?.message;
        if (serverMessage && serverMessage == "PCMiler is unavailable") {
          ErrorService.pushErrorMessage("PC Miler is not available. Contact IT Support", "high", "Retry", () => this.updateAllRateModelTestsMiles(companyId));
        }
        else if (serverMessage && serverMessage.includes("PCMiler")) {
          ErrorService.pushErrorMessage(serverMessage);
        }
        else {
          ErrorService.pushErrorMessage("Failed to update rate model tests' miles.");
        }
      }
    });
  }

  public setIncludeFuture(value: boolean) {
    this.freezer.get().set({
      includeFuture: value,
      futureDateTime: null,
      futureDateValidationErrors: null,
    });
  }

  public setFutureDateTime(value: Date) {
    this.freezer.get().set({
      futureDateTime: value,
      futureDateValidationErrors: null,
    });
  }

  public async update(companyId: number | undefined) {
    const errors = await this.isValidFutureDate();

    if (errors) {
      return;
    }

    await this.fetchRateModelTests(companyId, true);
  }

  private async isValidFutureDate(): Promise<ValidationError | null> {
    {
      const futureDateTime = this.freezer.get().futureDateTime;

      FutureDateTimeSchema.fields.futureDateTime =
        yup.date().nullable().typeError("Invalid Date").required("Future Date is required.").min(new Date(), "Future Date must be greater than now.");

      const errors = await validateSchema(FutureDateTimeSchema.fields.futureDateTime, futureDateTime as Date | null | undefined, {
        abortEarly: false
      })

      this.freezer.get().set({ futureDateValidationErrors: errors });

      return errors;
    }
  }

  setQuickTestRequest(quickTestRequest: Partial<QuickRateModelTestRequest>) {
    this.freezer.get().quickTestRequest.set(quickTestRequest);
  }

  public async fetchQuickTestResult(companyId: number | undefined) {
    const { quickTestRequest } = this.freezer.get().toJS()

    quickTestRequest.companyId = companyId;

    // update min date check to use datetime of user triggers validation
    QuickTestRequestValidationSchema.fields.futureDate =
      yup.date().nullable().typeError("Invalid Date").notRequired().min(new Date(), "Future Date must be greater than now.");
      
    const errors = await validateSchema(QuickTestRequestValidationSchema, quickTestRequest, {
      abortEarly: false
    });

    this.freezer.get().set({ quickTestValidationErrors: errors });

    if (errors) {
      return;
    }

    managedAjaxUtil.fetchResults(
      {
        ajaxStateProperty: "quickTestResultFetchState",
        freezer: this.freezer,
        onExecute: (apiOptions, params, options) => {
          const factory = RateModelTestApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return factory.apiV1RateModelTestQuickRateModelTestPost(params);
        },
        params: {
          body: quickTestRequest
        },
        onError: (err, errorMessage) => {
          const serverMessage = err?.body?.message;
          if (serverMessage && serverMessage == "PCMiler is unavailable") {
            ErrorService.pushErrorMessage("PC Miler is not available. Contact IT Support", "high", "Retry", () => this.fetchQuickTestResult(companyId));
          }
          else if (serverMessage && (
            serverMessage.includes("PCMiler")
            || serverMessage.includes("Invalid")
            || serverMessage.includes("markup")
          )) {
            ErrorService.pushErrorMessage(serverMessage);
          }
          else {
            ErrorService.pushErrorMessage("Failed to fetch DAT rate.");
          }
        }
      }
    );
  }

  public async getDatRate(origin: string, destination: string, companyId: number | undefined) {
    if (!companyId) {
      return;
    }

    managedAjaxUtil.fetchResults({
      ajaxStateProperty: "datRateFetchResult",
      freezer: this.freezer,
      params: {
        origin,
        destination,
        companyId
      },
      onExecute: (apiOptions, params) => {
        const factory = RateModelTestApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1RateModelTestDatRateOriginDestinationCompanyIdGet(params);
      }
    });
  }
}

export const RateModelTestService = new RateModelTestFreezerService();
export type IRateModelTestServiceInjectedProps = ReturnType<RateModelTestFreezerService["getPropsForInjection"]>;
