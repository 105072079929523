// extracted by mini-css-extract-plugin
var _1 = "CustomerQuoteViewPage__aboveSum__p1u_B";
var _2 = "CustomerQuoteViewPage__actionButton__pztZo";
var _3 = "CustomerQuoteViewPage__cardTitle__j7FsE";
var _4 = "CustomerQuoteViewPage__customerCard__Agc2C";
var _5 = "CustomerQuoteViewPage__customerInfo__mrZTF";
var _6 = "CustomerQuoteViewPage__expired__Os0Z2";
var _7 = "CustomerQuoteViewPage__logo__k15kC";
var _8 = "CustomerQuoteViewPage__logoContainer__Dliuf";
var _9 = "CustomerQuoteViewPage__printQuoteCard__qkRgX";
var _a = "CustomerQuoteViewPage__sum__ygydw";
export { _1 as "aboveSum", _2 as "actionButton", _3 as "cardTitle", _4 as "customerCard", _5 as "customerInfo", _6 as "expired", _7 as "logo", _8 as "logoContainer", _9 as "printQuoteCard", _a as "sum" }
