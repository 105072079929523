import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Customer
} from "$Generated/api";

import {
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  Button
} from "$Imports/MaterialUIComponents";

interface ICustomerAlertModalProps {
  isOpen: boolean;
  customers: Customer[];
  onClose: () => void;
  customerType?: string;
}

const styles: {
  alertContainer: string;
  alertTitle: string;
  alertText: string;
} = require("./CustomerAlertModal.scss");

export class CustomerAlertModal extends React.PureComponent<ICustomerAlertModalProps> {
  @bind
  private _onClose() {
    this.props.onClose();
  }

  render() {
    const {
      isOpen,
      customers,
      customerType
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
          {customerType} Alert
        </DialogTitle>
        <DialogContent className={styles.alertContainer}>
          {_.map(customers, (customer, idx) => (
            <div key={idx}>
              <div className={styles.alertTitle}>Alert for {customer?.customerName}:</div>
              <div className={styles.alertText}>{customer?.alert}</div>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._onClose}
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}