import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
} from "$Imports/Imports";

import {
  LoadingInstruction,
  LoadingInstructionApiFactory
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

// prevent controlled/uncontrolled input warnings
export const EMPTY_LOADING_INSTRUCTION: LoadingInstruction = {
  id: 0,
  instruction: "",
  appliesTo: "BothShippersAndConsignees"
};

interface ILoadingInstructionServiceState {
  loadingInstructionFetchResults: IAjaxState<LoadingInstruction[]>;
  activeLoadingInstructions: LoadingInstruction[];
  loadingInstructionSaveResult: IAjaxState<LoadingInstruction>;
}

const InjectedPropName = "loadingInstructionService";

const initialState = {
  loadingInstructionFetchResults: managedAjaxUtil.createInitialState(),
  activeLoadingInstructions: [],
  loadingInstructionSaveResult: managedAjaxUtil.createInitialState()
} as ILoadingInstructionServiceState;

class LoadingInstructionFreezerService extends FreezerService<ILoadingInstructionServiceState, typeof InjectedPropName> {

  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchLoadingInstructions(filterActive: boolean = false, forceUpdate: boolean = false) {
    const {
      loadingInstructionFetchResults: loadingInstructionFetchResults,
    } = this.freezer.get();

    if (loadingInstructionFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "loadingInstructionFetchResults",
      params: {
        activeOnly: filterActive
      },
      onExecute: (apiOptions, params, options) => {
        const factory = LoadingInstructionApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getLoadingInstructions(params);
      },
      onOk: (data: LoadingInstruction[]) => {
        const activeLoadingInstructions = data.filter(i => i.isActive);
        this.freezer.get().set({ activeLoadingInstructions });
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch loading instructions.");
      }
    });
  }

  @bind
  public saveLoadingInstruction(loadingInstruction: LoadingInstruction): Promise<LoadingInstruction | void> {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "loadingInstructionSaveResult",
      params: {
        body: loadingInstruction
      },
      onExecute: (apiOptions, params, options) => {
        const factory = LoadingInstructionApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);

        if (!!loadingInstruction.id && loadingInstruction.id > 0) {
          return factory.updateLoadingInstruction(params);
        } else {
          return factory.addLoadingInstruction(params);
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body.message ?? "Failed to save loading instruction.");
      }
    });
  }
}

export const LoadingInstructionService = new LoadingInstructionFreezerService();
export type ILoadingInstructionServiceInjectedProps = ReturnType<LoadingInstructionFreezerService["getPropsForInjection"]>;
