import {
  React,
  bind,
  moment,
  _
} from "$Imports/Imports";

import {
  AdvanceTextField,
  DateRangePicker,
  SearchControlsContainer
} from "$Imports/CommonComponents";

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent
} from "$Imports/MaterialUIComponents";

import {
  CustomerQuotesSearchCriteria,
  CustomerQuotesSearchCriteriaDateTypeEnum,
  CustomerQuotesSearchCriteriaQuoteStatusesEnum
} from "$Generated/api";

import {
  ICustomerQuoteServiceInjectedProps,
  CustomerQuoteService,
  DEFAULT_PORTAL_QUOTE_SEARCH
} from "$State/CustomerQuotesFreezerService";

import {
  EmployeeService,
  IEmployeeServiceInjectedProps
} from "$State/EmployeeFreezerService";

import {
  SalesRepHomeService,
  ISalesRepHomeServiceInjectedProps
} from "$State/SalesRepHomeFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

interface IOwnProps {
  companyId: number | undefined;
}

type OwnProps = IOwnProps
  & ICustomerQuoteServiceInjectedProps
  & IEmployeeServiceInjectedProps
  & ISalesRepHomeServiceInjectedProps;

const styles: {
  container: string;
} = require("./CustomerQuoteSearchForm.scss");

class _CustomerQuoteSearchForm extends React.Component<OwnProps, {}> {
  @bind
  private _onQuoteOrPONumChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.customerQuotesService.onSearchModelChanged({ quoteOrPONumber: e.target.value });
  }

  @bind
  private _onMenuItemChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value === "") {
      this.props.customerQuotesService.onSearchModelChanged({ [e.target.name]: undefined });
    } else {
      this.props.customerQuotesService.onSearchModelChanged({ [e.target.name]: parseInt(e.target.value)});
    }
  }
  
  @bind
  private _onDateTypeChange(event: SelectChangeEvent) {
    this.props.customerQuotesService.onSearchModelChanged({ dateType: event.target.value ? event.target.value as CustomerQuotesSearchCriteriaDateTypeEnum : undefined });
  }

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    this.props.customerQuotesService.onSearchModelChanged({
      startDate: start ? moment(start).startOf('day').toDate() : undefined,
      endDate: end ? moment(end).endOf('day').toDate() : undefined
    });
  }

  @bind
  private _onStatusChange(e: SelectChangeEvent<CustomerQuotesSearchCriteriaQuoteStatusesEnum[]>) {
    this.props.customerQuotesService.onSearchModelChanged({
      quoteStatuses: e.target.value as CustomerQuotesSearchCriteriaQuoteStatusesEnum[]
    });
  }

  @bind
  private _onReviewedStatusChange(value: string) {
    const isReviewed = value === undefined ? undefined : value === "true" ? true : false;
    this.props.customerQuotesService.onSearchModelChanged({ isReviewed: isReviewed });
  }

  @bind
  private _onFieldKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      this._onSubmit();
    }
  }

  @bind
  private _onSubmit() {
    this.props.customerQuotesService.fetchQuotes(this.props.companyId, true);
  }

  @bind
  private _onClear() {
    this.props.customerQuotesService.onResetSearchModel({companyId: this.props.companyId, ...DEFAULT_PORTAL_QUOTE_SEARCH});
    this._onSubmit();
  }

  render() {
    const {
      quotesFetchResults,
      searchCriteria,
      searchValidationErrors
    } = this.props.customerQuotesService.getState();

    const { activeSalesReps } = this.props.employeeService.getState();
    const salesReps = activeSalesReps ?? [];

    const { simplifiedCustomersFetchResults } = this.props.salesRepHomeService.getState();
    const customers = simplifiedCustomersFetchResults.data ?? [];

    const statusArray: CustomerQuotesSearchCriteriaQuoteStatusesEnum[] = ["Declined", "Expired", "Requested", "Pending"];

    const validationsParser = new ValidationErrorParser<CustomerQuotesSearchCriteria>(searchValidationErrors);

    return (
      <SearchControlsContainer
        className={styles.container}
        onSubmit={this._onSubmit}
        onClear={this._onClear}
        disabled={quotesFetchResults.isFetching}
      >
        <div style={{ flex: "0 1 10rem" }}>
          <AdvanceTextField
            label="Quote # or PO #"
            onChange={this._onQuoteOrPONumChange}
            value={searchCriteria.quoteOrPONumber ?? ""}
            error={!validationsParser.isValid("quoteOrPONumber")}
            helperText={validationsParser.validationMessage("quoteOrPONumber")}
            onKeyDown={this._onFieldKeyDown}
          />
        </div>

        <FormControl style={{ flex: "0 1 10rem" }}>
          <InputLabel shrink>Sales Representative</InputLabel>
          <Select
            name="salesAgentId"
            value={searchCriteria.salesAgentId?.toString() ?? ""}
            onChange={(event) => this._onMenuItemChange(event as React.ChangeEvent<HTMLInputElement>)}
            displayEmpty
          >
            <MenuItem value={""}>All</MenuItem>
            {salesReps.map((agent, index) => (
              <MenuItem key={index} value={agent.id}>
                {`${agent.firstName} ${agent.lastName}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={{ flex: "0 1 14rem" }}>
          <InputLabel shrink>Customer</InputLabel>
          <Select
            name="customerId"
            value={searchCriteria.customerId?.toString() ?? ""}
            onChange={(event) => this._onMenuItemChange(event as React.ChangeEvent<HTMLInputElement>)}
            displayEmpty
          >
            <MenuItem value={""}>All</MenuItem>
            {customers.map((customer, index) => (
              <MenuItem key={index} value={customer.id}>
                {`${customer.customerName}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={{ flex: "0 0 10rem" }} error={!validationsParser.isValid("dateType")}>
            <InputLabel>Date Type</InputLabel>
            <Select
              value={searchCriteria?.dateType ?? ""}
              onChange={this._onDateTypeChange}
              
            >
              <MenuItem value={"QuoteDate"}>Quote Date</MenuItem>
              <MenuItem value={"DeliveryDate"}>Delivery Date</MenuItem>
            </Select>
          </FormControl>

        <div style={{ display: "inline-flex", flex: "0 0 17rem", gap: "0.5rem" }}>
          <DateRangePicker
            startDate={searchCriteria.startDate}
            startError={validationsParser.validationMessage("startDate")}
            endDate={searchCriteria.endDate}
            endError={validationsParser.validationMessage("endDate")}
            onChange={this._onDateRangeChange}
          />
        </div>

        <FormControl style={{ flex: "0 1 14rem" }}>
          <InputLabel>Quote Status</InputLabel>
          <Select
            value={searchCriteria?.quoteStatuses ?? []}
            onChange={this._onStatusChange}
            multiple
            renderValue={(selected) => {
              if (selected.length === 4) {
                return <i>All</i>;
              }
              else {
                return _.map(selected as CustomerQuotesSearchCriteriaQuoteStatusesEnum[], (s, idx) => {
                  return (
                    <span key={idx}>
                      {s}
                      <>{idx !== (selected as CustomerQuotesSearchCriteriaQuoteStatusesEnum[]).length - 1 ? ", " : ""}</>
                    </span>
                  )
                })
              }
            }}
          >
            {
              statusArray.map((status) => {
                return (
                  <MenuItem key={status} value={status}>
                    <Checkbox checked={_.findIndex(searchCriteria?.quoteStatuses, s => s === status) > -1} />
                    <ListItemText primary={status} />
                  </MenuItem>
                )
              })
            }
          </Select>
        </FormControl>

        <FormControl style={{ flex: "0 1 12rem" }}>
          <InputLabel shrink>Review Status</InputLabel>
          <Select<Boolean>
            value={searchCriteria.isReviewed ?? undefined}
            onChange={(event) => this._onReviewedStatusChange(event.target.value as string)}
            displayEmpty
          >
            <MenuItem value={undefined}>Both</MenuItem>
            <MenuItem value={"false"}>Outstanding</MenuItem>
            <MenuItem value={"true"}>Reviewed</MenuItem>
          </Select>
        </FormControl>

      </SearchControlsContainer>
    );
  }
}

export const CustomerQuoteSearchForm = CustomerQuoteService.inject(
  EmployeeService.inject(
    SalesRepHomeService.inject(
      _CustomerQuoteSearchForm
    )
  )
);
