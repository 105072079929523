import {
  React
} from "$Imports/Imports";


import {
  QuoteCarrierCarrierStatusEnum
} from "$Generated/api";

interface ICarrierStatusProps {
  carrierStatus: QuoteCarrierCarrierStatusEnum
};

export class CarrierStatus extends React.PureComponent<ICarrierStatusProps> {
  render() {
    const {
      carrierStatus
    } = this.props;

    const statusSwitch = (status: QuoteCarrierCarrierStatusEnum) => {
      switch(status) {
        case "OnHold":
          return "On Hold";
        default:
          return status;
      }
    }

    return (
      <>
        {statusSwitch(carrierStatus)}
      </>
    );
  }
}