import {
  _,
  moment,
  FreezerService,
  bind,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf
} from "$Shared/imports/Yup";

import {
  LeadSource,
  LeadSourceApiFactory
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

export const LeadSourceSchema: SchemaOf<NullableOptional<LeadSource>> = yup.object({
  id: yup.number().notRequired(),
  // db column is 100
  name: yup.string().required("Name is required").max(30, "Cannot be longer than 30 characters")
    .test("name", "${message}", (value: string | undefined, testContext: any) => {
      if (value?.toLowerCase() === "other") {
        return testContext.createError({ message: "Cannot create or edit Other" })
      }
      return true;
    }),
  isDetailRequired: yup.boolean().notRequired(),
  startDate: yup.date().required("Start Date is required").typeError("Invalid Date"),
  endDate: yup.date().notRequired().nullable().typeError("Invalid Date")
    .when('startDate', (startDate: Date, schema: any) => {
      return startDate ? schema.min(startDate, "Must be after Start Date")
        : schema.notRequired();
    }),
  createdById: yup.number().notRequired().nullable(),
  createdOn: yup.date().notRequired(),
  createdBy: yup.object().notRequired().nullable()
});

interface ILeadSourceState {
  fetchResult: IAjaxState<LeadSource[]>;
  filterActive: boolean;
  // add/edit modal
  modalIsOpen: boolean;
  addEditLeadSource: LeadSource | undefined;
  saveLeadSourceResults: IAjaxState<LeadSource>;
}

const initialState: ILeadSourceState = {
  fetchResult: managedAjaxUtil.createInitialState(),
  filterActive: true,
  modalIsOpen: false,
  addEditLeadSource: undefined,
  saveLeadSourceResults: managedAjaxUtil.createInitialState()
};

const injectedPropName = "leadSourceService";

class _LeadSourceFreezerService extends FreezerService<ILeadSourceState, typeof injectedPropName> {
  constructor() {
    super(initialState, injectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchLeadSources(includeInactive: boolean = false) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "fetchResult",
      params: {
        includeInactive: includeInactive
      },
      onExecute: (apiOptions, params, options) => {
        const factory = LeadSourceApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getLeadSources(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch sales opportunities.");
      }
    });
  }

  public setFilterActive(filterActive: boolean) {
    this.freezer.get().set({ filterActive });
  }

  public openAddEditModal(leadSource?: LeadSource) {
    this.freezer.get().set({
      modalIsOpen: true,
      addEditLeadSource: leadSource ? _.cloneDeep(leadSource) : {
        startDate: moment().startOf('day').toDate()
      }
    });
  }

  public closeAddEditModal() {
    this.freezer.get().set({
      modalIsOpen: false,
      addEditLeadSource: undefined
    });
  }

  public onChangeLeadSource(leadSource: Partial<LeadSource>) {
    this.freezer.get().addEditLeadSource?.set(leadSource);
  }

  public async saveLeadSource(): Promise<LeadSource | void> {
    const addEditLeadSource = this.freezer.get().addEditLeadSource?.toJS();

    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "saveLeadSourceResults",
      params: {
        body: addEditLeadSource
      },
      onExecute: (apiOptions, params, options) => {
        const factory = LeadSourceApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        if (addEditLeadSource?.id) {
          return factory.updateLeadSource({
            body: addEditLeadSource,
            id: addEditLeadSource.id
          });
        } else {
          return factory.createLeadSource(params);
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save lead source.");
      }
    });
  }
}

export const LeadSourceService = new _LeadSourceFreezerService();
export type ILeadSourceServiceInjectedProps = ReturnType<_LeadSourceFreezerService["getPropsForInjection"]>;
