import {
  React,
  bind,
  _,
  moment
} from "$Imports/Imports";

import {
  Card,
  CardHeader,
  KeyboardDatePicker, 
  IconButton,
  TextField,
  TextFieldProps
} from "$Imports/MaterialUIComponents";

import {
  Search
} from "$Imports/MaterialUIIcons";

import {
  IDataTableColumn,
  DataTable,
  DisplayFormattedDatetime
} from "$Imports/CommonComponents";

import {
  PagingControls
} from "$Shared/components/PagingControls";

import {
  AuditService,
  IAuditServiceInjectedProps
} from "$State/AuditFreezerService";

import {
  AuditSearchCriteria,
  AuditEvent
} from "$Generated/api";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import { DATE_WITH_TIME_MERIDIAN_FORMAT } from "$Shared/utilities/formatUtil";

const styles: {
  datePickers: string
} = require("./AuditViewPage.scss");

const containerStyles: {
  mainContainer: string,
  cardStyle: string,
} = require("$Shared/styles/Modal.scss");


interface IAuditPageViewBaseProps {

}

type IAuditPageViewProps = IAuditPageViewBaseProps & IAuditServiceInjectedProps;

class _AuditViewPage extends React.Component<IAuditPageViewProps> {

  componentDidMount() {
    this.props.AuditService.fetchKeycloakEvents(true);
  }

  private readonly columns: Array<IDataTableColumn<AuditEvent>> = [
    {
      columnName: "eventDateTime",
      headerValue: "Datetime",
      columnFieldData: (e) => <DisplayFormattedDatetime value={new Date(e.eventDateTime + "Z")} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone /> 
    },
    {
      columnName: "user",
      headerValue: "User",
      columnFieldData: (e) => `${e.userFirstName} ${e.userLastName}` 
    },
    {
      columnName: "eventType",
      headerValue: "Event Type",
      columnFieldData: (e) => e.eventType 
    }
  ]

  @bind
  private _onDateChange(date: Date | null, value: string | undefined | null, dateType: "Start" | "End") {
    if (dateType === "Start") {
      let convertedDate = moment(date).startOf('day').toDate();
      this.props.AuditService.updateSearchModel({ startDate: convertedDate });
    } else {
      let convertedDate = moment(date).endOf('day').toDate();
      this.props.AuditService.updateSearchModel({ endDate: convertedDate });
    }
  }

  @bind 
  private _onSearchClick() {
    this.props.AuditService.fetchKeycloakEvents(true);
  }

  @bind
  private _onPageChanged(pageNum: number) {
    this.props.AuditService.searchPage(pageNum);
  }

  render() {

    const {
      auditSearchModel,
      auditSearchModelValidationErrors,
      keycloakEventsFetchResults
    } = this.props.AuditService.getState();

    let eventsFetchResults: AuditEvent[] = keycloakEventsFetchResults.data?.results ?? [];

    let currentPage = 0;
    if (keycloakEventsFetchResults.hasFetched && keycloakEventsFetchResults.data?.totalRecords) {
      let currentStartIndex = (auditSearchModel?.startIndex ?? 0);
      let pageSize = auditSearchModel?.pageSize ?? 0;

      currentPage = currentStartIndex / pageSize + 1;
    }

    const auditSearchValidationParser = new ValidationErrorParser<AuditSearchCriteria>(auditSearchModelValidationErrors);

    return (
      <div className={containerStyles.mainContainer}>
        <Card className={containerStyles.cardStyle}>
          <CardHeader title={"Login History"} />
          <div style={{ padding: "0 10px 10px" }}>
            <KeyboardDatePicker
              value={auditSearchModel?.startDate ?? null}
              onChange={(date: Date | null, value?: string | undefined) => this._onDateChange(date, value, "Start")}
              className={styles.datePickers}
              renderInput={(props: TextFieldProps) =>(<TextField 
                {...props}
                error={!auditSearchValidationParser.isValid("startDate")}
                helperText={auditSearchValidationParser.validationMessage("startDate")}
                style={{ width: "150px", paddingRight: "10px" }}

              />)}              
              inputFormat="MM/DD/YYYY"
              label="Start Date"
            />
            <KeyboardDatePicker
              value={auditSearchModel?.endDate ?? null}
              onChange={(date: Date | null, value?: string | undefined) => this._onDateChange(date, value, "End")}
              className={styles.datePickers}
              inputFormat="MM/DD/YYYY"
              label="End Date"
              renderInput={(props: TextFieldProps) =>(<TextField 
                {...props}
                error={!auditSearchValidationParser.isValid("endDate")}
                helperText={auditSearchValidationParser.validationMessage("endDate")}
                style={{ width: "150px", paddingRight: "10px" }}
              />)}   
              
            />
            <IconButton
              color="primary"
              disabled={keycloakEventsFetchResults.isFetching}
              onClick={this._onSearchClick}
              style={{marginTop:"15px"}}
            >
              <Search />
            </IconButton>

          </div>
          <DataTable 
            columns={this.columns}
            data={eventsFetchResults}
          />
          <PagingControls
            rowCount={keycloakEventsFetchResults.data?.totalRecords ?? 0}
            pageSize={auditSearchModel?.pageSize ?? 0}
            currentPage={currentPage}
            setPage={this._onPageChanged}
          />
        </Card> 
      </div>
    );
  }

}

export const AuditViewPage = AuditService.inject(_AuditViewPage);

