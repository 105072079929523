import {
  React,
  bind
} from "$Imports/Imports";

import {
  ApplicationSetting
} from "$Generated/api";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from "$Imports/MaterialUIComponents";

import {
  AdvanceTextField
} from "$Imports/CommonComponents";

interface IFilteredSitesEditModal {
  defaultFilteredSites?: ApplicationSetting; 
  isModalOpen?: boolean;
  onFilteredSitesChange?: (applicationSetting: Partial<ApplicationSetting>) => void;
  onSaveFilteredSites?: () => void; 
}

export class FilteredSitesEditModal extends React.PureComponent<IFilteredSitesEditModal> {

  @bind
  private _onFilteredSitesChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    if (this.props.onFilteredSitesChange) {
      this.props.onFilteredSitesChange({ settingsValue: event.target.value });
    }
  }

  @bind
  private _onSave() {
    if (this.props.onSaveFilteredSites) {
      this.props.onSaveFilteredSites();
    }
  }

  render() {

    const {
      isModalOpen,
      defaultFilteredSites
    } = this.props;

    return (
      <Dialog open={isModalOpen ?? false}>
      <DialogTitle>
        Filtered Sites
      </DialogTitle>
      <DialogContent>
        <AdvanceTextField 
          label={"Filtered Sites"}
          name={"filteredSites"}
          value={defaultFilteredSites?.settingsValue ?? ""}
          onChange={this._onFilteredSitesChange}
          helperText={"Enter sites in a comma delimited list i.e. SITE1, SITEA,.."}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={this._onSave}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
    )
  }
}