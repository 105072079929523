import {
  React,
  bind
} from "$Imports/Imports";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  Delete
} from "$Imports/MaterialUIIcons";

import {
  RateModelTest
} from "$Generated/api";
import { RateTestTooltip } from "./RateTestTooltip";

const styles: {
  actionButtons: string
} = require("$Shared/styles/Modal.scss");

interface IActionMenuProps {
  data: RateModelTest | undefined;
  includeFuture: boolean;
  onDelete: (data: RateModelTest) => void;
}

export class ActionMenu extends React.PureComponent<IActionMenuProps> {
  @bind
  private _onDeleteClick() {
    if (this.props.data) {
      this.props.onDelete(this.props.data);
    }
  }

  render() {
    const {
      data,
      includeFuture
    } = this.props;

    return (
      this.props.data ?
      <div className={styles.actionButtons}>
        <IconButton
          size={"small"}
          onClick={this._onDeleteClick}
        >
          <Delete />
        </IconButton>
        <RateTestTooltip
          defaultCalculationInfo={data?.defaultCalculationInfo}
          activeCalculationInfo={data?.activeCalculationInfo}
          futureCalculationInfo={data?.futureCalculationInfo}
          sandboxCalculationInfo={data?.sandboxCalculationInfo}
          includeFuture={includeFuture}
        />
      </div>
      : null
    );
  }
}