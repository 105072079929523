import {
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  bind
} from "$Imports/Imports";

import {
  ValidationError,
  SchemaOf
} from "$Shared/imports/Yup";

import {
  AddressAddressTypeEnum,
  PCMilerApiFactory,
  Place
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import yup from "$Shared/utilities/yupExtension";

const InjectedPropName = "CityStateService";

interface ICityStateServiceState {
  searchCriteria: string;
  searchValidationErrors: ValidationError | null;
  searchResults: IAjaxState<Place[]>;
  selectedRow: Place | null;
  
  shipperPlaceResults: IAjaxState<Place[]>;
  consigneePlaceResults: IAjaxState<Place[]>;
}

export interface CityStateSearch {
  cityStateSearchCriteria?: string;
}

const initialState: ICityStateServiceState = {
  searchCriteria: "",
  searchValidationErrors: null,
  searchResults: managedAjaxUtil.createInitialState(),
  selectedRow: null,
  shipperPlaceResults: managedAjaxUtil.createInitialState(),
  consigneePlaceResults: managedAjaxUtil.createInitialState()
}

const CityStateSearchValidationSchema: SchemaOf<CityStateSearch> = yup.object({
  cityStateSearchCriteria: yup.string().required("City, State is required.").min(3, "At least 3 characters are required.").cityState("Invalid City, State search")
});

class CityStateFreezerService extends FreezerService<ICityStateServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public setSelectedRow(selectedRow: Place | null) {
    this.freezer.get().set({ selectedRow });
  }

  public updateSearchCriteria(searchCriteria: string) {
    this.freezer.get().set({ searchCriteria });
  }

  public async onSearchClick() {
    const searchModel = this.freezer.get().searchCriteria;

    const errors = await validateSchema(CityStateSearchValidationSchema, {cityStateSearchCriteria: searchModel}, {
      abortEarly: false
    });

    this.freezer.get().set({ searchValidationErrors: errors });

    if (errors) {
      return;
    }

    this.setSelectedRow(null);

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "searchResults",
      params: {
        body: searchModel
      },
      onExecute: (apiOptions, params, options) => {
        const factory = PCMilerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1PCMilerGetZipCodesByCityStatePost(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch location search results.");
      }
    });
  }

  public async queryForPlace(zipcode: string, whichPlace: AddressAddressTypeEnum) : Promise<Place[] | undefined> {
    // we have to query shippers and consignees separately because two requests could be in flight simultaneously
    if (whichPlace === "Shipper" && this.freezer.get().shipperPlaceResults.isFetching) return;
    if (whichPlace === "Consignee" && this.freezer.get().consigneePlaceResults.isFetching) return;

    const places = await this.validateAndFetchPlace(zipcode, whichPlace);

    return places;
  }

  private async validateAndFetchPlace(zipcode: string, whichPlace: AddressAddressTypeEnum) : Promise<Place[] | undefined> {
    try {
      await yup.string().zipCode().validate(zipcode);

      await managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        ajaxStateProperty: whichPlace === "Shipper" ? "shipperPlaceResults" : "consigneePlaceResults",
        params: {
          body: zipcode
        },
        onExecute: (apiOptions, params, options) => {
          const factory = PCMilerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return factory.apiV1PCMilerGetZipCodesByCityStatePost(params);
        },
        onError: (err, errorMessage) => {
          ErrorService.pushErrorMessage("Failed to fetch location search results.");
        }
      });

      if (whichPlace === "Shipper") {
        if (this.freezer.get().shipperPlaceResults.hasFetched && !this.freezer.get().shipperPlaceResults.error) {
          return this.freezer.get().shipperPlaceResults.data?.toJS();
        }
      }
      else if (whichPlace === "Consignee") {
        if (this.freezer.get().consigneePlaceResults.hasFetched && !this.freezer.get().consigneePlaceResults.error) {
          return this.freezer.get().consigneePlaceResults.data?.toJS();
        }
      }
    }
    catch {
      // ignore
    }

    return undefined;
  }
}

export const CityStateService = new CityStateFreezerService();
export type ICityStateFreezerServiceInjectedProps = ReturnType<CityStateFreezerService["getPropsForInjection"]>;