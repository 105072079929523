import {
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf
} from "$Shared/imports/Yup";

import {
  AccessorialCharge,
  AccessorialChargeValue,
  AccessorialChargeValueStatusEnum
} from "$Generated/api";

const AccessorialChargeValidationSchema: SchemaOf<NullableOptional<AccessorialCharge>> = yup.object({
  id: yup.number().notRequired(),
  description: yup.string()
    .required("Description is required")
    .max(40, "Description cannot be longer than 40 characters"),
  tmChargeCode: yup.string()
    .required("Charge code is required")
    .max(10, "Charge code cannot be longer than 10 characters"),
  isActive: yup.boolean().notRequired(), // technically it is required but it's a boolean
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  accessorialChargeValues: yup.array().notRequired()
});

const AccessorialChargeValueValidationSchema: SchemaOf<NullableOptional<AccessorialChargeValue>> = yup.object({
  id: yup.number().notRequired(),
  accessorialChargeId: yup.number().required("Charge Description is required"),
  quoteId: yup.number().notRequired(),
  amount: yup.number().required("Amount is required").test("amount", "${message}", (value: number | undefined, testContext: any) => {
    if (!value) {
      return testContext.createError({ message: "Amount is required" });
    }
    return true;
  }),
  status: yup.mixed<AccessorialChargeValueStatusEnum>().oneOf(["Pending", "Approved"]).notRequired(),
  notes: yup.string().notRequired().max(40, "Notes cannot be longer than 40 characters"),
  createdOn: yup.date().notRequired(),
  createdById: yup.number().notRequired(),
  quote: yup.object().notRequired(),
  accessorialCharge: yup.object().notRequired()
});

export {
  AccessorialChargeValidationSchema,
  AccessorialChargeValueValidationSchema
}
