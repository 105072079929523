import {
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  EquipmentType
} from "$Generated/api";

import {
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControlLabel,
  Switch
} from "$Imports/MaterialUIComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

const styles: {
  modalContainer: string;
} = require("$Shared/styles/Modal.scss");

interface IEditEquipmentTypeProps {
  isOpen: boolean;
  equipmentType: EquipmentType;
  validationErrors: ValidationError | null;
  canSave: boolean;
  onSave: () => void;
  onCancel: () => void;
  onChange: (value: Partial<EquipmentType>) => void;
}

export class EditEquipmentTypeModal extends React.PureComponent<IEditEquipmentTypeProps> {

  @bind
  private _onSave() {
    this.props.onSave();
  }

  @bind
  private _onCancel() {
    this.props.onCancel();
  }

  @bind
  private _onActiveChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.onChange({ isActive: checked });
  }

  @bind
  private _onTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    const eventName = event.target.name;
    const eventValue = event.target.value;

    if (eventName === "name") {
      this.props.onChange({ name: eventValue });
    }
    else if (eventName === "tmEquipmentCode") {
      this.props.onChange({ tmEquipmentCode: eventValue });
    }
  }

  render() {
    const {
      isOpen,
      equipmentType,
      validationErrors,
      canSave
    } = this.props;

    const validationsParser = new ValidationErrorParser<EquipmentType>(validationErrors);

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
          {equipmentType.id ? "Edit Equipment" : "Add Equipment"}
        </DialogTitle>
        <DialogContent
          className={styles.modalContainer}
        >
          <FormControlLabel
            control={
              (
                <Switch
                  color="primary"
                  checked={equipmentType.isActive ?? false}
                  onChange={this._onActiveChange}
                />
              )
            }
            label="Active"
          />
          <AdvanceTextField
            label="Name"
            name="name"
            value={equipmentType.name ?? ""}
            onChange={this._onTextChange}
            error={!validationsParser.isValid("name")}
            helperText={validationsParser.validationMessage("name")}
          />
          <AdvanceTextField
            label="TruckMate Equipment ID"
            name="tmEquipmentCode"
            value={equipmentType.tmEquipmentCode ?? ""}
            onChange={this._onTextChange}
            error={!validationsParser.isValid("tmEquipmentCode")}
            helperText={validationsParser.validationMessage("tmEquipmentCode")}
            inputProps={{ maxLength: 10 }}
            style={{ marginTop: "4px", width: "250px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._onCancel}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this._onSave}
            disabled={!canSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}