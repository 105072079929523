import {
  React
} from "$Imports/Imports";


import {
  QuoteStatusEnum
} from "$Generated/api";

interface IQuoteStatus {
  quoteStatus: QuoteStatusEnum
};

export class QuoteStatus extends React.PureComponent<IQuoteStatus> {
  render() {
    const {
      quoteStatus
    } = this.props;

    const statusSwitch = (status: QuoteStatusEnum) => {
      switch(status) {
        case "ApprovalNeeded":
          return "Approval Needed";
        case "InProgress":
          return "In Progress";
        case "PendingNeedsCustomers":
          return "Pending";
        case "AcceptanceRejected":
          return "Acceptance Rejected";
        case "PendingResave":
          return "Pending Re-save";
        default:
          return status;
      }
    }

    return (
      <>
        {statusSwitch(quoteStatus)}
      </>
    );
  }
}