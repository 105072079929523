import {
  React,
  bind,
  _,
  moment
} from "$Imports/Imports";

import {
  CarrierMetricsParameters
} from "$Generated/api";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "$Imports/MaterialUIComponents";

import {
  CardLinedHeader,
  MetricCell,
  DisplayFormattedNumber,
  ToggleBox,
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  KPIDatePreset
} from "$State/SalesRepHomeFreezerService";

import {
  CarrierService,
  ICarrierServiceInjectedProps
} from "$State/CarrierFreezerService";

import {
  PERCENTAGE_ONE_DECIMAL
} from "$Shared/utilities/formatUtil";

import {
  KPIDateForm
} from "../SalesRepHomeView/MyMetrics/KPIDateForm";

import { 
  CompanyEnum 
} from "$Utilities/companyUtil";

const metricTypes = ["Just Me", "Everyone"];
type MetricType = typeof metricTypes[number];

type MarketFilter = "Primary" | "Secondary" | "Both";

interface ICarrierKPIFilter {
  preset: KPIDatePreset;
  startDate?: Date;
  endDate?: Date;
}

interface ICarrierMetricsBaseProps {
  currentUserId: number | undefined;
  style?: React.CSSProperties;
}

type ICarrierMetricsProps = ICarrierMetricsBaseProps
  & ICarrierServiceInjectedProps;

interface ICarrierMetricsState {
  selectedMetricType: MetricType;
  kpiFilters: ICarrierKPIFilter;
  marketFilter: MarketFilter;
  filtersChanged: boolean;
}

class _CarrierMetrics extends React.Component<ICarrierMetricsProps, ICarrierMetricsState> {
  state: ICarrierMetricsState = _CarrierMetrics.getInitialFilterState();

  componentDidUpdate(prevProps: ICarrierMetricsBaseProps) {
    if (prevProps.currentUserId != this.props.currentUserId) {
      this.fetchMetrics();
    }
  }

  componentWillUnmount() {
    if (!this.state.filtersChanged) {
      return;
    }

    // re-fetch with initial filters to ensure metrics match on next navigation back to this page
    const initialFilterState = _CarrierMetrics.getInitialFilterState();

    this.props.carrierService.fetchCarrierMetrics({
      metricType: initialFilterState.selectedMetricType,
      employeeId: this.props.currentUserId,
      marketFilter: initialFilterState.marketFilter,
      startDate: initialFilterState.kpiFilters.startDate,
      endDate: initialFilterState.kpiFilters.endDate
    });
  }

  private static getInitialFilterState = (): ICarrierMetricsState => ({
    selectedMetricType: "Just Me",
    kpiFilters: {
      preset: "Week",
      startDate: moment().startOf("week").toDate(),
      endDate: moment().endOf("week").toDate()
    },
    marketFilter: "Secondary",
    filtersChanged: false
  });

  @bind
  private fetchMetrics() {
    const {
      currentUserId
    } = this.props;
    const {
      selectedMetricType,
      marketFilter,
      kpiFilters
    } = this.state;

    if (!kpiFilters.startDate || !kpiFilters.endDate) {
      return;
    }

    const filters: CarrierMetricsParameters = {
      metricType: selectedMetricType,
      employeeId: currentUserId,
      marketFilter: marketFilter,
      startDate: kpiFilters.startDate,
      endDate: kpiFilters.endDate
    }

    this.props.carrierService.fetchCarrierMetrics(filters);
  }

  @bind
  selectMetricType(metricType: string) {
    if (metricType === this.state.selectedMetricType) {
      return;
    }

    this.setState({
      selectedMetricType: metricType as MetricType,
      filtersChanged: true
    }, this.fetchMetrics);
  }

  @bind
  selectMarketFilter(marketFilter: MarketFilter) {
    if (marketFilter !== this.state.marketFilter) {
      this.setState({
        marketFilter: marketFilter,
        filtersChanged: true
      }, this.fetchMetrics);
    }
  }

  @bind
  private onKPIFilterChange(startDate: Date | null, endDate: Date | null, preset: KPIDatePreset): void {
    this.setState({
      kpiFilters: {
        startDate: startDate ?? undefined,
        endDate: endDate ?? undefined,
        preset: preset
      },
      filtersChanged: true
    }, this.fetchMetrics);
  }

  render() {
    const {
      selectedMetricType,
      kpiFilters,
      marketFilter
    } = this.state;

    const {
      quoteCarrierFetchResults,
      carrierMetricsFetchResults
    } = this.props.carrierService.getState();

    var carrierData = quoteCarrierFetchResults.data ?? [];

    // comparing directly to booleans for typescript reasons
    if (marketFilter === "Primary") {
      carrierData = _.filter(carrierData, qc => qc.isMarketPrimary === true);
    }
    else if (marketFilter === "Secondary") {
      carrierData = _.filter(carrierData, qc => qc.isMarketPrimary === false);
    }

    // available count is not affected by just me/everyone
    const availableCount = _.filter(carrierData, qc => qc.carrierStatus === "Available").length;

    if (selectedMetricType === "Just Me" && this.props.currentUserId) {
      carrierData = _.filter(carrierData, qc => qc.carrierRepId === this.props.currentUserId);
    }

    const takeRate = carrierMetricsFetchResults.data?.takeRate ?
      <DisplayFormattedNumber value={carrierMetricsFetchResults.data.takeRate} formatString={PERCENTAGE_ONE_DECIMAL} />
      : "";

    const assignedCount = _.filter(carrierData, qc => qc.carrierStatus === "Assigned").length;
    const pickedCount = _.filter(carrierData, qc => qc.carrierStatus === "Picked").length;

    return (
      <CardLinedHeader
        titleText={"Carrier Metrics"}
        style={{ flex: 4 }}
        titleComponents={
          <ToggleBox
            selectedValue={selectedMetricType}
            options={metricTypes}
            toggleClick={this.selectMetricType}
            companyId={CompanyEnum.Logistics}
          />
        }
      >
        <AjaxActionIndicator state={[carrierMetricsFetchResults]} />
        <div style={{ display: "flex" }}>
          <div style={{ flexBasis: "385px" }}>
            <KPIDateForm
              preset={kpiFilters.preset}
              startDate={kpiFilters.startDate ?? null}
              endDate={kpiFilters.endDate ?? null}
              onChange={this.onKPIFilterChange}
            />
          </div>
          <FormControl style={{ flexBasis: "120px" }}>
            <InputLabel></InputLabel>
            <Select
              value={marketFilter}
              onChange={(event) => this.selectMarketFilter(event.target.value as MarketFilter)}
            >
              <MenuItem value="Primary">Primary</MenuItem>
              <MenuItem value="Secondary">Secondary</MenuItem>
              <MenuItem value="Both">Both</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Grid
          container
          justifyContent="space-between"
          columnSpacing={2}
          rowSpacing={2}
          style={{ marginTop: "5px" }}
        >
          <MetricCell
            title="Take Rate"
            majorValue={takeRate}
            xs={6}
          />

          <MetricCell
            title="Available Shipments"
            majorValue={`${availableCount}`}
            xs={6}
          />

          <MetricCell
            title="Assigned Shipments"
            majorValue={`${assignedCount}`}
            xs={6}
          />

          <MetricCell
            title="Picked Shipments"
            majorValue={`${pickedCount}`}
            xs={6}
          />
        </Grid>
      </CardLinedHeader>
    );
  }
}

export const CarrierMetrics = CarrierService.inject(
  _CarrierMetrics
);