import {
  React,
  bind,
  moment,
  _,
  numeral,
  Helpers,
  memoizeOne
} from "$Imports/Imports";

import {
  DataTable,
  IDataTableColumn,
  AjaxActionIndicator,
  directionType,
  DisplayFormattedDatetime,
  BinaryChoiceDialog
} from "$Imports/CommonComponents";

import {
  Card,
  CardActions,
  CardHeader,
  FormControlLabel,
  Switch
} from "$Imports/MaterialUIComponents";

import {
  Commodity,
  CommodityRate
} from "$Generated/api";

import {
  ICommodityRateServiceInjectedProps,
  CommodityRateService
} from "$State/CommodityRateFreezerService";

import {
  ICommodityServiceInjectedProps,
  CommodityService
} from "$State/CommodityFreezerService";

import {
  ActionMenu
} from "./ActionMenu";

import {
  EditCommodityRate
} from "./EditCommodityRate";

import {
  getTimeZone
} from "$Shared/utilities/dateTimeUtil";

import { DATE_WITH_TIME_MERIDIAN_FORMAT } from "$Shared/utilities/formatUtil";

const styles: {
  mainContainer: string;
  actionArea: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface ICommodityRatesViewState {
  undoModalOpen: boolean;
  undoMessage: string;
  undoRow: any;
}

interface ICommodityRatesViewBaseProps {
  companyId: number | undefined;
}

type ICommodityRatesViewProps = ICommodityRatesViewBaseProps
  & ICommodityRateServiceInjectedProps
  & ICommodityServiceInjectedProps;

class _CommodityRatesView extends React.Component<ICommodityRatesViewProps, ICommodityRatesViewState> {
  state = {
    undoModalOpen: false,
    undoMessage: "",
    undoRow: {} as any
  };

  private readonly _filterSortCommodity_Memoized = memoizeOne((commodities: Commodity[]): Commodity[] => { 
    return _.sortBy( _.filter(commodities, c => !!c.isActive), c => c.commodityName?.toLowerCase());
  });

  private readonly baseColumns: Array<IDataTableColumn<CommodityRate>> = [
    {
      columnName: "originZone",
      columnFieldData: (d) => d.zoneName ?? "",
      headerValue: "Origin Zone",
      sortMethod: (d) => d.zoneName,
    },
    {
      columnName: "startDateTime",
      columnFieldData: (d) => <DisplayFormattedDatetime value={d.startDateTime} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />,
      headerValue: "Start Date",
      sortMethod: (d) => d.startDateTime ?? ""
    },
    {
      columnName: "endDateTime",
      columnFieldData: (d) => <DisplayFormattedDatetime value={d.endDateTime} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />,
      headerValue: "End Date",
      sortMethod: (d) => d.endDateTime ?? ""
    },
    // dynamically generated commodity rate item columns will go here
  ];

  componentDidMount() {
    this.props.commodityRateService.clearFreezer();
    this.props.commodityRateService.fetchCommodityRates(this.props.companyId, true);
    this.props.commodityService.fetchCommodities(this.props.companyId);
  }

  componentDidUpdate(prevProps: ICommodityRatesViewProps) {
    if (this.props.companyId !== prevProps.companyId) {
      this.props.commodityRateService.clearFreezer();
      this.props.commodityRateService.fetchCommodityRates(this.props.companyId, true);
      this.props.commodityService.fetchCommodities(this.props.companyId);
    }
  }

  @bind
  private _onShowHistoryChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.commodityRateService.setShowHistory(this.props.companyId, checked);
  }

  @bind
  private _onSortChange(event: React.MouseEvent<HTMLElement>, columnName: string | undefined, direction: directionType) {
    this.props.commodityService.setSortState({
      sortColumnName: columnName,
      sortDirection: direction,
    });
  }

  @bind
  private _onMenuItemSelected(taskName: string, commodityRate: CommodityRate) {
    const {
      commodityFetchResults
    } = this.props.commodityService.getState();

    var commodities: Commodity[] = commodityFetchResults.data ?? [];
    commodities = _.filter(commodities, c => !!c.isActive);

    if (taskName === "edit") {
      this.props.commodityRateService.editCommodityRate(this.props.companyId, commodities, commodityRate);
    }
    else if (taskName === "undo") {
      let datestamp = "";
      if (commodityRate.startDateTime) {
        datestamp = moment(commodityRate.startDateTime).local().format(DATE_WITH_TIME_MERIDIAN_FORMAT) + " " + getTimeZone(commodityRate.startDateTime);
      }
      this.setState({
        undoModalOpen: true,
        undoMessage: `Click Continue to remove the future commodity rates starting on ${datestamp}.`,
        undoRow: commodityRate
      });
    }
  }

  @bind
  private _confirmUndo(answer: boolean) {
    if (answer) {
      this.props.commodityRateService.undoCommodityRates(this.props.companyId, this.state.undoRow);
    }

    this.setState({
      undoModalOpen: false,
      undoMessage: "",
      undoRow: undefined
    });
  }

  @bind
  private _copyCurrentRates() {
    this.props.commodityRateService.copyCurrentRates();
  }

  @bind
  private _onPercentRateChange(commodityId: number, value?: number) {
    this.props.commodityRateService.onPercentRateChanged(commodityId, value);
  }

  @bind
  private _onStartDateChanged(date: Date) {
    this.props.commodityRateService.onStartDateChanged(date);
  }

  @bind
  private _onImmediateStartChanged(immediateStart: boolean) {
    this.props.commodityRateService.onImmediateStartChanged(immediateStart);
  }
  
  @bind
  private _onSave() {
    this.props.commodityRateService.saveCommodityRates(this.props.companyId);
  }

  @bind
  private _onCancel() {
    this.props.commodityRateService.clearEditForm();
  }

  @bind
  private getCellValue(viewModel: CommodityRate, ri: number) {
    if (viewModel.commodityValues !== undefined && !Helpers.isNullOrUndefined(viewModel.commodityValues[ri])) {
      return numeral(viewModel.commodityValues[ri].percentRate).format("0.00%")
    }

    return "n/a";
  }

  @bind
  private showUndo(viewModel: CommodityRate): boolean {
    const {
      commodityVMFetchResults
    } = this.props.commodityRateService.getState();
    const viewModels = commodityVMFetchResults.data ?? [];

    var showUndo = viewModel.endDateTime === null && moment().isBefore(viewModel.startDateTime);
    // this doesn't necessarily find the previous row
    // but all we need to know is that *a* previous row exists
    var hasPrevious = _.find(viewModels, vm => {
      return viewModel.zoneId === vm.zoneId && moment(vm.startDateTime).isBefore(viewModel.startDateTime);
    });
    showUndo = showUndo && hasPrevious !== undefined;

    return showUndo;
  }

  @bind
  private buildColumns(commodities: Commodity[]): IDataTableColumn<CommodityRate>[] {
    var columns = _.cloneDeep(this.baseColumns);

    _.forEach(commodities, c => {
      if (c.id !== undefined) {
        var commodityIdKey = `commodity_${c.id}`;

        columns.push({
          columnName: commodityIdKey,
          columnFieldData: (d) => this.getCellValue(d, c.id as number), // it's definitely not undefined
          headerValue: c.commodityName ?? "",
          sortMethod: (d) => this.getCellValue(d, c.id as number)
        });
      }
    });

    columns.push({
      columnName: "action",
      columnFieldData: (d) => (
        <ActionMenu
          commodityRate={d}
          showUndo={this.showUndo(d)}
          onMenuItemClick={this._onMenuItemSelected}
        />
      ),
      headerValue: "",
    });

    return columns;
  }

  render() {
    const {
      showHistory,
      sortState,
      editState,
      commodityVMFetchResults
    } = this.props.commodityRateService.getState();
    
    const viewModels = commodityVMFetchResults.data ?? [];

    const {
      commodityFetchResults,
      commodityAddResults,
      commodityUpdateResults
    } = this.props.commodityService.getState();

    const isFetching = commodityFetchResults.isFetching || commodityAddResults.isFetching || commodityUpdateResults.isFetching;

    const commodities = this._filterSortCommodity_Memoized(commodityFetchResults.data ?? []);   

    const columns = this.buildColumns(commodities);

    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader
            title="Commodity Rates"
          />
          <CardActions
            disableSpacing={true}
            className={styles.actionArea}
          >
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={showHistory}
                    onChange={this._onShowHistoryChange}
                  />
                )
              }
              label="Show History"
            />
          </CardActions>
          <AjaxActionIndicator
            state={[commodityVMFetchResults, commodityFetchResults]}
          />
          <DataTable
            columns={columns}
            data={viewModels}
            defaultSortColumnName={sortState.sortColumnName}
            defaultSortDirection={sortState.sortDirection}
            onSortChange={this._onSortChange}
          />
        </Card>
        <EditCommodityRate
          commodities={commodities}
          editState={editState}
          isFetching={isFetching}
          copyCurrentRates={this._copyCurrentRates}
          percentRateChanged={this._onPercentRateChange}
          startDateChanged={this._onStartDateChanged}
          immediateStartChanged={this._onImmediateStartChanged}
          onSave={this._onSave}
          onCancel={this._onCancel}
        />
        <BinaryChoiceDialog
          isOpen={this.state.undoModalOpen}
          message={this.state.undoMessage}
          trueText={"Continue"}
          falseText={"Cancel"}
          onClick={this._confirmUndo}
        />
      </div>
    );
  }
}

export const CommodityRatesView =
  CommodityRateService.inject(
    CommodityService.inject(
      _CommodityRatesView
    )
  );
