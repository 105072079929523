import {
  React
} from "$Imports/Imports";

import {
  QuoteStop
} from "$Generated/api";

import {
  StopFlagErrorMessage
} from "./StopFlagErrorMessage";

interface IOwnProps {
  quoteStop: QuoteStop
}

const StopFlagDisplay = (props: IOwnProps) => {
  const {
    quoteStop
  } = props;

  const isLayover = quoteStop.isConsigneeLayover || quoteStop.isShipperLayover;
  const isExpedited = quoteStop.isConsigneeExpedited || quoteStop.isShipperExpedited;

  return (
    <>
      {isLayover ? (
        <StopFlagErrorMessage 
          message={`Stop ${quoteStop.stopNumber ?? ""}: Potential Layover`}
        />
      ) : null }
      {isExpedited ? (
        <StopFlagErrorMessage 
          message={`Stop ${quoteStop.stopNumber ?? ""}: Expedited ${ quoteStop.isShipperExpedited ? "Pickup": "Drop-off" }`}
        />
      ) : null }
    </>
  )
}

export {
  StopFlagDisplay
}