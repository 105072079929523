import {
    React,
    bind,
    Helpers
  } from "$Imports/Imports";
  
  import {
    Edit,
    Delete
  } from "$Imports/MaterialUIIcons";
  
  import {
    IconButton
  } from "$Imports/MaterialUIComponents";
  
  import {
    PerStopFee
  } from "$Generated/api";
  
  
  interface IActionMenuState {
  }
  
  interface IActionMenuProps {
    perStopFee: PerStopFee;
    onEdit: (perStopFee: PerStopFee) => void;
  }
  
  export class ActionMenu extends React.PureComponent<IActionMenuProps, IActionMenuState> {
  
    @bind
    private _onEdit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
      this.props.onEdit(this.props.perStopFee);
    }
  
    render() {
      const canEdit = Helpers.isNullOrUndefined(this.props.perStopFee?.endDateTime);
  
      return (
        <>
          { canEdit &&
            <IconButton
              size="small"
              onClick={this._onEdit}
            >
              <Edit />
            </IconButton> }
        </>
      );
    }
  }
  