import {
  React,
  bind
} from "$Imports/Imports";

import {
  Paper,
  Typography,
  IconButton,
  Popper
} from "$Imports/MaterialUIComponents"

import {
  Message
} from "$Imports/MaterialUIIcons"

const styles: {
  reasonTextContainer: string
} = require("./CapacityRateView.scss");

interface ICapacityReasonToolTipBaseProp {
  capacityReason: string
}

interface ICapacityReasonToolTipState {
  displayReason: boolean,
  reasonAnchorEl: HTMLElement | null
}

export class CapacityReasonToolTip extends React.PureComponent<ICapacityReasonToolTipBaseProp, ICapacityReasonToolTipState> {

  state: ICapacityReasonToolTipState = {
    displayReason: false,
    reasonAnchorEl: null
  }

  @bind
  private _onReasonMouseEnter(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({
      displayReason: true,
      reasonAnchorEl: e.currentTarget
    });
  }

  @bind
  private _onReasonMouseLeave(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({
      displayReason: false,
      reasonAnchorEl: null
    })
  }

  render() {

    const {
      capacityReason
    } = this.props

    return (
      <>
        <Popper
          open={this.state.displayReason && capacityReason !== ""}
          placement="left-start"
          anchorEl={this.state.reasonAnchorEl}
        >
          <Paper className={styles.reasonTextContainer}>
            <Typography>{capacityReason ?? ""}</Typography>
          </Paper>
        </Popper>
        <IconButton onMouseEnter={this._onReasonMouseEnter} onMouseLeave={this._onReasonMouseLeave}>
          <Message />
        </IconButton>      
      </>
    );
  }

}