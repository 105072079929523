import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState
} from "$Imports/Imports";

import {
  ISortState
} from "$Imports/CommonComponents";

import {
  Reminder,
  ReminderApiFactory,
  ReminderSearchCriteria,
  ReminderTypeEnum
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

interface IReminderState {
  searchCriteria: ReminderSearchCriteria;
  sort: ISortState;
  showCompleted: boolean;
  fetchResult: IAjaxState<Reminder[]>;
  createResult: IAjaxState<Reminder>;
  updateResult: IAjaxState<Reminder>;
  deleteResult: IAjaxState<void>;
  setCompleteResult: IAjaxState<void>;
}

const injectedPropName = "reminderService";

// there is no method available to morph a string union type (ReminderTypeEnum) to a string array
export const REMINDER_TYPES: string[] = ["General", "Activity", "Contact", "Quote", "Sales"];

// prevent controlled/uncontrolled input warnings
export const EMPTY_REMINDER: Reminder = {
  id: 0,
  text: "",
  type: "" as ReminderTypeEnum
};

export const EMPTY_REMINDER_SEARCH: ReminderSearchCriteria = {
  search: "",
  type: "" as ReminderTypeEnum,
  customerId: undefined,
  quoteId: undefined,
  startDate: undefined,
  endDate: undefined,
  createdById: undefined
};

const initialState: IReminderState = {
  searchCriteria: { ...EMPTY_REMINDER_SEARCH },
  sort: {
    sortColumnName: "dueDate",
    sortDirection: "asc"
  },
  showCompleted: false,
  fetchResult: managedAjaxUtil.createInitialState(),
  createResult: managedAjaxUtil.createInitialState(),
  updateResult: managedAjaxUtil.createInitialState(),
  deleteResult: managedAjaxUtil.createInitialState(),
  setCompleteResult: managedAjaxUtil.createInitialState()
};

class _ReminderFreezerService extends FreezerService<IReminderState, typeof injectedPropName> {
  constructor() {
    super(initialState, injectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  @bind
  public clearSearchCriteria() {
    this.freezer.get().searchCriteria.set({ ...EMPTY_REMINDER_SEARCH });
  }

  @bind
  public update(newState: Partial<Omit<IReminderState, "fetchResult" | "createResult" | "updateResult" | "deleteResult" | "setCompleteResult">>) {
    this.freezer.get().set(newState);
  }

  @bind
  public fetchReminders(forceUpdate: boolean = false) {
    const {
      searchCriteria,
      fetchResult
    } = this.freezer.get();

    if (fetchResult.hasFetched && !forceUpdate) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "fetchResult",
      params: {
        body: searchCriteria.toJS()
      },
      onExecute: (apiOptions, params, options) => {
        const factory = ReminderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1ReminderSearchPost(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch reminders.");
      }
    })
  }

  @bind
  public createReminder(model: Reminder) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "createResult",
      params: {
        body: model
      },
      onExecute: (apiOptions, params, options) => {
        const factory = ReminderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1ReminderPost(params);
      },
      onOk: () => {
        ErrorService.pushErrorMessage("Reminder set.", "successful");
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to create reminder.");
      }
    });
  }

  @bind
  public updateReminder(id: number, model: Reminder) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "updateResult",
      params: {
        id: id,
        body: model
      },
      onExecute: (apiOptions, params, options) => {
        const factory = ReminderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1ReminderIdPut(params);
      },
      onOk: () => {
        ErrorService.pushErrorMessage("Reminder updated.", "successful");
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to update reminder.");
      }
    });
  }

  @bind
  public deleteReminder(id: number) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "deleteResult",
      params: {
        id: id
      },
      onExecute: (apiOptions, params, options) => {
        const factory = ReminderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1ReminderIdDelete(params);
      },
      onOk: () => {
        ErrorService.pushErrorMessage("Reminder deleted.", "successful");
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to delete reminder.");
      }
    });
  }

  @bind
  public setReminderToComplete(id: number) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "setCompleteResult",
      params: {
        id: id
      },
      onExecute: (apiOptions, params, options) => {
        const factory = ReminderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1ReminderIdCompletePost(params);
      },
      onOk: () => {
        ErrorService.pushErrorMessage("Reminder marked complete.", "successful");
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to mark reminder as complete.");
      }
    });
  }
}

export const ReminderService = new _ReminderFreezerService();
export type IReminderServiceInjectedProps = ReturnType<_ReminderFreezerService["getPropsForInjection"]>;
