import {
  React,
  bind
} from "$Imports/Imports";

import {
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Edit,
  Undo
} from "$Imports/MaterialUIIcons";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  TarpValue,
  Tarp
} from "$Generated/api";

interface IActionMenuState {
}

interface IActionMenuProps {
  tarpValue: TarpValue;
  showUndo: boolean;
  onMenuItemClick: (taskName: string, tarpValue: TarpValue) => void;
}

export class ActionMenu extends React.PureComponent<IActionMenuProps, IActionMenuState> {
  @bind
  private _onEditClick() {
    this.props.onMenuItemClick("edit", this.props.tarpValue);
  }

  @bind
  private _onUndoClick() {
    this.props.onMenuItemClick("undo", this.props.tarpValue);
  }

  render() {
    const {
      tarpValue,
      showUndo
    } = this.props;

    var now = new Date();
    now.setSeconds(0);

    return (
      <>
        <UserAccessControl roles={["tarp-rate:delete"]}>
          {showUndo ?
          <IconButton
            size="small"
            onClick={this._onUndoClick}
          >
            <Undo />
          </IconButton>
          : <div style={{width: "30px", display: "inline-block"}}></div>}
        </UserAccessControl>
        <UserAccessControl roles={["tarp-rate:edit"]}>
          {tarpValue.endDateTime == null &&
          <IconButton
            size="small"
            onClick={this._onEditClick}
          >
            <Edit />
          </IconButton>}
        </UserAccessControl>
      </>
    );
  }
}
