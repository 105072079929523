import {
  React,
  bind,
  _,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  RateEngineAuditSearchCriteria
} from "$Generated/api";

import {
  Card,
  CardActions,
  CardHeader,
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  DataGridPro,
  GridSortModel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Checkbox,
  ListItemText
} from "$Imports/MaterialUIComponents";

import {
  AjaxActionIndicator,
  DisplayFormattedDatetime,
  SearchControlsContainer,
  DateRangePicker,
  AdvanceTextField,
  TextCellTruncated
} from "$Imports/CommonComponents";

import {
  DATE_WITH_TIME_MERIDIAN_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  IAuditRateEngineServiceInjectedProps,
  AuditRateEngineService,
  RateEngineAuditSearchValidationSchema
} from "$State/AuditRateEngineFreezerService";

import {
  IEmployeeServiceInjectedProps,
  EmployeeService
} from "$State/EmployeeFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  CompanyName
} from "./CompanyName";

const styles: {
  mainContainer: string;
  cardStyle: string;
  gridContainer: string;
  searchContainer: string;
} = require("./AuditRateEngineView.scss");

interface IAuditRateEngineViewPageBaseProps {

}

type IAuditRateEngineViewPageProps = IAuditRateEngineViewPageBaseProps
  & IAuditRateEngineServiceInjectedProps
  & IEmployeeServiceInjectedProps;

interface IAuditRateEngineViewPageState {
  criteria: RateEngineAuditSearchCriteria;
  errors: ValidationError | null;
}

class _AuditRateEngineViewPage extends React.Component<IAuditRateEngineViewPageProps, IAuditRateEngineViewPageState> {

  state: IAuditRateEngineViewPageState = {
    criteria: {
      sortColumn: "createdOn",
      sortAscending: true,
      startDate: moment().startOf("day").toDate(),
      endDate: moment().endOf("day").toDate()
    },
    errors: null
  };

  private readonly columns: GridColDef[] = [
    {
      headerName: "Datetime",
      field: "createdOn",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date | undefined>) =>
        <DisplayFormattedDatetime value={params.value} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} />,
    },
    {
      headerName: "User",
      field: "employee",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.value.firstName} ${params.value.lastName}`;
      }
    },
    {
      headerName: "Company",
      field: "companyId",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.log.companyId;
      },
      renderCell: (params: GridRenderCellParams<number>) => {
        return <CompanyName companyId={params.value} />
      }
    },
    {
      headerName: "Event Type",
      field: "eventType",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.log.eventType;
      }
    },
    {
      headerName: "Element",
      field: "element",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.log.element;
      },
      renderCell: (params: GridRenderCellParams<string>) => {
        return params.value ?? "N/A";
      }
    },
    {
      headerName: "Change",
      field: "change",
      flex: 3,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.log.change;
      },
      renderCell: (params: GridRenderCellParams<string>) => {
        return params.value ? <TextCellTruncated text={params.value} /> : "N/A";
      }
    }
  ];

  componentDidMount() {
    this.props.employeeService.fetchAllEmployees();
    this.props.auditRateEngineService.fetchEventTypes();
    this._onSearchClick(); // initial load
  }

  @bind
  private async _onSearchClick() {
    const errors = await validateSchema(RateEngineAuditSearchValidationSchema, this.state.criteria);
    this.setState({ errors: errors });

    if (errors) {
      return;
    }

    this.props.auditRateEngineService.fetchAuditLogs(this.state.criteria);
  }

  @bind
  private _onClearSearch() {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        startDate: undefined,
        endDate: undefined,
        employeeId: undefined,
        eventTypes: undefined,
        elementOrChange: undefined
      }
    }), this._onSearchClick);
  }

  @bind
  private _onSortChange(sortModel: GridSortModel) {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        sortColumn: sortModel[0].field,
        sortAscending: sortModel[0].sort === "asc"
      }
    }), this._onSearchClick);
  }

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        startDate: start ? moment(start).startOf('day').toDate() : undefined,
        endDate: end ? moment(end).endOf('day').toDate() : undefined
      }
    }));
  }

  @bind
  private _onUserChange(event: SelectChangeEvent<number[]>) {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        employeeIds: event.target.value as number[]
      }
    }));
  }

  @bind
  private _onEventTypeChange(event: SelectChangeEvent<string[]>) {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        eventTypes: event.target.value as string[]
      }
    }));
  }

  @bind
  private _onElementChange(event: React.ChangeEvent<{ name: string; value: string; }>) {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        elementOrChange: event.target.value
      }
    }));
  }

  @bind
  private _searchFieldOnKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      this._onSearchClick();
    }
  }

  render() {
    const {
      auditFetchResults,
      eventTypesFetchResults
    } = this.props.auditRateEngineService.getState();
    const auditLines = auditFetchResults.data ?? [];
    const eventTypes = eventTypesFetchResults.data ?? [];

    const {
      employeeFetchResults
    } = this.props.employeeService.getState();
    const employees = employeeFetchResults.data ?? [];

    const {
      criteria,
      errors
    } = this.state;

    const sortModel: GridSortModel = [{
      field: criteria.sortColumn ?? "createdOn",
      sort: criteria.sortAscending ? "asc" : "desc"
    }];

    const validationParser = new ValidationErrorParser<RateEngineAuditSearchCriteria>(errors);

    return (
      <div className={styles.mainContainer}>
        <Card className={styles.cardStyle}>
          <CardHeader
            title="Rate Engine Audit Log"
          />
          <CardActions>
            <SearchControlsContainer
              className={styles.searchContainer}
              onSubmit={this._onSearchClick}
              onClear={this._onClearSearch}
            >
              <DateRangePicker
                startDate={criteria.startDate}
                startError={validationParser.validationMessage("startDate")}
                endDate={criteria.endDate}
                endError={validationParser.validationMessage("endDate")}
                onChange={this._onDateRangeChange}
              />

              <FormControl style={{ width: "200px" }}>
                <InputLabel>User</InputLabel>
                <Select
                  value={criteria.employeeIds ?? []}
                  onChange={this._onUserChange}
                  multiple
                  renderValue={(selected: number[]) => {
                    if (selected.length === employees.length) {
                      return <i>All</i>;
                    }
                    else {
                      return _.map(selected, (e, idx) => {
                        const emp = _.find(employees, m => m.id === e);
                        return (
                          <span key={idx}>
                            {`${emp?.firstName} ${emp?.lastName}`}
                            <>{idx !== (selected).length - 1 ? ", " : ""}</>
                          </span>
                        );
                      });
                    }
                  }}
                >
                  {_.map(employees, (emp) => {
                    return (
                      <MenuItem key={emp.id} value={emp.id}>
                        <Checkbox checked={_.findIndex(criteria.employeeIds, e => e === emp.id) > -1} />
                        <ListItemText primary={`${emp?.firstName} ${emp?.lastName}`} />
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <FormControl style={{ width: "250px" }}>
                <InputLabel>Event Type</InputLabel>
                <Select
                  value={criteria.eventTypes ?? []}
                  onChange={this._onEventTypeChange}
                  multiple
                  renderValue={(selected) => {
                    if (selected.length === eventTypes.length) {
                      return <i>All</i>;
                    }
                    else {
                      return _.map(selected as string[], (s, idx) => {
                        return (
                          <span key={idx}>
                            {s}
                            <>{idx !== (selected as string[]).length - 1 ? ", " : ""}</>
                          </span>
                        )
                      })
                    }
                  }}
                >
                  {
                    eventTypes.map((et) => {
                      return (
                        <MenuItem key={et} value={et}>
                          <Checkbox checked={_.findIndex(criteria.eventTypes, e => e === et) > -1} />
                          <ListItemText primary={et} />
                        </MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>

              <AdvanceTextField
                label="Element or change details"
                onChange={this._onElementChange}
                value={criteria.elementOrChange ?? ""}
                onKeyDown={this._searchFieldOnKeyPress}
              />
            </SearchControlsContainer>
          </CardActions>
          <AjaxActionIndicator state={[auditFetchResults]} />
          <div className={styles.gridContainer}>
            <DataGridPro
              columns={this.columns}
              rows={auditLines}
              density="compact"
              onSortModelChange={this._onSortChange}
              sortingMode="server"
              sortingOrder={["asc", "desc"]}
              sortModel={sortModel}
              disableColumnFilter
              disableColumnMenu
              disableSelectionOnClick
              hideFooter
            />
          </div>
        </Card>
      </div>
    );
  }
}

export const AuditRateEngineViewPage = AuditRateEngineService.inject(
  EmployeeService.inject(
    _AuditRateEngineViewPage
  )
);