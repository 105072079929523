import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Company
} from "$Generated/api";

import {
  ArrowDropDown
} from "$Imports/MaterialUIIcons";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  ISelectedCompanyContext
} from "$Providers/CompanyProvider";

import {
  CompanySelectModal
} from "$Imports/CommonComponents";

interface ISelectedCompanyDisplayProps {
  quoteCompanyId: number | undefined;
  companies: Company[];
  companyContext: ISelectedCompanyContext;
  onCompanySelect: () => void;
}

interface ISelectedCompanyDisplayState {
  isOpen: boolean;
}

const styles: {
  companyContainer: string,
  iconButton: string,
  companyDisplay: string
} = require("./SelectedCompanyDisplay.scss");

export class SelectedCompanyDisplay extends React.PureComponent<ISelectedCompanyDisplayProps, ISelectedCompanyDisplayState> {
  state: ISelectedCompanyDisplayState = {
    isOpen: false
  };

  @bind
  private _onClose() {
    this.props.onCompanySelect();
    this.setState({ isOpen: false });
  }

  @bind
  private _onOpen() {
    this.setState({ isOpen: true });
  }

  render() {
    const {
      isOpen
    } = this.state;
    
    const {
      quoteCompanyId,
      companies,
      companyContext
    } = this.props;

    return (
      <>
        <IconButton
          color="inherit"
          disableRipple
          className={styles.iconButton}
          disabled={!!quoteCompanyId}
          onClick={!quoteCompanyId && (companies.length > 1) ? this._onOpen : undefined}
        >
          {companyContext.companyKey.toLowerCase() === "kt" ?
            <span className={styles.companyDisplay}>Company: Transport</span>
            :
            <span className={styles.companyDisplay}>Company: Logistics</span>
          }
          {!quoteCompanyId && (companies.length > 1) && <ArrowDropDown />}
        </IconButton>
        <CompanySelectModal
          isOpen={isOpen}
          companies={companies}
          selectedCompany={companyContext.companyKey}
          selectCompany={companyContext.setCompany}
          onClose={this._onClose}
        />
      </>
    );
  }
}