import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  AccessorialChargeValue,
  QuoteStatusEnum
} from "$Generated/api";

import {
  AjaxActionIndicator,
  BinaryChoiceDialog,
  CardLinedHeader,
  DisplayFormattedNumber,
  TextCellTruncated,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Button,
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from "$Imports/MaterialUIComponents";

import {
  ActionMenu
} from "$Pages/SalesPortalView/AccessorialChargesComponents/ActionMenu";

import {
  AddAccessorialChargeValue
} from "$Pages/SalesPortalView/AccessorialChargesComponents/AddAccessorialChargeValue";

import {
  IQuoteEntryServiceInjectedProps,
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

import {
  IAccessorialChargeServiceInjectedProps,
  AccessorialChargeService
} from "$State/AccessorialChargeFreezerService";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  CURRENCY_FORMAT
} from "$Shared/utilities/formatUtil";

interface IAccessorialChargesCardBaseProps {
  quoteId: number | undefined;
  quoteStatus: QuoteStatusEnum;
  accessorialChargeValues: AccessorialChargeValue[];
}

interface IAccessorialChargesCardState {
  isAddModalOpen: boolean;
  isDeleteModalOpen: boolean;
  isApproveModalOpen: boolean;
  selectedChargeValue: AccessorialChargeValue;
  message: string;
}

const styles: {
  chargesCard: string;
  smallGridContainer: string;
  fullGridContainer: string;
  addChargeBtn: string;
  chargeRow: string;
} = require("./AccessorialChargesCard.scss");

type IAccessorialChargesCardProps = IAccessorialChargesCardBaseProps
  & IQuoteEntryServiceInjectedProps
  & IAccessorialChargeServiceInjectedProps;

const getRowClassNames = (row: AccessorialChargeValue): string => {
  return styles.chargeRow;
};

class _AccessorialChargesCard extends React.PureComponent<IAccessorialChargesCardProps, IAccessorialChargesCardState> {
  state: IAccessorialChargesCardState = {
    isAddModalOpen: false,
    isDeleteModalOpen: false,
    isApproveModalOpen: false,
    selectedChargeValue: {},
    message: ""
  }

  private readonly columns: GridColDef[] = [
    {
      headerName: "Description",
      field: "accessorialCharge",
      valueGetter: (params: GridValueGetterParams) => params.value.description,
      renderCell: (params: GridRenderCellParams) => <TextCellTruncated text={params.value} />,
      flex: 2,
      sortable: true
    }, {
      headerName: "Amount",
      field: "amount",
      renderCell: (params: GridRenderCellParams) => <DisplayFormattedNumber value={params.value} formatString={CURRENCY_FORMAT} />,
      flex: 1,
      sortable: true
    }, {
      headerName: "Status",
      field: "status",
      flex: 1,
      sortable: true
    }, {
      headerName: "",
      field: "actions",
      minWidth: 56,
      flex: 1,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <ActionMenu accessorialChargeValue={params.row} approveChargeValue={this._toggleApproveModal} deleteChargeValue={this._toggleDeleteModal} />
      )
    }];

    componentDidMount() {
      this.props.accessorialChargeService.fetchAccessorialCharges(true);
    }

    @bind
    private _toggleDeleteModal(model?: AccessorialChargeValue): void {
      this.setState((prev) => ({
        selectedChargeValue: model ? model : {},
        isDeleteModalOpen: !prev.isDeleteModalOpen,
        message: !prev.isDeleteModalOpen ? `Are you sure you want to delete charge ${model?.accessorialCharge?.description} for ${model?.amount?.toLocaleString('en-Us', {style: 'currency', currency: 'USD'})}?` : ""
      }));
    }

    @bind
    private _toggleApproveModal(model?: AccessorialChargeValue): void {
      this.setState((prev) => ({
        selectedChargeValue: model ? model : {},
        isApproveModalOpen: !prev.isApproveModalOpen,
        message: !prev.isApproveModalOpen ? `Are you sure you want to approve charge ${model?.accessorialCharge?.description} for ${model?.amount?.toLocaleString('en-Us', {style: 'currency', currency: 'USD'})}?` : ""
      }));
    }

    @bind
    private async _approveChargeValue(id: number): Promise<void> {
      await this.props.QuoteEntryService.approveAccessorialChargeValue(id);
      await this.props.QuoteEntryService.fetchAccessorialChargeValues(this.props.quoteId, true);
      await this.props.QuoteEntryService.fetchDataChangeLogs(this.props.quoteId);
    }

    @bind
    private async _deleteChargeValue(id: number): Promise<void> {
      await this.props.QuoteEntryService.deleteAccessorialChargeValue(id);
      await this.props.QuoteEntryService.fetchAccessorialChargeValues(this.props.quoteId, true);
      await this.props.QuoteEntryService.fetchDataChangeLogs(this.props.quoteId);
    }

    @bind
    private async _toggleAccessorialChargeModal() {
      this.setState((prev) => ({
        isAddModalOpen: !prev.isAddModalOpen
      }));
    }

  render() {
    const {
      quoteId,
      quoteStatus,
      accessorialChargeValues
    } = this.props;

    const {
      isAddModalOpen,
      isApproveModalOpen,
      isDeleteModalOpen,
      selectedChargeValue,
      message
    } = this.state;

    const {
      accessorialChargeFetchResults
    } = this.props.accessorialChargeService.getState();

    const accessorialCharges = accessorialChargeFetchResults.data ?? [];
    const autoApprove = SharedSecurityContext.hasRole(["accessorial-charge-value:approve"]);

    return (
      <>
        <CardLinedHeader className={styles.chargesCard} titleText="Accessorial Charges">
          <AjaxActionIndicator showProgress={accessorialChargeFetchResults.isFetching} />
          {
            accessorialChargeValues && (accessorialChargeValues.length > 0) ? (
              <>
                <div className={SharedSecurityContext.hasRole(["accessorial-charge-value:create"]) ? styles.smallGridContainer : styles.fullGridContainer} >
                  <DataGridPro
                    rows={accessorialChargeValues}
                    columns={this.columns}
                    density="compact"
                    hideFooter
                    disableSelectionOnClick
                    getRowClassName={(params) => getRowClassNames(params.row)}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "accessorialCharge", sort: "asc" }]
                      }
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={SharedSecurityContext.hasRole(["accessorial-charge-value:create"]) ? styles.smallGridContainer : styles.fullGridContainer}>This quote has no accessorial charges.</div>
            )
          }
          {quoteStatus === "Accepted" ?
            <UserAccessControl roles={["accessorial-charge-value:create"]}>
              <Button
                className={styles.addChargeBtn}
                color="primary"
                onClick={this._toggleAccessorialChargeModal}
              >
                Add Charge
              </Button>
            </UserAccessControl>
            : ""
          }
        </CardLinedHeader>
        <AddAccessorialChargeValue
          isOpen={isAddModalOpen}
          quoteId={quoteId}
          autoApprove={autoApprove}
          accessorialCharges={accessorialCharges}
          onClose={this._toggleAccessorialChargeModal}
        />
        <BinaryChoiceDialog
          isOpen={isDeleteModalOpen}
          message={message}
          onClick={(value) => {
            if (!value) {
              this._deleteChargeValue(selectedChargeValue.id!);
            }
            this._toggleDeleteModal();
          }}
          title="Delete Accessorial Charge"
          trueText="Cancel"
          trueVariant="outlined"
          falseText="Confirm"
        />
        <BinaryChoiceDialog
          isOpen={isApproveModalOpen}
          message={message}
          onClick={(value) => {
            if (!value) {
              this._approveChargeValue(selectedChargeValue.id!);
            }
            this._toggleApproveModal();
          }}
          title="Approve Accessorial Charge"
          trueText="Cancel"
          trueVariant="outlined"
          falseText="Confirm"
        />
      </>
    )
  }
}

export const AccessorialChargesCard = QuoteEntryService.inject(
  AccessorialChargeService.inject(
    _AccessorialChargesCard
  )
);