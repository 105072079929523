
import {
  React,
  _
} from "$Imports/Imports";

import {
  Customer,
  Region
} from "$Generated/api";

import { getFormattedZipPostalCode } from "$Shared/utilities/helpers";

interface ICustomerCardInfoBaseProps {
  customer: Customer;
  regions: Region[];
}

export class CustomerCardInfo extends React.PureComponent<ICustomerCardInfoBaseProps> {

  render() {

    const {
      customer,
      regions
    } = this.props;

    return (
      <>
        <div style={{ display: "flex" }}>
          <div style={{ marginLeft: "10px" }}>
            <div>{customer.tmcustomerId ?? ""}</div>
            <div>{customer.customerName ?? ""}</div>
            <div>{customer.address1 ?? ""}</div>
            <div>{customer.address2 ?? ""}</div>
            <div>{`${customer.city ?? ""}, ${(customer.region?.regionAbbreviation ?? _.find(regions, r => r.id === customer.regionId)?.regionAbbreviation) ?? ""}, ${getFormattedZipPostalCode(customer) ?? ""}`}</div>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <div>{customer.contactName ?? ""}</div>
            <div>{`${customer.cellNumber ? "C: " + customer.cellNumber : ""} ${customer.phoneNumber ? "P: " + customer.phoneNumber : ""}`}</div>
            <div>{customer.emailAddress ?? ""}</div>
          </div>
        </div>
      </>
    );
  }

}