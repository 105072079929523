import {
  React,
  _
} from "$Imports/Imports";

import {
  SelectedCompanyConsumer
} from "$Providers/index";

import {
  SalesRepHomeView
} from "./SalesRepHomeView";

import {
  EmployeeService,
  IEmployeeServiceInjectedProps
} from "$State/EmployeeFreezerService";

interface ISalesRepHomeViewPageBaseProps { }

type ISalesRepHomeViewPageProps = ISalesRepHomeViewPageBaseProps
  & IEmployeeServiceInjectedProps;

class _SalesRepHomeViewPage extends React.Component<ISalesRepHomeViewPageProps> {
  componentDidMount() {
  this.props.employeeService.fetchSalesReps();
  }

  render() {
    return (
      <SelectedCompanyConsumer>
        {(companyContext) => { 
          return (
            <SalesRepHomeView companyContext={companyContext}/>
          );
        }}
      </SelectedCompanyConsumer>
    );
  }
}

export const SalesRepHomeViewPage = EmployeeService.inject(
  _SalesRepHomeViewPage
);