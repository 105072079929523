import {
  React,
  bind,
  _,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  Card,
  CardActions,
  CardHeader,
  GridSortModel,
  GridColDef,
  GridRenderCellParams,
  DataGridPro
} from "$Imports/MaterialUIComponents";

import {
  AjaxActionIndicator,
  DisplayFormattedDatetime,
  DisplayFormattedNumber,
  SearchControlsContainer,
  DateRangePicker,
  QuoteLink,
  QuoteStatus,
  DisplayFormattedFeet,
  TextCellTruncated
} from "$Imports/CommonComponents";

import {
  DatAuditReport,
  DatAuditSearchCriteria,
  QuoteStatusEnum
} from "$Generated/api";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  DATE_ONLY_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  IDatAuditServiceInjectedProps,
  DatAuditService,
  DatAuditSearchValidationSchema
} from "$State/DatAuditFreezerService";

import {
  getDisplayedQuoteNumber
} from "$Shared/utilities/helpers";

const styles: {
  mainContainer: string;
  cardStyle: string;
  resultsGrid: string;
  searchContainer: string;
} = require("./DatAuditReportView.scss");

interface IDatAuditReportViewPageBaseProps {

}

type IDatAuditReportViewPageProps = IDatAuditReportViewPageBaseProps
  & IDatAuditServiceInjectedProps;

interface IDatAuditReportViewPageState {
  criteria: DatAuditSearchCriteria;
  errors: ValidationError | null;
}

class _DatAuditReportViewPage extends React.Component<IDatAuditReportViewPageProps, IDatAuditReportViewPageState> {
  state: IDatAuditReportViewPageState = {
    criteria: {
      sortColumn: "quoteNumber",
      sortAscending: true
    },
    errors: null
  };

  private readonly gridColumns: GridColDef[] = [
    {
      headerName: "Quote #",
      field: "quoteNumber",
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams<number, DatAuditReport>) => {
        const quoteNumber = params.row.quoteNumber ? getDisplayedQuoteNumber(params.row.quoteNumber, params.row.quoteType, undefined) : `Q${params.row.quoteNumber}`;
        return <QuoteLink quoteId={params.row.quoteId} quoteNumber={quoteNumber} />
      }
    },
    {
      headerName: "Date",
      field: "quoteDate",
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<Date>) =>
        <DisplayFormattedDatetime
          value={params.value}
          formatString={DATE_ONLY_FORMAT}
        />
    },
    {
      headerName: "Status",
      field: "quoteStatus",
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<QuoteStatusEnum>) =>
        params.value && <QuoteStatus quoteStatus={params.value} />
    },
    {
      headerName: "FB Miles",
      field: "fbMiles",
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams<number | undefined>) =>
        params.value && <DisplayFormattedNumber value={params.value} formatString={"0"} />
    },
    {
      headerName: "Length",
      field: "length",
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams<number>) => <DisplayFormattedFeet value={params.value} />
    },
    {
      headerName: "Weight",
      field: "weight",
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams<number>) => <DisplayFormattedNumber value={params.value} formatString={"0"} postfix={" lbs"} />
    },
    {
      headerName: "Origin Zip",
      field: "originZip",
      disableColumnMenu: true,
      flex: 1
    },
    {
      headerName: "Origin Market",
      field: "originMarket",
      disableColumnMenu: true,
      flex: 2
    },
    {
      headerName: "Destination Zip",
      field: "destinationZip",
      disableColumnMenu: true,
      flex: 1
    },
    {
      headerName: "Destination Market",
      field: "destinationMarket",
      disableColumnMenu: true,
      flex: 2
    },
    {
      headerName: "DAT Rate",
      field: "datRate",
      disableColumnMenu: true,
      flex: 1
    },
    {
      headerName: "DAT Timeframe",
      field: "datTimeFrame",
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams<number>) =>
        params.value && `${params.value} days`
    },
    {
      headerName: "DAT Message",
      field: "datMessage",
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params: GridRenderCellParams<string>) =>
        params.value && <TextCellTruncated text={params.value} />
    }
  ];

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        startDate: start ? moment(start).startOf('day').toDate() : undefined,
        endDate: end ? moment(end).endOf('day').toDate() : undefined
      }
    }));
  }

  @bind
  private _onSortChange(sortModel: GridSortModel) {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        sortColumn: sortModel[0].field,
        sortAscending: sortModel[0].sort === "asc"
      }
    }));

    this._onSearchClick();
  }

  @bind
  private async _onSearchClick() {
    const errors = await validateSchema(DatAuditSearchValidationSchema, this.state.criteria);
    this.setState({ errors: errors });

    if (errors) {
      return;
    }

    this.props.datAuditService.fetchDatAuditReport(this.state.criteria);
  }

  render() {
    const {
      datAuditReportFetchResults
    } = this.props.datAuditService.getState();
    const reportData = datAuditReportFetchResults.data ?? [];

    const {
      criteria,
      errors
    } = this.state;

    const sortModel: GridSortModel = [{
      field: criteria.sortColumn ?? "quoteNumber",
      sort: criteria.sortAscending ? "asc" : "desc"
    }];

    const validationParser = new ValidationErrorParser<DatAuditSearchCriteria>(errors);

    return (
      <div className={styles.mainContainer}>
        <Card className={styles.cardStyle}>
          <CardHeader title="DAT Audit Report" />
          <CardActions disableSpacing>
            <SearchControlsContainer className={styles.searchContainer} onSubmit={this._onSearchClick}>
              <DateRangePicker
                startDate={criteria.startDate}
                startError={validationParser.validationMessage("startDate")}
                endDate={criteria.endDate}
                endError={validationParser.validationMessage("endDate")}
                onChange={this._onDateRangeChange}
              />
            </SearchControlsContainer>
          </CardActions>
          <AjaxActionIndicator state={[datAuditReportFetchResults]} />
          <div className={styles.resultsGrid}>
            <DataGridPro
              columns={this.gridColumns}
              rows={reportData}
              getRowId={(row) => row.quoteId}
              density="compact"
              onSortModelChange={this._onSortChange}
              sortingMode="server"
              sortingOrder={["asc", "desc"]}
              sortModel={sortModel}
              disableMultipleSelection
              disableSelectionOnClick
              hideFooter
              localeText={{ noRowsLabel: "Enter start and end dates to view quote DAT rates" }}
            />
          </div>
        </Card>
      </div>
    );
  }
}

export const DatAuditReportViewPage = DatAuditService.inject(
  _DatAuditReportViewPage
);
