import {
  React,
  bind
} from "$Imports/Imports";

import {
  UserAccessControl,
  ConfirmDeleteModal
} from "$Imports/CommonComponents";

import {
  Edit,
  Delete
} from "$Imports/MaterialUIIcons";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  Terminal
} from "$Generated/api";

interface IActionMenuState {
}

interface IActionMenuProps {
  terminal: Terminal;
  onEditTask?: (terminal: Terminal) => void;
  onDelete?: (terminal: Terminal) => void;
}

interface IActionMenuState {
  deleteModalOpen: boolean
}

export class ActionMenu extends React.PureComponent<IActionMenuProps, IActionMenuState> {

  state: IActionMenuState = {
    deleteModalOpen: false
  }

  @bind
  private _onEditTask(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    if (this.props.onEditTask) {
      this.props.onEditTask(this.props.terminal);
    }
  }

  @bind
  private _onDelete(): void {
    if (this.props.onDelete) {
      this.props.onDelete(this.props.terminal);
    }
    this.setState({
      deleteModalOpen: false
    })
  }

  @bind
  private _onDeleteTask() {
    this.setState({
      deleteModalOpen: true
    });
  }

  @bind
  private _onDeleteCancel() {
    this.setState({
      deleteModalOpen: false
    });
  }

  @bind
  private _getDeleteMessage() {
    return `Delete ${this.props.terminal.terminalName} terminal?`
  }

  render() {
    return (
      <>
        <UserAccessControl roles={["terminals-setup:edit"]}>
          <IconButton
            size="small"
            onClick={this._onEditTask}
          >
            <Edit />
          </IconButton>
        </UserAccessControl>
        <UserAccessControl roles={["terminals-setup:create"]}>
          <IconButton
            size="small"
            onClick={this._onDeleteTask}
          >
            <Delete />
          </IconButton>
        </UserAccessControl>        
        <ConfirmDeleteModal
          isOpen={this.state.deleteModalOpen}
          onDelete={this._onDelete}
          onDeleteCancel={this._onDeleteCancel}
          deleteMessage={this._getDeleteMessage()}
        />
      </>
    );
  }
}
