import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  CardLinedHeader,
  AjaxActionIndicator,
  ToggleBox,
} from "$Imports/CommonComponents";

import {
  LabelValueDisplay
} from "$Components/common/LabelValueDisplay";

import {
  CommodityService,
  ICommodityServiceInjectedProps
} from "$State/CommodityFreezerService";

import {
  CustomerDetailService,
  ICustomerDetailServiceInjectedProps
} from "$State/CustomerDetailFreezerService";

interface ICustomerPortalCommoditiesCardProps {
  companyId: number | undefined;
  customerId: number | undefined;
}

const styles: {
  commoditiesContainer: string;
  labelValueDisplay: string;
  label: string;
} = require('./CustomerPortalCommoditiesCard.scss');

const commodityActions = ["Available", "Hidden"];
type CommodityAction = typeof commodityActions[number];

type CustomerPortalCommoditiesCardProps = ICustomerPortalCommoditiesCardProps
& ICommodityServiceInjectedProps
& ICustomerDetailServiceInjectedProps;

interface ICustomerPortalCommoditiesCardState { }

export class _CustomerPortalCommoditiesCard extends React.Component<CustomerPortalCommoditiesCardProps, ICustomerPortalCommoditiesCardState> {

  @bind
  async selectCommodityAction(oldValue: CommodityAction, newValue: CommodityAction, commodityId: number | undefined) {
    if (oldValue === newValue) {
      return;
    }
    if (newValue === "Hidden") {
      await this.props.customerDetailService.addCommodityExclusion(this.props.customerId, commodityId);
      await this.props.customerDetailService.fetchCustomerCommodityExclusions(this.props.customerId, true);
    }
    if (newValue === "Available") {
      await this.props.customerDetailService.deleteCommodityExclusion(this.props.customerId, commodityId);
      await this.props.customerDetailService.fetchCustomerCommodityExclusions(this.props.customerId, true);
    }
  }

  render() {
    const {
      companyId,
      customerId
    } = this.props;

    const {
      commodityExclusionsFetchResults
    } = this.props.customerDetailService.getState();
    const commodityExclusions = commodityExclusionsFetchResults.data ?? [];

    const {
      activeCommodities,
      commodityFetchResults
    } = this.props.commodityService.getState();
    const commodities = activeCommodities ?? [];

    return (
      <CardLinedHeader titleText="Customer Portal Commodities">
        <AjaxActionIndicator showProgress={commodityFetchResults.isFetching || commodityExclusionsFetchResults.isFetching} />
        {
          <div className={styles.commoditiesContainer}>
            {
              _.map(commodities, (c, idx) => {
                const selectedCommodityAction: CommodityAction = commodityExclusions.length && commodityExclusions.find(e => e.customerId === Number(customerId) && e.commodityId === c.id) 
                  ? "Hidden"
                  : "Available";
                return <div className={styles.labelValueDisplay} key={idx}>
                        <LabelValueDisplay 
                          label={c.commodityName}
                          labelClass={styles.label}
                          value={<ToggleBox
                            selectedValue={selectedCommodityAction}
                            options={commodityActions}
                            toggleClick={(value, mappedId) => this.selectCommodityAction(selectedCommodityAction, value, mappedId)}
                            companyId={companyId}
                            mappedId={c.id}
                          />}
                        />
                      </div>
              })
            }
          </div>
        }
      </CardLinedHeader>
    )
  }
}

export const CustomerPortalCommoditiesCard = CommodityService.inject(CustomerDetailService.inject(_CustomerPortalCommoditiesCard));