import {
  React
} from "$Imports/Imports";

import {
  Employee,
  QuoteApprovalReason,
  QuoteApprovalReasonApprovalNeededReasonIdEnum,
  QuoteApprovalReasonApprovalStatusEnum
} from "$Generated/api";

import {
  CardLinedHeader,
  DisplayFormattedDatetime
} from "$Imports/CommonComponents";

import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams
} from "$Imports/MaterialUIComponents";

import {
  approvalReasonTextMap,
  approvalStatusTextMap
} from "$Utilities/enumUtil";

import {
  DATE_WITH_TIME_MERIDIAN_FORMAT
} from "$Shared/utilities/formatUtil";

interface IOwnProps {
  approvals?: QuoteApprovalReason[];
  hasQuoteChanged: boolean;
}

const styles: {
  approvalsCard: string;
  message: string;
} = require("./QuoteApprovalsCard.scss");

const columns: GridColDef[] = [{
  headerName: "Reason",
  field: "approvalNeededReasonId",
  valueFormatter: (params: GridValueFormatterParams) => approvalReasonTextMap[params.value as QuoteApprovalReasonApprovalNeededReasonIdEnum ?? ""],
  width: 160,
  sortable: false
}, {
  headerName: "Decision",
  field: "approvalStatus",
  valueFormatter: (params: GridValueFormatterParams) => approvalStatusTextMap[params.value as QuoteApprovalReasonApprovalStatusEnum ?? ""],
  width: 135,
  sortable: false
}, {
  headerName: "Reviewer",
  field: "reviewedBy",
  valueGetter: (params: GridValueGetterParams<Employee, QuoteApprovalReason>) => params.value ? `${params.value.firstName} ${params.value.lastName}` : "",
  minWidth: 120,
  flex: 1,
  sortable: false
}, {
  headerName: "Review Date",
  field: "reviewedOn",
  renderCell: (params: GridRenderCellParams<Date | undefined>) =>
    <DisplayFormattedDatetime value={params.value} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />,
  width: 200,  
  sortable: false
}];

class _QuoteApprovalsCard extends React.PureComponent<IOwnProps> {
  render() {
    const {
      approvals,
      hasQuoteChanged
    } = this.props;

    return (
      <CardLinedHeader
        className={styles.approvalsCard}
        titleText="Quote Approvals"
      >
        {approvals && (approvals.length > 0) ? (
          <>
            <DataGridPro
              columns={columns}
              rows={approvals}
              density="compact"
              autoHeight
              disableColumnMenu
              disableColumnFilter
              hideFooter
            />
            {hasQuoteChanged && (
              <div className={styles.message}>
                Re-rating may create additional required approvals or void pending approvals.
              </div>
            )}
          </>
        ) : (
          <div className={styles.message}>
            This quote does not currently require approval{hasQuoteChanged ? `, but re-rating may create required approvals` : ""}.
          </div>
        )}
      </CardLinedHeader>
    );
  }
}

export const QuoteApprovalsCard = _QuoteApprovalsCard;
