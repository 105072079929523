import {
  React,
  bind
} from "$Imports/Imports";

import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent
} from "$Imports/MaterialUIComponents";

import {
  CustomerActivityCriteria,
  CustomerActivityCriteriaDateRangeEnum
} from "$Generated/api";

import {
  CompanyEnum
} from "$Utilities/companyUtil";

interface IOwnProps {
  onChange: (filter: CustomerActivityCriteria) => void;
}

type OwnState = CustomerActivityCriteria;

const styles: {
  container: string;
  companies: string;
} = require("./ActivityFilters.scss");

class _ActivityFilters extends React.PureComponent<IOwnProps, OwnState> {
  state: OwnState = {
    dateRange: "CurrentMonth",
    companyId: undefined
  };

  @bind
  private _onSelectDateRange(event: SelectChangeEvent<CustomerActivityCriteriaDateRangeEnum>): void {
    const dateRange = event.target.value as CustomerActivityCriteriaDateRangeEnum;

    this.setState({
      dateRange: dateRange
    });

    this.props.onChange({
      ...this.state,
      dateRange
    });
  }

  @bind
  private _onSelectCompany(event: React.ChangeEvent<HTMLInputElement>): void {
    const companyId = event.target.value
      ? Number(event.target.value)
      : undefined;

    this.setState({
      companyId: companyId
    });

    this.props.onChange({
      ...this.state,
      companyId
    });
  }

  render() {
    const {
      dateRange,
      companyId
    } = this.state;

    return (
      <div className={styles.container}>
        <FormControl hiddenLabel>
          <Select
            value={dateRange}
            onChange={this._onSelectDateRange}
          >
            <MenuItem value="CurrentMonth">Current Month</MenuItem>
            <MenuItem value="Last30Days">Last 30 Days</MenuItem>
            <MenuItem value="Last3Months">Last 3 Months</MenuItem>
            <MenuItem value="Last6Months">Last 6 Months</MenuItem>
            <MenuItem value="Last9Months">Last 9 Months</MenuItem>
            <MenuItem value="Last12Months">Last 12 Months</MenuItem>
          </Select>
        </FormControl>

        <RadioGroup
          className={styles.companies}
          value={companyId?.toString() ?? ""}
          onChange={this._onSelectCompany}
        >
          <FormControlLabel
            value={""}
            control={<Radio />}
            label="Both"
          />
          <FormControlLabel
            value={CompanyEnum.Logistics}
            control={<Radio />}
            label="Logistics"
          />
          <FormControlLabel
            value={CompanyEnum.Transport}
            control={<Radio />}
            label="Transport"
          />
        </RadioGroup>
      </div>
    );
  }
}

export const ActivityFilters = _ActivityFilters;
