
import {
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  Quote
} from "$Generated/api";

interface IPercentUpchargeReasonTextFieldBaseProps {
  viewOnly?: boolean;
  quote?: Quote;
  className?: string | undefined;
  validationErrors: ValidationError | null;
  setReason: (reason: string) => void;
}

export class PercentUpchargeReasonTextField extends React.PureComponent<IPercentUpchargeReasonTextFieldBaseProps> {

  @bind
  setReason(reason: string) {
    this.props.setReason(reason);
  }

  render() {
    
    const {
      viewOnly,
      quote,
      validationErrors
    } = this.props;

    return (
      <AdvanceTextField
        label="Reason for % Upcharge"
        disabled={viewOnly}
        onDebouncedChange={this.setReason}
        value={quote?.percentUpchargeReason ?? ""}
        error={!!(validationErrors?.errors.length)}
        helperText={validationErrors?.errors}
        fullWidth
      />
    )
  }
}