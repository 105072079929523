import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  LoadingInstruction,
  LoadingInstructionAppliesToEnum
} from "$Generated/api";

import {
  Card,
  CardActions,
  CardHeader,
  Button,
  FormControlLabel,
  Switch,
  DataGridPro,
  GridColDef,
  GridValueFormatterParams,
  GridRenderCellParams
} from "$Imports/MaterialUIComponents";

import {
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  ILoadingInstructionServiceInjectedProps,
  LoadingInstructionService,
  EMPTY_LOADING_INSTRUCTION
} from "$State/LoadingInstructionFreezerService";

import {
  AddEditLoadingInstructionModal
} from "./AddEditLoadingInstructionModal";

import {
  ActionMenu
} from "./ActionMenu";

import {
  appliesToTextMap
} from "$Utilities/enumUtil";

const styles: {
  mainContainer: string;
  actionArea: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface ILoadingInstructionViewPageBaseProps { }

interface ILoadingInstructionViewState {
  isLoadingInstructionOpen: boolean;
  filterActive: boolean;
  loadingInstruction: LoadingInstruction;
}

type ILoadingInstructionViewPageProps = ILoadingInstructionViewPageBaseProps
  & ILoadingInstructionServiceInjectedProps;

class _LoadingInstructionViewPage extends React.Component<ILoadingInstructionViewPageProps, ILoadingInstructionViewState> {
  state: ILoadingInstructionViewState = {
    isLoadingInstructionOpen: false,
    filterActive: true,
    loadingInstruction: { ...EMPTY_LOADING_INSTRUCTION }
  }

  private readonly columns: GridColDef[] = [
    {
      headerName: "Instruction",
      field: "instruction",
      flex: 4
    },
    {
      headerName: "Applies To",
      field: "appliesTo",
      valueFormatter: (params: GridValueFormatterParams) => appliesToTextMap[params.value as LoadingInstructionAppliesToEnum ?? ""],
      flex: 2
    },
    {
      headerName: "Active",
      field: "isActive",
      valueFormatter: (params: GridValueFormatterParams) => params.value ? "Yes" : "No",
      flex: 1
    },
    {
      headerName: "",
      field: "actions",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any, LoadingInstruction>) => (
        <ActionMenu
          loadingInstruction={params.row}
          onEdit={this._toggleLoadingInstructionModal}
        />
      )
    }
  ];

  componentDidMount() {
    this.props.loadingInstructionService.fetchLoadingInstructions(true);
  }

  @bind
  private _onActiveFilterChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.setState({ filterActive: checked });
    this.props.loadingInstructionService.fetchLoadingInstructions(checked, true);
  }

  @bind
  private async _toggleLoadingInstructionModal(seed?: LoadingInstruction) {
    const { isLoadingInstructionOpen } = this.state;
    if (isLoadingInstructionOpen) {
      this.setState({
        isLoadingInstructionOpen: false,
        loadingInstruction: { ...EMPTY_LOADING_INSTRUCTION }
      });
    }
    else {
      let editLoadingInstruction: LoadingInstruction = { ...EMPTY_LOADING_INSTRUCTION, isActive: true };
      if (seed) {
        editLoadingInstruction = _.cloneDeep(seed);
      }
      this.setState({
        isLoadingInstructionOpen: true,
        loadingInstruction: editLoadingInstruction
      });
    }
  }

  render() {
    const {
      loadingInstructionFetchResults,
      loadingInstructionSaveResult
    } = this.props.loadingInstructionService.getState();
    const {
      isLoadingInstructionOpen,
      filterActive,
      loadingInstruction
    } = this.state;
    let loadingInstructionData = loadingInstructionFetchResults.data ?? [];

    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader title="Loading/Unloading Instructions" />
          <CardActions
            disableSpacing={true}
            className={styles.actionArea}
          >
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={filterActive}
                    onChange={this._onActiveFilterChange}
                  />
                )
              }
              label="Only show active"
            />
            <Button
              color="primary"
              onClick={() => this._toggleLoadingInstructionModal()}
            >
              Add
            </Button>
          </CardActions>
          <AjaxActionIndicator
            state={[loadingInstructionFetchResults, loadingInstructionSaveResult]}
          />
          <div style={{ margin: "8px" }}>
            <DataGridPro
              rows={loadingInstructionData}
              columns={this.columns}
              density="compact"
              hideFooter
              disableSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: "id", sort: "asc" }]
                }
              }}
              autoHeight
            />
          </div>
        </Card>
        <AddEditLoadingInstructionModal
          isOpen={isLoadingInstructionOpen}
          filterActive={filterActive}
          loadingInstruction={loadingInstruction}
          onClose={() => this._toggleLoadingInstructionModal()}
        />
      </div>
    );
  }
}

export const LoadingInstructionViewPage = LoadingInstructionService.inject(
  _LoadingInstructionViewPage
);