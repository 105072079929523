
import {
  React,
  bind
} from "$Imports/Imports";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  QuestionAnswerIcon
} from "$Imports/MaterialUIIcons"

interface IQuestionAnswerButtonBaseProps {
  disabled?: boolean,
  onClick?: () => void,
  size?: "small" | "medium";
}

export class QuestionAnswerButton extends React.PureComponent<IQuestionAnswerButtonBaseProps> {

  @bind 
  private _onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    
    const {
      disabled,
      size
    } = this.props;

    return (
      <>
        <IconButton
          color={!disabled ? "secondary" : "primary"}
          disabled={disabled}
          onClick={this._onClick}
          size={size}
        >
          <QuestionAnswerIcon />
        </IconButton>
      </>
    )
  }

}