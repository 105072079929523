import {
  React
} from "$Imports/Imports";

import {
  Error
} from "$Imports/MaterialUIIcons";

const styles: {
  errorIcon: string
} = require("./StopFlagErrorMessage.scss");

interface IOwnProps {
  message: string;
}

const StopFlagErrorMessage = (props: IOwnProps) => {
  return (
    <div className={styles.errorIcon}>
      <Error fontSize="small" style={{ verticalAlign: "middle" }} /> {props.message}
    </div>
  )
}

export {
  StopFlagErrorMessage
}