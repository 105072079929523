import {
  React,
  bind,
  _,
} from "$Imports/Imports";

import {
  AddEditCapacityRate
} from "./AddEditCapacityRate";

import {
  DataTable,
  IDataTableColumn,
  AjaxActionIndicator,
  DisplayFormattedDatetime,
  DisplayFormattedNumber,
  TruncatedTextDisplay,
  ConfirmDeleteModal,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Card,
  CardActions,
  CardHeader,
  Button,
  IconButton,
  FormControlLabel,
  Switch,
  InputLabel,
  FormControl,
  Select,
  MenuItem
} from "$Imports/MaterialUIComponents";

import {
  ICapacityRateServiceInjectedProps,
  CapacityRateService
} from "$State/CapacityRateFreezerService";

import {
  IZoneConfigurationServiceInjectedProps,
  ZoneConfigurationService,
} from "$State/ZoneConfigurationService";

import {
  IStateServiceInjectedProps,
  StateService
} from "$State/RegionFreezerService";


import {
  CapacityRate,
  Region,
  Zone
} from "$Generated/api";

import {
  ActionMenu
} from "./ActionMenu";

import {
  CapacityReasonToolTip
} from "./CapacityReasonToolTip"

import {
  GradeIcon,
  Clear
} from "$Imports/MaterialUIIcons"

import {
  renderStatesSelection,
  renderZonesSelection
} from "$Utilities/capacityModelColConstants";

import { DATE_WITH_TIME_MERIDIAN_FORMAT } from "$Shared/utilities/formatUtil";

const styles: {
  table: string;
  clearIcon: string;
  clearButton: string;
  clearButtonTd: string;
} = require("./CapacityRateView.scss");

const containerStyles: {
  mainContainer: string;
  actionArea: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");


interface ICapacityRateViewBaseProp {
  companyId: number | undefined;
}

interface ICapacityRateViewState {
  deleteOpen: boolean;
  dataToDelete: CapacityRate | null;
  originStateId: number | null;
  originZoneId: number | null;
  destStateId: number | null;
  destZoneId: number | null;
}

type ICapacityRateViewProp = ICapacityRateServiceInjectedProps
& ICapacityRateViewBaseProp
& IStateServiceInjectedProps
& IZoneConfigurationServiceInjectedProps;

class _CapacityRateView extends React.Component<ICapacityRateViewProp> {

  state: ICapacityRateViewState = {
    deleteOpen: false,
    dataToDelete: null,
    originStateId: null,
    originZoneId: null,
    destStateId: null,
    destZoneId: null
  };


  componentDidMount() {
    this.props.capacityRateService.clearFreezer();
    this.props.capacityRateService.fetchCapacities(this.props.companyId);
    this.props.zoneConfigurationService.clearFreezer();
    this.props.zoneConfigurationService.fetchZoneConfigurations(this.props.companyId, true);
    this.props.regionService.fetchStates();
  }

  componentDidUpdate(prevProps: ICapacityRateViewProp) {
    if(this.props.companyId !== prevProps.companyId){
      this.props.capacityRateService.fetchCapacities(this.props.companyId, true);
    }
  }

  @bind
  private _onAddClick() {
    this.props.capacityRateService.addCapacityRate();
  }

  @bind
  private _onCancel() {
    this.props.capacityRateService.clearEditAddForm();
  }

  @bind
  private _onTempCapacityRateChanged(capacityRate: Partial<CapacityRate>) {
    this.props.capacityRateService.updateTempCapacityRate(capacityRate);
  }

  @bind
  private _onCapacityRateChanged(capacityRate: Partial<CapacityRate>) {
    this.props.capacityRateService.updateCapacityRate(capacityRate);
  }

  @bind
  private _onChangeImmediateStart(immediateStart: boolean) {
    this.props.capacityRateService.onChangeImmediateStart(immediateStart);
  }

  @bind
  private _onSave() {
    this.props.capacityRateService.saveCapacityRate(this.props.companyId);
  }

  @bind
  private _onMenuItemSelected(capacityRate: CapacityRate) {
    this.props.capacityRateService.editCapacityRate(capacityRate?.id);
  }

  @bind
  private _onShowHistoryChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.capacityRateService.setShowHistory(checked);
  }

  @bind
  private _onDeleteClick(data: CapacityRate) {
    this.setState({
      deleteOpen: true,
      dataToDelete: data
    })
  }

  @bind
  private _onDelete() {
    if (this.state.dataToDelete?.id) {
      this.props.capacityRateService.deleteCapacityRate(this.props.companyId, this.state.dataToDelete.id)
    }
    this.clearDeleteState();
  }

  @bind
  private clearDeleteState() {
    this.setState({
      deleteOpen: false,
      dataToDelete: null
    })
  }

  @bind
  private getCapacityDeleteMessage(): string {
    if (!this.state.dataToDelete) {
      return "";
    }

    const { originRegions, originZones, destinationRegions, destinationZones } = this.state.dataToDelete;
    const allStates = this.props.regionService.getState().regionFetchResults.data ?? [];
    const allZoneLen = this.props.zoneConfigurationService.getState().originZones?.length;
    let originString = "";
    let destString = "";

    if (originRegions && originRegions.length > 0) {
      originString = renderStatesSelection(originRegions, allStates);
    } else if (originZones && originZones.length > 0) {
      originString = renderZonesSelection(originZones, allZoneLen ?? 0);
    } else {
      return "";
    }
    
    if (destinationRegions && destinationRegions.length > 0) {
      destString = renderStatesSelection(destinationRegions, allStates);
    } else if (destinationZones && destinationZones.length > 0) {
      destString = renderZonesSelection(destinationZones, allZoneLen ?? 0);
    } else {
      return "";
    }

    return `Delete capacity rate for ${originString} to ${destString}?`;
  }

  @bind
  private _onChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      [event.target.name]: event.target.value === "" ? null : event.target.value,
    })
  }

  @bind
  private _onClearStateFilter() {
    this.setState({
      originStateId: null,
      originZoneId: null,
      destStateId: null,
      destZoneId: null
    })
  }

  @bind
  private _onToolTipRowClick(capacityRate: CapacityRate) {
    this.props.capacityRateService.onToolTipFavoriteRowClick(capacityRate);
  }

  private renderStatesSelection(selected: Region[]): string {
    const regionData = this.props.regionService.getState().regionFetchResults.data;
    if (!regionData) {
      return "";
    }

    return renderStatesSelection(selected, regionData);
  }

  private renderZonesSelection(selected: Zone[]): string {
    const zoneData = this.props.zoneConfigurationService.getState().originZones;
    if (!zoneData) {
      return "";
    }

    return renderZonesSelection(selected, zoneData.length);
  }

  private readonly columns: Array<IDataTableColumn<CapacityRate>> = [
    {
      columnName: "isFavorite",
      columnFieldData: (d) => d.isFavorite ? <GradeIcon style={{ color: "#FFCB25" }} /> : "",
      headerValue: "Favorite",
      sortMethod: (d) => d.isFavorite ?? "",
    },
    {
      columnName: "originState",
      columnFieldData: (d) => <TruncatedTextDisplay
        text={ d?.originRegions && d?.originRegions?.length > 0
          ? this.renderStatesSelection(d?.originRegions)
          : d?.originZones && d?.originZones?.length > 0
          ? this.renderZonesSelection(d?.originZones)
          : "" }
        maxDisplayLength={16}
      />,
      headerValue: "Origin(s)",
      sortMethod: (d) => d?.originRegions && d?.originRegions?.length > 0
        ? this.renderStatesSelection(d?.originRegions)
        : d?.originZones && d?.originZones?.length > 0
        ? this.renderZonesSelection(d?.originZones)
        : "",
    },
    {
      columnName: "destinationStates",
      columnFieldData: (d) => <TruncatedTextDisplay
        text={ d?.destinationRegions && d?.destinationRegions?.length > 0
          ? this.renderStatesSelection(d?.destinationRegions)
          : d?.destinationZones && d?.destinationZones?.length > 0
          ? this.renderZonesSelection(d?.destinationZones)
          : "" }
        maxDisplayLength={16}
      />,
      headerValue: "Destination(s)",
      sortMethod: (d) => d?.destinationRegions && d?.destinationRegions?.length > 0
        ? this.renderStatesSelection(d?.destinationRegions)
        : d?.destinationZones && d?.destinationZones?.length > 0
        ? this.renderZonesSelection(d?.destinationZones)
        : "",
    },
    {
      columnName: "rateValue",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.rateValue} formatString={'0.00%'} />,
      headerValue: "Rate %",
      sortMethod: (d) => d.rateValue ?? "",
    },
    {
      columnName: "startDateTime",
      columnFieldData: (d) => <DisplayFormattedDatetime value={d.startDateTime} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />,
      headerValue: "Start Date",
      sortMethod: (d) => d.startDateTime ?? "",
    },
    {
      columnName: "endDateTime",
      columnFieldData: (d) => <DisplayFormattedDatetime value={d.endDateTime} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />,
      headerValue: "End Date",
      sortMethod: (d) => d.endDateTime ?? "",
    },
    {
      columnName: "reason",
      columnFieldData: (d) => <CapacityReasonToolTip capacityReason={d.reason ?? ""} />,
      headerValue: "Reason"
    },
    {
      columnName: "action",
      columnFieldData: (d) => (
        <ActionMenu
          capacityRate={d}
          onDelete={this._onDeleteClick}
          onMenuItemClick={this._onMenuItemSelected}
        />
      ),
      headerValue: "",
    }
  ];

  @bind
  private rowStyle(rowData: any): React.CSSProperties {
    const capacityRate = rowData as CapacityRate;
    const originText = this.renderStatesSelection(capacityRate.originRegions ?? []);
    const destText = this.renderStatesSelection(capacityRate.destinationRegions ?? []);

    if (originText.includes('All') || destText.includes('All')) {
      return { color: '#CC0000' };
    }
    return {};
  }

  render() {
    const { regionFetchResults: stateFetchResults } = this.props.regionService.getState();
    const {
      editAddCapacityRate,
      editAddImmediateStart,
      editAddValidationErrors,
      formMode,
      capacityRateFetchResults,
      capacityRateUpdateResults,
      capacityRateAddResults,
      sortState,
      showHistory,
      rateFromServer,
      tempCapacityRate
    } = this.props.capacityRateService.getState();

    const {
      originZones
    } = this.props.zoneConfigurationService.getState();

    const { originStateId, originZoneId, destStateId, destZoneId } = this.state;
    const currentTime = new Date();
    const stateData = stateFetchResults.data ?? [];
    const zoneData = originZones ?? [];
    const capacityRateData = capacityRateFetchResults.data ?? [];

    const favoritedCapacityRates: CapacityRate[] = capacityRateData.filter(cr => cr.isFavorite); 

    let filteredData = _.filter(capacityRateData, function (capacityRate) {
      const isHistory = capacityRate.endDateTime && new Date(capacityRate.endDateTime) <= currentTime;
      if ((showHistory && isHistory) || (!showHistory && !isHistory)) { // is there a way to simplify this algebra
        if (!originStateId || capacityRate.originRegions!.some(x => x.id === originStateId)) {
          if (!originZoneId || capacityRate.originZones!.some(x => x.id === originZoneId)) {
            if (!destStateId || capacityRate.destinationRegions!.some(x => x.id === destStateId)) {
              if (!destZoneId || capacityRate.destinationZones!.some(x => x.id === destZoneId)) {
                return true;
              }
            }
          }
        }
      }
      return false;
    }) as CapacityRate[];

    const isFetching: boolean = formMode === "add" ? capacityRateAddResults.isFetching :
      formMode === "edit" ? capacityRateUpdateResults.isFetching : false;

    return (
      <div
        className={containerStyles.mainContainer}
      >
        <Card
          className={containerStyles.cardStyle}
        >
          <CardHeader
            title="Capacity Model"
          />
          <CardActions
            disableSpacing={true}
            className={containerStyles.actionArea}
          >
            <table className={styles.table}>
              <tbody>
                <tr>
                  <td style={{ width: "5%" }} >
                    <FormControlLabel
                      control={
                        (
                          <Switch
                            color="primary"
                            checked={showHistory}
                            onChange={this._onShowHistoryChange}
                          />
                        )
                      }
                      label="Show History"
                    />
                  </td>
                  <td style={{ width: "15%" }} >
                    <FormControl fullWidth>
                      <InputLabel>Origin State</InputLabel>
                      <Select
                        name="originStateId"
                        value={originStateId ?? ""}
                        disabled={!!originZoneId}
                        onChange={(event) => this._onChange(event as React.ChangeEvent<HTMLInputElement>)}
                      >
                        <MenuItem value="">All States</MenuItem>
                        {stateData.map((state, i) =>
                          <MenuItem value={state.id} key={i}>
                            {state.regionName}
                          </MenuItem>)}
                      </Select>
                    </FormControl>
                  </td>
                  <td style={{ width: "15%" }} >
                    <FormControl fullWidth>
                      <InputLabel>Origin Zone</InputLabel>
                      <Select
                        name="originZoneId"
                        value={originZoneId ?? ""}
                        disabled={!!originStateId}
                        onChange={(event) => this._onChange(event as React.ChangeEvent<HTMLInputElement>)}
                      >
                        <MenuItem value="">All Zones</MenuItem>
                        {zoneData.map((zone, i) =>
                          <MenuItem value={zone.id} key={i}>
                            {zone.zoneName}
                          </MenuItem>)}
                      </Select>
                    </FormControl>
                  </td>
                  <td style={{ width: "15%" }} >
                    <FormControl fullWidth>
                      <InputLabel>Destination State</InputLabel>
                      <Select
                        name="destStateId"
                        value={destStateId ?? ""}
                        disabled={!!destZoneId}
                        onChange={(event) => this._onChange(event as React.ChangeEvent<HTMLInputElement>)}
                      >
                        <MenuItem value="">All States</MenuItem>
                        {stateData.map((state, i) =>
                          <MenuItem value={state.id} key={i}>
                            {state.regionName}
                          </MenuItem>)}
                      </Select>
                    </FormControl>
                  </td>
                  <td style={{ width: "15%" }} >
                    <FormControl fullWidth>
                      <InputLabel>Destination Zone</InputLabel>
                      <Select
                        name="destZoneId"
                        value={destZoneId ?? ""}
                        disabled={!!destStateId}
                        onChange={(event) => this._onChange(event as React.ChangeEvent<HTMLInputElement>)}
                      >
                        <MenuItem value="">All Zones</MenuItem>
                        {zoneData.map((zone, i) =>
                          <MenuItem value={zone.id} key={i}>
                            {zone.zoneName}
                          </MenuItem>)}
                      </Select>
                    </FormControl>
                  </td>
                  <td className={styles.clearButtonTd} >
                    <IconButton
                      size="small"
                      onClick={this._onClearStateFilter}
                      className={styles.clearButton}
                    >
                      <Clear className={styles.clearIcon} />
                    </IconButton>
                  </td>
                  <td style={{ width: "25%" }} >
                  </td>
                  <td style={{
                    width: "25%",
                    textAlign: "right"
                  }} >
                    <UserAccessControl roles={["capacity-model:create"]}>
                      <Button
                        color="primary"
                        onClick={this._onAddClick}
                        disabled={isFetching}
                        style={{ textTransform: 'none' }}
                      >
                        Add
                      </Button>
                    </UserAccessControl>
                  </td>
                </tr>
              </tbody>
            </table>
          </CardActions>
          <AjaxActionIndicator
            state={[capacityRateFetchResults, stateFetchResults]}
          />
          <DataTable
            columns={this.columns}
            data={filteredData}
            defaultSortColumnName={sortState.sortColumnName}
            defaultSortDirection={sortState.sortDirection}
            tableContextProps={{
              rowStyle: this.rowStyle
            }}
          />
        </Card>
        <AddEditCapacityRate
          formMode={formMode}
          capacityRate={editAddCapacityRate}
          tempCapacityRate={tempCapacityRate}
          favoriteCapacityRates={favoritedCapacityRates}
          immediateStart={editAddImmediateStart}
          validationErrors={editAddValidationErrors}
          onCancel={this._onCancel}
          onSave={this._onSave}
          isFetching={isFetching}
          onCapacityRateChange={this._onCapacityRateChanged}
          onTempCapacityRateChange={this._onTempCapacityRateChanged}
          onChangeImmediateStart={this._onChangeImmediateStart}
          onToolTipRowClick={this._onToolTipRowClick}
          regionData={stateData}
          zoneData={zoneData}
          rateFromServer={rateFromServer}
        />
        <ConfirmDeleteModal
          isOpen={this.state.deleteOpen}
          onDelete={this._onDelete}
          onDeleteCancel={this.clearDeleteState}
          deleteMessage={this.getCapacityDeleteMessage()}
        />
      </div>
    );
  }
}

export const CapacityRateView =
  StateService.inject(
    CapacityRateService.inject(
      ZoneConfigurationService.inject(
        _CapacityRateView
      )
    )
  );
