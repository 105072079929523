import {
  React,
  bind,
  moment
} from "$Imports/Imports";

import {
  Icon
} from "$Imports/MaterialUIComponents";

import {
  HourglassEmptyOutlined
} from "$Imports/MaterialUIIcons";

import {
  DisplayFormattedDatetime
} from "./DisplayFormattedDatetime";

interface IOwnProps {
  value?: Date;
  soonCutoff?: Date;
  formatString?: string;
  includeTime?: boolean;
}

interface IOwnState {
  isDueSoon: boolean;
  isOverdue: boolean;
}

const styles: {
  overdue: string;
} = require("./DueDateField.scss");

class _DueDateField extends React.PureComponent<IOwnProps, IOwnState> {
  state: IOwnState = {
    isDueSoon: false,
    isOverdue: false
  };

  componentDidMount() {
    this._checkDate();
  }

  componentDidUpdate(prev: IOwnProps) {
    if (this.props.value !== prev.value) {
      this._checkDate();
    }
  }

  @bind
  private _checkDate() {
    const { value, soonCutoff, includeTime } = this.props;

    if (!value) {
      this.setState({
        isDueSoon: false,
        isOverdue: false
      });
      return;
    }

    const valueMoment = includeTime ? moment.utc(value) : moment.utc(value).startOf('day');
    const valueDate = valueMoment.toDate();

    const today = moment.utc().startOf('day').toDate();
    const now = moment.utc().toDate();

    const isOverdue = includeTime ? now > valueDate : today > valueDate;
    let isDueSoon = false;

    if (!isOverdue) {
      const cutoffSoon = soonCutoff ?? valueMoment.subtract(3, 'day').toDate();

      isDueSoon = includeTime ? now >= cutoffSoon : today >= cutoffSoon;
    }

    this.setState({
      isDueSoon: isDueSoon,
      isOverdue: isOverdue
    });
  }

  render() {
    const {
      value,
      formatString
    } = this.props;

    const {
      isOverdue,
      isDueSoon
    } = this.state;

    const formattedValue = <DisplayFormattedDatetime value={value} formatString={formatString ?? "MM/DD/YYYY"} />

    return isOverdue
      ? (
        <span className={styles.overdue}>
          {formattedValue}
        </span>
      )
      : isDueSoon
        ? (
          <>
            {formattedValue}
            <Icon title="Due soon">
              <HourglassEmptyOutlined style={{ verticalAlign: 'top' }} />
            </Icon>
          </>
        )
        : formattedValue;
  }
}

export const DueDateField = _DueDateField;