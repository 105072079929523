import {
  React,
  Helpers,
  _
} from "$Imports/Imports";

import { 
  QuoteEntryService, 
  IQuoteEntryServiceInjectedProps, 
  FreightTotalData
} from "$State/QuoteEntryFreezerService";

import { 
  ISelectedCompanyContext
 } from "$Providers/CompanyProvider";

import { 
  Quote, QuoteFreight 
} from "$Generated/api";

import { 
  CommodityDataEntryView 
} from "./CommodityDataEntryView";

const styles: {
  subSection: string;
  addressesContainer: string;
  milesCard: string;
} = require("./QuoteStopAddressesEntry.scss");

interface IQuoteStopFreightDimensionsEntryBaseProps {
  companyContext: ISelectedCompanyContext,
  thisQuoteStopIndex: number;
  quote: Quote;
  currentQuoteFreight: QuoteFreight[];
  freightTotalData: FreightTotalData;
  viewOnly: boolean;
  themeColor: string;
}

type IQuoteStopFreightDimensionsEntryProps = IQuoteStopFreightDimensionsEntryBaseProps & IQuoteEntryServiceInjectedProps;

export class _QuoteStopFreightDimensionsEntry extends React.PureComponent<IQuoteStopFreightDimensionsEntryProps> {

  render() {
    const {
    } = this.props.QuoteEntryService.getState();

    const {
      companyContext,
      thisQuoteStopIndex,
      quote,
      currentQuoteFreight,
      freightTotalData,
      viewOnly,
      themeColor
    } = this.props;
    
    const quoteFreights = quote.quoteFreights;
    const totalNumberOfPieces: number = _.reduce(_.map(quoteFreights, qsf => (qsf.isGrouped ? 1 : qsf.numberOfPieces)), ((sum, p) => (sum ?? 0) + (p ?? 0))) ?? 0;

    return (      
      <>
        <div className={styles.subSection} style={{ backgroundColor: themeColor }}>
          Freight Dimensions
          <span>Items: {totalNumberOfPieces}</span>
          {companyContext.companyKey === "KL" &&
            <span>
              {(freightTotalData.market === "None" || Helpers.isNullOrUndefined(freightTotalData.market))
                ?
                "No market selected"
                :
                `${freightTotalData.market} Market`}
            </span>
          }
        </div>
        <CommodityDataEntryView 
          quote={quote} 
          viewOnly={viewOnly}
          freightTotalData={freightTotalData}
          currentQuoteFreight={currentQuoteFreight}
          thisQuoteStopIndex={thisQuoteStopIndex} />
      </>
    );
  }
}

export const QuoteStopFreightDimensionsEntry = QuoteEntryService.inject(_QuoteStopFreightDimensionsEntry);