import {
  React,
  bind,
  _,
  NumberFormat,
  NumberFormatValues
} from "$Imports/Imports";

import {
  AdvanceTextField,
  CardLinedHeader,
  PhoneNumberInputField
} from "$Imports/CommonComponents";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps,
  NegotiatedQuoteDataEntry
} from "$State/QuoteEntryFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import { 
  Quote
} from "$Generated/api";

import { 
  TextField
} from "$Imports/MaterialUIComponents";

interface IContactInfoCardBaseProps {
  quote: Quote,
  viewOnly: boolean,
  hasQuoteChanged: boolean
}

type IContactInfoCardProps = IContactInfoCardBaseProps & IQuoteEntryServiceInjectedProps;

const styles: {
  contactInfoCard: string;
  textfield: string;
} = require('./ContactInfoCard.scss');

class _ContactInfoCard extends React.Component<IContactInfoCardProps> {

  @bind
  private _onNegotiatedQuoteChange(value?: NumberFormatValues) {
    this.props.QuoteEntryService.updateNegotiatedQuoteDataEntry({ negotiatedRateValue: value?.floatValue });
  }

  @bind
  private _onContactNameChange(contactName: string) {
    this.props.QuoteEntryService.updateQuote({ contactName: contactName });
  }

  @bind
  private _onContactPhoneNumberChange(contactPhoneNumber: string | undefined) {
    this.props.QuoteEntryService.updateQuote({ contactPhoneNumber: contactPhoneNumber });
  }

  render() {
    const {
      quoteValidationErrors,
      negotiatedQuoteDataEntry,
      negotiatedRateDataEntryValidationErrors
    } = this.props.QuoteEntryService.getState();

    const {
      quote,
      viewOnly,
      hasQuoteChanged
    } = this.props

    const validationParserContactInfo = new ValidationErrorParser<Quote>(quoteValidationErrors);
    const validationsNegotiatedRates = new ValidationErrorParser<NegotiatedQuoteDataEntry>(negotiatedRateDataEntryValidationErrors);

    return (
      <CardLinedHeader
        className={styles.contactInfoCard}
        titleText="Rate & Contact Information"
      >
        <div>
          <NumberFormat
            allowNegative={false}
            decimalSeparator="."
            decimalScale={2}
            thousandSeparator={true}
            disabled={viewOnly || quote.status === "Converted" || hasQuoteChanged || !quote.calculatedRates}
            prefix="$"
            label="Negotiated Rate"
            value={negotiatedQuoteDataEntry.negotiatedRateValue?.toFixed(2) ?? ""}
            customInput={TextField}
            onValueChange={this._onNegotiatedQuoteChange}
            error={!validationsNegotiatedRates?.isValid("negotiatedRateValue")}
            helperText={validationsNegotiatedRates?.validationMessage("negotiatedRateValue")}
            className={styles.textfield}
          />
          <AdvanceTextField
            value={quote.contactName ?? ""}
            onDebouncedChange={this._onContactNameChange}
            label={"Contact Name"}
            error={!validationParserContactInfo.isValid('contactName')}
            helperText={validationParserContactInfo.validationMessage('contactName')}
            className={styles.textfield}
            disabled={viewOnly || quote.status === "Converted"}
          />
          <PhoneNumberInputField
            label="Phone Number"
            name="phoneNumber"
            onPhoneNumberChange={this._onContactPhoneNumberChange}
            value={quote.contactPhoneNumber ?? ""}
            error={!validationParserContactInfo.isValid("contactPhoneNumber")}
            helperText={validationParserContactInfo.validationMessage("contactPhoneNumber")}
            className={styles.textfield}
            disabled={viewOnly || quote.status === "Converted"}
          />
        </div>
      </CardLinedHeader>
    );
  }
}

export const ContactInfoCard = QuoteEntryService.inject(_ContactInfoCard);