import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  logisticsGreen,
  highlightGreen,
  highlightBlue,
  transportBlue
} from "$Themes/index";

const styles: {
  container: string;
  toggle: string;
} = require("./ToggleBox.scss");

/*
  usage:
  Define a list of options like this in your component/freezer:

  const optionTypes = ["Option 1", "Option 2"];
  type OptionType = typeof optionTypes[number];

  Then in your component's state/props you can use OptionType to have
  a pre-defined list of values.
  In the toggleClick method you'll still need to do "as OptionType" though, sorry.

  In the future we may want to separate the toggle value and display value. another exercise for the reader.
*/

interface IToggleBoxProps {
  selectedValue: string;
  options: string[];
  toggleClick: (value: string, mappedId: number | undefined) => void;
  companyId?: number;
  mappedId?: number;
}

export class ToggleBox extends React.PureComponent<IToggleBoxProps> {

  @bind
  private onClick(value: string) {
    this.props.toggleClick(value, this.props.mappedId);
  }

  @bind
  private getActiveToggle() {
      return {
        backgroundColor: this.props.companyId === 1 ? highlightBlue : highlightGreen,
        color: this.props.companyId === 1 ? transportBlue : logisticsGreen
      }
  }

  render() {

    const activeToggle = this.getActiveToggle();

    return (
      <div className={styles.container}>
        {
          _.map(this.props.options, (opt, idx) => (
            <div
              className={styles.toggle}
              style={this.props.selectedValue === opt ? activeToggle : undefined}
              onClick={() => this.onClick(opt)}
              key={idx}
            >
              {opt}
            </div>
          ))
        }
      </div>
    );
  }
}