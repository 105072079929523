import {
  React,
  bind
} from "$Imports/Imports";

import {
  ApplicationSetting
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Button
} from "$Imports/MaterialUIComponents";

interface IDefaultMarketEditModalBaseProps {
  isModalOpen?: boolean;
  defaultMarketSetting?: ApplicationSetting;
  onMarketTypeSettingChange?: (applicationSetting: Partial<ApplicationSetting>) => void
  onSaveMarketTypeSetting?: () => void;
}

type IDefaultMarketEditModalProps = IDefaultMarketEditModalBaseProps;

export class DefaultMarketEditModal extends React.PureComponent<IDefaultMarketEditModalProps> {

  @bind
  private _onMarketTypeSettingChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.onMarketTypeSettingChange) {
      this.props.onMarketTypeSettingChange({ settingsValue: event.target.value})
    }
  }

  @bind
  private _onSave() {
    if (this.props.onSaveMarketTypeSetting) {
      this.props.onSaveMarketTypeSetting();
    }
  }

  render() {
    const {
      isModalOpen,
      defaultMarketSetting
    } = this.props;

    return (
      <Dialog open={isModalOpen ?? false}>
        <DialogTitle>
          Default Market Type
        </DialogTitle>
        <DialogContent>
          <FormControl>
            <FormLabel>
              Market Type
            </FormLabel>
            <RadioGroup id={`${defaultMarketSetting?.id}`} value={defaultMarketSetting?.settingsValue ?? "None"} onChange={this._onMarketTypeSettingChange} >
              <FormControlLabel value={"Primary"} control={<Radio /> } label="Primary" />
              <FormControlLabel value={"Secondary"} control={<Radio /> } label="Secondary" />
              <FormControlLabel value={"None"} control={<Radio /> } label="None" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this._onSave}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

}