import {
    CustomerHourDayOfWeekEnum
} from "$Generated/api";

export default class AppConstants {
    public static DayOfWeekEnumArray: CustomerHourDayOfWeekEnum[] = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ];
}