import {
  React,
  bind,
  _,
  moment
} from "$Imports/Imports";

import {
  CustomerHour
} from "$Generated/api";

import AppConstants from "$Utilities/AppConstants";

const styles: {
  fieldBlock: string;
  fieldPair: string;
  fieldLabel: string;
  fieldValue: string;
} = require("./CustomerDetailView.scss");

interface IHoursOfOperationProps {
  hours: CustomerHour[];
}

export class HoursOfOperation extends React.PureComponent<IHoursOfOperationProps> {
  
  getDisplayHours(hh: CustomerHour | undefined) {
    if (hh?.allDay) return "All Day";
    else if (hh?.closed) return "Closed";
    else if (hh?.openTime && hh?.closeTime) {
      return `${moment(hh.openTime, "HH:mm:SS").format("h:mma")} - ${moment(hh.closeTime, "HH:mm:SS").format("h:mma")}`;
    }
    else return "Undefined";
  }

  render() {
    const {
      hours
    } = this.props;

    return (
      <div className={styles.fieldBlock}>
        <b>Hours of Operation</b>
        {
          _.map(AppConstants.DayOfWeekEnumArray, (day, idx) => (
            <div className={styles.fieldPair} key={idx}>
              <div className={styles.fieldLabel}>{day}:</div>
              <div className={styles.fieldValue}>{this.getDisplayHours(_.find(hours, h => h.dayOfWeek === day))}</div>
            </div>
          ))
        }
      </div>
    );
  }
}