import {
  _
} from "$Imports/Imports";

import {
  Address,
  AddressAddressTypeEnum
} from "$Generated/api";

export const getCurrent = (addresses: Address[] | undefined, type: AddressAddressTypeEnum): Address | undefined =>
  _.find(addresses, a => (a.isCurrent === true) && (a.addressType === type));

export const getPrevious = (addresses: Address[] | undefined, type: AddressAddressTypeEnum): Address | undefined =>
  _.flow(
    _.partialRight(_.filter, (a: Address) => (a.isCurrent === false) && (a.addressType === type)),
    _.partialRight(_.orderBy, (a: Address) => a.createdOn, "desc"),
    _.first
  )(addresses);
  