import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  EquipmentType
} from "$Generated/api";

import {
  Card,
  CardActions,
  CardHeader,
  Button,
  FormControlLabel,
  Switch
} from "$Imports/MaterialUIComponents";

import {
  DataTable,
  IDataTableColumn,
  AjaxActionIndicator,
  directionType
} from "$Imports/CommonComponents";

import {
  IEquipmentTypeServiceInjectedProps,
  EquipmentTypeService
} from "$State/EquipmentTypeFreezerService";

import {
  EditEquipmentTypeModal
} from "./EditEquipmentTypeModal";

import {
  ActionMenu
} from "./ActionMenu";

const styles: {
  mainContainer: string;
  actionArea: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface IEquipmentTypeViewPageBaseProps {

}

type IEquipmentTypeViewPageProps = IEquipmentTypeViewPageBaseProps
  & IEquipmentTypeServiceInjectedProps;

class _EquipmentTypeViewPage extends React.Component<IEquipmentTypeViewPageProps> {
  private readonly columns: Array<IDataTableColumn<EquipmentType>> = [
    {
      columnName: "name",
      columnFieldData: (x) => x.name,
      sortMethod: (x) => x.name,
      headerValue: "Name"
    },
    {
      columnName: "tmEquipmentCode",
      columnFieldData: (x) => x.tmEquipmentCode,
      sortMethod: (x) => x.tmEquipmentCode,
      headerValue: "TM Equipment ID"
    },
    {
      columnName: "isActive",
      columnFieldData: (x) => x.isActive ? "Yes" : "No",
      sortMethod: (x) => x.isActive,
      headerValue: "Active"
    },
    {
      columnName: "action",
      columnFieldData: (x) => (
        <ActionMenu
          equipmentType={x}
          onEdit={this._onEdit}
        />
      ),
      headerValue: ""
    }
  ];

  componentDidMount() {
    this.props.equipmentTypeService.fetchEquipmentTypes();
  }

  @bind
  private _onSortChange(event: React.MouseEvent<HTMLElement>, columnName: string | undefined, direction: directionType) {
    this.props.equipmentTypeService.setSortState({
      sortColumnName: columnName,
      sortDirection: direction,
    });
  }

  @bind
  private _onActiveFilterChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.equipmentTypeService.setFilterActive(checked);
  }

  @bind
  private _onAddClick() {
    this.props.equipmentTypeService.openEditModal();
  }

  @bind
  private _onEdit(equipmentType: EquipmentType) {
    this.props.equipmentTypeService.openEditModal(equipmentType);
  }

  @bind
  private _onChange(value: Partial<EquipmentType>) {
    this.props.equipmentTypeService.onChangeFromModal(value);
  }

  @bind
  private _onSave() {
    this.props.equipmentTypeService.saveEquipmentType();
  }

  @bind
  private _closeModal() {
    this.props.equipmentTypeService.closeEditModal();
  }

  render() {
    const {
      equipmentTypeFetchResults,
      equipmentTypeSaveResults,
      sortState,
      filterActive,
      equipmentTypeModalState
    } = this.props.equipmentTypeService.getState();
    let equipmentTypeData = equipmentTypeFetchResults.data ?? [];

    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader title="Equipment" />
          <CardActions
            disableSpacing={true}
            className={styles.actionArea}
          >
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={filterActive}
                    onChange={this._onActiveFilterChange}
                  />
                )
              }
              label="Active"
            />
            <Button
              color="primary"
              onClick={this._onAddClick}
            >
              Add
            </Button>
          </CardActions>
          <AjaxActionIndicator
            state={[equipmentTypeFetchResults, equipmentTypeSaveResults]}
          />
          <DataTable
            columns={this.columns}
            data={equipmentTypeData}
            defaultSortColumnName={sortState.sortColumnName}
            defaultSortDirection={sortState.sortDirection}
            onSortChange={this._onSortChange}
          />
        </Card>
        <EditEquipmentTypeModal
          isOpen={equipmentTypeModalState.isOpen}
          equipmentType={equipmentTypeModalState.equipmentType}
          validationErrors={equipmentTypeModalState.validationErrors}
          canSave={!equipmentTypeSaveResults.isFetching}
          onSave={this._onSave}
          onCancel={this._closeModal}
          onChange={this._onChange}
        />
      </div>
    );
  }
}

export const EquipmentTypeViewPage = EquipmentTypeService.inject(
  _EquipmentTypeViewPage
);
