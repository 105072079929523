import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  CardLinedHeader,
  ToggleBox
} from "$Imports/CommonComponents";

import {
  KPIGrid
} from "./KPIGrid";

import {
  SalesLeaderboard
} from "./SalesLeaderboard";

import {
  BookedQuotesByWeek
} from "./BookedQuotesByWeek";

import {
  ISalesRepHomeServiceInjectedProps,
  SalesRepHomeService
} from "$State/SalesRepHomeFreezerService";

interface IOwnProps {
  companyId?: number;
}

const styles: {
  divider: string;
} = require("./MyMetrics.scss");

const metricTypes = ["Just Me", "Everyone"];
type MetricType = typeof metricTypes[number];

interface IOwnState {
  selectedMetricType: MetricType;
}

type OwnProps = IOwnProps
  & ISalesRepHomeServiceInjectedProps;

class _MyMetrics extends React.Component<OwnProps, IOwnState> {
  state: IOwnState = {
    selectedMetricType: "Just Me"
  };

  @bind
  selectMetricType(metricType: string) {
    if (metricType === this.state.selectedMetricType) {
      return;
    }

    this.setState({
      selectedMetricType: metricType as MetricType
    });
    this.props.salesRepHomeService.fetchKpis(this.props.companyId, metricType);
  }

  render() {
    const {companyId} = this.props;
    const {
      selectedMetricType
    } = this.state;
    
    return (
      <CardLinedHeader
        titleText="My Metrics"
        style={{ display: "flex", flexDirection: "column", flex: "1" }}
        titleComponents={
          <ToggleBox
            selectedValue={selectedMetricType}
            options={metricTypes}
            toggleClick={this.selectMetricType}
            companyId={companyId}
          />
        }
      >
        <KPIGrid  companyId={this.props.companyId} selectedMetricType={selectedMetricType} />
        <hr className={styles.divider} />
        <SalesLeaderboard />
        <hr className={styles.divider} />
        <BookedQuotesByWeek />
      </CardLinedHeader>
    );
  }
}

export const MyMetrics = SalesRepHomeService.inject(_MyMetrics);