import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional,
  moment
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  CapacityRateApiFactory,
  CapacityRate
} from "$Generated/api";

import {
  directionType
} from "$Imports/CommonComponents";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

export type formModeType = "add" | "edit" | "none";

interface ICapacityRateState {
  formMode: formModeType;
  editAddValidationErrors: ValidationError | null;
  capacityRateFetchResults: IAjaxState<CapacityRate[]>;
  capacityRateAddResults: IAjaxState<CapacityRate>;
  capacityRateUpdateResults: IAjaxState<CapacityRate>;
  capacityRateDeleteResults: IAjaxState;
  editAddCapacityRate: CapacityRate | null;
  tempCapacityRate: CapacityRate | null;
  editAddImmediateStart: boolean;
  editAddCapacityRateError: string;
  sortState: ISortState;
  showHistory: boolean;
  rateFromServer: CapacityRate | null;
}

interface ISortState {
  sortColumnName?: string;
  sortDirection?: directionType;
}

const InjectedPropName = "capacityRateService";

const CapacityRateValidationSchema: SchemaOf<NullableOptional<CapacityRate>> = yup.object({
  id: yup.number().notRequired(),
  companyId: yup.number().notRequired(),
  startDateTime: yup.date().nullable().typeError("Invalid Date").required("Start Date/Time is required."),
  endDateTime: yup.date().nullable().typeError("Invalid Date").required("End Date/Time is required."),
  isFavorite: yup.boolean().required().defined(),
  favoriteName: yup.string()
    .when('isFavorite', {
      is: true,
      then: yup.string().required("Favorite name is required.").nullable(true),
      otherwise: yup.string().notRequired().nullable(true)
    })
    .test("favoriteName", "${message}", (value: any, testContext: any) => {
      const favoritedCapacityRates: CapacityRate[] = testContext.options.context.currentFavoritedCapacityRates;
      const isFavorited: boolean = testContext.options.context.isFavorited;
      const formMode: formModeType = testContext.options.context.formMode;
      const currentCapacityRate: CapacityRate = testContext.options.context.currentCapacityRate;

      var favoriteCapacityNames: (string|undefined)[] = [];

      if (formMode === "edit") {
        favoriteCapacityNames = favoritedCapacityRates
          .filter(fCr => fCr.id !== currentCapacityRate.id)
          .map(fCr => fCr.favoriteName?.toLowerCase().replace(/\s+/g, ''))
      }
      else if (formMode === "add") {
        favoriteCapacityNames = favoritedCapacityRates.map(fCr => fCr.favoriteName?.toLowerCase().replace(/\s+/g, ''));
      }

      if (isFavorited && value && favoriteCapacityNames.includes(value.toLowerCase().replace(/\s+/g, ''))) {
        return testContext.createError({ message: "This favorite name is already in use."})
      }

      return true;
    }),
  modifiedOn: yup.date().notRequired(),
  createdOn: yup.date().notRequired(),
  reason: yup.string()
    .required("Capacity rate reason is required.")
    .max(100, "Capacity rate reason can not exceed 100 characters."),
  originRegions: yup.array().notRequired().nullable(true), // validation is contingent on the originZones being set; handled in isValidCapacityModel
  destinationRegions: yup.array().notRequired().nullable(true), // validation is contingent on the destinationZones being set; handled in isValidCapacityModel
  originZones: yup.array().notRequired().nullable(true), // validation is contingent on the originRegions being set; handled in isValidCapacityModel
  destinationZones: yup.array().notRequired().nullable(true), // validation is contingent on the destinationRegions being set; handled in isValidCapacityModel
  rateValue: yup.number().required("Rate Value is required").defined().min(-0.99, "The rate value must be greater than -99%").max(0.99, "The rate value must be less than 99%"),
});

const initialState = {
  formMode: "none",
  editAddValidationErrors: null,
  editAddCapacityRate: null,
  tempCapacityRate: null,
  editAddImmediateStart: false,
  editAddCapacityRateError: "",
  capacityRateFetchResults: managedAjaxUtil.createInitialState(),
  capacityRateAddResults: managedAjaxUtil.createInitialState(),
  capacityRateUpdateResults: managedAjaxUtil.createInitialState(),
  sortState: {
    sortColumnName: "startDateTime",
    sortDirection: "desc",
  },
  showHistory: false,
  capacityRateDeleteResults: managedAjaxUtil.createInitialState(),
  rateFromServer: null,
} as ICapacityRateState;

class CapacityRateFreezerService extends FreezerService<ICapacityRateState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public addCapacityRate() {
    const now = new Date();
    now.setSeconds(0);

    this.freezer.get().set({
      editAddCapacityRate: {
        isFavorite: false,
        startDateTime: new Date(),
        endDateTime: undefined,
      },
      tempCapacityRate: {
        originRegions: [],
        destinationRegions: [],
        originZones: [],
        destinationZones: []
      },
      editAddImmediateStart: false,
      editAddCapacityRateError: "",
      formMode: "add"
    });
  }

  public setShowHistory(showHistory: boolean) {
    this.freezer.get().set({ showHistory });
  }

  public updateTempCapacityRate(rate: Partial<CapacityRate>) {
    this.freezer.get().tempCapacityRate?.set(rate);
  }

  public updateCapacityRate(rate: Partial<CapacityRate>) {
    this.freezer.get().editAddCapacityRate?.set(rate);
  }

  public onChangeImmediateStart(immediateStart: boolean) {
    this.freezer.get().set({
      editAddImmediateStart: immediateStart
    });
  }

  public onToolTipFavoriteRowClick(capacityRate: CapacityRate) {
    this.freezer.get().editAddCapacityRate?.set(capacityRate);
  }

  public editCapacityRate(id: number | undefined) {
    const foundCapacity = _.find(this.freezer.get().capacityRateFetchResults.data, (d) => d.id === id);

    if (foundCapacity) {
      this.freezer.get().set({
        editAddCapacityRate: foundCapacity.toJS(),
        editAddImmediateStart: false,
        editAddCapacityRateError: "",
        formMode: "edit",
        tempCapacityRate: {
          originRegions: foundCapacity.originRegions?.toJS() ?? [],
          destinationRegions: foundCapacity.destinationRegions?.toJS() ?? [],
          originZones: foundCapacity.originZones?.toJS() ?? [],
          destinationZones: foundCapacity.destinationZones?.toJS() ?? []
        },
        rateFromServer: foundCapacity.toJS()
      });
    }
  }

  public clearEditAddForm() {
    this.freezer.get().set({
      editAddCapacityRate: null,
      editAddImmediateStart: false,
      editAddCapacityRateError: "",
      editAddValidationErrors: null,
      tempCapacityRate: null,
      formMode: "none",
      rateFromServer: null
    });
  }

  public setEditAddError(message: string) {
    this.freezer.get().set({
      editAddCapacityRateError: message
    });
  }

  public saveCapacityRate(companyId: number | undefined) {
    if (!companyId) {
      return;
    }

    if (this.freezer.get().formMode === "add") {
      this.saveAddCapacityRate(companyId);
    }

    if (this.freezer.get().formMode === "edit") {
      this.saveUpdateCapacityRate(companyId);
    }
  }

  public setSortState(sortState: Partial<ISortState>) {
    this.freezer.get().sortState.set(sortState);
  }

  private async saveUpdateCapacityRate(companyId: number) {
    const editAddCapacityRate = this.freezer.get().editAddCapacityRate?.toJS();
    const editAddImmediateStart = this.freezer.get().editAddImmediateStart;

    const errors = await this.isValidCapacityModel();

    if (errors) {
      return;
    }

    if (editAddCapacityRate && editAddImmediateStart) {
      editAddCapacityRate.startDateTime = new Date();
    }

    if (editAddCapacityRate && !editAddCapacityRate.isFavorite && editAddCapacityRate.favoriteName) {
      editAddCapacityRate.favoriteName = undefined;
    }  

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "capacityRateUpdateResults",
      onExecute: (apiOptions, param, options) => {
        const factory = CapacityRateApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.updateCapacityRate(param);
      },
      params: {
        immediateStart: editAddImmediateStart,
        body: editAddCapacityRate,
      },
      onOk: (data) => {
        this.clearEditAddForm();
        this.fetchCapacities(companyId, true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save capacity rate model.");
      }
    });
  }

  public fetchCapacities(companyId: number | undefined, forceUpdate: boolean = false) {
    const { capacityRateFetchResults } = this.freezer.get();

    if (!companyId || (capacityRateFetchResults.hasFetched && !forceUpdate)) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "capacityRateFetchResults",
      onExecute: (apiOptions, param, options) => {
        const factory = CapacityRateApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CapacityRateGet({
              companyId
            }
        );
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch capacity rate models.");
      }
    });
  }

  private async isValidCapacityModel(): Promise<ValidationError | null> {
    const editAddCapacityRate = this.freezer.get().editAddCapacityRate?.toJS();
    const immediateStart = this.freezer.get().editAddImmediateStart;

    // refresh min date validation, if server record's start date before now, do not apply min validation
    const formMode = this.freezer.get().formMode;
    const rateFromServer = this.freezer.get().rateFromServer?.toJS();
    const minDate = formMode === 'edit' && moment(rateFromServer?.startDateTime).isBefore(moment()) ? rateFromServer?.startDateTime : new Date();

    const allCapacityRates = this.freezer.get().capacityRateFetchResults.data ?? [];
    const allFavoritedCapacityRates = allCapacityRates.filter(cR => cR.isFavorite);

    const isFavorited = this.freezer.get().editAddCapacityRate?.isFavorite;

    if ((!editAddCapacityRate?.originRegions || editAddCapacityRate?.originRegions.length === 0) &&
      (!editAddCapacityRate?.originZones || editAddCapacityRate?.originZones.length === 0)) {
      CapacityRateValidationSchema.fields.originRegions = yup.array().min(1, 'Must have one origin selected.').required("Origin is required.").defined("Origin is required.");
    } else {
      CapacityRateValidationSchema.fields.originRegions = yup.array().notRequired().nullable(true);
    }

    if ((!editAddCapacityRate?.destinationRegions || editAddCapacityRate?.destinationRegions.length === 0) &&
      (!editAddCapacityRate?.destinationZones || editAddCapacityRate?.destinationZones.length === 0)) {
      CapacityRateValidationSchema.fields.destinationRegions = yup.array().min(1, 'Must have one destination selected.').required("Destination is required.").defined("Destination is required.");
    } else {
      CapacityRateValidationSchema.fields.destinationRegions = yup.array().notRequired().nullable(true);
    }

    if (immediateStart) {
      CapacityRateValidationSchema.fields.startDateTime = yup.mixed().notRequired();
    } else {
      CapacityRateValidationSchema.fields.startDateTime = yup.date()
      .nullable()
      .typeError("Invalid Date")
      .required("Start Date/Time is required.")
      .test("startDateTime", "${message}", (value: any, testContext: any) => {
        const minDate = testContext.options.context.minDate;

        if (moment(value).isBefore(minDate,'minutes')) {
          return testContext.createError({ message: "Date must be greater than now." });
        }

        return true;
      });
    }

    CapacityRateValidationSchema.fields.endDateTime = yup.date()
      .nullable()
      .typeError("Invalid Date")
      .required("End Date/Time is required.")
      .min(new Date(), "Date must be greater than now.")
      .test("endDateTime", "${message}", (value: any, testContext: any) => {
        const immediateStart = testContext.options.context.immediateStart;

        if ((!immediateStart && moment(value).isBefore(testContext.parent.startDateTime)) || (immediateStart && moment().isAfter(value))) {
          return testContext.createError({ message: "End Date/Time cannot be before Start Date/Time" });
        }
        return true;
      });

    const errors = await validateSchema(CapacityRateValidationSchema, editAddCapacityRate, {
      abortEarly: false,
      context: {
        immediateStart: immediateStart,
        minDate: minDate,
        currentFavoritedCapacityRates: allFavoritedCapacityRates,
        isFavorited: isFavorited,
        formMode: formMode,
        currentCapacityRate: editAddCapacityRate
      }
    });
    this.freezer.get().set({ editAddValidationErrors: errors });
    
    return errors;
  }

  private async saveAddCapacityRate(companyId: number) {
    var editAddCapacityRate = this.freezer.get().editAddCapacityRate?.toJS();
    const immediateStart = this.freezer.get().editAddImmediateStart;

    const errors = await this.isValidCapacityModel();

    if (editAddCapacityRate && editAddCapacityRate.id) {
      editAddCapacityRate.id = 0;
    }

    if (editAddCapacityRate) {
      editAddCapacityRate.companyId = companyId;
    }

    if (errors) {
      return;
    }

    if (editAddCapacityRate && immediateStart) {
      editAddCapacityRate.startDateTime = new Date();
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "capacityRateAddResults",
      onExecute: (apiOptions, param, options) => {
        const factory = CapacityRateApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CapacityRatePost(param);
      },
      params: {
        body: editAddCapacityRate,
      },
      onOk: (data: any) => {
        if (data.success) {
          this.clearEditAddForm();
          this.fetchCapacities(companyId, true);
        } else {
          this.setEditAddError(data?.error?.Message ?? "");
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to add capacity rate model.");
      }
    });
  }

  public deleteCapacityRate(companyId: number | undefined, id: number) {
    if (!companyId) {
      return;
    }
    
    console.log(`api call to delete ${id}`)
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "capacityRateDeleteResults",
      onExecute: (apiOptions, param, options) => {
        const factory = CapacityRateApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CapacityRateIdDelete(param);
      },
      params: {
        companyId,
        id,
      },
      onOk: (data: any) => {
        if(!data.success) {
          console.log(data?.error?.Message);
          ErrorService.pushErrorMessage("Failed to delete capacity rate.");
        }
        this.fetchCapacities(companyId, true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to delete capacity rate.");
        this.fetchCapacities(companyId, true);
      }
    });
  }
}

export const CapacityRateService = new CapacityRateFreezerService();
export type ICapacityRateServiceInjectedProps = ReturnType<CapacityRateFreezerService["getPropsForInjection"]>;
