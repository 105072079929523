import {
  React,
  _
} from "$Imports/Imports";

import {
  CardLinedHeader, 
  DisplayFormattedDatetime, 
  DisplayFormattedNumber,
  QuoteLink
} from "$Imports/CommonComponents";

import { 
  FreightBillTooltip 
} from "./FreightBillTooltip";

import { 
  quoteStatusTextMap,
  cancellationReasonTextMap
} from "$Utilities/enumUtil";

import { 
  Quote,
  QuoteCancellationReasonEnum
} from "$Generated/api";

import { 
  LabelValueDisplay
} from "$Imports/CommonComponents";

import { 
  CURRENCY_FORMAT, 
  DATE_WITH_TIME_MERIDIAN_FORMAT 
} from "$Shared/utilities/formatUtil";

import { getDisplayedQuoteNumber } from "$Shared/utilities/helpers";

interface IQuoteInfoCardBaseProps {
  quote: Quote;
  approvalReasons: string | undefined;
  accessorialChargesValue: number;
  needsApproval: boolean | undefined;
  savedReasons: string | undefined;  
  createReminderButton: JSX.Element | undefined;
  actionButtons: JSX.Element;
}

const styles: {
  status: string;
  negotiatedRateEmphasis: string;
  rateModelCard: string;
  quoteButtons: string;
  rateModelContainer: string;
  labelValueDisplay: string;
  limitTextField: string;
  canceledStatus: string;
  quoteInfoContainer: string;
} = require('./RateInfoSection.scss');

export class QuoteInfoCard extends React.Component<IQuoteInfoCardBaseProps> {
  render() {

    const {
      quote,
      approvalReasons,
      accessorialChargesValue,
      needsApproval,
      savedReasons,
      createReminderButton,
      actionButtons
    } = this.props;

    const quoteStatusHeader = ((quote.status === "Pending") || (quote.status === "PendingNeedsCustomers")) && (quote.approvalReasons?.length && !quote.approvalReasons?.find(x => x.approvalStatus !== "Approved")) ? "Quote Approved"
      : ((quote.status === "Pending" || quote.status === "PendingNeedsCustomers") && needsApproval) ? "Pending Approval"
        : quote.status === "Declined" ? "Quote Declined"
          : quote.status === "ApprovalNeeded" ? approvalReasons
            : quote.status === "AcceptanceRejected" ? "Quote Rejected"
              : "";

    const negotiatedRate = quote.negotiatedRate ? quote.negotiatedRate + accessorialChargesValue : undefined;

    const showExpirationDate = quote.status === "ApprovalNeeded" || quote.status === "Pending" || quote.status === "PendingNeedsCustomers";

    return (
      <CardLinedHeader
        className={styles.rateModelCard}
        titleText={quote.quoteType === "Quick" ? "Quote Details" : quote.quoteNumber != undefined ? `Quote Q${quote.quoteNumber}` : "Quote"}
        titleComponents={(
          quote.quoteType !== "Quick" &&
          <div>
            <span className={styles.status}>{quoteStatusHeader}</span>
            {createReminderButton}
          </div>
        )}
      >
        <div className={styles.quoteInfoContainer}>
          {quote.quoteType !== "Quick" &&
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Negotiated Rate"}
              value={<DisplayFormattedNumber
                value={negotiatedRate}
                formatString={CURRENCY_FORMAT}
              />}
              valueClass={styles.negotiatedRateEmphasis}
            />
          </div>
          }
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Status"}
              value={quoteStatusTextMap[quote.status ?? ""]}
              valueClass={quote.status === "Canceled" ? styles.canceledStatus : ""}
            />
          </div>
          {(quote.status === "Accepted" || quote.status === "Canceled") &&
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"FB#"}
              value={<FreightBillTooltip
                quote={quote}
              />}
            />
          </div>
          }
          {quote.quoteType !== "Quick" &&
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Note"}
              value={quote.notes}
              valueClass={styles.limitTextField}
              valueTitle={quote.notes}
            />
          </div>
          }
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Sales Representative"}
              value={`${quote.createdBy?.firstName} ${quote.createdBy?.lastName}`}
            />
          </div>
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Quote Date"}
              value={<DisplayFormattedDatetime value={quote.quoteDate} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />}
            />
          </div>
          {quote.status === "Canceled" && 
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Cancellation Reason"}
              value={cancellationReasonTextMap[quote.cancellationReason as QuoteCancellationReasonEnum]}
            />
          </div>}
          {quote.cancellationDetails && 
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Cancellation Details"}
              value={quote.cancellationDetails}
              valueClass={styles.limitTextField}
              valueTitle={quote.cancellationDetails}
            />
          </div>
          }
          {showExpirationDate &&
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Expiration Date"}
              value={<DisplayFormattedDatetime value={quote.expirationDate} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />}
            />
          </div>
          }
          {quote.customerQuote?.linkName &&
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Converted From"}
              value={<QuoteLink
                quoteId={quote.customerQuote.linkId}
                quoteNumber={getDisplayedQuoteNumber(quote.customerQuote.linkName, undefined, "CustomerQuote")}
                isCustomerQuote={true}
              />}
            />
          </div>
          }
          {!!quote.declineReasonText &&
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Declined Reason"}
              value={quote.declineReasonText}
            />
          </div>
          }
          {(quote.status === "ApprovalNeeded") && savedReasons &&
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Approval Needed Reason(s)"}
              value={savedReasons}
            />
          </div>
          }
          {(quote.status === "Converted") &&
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Conversion Date"}
              value={<DisplayFormattedDatetime value={quote.modifiedOn} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />}
            />
          </div>
          }
          <div className={styles.quoteButtons}>
            {actionButtons}
          </div>
        </div>        
      </CardLinedHeader>
    );
  }
}