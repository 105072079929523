// shim to reduce magic numbers / magic strings

import {
  logisticsGreen,
  caTerminalOrange,
  waTerminalPurple,
  transportBlue
} from "$Themes/index";

const kaiserLogo = require("../images/kaiser-red-small.png") as string;
const kaiserLogLogo = require("../images/kaiser-log-small.png") as string;
const kaiserTransLogo = require("../images/kaiser-trans-small.png") as string;

export enum CompanyEnum {
  Transport = 1,
  Logistics = 2
}

export const COMPANY_MAP: { [value in CompanyEnum]: { name: string, color: string } } = {
  "1": { name: "Transport", color: transportBlue },
  "2": { name: "Logistics", color: logisticsGreen }
};

export enum TerminalEnum {
  Logistics = "0",
  Wisconsin = "1",
  California = "2",
  Washington = "4"
}

// all available terminals; may want to load from API at some point, for now manually map here
// "Logistics" represents a "company" with possible terminals of its own
export const TERMINAL_MAP: { [value in TerminalEnum]: { name: string, color: string } } = {
  "0": { name: "Logistics", color: logisticsGreen },
  "1": { name: "WI Terminal", color: transportBlue },
  "2": { name: "CA Terminal", color: caTerminalOrange },
  "4": { name: "WA Terminal", color: waTerminalPurple }
};

export function getCompanyLogo (companyId: number | undefined) {
  if (companyId == undefined){
    return kaiserLogo;
  }
  return companyId == CompanyEnum.Logistics ? kaiserLogLogo : kaiserTransLogo;
}