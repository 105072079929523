import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  AuditDataChange,
  AuditDataChangeAuditLog,
  Employee,
  QuoteStop
} from "$Generated/api";

import {
  AjaxActionIndicator,
  CardLinedHeader,
  DisplayFormattedDatetime
} from "$Imports/CommonComponents";

import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from "$Imports/MaterialUIComponents";

import {
  DATE_WITH_TIME_MERIDIAN_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  EditHistoryDetails
} from "./EditHistoryDetails";

import { ActionMenu } from "$Pages/SalesPortalView/EditHistoryComponents/ActionMenu";

interface IEditHistoryCardSProps {
  quoteStops: QuoteStop[];
  auditLogs: AuditDataChangeAuditLog[];
  isFetching: boolean;
}

interface IEditHistoryCardState {
  detailAuditLog: AuditDataChangeAuditLog | undefined;
  showDetails: boolean;
}

const styles: {
  historyCard: string;
  message: string;
} = require("./EditHistoryCard.scss");

export class _EditHistoryCard extends React.PureComponent<IEditHistoryCardSProps, IEditHistoryCardState> {

  state: IEditHistoryCardState = {
    detailAuditLog: undefined,
    showDetails: false
  }

  private readonly columns: GridColDef[] = [
    {
      headerName: "Datetime",
      field: "createdOn",
      renderCell: (params: GridRenderCellParams<Date | undefined>) =>
        <DisplayFormattedDatetime value={params.value} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} />,
      width: 200,  
      sortable: true
    }, {
      headerName: "User",
      field: "employee",
      valueGetter: (params: GridValueGetterParams<Employee, AuditDataChangeAuditLog>) => params.value ? `${params.value.firstName} ${params.value.lastName}` : "",
      minWidth: 120,
      flex: 1,
      sortable: true
    }, {
      headerName: "Field",
      field: "log",
      valueGetter: (params: GridValueGetterParams<AuditDataChange, AuditDataChangeAuditLog>) => {
        if (params.value?.propertyName) {
          if (params.value.propertyName === "Address" && params.value.message) {
            return params.value.message.split("~")[0];
          }
          if (params.value.propertyName === "Acc Chg") {
            return params.value.message ?? params.value.newPropertyValue?.split("~")[0];
          }
          if (params.value.tableName === 'QuoteStop') {
            const quoteStopId = _.find(params.value.relatedEntities, e => e.entityType === 'QuoteStop')?.identifier;
            const stopNumber = _.find(this.props.quoteStops, s => s.id === quoteStopId)?.stopNumber;
            return this.props.quoteStops.length > 1 && stopNumber ? `${params.value.propertyName} (Stop ${stopNumber})` : params.value.propertyName;
          }
          return params.value.propertyName;
        }
        return "";
      },
      minWidth: 150,
      flex: 1,
      sortable: true
    }, {
      headerName: "",
      field: "actions",
      width: 56,
      resizable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<AuditDataChangeAuditLog>) => (
        <ActionMenu auditLog={params.row} openDetails={this.openAuditDetails} />
      )
    }];

  @bind
  private openAuditDetails(auditLog: AuditDataChangeAuditLog) {
    this.setState({
      detailAuditLog: auditLog,
      showDetails: true
    });
  }

  @bind
  private closeAuditDetails() {
    this.setState({
      detailAuditLog: undefined,
      showDetails: false
    });
  }

  render() {
    const {
      quoteStops,
      auditLogs,
      isFetching
    } = this.props;

    const {
      detailAuditLog,
      showDetails
    } = this.state;

    return (
      <CardLinedHeader className={styles.historyCard} titleText="Edit History">
        <AjaxActionIndicator showProgress={isFetching} />
        {
          showDetails && detailAuditLog ?
          <EditHistoryDetails
            quoteStops={quoteStops}
            auditLog={detailAuditLog}
            closeDetails={this.closeAuditDetails}
          />
          :
          auditLogs && (auditLogs.length > 0) ? (
            <>
              <div style={{ height: "250px" }}>
                <DataGridPro
                  rows={auditLogs}
                  columns={this.columns}
                  density="compact"
                  hideFooter
                  disableSelectionOnClick
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "createdOn", sort: "desc" }]
                    }
                  }}
                />
              </div>
            </>
          ) : (
            <div className={styles.message}>This quote has not been edited.</div>
          )
        }
      </CardLinedHeader>
    )
  }
}

export const EditHistoryCard = _EditHistoryCard;
