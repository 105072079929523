import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  EquipmentType,
  EquipmentTypeValue,
  EquipmentApiFactory
} from "$Generated/api";

import {
  ISortState
} from "$Imports/CommonComponents";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

interface IEquipmentRateModalState {
  isOpen: boolean;
  equipmentRate: EquipmentTypeValue;
  validationErrors: ValidationError | null;
}

interface IEquipmentRateServiceState {
  equipmentRateFetchResults: IAjaxState<EquipmentType[]>;
  equipmentRateData: EquipmentTypeValue[];
  equipmentRateSaveResults: IAjaxState<EquipmentTypeValue>;
  filterActive: boolean;
  sortState: ISortState;
  equipmentRateModalState: IEquipmentRateModalState;
}

const InjectedPropName = "equipmentRateService";

const EquipmentTypeValueValidationSchema: SchemaOf<NullableOptional<EquipmentTypeValue>> = yup.object({
  id: yup.number().notRequired(),
  equipmentTypeId: yup.number().notRequired(),
  companyId: yup.number().notRequired(),
  percentRate: yup.number()
    .required("Equipment rate is required")
    .defined()
    .moreThan(-0.5, "Rate must be greater than -50%")
    .lessThan(1, "Rate must be less than 100%"),
  startDateTime: yup.date().notRequired(),
  endDateTime: yup.date().nullable().notRequired(),
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  createdById: yup.number().notRequired(),
  company: yup.mixed().notRequired(),
  equipmentType: yup.mixed().notRequired(),
  createdBy: yup.mixed().notRequired()
});

const initialState = {
  equipmentRateFetchResults: managedAjaxUtil.createInitialState(),
  equipmentRateData: [],
  equipmentRateSaveResults: managedAjaxUtil.createInitialState(),
  filterActive: true,
  sortState: {
    sortColumnName: "equipmentType",
    sortDirection: "asc",
  },
  equipmentRateModalState: {
    isOpen: false,
    equipmentRate: { },
    validationErrors: null
  }
} as IEquipmentRateServiceState;

class EquipmentRateFreezerService extends FreezerService<IEquipmentRateServiceState, typeof InjectedPropName> {

  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchEquipmentRates(companyId: number | undefined, forceUpdate: boolean = false) {
    const {
      equipmentRateFetchResults,
      filterActive
    } = this.freezer.get();

    if (!companyId || (equipmentRateFetchResults.hasFetched && !forceUpdate)) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "equipmentRateFetchResults",
      params: {
        companyId: companyId,
        activeOnly: filterActive
      },
      onExecute: (apiOptions, params, options) => {
        const factory = EquipmentApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getEquipmentRates(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch equipment rates.");
      },
      onOk: (data: EquipmentType[]) => {
        let equipmentRateData: EquipmentTypeValue[] = [];

        data.forEach(type => {
          if (type.equipmentTypeValues && type.equipmentTypeValues.length > 0) {
            type.equipmentTypeValues.forEach(value => value.equipmentType = {
              id: type.id,
              name: type.name
            });
            equipmentRateData = equipmentRateData.concat(type.equipmentTypeValues);
          } else {
            equipmentRateData.push({
              percentRate: 0,
              equipmentType: type,
              equipmentTypeId: type.id
            } as EquipmentTypeValue);
          }
          this.freezer.get().set({ equipmentRateData });
        });
      }
    });
  }

  public setFilterActive(companyId: number | undefined, filterActive: boolean) {
    this.freezer.get().set({ filterActive });
    this.fetchEquipmentRates(companyId, true);
  }

  public setSortState(sortState: Partial<ISortState>) {
    this.freezer.get().sortState.set(sortState);
  }

  public openEditModal(equipmentRate: EquipmentTypeValue, companyId: number | undefined) {
    let newRate = _.cloneDeep(equipmentRate);
    newRate.companyId = companyId;
    newRate.id = undefined;

    this.freezer.get().set({
      equipmentRateModalState: {
        isOpen: true,
        equipmentRate: newRate,
        validationErrors: null
      }
    });
  }

  public closeEditModal() {
    this.freezer.get().set({
      equipmentRateModalState: initialState.equipmentRateModalState
    });
  }

  public onChangeFromModal(value: Partial<EquipmentTypeValue>) {
    this.freezer.get().equipmentRateModalState.equipmentRate.set(value);
  }

  public async saveEquipmentRate() {
    const equipmentRateFromModal = this.freezer.get().equipmentRateModalState.equipmentRate;
    const errors = await validateSchema(EquipmentTypeValueValidationSchema, equipmentRateFromModal, {
      abortEarly: false
    })

    this.freezer.get().equipmentRateModalState.set({ validationErrors: errors });

    if (errors) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "equipmentRateSaveResults",
      params: {
        body: equipmentRateFromModal.toJS()
      },
      onExecute: (apiOptions, params, options) => {
        const factory = EquipmentApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.saveEquipmentRate(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save equipment rate.");
      },
      onOk: (data) => {
        this.closeEditModal();
        this.fetchEquipmentRates(equipmentRateFromModal.companyId, true);
      }
    });
  }
}

export const EquipmentRateService = new EquipmentRateFreezerService();
export type IEquipmentRateServiceInjectedProps = ReturnType<EquipmentRateFreezerService["getPropsForInjection"]>;
