// extracted by mini-css-extract-plugin
var _1 = "RateDiscrepancyModal__actions__pcRJ3";
var _2 = "RateDiscrepancyModal__addressBlock__Tst8m";
var _3 = "RateDiscrepancyModal__addressContainer__p9M_g";
var _4 = "RateDiscrepancyModal__addressQuoteStop__zLNoM";
var _5 = "RateDiscrepancyModal__comparisonContainer__K3sZ3";
var _6 = "RateDiscrepancyModal__comparisonField__tu1CN";
var _7 = "RateDiscrepancyModal__comparisonRow__tR6cE";
var _8 = "RateDiscrepancyModal__comparisonValue__J2iRK";
var _9 = "RateDiscrepancyModal__highlightedValue__GhiRW";
var _a = "RateDiscrepancyModal__reasons__eKKfl";
export { _1 as "actions", _2 as "addressBlock", _3 as "addressContainer", _4 as "addressQuoteStop", _5 as "comparisonContainer", _6 as "comparisonField", _7 as "comparisonRow", _8 as "comparisonValue", _9 as "highlightedValue", _a as "reasons" }
