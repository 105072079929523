import {
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf
} from "$Shared/imports/Yup";

import {
  LoadingInstruction,
  LoadingInstructionAppliesToEnum
} from "$Generated/api";

const LoadingInstructionValidationSchema: SchemaOf<NullableOptional<LoadingInstruction>> = yup.object({
  id: yup.number().notRequired(),
  instruction: yup.string()
    .required("Instruction is required")
    .max(1000, "Instruction cannot be longer than 1000 characters"),
  appliesTo: yup.mixed<LoadingInstructionAppliesToEnum>().oneOf(["ShippersOnly", "ConsigneesOnly", "BothShippersAndConsignees"]).notRequired(),
  isActive: yup.boolean().required(),
});

export {
  LoadingInstructionValidationSchema
}
