import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  SelectedCompanyConsumer
} from "$Providers/index";

import {
  QuotesView
} from "./QuotesView";

interface IQuickQuotesViewPageProps {

}

export class QuickQuotesViewPage extends React.Component<IQuickQuotesViewPageProps> {
  render() {
    return (
      <SelectedCompanyConsumer>
        {(companyContext) => { 
          return (
            <QuotesView companyId={companyContext.companyId} quoteType={"Quick"}/>
          );
        }}
      </SelectedCompanyConsumer>
    );
  }
}
