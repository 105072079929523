import {
  React,
  NumberFormat,
  NumberFormatValues,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  TextField
} from "$Imports/MaterialUIComponents";

import {
  Quote
} from "$Generated/api";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import { 
  UpchargeType 
} from "./QuoteStopEntry";

interface IFlatUpchargeEntryBaseProps {
  thisQuoteStopIndex: number,
  upchargeType: UpchargeType
  upchargeValue?: number,
  validationErrors?: ValidationError | null,
  viewOnly?: boolean,
  onUpchargeChange?: (value?: NumberFormatValues) => void,
  onUpchargeBlur?: () => void,
  onUpchargeFocus?: () => void
}

export class UpchargeEntry extends React.PureComponent<IFlatUpchargeEntryBaseProps> {

  @bind
  private _onUpchargeChange(value: NumberFormatValues) {
    if (this.props.onUpchargeChange) {
      this.props.onUpchargeChange(value);
    }
  }

  @bind
  private _onUpchargeBlur() {
    if (this.props.onUpchargeBlur) {
      this.props.onUpchargeBlur();
    }
  }

  @bind
  private _onFlatUpchargeFocus() {
    if (this.props.onUpchargeFocus) {
      this.props.onUpchargeFocus();
    }
  }

  render() {

    const {
      upchargeValue,
      validationErrors,
      viewOnly,
      upchargeType,
      thisQuoteStopIndex
    } = this.props;

    const validationsParserQuote = new ValidationErrorParser<Quote>(validationErrors);

    return (
      <>
        <NumberFormat
          allowNegative={true}
          thousandSeparator={upchargeType === "Flat" ? true : false}
          disabled={viewOnly}
          prefix={upchargeType === "Flat" ? "$" : undefined}
          suffix={upchargeType === "Percent" ? "%" : undefined}
          label= {upchargeType === "Flat" ? "Flat $ Upcharge" : "% Upcharge"}
          value={upchargeValue ?? ""}
          customInput={TextField}
          fullWidth
          onValueChange={this._onUpchargeChange}
          onBlur={(event: React.ChangeEvent<{ name?: string; value: unknown; }>) => this._onUpchargeBlur()}
          onFocus={this._onFlatUpchargeFocus}
          error={!validationsParserQuote.isValidDeep(`${upchargeType === "Flat" ? `quoteStops[${thisQuoteStopIndex}].flatUpcharge` : `quoteStops[${thisQuoteStopIndex}].upchargePercentage`}`)}
          helperText={validationsParserQuote.validationMessageDeep(`${upchargeType === "Flat" ? `quoteStops[${thisQuoteStopIndex}].flatUpcharge` : `quoteStops[${thisQuoteStopIndex}].upchargePercentage`}`)}
        />
      </>
    )
  }
}