import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  moment,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  directionType
} from "$Imports/CommonComponents";

import {
  CommodityApiFactory,
  Commodity,
  CommodityRate
} from "$Generated/api";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

export interface ISortState {
  sortColumnName?: string;
  sortDirection?: directionType;
}

export interface EditCommodityRateState {
  openedAt: Date;
  current: CommodityRate | null;
  edited: CommodityRate | null;
  validationErrors: ValidationError | null;
  immediateStart: boolean;
}

interface ICommodityState {
  showHistory: boolean;
  sortState: ISortState;
  commodityFetchResults: IAjaxState<Commodity[], managedAjaxUtil.IError>;
  commodityVMFetchResults: IAjaxState<CommodityRate[], managedAjaxUtil.IError>;
  commoditySaveResults: IAjaxState<boolean>;
  commodityUndoResults: IAjaxState<boolean>;
  editState: EditCommodityRateState;
}

const InjectedPropName = "commodityRateService";

const CommodityRateValidationSchema: SchemaOf<NullableOptional<CommodityRate>> = yup.object({
  zoneId: yup.number().required(),
  zoneName: yup.string().notRequired(),
  companyId: yup.number().required(),
  startDateTime: yup.date().required().test("startDateTime", "${message}", (value: any, testContext: any) => {
    const openedAt = testContext.options.context.openedAt;
    const previous = testContext.options.context.previous;
    const immediateStart = testContext.options.context.immediateStart;

    if (moment(value).isBefore(openedAt)) {
      return testContext.createError({ message: "The start date and time cannot be in the past." });
    } else if (moment(value).isBefore(previous) || (immediateStart && moment().isBefore(previous))) {
      return testContext.createError({ message: "The start date and time must be after the start of the previous rate." });
    }

    return true;
  }),

  // should be after startDate but not editable from AddEditCommodity modal
  endDateTime: yup.date().notRequired(),

  commodityValues: yup.mixed().test("commodityValues", "${message}", (value: any, testContext: any) => {
    var isValid = true;

    Object.keys(value).forEach(tarpId => {
      var percentRate = value[tarpId]["percentRate"];
      if (percentRate == undefined || percentRate >= 1 || percentRate <= -1) {
        isValid = false;
      }
    });

    if (!isValid) {
      return testContext.createError({ message: "Error in commodity rate values." });
    }
    return isValid;
  })
});

const initialState = {
  showHistory: false,
  commodityFetchResults: managedAjaxUtil.createInitialState(),
  commodityVMFetchResults: managedAjaxUtil.createInitialState(),
  commoditySaveResults: managedAjaxUtil.createInitialState(),
  commodityUndoResults: managedAjaxUtil.createInitialState(),
  editState: {
    openedAt: new Date(),
    current: null,
    edited: null,
    validationErrors: null,
    immediateStart: false
  },
  sortState: {
    sortColumnName: "originZone",
    sortDirection: "asc",
  }
} as ICommodityState;

class CommodityRateFreezerService extends FreezerService<ICommodityState, typeof InjectedPropName> {

  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchCommodityRates(companyId: number | undefined, forceUpdate: boolean = false) {
    const {
      commodityVMFetchResults,
      showHistory
    } = this.freezer.get();

    if ((commodityVMFetchResults.hasFetched && !forceUpdate) || !companyId) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "commodityVMFetchResults",
      params: {
        showHistory,
        companyId: companyId
      },
      onExecute: (apiOptions, param, options) => {
        const factory = CommodityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CommodityCommodityRatesCompanyIdShowHistoryGet(param);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to retrieve commodity rate information.");
      }
    });
  }

  public setShowHistory(companyId: number | undefined, showHistory: boolean) {
    this.freezer.get().set({ showHistory });
    this.fetchCommodityRates(companyId, true);
  }

  public setSortState(sortState: Partial<ISortState>) {
    this.freezer.get().sortState.set(sortState);
  }

  public editCommodityRate(companyId: number | undefined, commodities: Commodity[], current: CommodityRate) {
    const now = new Date();
    now.setSeconds(0);

    // if the current record's startDateTime is in the future, the edited one must start then or later
    var minStart = _.cloneDeep(now);
    if (current.startDateTime && moment(current.startDateTime).isAfter(now)) {
      minStart = _.cloneDeep(current.startDateTime);
    }

    var edited: CommodityRate = {
      zoneId: current.zoneId,
      zoneName: current.zoneName,
      companyId: companyId,
      startDateTime: minStart,
      commodityValues: {}
    }
    commodities.forEach(commodity => {
      if (commodity.id !== undefined && edited.commodityValues !== undefined) {
        edited.commodityValues[commodity.id] = {
          percentRate: undefined
        }
      }
    });

    this.freezer.get().set({
      editState: {
        openedAt: now,
        current: current,
        edited: edited,
        validationErrors: null,
        immediateStart: false
      }
    });
  }

  public copyCurrentRates() {
    const { editState } = this.freezer.get().toJS();
    var { current, edited } = editState;

    if (current?.commodityValues !== undefined && edited?.commodityValues !== undefined) {
      Object.keys(current.commodityValues).forEach(tarpId => {
        if (current?.commodityValues !== undefined && edited?.commodityValues !== undefined) { // the foibles of typescript
          edited.commodityValues[tarpId].percentRate = current.commodityValues[tarpId] ? current.commodityValues[tarpId].percentRate : undefined;
        }
      });
    }

    this.freezer.get().set({
      editState: {
        ...editState,
        current,
        edited
      }
    });
  }

  public onPercentRateChanged(commodityId: number, value?: number) {
    const { editState } = this.freezer.get().toJS();
    var { edited } = editState;

    if (edited?.commodityValues !== undefined) {
      edited.commodityValues[commodityId].percentRate = value;
    }

    this.freezer.get().set({
      editState: {
        ...editState,
        edited: edited
      }
    });
  }

  public onStartDateChanged(date: Date) {
    const { editState } = this.freezer.get().toJS();
    var { edited } = editState;

    if (edited) {
      edited.startDateTime = date;
    }
    
    this.freezer.get().set({
      editState: {
        ...editState,
        edited: edited
      }
    });
  }

  public onImmediateStartChanged(immediateStart: boolean) {
    this.freezer.get().editState.set({ immediateStart });
  }

  public async saveCommodityRates(companyId: number | undefined) {
    const { editState } = this.freezer.get().toJS();
    const {
      edited,
      openedAt,
      current,
      immediateStart
    } = editState;

    const errors = await validateSchema(CommodityRateValidationSchema, edited, {
      abortEarly: false,
      context: {
        openedAt: openedAt,
        previous: current?.startDateTime,
        immediateStart: immediateStart
      }
    });
    this.freezer.get().set({
      editState: {
        ...editState,
        validationErrors: errors
      }
    });

    if (errors) {
      return;
    }

    if (edited && immediateStart) {
      edited.startDateTime = new Date();
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "commoditySaveResults",
      params: {
        body: edited || undefined
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CommodityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CommoditySavePost(params);
      },
      onOk: () => {
        this.clearEditForm();
        this.fetchCommodityRates(companyId, true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save commodity rates.");
      }
    });
  }

  public undoCommodityRates(companyId: number | undefined, undo: CommodityRate) {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "commodityUndoResults",
      params: {
        body: undo
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CommodityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CommodityUndoPost(params);
      },
      onOk: () => {
        this.fetchCommodityRates(companyId, true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to undo commodity rates.");
      }
    });
  }

  public clearEditForm() {
    this.freezer.get().set({
      editState: {
        openedAt: new Date(),
        current: null,
        edited: null,
        validationErrors: null,
        immediateStart: false
      }
    });
  }
}

export const CommodityRateService = new CommodityRateFreezerService();
export type ICommodityRateServiceInjectedProps = ReturnType<CommodityRateFreezerService["getPropsForInjection"]>;