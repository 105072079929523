import {
  React
} from "$Imports/Imports";

import {
  CustomerQuotesListView
} from "./CustomerQuotesListView";

import { SelectedCompanyConsumer } from "$Providers/CompanyProvider";

interface ICustomerQuotesListViewPageProps {}

export class CustomerQuotesListViewPage extends React.Component<ICustomerQuotesListViewPageProps> {
  render() {
    return (
      <SelectedCompanyConsumer>
        {(companyContext) => { 
          return (
            <CustomerQuotesListView companyId={companyContext.companyId}/>
          );
        }}
      </SelectedCompanyConsumer>
    );
  }
}