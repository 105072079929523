import {
  React,
  bind
} from "$Imports/Imports";

import {
  QuoteCarrierFlattened
} from "$Generated/api";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  Edit,
  Sell
} from "$Imports/MaterialUIIcons";

interface ICarrierActionMenuProps {
  carrier: QuoteCarrierFlattened;
  openSaleDetails: (carrier: QuoteCarrierFlattened) => void;
  openEdit: (carrier: QuoteCarrierFlattened) => void;
}

export class CarrierActionMenu extends React.PureComponent<ICarrierActionMenuProps> {

  @bind
  private salesInfoClick() {
    this.props.openSaleDetails(this.props.carrier);
  }

  @bind
  private editClick() {
    this.props.openEdit(this.props.carrier);
  }

  render() {
    return (<>
      <IconButton
        size="small"
        onClick={this.salesInfoClick}
      >
        <Sell fontSize="small" />
      </IconButton>
      <IconButton
        size="small"
        onClick={this.editClick}
      >
        <Edit fontSize="small" />
      </IconButton>
    </>);
  }
}