import {
  React,
  _
} from "$Imports/Imports"

import { 
  QuoteStop 
} from "$Generated/api";

import {
  StopFlagDisplay
} from "./StopFlagDisplay";

interface IOwnProps {
  quoteStops: QuoteStop[]
}

const StopFlagsDisplay = (props: IOwnProps) => {
  return (
    <>
      {_.map(props.quoteStops, (quoteStop, qsIdx) => (<StopFlagDisplay key={qsIdx} quoteStop={quoteStop} />))}
    </>
  )
}

export {
  StopFlagsDisplay
}