import {
  React,
  Helpers,
  bind,
  _
} from "$Imports/Imports";

import {
  RateModel,
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell
} from "$Imports/MaterialUIComponents";

import {
  formModeType
} from "$State/RateModelFreezerService";

import {
  AjaxActionIndicator,
  PercentageInputField,
  ConfirmCancelModal
} from "$Imports/CommonComponents";

import {
  ThemeProvider,
  ThemeOptions,
  createTheme
} from "$Imports/MaterialUIStyles"

const styles: {
  container: string;
} = require("./EditRateModelDefaults.scss");


const tableCellTheme: ThemeOptions = {
  components: {
    MuiTableCell: {
      styleOverrides: {

        head: {
          borderBottom: "none"
        },
        body: {
          borderBottom: "none"
        }
      }
    }
  }
}

interface IEditRateModelDefaultsProps {
  formMode: formModeType;
  rateModelData: RateModel[] | null;
  dataChanged?: () => any;
  isFetching?: boolean;
  onSave?: () => void;
  onCancelClick?: () => void;
  onRateModelsChanged?: (index: number, rateModels: Partial<RateModel>) => void;
  onCancelYes: () => void;
  onCancelNo: () => void;
  cancelOpen: boolean;
  invalidForm: boolean;
  isRateInvalid: (rate: RateModel) => boolean;
  invalidRateMessage: string;
}

interface IEditRateModelDefaultsState {
}

export class EditRateModelDefaults extends React.PureComponent<IEditRateModelDefaultsProps, IEditRateModelDefaultsState> {

  static defaultProps: Partial<IEditRateModelDefaultsProps> = {
    isFetching: false,
  };

  state: IEditRateModelDefaultsState = {
  };

  @bind
  private _onRateChange(index: number, value?: number) {
    if (this.props.onRateModelsChanged) {
      this.props.onRateModelsChanged(index, { defaultRate: value });
    }
  }

  render() {
    const {
      formMode,
      rateModelData,
      isFetching,
      invalidForm,
      invalidRateMessage,
      isRateInvalid
    } = this.props;

    return !Helpers.isNullOrUndefined(rateModelData) ? (
      <>
        <Dialog
          open={formMode === "edit default"}
          fullWidth={true}
        >
          <AjaxActionIndicator
            showProgress={isFetching}
          />
          <DialogTitle>
            Edit Kaiser Default TSS %
          </DialogTitle>
          <DialogContent className={styles.container}>
            <ThemeProvider 
              theme={(theme) => createTheme(
                tableCellTheme,
                theme
              )}
            >
               <Table>
                <TableHead>
                  <TableRow >
                    <TableCell >
                      Linear Foot
                    </TableCell>
                    <TableCell>
                      Kaiser Default TSS %
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    _.map(rateModelData, (d: RateModel, dIdx: number) => (
                      <TableRow key={dIdx}>
                        <TableCell >
                          {d.feet ? `${d.feet} feet ` : ""}
                        </TableCell>
                        <TableCell>
                          <PercentageInputField
                            required
                            error={invalidForm || isRateInvalid(d)}
                            helperText={(invalidForm || isRateInvalid(d)) && invalidRateMessage}
                            decimalScale={1}
                            value={d.defaultRate}
                            onPercentValueChange={(value?: number) => this._onRateChange(dIdx, value)}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </ThemeProvider>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.onCancelClick}
              disabled={isFetching}
              variant="outlined"
            >
              Cancel
          </Button>
            <Button
              color="primary"
              disabled={isFetching}
              onClick={this.props.onSave}
            >
              Save
          </Button>
          </DialogActions>
        </Dialog >
        <ConfirmCancelModal
          isOpen={this.props.cancelOpen}
          onCancelNo={this.props.onCancelNo}
          onCancelYes={this.props.onCancelYes}
        />
      </>
    ) : null;
  }
}
