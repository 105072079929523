import {
  Address,
  AddressAddressTypeEnum,
  Customer,
  EquipmentType,
  OtherFreightInfoApiFactory,
  PCMilerApiFactory,
  Question,
  QuestionApiFactory,
  Quote,
  QuoteApiFactory,
  QuoteFreight,
  QuoteQuestion,
  QuoteStatusEnum,
  QuoteStop,
  QuoteStopFreightQuestionAnswerEnum,
  QuoteStopRouteResults,
  SiteView,
  Tarp,
  TarpApiFactory,
  TripResult,
  ZipCodePair,
  ZipCodeValidationResult,
  OpsCodeView,
  Place,
  QuoteResultVM,
  CalculateRatingVariableResult,
  WorkflowState,
  QuoteCalculatedRate,
  CustomerContact,
  CustomerApiFactory,
  TarpValue,
  QuoteCalculateRatingResult,
  AuditLogApiFactory,
  AuditDataChangeAuditLog,
  RelatedEntity,
  QuickQuoteApiFactory,
  CancelQuoteParams,
  CustomerQuote,
  AccessorialChargeValue,
  OtherFreightInfosParams,
  OtherFreightInfoCriteria
} from "$Generated/api";

import {
  bind,
  FreezerService,
  Helpers,
  IAjaxState,
  managedAjaxUtil,
  moment,
  _
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  DateEntity
} from "$Pages/SalesPortalView/QuoteStopEntryComponents/QuoteStopEntry";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import * as quoteStopUtilities from "$Utilities/quoteStopUtil";

import {
  RateEngineResult
} from "$Utilities/rateEngineUtil";

import {
  SecurityContext
} from "$Utilities/Security/ApplicationSecuritySettings";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  CommodityService
} from "./CommodityFreezerService";

import {
  CompanySelectService
} from "./CompanySelectFreezerService";

import {
  DeclaredValueService
} from "./DeclaredValueFreezerService";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  NavigationService
} from "./NavigationFreezerService";

import {
  ApplicationSettingsService
} from "./ApplicationSettingsFreezerService";

import {
  CityStateService
} from "./CityStateFreezerService";

import {
  StateService
} from "./RegionFreezerService";

import {
  FreightTotalData,
  NegotiatedQuoteDataEntry,
  NegotiatedQuoteDataEntrySchema,
  QuoteFreightArraySchema,
  QuoteMarket,
  QuoteSchema,
  ResponseType,
  ZipCodePairSchema,
  RateVariableValidationSchema,
  OtherFreightInfoValidationSchema
} from "./QuoteEntryValidationSchema";

import {
  DEFAULT_TIMEOUT,
  getTotalPieceCount
} from "$Utilities/ratingUtil";

import { getTrimmedZipPostalCode } from "$Shared/utilities/helpers";
import { jsDateConverter } from "$Shared/utilities/dateTimeUtil";

export type ApprovalNeededReasonType = "Over Dimensional" | "Low negotiated rate" | "Declared value" // 1, 2, 3 respectively

export type QuestionType = "Commodity" | "Flat $ Upcharge" | "% Upcharge"

export interface Response {
  questionId?: number;
  quoteStopIdx?: number;
  question?: Question;
  answer?: QuoteStopFreightQuestionAnswerEnum;
}

export type CustomerType = "Caller" | "Shipper" | "Consignee";

// customer alert modal title
export type AlertCustomerType = CustomerType | "Customer";

export interface QuoteStopShipperConsignee {
  zipCodePair: ZipCodePair,
  quoteStopIndex: number,
}

export interface OtherFreightInfo {
  poNumber?: string,
  opsCode?: string,
  siteId?: string,
  externalNotes?: string
}

export type ShippingNotes = "Expedited delivery" | "Hard shipper time" | "Hard consigner time";

interface IQuoteEntryState {
  quote: Quote;
  pcMilerValidationResults: IAjaxState<ZipCodeValidationResult[]>;
  quoteGetResults: IAjaxState<QuoteResultVM>;
  quoteValidationErrors: ValidationError | null;
  quoteSaveResults: IAjaxState<QuoteResultVM>;
  selectedCustomer: Customer | null;
  selectedCustomerContactsResults: IAjaxState<CustomerContact[]>;
  shipperContactsResults: IAjaxState<CustomerContact[]>;
  consigneeContactsResults: IAjaxState<CustomerContact[]>;
  saveQuoteContactResults: IAjaxState<Quote>;
  saveContactResults: IAjaxState<CustomerContact>;
  shadowQuote: Quote;
  previousOtherFreightInfo: OtherFreightInfo;
  saveOtherFreightInfoResults: IAjaxState<void>;
  otherFreightInfoValidationErrors: ValidationError | null;
  applyTripFlagsResults: IAjaxState<QuoteStopRouteResults>;
  tripMileageResults: IAjaxState<TripResult>;
  calculateRatingVariableResults: IAjaxState<CalculateRatingVariableResult>;
  rateEngineResult: RateEngineResult | undefined;
  workflowState: WorkflowState | undefined;
  upchargeTarpsFetchResults: IAjaxState<Tarp[]>;
  sitesFetchResults: IAjaxState<SiteView[]>;
  opsCodesFetchResults: IAjaxState<OpsCodeView[]>;
  opsCodes: OpsCodeView[] | undefined;
  sites: SiteView[] | undefined;
  commodityQuestionsFetchResults: IAjaxState<Question[]>;
  upchargeQuestionsFetchResults: IAjaxState<Question[]>;
  shipperConsigneeValidationErrors: ValidationError | null;
  flatUpchargeOnFocus: number | null;
  percentUpchargeOnFocus: number | null;
  modalQuestionType: QuestionType | null;
  questionModalIsOpen: boolean;
  upchargeResponses: Response[];
  negotiatedQuoteDataEntry: NegotiatedQuoteDataEntry;
  isApprovalNeededForLowRate: boolean;
  isApprovalNeededForDeclaredValue: boolean;
  negotiatedRateDataEntryValidationErrors: ValidationError | null;
  viewOnly: boolean;
  isOverDimensional: boolean;
  hasQuoteChanged: boolean;
  hasOtherInformationChanged: boolean;
  hasRateContactChanged: boolean;
  hasOverrideRateVariableChanged: boolean;
  freightModalIsOpen: boolean;
  freightSerialModalIsOpen: boolean;
  freightSerialSaveResults: IAjaxState<QuoteFreight[]>;
  currentQuoteFreight: QuoteFreight[];
  isZipValidationSuccessful: boolean;
  freightTotalData: FreightTotalData;
  originalFreightTotalData: FreightTotalData;
  isRateDiscrepancyModalOpen: boolean;
  currentQuoteStopIdx: number;
  isCityStateSearchModalOpen: boolean;
  cityStateCustomerSearchType: CustomerType | undefined;
  isCustomerSearchModalOpen: boolean;
  customerSearchType: CustomerType | undefined;
  shipperPlace: Place | undefined;
  consigneePlace: Place | undefined;
  forceNavigate: boolean;
  editMode: boolean;
  editShipperConsignee: boolean;
  quoteCalculateRatingResult: IAjaxState<QuoteCalculateRatingResult>;
  dataChangeLogsFetchResults: IAjaxState<AuditDataChangeAuditLog[]>;
  editHistories: AuditDataChangeAuditLog[];
  isAdminCreatedApprovalReasons: boolean;
  quickQuoteSaveResults: IAjaxState<QuoteResultVM>;
  cancelQuoteResults: IAjaxState<QuoteResultVM>;
  accessorialChargeValueFetchResults: IAjaxState<AccessorialChargeValue[]>;
  accessorialChargeValueSaveResults: IAjaxState<AccessorialChargeValue>;
  approveAccessorialChargeValueResult: IAjaxState<void>;
  deleteAccessorialChargeValueResult: IAjaxState<void>;
}

const INITIAL_FREIGHT_TOTAL_DATA: FreightTotalData = {
  ratingVariableOverriddenAmount: undefined,
  ratingVariableCalculatedAmount: 0,
  totalNumOfPieces: undefined,
  totalLength: undefined,
  totalWeight: undefined,
  canRatingVariableBeOverriden: false,
  ratingVariable: undefined,
  isWeightOverdimensional: false,
  overriddenRatingLength: undefined
};

const INITIAL_QUOTE_STOPS: QuoteStop[] = [
  {
    addresses: [{
      addressType: "Shipper",
      isCurrent: true
    }, {
      addressType: "Consignee",
      isCurrent: true
    }],
    stopNumber: 1
  }
];

const InjectedPropName = "QuoteEntryService";

const initialState = {
  quote: {
    quoteStops: INITIAL_QUOTE_STOPS
  },
  pcMilerValidationResults: managedAjaxUtil.createInitialState(),
  applyTripFlagsResults: managedAjaxUtil.createInitialState(),
  shadowQuote: {
    // otherwise change detection picks up on a new quote with a customer with a primary contact
    quoteStops: INITIAL_QUOTE_STOPS
  },
  quoteGetResults: managedAjaxUtil.createInitialState(),
  quoteValidationErrors: null,
  quoteSaveResults: managedAjaxUtil.createInitialState(),
  tripMileageResults: managedAjaxUtil.createInitialState(),
  calculateRatingVariableResults: managedAjaxUtil.createInitialState(),
  questionModalIsOpen: false,
  previousOtherFreightInfo: {},
  saveOtherFreightInfoResults: managedAjaxUtil.createInitialState(),
  commodityQuestionsFetchResults: managedAjaxUtil.createInitialState(),
  otherFreightInfoValidationErrors: null,
  sitesFetchResults: managedAjaxUtil.createInitialState(),
  opsCodesFetchResults: managedAjaxUtil.createInitialState(),
  opsCodes: undefined,
  sites: undefined,
  shipperConsigneeValidationErrors: null,
  rateEngineResult: undefined,
  workflowState: undefined,
  upchargeTarpsFetchResults: managedAjaxUtil.createInitialState(),
  flatUpchargeOnFocus: null,
  percentUpchargeOnFocus: null,
  negotiatedQuoteDataEntry: {},
  isApprovalNeededForLowRate: false,
  isApprovalNeededForDeclaredValue: false,
  negotiatedRateDataEntryValidationErrors: null,
  upchargeQuestionsFetchResults: managedAjaxUtil.createInitialState(),
  upchargeResponses: [],
  modalQuestionType: null,
  selectedCustomer: null,
  selectedCustomerContactsResults: managedAjaxUtil.createInitialState(),
  shipperContactsResults: managedAjaxUtil.createInitialState(),
  consigneeContactsResults: managedAjaxUtil.createInitialState(),
  saveQuoteContactResults: managedAjaxUtil.createInitialState(),
  saveContactResults: managedAjaxUtil.createInitialState(),
  viewOnly: false,
  isOverDimensional: false,
  hasQuoteChanged: false,
  hasOtherInformationChanged: false,
  hasRateContactChanged: false,
  hasOverrideRateVariableChanged: false,
  freightModalIsOpen: false,
  freightSerialModalIsOpen: false,
  freightSerialSaveResults: managedAjaxUtil.createInitialState(),
  currentQuoteFreight: [],
  isZipValidationSuccessful: false,
  freightTotalData: {},
  originalFreightTotalData: {},
  isRateDiscrepancyModalOpen: false,
  currentQuoteStopIdx: 0,
  isCityStateSearchModalOpen: false,
  cityStateCustomerSearchType: undefined,
  isCustomerSearchModalOpen: false,
  customerSearchType: undefined,
  shipperPlace: undefined,
  consigneePlace: undefined,
  forceNavigate: false,
  editMode: false,
  editShipperConsignee: false,
  quoteCalculateRatingResult: managedAjaxUtil.createInitialState(),
  dataChangeLogsFetchResults: managedAjaxUtil.createInitialState(),
  editHistories: [],
  isAdminCreatedApprovalReasons: false,
  quickQuoteSaveResults: managedAjaxUtil.createInitialState(),
  cancelQuoteResults: managedAjaxUtil.createInitialState(),
  accessorialChargeValueFetchResults: managedAjaxUtil.createInitialState(),
  accessorialChargeValueSaveResults: managedAjaxUtil.createInitialState(),
  approveAccessorialChargeValueResult: managedAjaxUtil.createInitialState(),
  deleteAccessorialChargeValueResult: managedAjaxUtil.createInitialState()
} as IQuoteEntryState;

class QuoteEntryFreezerService extends FreezerService<IQuoteEntryState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public downloadFreezerState() {
    const timestamp = moment().format("YYYY-MM-DD_HH-mm-ss");
    const json = JSON.stringify(this.freezer.get().toJS());
    const file = new Blob([json], { type: "text/json" });

    const a = document.createElement("a");
    a.href = URL.createObjectURL(file);
    a.download = `SalesPortalDebugInfo_${timestamp}.json`;
    a.click();
    URL.revokeObjectURL(a.href);
  }

  public async saveOtherFreightInfo() {
    const quoteStops = this.freezer.get().quote.quoteStops?.toJS();
    if (!quoteStops) {
      return;
    }

    const otherFreightInfoCriterias: OtherFreightInfoCriteria[] = [];
    _.forEach(quoteStops, (qs) => {
      const otherFreightInfoCriteria: OtherFreightInfoCriteria = {
        quoteStopId: qs.id,
        siteId: qs.siteId,
        opsCode: qs.opsCode,
        poNumber: qs.ponumber,
        externalNotes: qs.externalNotes
      };
      otherFreightInfoCriterias.push(otherFreightInfoCriteria);
    });

    const quoteStopsOtherInfoErrors = await validateSchema(OtherFreightInfoValidationSchema, otherFreightInfoCriterias, {
      abortEarly: false
    });
    
    if (quoteStopsOtherInfoErrors) {
      ErrorService.pushErrorMessage("One or more quote stop other information items are invalid.");
      return;
    }

    const body: OtherFreightInfosParams = { otherFreightInfoCriterias };

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "saveOtherFreightInfoResults",
      params: {
        body: body
      },
      onExecute: (apiOptions, params) => {
        const factory = QuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1QuotesSaveOtherFreightInfoPost(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to save other quote stop info.");
      },
      onOk: () => {
        this.freezer.get().set({
          hasOtherInformationChanged: false
        });
      }
    })
  }

  public updateEditMode(isEditMode: boolean) {
    const {
      quote
    } = this.freezer.get().toJS();

    this.freezer.get().set({
      quote: quote,
      editShipperConsignee: isEditMode && quote.status === "PendingNeedsCustomers",
      editMode: isEditMode,
      viewOnly: !isEditMode
    });
  }

  public updateQuote(quote: Partial<Quote>, includeShadow: boolean = false) {
    this.freezer.get().quote?.set(quote);

    // set if changes have been saved, so shadow should be mirrored to prevent incorrect change detection
    if (includeShadow) {
      this.freezer.get().shadowQuote?.set(quote);
    }

    this._runQuoteChangeDetection();
    this._runRateContactInfoChangeDetection(false);
  }

  public updateCurrentQuoteStop(index: number) {
    const { quote } = this.freezer.get();

    this.freezer.get().set({
      currentQuoteStopIdx: index
    });

    const newQuoteStop = quote.quoteStops ? quote.quoteStops[index] : undefined;
    if (newQuoteStop) {
      const regions = StateService.getState().regionFetchResults?.data ?? [];

      _.forEach(["Shipper", "Consignee"] as AddressAddressTypeEnum[], (addressType) => {
        const entity = _.find(newQuoteStop.addresses, a => (a.isCurrent === true) && (a.addressType === addressType));
        if (entity && !entity.customerId) {
          if (entity.city && entity.regionId) {
            const region = _.find(regions, r => r.id === entity.regionId);

            // if no customer, attempt to pull existing "place" from address
            this.updateVisiblePlace(addressType, {
              city: entity.city,
              stateProvince: region?.regionAbbreviation
            });
          }
          else {
            // no address saved, attempt to re-query places by zip
            this.onPlaceChanged(entity.zipPostalCode ?? "", addressType);
          }
        }
        this.getContactsForShipperConsignee(entity?.customerId, addressType);
      });
    }
  }

  public async addNewQuoteStop() {
    const {
      quote
    } = this.freezer.get().toJS();

    const quoteStops = quote.quoteStops ?? [];

    const lastIndex = quoteStops.length - 1;
    const previousQuoteStopAddresses = quoteStops[lastIndex].addresses ?? [];

    const {
      quoteStopId: strippedQuoteStop1,
      id: strippedId1,
      ...addressOne
    } = previousQuoteStopAddresses.find(x => (x.isCurrent === true) && (x.addressType === "Shipper")) ?? {};

    const {
      quoteStopId: strippedQuoteStop2,
      id: strippedId2,
      ...addressTwo
    } = previousQuoteStopAddresses.find(x => (x.isCurrent === true) && (x.addressType === "Consignee")) ?? {};

    const newQuoteStop: QuoteStop = { 
      shipperContactId: quoteStops[lastIndex]?.shipperContactId,
      consigneeContactId: quoteStops[lastIndex]?.consigneeContactId,
      addresses: [{ ...addressOne }, 
      { ...addressTwo }], 
      stopNumber: (quoteStops[lastIndex]?.stopNumber ?? 1) + 1, 
      siteId: quoteStops[lastIndex]?.siteId,
      opsCode: quoteStops[lastIndex]?.opsCode,
      ponumber: quoteStops[lastIndex]?.ponumber,
      externalNotes: quoteStops[lastIndex]?.externalNotes
    };

    quoteStops.push(newQuoteStop);

    this.updateQuote(quote);
    this.updateCurrentQuoteStop(lastIndex + 1);

    await this.isValidShipperConsignee(true, true);
  }

  public removeQuoteStop(quoteStopIndex: number) {
    const {
      quote,
      currentQuoteFreight,
      currentQuoteStopIdx
    } = this.freezer.get().toJS();

    // X should not appear on the only quote stop tab; ensure we don't accidentally catch an event
    if (!quote.quoteStops || (quote.quoteStops.length === 1) || (quoteStopIndex === 0)) {
      ErrorService.pushErrorMessage("Cannot remove the first stop.", "high");
      return;
    }

    const hadQuoteFreight = _.some(currentQuoteFreight, f => f.quoteStop?.stopNumber === quoteStopIndex + 1);

    // remove the quote stop, then renumber any later stops
    // backend (save) recreates all stops and dependent objects anyway
    quote.quoteStops.splice(quoteStopIndex, 1);

    for (let i = quoteStopIndex; i < quote.quoteStops.length; i += 1) {
      quote.quoteStops[i].stopNumber = i + 1;
    }

    if (hadQuoteFreight) {
      ErrorService.pushErrorMessage("Freight for a stop is not removed. Modify the freight by clicking the Freight button.", "high");
    }

    // update current stop index if deleting it (or an earlier stop)
    let nextQuoteStopIdx = currentQuoteStopIdx;
    if (quoteStopIndex <= currentQuoteStopIdx) {
      nextQuoteStopIdx -= 1;
    }

    this.updateQuote(quote);
    this.updateCurrentQuoteStop(nextQuoteStopIdx);
    this.fetchTripMileage();
  }

  public onCustomerClear(customerType: CustomerType) {
    this.updateSelectedCustomer(undefined, customerType, false);

    if (customerType === "Shipper" || customerType === "Consignee") {
      this.updateAllQuoteStopsCustomerTypeContacts(customerType, undefined, undefined);
    }
  }

  public async onPlaceChanged(zipcode: string, addressType: AddressAddressTypeEnum) {
    const {
      currentQuoteStopIdx
    } = this.freezer.get();

    let newAddress: Partial<Address> = {
      zipPostalCode: zipcode
    };

    let places: Place[] | undefined = undefined;
    if (zipcode) {
      places = await CityStateService.queryForPlace(zipcode, addressType);
    }

    if (places?.length) {
      const regions = StateService.getState().regionFetchResults?.data ?? [];
      const region = _.find(regions, r => r.regionAbbreviation === places![0].stateProvince);

      newAddress.city = places[0].city;
      newAddress.regionId = region?.id;
      newAddress.region = region;
    }
    else {
      // clear when no results or no zip entered
      newAddress.city = undefined;
      newAddress.region = undefined;
      newAddress.regionId = undefined;
    }

    //TO-DO: figure out the workflow for onBlur validation of zip codes, are all zips for each stop required?
    this.updateAddress(currentQuoteStopIdx, addressType, newAddress);
    this.updateVisiblePlace(addressType, { city: newAddress.city, stateProvince: newAddress.region?.regionAbbreviation });
    this.updateShipperConsignee();
  }

  public async updateAddressInformation() {
    const {
      quote,
      selectedCustomer
    } = this.freezer.get().toJS();

    if (quote && quote.quoteStops && selectedCustomer) {
      const newAddress = this.getPartialAddress(selectedCustomer);
      _.forEach(quote.quoteStops, (qs, stopIdx) => {
        _.forEach(qs.addresses, (a, addIdx) => {
          if (a.customerId === selectedCustomer.id) {
            const addressType = a.addressType ? a.addressType : addIdx === 0 ? "Shipper" : "Consignee";
            this.updateAddress(stopIdx ?? 0, addressType, newAddress);
          }
        });
      });

      await this.updateShipperConsignee();
    }
  }

  private getPartialAddress(customer: Customer | undefined) {
    let customerZip = "";
    if (customer?.zipPostalCode) {
      customerZip = customer.zipPostalCode.split("-")[0]; // Canadian zipcodes are unaffected
    }

    const newAddress: Partial<Address> = {
      address1: customer?.address1,
      address2: customer?.address2,
      city: customer?.city,
      regionId: customer?.regionId,
      region: customer?.region,
      zipPostalCode: getTrimmedZipPostalCode(customerZip),
      customerId: customer?.id,
      customer: customer
    };

    return newAddress;
  }

  public async updateSelectedCustomer(customer: Customer | undefined, customerType: CustomerType | undefined, calculateMileage: boolean = true) {
    const {
      currentQuoteStopIdx
    } = this.freezer.get().toJS();

    if (customerType) {
      const newAddress = this.getPartialAddress(customer);
      if (newAddress.zipPostalCode && (customerType === "Shipper" || customerType === "Consignee")) {
        const places = await CityStateService.queryForPlace(newAddress.zipPostalCode, customerType);
        if (places?.length === 0) {
          ErrorService.pushErrorMessage(`${customerType} zipcode could not be found.`);
        }
      }

      // translate customer type to address type here
      if (customerType === "Shipper") {
        this.updateAddress(currentQuoteStopIdx ?? 0, "Shipper", newAddress);
      }
      else if (customerType === "Consignee") {
        this.updateAddress(currentQuoteStopIdx ?? 0, "Consignee", newAddress);
      }
      else {
        // caller: no-op
      }

      await this.updateShipperConsignee(calculateMileage, true);
    }
  }

  public onCustomerCityStateSearchOpen(customerType: CustomerType) {
    this.freezer.get().set({
      isCityStateSearchModalOpen: true,
      cityStateCustomerSearchType: customerType
    });
  }

  public onCustomerCityStateSearchClose() {
    this.freezer.get().set({
      isCityStateSearchModalOpen: false,
      cityStateCustomerSearchType: undefined
    });
  }

  public onCustomerSearchModalOpen(customerType: CustomerType) {
    this.freezer.get().set({
      isCustomerSearchModalOpen: true,
      customerSearchType: customerType
    });
  }

  public onCustomerSearchModalClose() {
    this.freezer.get().set({
      isCustomerSearchModalOpen: false,
      customerSearchType: undefined
    });
  }

  public openFreightModal() {
    const {
      quote,
      freightTotalData
    } = this.freezer.get().toJS();

    this.freezer.get().set({
      currentQuoteFreight: quote.quoteFreights ?? [],
      freightModalIsOpen: true,
      originalFreightTotalData: freightTotalData
    });
  }

  public cancelFreightModal() {
    const {
      quote,
      originalFreightTotalData
    } = this.freezer.get().toJS();

    // when cancelling - close the modal and reset the value of the
    // currentQuoteFreight variable to its previous value
    this.freezer.get().set({
      freightModalIsOpen: false,
      currentQuoteFreight: quote.quoteFreights ?? [],
      freightTotalData: originalFreightTotalData
    });
  }

  public async saveFreightModal() {
    const {
      quote,
      currentQuoteFreight,
      freightTotalData
    } = this.freezer.get().toJS();

    // re-validate all quote freight rows again as a final sanity check
    // add/edit on individual quote freight entries should catch errors earlier
    const quoteFreightErrors = await validateSchema(QuoteFreightArraySchema, currentQuoteFreight, {
      abortEarly: false
    });
    if (quoteFreightErrors) {
      ErrorService.pushErrorMessage("One or more freight items are invalid.");
      return;
    }

    const currentCompany = CompanySelectService.getState().companyContext.companyKey;

    if (currentCompany === "KL") {
      this.updateQuote({ isMarketPrimary: freightTotalData.market === "Primary" });
    }

    // update freezer state first, so change detection fires properly
    this.freezer.get().set({
      freightModalIsOpen: false,
      currentQuoteFreight: currentQuoteFreight
    });

    this.updateQuote({
      quoteStops: quote.quoteStops,
      quoteFreights: currentQuoteFreight,
      equipmentTypeId: freightTotalData.equipmentType?.id,
      equipmentType: freightTotalData.equipmentType
    });
  }

  public openFreightSerialModal() {
    const {
      quote,
      freightTotalData
    } = this.freezer.get().toJS();

    this.freezer.get().set({
      freightSerialModalIsOpen: true,
      currentQuoteFreight: quote.quoteFreights ?? [],
      originalFreightTotalData: freightTotalData
    });
  }

  public cancelFreightSerialModal() {
    const {
      quote,
      originalFreightTotalData
    } = this.freezer.get().toJS();

    this.freezer.get().set({
      freightSerialModalIsOpen: false,
      currentQuoteFreight: quote.quoteFreights ?? [],
      freightTotalData: originalFreightTotalData
    });
  }

  public async saveFreightSerialModal() {
    const {
      currentQuoteFreight,
      quote: { quoteFreights }
    } = this.freezer.get().toJS();

    const wasRotated = (SecurityContext.isInGroup("/Admin") || SecurityContext.isInGroup("/Manager"))
      && _.some(currentQuoteFreight, (x, idx) => {
        return x.rotated !== quoteFreights?.[idx].rotated;
      });

    this.updateQuote({ quoteFreights: currentQuoteFreight }, true);

    this.freezer.get().set({
      freightSerialModalIsOpen: false,
      currentQuoteFreight: currentQuoteFreight
    });

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "freightSerialSaveResults",
      params: {
        body: currentQuoteFreight
      },
      onExecute: (apiOptions, params, options) => {
        const factory = QuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.saveFreightSerialNumbers(params);
      },
      onError: (err, errMessage) => {
        ErrorService.pushErrorMessage("Failed to save freight serials")
      },
      onOk: (data: QuoteFreight[]) => {
        // if freight was rotated (and user is an admin), re-save the quote and force a re-rate
        if (wasRotated) {
          this.save("AdminRerate");
        }
      }
    });
  }

  public setQuoteMarketType() {
    const currentFreightTotalData = this.freezer.get().freightTotalData;

    const defaultMarket = ApplicationSettingsService.getDefaultMarketSetting();
    const currentCompany = CompanySelectService.getState().companyContext.companyKey;

    if (!Helpers.isNullOrUndefined(currentFreightTotalData.market)) {
      return;
    } else if (currentCompany === "KL") {
      this.updateFreightTotalData({ market: defaultMarket as QuoteMarket });
    }
  }

  public updateQuoteStop(index: number, quoteStop: Partial<QuoteStop>) {
    let quoteStops = this.freezer.get().quote.quoteStops;

    if (quoteStops && quoteStops.hasOwnProperty(index)) {
      quoteStops[index].set(quoteStop);
    }

    if (_.has(quoteStop, "shipperStartDate") ||
      _.has(quoteStop, "shipperEndDate") ||
      _.has(quoteStop, "consigneeStartDate") ||
      _.has(quoteStop, "consigneeEndDate")) {
      this.applyTripFlags();
    }

    this._runQuoteChangeDetection();
    this._runOtherInformationChangeDetection();
  }

  public updateQuoteFreightSerial(quoteFreightIndex: number, quoteFreight: Partial<QuoteFreight>): boolean {
    const currentQuoteFreight = this.freezer.get().currentQuoteFreight;
    let requireRetotal = false;

    // limited subset of edits available in freight serial modal, even as an admin
    if (currentQuoteFreight && currentQuoteFreight.hasOwnProperty(quoteFreightIndex)) {
      const previousQuoteFreight = currentQuoteFreight[quoteFreightIndex];
      if ((quoteFreight.length && (previousQuoteFreight.length !== quoteFreight.length))
        || (quoteFreight.width && (previousQuoteFreight.width !== quoteFreight.width))
        || ((quoteFreight.rotated !== undefined) && (previousQuoteFreight.rotated !== quoteFreight.rotated))) {
        requireRetotal = true;

        this.updateFreightTotalData({
          totalLength: 0,
          overriddenRatingLength: undefined
        });
      }

      currentQuoteFreight[quoteFreightIndex].set(quoteFreight);
    }

    return requireRetotal;
  }

  public onUpdateQuoteFreightRow(quoteFreight: QuoteFreight, index: number): boolean {
    const {
      currentQuoteFreight
    } = this.freezer.get().toJS();

    const freightTotalUpdate: Partial<FreightTotalData> = {};
    let requireRetotal = false

    // freight data/flag changes that affect total length
    const previousFreight = currentQuoteFreight[index];
    if ((previousFreight.length !== quoteFreight.length)
      || (previousFreight.width !== quoteFreight.width)
      || (previousFreight.height !== quoteFreight.height)
      || (previousFreight.isSideBySide !== quoteFreight.isSideBySide)
      || (previousFreight.isStackable !== quoteFreight.isStackable)
      || (previousFreight.isGrouped !== quoteFreight.isGrouped)
      || (previousFreight.numberOfPieces !== quoteFreight.numberOfPieces)) {
      requireRetotal = true;
      freightTotalUpdate.isOverdimensional = false;
      freightTotalUpdate.totalLength = 0;
      freightTotalUpdate.overriddenRatingLength = undefined;
    }

    // freight data/flag changes that affect total weight
    if ((previousFreight.weight !== quoteFreight.weight)
      || (previousFreight.isGrouped !== quoteFreight.isGrouped)
      || (!quoteFreight.isGrouped && (previousFreight.numberOfPieces !== quoteFreight.numberOfPieces))) {
      requireRetotal = true;
      freightTotalUpdate.isOverdimensional = false;
      freightTotalUpdate.totalWeight = 0;
    }

    currentQuoteFreight[index] = quoteFreight;
    freightTotalUpdate.totalNumOfPieces = getTotalPieceCount(currentQuoteFreight);

    this.freezer.get().set({
      currentQuoteFreight: currentQuoteFreight
    });

    this.updateFreightTotalData(freightTotalUpdate);

    return requireRetotal;
  }

  public updateFreightTotalData(newFreightTotalData: Partial<FreightTotalData>) {
    this.freezer.get().freightTotalData.set(newFreightTotalData);
  }

  public removeQuoteFreight(quoteStopFreightIndex: number) {
    const {
      currentQuoteFreight
    } = this.freezer.get().toJS();

    if (currentQuoteFreight.length && currentQuoteFreight[quoteStopFreightIndex]) {
      currentQuoteFreight.splice(quoteStopFreightIndex, 1);

      this.freezer.get().set({
        currentQuoteFreight: currentQuoteFreight
      });

      this.updateFreightTotalData({
        isOverdimensional: false,
        totalNumOfPieces: getTotalPieceCount(currentQuoteFreight),
        totalLength: 0,
        totalWeight: 0,
        canRatingVariableBeOverriden: false,
        overriddenRatingLength: undefined
      });
    }
  }

  public addQuoteFreight(quoteFreight: QuoteFreight) {
    const {
      currentQuoteFreight
    } = this.freezer.get().toJS();

    if (currentQuoteFreight) {
      currentQuoteFreight.push(quoteFreight);

      this.freezer.get().set({
        currentQuoteFreight: currentQuoteFreight
      });

      this.updateFreightTotalData({
        isOverdimensional: false,
        totalNumOfPieces: getTotalPieceCount(currentQuoteFreight),
        totalLength: 0,
        totalWeight: 0,
        canRatingVariableBeOverriden: false,
        overriddenRatingLength: undefined
      });
    }
  }

  public onMarketChange(newMarket: QuoteMarket): void {
    const freightTotalDataUpdate: Partial<FreightTotalData> = {
      market: newMarket
    };

    this.updateFreightTotalData(freightTotalDataUpdate);
  }

  public async onEquipmentTypeChange(newEquipmentType?: EquipmentType | undefined) {
    this.updateFreightTotalData({
      equipmentType: newEquipmentType,
      isOverdimensional: false,
      overriddenRatingLength: undefined
    });
  }

  // this is essentially the CalculateRateVariable workflow; includes getting total weight, total length, overdimensional, etc
  public async calculateFreightTotalResults(currentQuoteFreight: QuoteFreight[], useExisting?: QuoteCalculatedRate, timeout?: number): Promise<Partial<FreightTotalData>> {
    // If no freight items exist, reset the total data and return.
    if (currentQuoteFreight.length === 0 || !_.some(currentQuoteFreight, c => c.numberOfPieces ?? 0 >= 1)) {
      const newFreightTotal = { ...INITIAL_FREIGHT_TOTAL_DATA };

      this.freezer.get().set({
        freightTotalData: newFreightTotal
      });

      return newFreightTotal;
    }

    let newFreightTotal: Partial<FreightTotalData> = {};

    // an existing calculated rate exists (i.e. quote get/fetch)
    // if not current, warn user in summary data
    if (useExisting) {
      const canOverride = useExisting.isCurrent && (useExisting.rateLevelFactor === "Length");
      const isOverdimensional = useExisting.isCurrent && (useExisting.rateLevelFactor === "Overdimensional");

      const overriddenRatingLength = !useExisting.isCurrent ? undefined
        : useExisting.totalFreightLength ? useExisting.totalFreightLength
          : isOverdimensional ? 0
            : undefined;

      newFreightTotal = {
        totalNumOfPieces: currentQuoteFreight.map(x => x.numberOfPieces).reduce((acc, cur) => (acc ?? 0) + (cur ?? 0)),
        canRatingVariableBeOverriden: canOverride,
        isOverdimensional: isOverdimensional,
        // TODO: the freight table on initial load will not highlight weight even if overdimensional
        ratingError: !useExisting.isCurrent ? "Totals are not current" : undefined,
        ratingVariable: !useExisting.isCurrent ? "Error" : useExisting.rateLevelFactor,
        ratingVariableCalculatedAmount: useExisting.calculatedRateVariable,
        ratingVariableOverriddenAmount: canOverride ? useExisting.overriddenRateVariable : undefined,
        totalLength: !useExisting.isCurrent ? 0 : useExisting.totalFreightLength,
        totalWeight: !useExisting.isCurrent ? 0 : useExisting.totalFreightWeight,
        overriddenRatingLength: overriddenRatingLength
      };
    }
    else {
      const {
        ratingVariableOverriddenAmount,
        equipmentType,
        overriddenRatingLength
      } = this.freezer.get().freightTotalData;

      await managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        ajaxStateProperty: "calculateRatingVariableResults",
        params: {
          body: {
            quoteFreight: currentQuoteFreight,
            overriddenRatingVariable: ratingVariableOverriddenAmount,
            overriddenRatingLength: overriddenRatingLength,
            equipmentTypeCode: equipmentType?.tmEquipmentCode,
            overrideLengthTimeout: timeout
          }
        },
        onExecute: (apiOptions, param, options) => {
          const factory = QuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return factory.getCalculatedRatingVariable(param);
        },
        onError: (err, errorMessage) => {
          ErrorService.pushErrorMessage(this.handleErrorStatusCode(err) ?? "Failed to fetch rating variable");
        },
        onOk: (data: CalculateRatingVariableResult) => {
          const isOverdimensional = (data.ratingVariableType === "Overdimensional");
          const canOverride = (data.ratingVariableType === "Length")
            || (isOverdimensional && !!ratingVariableOverriddenAmount);
          const overriddenRatingLength = data.totalLength ? data.totalLength
            : isOverdimensional ? 0
              : undefined;

          newFreightTotal = {
            totalNumOfPieces: currentQuoteFreight.map(x => x.numberOfPieces).reduce((acc, cur) => (acc ?? 0) + (cur ?? 0)),
            canRatingVariableBeOverriden: canOverride,
            isOverdimensional: isOverdimensional,
            ratingError: data.error,
            ratingVariable: data.ratingVariableType,
            ratingVariableCalculatedAmount: data.ratingVariable,
            ratingVariableOverriddenAmount: canOverride ? ratingVariableOverriddenAmount : undefined,
            totalLength: data.totalLength,
            totalWeight: data.totalWeight,
            isWeightOverdimensional: data.isWeightOverdimensional,
            overriddenRatingLength: overriddenRatingLength
          };

          if (data.error) {
            ErrorService.pushErrorMessage(data.error, "high");
          }
        }
      });
    }

    this.freezer.get().freightTotalData.set(newFreightTotal);

    return newFreightTotal;
  }

  public updateHasOverrideRateVariableChanged(changed: boolean) {
    this.freezer.get().set({ hasOverrideRateVariableChanged: changed });
  }

  private _runQuoteChangeDetection() {
    const currentQuote = this.freezer.get().quote.toJS();
    const shadowQuote = this.freezer.get().shadowQuote.toJS();
    const { hasOverrideRateVariableChanged } = this.freezer.get();

    if (!_.isEmpty(shadowQuote)) {
      // fields that should not trigger a re-rate
      currentQuote.contactName = undefined;
      currentQuote.contactPhoneNumber = undefined;
      currentQuote.quoteType = undefined;
      shadowQuote.contactName = undefined;
      shadowQuote.contactPhoneNumber = undefined;
      shadowQuote.quoteType = undefined;

      _.forEach(currentQuote.quoteStops, (qs) => {
        qs.ponumber = undefined;
        qs.siteId = undefined;
        qs.opsCode = undefined;
        qs.externalNotes = undefined;
      })

      _.forEach(shadowQuote.quoteStops, (qs) => {
        qs.ponumber = undefined;
        qs.siteId = undefined;
        qs.opsCode = undefined;
        qs.externalNotes = undefined;
      })

      if (!_.isEqual(currentQuote, shadowQuote) || hasOverrideRateVariableChanged) {
        this.freezer.get().set({
          hasQuoteChanged: true
        });
      }
    }
  }

  private _runOtherInformationChangeDetection() {
    const currentQuote = this.freezer.get().quote.toJS();
    const shadowQuote = this.freezer.get().shadowQuote.toJS();

    if (_.isEmpty(shadowQuote)) {
      this.freezer.get().set({
        hasOtherInformationChanged: true
      });
      return;
    }
    
    _.forEach(currentQuote.quoteStops, (qs, idx) => {
      if (!shadowQuote.quoteStops?.hasOwnProperty(idx)) {
        this.freezer.get().set({
          hasOtherInformationChanged: true
        });
        return;
      }

      if (!_.isEqual(qs.siteId, shadowQuote.quoteStops[idx].siteId) ||
        !_.isEqual(qs.opsCode, shadowQuote.quoteStops[idx].opsCode) ||
        !_.isEqual(qs.ponumber, shadowQuote.quoteStops[idx].ponumber) ||
        !_.isEqual(qs.externalNotes, shadowQuote.quoteStops[idx].externalNotes)) {
          this.freezer.get().set({
            hasOtherInformationChanged: true
          });
          return;
      }
      
    })
  }

  private _runRateContactInfoChangeDetection(negotiatedRateChanged: boolean) {
    const currentQuote = this.freezer.get().quote.toJS();
    if (currentQuote.quoteType === "Quick") {
      const shadowQuote = this.freezer.get().shadowQuote.toJS();

      if (!_.isEmpty(shadowQuote)) {
        if (negotiatedRateChanged ||
          !_.isEqual(currentQuote.contactName, shadowQuote.contactName) ||
          !_.isEqual(currentQuote.contactPhoneNumber, shadowQuote.contactPhoneNumber)) {
          this.freezer.get().set({
            hasRateContactChanged: true
          });
        }
      }
    }
  }

  public updateHardTimeShippingNotes(quoteStopIndex: number, dateEntity: DateEntity) {
    let quoteStops = this.freezer.get().quote.quoteStops?.toJS();

    if (quoteStops && quoteStops.hasOwnProperty(quoteStopIndex)) {
      let stop = quoteStops[quoteStopIndex];
      const newShippingNotes = stop.shipmentNotes ? stop.shipmentNotes.split(', ') : [];

      const shipperHardTimeIndex = newShippingNotes.indexOf('Hard shipper time');
      const consigneeHardTimeIndex = newShippingNotes.indexOf('Hard consigner time');

      const indexToUse = dateEntity === "shipper" ? shipperHardTimeIndex : consigneeHardTimeIndex;

      const hardTimeAdded = dateEntity === "shipper" ? stop.isShipperAppointmentRequired : stop.isConsigneeAppointmentRequired;

      if (hardTimeAdded && indexToUse === -1) {
        newShippingNotes.push(dateEntity === "shipper" ? 'Hard shipper time' : 'Hard consigner time');
      }
      else if (!hardTimeAdded && indexToUse > -1) {
        newShippingNotes.splice(dateEntity === "shipper" ? shipperHardTimeIndex : consigneeHardTimeIndex, 1);
      }
      this.updateQuoteStop(0, { shipmentNotes: newShippingNotes.join(', ') });
    }
  }

  public updateNegotiatedQuoteDataEntry(negotiatedQuoteDataEntry: Partial<NegotiatedQuoteDataEntry>) {
    const {
      quote
    } = this.freezer.get().toJS();

    let rateLowered = false;

    const previousNegotiatedRateDataEntry = this.freezer.get().negotiatedQuoteDataEntry;
    const negotiatedRateChanged = !_.isEqual(previousNegotiatedRateDataEntry.negotiatedRateValue, negotiatedQuoteDataEntry.negotiatedRateValue);

    this.freezer.get().negotiatedQuoteDataEntry?.set(negotiatedQuoteDataEntry);

    let isLowRate = this.freezer.get().isApprovalNeededForLowRate;
    const hasApproval = quote.approvalReasons && quote.approvalReasons.length > 0 ?
      quote.approvalReasons.find(x => x.approvalNeededReasonId === "LowNegotiatedRate" && x.approvalStatus === "Approved")
      && !quote.approvalReasons.find(x => x.approvalNeededReasonId === "LowNegotiatedRate" && x.approvalStatus === "PendingApproval") :
      false;

    if (negotiatedQuoteDataEntry.negotiatedRateValue !== undefined) {
      const currentCalculatedRate = quote.calculatedRates && quote.calculatedRates[0];

      isLowRate = (currentCalculatedRate?.lowRate !== undefined)
        && (negotiatedQuoteDataEntry.negotiatedRateValue < currentCalculatedRate.lowRate);

      if (quote.negotiatedRate) {
        rateLowered = quote.negotiatedRate > negotiatedQuoteDataEntry.negotiatedRateValue;
      }
    }

    if (!isLowRate) {
      quote.approvalReasons = quote.approvalReasons?.filter(x => !(x.approvalNeededReasonId === "LowNegotiatedRate" && x.approvalStatus === "PendingApproval"));
    }

    this.freezer.get().set({
      isApprovalNeededForLowRate: isLowRate && !hasApproval || isLowRate && rateLowered,
      quote: quote
    });

    this._runRateContactInfoChangeDetection(negotiatedRateChanged);
  }

  private async updateAddress(quoteStopIndex: number, addressType: AddressAddressTypeEnum, updatedAddress: Partial<Address>) {
    let quoteStops = this.freezer.get().quote.quoteStops?.toJS();

    if (quoteStops && quoteStops.hasOwnProperty(quoteStopIndex)) {
      let stop = quoteStops[quoteStopIndex];

      if (stop.addresses) {
        let addressIndex = _.findIndex(stop.addresses, a => (a.isCurrent === true) && a.addressType === addressType);

        stop.addresses[addressIndex] = {
          ...stop.addresses[addressIndex],
          ...updatedAddress
        }

        this.freezer.get().quote?.set({ quoteStops });
      }
    }

    this._runQuoteChangeDetection();
  }

  public updateFlatUpchargeFocus() {
    let quote = this.freezer.get().quote.toJS();

    const origValue = quote.flatUpcharge;

    this.freezer.get().set({
      flatUpchargeOnFocus: origValue
    });
  }

  public updatePercentUpchargeFocus() {
    let quote = this.freezer.get().quote.toJS();

    const origValue = quote.upchargePercentage;

    this.freezer.get().set({
      percentUpchargeOnFocus: origValue
    });
  }

  // for reference: ResponseType = "yes" | "no" | "na"; // 1, 2, 3 respectively
  public updateQuestionResponse(questionId: number, answer: ResponseType) {
    const {
      upchargeResponses
    } = this.freezer.get().toJS();

    const rIdx = upchargeResponses.findIndex(q => q.questionId === questionId);
    if (rIdx !== -1) {
      upchargeResponses[rIdx].answer = answer;
    }

    this.freezer.get().set({
      upchargeResponses: upchargeResponses
    });

    this.updateQuoteResponses();
  }

  //seems like the least invasive way of keeping track when questions/responses are changed
  private updateQuoteResponses() {
    const {
      quote,
      upchargeResponses
    } = this.freezer.get().toJS();

    // questions
    quote.quoteQuestions = _.map(upchargeResponses, r => {
      return {
        questionId: r.questionId,
        answer: r.answer
      }
    });

    this.freezer.get().quote.set(quote);

    this._runQuoteChangeDetection();
  }

  public updateFlatUpchargeOtherResponse(answer: ResponseType) {

    const {
      quote
    } = this.freezer.get().toJS();

    quote.otherFlatUpchargeReason = answer === "Yes";
    this.updateQuote(quote);

    this._runQuoteChangeDetection();
  }

  public updateUpchargeReason(reason: string) {

    const {
      modalQuestionType: questionType,
      quote
    } = this.freezer.get().toJS();

    if (questionType === "Flat $ Upcharge") {
      quote.flatUpchargeReason = reason;
    }
    else if (questionType === "% Upcharge") {
      quote.percentUpchargeReason = reason;
    }

    this.freezer.get().quote?.set(quote);
    this._runQuoteChangeDetection();
  }

  public setSelectedCustomer(selectedCustomer: Customer) {
    this.setQuoteType(true);
    this.freezer.get().set({ selectedCustomer });
    this.getContactsForSelectedCustomer(selectedCustomer.id ?? 0);
  }

  private getContactsForSelectedCustomer(customerId: number) {
    const {
      quote,
      viewOnly
    } = this.freezer.get();

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "selectedCustomerContactsResults",
      params: {
        customerId: customerId,
        activeOnly: true
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getContactsForCustomer(params);
      },
      onOk: (data: CustomerContact[]) => {
        const primaryContact = _.find(data, c => c.isPrimary === true);

        if (!viewOnly && !quote.customerContactId && primaryContact) {
          this.updateQuote({ customerContactId: primaryContact.id }, true);
        }
      },
      onError: (error, message) => {
        ErrorService.pushErrorMessage("Failed to fetch customer contacts");
      }
    });
  }

  public getContactsForShipperConsignee(customerId: number | undefined, customerType: CustomerType | undefined) {
    if (!customerId || !customerType) {
      return;
    }

    const ajaxStateProperty = customerType === "Shipper" ? "shipperContactsResults" : "consigneeContactsResults";
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: ajaxStateProperty,
      params: {
        customerId: customerId,
        activeOnly: true
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getContactsForCustomer(params);
      },
      onError: (error, message) => {
        ErrorService.pushErrorMessage("Failed to fetch customer contacts");
      }
    });
  }

  public updateSelectedContact(contactId: number | undefined) {
    const quote = this.freezer.get().quote;

    this.updateQuote({ customerContactId: contactId }, true);

    if (quote.id) {
      managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        ajaxStateProperty: "saveQuoteContactResults",
        params: {
          body: {
            quoteId: quote.id,
            contactId: contactId
          }
        },
        onExecute: (apiOptions, params, options) => {
          const factory = QuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return factory.saveContactForQuote(params);
        },
        onError: (error, message) => {
          ErrorService.pushErrorMessage("Failed to save contact to quote");
        }
      });
    }
  }

  public updateAllQuoteStopsCustomerTypeContacts(customerType: CustomerType | undefined, contactId: number | undefined, customerId: number | undefined) {
    if (!customerType){
      return;
    }

    const { currentQuoteStopIdx } = this.freezer.get();
    const quoteStops = this.freezer.get().quote.quoteStops?.toJS();

    if (quoteStops && quoteStops.hasOwnProperty(currentQuoteStopIdx)) {
      quoteStops.forEach(s => s.addresses?.forEach(a => {
        if (a.customerId === customerId && a.isCurrent) {
          if (a.addressType === "Shipper" && customerType === "Shipper") {
            s.shipperContactId = contactId;
            if (!contactId || (s.shipperContact && contactId !== s.shipperContact.id)) {
              s.shipperContact = undefined;
            }
          }
          if (a.addressType === "Consignee" && customerType === "Consignee") {
            s.consigneeContactId = contactId;
            if (!contactId || (s.consigneeContact && contactId !== s.consigneeContact.id)) {
              s.consigneeContact = undefined;
            }
          }
        }
      }));
    }

    this.freezer.get().quote?.set({ quoteStops });
  }

  public getQuoteStopContactId(customerId: number | undefined, customerType: CustomerType | undefined): number | undefined {
    const { currentQuoteStopIdx } = this.freezer.get();
    const quoteStops = this.freezer.get().quote.quoteStops;
    let contactId: number | undefined;

    if (quoteStops && quoteStops.hasOwnProperty(currentQuoteStopIdx)) {
      const matchingStop = quoteStops.find(s => s.stopNumber !== (currentQuoteStopIdx + 1) && s.addresses?.find(a => a.customerId === customerId && a.addressType === customerType && a.isCurrent));
      contactId = customerType === "Shipper" ? matchingStop?.shipperContactId 
        : customerType === "Consignee" ? matchingStop?.consigneeContactId
        : undefined;
    }

    return contactId;
  }

  public saveNewContact(contact: CustomerContact, customerType?: CustomerType) {
    // assumes contact has already passed validation
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "saveContactResults",
      params: {
        body: contact
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.addContact(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to add customer contact.");
      },
      onOk: (data: CustomerContact) => {
        if (customerType === "Caller") {
          this.getContactsForSelectedCustomer(data.customerId ?? 0);
          this.updateSelectedContact(data.id);
        }
        if (data.customerId && (customerType === "Shipper" || customerType === "Consignee")) {
          this.getContactsForShipperConsignee(data.customerId, customerType);
          this.updateAllQuoteStopsCustomerTypeContacts(customerType, data.id, data.customerId);
        }
      }
    });
  }

  private _stripQuoteFreight(quoteFreight: QuoteFreight[] | undefined, keepRotation: boolean = false) {
    const activeCommodities = CommodityService.freezer.get().commodityFetchResults.data?.toJS();

    if (!quoteFreight) {
      return;
    }

    const currentQuoteFreight = quoteFreight.filter(f => f.commodity?.isActive);

    let strippedQuoteFreight: QuoteFreight[] = currentQuoteFreight?.map(qf => {
      const {
        id,
        quoteId,
        quote,
        quoteFreights,
        ...strippedQuoteStop
      } = qf.quoteStop ?? {} as QuoteStop;

      const commodity = _.find(activeCommodities, c => c.id === qf.commodityId);

      return {
        commodityId: qf.commodityId,
        weight: qf.weight,
        width: qf.width,
        length: qf.length,
        height: qf.height,
        description: qf.description,
        serialRefNumber: qf.serialRefNumber,
        isStackable: qf.isStackable,
        isSideBySide: qf.isSideBySide,
        isGrouped: qf.isGrouped,
        numberOfPieces: qf.numberOfPieces,
        rotated: keepRotation ? qf.rotated : false,
        quoteStopFreightQuestions: qf.quoteStopFreightQuestions,
        quoteStop: strippedQuoteStop,
        commodity: {
          commodityName: commodity?.commodityName,
          isActive: commodity?.isActive,
          tmCommodityId: commodity?.tmCommodityId
        }
      };
    });

    strippedQuoteFreight = this._mapActiveAndNewCommodityQuestions(strippedQuoteFreight, undefined, true);

    return strippedQuoteFreight;
  }

  /*
    Helper function to include new commodity questions and exclude inactive questions
    that were answered previously
  */
  private _mapActiveAndNewCommodityQuestions(quoteStopFreight: QuoteFreight[], activeQuestions?: Question[], stripQuoteFreightQuestionIds: boolean = false) {
    const activeCommodityQuestions = activeQuestions ? activeQuestions : this.freezer.get().commodityQuestionsFetchResults.data?.toJS() ?? [];

    if (quoteStopFreight) {
      for (let i = 0; i < quoteStopFreight.length; i++) {
        let quoteStopFreightQuestions = quoteStopFreight[i].quoteStopFreightQuestions;
        const activeQuestionsForCommodity: Question[] = _.filter(activeCommodityQuestions, q => _.findIndex(q.commodityQuestions, cQ => cQ.commodityId === quoteStopFreight[i].commodityId) !== -1);

        quoteStopFreightQuestions = _.filter(quoteStopFreightQuestions, fQ => _.findIndex(activeQuestionsForCommodity, aQ => aQ.id === fQ.questionId) !== -1);

        let strippedQuoteFreightQuestions = quoteStopFreightQuestions?.map(q => {
          return {
            id: stripQuoteFreightQuestionIds ? undefined : q.id,
            questionId: q.questionId,
            answer: q.answer
          }
        });

        const newQuestions = _.filter(activeQuestionsForCommodity, q => _.find(strippedQuoteFreightQuestions, r => r.questionId === q.id) === undefined);
        if (newQuestions.length > 0) {
          strippedQuoteFreightQuestions = strippedQuoteFreightQuestions?.concat(_.map(newQuestions, q => {
            return {
              id: undefined,
              questionId: q.id,
              answer: q.isNa ? "NA" : "No"
            }
          }));
        }

        quoteStopFreight[i].quoteStopFreightQuestions = strippedQuoteFreightQuestions;
      }
    }

    return quoteStopFreight;
  }

  // TODO: this does not change the navigation route so it looks like you're still in the prev quote
  // even after clicking Rate to save the copied quote to the database
  public async copyCurrentQuote() {
    const quote = this.freezer.get().quote.toJS();
    const currentQuoteFreight = this.freezer.get().currentQuoteFreight.toJS();
    const { isApprovalNeededForDeclaredValue, currentQuoteStopIdx } = this.freezer.get();

    const {
      createdBy,
      reviewedBy,
      reviewedById,
      finalizedBy,
      notes,
      negotiatedRate,
      id,
      quoteStatusHistories,
      modifiedOn,
      createdOn,
      workflowState,
      approvalReasons,
      calculatedRates,
      declineReasonText,
      ...copyQuote
    } = quote;

    let notifyInactive = false;
    let freightRemoved = false;

    copyQuote.customerQuote = undefined;
    
    // clear cancellation data (if any)
    copyQuote.cancellationReason = undefined;
    copyQuote.cancellationDetails = undefined;

    // strip various ids from quote freights
    const strippedQuoteFreight = this._stripQuoteFreight(currentQuoteFreight);
    const copiedFreightTotal = await this.calculateFreightTotalResults(strippedQuoteFreight ?? []);

    // stuff that could be inactive:
    // commodity questions }
    // upcharge questions  } stored together in quoteStop.quoteQuestions
    // tarps
    // commodities
    copyQuote.quoteFreights = copyQuote.quoteFreights?.filter(qf => {
      if (qf.commodity?.isActive) {
        return true;
      }
      else {
        notifyInactive = true;
        freightRemoved = true;
        return false;
      }
    });

    copyQuote.quoteFreights?.forEach(qf => {
      qf.quoteStopFreightQuestions = qf.quoteStopFreightQuestions?.filter(qq => qq.question?.isActive);
    });

    copyQuote.quoteQuestions = copyQuote.quoteQuestions?.filter(qq => qq.question?.isActive);

    copyQuote.quoteStops?.forEach(qs => {
      qs.quoteFreights = qs.quoteFreights?.filter(qf => {
        if (qf.commodity?.isActive) {
          return true;
        }
        else {
          notifyInactive = true;
          freightRemoved = true;
          return false;
        }
      });

      if (qs.tarp && !qs.tarp?.isActive) {
        notifyInactive = true;
        delete qs.tarp;
        delete qs.tarpId;
      }
    });

    copyQuote.quoteStops?.forEach((qs, qsIdx) => {
      if (qsIdx === currentQuoteStopIdx) {
        const origAddress = _.find(qs.addresses, a => (a.isCurrent === true) && (a.addressType === "Shipper"));
        const destAddress = _.find(qs.addresses, a => (a.isCurrent === true) && (a.addressType === "Consignee"));

        if (origAddress && origAddress?.customerId) {
          this.getContactsForShipperConsignee(origAddress.customerId, "Shipper");
        }
        if (destAddress && destAddress?.customerId) {
          this.getContactsForShipperConsignee(destAddress.customerId, "Consignee");
        }
      }

      //strip certain fields from the quote stop so we can resave a copied quote
      delete qs.id;
      delete qs.quoteId;
      delete qs.freightBillNumber;
      delete qs.freightBillCreatedOn;

      // remove any non-current addresses
      qs.addresses = qs.addresses?.filter(x => x.isCurrent === true);

      // strip address IDs
      qs.addresses?.forEach((addr, aIdx) => {
        delete addr.id;
        delete addr.quoteStopId;
      });
    });

    copyQuote.status = "InProgress";

    this.freezer.get().set({
      viewOnly: false,
      isApprovalNeededForDeclaredValue: isApprovalNeededForDeclaredValue,
      isApprovalNeededForLowRate: false,
      isOverDimensional: copiedFreightTotal.isOverdimensional,
      quote: copyQuote,
      calculateRatingVariableResults: managedAjaxUtil.createInitialState(),
      negotiatedQuoteDataEntry: {},
      workflowState: undefined,
      quoteSaveResults: managedAjaxUtil.createInitialState(),
      editHistories: []
    });

    this.updateQuote({ quoteFreights: strippedQuoteFreight });
    _.forEach(copyQuote.quoteStops, (stop, idx) => {
      //map the appropriate freight to each quote stop
      const thisStopsFreight = _.map(_.filter(quote.quoteFreights, f => f.quoteStop?.stopNumber === stop.stopNumber), (qsf) => {
        const {
          id,
          quoteId,
          quote,
          quoteStop,
          quoteStopId,
          ...strippedFreight
        } = qsf;

        return strippedFreight;
      });
      stop.quoteFreights = thisStopsFreight;

      this.updateQuoteStop(idx, stop);
      this.updateDeclaredValueApprovalNeeded(quote.companyId, idx);
    });

    if (freightRemoved) {
      this.updateFreightTotalData({
        isOverdimensional: false,
        totalLength: 0,
        totalWeight: 0,
        overriddenRatingLength: undefined
      });
    }

    if (notifyInactive) {
      ErrorService.pushErrorMessage("A tarp or commodity on the original quote was inactive and has been removed from the copied quote.");
    }
  }

  public async emailQuotePDF(quote: Quote, pdf: ArrayBuffer): Promise<boolean> {
    var emailSent = false;
    var pdfString = btoa(
      new Uint8Array(pdf)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quoteSaveResults",
      params: {
        body: {
          quoteId: quote.id,
          pdfString: pdfString
        }
      },
      onExecute: (apiOptions, params, options) => {
        const factory = QuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.emailQuotePDF(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to send email.");
      },
      onOk: (data: any) => {
        ErrorService.pushErrorMessage(`Email sent for quote ${quote.quoteNumber}`, "successful");
        emailSent = true;
      }
    });
    return emailSent;
  }

  public async calculateRate(timeout: number = DEFAULT_TIMEOUT) {
    const {
      quote,
      shadowQuote,
      freightTotalData
    } = this.freezer.get().toJS();

    const quoteValidationErrors = await validateSchema(QuoteSchema, quote, {
      context: {
        currentTime: moment(new Date()).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
        numberOfStops: quote.quoteStops?.length ?? 0,
        status: quote.status
      },
      abortEarly: false
    });

    this.freezer.get().set({
      quoteValidationErrors: quoteValidationErrors
    });

    if (quoteValidationErrors) {
      return;
    }

    const previousLowRate = _.find(quote.calculatedRates, c => c.isCurrent === true)?.lowRate;

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quoteCalculateRatingResult",
      params: {
        body: {
          timeout: timeout,
          quote: quote
        }
      },
      onExecute: (apiOptions, params) => {
        const factory = QuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.calculateRate(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to calculate rate.", "high");
      },
      onOk: (data: QuoteCalculateRatingResult) => {
        if (data.quoteCalculatedRate) {
          quote.calculatedRates = [data.quoteCalculatedRate];

          // (re)set freight totals with new rating info
          this.calculateFreightTotalResults(quote.quoteFreights ?? [], data.quoteCalculatedRate);
        }

        let newShadowQuote: Quote | undefined = undefined;

        if (!data.error) {
          this.populateEmptyTarpEntry(quote.quoteStops);
          quote.approvalReasons = data.quoteApprovalReasons;

          newShadowQuote = this.freezer.get().quote.toJS();
        }
        else {
          // clear "local" calculated rates if an error occured while calculating a new rate
          quote.calculatedRates = [];

          if (data.error.indexOf("timed out") > -1) {
            this._setFreightTotalsError(data.error);
          }

          ErrorService.pushErrorMessage(data.error, "high");
        }

        const currentRate = quote.calculatedRates?.[0];
        const rateEngineResult = QuoteEntryFreezerService._getRateEngineResult(currentRate?.rateEngineResults);

        this.freezer.get().set({
          quote: quote,
          rateEngineResult: rateEngineResult,
          isOverDimensional: currentRate?.rateLevelFactor === "Overdimensional",
          shadowQuote: newShadowQuote ?? shadowQuote,
          hasQuoteChanged: false,
          hasOtherInformationChanged: false,
          hasOverrideRateVariableChanged: false
        });

        if (!!previousLowRate && !!currentRate?.lowRate && (currentRate.lowRate !== previousLowRate)) {
          this.updateNegotiatedQuoteDataEntry({ negotiatedRateValue: quote.negotiatedRate });
        }
      }
    });
  }

  // Saving as "In Progress" will always recalculate rate
  public async save(status: QuoteStatusEnum, navigateTo: boolean = true, timeout: number = DEFAULT_TIMEOUT) {
    const {
      quote,
      selectedCustomer,
      upchargeResponses,
      freightTotalData,
      negotiatedQuoteDataEntry,
      editMode
    } = this.freezer.get().toJS();

    // evaluate separately to avoid short-circuit logic
    //const isValidZipCodes = await this.isValidShipperConsignee(true);

    const isQuickQuoteConvert = quote.status === "Convert";
    if (isQuickQuoteConvert) {
      quote.status = undefined;
    }

    const rateVariableErrors = await validateSchema(RateVariableValidationSchema, freightTotalData, {
      abortEarly: false,
      context: {
        currentCompany: quote.company
      }
    });

    if (rateVariableErrors) {
      ErrorService.pushErrorMessage(rateVariableErrors.message);
      return;
    }

    const quoteValidationErrors = await validateSchema(QuoteSchema, quote, {
      context: {
        currentTime: moment(new Date()).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
        numberOfStops: quote.quoteStops?.length ?? 0,
        status: status
      },
      abortEarly: false
    });

    this.freezer.get().set({
      quoteValidationErrors: quoteValidationErrors
    });

    if (quoteValidationErrors) {
      return;
    }

    if (quote.quoteType !== "Quick" && status !== "Convert") {
      const negotiatedRateErrors = await validateSchema(NegotiatedQuoteDataEntrySchema, negotiatedQuoteDataEntry, {
        abortEarly: false,
        context: {
          isOverdimensional: freightTotalData.isOverdimensional,
          quoteResponse: status
        }
      });

      this.freezer.get().set({
        negotiatedRateDataEntryValidationErrors: negotiatedRateErrors
      });

      if (negotiatedRateErrors) {
        return;
      }
    }

    // validate that customer contact is populated
    if ((quote.quoteType === "Full")
      && Helpers.isNullOrUndefined(quote.customerContactId)) {
      if (status === "Pending" || status === "Accepted") {
        ErrorService.pushErrorMessage("Contact person is required before the quote can be pended or accepted.");
        return;
      }
      if (status === "ApprovalNeeded") {
        ErrorService.pushErrorMessage("Contact person is required before the quote can be sent for approval.");
        return;
      }
    }

    // don't send negotiated rate entry values for "In Progress"/re-rating
    // any intended-terminal status (incl approval needed) should save negotiated rate only once
    if (status !== "InProgress" || quote.quoteType === "Quick" || isQuickQuoteConvert) {
      quote.negotiatedRate = negotiatedQuoteDataEntry.negotiatedRateValue;
      quote.notes = negotiatedQuoteDataEntry.notes;
    }

    // only set quote's company ID on initial save
    if (!quote.companyId) {
      quote.companyId = CompanySelectService.getState().companyContext.companyId;
    }

    quote.quoteDate = new Date();
    quote.status = isQuickQuoteConvert ? "Convert" : status;

    if (selectedCustomer && selectedCustomer.id) {
      quote.customerId = selectedCustomer.id;
      quote.customer = selectedCustomer;
    }

    // set modified date - applicable for rerating quotes
    if (quote.modifiedOn) {
      quote.modifiedOn = new Date();
    }

    const currentCompany = CompanySelectService.getState().companyContext.companyKey;

    // set the market for the quote if currently logistics
    if (currentCompany === "KL") {
      quote.isMarketPrimary = (freightTotalData.market === "Primary" || freightTotalData.market === undefined) ? true : false
    } else {
      quote.isMarketPrimary = undefined;
    }

    quote.equipmentType = undefined
    quote.equipmentTypeId = freightTotalData.equipmentType?.id;

    // questions - still need to map them again here in case questions aren't updated on quote save
    quote.quoteQuestions = _.map(upchargeResponses, (r) => {
      return {
        questionId: r.questionId,
        answer: r.answer
      }
    });

    if (quote.quoteStops) {
      // map the appropriate freight to each quote stop
      _.forEach(quote.quoteStops, (qs, idx) => {
        // remove tarpId if "No Tarp" seleted
        if (qs.tarpId === -1) {
          qs.tarpId = undefined;
        }

        qs.quoteFreights = _.filter(quote.quoteFreights, f => f.quoteStop?.stopNumber === qs.stopNumber);
      });
    }

    const hadRateDiscrepancy = !!quote.approvalReasons?.find(x => (x.approvalNeededReasonId === "NegotiatedRateOutOfRange"));

    // quoteFreights are obtained through the quoteStops on the backend, clear them out here to reduce payload
    quote.quoteFreights = undefined;

    const useQuickQuoteWorkflow = quote.quoteType === "Quick" || isQuickQuoteConvert;
    const ajaxStateProperty = useQuickQuoteWorkflow ? "quickQuoteSaveResults" : "quoteSaveResults";

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: ajaxStateProperty,
      onExecute: (apiOptions, _) => {
        if (useQuickQuoteWorkflow) {
          const factory = QuickQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return factory.saveQuickQuote({
            body: {
              quote: quote,
              status: status,
              overriddenRatingLength: freightTotalData.overriddenRatingLength,
              overrideLengthTimeout: timeout
            }
          });
        } else {
          const factory = QuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return factory.saveQuote({
            body: {
              quote: quote,
              overriddenRateVariable: freightTotalData.ratingVariableOverriddenAmount,
              overriddenRatingLength: freightTotalData.overriddenRatingLength,
              overrideLengthTimeout: timeout,
              isEditMode: editMode
            }
          });
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(this.handleErrorStatusCode(err) ?? "Failed to save quote.");
      },
      onOk: (data: QuoteResultVM) => {
        // TODO: JSON.Serialize of nested Exception uses Pascal case, even though swaggergen (and all other JSON serialize) does camel case
        const error: string = (data.state?.error as any)?.Message;

        this.populateEmptyTarpEntry(data.quote?.quoteStops);

        let newQuote: Quote | undefined = undefined;

        const currentRate = data?.quote?.calculatedRates?.find(x => x.isCurrent);
        const rateEngineResult = QuoteEntryFreezerService._getRateEngineResult(currentRate?.rateEngineResults);
        const isAdminUser = SecurityContext.isInGroup("/Admin");
        const isManagerUser = SecurityContext.isInGroup("/Manager");
        const isAuthorizedCreated = currentRate && data.quote?.approvalReasons && data.quote?.approvalReasons?.length > 0 && (isAdminUser || isManagerUser);

        // if returned, the quote was saved, even if another internal error happened afterward
        if (data.quote) {
          // rate calculation failed - erase rate + model display
          if (!currentRate) {
            data.quote.calculatedRates = [];

            // clear freight totals summary if rating error was from a timeout
            if (error.indexOf("timed out") > -1) {
              this._setFreightTotalsError(error);
            }
          }

          this.freezer.get().set({
            quote: data.quote,
            currentQuoteFreight: data.quote?.quoteFreights,
            rateEngineResult: rateEngineResult,
            workflowState: data.state,
            isOverDimensional: currentRate?.rateLevelFactor === "Overdimensional",
            isAdminCreatedApprovalReasons: isAuthorizedCreated
          });

          if (data.quote?.quoteStops?.length) {
            // trim the seconds off the returned time and convert dates to format JS likes
            this._formatShipperConsigneeDateTimes();
            this.updateQuote({ quoteFreights: data.quote.quoteFreights });
          }

          newQuote = this.freezer.get().quote.toJS();
          // set the shadowQuote object to the current saved quote to determine when differences are made
          this.freezer.get().set({
            shadowQuote: newQuote,
            hasQuoteChanged: false,
            hasOtherInformationChanged: false,
            hasRateContactChanged: false,
            hasOverrideRateVariableChanged: false
          });

          if (negotiatedQuoteDataEntry.negotiatedRateValue) {
            this.updateNegotiatedQuoteDataEntry({ negotiatedRateValue: negotiatedQuoteDataEntry.negotiatedRateValue });
          }
        }

        if (error) {
          ErrorService.pushErrorMessage(error, "high");
          return;
        }

        switch (newQuote?.status) {
          case "Accepted":
            const freightBillNumbers = newQuote?.quoteStops?.map(x => x.freightBillNumber ?? "").join(", ");
            ErrorService.pushErrorMessage(`Freight bill(s) created: ${freightBillNumbers}`, "successful");

            // quote was auto-accepted after detecting a new rate discrepancy (i.e. previously approved for low rate)
            if (!hadRateDiscrepancy
              && !!newQuote.approvalReasons?.find(x => (x.approvalNeededReasonId === "NegotiatedRateOutOfRange")
                && (x.approvalStatus === "Approved"))) {
              this.openRateDiscrepancyModal();
            }
            else {
              NavigationService.navigateTo("/quotes");
            }
            break;
          case "PendingNeedsCustomers":
            if (editMode) {
              this.updateEditMode(false);
            } else {
              NavigationService.navigateTo("/quotes");
            }
            break;
          case "Pending":
            // compare against intended status change, before navigating away
            // e.g. Pending could be an admin approving, or rate/mileage discrepancy, or... etc
            if (newQuote.approvalReasons?.find(x => (
              (x.approvalNeededReasonId === "NegotiatedRateOutOfRange")
              || (x.approvalNeededReasonId === "MileageDifferenceOverThreshold")
              || (x.approvalNeededReasonId === "ShipperZoneChanged")
              || (x.approvalNeededReasonId === "ConsigneeZoneChanged")
            )
              && (x.approvalStatus === "PendingApproval"))) {
              this.openRateDiscrepancyModal();
            }
            else if (!editMode && navigateTo) {
              NavigationService.navigateTo(quote.quoteType === "Quick" ? "/quickquotes" : "/quotes");
            }
            if (editMode) {
              this.updateEditMode(false);
            }
            break;
          case "InProgress": // don't navigate away
            this.freezer.get().set({ viewOnly: false });
            if (newQuote.id && quote.id !== newQuote.id) {
              NavigationService.navigateTo(`/salesportal/quote/${newQuote.id}/false`, true);
            }
            break;
          default:
            // don't navigate away if some other (related, internal) process forced a re-rate
            if (status !== "AdminRerate") {
              NavigationService.navigateTo(quote.quoteType === "Quick" ? "/quickquotes" : "/quotes");
            }
            break;
        }
      }
    });
  }

  private handleErrorStatusCode(err:  managedAjaxUtil.IErrorMessage<any>): string | undefined {
    switch (err.statusCode) {
      case 504:
        return "Quote Timed Out.";
      case 413:
        return "Quote too large to process.";
      default:
        return err.body?.message ?? undefined;
    }
  }

  public async cancelQuote(cancelQuoteParams: CancelQuoteParams) {
    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "cancelQuoteResults",
      params: {
        body: cancelQuoteParams
      },
      onExecute: (apiOptions, params, options) => {
        const factory = QuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.cancelQuote(params);
      },
      onOk: (data: Quote) => {
        NavigationService.navigateTo("/quotes");
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to cancel quote.")
      }
    });
  }

  public async fetchCommodityQuestions(forceUpdate: boolean = false) {
    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "commodityQuestionsFetchResults",
      params: {

      },
      onExecute: (apiOptions, param, options) => {
        const factory = QuestionApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1QuestionsActiveCommodityGet(param);
      },
      onOk: (data: Question[]) => {

        const {
          viewOnly
        } = this.freezer.get().toJS();
        const quoteStops = this.freezer.get().quote.quoteStops?.toJS();
        const isEditQuote = this.freezer.get().quote.status === "InProgress" ? true : false;

        if (isEditQuote && !viewOnly) {
          quoteStops?.forEach((qs, idx) => {
            if (qs.quoteFreights) {
              qs.quoteFreights = this._mapActiveAndNewCommodityQuestions(qs.quoteFreights, data);

              this.updateQuoteStop(idx, { quoteFreights: qs.quoteFreights });
            }
          });
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch commodity questions.")
      }
    });
  }

  public async fetchSiteIds(tmCompanyid?: number, forceUpdate: boolean = false) {
    const sitesFetchResults = this.freezer.get().sitesFetchResults;

    if (!tmCompanyid || (sitesFetchResults.hasFetched && !forceUpdate)) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      force: forceUpdate,
      ajaxStateProperty: "sitesFetchResults",
      params: {
        "tmCompanyId": tmCompanyid
      },
      onExecute: (apiOptions, param, options) => {
        const factory = OtherFreightInfoApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1OtherFreightInfoSitesPost(param);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch site ids");
      },
      onOk: (data: SiteView[]) => {
        this.freezer.get().set({
          sites: data
        });
      }
    })
  }

  public async fetchOpsCodes(tmCompanyId?: number, forceUpdate: boolean = false) {
    const opsCodesFetchResults = this.freezer.get().opsCodesFetchResults;

    if (!tmCompanyId || (opsCodesFetchResults.hasFetched && !forceUpdate)) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      force: forceUpdate,
      ajaxStateProperty: "opsCodesFetchResults",
      params: {
        "tmCompanyId": tmCompanyId
      },
      onExecute: (apiOptions, param, options) => {
        const factory = OtherFreightInfoApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1OtherFreightInfoOpsCodesPost(param);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch ops codes");
      },
      onOk: (data: OpsCodeView[]) => {
        this.freezer.get().set({
          opsCodes: data
        });
      }
    });
  }

  public async fetchUpchargeQuestions(forceUpdate: boolean = false) {
    const {
      viewOnly,
      quote
    } = this.freezer.get().toJS();

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "upchargeQuestionsFetchResults",
      params: {

      },
      onExecute: (apiOptions, param, options) => {
        const factory = QuestionApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1QuestionsActiveUpchargeGet(param);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch upcharge questions.")
      }
    });

    if (quote && viewOnly) {
      this._setResponsesOnViewOnlyOrCopy();
    }
  }

  public async fetchUpchargeTarpRates(companyId: number | undefined, forceUpdate: boolean = false) {
    const {
      upchargeTarpsFetchResults
    } = this.freezer.get();

    if ((upchargeTarpsFetchResults.hasFetched && !forceUpdate) || !companyId) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      force: forceUpdate,
      ajaxStateProperty: "upchargeTarpsFetchResults",
      params: {
        activeOnly: true,
        "companyId": 0
      },
      onExecute: (apiOptions, param, options) => {
        const factory = TarpApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(param);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch tarps.");
      },
      onOk: (data: Tarp[]) => {
        var tarps = _.sortBy(data, t => { return t.tarpName?.toLowerCase(); });
        const noTarpValue: TarpValue = {
          id: -1,
          tarpId: -1,
          rate: 0.0000,
        };
        const noTarp: Tarp = {
          id: -1,
          tarpName: "No Tarp",
          tarpDescription: "No tarp needed for the quote's freight",
          isActive: true,
          companyId: undefined,
          tarpValues: [noTarpValue]
        };
        tarps.push(noTarp);
        return tarps;
      }
    });
  }

  // miles for entire trip for use in rate calculation gets set on quote
  private async updateShipperConsignee(calculateMileage: boolean = true, isFromTruckmate: boolean = false) {
    //TO-DO: I set up the shipperConsigee as an array of shipperConsignee objects now
    //We'll need to use it to validate each individual zip code with PCMiler - or can we
    //do it with the quoteStop array?
    const {
      quote
    } = this.getState();

    //find any stops with missing zips
    for (let quoteStop of quote.quoteStops!) {
      const addresses = quoteStop.addresses;
      if (_.some(addresses, adr => (adr.isCurrent === true) && (adr.zipPostalCode === undefined))) {
        return;
      }
    }

    if (calculateMileage) {
      this.freezer.get().set({
        isZipValidationSuccessful: false
      });

      const isValidZipFormat = await this.isValidShipperConsignee();

      if (isValidZipFormat) {
        this.fetchValidatedZipPostalCodes();
      }

      if (!isValidZipFormat && isFromTruckmate) {
        ErrorService.pushErrorMessage("Invalid zipcode format. If this zipcode came from TruckMate, you will need to update it there.");
      }
    }
  }

  /// Applies the flags to the stops.
  public async applyTripFlags() {
    const stops = this.getState().quote?.quoteStops ?? [];

    const filterStops = _.filter(stops, (qs) => quoteStopUtilities.validateStopsFilter(qs));

    if (stops.length === filterStops.length) {
      await managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        ajaxStateProperty: "applyTripFlagsResults",
        onExecute: (apiOptions, params, options) => {
          const factory = QuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return factory.apiV1QuotesApplyLayoverExpeditedFlagsPost(params);
        },
        params: {
          body: filterStops,
        },
        onOk: (data: QuoteStopRouteResults) => {
          if (data.successful) {
            _.forEach(data.stops, (d) => {
              const stops = this.freezer.get().quote?.quoteStops ?? [];
              const foundStop = _.find(stops, s => s.stopNumber === d.stopNumber);

              if (foundStop !== undefined) {
                foundStop.set({
                  isConsigneeExpedited: d.isConsigneeExpedited,
                  isConsigneeLayover: d.isConsigneeLayover,
                  isShipperExpedited: d.isShipperExpedited,
                  isShipperLayover: d.isShipperLayover
                })
              }
            });
          }
        }
      });
    }
  }

  // Fetches the mileage for multiple stops.
  public async fetchTripMileage() {
    const stops = this.freezer.get().quote?.quoteStops ?? []

    // Filter the stops to validate stops only.
    const filteredStops = _.filter(stops, (s) => {
      const addresses = s.addresses?.filter(a => a.isCurrent === true) ?? [];

      if (addresses.length !== 2)
        return false;

      if (addresses[0].zipPostalCode !== undefined && addresses[0].zipPostalCode !== null && addresses[0].zipPostalCode.trim() !== "" &&
        addresses[1].zipPostalCode !== undefined && addresses[1].zipPostalCode !== null && addresses[1].zipPostalCode.trim() !== "")
        return true;

      return false;
    });

    // Convert the filtered list to Zip Code Pairs.
    const zipCodes: ZipCodePair[] = _.map(filteredStops, (s) => {
      return {
        originZipPostalCode: _.find(s.addresses, a => (a.isCurrent === true) && (a.addressType === "Shipper"))?.zipPostalCode,
        destZipPostalCode: _.find(s.addresses, a => (a.isCurrent === true) && (a.addressType === "Consignee"))?.zipPostalCode
      } as ZipCodePair
    }) ?? [];

    // If nothing is valid, do not perform the request.
    if (zipCodes.length === 0) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "tripMileageResults",
      params: {
        body: zipCodes
      },
      onExecute: (apiOptions, params, options) => {
        const factory = PCMilerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1PCMilerGetTripMileagePost(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("PC Miler is not available. Contact IT Support", "high", "Retry", () => this.fetchTripMileage());
      },
      onOk: (data: QuoteStopRouteResults) => {
        if (data.successful) {
          this.freezer.get().set({
            isZipValidationSuccessful: true
          });

          this.freezer.get().quote.set({
            miles: data.mileage === undefined ? undefined : Math.ceil(data.mileage),
          });
        } else {
          ErrorService.pushErrorMessage("Unable to calculate trip mileage");
        }
      }
    });
  }

  public async fetchValidatedZipPostalCodes() {
    const {
      quote,
      pcMilerValidationResults,
    } = this.getState();

    const validatedZipCodes = pcMilerValidationResults.data ?? []

    // Get the Zip/Postal Codes for all of the stops.
    const shipperConsigneesZipCodes = _.flatten(_.map(quote.quoteStops ?? [], (qs) => [
      _.find(qs.addresses, adr => (adr.isCurrent === true) && (adr.addressType === "Shipper"))?.zipPostalCode,
      _.find(qs.addresses, adr => (adr.isCurrent === true) && (adr.addressType === "Consignee"))?.zipPostalCode
    ]
    ));

    // Get the unique list of codes.
    const filterZipPostalCode = _.uniq(_.filter(shipperConsigneesZipCodes, (zpc) => zpc !== undefined && zpc !== null && zpc.trim() !== "") ?? []) as string[];

    // No need to call the API if the zip codes are in the validated list.
    if (pcMilerValidationResults.hasFetched && validatedZipCodes.length !== 0 && _.every(filterZipPostalCode, fz => _.some(validatedZipCodes, vz => vz.zipCode === fz))) {
      if (_.every(filterZipPostalCode, fz => _.some(validatedZipCodes, vz => vz.zipCode === fz && vz.isValid))) {
        this.fetchTripMileage();
        this.applyTripFlags();
      }

      await this.isValidShipperConsignee(true);
      return;
    }

    // Do not perform the validation if the length is 0.
    if (filterZipPostalCode.length === 0)
      return;

    // Make the api call.
    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "pcMilerValidationResults",
      onExecute: (apiOptions, params, options) => {
        const factory = PCMilerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1PCMilerValidateZipCodesPost(params);
      },
      params: {
        body: filterZipPostalCode,
      },
      onOk: (data: ZipCodeValidationResult[]) => {
        if (data !== null && data !== undefined && data.length !== 0) {
          if (_.every(data, (d) => d.isValid ?? false)) {
            this.fetchTripMileage();
            this.applyTripFlags();
          }
        }
        return data;
      }
    });

    await this.isValidShipperConsignee(true);
  }

  private async isValidShipperConsignee(validateZips: boolean = false, pushMsg: boolean = false): Promise<boolean> {
    const {
      quote,
      pcMilerValidationResults,
      currentQuoteStopIdx
    } = this.getState();

    const shipperConsignees: ZipCodePair[] = _.map(quote.quoteStops, (qs) => {
      return {
        originZipPostalCode: _.find(qs.addresses, adr => (adr.isCurrent === true) && (adr.addressType === "Shipper"))?.zipPostalCode,
        destZipPostalCode: _.find(qs.addresses, adr => (adr.isCurrent === true) && (adr.addressType === "Consignee"))?.zipPostalCode
      } as ZipCodePair;
    }) ?? [];

    const zipErrors = await validateSchema(ZipCodePairSchema, shipperConsignees, {
      abortEarly: false,
      context: {
        validateZips: validateZips,
        pcMilerResults: pcMilerValidationResults.data ?? []
      }
    });

    this.freezer.get().set({
      shipperConsigneeValidationErrors: zipErrors
    });

    if (pcMilerValidationResults?.data) {
      const invalidZips: string[] = [];
      pcMilerValidationResults.data.forEach(z => {
        if (z.zipCode && !z.isValid) {
          invalidZips.push(z.zipCode);
        }
      });
      const customerType = invalidZips.includes(shipperConsignees[currentQuoteStopIdx].originZipPostalCode as string) &&
        invalidZips.includes(shipperConsignees[currentQuoteStopIdx].destZipPostalCode as string) ? "Shipper, Consignee"
        : invalidZips.includes(shipperConsignees[currentQuoteStopIdx].originZipPostalCode as string) ? "Shipper"
        : invalidZips.includes(shipperConsignees[currentQuoteStopIdx].destZipPostalCode as string) ? "Consignee"
        : undefined;
      if (customerType && pushMsg) {
        ErrorService.pushErrorMessage(`${customerType} zipcode could not be found.`);
      }
    }

    return zipErrors === null;
  }

  public async fetchQuoteData(quoteId: number | undefined, viewOnly: boolean = false) {
    if (!quoteId) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quoteGetResults",
      params: {
        quoteId: quoteId
      },
      onExecute: (apiOptions, param, options) => {
        const factory = QuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getQuote(param);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch quote");
      },
      onOk: (data: QuoteResultVM) => {
        const quote = data.quote;
        this.populateEmptyTarpEntry(quote?.quoteStops);

        const {
          assignedCompanies,
          companyContext
        } = CompanySelectService.getState();
        let companyKey = companyContext.companyKey;

        // initial quote company vs current company context check
        if (quote?.companyId && (companyContext.companyId !== quote.companyId)) {
          const assignedCompany = assignedCompanies.find(x => x.id === quote.companyId)
          if (!assignedCompany) {
            // user cannot access quote's company - redirect back to quotes list
            this.freezer.get().set({
              forceNavigate: true
            });

            ErrorService.pushErrorMessage("Unable to access quote");
            NavigationService.navigateTo("/quotes");

            return;
          }
          else {
            // user can access quote's company - change company context to match
            CompanySelectService.updateSelectedCompany(assignedCompany.companyKey!);
            companyKey = assignedCompany.companyKey!;
          }
        }

        // TODO: this is explicitly only processing one quote stop
        if (quote?.quoteStops?.length) {
          const quoteStop = quote.quoteStops[0];

          const origAddress = _.find(quoteStop.addresses, a => (a.isCurrent === true) && (a.addressType === "Shipper"));
          const destAddress = _.find(quoteStop.addresses, a => (a.isCurrent === true) && (a.addressType === "Consignee"));

          if (origAddress && origAddress.customer) {
            this.updateSelectedCustomer(origAddress.customer, "Shipper", !viewOnly);
          } else {
            this.updateVisiblePlace("Shipper", {
              city: origAddress?.city,
              stateProvince: origAddress?.region?.regionAbbreviation
            });
            this.updateShipperConsignee();
          }

          if (destAddress && destAddress.customer) {
            this.updateSelectedCustomer(destAddress.customer, "Consignee", !viewOnly);
          } else {
            this.updateVisiblePlace("Consignee", {
              city: destAddress?.city,
              stateProvince: destAddress?.region?.regionAbbreviation
            });
            this.updateShipperConsignee(!viewOnly);
          }

          let formattedExpirationTime = jsDateConverter(quote.expirationDate)?.setHours(23, 59);

          if (formattedExpirationTime) {
            quote.expirationDate = new Date(formattedExpirationTime);
          }

          const recentRate = _.orderBy(quote.calculatedRates, 'createdOn', 'desc')?.[0];
          const rateEngineResult = QuoteEntryFreezerService._getRateEngineResult(recentRate?.isCurrent === true ? recentRate.rateEngineResults : undefined);

          // re-check to prevent people from navigating to a quote they shouldn't edit
          const isUsersQuote = quote.createdBy?.userId === SharedSecurityContext.getUserId()
          const allowEdit = isUsersQuote
            && moment().diff(quote.createdOn, "days") <= 7
            && quote.status === "InProgress";

          const pendingQuickQuote = quote.quoteType === "Quick" && quote.status === "Pending";

          // returned most-recent rate (re)fills in freight totals summary
          this.calculateFreightTotalResults(quote.quoteFreights ?? [], recentRate);

          let freightTotalDataUpdate: Partial<FreightTotalData> = {
            equipmentType: quote.equipmentType
          };

          if (companyKey === "KL") {
            freightTotalDataUpdate.market = quote.isMarketPrimary ? "Primary" : "Secondary";
          }
          else {
            freightTotalDataUpdate.market = undefined;
          }

          this.freezer.get().freightTotalData.set(freightTotalDataUpdate);

          var isViewOnlyUser = SecurityContext.isInGroup("/ViewOnly");

          this.freezer.get().set({
            quote: quote,
            viewOnly: isViewOnlyUser ? true : pendingQuickQuote ? false : viewOnly ? true : !allowEdit,
            rateEngineResult: rateEngineResult,
            workflowState: data.state,
            isZipValidationSuccessful: true,
            negotiatedQuoteDataEntry: {
              negotiatedRateValue: quote.negotiatedRate,
              notes: quote.notes
            },
            selectedCustomer: quote.customer,
            isOverDimensional: this.freezer.get().freightTotalData.isOverdimensional,
            currentQuoteFreight: quote.quoteFreights,
            upchargeResponses: _.map(quote.quoteQuestions, q => _.pick(q, "questionId", "answer")),
            forceNavigate: false,
            isAdminCreatedApprovalReasons: isUsersQuote
          });
        }

        this._formatShipperConsigneeDateTimes();
        this.getContactsForSelectedCustomer(quote?.customer?.id ?? 0);

        // set shadow on retrieve, but _after_ all other formattings, etc, to prevent erroneous change detection
        this.freezer.get().set({
          shadowQuote: this.freezer.get().quote.toJS(),
          hasQuoteChanged: false,
          hasOtherInformationChanged: false,
          hasOverrideRateVariableChanged: false
        });
      }
    });
  }

  public fetchDataChangeLogs(quoteId: number | undefined) {
    if (!quoteId) {
      return;
    }

    const relatedEntity: RelatedEntity = {
      entityType: "Quote",
      identifier: quoteId
    };

    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "dataChangeLogsFetchResults",
      params: {
        body: [relatedEntity]
      },
      onExecute: (apiOptions, params, options) => {
        const factory = AuditLogApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getDataChangeLogs(params);
      },
      onOk: (data: AuditDataChangeAuditLog[]) => {
        this.freezer.get().set({
          editHistories: data
        });
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch audit events");
      }
    })
  }

  private populateEmptyTarpEntry(stops: QuoteStop[] | undefined) {
    // add in "No Tarp" tarpId for all quoteStops without a tarpId
    stops?.forEach(stop => {
      if (!stop.tarpId) {
        stop.tarpId = -1;
      }
    });
    return;
  }

  /*************************************
    Question modal control function
    along with various helper functions
  **************************************/
  public openUpchargeQuestionModal(reset: boolean = false) {
    if (this.freezer.get().questionModalIsOpen) {
      return;
    }

    if (reset) {
      let openModal: boolean = true;
      openModal = this._resetFlatUpchargeQuestionModal();


      if (!openModal) {
        return;
      }

      this.freezer.get().set({
        modalQuestionType: "Flat $ Upcharge",
        questionModalIsOpen: true
      });
    } else {

      this._reopenFlatUpchargeQuestionModal();
    }
  }

  private _resetFlatUpchargeQuestionModal(): boolean {
    const {
      quote,
      flatUpchargeOnFocus: priorFlatUpcharge
    } = this.freezer.get().toJS();

    let currentFlatUpcharge = quote.flatUpcharge;

    //don't open the modal if the flat upcharge hasn't changed - or if the reason is already given
    if (currentFlatUpcharge === priorFlatUpcharge || (!!priorFlatUpcharge && !!currentFlatUpcharge)) {
      return false;
    }

    const questionFetchResults = this.freezer.get().upchargeQuestionsFetchResults.toJS();
    let questions = questionFetchResults.data ?? [];

    let defaultAnswers = questions.map(q => {
      return {
        questionId: q.id,
        answer: q.isNa ? "NA" : "No"
      } as Response
    });

    //if the input field is emptied, reset the modal and disable the button
    if (!quote.flatUpcharge) {
      this.freezer.get().set({
        upchargeResponses: defaultAnswers
      });

      this.updateQuote({ flatUpchargeReason: undefined, otherFlatUpchargeReason: undefined });

      return false;
    }
    else {
      this.updateQuote({ flatUpchargeReason: undefined, otherFlatUpchargeReason: undefined });
      this._reopenFlatUpchargeQuestionModal();
    }

    return true;
  }

  private _reopenFlatUpchargeQuestionModal() {
    let questionFetchResults = this.freezer.get().upchargeQuestionsFetchResults.toJS().data ?? [];
    const responses = this.freezer.get().upchargeResponses.toJS();

    const newQuestions = _.filter(questionFetchResults, q => _.find(responses, r => r.questionId === q.id) === undefined);
    if (newQuestions.length > 0) {
      const newResponses = responses.concat(newQuestions.map(q => {
        return {
          questionId: q.id,
          answer: q.isNa ? "NA" : "No"
        } as Response
      }));

      this.freezer.get().set({ upchargeResponses: newResponses });
    }

    this.freezer.get().set({
      modalQuestionType: "Flat $ Upcharge",
      questionModalIsOpen: true
    });
  }

  private async _setResponsesOnViewOnlyOrCopy() {
    const {
      quote,
      upchargeQuestionsFetchResults: questionFetchResults
    } = this.freezer.get().toJS();

    const quoteQuestions = quote.quoteQuestions;

    const questionsWithAnswers = this._mapResponsesToQuestions(questionFetchResults.data ?? [], quoteQuestions);

    this.freezer.get().set({
      upchargeResponses: questionsWithAnswers
    });
  }

  private _mapResponsesToQuestions(questionsArray: Question[], questionResponses: QuoteQuestion[] | undefined) {
    const questionsWithAnswers = _.map(questionsArray, q => {
      const response = _.find(questionResponses, qr => qr.questionId === q.id);

      return {
        questionId: q.id,
        answer: (response?.answer) ?? (q.isNa ? "NA" : "No")
      }
    });

    return questionsWithAnswers;
  }

  public openPercentUpchargeModal(reset: boolean = false) {
    //don't try to open/generate another field's modal if you click into it
    if (this.freezer.get().questionModalIsOpen) {
      return;
    }

    const {
      quote,
      percentUpchargeOnFocus: priorPercentUpcharge
    } = this.freezer.get().toJS();

    if (reset) {
      const currentPercentUpcharge = quote.upchargePercentage;

      if (currentPercentUpcharge === priorPercentUpcharge || (!!priorPercentUpcharge && currentPercentUpcharge)) {
        return;
      }
      //if the field is emptied, reset the modal value and disable the button
      else if (!currentPercentUpcharge) {
        this.updateQuote({ percentUpchargeReason: undefined });
        return;
      }

      this.updateQuote({ percentUpchargeReason: "" });
    }

    this.freezer.get().set({
      modalQuestionType: "% Upcharge",
      questionModalIsOpen: true
    });
  }

  public async closeQuestionsModal() {
    const {
      quote,
      modalQuestionType: currentQuestionType,
      viewOnly
    } = this.freezer.get();

    if (viewOnly) {
      this.freezer.get().set({ questionModalIsOpen: false });
      return;
    }

    if (currentQuestionType === "Flat $ Upcharge" && !quote.otherFlatUpchargeReason) {
      this.updateFlatUpchargeOtherResponse("No");
      this.updateUpchargeReason("");
    }

    this.freezer.get().set({
      questionModalIsOpen: false
    });
  }

  // calculated in workflow, keep the check here for UI flag (for now)
  public updateDeclaredValueApprovalNeeded(companyId: number | undefined, quoteStopIndex: number) {
    const quote = this.freezer.get().quote.toJS();
    const declaredValue = quote?.quoteStops?.[quoteStopIndex].declaredValue;
    const useCompanyId = quote?.companyId ?? companyId; // either the saved company ID on the quote, or the current context

    //declared value threshold check
    if (useCompanyId && declaredValue) {
      let approvalNeeded = false;
      const threshold = DeclaredValueService.getThresholdForCompany(useCompanyId);
      const hasApproval = quote.approvalReasons ? quote.approvalReasons.find(x => x.approvalNeededReasonId === "DeclaredValue" && x.approvalStatus === "PendingApproval") === undefined : false;
      if (!hasApproval && threshold && declaredValue >= (threshold?.declaredValue ?? 0)) {
        approvalNeeded = true;
      }

      this.freezer.get().set({
        isApprovalNeededForDeclaredValue: approvalNeeded
      });
    }
  }

  private openRateDiscrepancyModal() {
    this.freezer.get().set({
      isRateDiscrepancyModalOpen: true
    });
  }

  public closeRateDiscrepancyModal() {
    this.freezer.get().set({
      isRateDiscrepancyModalOpen: false
    });
  }

  private _formatShipperConsigneeDateTimes() {
    const {
      quote
    } = this.freezer.get().toJS();

    _.forEach(quote.quoteStops, (qs, idx) => {
      //trim the seconds off the returned time
      //also convert dates to format JS likes
      this.updateQuoteStop(idx, {
        shipperHardTime: qs.shipperHardTime?.substring(0, 5),
        consigneeHardTime: qs.consigneeHardTime?.substring(0, 5),
        shipperStartDate: jsDateConverter(qs.shipperStartDate),
        shipperEndDate: jsDateConverter(qs.shipperEndDate),
        consigneeStartDate: jsDateConverter(qs.consigneeStartDate),
        consigneeEndDate: jsDateConverter(qs.consigneeEndDate)
      });
    });
  }

  private static _getRateEngineResult(rateEngineJSON: string | undefined): RateEngineResult | undefined {
    let rateEngineResult: RateEngineResult | undefined;

    if (rateEngineJSON) {
      try {
        rateEngineResult = JSON.parse(rateEngineJSON);
      }
      catch (ex) {
        if (SharedSecurityContext.hasRole(["yahara:dev"])) {
          console.log(ex);
        }
      }
    }

    return rateEngineResult;
  }

  private updateVisiblePlace(addressType: AddressAddressTypeEnum, place: Place) {
    if (addressType === "Shipper") {
      this.freezer.get().set({ shipperPlace: place });
    }
    else if (addressType === "Consignee") {
      this.freezer.get().set({ consigneePlace: place });
    }
  }

  public calculateFreightLengthForPrint() {
    const freights = this.freezer.get().quote.quoteFreights;
    let totalLength = 0;
    freights?.forEach(qf => {
      if (qf.length && qf.numberOfPieces) {
        totalLength += (qf.length * qf.numberOfPieces);
      }
    });
    return totalLength;
  }

  public setQuoteType(isFullQuoteType: boolean) {
    const { quote } = this.freezer.get().toJS();
    quote.quoteType = isFullQuoteType ? "Full" : "Quick";

    // initialize Quick Quote if new
    if (quote.quoteType === "Quick" && quote.quoteStops && !quote.quoteStops[0].shipperStartDate) {
      const now = moment().startOf('day').toDate();
      quote.quoteStops[0].shipperStartDate = now;
      quote.quoteStops[0].consigneeStartDate = now;
    }

    this.freezer.get().set({ quote: quote });
  }

  private _setFreightTotalsError(error: string) {
    this.freezer.get().freightTotalData.set({
      ratingError: error,
      ratingVariable: "Error",
      ratingVariableCalculatedAmount: 0,
      totalLength: 0,
      overriddenRatingLength: undefined,
      isOverdimensional: false
    });
  }

  public prepForQuickQuoteConvert() {
    const { quote } = this.freezer.get().toJS();

    quote.calculatedRates = undefined;
    quote.quoteStatusHistories = undefined;
    quote.quoteNumber = undefined;
    quote.status = "Convert";
    quote.createdOn = undefined;
    this.freezer.get().set({ quote: quote, rateEngineResult: undefined, forceNavigate: true, workflowState: undefined });
  }

  public createNewQuickQuote() {
    this.clearFreezer();
    this.setQuoteType(false);
  }

  public async prepForCustomerQuoteConvert(customerQuote: CustomerQuote) {
    const currentCompany = CompanySelectService.getState().companyContext.companyKey;
    const { quote } = this.freezer.get().toJS();
    quote.companyId = customerQuote.companyId;
    quote.customerId = customerQuote.customerId;
    quote.customerContactId = customerQuote.customerContactId;
    quote.customerQuote = {
      linkId: customerQuote.id,
      linkName: customerQuote.quoteNumber?.toString()
    };

    if (currentCompany === "KL") {
      quote.isMarketPrimary = false;
    }

    this.freezer.get().set({ quote: quote });

    const freightToAdd: QuoteFreight[] = [];
    _.forEach(customerQuote.customerQuoteFreights, f => {
      const {
        id,
        customerQuoteId,
        createdOn,
        ...strippedFreight
      } = f;
      const quoteFreight: QuoteFreight = strippedFreight;
      quoteFreight.quoteStop = {stopNumber: 1};
      this.addQuoteFreight(quoteFreight);
      
      freightToAdd.push(quoteFreight);
    });

    quote.quoteFreights = freightToAdd;

    if (quote.quoteStops) {
      quote.quoteStops[0].description = customerQuote.description;
      quote.quoteStops[0].tarpId = customerQuote.tarpId ?? -1;
      quote.quoteStops[0].tarp = customerQuote.tarp;
      quote.quoteStops[0].declaredValue = customerQuote.declaredValue;
      quote.quoteStops[0].shipperStartDate = customerQuote.shipperStartDate;
      quote.quoteStops[0].isShipperAppointmentRequired = customerQuote.isShipperAppointmentRequired;
      quote.quoteStops[0].shipperEndDate = customerQuote.shipperEndDate;
      quote.quoteStops[0].shipperHardTime = customerQuote.shipperHardTime;
      quote.quoteStops[0].consigneeStartDate = customerQuote.consigneeStartDate;
      quote.quoteStops[0].isConsigneeAppointmentRequired = customerQuote.isConsigneeAppointmentRequired;
      quote.quoteStops[0].consigneeEndDate = customerQuote.consigneeEndDate;
      quote.quoteStops[0].consigneeHardTime = customerQuote.consigneeHardTime;
      quote.quoteStops[0].ponumber = customerQuote.poNumber;
      quote.quoteStops[0].externalNotes = customerQuote.notes;
      quote.quoteStops[0].quoteFreights = freightToAdd;
      quote.quoteStops[0].isMilitaryBase = customerQuote.isMilitaryBase;
      quote.quoteStops[0].isTwicCardRequired = customerQuote.isTwicCardRequired;
      _.forEach(quote.quoteStops[0].addresses, a => {
        if (a.addressType === "Shipper") {
          a.zipPostalCode = customerQuote.shipperZipPostalCode;
        } else {
          a.zipPostalCode = customerQuote.consigneeZipPostalCode;
        }     
      });
    }

    this.onEquipmentTypeChange(customerQuote.equipmentType);
    
    this.onMarketChange("Secondary");
    const newFreightTotal: Partial<FreightTotalData> = await this.calculateFreightTotalResults(quote.quoteFreights);
    this.freezer.get().set({
      quote: quote,
      isOverDimensional: newFreightTotal.isOverdimensional
    });

    if (customerQuote.shipperZipPostalCode) {
      this.onPlaceChanged(customerQuote.shipperZipPostalCode, "Shipper");
    }
    if (customerQuote.consigneeZipPostalCode) {
      this.onPlaceChanged(customerQuote.consigneeZipPostalCode, "Consignee");
    }

    this.updateDeclaredValueApprovalNeeded(customerQuote.companyId, 0);
  }

  public fetchAccessorialChargeValues(quoteId: number | undefined, forceUpdate: boolean = false) {
    const {
      accessorialChargeValueFetchResults: accessorialChargeValueFetchResults,
    } = this.freezer.get();

    if (!quoteId || (accessorialChargeValueFetchResults.hasFetched && !forceUpdate)) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "accessorialChargeValueFetchResults",
      params: {
        quoteId: quoteId
      },
      onExecute: (apiOptions, params, options) => {
        const factory = QuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getAccessorialChargeValues(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch accessorial charge values.");
      },
    });
  }

  public saveAccessorialChargeValue(accessorialChargeValue: AccessorialChargeValue): Promise<AccessorialChargeValue | void> {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "accessorialChargeValueSaveResults",
      params: {
        body: accessorialChargeValue
      },
      onExecute: (apiOptions, params, options) => {
        const factory = QuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.addAccessorialChargeValue(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to save accessorial charge value.");
      },
      onOk: (data: AccessorialChargeValue) => {
        if (data.status === "Approved") {
          ErrorService.pushErrorMessage("Accessorial charges added to freight bill.", "successful");
        }
      }
    });
  }

  @bind
  public approveAccessorialChargeValue(id: number) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "approveAccessorialChargeValueResult",
      params: {
        id: id
      },
      onExecute: (apiOptions, params, options) => {
        const factory = QuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.approveAccessorialChargeValue(params);
      },
      onOk: () => {
        ErrorService.pushErrorMessage("Accessorial charges added to freight bill.", "successful");
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to add Accessorial charge to freight bill.");
      }
    });
  }

  @bind
  public deleteAccessorialChargeValue(id: number) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "deleteAccessorialChargeValueResult",
      params: {
        id: id
      },
      onExecute: (apiOptions, params, options) => {
        const factory = QuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.deleteAccessorialChargeValue(params);
      },
      onOk: () => {
        ErrorService.pushErrorMessage("Accessorial charge value deleted.", "successful");
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to delete accessorial charge value.");
      }
    });
  }
}

export const QuoteEntryService = new QuoteEntryFreezerService();
export type IQuoteEntryServiceInjectedProps = ReturnType<QuoteEntryFreezerService["getPropsForInjection"]>;

export {
  FreightTotalData,
  QuoteMarket,
  ResponseType,
  NegotiatedQuoteDataEntry
};
