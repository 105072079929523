import {
  React,
  bind,
  _,
  Helpers
} from "$Imports/Imports";

import {
  IconButton,
  Popper
} from "$Imports/MaterialUIComponents";

import {
  Info
} from "$Imports/MaterialUIIcons";

import {
  DatRate
} from "$Generated/api";

const styles: {
  message: string;
  tooltipContainer: string;
} = require("./DatRateMessage.scss");

interface IDatRateMessageProps {
  datRate: DatRate | null | undefined;
  showAsTooltip?: boolean;
}

interface IDatRateMessageState {
  open: boolean;
  anchorEl: HTMLElement | null;
}

export class DatRateMessage extends React.Component<IDatRateMessageProps, IDatRateMessageState> {
  state = {
    open: false,
    anchorEl: null
  }

  @bind
  private _mouseEnter(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({
      open: true,
      anchorEl: e.currentTarget
    });
  }

  @bind
  private _mouseLeave(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({
      open: false,
      anchorEl: null
    });
  }

  render() {
    const {
      datRate,
      showAsTooltip
    } = this.props;
    const {
      open,
      anchorEl
    } = this.state;

    var message = Helpers.getDatRateMessage(datRate);

    return (
      datRate && message !== "" ? (
        showAsTooltip ?
        <>
          <Popper
            open={open}
            anchorEl={anchorEl}
          >
            <div className={styles.tooltipContainer}>
              <span className={styles.message}>{message}</span>
            </div>
          </Popper>
          <IconButton size={"small"} onMouseEnter={this._mouseEnter} onMouseLeave={this._mouseLeave} className={styles.message}>
            <Info />
          </IconButton>
        </>
        :
        <span className={styles.message}>* {message}</span>
      )
      :
      null
    );
  }
}