import {
  FreezerService,
  bind,
  NullableOptional,
  managedAjaxUtil,
  IAjaxState,
  moment
} from "$Imports/Imports";

import {
  yup,
  SchemaOf
} from "$Shared/imports/Yup";

import {
  DatAuditReport,
  DatAuditApiFactory,
  DatAuditSearchCriteria
} from "$Generated/api";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

export const DatAuditSearchValidationSchema: SchemaOf<NullableOptional<DatAuditSearchCriteria>> = yup.object({
  startDate: yup.date()
    .typeError("Invalid date")
    .required("From is required"),
  endDate: yup.date()
    .typeError("Invalid date")
    .required("To is required")
    .test("endDate", "${message}", (value: Date | undefined, testContext: any) => {
      const startDate = moment(testContext.parent.startDate, 'day');
      const endDate = moment(value);

      if (endDate.isBefore(startDate)) {
        return testContext.createError({ message: "To must be after From" });
      }

      if (endDate.diff(startDate, 'years') > 0) {
        return testContext.createError({ message: "Date range cannot exceed one year" });
      }

      return true;
    }),
  startIndex: yup.number().notRequired(),
  pageSize: yup.number().notRequired(),
  sortColumn: yup.string().notRequired(),
  sortAscending: yup.boolean().notRequired()
});

interface IDatAuditServiceState {
  datAuditReportFetchResults: IAjaxState<DatAuditReport[]>;
}

const initialState = {
  datAuditReportFetchResults: managedAjaxUtil.createInitialState()
} as IDatAuditServiceState;

const InjectedPropName = "datAuditService";

class DatAuditFreezerService extends FreezerService<IDatAuditServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public async fetchDatAuditReport(criteria: DatAuditSearchCriteria) {
    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "datAuditReportFetchResults",
      params: {
        body: criteria
      },
      onExecute: (apiOptions, params, options) => {
        const factory = DatAuditApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getDatAuditReport(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch DAT audit report.");
      }
    });
  }
}

export const DatAuditService = new DatAuditFreezerService();
export type IDatAuditServiceInjectedProps = ReturnType<DatAuditFreezerService["getPropsForInjection"]>;
