import * as _ from "lodash";
import { FetchAPI } from "./../generated/api";
import {
  Freezer,
  ILogger,
  DefaultLogger,
  ConsoleLogHandler
} from "$Shared/imports/Yahara";

import {
  IAjaxState,
  IError,
  IErrorMessage,
  IFetcher,
  createInitialState
} from "$Shared/utilities/managedAjaxUtil";

let logger: ILogger = new DefaultLogger("console-logger", new ConsoleLogHandler());

interface IApiOptions {
  baseUrl: string;
  wrappedFetch: IFetcher | FetchAPI;
}

export const apiOptions: IApiOptions = {
  baseUrl: "",
  wrappedFetch: fetch,
};

export function setBaseUrl(baseUrl: string) {
  apiOptions.baseUrl = baseUrl === "/" ? "" : baseUrl;
}

export function setFetch(fetch: IFetcher | FetchAPI) {
  apiOptions.wrappedFetch = fetch;
}

export interface IFetchOptions<D, P extends object, F = unknown, E = IError> {
  freezer: Freezer.Types.IFreezer<F>;
  ajaxStateProperty?: keyof Freezer.Types.IFrozen<F>;
  getAjaxState?: (options: IFetchOptions<D, P, F, E>) => IAjaxState<D, E>;
  setAjaxState?: (options: IFetchOptions<D, P, F, E>, newStatus: IAjaxState<D, E>) => void;
  clearDataOnFetch?: boolean;
  onExecute: (apiOptions: IApiOptions, params: P, options?: any) => Promise<D>;
  onOk?: (data: D) => D | null | void;
  onFetching?: () => void;
  onError?: (err: IErrorMessage<E>, errorString: string) => void | string;
  errorString?: string;
  params?: P;
  force?: boolean;  // escape hatch - use in _very select_ circumstances
}

function getAjaxState<D, P extends object, F = unknown, E = any>(options: IFetchOptions<D, P, F, E>): IAjaxState<D, E> {
  if (options.ajaxStateProperty) {
    return (options.freezer.get()[options.ajaxStateProperty] as unknown) as IAjaxState<D, E>;
  }

  if (options.getAjaxState) {
    return options.getAjaxState(options);
  }

  throw new Error("ajaxStateProperty or getAjaxState is required.");
}

function setAjaxState<D, P extends object, F, E = any>(options: IFetchOptions<D, P, F, E>, newState: IAjaxState<D, E>): void {
  if (
    (options.ajaxStateProperty === undefined || options.ajaxStateProperty === null) &&
    (options.setAjaxState === undefined || options.setAjaxState === null)
  ) {
    throw new Error("ajaxStateProperty or setAjaxState is required.");
  }

  if (options.ajaxStateProperty) {
    ((options.freezer.get()[options.ajaxStateProperty] as unknown) as Freezer.Types.IFrozenObject<IAjaxState<D, E>>).set(newState);
  }

  if (options.setAjaxState) {
    options.setAjaxState(options, newState);
  }
}

export async function fetchResults<P extends object, D, F = any, E = any>(options: IFetchOptions<D, P, F, E>): Promise<void | D> {

  if (options.freezer === null) {
    throw new Error("Freezer is null");
  }

  const initialState = getAjaxState(options);

  // prevent multiple simultaneous requests for the same fetch call, unless explicitly forced
  if (initialState.isFetching && (options.force !== true)) {
    return;
  }

  const clearData: boolean = options.clearDataOnFetch === null || options.clearDataOnFetch === undefined ? false : options.clearDataOnFetch;
  const requestId = (initialState.requestId || 1) + 1;

  setAjaxState(options, {
    isFetching: true,
    error: null,
    hasFetched: false,
    requestId,
    data: clearData ? null : initialState.data,
    state: "fetching",
  });

  if (options.onFetching) {
    options.onFetching();
  }

  let params: P = {} as P;

  if (options.params !== undefined) {
    params = options.params;
  }

  await options.onExecute(apiOptions, params, options)
    .then((data: D) => dataFunc(data, options, requestId))
    .catch(async (err: Response) => await errorFunc(err, options, requestId));
}

function dataFunc<D, P extends object, F = any, E = any>(data: D, options: IFetchOptions<D, P, F, E>, requestId: number) {

  if (options.freezer === null) {
    throw new Error("Freezer is null");
  }

  const currentState = getAjaxState(options);

  if (requestId === currentState.requestId) {

    if (options.onOk) {
      // Massage data
      data = options.onOk(data) || data;
    }

    // Set OK state
    setAjaxState(options, {
      isFetching: false,
      error: null,
      hasFetched: true,
      requestId,
      data,
      state: "ok",
    });

  } else {
    logger.warn(`Request #${requestId} stale ok result`);
  }

  return data;
}

async function errorFunc<D, P extends object, F = any, E = any>(err: Response, options: IFetchOptions<D, P, F, E>, requestId: number) {
  if (options.freezer === null) {
    throw new Error("Freezer is null");
  }

  const currentState = getAjaxState(options);
  const clearData: boolean = options.clearDataOnFetch === null || options.clearDataOnFetch === undefined ? false : options.clearDataOnFetch;

  logger.error(`Error has occurred in request #${requestId} for state property ${options.ajaxStateProperty}`);

  if (requestId === currentState.requestId) {

    let errorString = options.errorString || " Server Error";
    let error: E | null = null;

    try {
      error = await err.json() as E;
    }
    catch {
      error = null;
    }


    const errorMessage: IErrorMessage<E> = {
      statusCode: err.status,
      statusText: err.statusText,
      body: error,
    }

    if (options.onError) {
      errorString = options.onError(errorMessage, errorString) || errorString;
    }

    setAjaxState(options, {
      isFetching: false,
      error: errorMessage,
      errorMessage: errorString,
      hasFetched: false,
      requestId,
      data: clearData ? null : currentState.data,
      state: "error",
    });

  } else {
    logger.warn(`Request #${requestId} stale error result`);
  }

  throw err;
}

export {
  IAjaxState,
  IError,
  IErrorMessage,
  createInitialState
};
