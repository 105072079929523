import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  ZoneApiFactory,
  Zone,
  ZoneRegion,
  PCMilerApiFactory
} from "$Generated/api";

import {
  directionType
} from "$Imports/CommonComponents";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

export type formModeType = "add" | "edit" | "none";

export interface ZoneModalState {
  editedZipCodes?: string[];
  originalZipCodes?: string[];
  primaryZipCode?: string;
  secondaryZipCode?: string;
  newZipCode?: string;
}

interface IZoneState {
  formMode: formModeType;
  zoneFetchResults: IAjaxState<Zone[]>;
  zoneAddResults: IAjaxState<Zone>;
  zoneUpdateResults: IAjaxState<Zone>;
  zoneRegionsWithDuplicateZips: IAjaxState<ZoneRegion[]>;
  zoneRegionDeleteResults: IAjaxState<ZoneRegion[]>;
  zoneModalValidationErrors: ValidationError | null;
  editAddZone: Zone | null;
  editAddZoneStates: ZoneRegion[];
  duplicateZipsFound: boolean; 
  sortState: ISortState;
  zoneModalState: ZoneModalState;
}

interface ISortState {
  sortColumnName?: string;
  sortDirection?: directionType;
}

const InjectedPropName = "zoneService";

const ZoneModalValidationSchema: SchemaOf<NullableOptional<ZoneModalState>> = yup.object({
  editedZipCodes: yup.array().notRequired(),
  originalZipCodes: yup.array().notRequired(),
  originalZipCode: yup.string().notRequired(),
  primaryZipCode: yup.string().required("Primary Zip Code value is required").zipCode().test({
    name: 'zipCode',
    exclusive: true,
    message: 'Zipcode is not valid.',
    test: async function (value) {
        if (value) {
            const factory = PCMilerApiFactory(managedAjaxUtil.apiOptions.wrappedFetch, managedAjaxUtil.apiOptions.baseUrl);
            const data = await factory.apiV1PCMilerValidateZipCodesPost({
                body: [value]
            });

            return data[0].isValid ?? false;
        }
        return false;
    }
  }),
  secondaryZipCode: yup.string().required("Secondary Zip Code value is required").zipCode().test({
    name: 'zipCode',
    exclusive: true,
    message: 'Zipcode is not valid.',
    test: async function (value) {
        if (value) {
            const factory = PCMilerApiFactory(managedAjaxUtil.apiOptions.wrappedFetch, managedAjaxUtil.apiOptions.baseUrl);
            const data = await factory.apiV1PCMilerValidateZipCodesPost({
                body: [value]
            });

            return data[0].isValid ?? false;
        }
        return false;
    }
  }),
  newZipCode: yup.string().notRequired()
    .test({
      name: 'newZipCode', 
      message: 'Zip code must be three digits long', 
      test: async function (value) {
        if (value) {
          return value.length === 3
        }

        return true;
      }
    })
    .matches(/^(\s*|\d+)$/, 'Zip code must be a number')
})


const initialState = {
  formMode: "none",
  editAddZone: null,
  zoneFetchResults: managedAjaxUtil.createInitialState(),
  zoneAddResults: managedAjaxUtil.createInitialState(),
  zoneUpdateResults: managedAjaxUtil.createInitialState(),
  zoneRegionsWithDuplicateZips: managedAjaxUtil.createInitialState(),
  zoneRegionDeleteResults: managedAjaxUtil.createInitialState(),
  editAddZoneStates: [],
  zoneModalValidationErrors: null,
  duplicateZipsFound: false,
  sortState: {
    sortColumnName: "zoneName",
    sortDirection: "asc",
  },
  zoneModalState: {
    editedZipCodes: [],
    originalZipCodes: []
  }
} as IZoneState;

class ZoneFreezerService extends FreezerService<IZoneState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public addZone() {
    this.freezer.get().set({
      editAddZone: {},
      formMode: "add"
    });
  }

  public updateZone(task: Partial<Zone>) {
    this.freezer.get().editAddZone?.set(task);
  }

  public updateZoneModal(task: Partial<ZoneModalState>) {
    this.freezer.get().zoneModalState.set(task);
  }

  public deleteZipCode(zipCode: string) {
    var indexOfZip = this.freezer.get().zoneModalState.editedZipCodes?.indexOf(zipCode);

    if (indexOfZip !== undefined && indexOfZip > -1) {
      this.freezer.get().zoneModalState.editedZipCodes?.splice(indexOfZip, 1).toJS()
    }
  }

  public closeConfirmModal() {
    this.freezer.get().set({
      duplicateZipsFound: false
    })
  }

  public async onZipCodeEnter() {
    const currentZoneModalState = this.freezer.get().zoneModalState;
    const {newZipCode, editedZipCodes} = currentZoneModalState;
    const editedZipCodesArray = editedZipCodes?.toJS() ?? [];

    //control for duplicate zips
    if (_.includes(editedZipCodesArray, newZipCode)) {
      this.freezer.get().zoneModalState.set({
        newZipCode: ""
      });

      return;
    }
    
    const errors = await validateSchema(ZoneModalValidationSchema, currentZoneModalState, {
      abortEarly: false
    });

    this.freezer.get().set({ zoneModalValidationErrors: errors });

    if (errors) {
      return;
    }

    if(editedZipCodes && newZipCode) {
      editedZipCodesArray.push(newZipCode);
    }

    this.freezer.get().zoneModalState.set({
      editedZipCodes: editedZipCodesArray,
      newZipCode: ""
    })
  }

  public editZone(id: number | undefined) {
    const foundZone = _.find(this.freezer.get().zoneFetchResults.data, (d) => d.id === id)?.toJS();
    const zoneStates = _.filter(foundZone?.zoneRegions, zR => zR.zipPostalCode === null);

    if (foundZone) {
      const zoneZipPostalCodes = _.map(foundZone.zoneRegions, (r) => r.zipPostalCode ? r.zipPostalCode : "").filter(Boolean);
      this.freezer.get().set({
        editAddZone: foundZone,
        editAddZoneStates: zoneStates,
        formMode: "edit",
        zoneModalState: {
          originalZipCodes: zoneZipPostalCodes,
          editedZipCodes: zoneZipPostalCodes,
          primaryZipCode: foundZone.primaryZipPostalCode,
          secondaryZipCode: foundZone.secondaryZipPostalCode,
        }
      });
    }
  }

  public clearEditAddForm() {
    this.freezer.get().set({
      editAddZone: null,
      formMode: "none",
      zoneModalValidationErrors: null
    });
  }

  public saveZone() {
    if (this.freezer.get().formMode === "add") {
      this.saveAddZone();
    }

    if (this.freezer.get().formMode === "edit") {
      this.saveUpdateZone();
    }
  }

  public setSortState(sortState: Partial<ISortState>) {
    this.freezer.get().sortState.set(sortState);
  }

  public async saveUpdateZone(performDuplicateCheck: boolean = true) {
    const editAddZone = this.freezer.get().editAddZone?.toJS();
    const zoneModalState = this.freezer.get().zoneModalState.toJS();
    const editedZipCodes = zoneModalState.editedZipCodes ?? [];
    const originalZipCodes = zoneModalState.originalZipCodes ?? [];
    const editAddZoneStates = this.freezer.get().editAddZoneStates.toJS() ?? [];

    const errors = await validateSchema(ZoneModalValidationSchema, zoneModalState, {
      abortEarly: false
    });

    this.freezer.get().set({ zoneModalValidationErrors: errors });

    if (errors) {
      return;
    }

    if (zoneModalState && editAddZone && editedZipCodes) {

      const currentPrimary = editAddZone.primaryZipPostalCode;
      const currentSeconday = editAddZone.secondaryZipPostalCode;

      if(currentPrimary === zoneModalState.primaryZipCode 
        && currentSeconday === zoneModalState.secondaryZipCode 
        && _.isEqual(zoneModalState.originalZipCodes, zoneModalState.editedZipCodes)) {
          this.clearEditAddForm();
          return;
      }

      editAddZone.primaryZipPostalCode = zoneModalState.primaryZipCode;
      editAddZone.secondaryZipPostalCode = zoneModalState.secondaryZipCode;

      const preExistingZoneRegions: ZoneRegion[] = _.map(editAddZone.zoneRegions, pZr => {
        return {
          zoneId: editAddZone?.id,
          regionId: pZr.regionId,
          zipPostalCode: pZr.zipPostalCode
        }
      });
      
      if (!_.isEqual(zoneModalState.originalZipCodes, zoneModalState.editedZipCodes)) {
        
        //find newly added zips and translate into a ZoneRegion object
        //back-end service will find them and calculate its RegionId
        const newZipCodes = _.difference(editedZipCodes, originalZipCodes);

        const newZoneRegions = newZipCodes.map(r => {
          return {
            zoneId: editAddZone?.id,
            zipPostalCode: r,
          }
        });
      
        var duplicatesExist: boolean = false; 
        if (performDuplicateCheck) {
          duplicatesExist = await this._gatherDuplicateZips(newZipCodes);
        }

        if (duplicatesExist) {
          this.freezer.get().set({
            duplicateZipsFound: true
          });
          return;
        }

        //filter out the pre-existing zone regions that have been deleted 
        const filteredPreExistingZones: ZoneRegion[] = _.filter(preExistingZoneRegions, (pZr) => _.some(editedZipCodes, eZc => (eZc == pZr.zipPostalCode)));

        //concat all arrays together - including state array so they don't get deleted
        const allZoneRegions: ZoneRegion[] = filteredPreExistingZones.concat(newZoneRegions).concat(editAddZoneStates);
        editAddZone.zoneRegions = allZoneRegions;
      }
      else {
        editAddZone.zoneRegions = preExistingZoneRegions;
      }

      managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        ajaxStateProperty: "zoneUpdateResults",
        onExecute: (apiOptions, param, options) => {
          const factory = ZoneApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return factory.apiV1ZonePut(param);
        },
        params: {
          body: editAddZone
        },
        onOk: (data) => {
          this.clearEditAddForm();
          this.fetchZones(true);
        },
        onError: (err, errorMessage) => {
          ErrorService.pushErrorMessage(err.body?.message ?? "Failed to save zone.");
        }
      });
    }
  }

  public fetchZones(forceUpdate: boolean = false) {
    const { zoneFetchResults } = this.freezer.get();

    if (zoneFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "zoneFetchResults",
      onExecute: (apiOptions, param, options) => {
        const factory = ZoneApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1ZoneGet();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch zones.");
      }
    });
  }

  private saveAddZone() {
    const editAddZone = this.freezer.get().editAddZone?.toJS();

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "zoneAddResults",
      onExecute: (apiOptions, param, options) => {
        const factory = ZoneApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1ZonePost(param);
      },
      params: {
        body: editAddZone,
      },
      onOk: (data) => {
        this.clearEditAddForm();
        this.fetchZones(true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to add zone.");
      }
    });
  }

  private async _gatherDuplicateZips(zoneRegions: string[]): Promise<boolean>
  {
    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "zoneRegionsWithDuplicateZips",
      onExecute: (apiOptions, param, options) => {
        const factory = ZoneApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1ZoneGetZoneRegionsByZipPostalCodePost(param);
      },
      params: {
        body: zoneRegions,
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to find duplicate zip codes.");
      }
    });

    const duplicateZoneRegions = this.freezer.get().zoneRegionsWithDuplicateZips.data?.toJS()

    return !!duplicateZoneRegions?.length;
  }
}

export const ZoneService = new ZoneFreezerService();
export type IZoneServiceInjectedProps = ReturnType<ZoneFreezerService["getPropsForInjection"]>;
