import {
    React,
    bind,
    _
  } from "$Imports/Imports";
  
  import {
    SelectedCompanyConsumer
  } from "$Providers/index";
  
  import {
    PerStopFeeConfigView
  } from "./PerStopFeeConfigView";
  
  interface IPerStopFeeConfigViewPageBaseProps {
  
  }
  
  export class PerStopFeeConfigViewPage extends React.Component<IPerStopFeeConfigViewPageBaseProps> {
    render() {
      return (
        <SelectedCompanyConsumer>
          {(companyContext) => { 
            return (
              <PerStopFeeConfigView companyId={companyContext.companyId}/>
            );
          }}
        </SelectedCompanyConsumer>
      );
    }
  }