import {
  FreezerService,
  _,
  bind,
  moment,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional
} from "$Imports/Imports";

import {
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import yup from "$Shared/utilities/yupExtension";

import {
  SalesRepHomeApiFactory,
  SimplifiedQuoteSearchResult,
  QuoteSearchCriteria,
  Customer,
  UserMetricsKPIView,
  UserMetricsSalesLeaderboardView,
  UserMetricsBookedQuotesView,
  SalesRepCustomerSearchCriteria,
  Opportunity,
  QuoteQuoteTypeEnum,
  SimplifiedCustomer
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

export interface IBookedSalesMetricsFilter {
  startDate?: Date;
  endDate?: Date;
}

export const BookedSalesMetricsFilterValidationSchema: SchemaOf<IBookedSalesMetricsFilter> = yup.object({
  startDate: yup.date()
    .typeError("Invalid Date")
    .required("From Date is required to perform a search"),
  endDate: yup.date()
    .typeError("Invalid Date")
    .notRequired()
    .when('startDate', (fromDate: Date, schema: any) => {
      return fromDate ? schema.min(fromDate, "The To Date must be after the From Date")
        : schema.notRequired();
    })
});

export type KPIDatePreset = 'Today' | 'Week' | 'Month' | 'Year' | 'Custom';

interface IKPIFilters extends IBookedSalesMetricsFilter {
  preset: KPIDatePreset;
}

interface ISalesLeaderboardFilters {
  selectedTerminals: string[];
}

interface ISalesRepHomeServiceState {
  quoteFetchResults: IAjaxState<SimplifiedQuoteSearchResult>;
  quickQuoteFetchResults: IAjaxState<SimplifiedQuoteSearchResult>;
  quoteSearchCriteria: QuoteSearchCriteria;
  customerFetchResults: IAjaxState<Customer[]>;
  simplifiedCustomersFetchResults: IAjaxState<SimplifiedCustomer[]>;
  customerSearchCriteria: SalesRepCustomerSearchCriteria;
  opportunityFetchResults: IAjaxState<Opportunity[]>;
  kpiFetchResults: IAjaxState<UserMetricsKPIView>;
  kpiFilters: IKPIFilters;
  salesLeaderboardFetchResults: IAjaxState<UserMetricsSalesLeaderboardView[]>;
  salesLeaderboardFilters: ISalesLeaderboardFilters;
  bookedQuotesByWeekFetchResults: IAjaxState<UserMetricsBookedQuotesView[]>;
}

export const DEFAULT_QUOTE_SEARCH: QuoteSearchCriteria = {
  customerName: "",
  quoteOrFreightNumber: "",
  dateType: "QuoteDate",
  quoteDate: undefined,
  statuses: [],
  sortColumn: "quoteDate",
  sortAscending: false,
  startIndex: 0,
  pageSize: 1000
};

export const DEFAULT_CUSTOMER_SEARCH: SalesRepCustomerSearchCriteria = {
  customerName: "",
  regionAbbreviation: "",
  zipPostalCode: ""
};

const InjectedPropName = "salesRepHomeService";

const initialState = {
  quoteFetchResults: managedAjaxUtil.createInitialState(),
  quickQuoteFetchResults: managedAjaxUtil.createInitialState(),
  quoteSearchCriteria: { ...DEFAULT_QUOTE_SEARCH },
  customerFetchResults: managedAjaxUtil.createInitialState(),
  simplifiedCustomersFetchResults: managedAjaxUtil.createInitialState(),
  customerSearchCriteria: { ...DEFAULT_CUSTOMER_SEARCH },
  opportunityFetchResults: managedAjaxUtil.createInitialState(),
  kpiFetchResults: managedAjaxUtil.createInitialState(),
  kpiFilters: {
    startDate: moment().startOf("day").toDate(),
    endDate: moment().endOf("day").toDate(),
    preset: "Today",
    justMe: true
  },
  salesLeaderboardFetchResults: managedAjaxUtil.createInitialState(),
  salesLeaderboardFilters: {
    selectedTerminals: ["0", "1", "2", "4"]
  },
  bookedQuotesByWeekFetchResults: managedAjaxUtil.createInitialState()
} as ISalesRepHomeServiceState;

export const CustomerSearchValidationSchema: SchemaOf<NullableOptional<SalesRepCustomerSearchCriteria>> = yup.object({
  employeeId: yup.number().notRequired(),
  customerName: yup.string()
    .notRequired()
    .allowEmpty()
    .min(3, "At least 3 characters are required."),
  latestQuoteStartDate: yup.date()
    .typeError("Invalid date")
    .notRequired(),
  latestQuoteEndDate: yup.date()
    .typeError("Invalid date")
    .when("latestQuoteStartDate", {
      is: (value?: Date) => !!value,
      then: yup.date()
        .test("latestQuoteEndDate", "${message}", (value: Date | undefined, testContext: any) => {
          const startDate = testContext.parent.latestQuoteStartDate;

          if (moment(value).isBefore(moment(startDate, 'day'))) {
            return testContext.createError({ message: "To must be after From" });
          }

          return true;
        }),
      otherwise: yup.date().notRequired()
    }),
  regionAbbreviation: yup.string()
    .notRequired()
    .allowEmpty(),
  zipPostalCode: yup.string()
    .notRequired()
    .allowEmpty()
});



class SalesRepHomeFreezerService extends FreezerService<ISalesRepHomeServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  @bind
  public update(newState: Partial<Omit<ISalesRepHomeServiceState, "quoteFetchResults" | "customerFetchResults" | "kpiFetchResults">>): void {
    this.freezer.get().set(newState);
  }

  @bind
  public fetchQuotes(companyId: number | undefined, force: boolean = false, quoteType: QuoteQuoteTypeEnum | undefined) {
    const {
      quoteFetchResults,
      quickQuoteFetchResults,
      quoteSearchCriteria
    } = this.freezer.get();

    if (!companyId || ((quoteFetchResults.hasFetched || quickQuoteFetchResults.hasFetched) && !force)) {
      return;
    }

    // todo, future story: i am not going to duplicate quote search criteria validation again

    const ajaxStateProperty = quoteType === "Quick" ? "quickQuoteFetchResults" : "quoteFetchResults";

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: ajaxStateProperty,
      params: {
        body: {
          ...quoteSearchCriteria.set({quoteType: quoteType}).toJS(),
          companyId
        }
      },
      onExecute: (apiOptions, params, options) => {
        const factory = SalesRepHomeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getMyQuotes(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch quotes.");
      }
    });
  }

  @bind
  public fetchCustomers(forceUpdate: boolean = false) {
    const {
      customerFetchResults,
      customerSearchCriteria
    } = this.freezer.get();

    if (customerFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "customerFetchResults",
      params: {
        body: customerSearchCriteria.toJS()
      },
      onExecute: (apiOptions, params, options) => {
        const factory = SalesRepHomeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getMyCustomers(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch customers.");
      }
    });
  }

  @bind
  public fetchSimplifiedCustomers(companyId: number | undefined, forceUpdate: boolean = false) {
    const {
      simplifiedCustomersFetchResults
    } = this.freezer.get();

    if (companyId === undefined || (simplifiedCustomersFetchResults.hasFetched && !forceUpdate)) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "simplifiedCustomersFetchResults",
      params: {
        companyId: companyId
      },
      onExecute: (apiOptions, params, options) => {
        const factory = SalesRepHomeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getSimplifiedCustomers(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch customers for search form.");
      }
    });
  }

  @bind
  public fetchOpportunities(forceUpdate: boolean = false) {
    const {
      opportunityFetchResults
    } = this.freezer.get();

    if (opportunityFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "opportunityFetchResults",
      params: {},
      onExecute: (apiOptions, params, options) => {
        const factory = SalesRepHomeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getMyOpportunities(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch sales pipeline opportunities");
      }
    })
  }

  @bind
  public fetchKpis(companyId: number | undefined, selectedMetricType: string) {
    const {
      kpiFilters
    } = this.freezer.get();

    const {
      startDate,
      endDate
    } = kpiFilters.toJS();

    if (!startDate || !endDate || !companyId) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "kpiFetchResults",
      params: {
        body: { companyId: companyId, startDate: startDate, endDate: endDate, metricType: selectedMetricType}
      },
      onExecute: (apiOptions, params, options) => {
        const factory = SalesRepHomeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getMyKPIMetrics(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch KPIs.");
      }
    });
  }

  @bind
  public fetchSalesLeaderboard() {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "salesLeaderboardFetchResults",
      onExecute: (apiOptions, params, options) => {
        const factory = SalesRepHomeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getSalesLeaderboard();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch sales leaderboard.");
      }
    });
  }

  @bind
  public fetchBookedQuotesByWeek() {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "bookedQuotesByWeekFetchResults",
      onExecute: (apiOptions, params, options) => {
        const factory = SalesRepHomeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getMyBookedQuotes();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch booked quotes by week.");
      }
    });
  }
}

export const SalesRepHomeService = new SalesRepHomeFreezerService();
export type ISalesRepHomeServiceInjectedProps = ReturnType<SalesRepHomeFreezerService["getPropsForInjection"]>;
