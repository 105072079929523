
import {
  React,
  bind
} from "$Imports/Imports";

import { 
  ApplicationSetting 
} from "$Generated/api";

import {
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  Edit
} from "$Imports/MaterialUIIcons";

interface IActionMenuBaseProps {
  setting: ApplicationSetting,
  onSettingEditClick: (applicationSetting: ApplicationSetting) => void;
}

type IActionMenuProps = IActionMenuBaseProps;

export class ActionMenu extends React.PureComponent<IActionMenuProps> {

  @bind
  private _onSettingEditClick() {
    if (this.props.onSettingEditClick) {
      this.props.onSettingEditClick(this.props.setting);
    }
  }

  render() {
    return (
      <UserAccessControl roles={["automatic-upcharge:edit"]}>
      <IconButton
        size="small"
        onClick={this._onSettingEditClick}
      >
        <Edit />
      </IconButton>
    </UserAccessControl>
    );
  }

}