import {
  React,
  bind,
  NumberFormat,
  NumberFormatValues
} from "$Imports/Imports";

import {
  ApplicationSetting
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField
} from "$Imports/MaterialUIComponents";

interface IAutomaticUpchargeEditModalValueProps {
  isModalOpen: boolean;
  title: string;
  value?: string;
  onSaveSetting: () => void;
  onChangeSetting: (applicationSetting: Partial<ApplicationSetting>) => void;
}

export class AutomaticUpchargeEditModal extends React.PureComponent<IAutomaticUpchargeEditModalValueProps> {

  @bind
  private _onSave() {
    this.props.onSaveSetting();
  }
  
  @bind
  private _onValueChange(value: NumberFormatValues | undefined) {
    this.props.onChangeSetting({ settingsValue: value?.value });
  }

  render() {
    const {
      isModalOpen,
      title,
      value
    } = this.props;

    return (
      <Dialog
        open={isModalOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent
          style={{ display: "flex", flexDirection: "column" }}
        >
          <NumberFormat
            isAllowed={({floatValue}) => (floatValue ?? 0) <= 2000000000}
            label="Amount"
            name="amount"
            value={value ?? ""}
            error={!value}
            helperText={!value ? "Amount must be populated" : ""}
            prefix={"$"}
            thousandSeparator={","}
            decimalScale={2}
            fixedDecimalScale
            onValueChange={this._onValueChange}
            customInput={TextField}
            allowNegative={false}
            autoFocus
            onFocus={(e: React.FocusEvent<HTMLTextAreaElement>) => e.target.select()}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this._onSave}
            disabled={!value}
          >
            Done
          </Button>
          </DialogActions>
      </Dialog>
    );
  }
}
