import {
  React,
  moment,
  bind,
  _
} from "$Imports/Imports";

import {
  ThemeConsumer
} from "$Providers/index";

import {
  ThemeProvider,
  createTheme
} from "$Imports/MaterialUIStyles";

import {
  Tabs,
  Box,
  Button,
  Tab
} from "$Imports/MaterialUIComponents";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps,
  OtherFreightInfo
} from "$State/QuoteEntryFreezerService";

import { 
  Quote,
  ZipCodePair
} from "$Generated/api";

import { 
  ValidationErrorParser
 } from "$Utilities/ValidationErrorParser";

import {
  Close
} from "$Imports/MaterialUIIcons";

import { 
  ReportIcon 
} from "$Imports/CommonComponents";

interface ITabSectionBaseProps {
  quote: Quote;
  currentQuoteStopIdx: number;
  viewOnly: boolean;
  editMode: boolean;
  onTabChange: (index: number) => void;
  onAddTabClick: () => void;
  onCloseTabClick: (index: number) => void;
}

type ITabSectionProps = ITabSectionBaseProps & IQuoteEntryServiceInjectedProps;

const styles: {
  paper: string,
  mainContainer: string,
  addStopButton: string
} = require("./QuoteStopEntryComponents/QuoteStopEntry.scss");

class _TabSection extends React.Component<ITabSectionProps> {

  @bind
  private _onTabChange(index: number) {
      this.props.onTabChange?.(index);
  }

  @bind
  private _onAddTabClick() {
      this.props.onAddTabClick?.();
  }

  @bind
  private _onCloseTabClick(index: number) {
      this.props.onCloseTabClick?.(index);
  }

  render() {
    const {
      quoteValidationErrors,
      shipperConsigneeValidationErrors,
      otherFreightInfoValidationErrors
    } = this.props.QuoteEntryService.getState();

    const {
      quote,
      currentQuoteStopIdx,
      viewOnly,
      editMode
    } = this.props;

    const validationsParserQuote = new ValidationErrorParser<Quote>(quoteValidationErrors);
    const validationsShipperConsignee = new ValidationErrorParser<ZipCodePair>(shipperConsigneeValidationErrors);
    const validationsOtherFreightInfo = new ValidationErrorParser<OtherFreightInfo>(otherFreightInfoValidationErrors);
    
    const quoteHasFreightErrors: boolean = !validationsParserQuote.isValid('quoteFreights');

    return (
      <Box style={{ display: "grid" }}>
        <ThemeConsumer>{(themeContext) => {
          const tabTheme = createTheme(themeContext.themeConfig.themeOptions, themeContext.themeConfig.coloredTabControl);

          return (
            <ThemeProvider theme={tabTheme}>
              <Tabs
                value={currentQuoteStopIdx}
                variant="scrollable"
                scrollButtons="auto"
                onChange={(e: React.ChangeEvent<{}>, newIdx: number) => this._onTabChange(newIdx)}
              >
                {_.map(quote.quoteStops, (qs, idx) => {
                  const scheduleDatePast = (qs.shipperStartDate && moment(qs.shipperStartDate).isBefore(moment().startOf('day'))) ||
                    (qs.shipperEndDate && moment(qs.shipperEndDate).isBefore(moment().startOf('day'))) ||
                    (qs.consigneeStartDate && moment(qs.consigneeStartDate).isBefore(moment().startOf('day'))) ||
                    (qs.consigneeEndDate && moment(qs.consigneeEndDate).isBefore(moment().startOf('day')));

                  const shipperAddress = qs.addresses?.find(a => a.isCurrent && a.addressType === "Shipper");
                  const consigneeAddress = qs.addresses?.find(a => a.isCurrent && a.addressType === "Consignee");
                  const shipperContactError = shipperAddress?.customerId && !qs.shipperContactId && (!!quote?.status || quote.status === "InProgress" || quote.status === "Pending");
                  const consigneeContactError = consigneeAddress?.customerId && !qs.consigneeContactId && (!!quote?.status || quote.status === "InProgress" || quote.status === "Pending");
                  const otherFreightInfoError = quote.status && (!qs.siteId || !qs.opsCode || !qs.ponumber);

                  const tabInvalid = (!validationsParserQuote.isIndexValid(idx) || !validationsShipperConsignee.isIndexValid(idx) || !validationsOtherFreightInfo.isIndexValid(idx))
                    || quoteHasFreightErrors || scheduleDatePast || shipperContactError || consigneeContactError || otherFreightInfoError;

                  const labelText = `Stop ${idx + 1}`;

                  const labelElement = <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ marginRight: "8px" }}>{labelText}</span>
                    <div
                      style={{ height: "20px" }}
                      onClick={(e: React.SyntheticEvent) => {
                        e.stopPropagation(); // don't switch to the tab
                        this._onCloseTabClick(idx);
                      }}
                    >
                      <Close fontSize="small" />
                    </div>
                  </div>;

                  // don't show the close button for the first stop or at all on viewOnly quotes
                  const label = (idx === 0) || viewOnly || editMode
                    ? <span>{labelText}</span>
                    : labelElement;

                  return (
                    <Tab
                      key={idx}
                      label={label}
                      icon={(tabInvalid && !viewOnly) ? <ReportIcon /> : undefined}
                      iconPosition="start"
                    />
                  )
                })}
                {!viewOnly &&
                  <Button
                    onClick={this._onAddTabClick}
                    disabled={viewOnly || editMode}
                    variant="outlined"
                    className={styles.addStopButton}
                  >
                    Add Stop
                  </Button>
                }
              </Tabs>
            </ThemeProvider>
          );
        }}</ThemeConsumer>
      </Box>
    );
  }
}

export const TabSection = QuoteEntryService.inject(_TabSection);