
import {
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "$Imports/MaterialUIComponents";

import {
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  DeclinedReasonSchema
} from "$State/QuoteEntryValidationSchema";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

interface IReasonModelProps {
  isOpen: boolean;
  onSave: (reason: string) => void;
  onCancel: () => void;
}

interface IOwnState {
  reason?: string;
  reasonErrors: ValidationError | null;
}

export class ReasonModal extends React.Component<IReasonModelProps, IOwnState> {
  state: IOwnState = {
    reasonErrors: null
  };

  @bind
  private _onCancel() {
    this.setState({
      reason: undefined,
      reasonErrors: null
    });

    this.props.onCancel();
  }

  @bind
  private async _onSave() {
    const errors = await validateSchema(DeclinedReasonSchema, this.state.reason, { abortEarly: false });
    this.setState({ reasonErrors: errors });

    if (!this.state.reason || errors) {
      return;
    }

    this.props.onSave(this.state.reason);
  }

  @bind
  private _onReasonChange(value: string) {
    this.setState({ reason: value });
  }

  render() {
    const {
      isOpen
    } = this.props;

    const {
      reason,
      reasonErrors
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
      >
        <DialogTitle>
          Reason for Quote Denial
        </DialogTitle>
        <DialogContent>
          <AdvanceTextField
            label="Reason"
            onDebouncedChange={this._onReasonChange}
            value={reason ?? ""}
            fullWidth
            error={!!reasonErrors?.errors.length}
            helperText={reasonErrors?.errors}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._onCancel}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this._onSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )

  }
}