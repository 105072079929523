import {
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf
} from "$Shared/imports/Yup";

import {
  CustomerSource
} from "$Generated/api";

const CustomerSourceValidationSchema: SchemaOf<NullableOptional<CustomerSource>> = yup.object({
  id: yup.number().notRequired(),
  name: yup.string()
    .required("Name cannot be blank")
    .max(30, "Name cannot be longer than 30 characters"),
  isActive: yup.boolean().required(),
});

export {
  CustomerSourceValidationSchema
}