
import {
  React,
  withAuth,
  AuthContextProps,
  bind
} from "$Imports/Imports";

import {
  Button
} from "$Imports/MaterialUIComponents";

import {
  NavigationService,
  INavigationServiceInjectedProps
} from "$State/NavigationFreezerService";

import {
  _
} from "$Imports/Imports";

import { 
  SharedSecurityContext 
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

const styles: {
  mainContainer: string,
  appTitle: string,
  card: string,
  message: string
} = require("./NoCompanyAccessView.scss");

interface INoCompanyAccessViewBaseProps {

}

type INoCompanyAccessViewProps = INoCompanyAccessViewBaseProps & INavigationServiceInjectedProps & AuthContextProps;

class _NoCompanyAccessView extends React.Component<INoCompanyAccessViewProps> {

  @bind
  private async _onLogout() {    
    await SharedSecurityContext.logout();
  }

  render() {
    return (
      <div className={styles.mainContainer}>
        <div className={styles.appTitle}>SALESPORTAL</div>

        <div className={styles.message}>
          This user does not currently have a company associated with their account. 
          Please associate a company to this account via KeyCloak and login again.
        </div>

        <Button 
          onClick={this._onLogout}
          color="secondary"
        >
            Logout
        </Button>

      </div>
    );
  }
}

export const NoCompanyAccessView = withAuth(
  NavigationService.inject(
    _NoCompanyAccessView,
  )
);
