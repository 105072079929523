import {
  React,
  bind
} from "$Imports/Imports";

import {
  Checkbox,
  FormControlLabel
} from "$Imports/MaterialUIComponents";

import {
  TerminalEnum,
  TERMINAL_MAP
} from "$Utilities/companyUtil";

interface IOwnProps {
  selectedTerminals: TerminalEnum[];
  onChange: (selected: TerminalEnum[]) => void;
}

class _SalesLeaderboardTerminalSelector extends React.PureComponent<IOwnProps> {
  @bind
  private _onChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    const selected = [...this.props.selectedTerminals];
    const terminalId = event.target.value as TerminalEnum;

    if (checked && (selected.indexOf(terminalId) === -1)) {
      selected.push(terminalId);
    }
    else if (!checked) {
      const foundIndex = selected.indexOf(terminalId);
      if (foundIndex > -1) {
        selected.splice(foundIndex, 1);
      }
    }

    this.props.onChange(selected.sort((a, b) => a > b ? 1 : a < b ? -1 : 0));
  }

  render() {
    const { selectedTerminals } = this.props;

    return (
      <>
        {Object.keys(TERMINAL_MAP).map((x, idx) => {
          const enumValue = x as TerminalEnum;

          return (
            <FormControlLabel
              key={idx}
              label={TERMINAL_MAP[enumValue].name}
              control={(
                <>
                  <div style={{ backgroundColor: TERMINAL_MAP[enumValue]?.color ?? "black", height: "1rem", width: "1rem" }}></div>
                  <Checkbox
                    checked={selectedTerminals.indexOf(enumValue) > -1}
                    onChange={this._onChange}
                    value={enumValue}
                  />
                </>
              )}
            />
          );
        })}
      </>
    );
  }
}

export const SalesLeaderboardTerminalSelector = _SalesLeaderboardTerminalSelector;

