import {
  React,
  bind,
  Helpers
} from "$Imports/Imports";

import {
  Edit
} from "$Imports/MaterialUIIcons";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  EquipmentType,
  EquipmentTypeValue
} from "$Generated/api";


interface IActionMenuState {
}

interface IActionMenuProps {
  equipmentRate: EquipmentTypeValue;
  onEdit: (equipmentRate: EquipmentTypeValue) => void;
}

export class ActionMenu extends React.PureComponent<IActionMenuProps, IActionMenuState> {

  @bind
  private _onEdit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    this.props.onEdit(this.props.equipmentRate);
  }

  render() {
    const {
      equipmentRate
    } = this.props;

    return (
      <>
        {Helpers.isNullOrUndefined(equipmentRate.endDateTime) && <IconButton
          size="small"
          onClick={this._onEdit}
        >
          <Edit />
        </IconButton>}
      </>
    );
  }
}
