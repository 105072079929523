import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
} from "$Imports/Imports";

import {
  AccessorialCharge,
  AccessorialChargeApiFactory
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

// prevent controlled/uncontrolled input warnings
export const EMPTY_ACCESSORIAL_CHARGE: AccessorialCharge = {
  id: 0,
  description: "",
  tmChargeCode: ""
};

interface IAccessorialChargeServiceState {
  accessorialChargeFetchResults: IAjaxState<AccessorialCharge[]>;
  activeAccessorialCharges: AccessorialCharge[];
  accessorialChargeSaveResult: IAjaxState<AccessorialCharge>;
}

const InjectedPropName = "accessorialChargeService";

const initialState = {
  accessorialChargeFetchResults: managedAjaxUtil.createInitialState(),
  activeAccessorialCharges: [],
  accessorialChargeSaveResult: managedAjaxUtil.createInitialState()
} as IAccessorialChargeServiceState;

class AccessorialChargeFreezerService extends FreezerService<IAccessorialChargeServiceState, typeof InjectedPropName> {

  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchAccessorialCharges(filterActive: boolean = false, forceUpdate: boolean = false) {
    const {
      accessorialChargeFetchResults: accessorialChargeFetchResults,
    } = this.freezer.get();

    if (accessorialChargeFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "accessorialChargeFetchResults",
      params: {
        activeOnly: filterActive
      },
      onExecute: (apiOptions, params, options) => {
        const factory = AccessorialChargeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getAccessorialCharges(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch accessorial charges.");
      },
      onOk: (data: AccessorialCharge[]) => {
        var activeAccessorialCharges = data.filter(e => e.isActive);
        activeAccessorialCharges = _.sortBy(activeAccessorialCharges, e => e.description?.toLowerCase());
        this.freezer.get().set({ activeAccessorialCharges: activeAccessorialCharges });
      }
    });
  }

  @bind
  public saveAccessorialCharge(accessorialCharge: AccessorialCharge): Promise<AccessorialCharge | void> {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "accessorialChargeSaveResult",
      params: {
        body: accessorialCharge
      },
      onExecute: (apiOptions, params, options) => {
        const factory = AccessorialChargeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);

        if (!!accessorialCharge.id && accessorialCharge.id > 0) {
          return factory.updateAccessorialCharge(params);
        } else {
          return factory.addAccessorialCharge(params);
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to save accessorial charge.");
      }
    });
  }
}

export const AccessorialChargeService = new AccessorialChargeFreezerService();
export type IAccessorialChargeServiceInjectedProps = ReturnType<AccessorialChargeFreezerService["getPropsForInjection"]>;
