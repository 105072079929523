import {
  React
} from "$Imports/Imports";

import {
  SvgIcon,
  SvgIconProps
} from "$Imports/MaterialUIComponents";

interface IReportIconBaseProps {
  
}

type IReportIconProps = IReportIconBaseProps & SvgIconProps;

export class ReportIcon extends React.PureComponent<IReportIconProps> {
  render() {
    // it doesn't put a marginRight on the icon unless you spread props like this. I don't know why.
    return (
      <SvgIcon {...this.props}>
        <path  style={{ color: "#AA001A" }} d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM12 17.3c-.72 0-1.3-.58-1.3-1.3 0-.72.58-1.3 1.3-1.3.72 0 1.3.58 1.3 1.3 0 .72-.58 1.3-1.3 1.3zm1-4.3h-2V7h2v6z"></path>
        <path d="M11 7h2v7h-2z" style={{ color: "white" }}></path>
        <circle cx="12" cy="16" r="1" style={{ color: "white" }}></circle>
      </SvgIcon>
    );
  }
}
