import {
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  EquipmentTypeValue
} from "$Generated/api";

import {
  PercentageInputField
} from "$Imports/CommonComponents";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button
} from "$Imports/MaterialUIComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

const styles: {
  modalContainer: string;
} = require("$Shared/styles/Modal.scss");

interface IEditEquipmentRateProps {
  isOpen: boolean;
  equipmentRate: EquipmentTypeValue;
  validationErrors: ValidationError | null;
  onSave: () => void;
  onCancel: () => void;
  onChange: (value: Partial<EquipmentTypeValue>) => void;
}

export class EditEquipmentRateModal extends React.PureComponent<IEditEquipmentRateProps> {

  @bind
  private _onSave() {
    this.props.onSave();
  }

  @bind
  private _onCancel() {
    this.props.onCancel();
  }

  @bind
  private _onRateChange(percentRate: number | undefined) {
    this.props.onChange({ percentRate });
  }

  @bind 
  private _selectAllOnFocus(event: React.FocusEvent<HTMLInputElement>) {
    event.target.select();
  }

  render() {
    const {
      isOpen,
      equipmentRate,
      validationErrors
    } = this.props;

    const validationsParser = new ValidationErrorParser<EquipmentTypeValue>(validationErrors);  

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
          Edit Equipment Rate
        </DialogTitle>
        <DialogContent
          className={styles.modalContainer}
        >
          <div>Equipment Name: {equipmentRate.equipmentType?.name ?? ""}</div>
          <PercentageInputField
            label="Rate"
            error={!validationsParser.isValid("percentRate")}
            helperText={validationsParser.validationMessage("percentRate")}
            decimalScale={1}
            value={equipmentRate.percentRate}
            onPercentValueChange={this._onRateChange}
            style={{ marginTop: "5px" }}
            onFocus={this._selectAllOnFocus}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._onCancel}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this._onSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}