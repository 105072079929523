import {
  _
} from "$Imports/Imports";

import {
  SimplifiedQuote,
  QuoteStatusEnum,
  Employee,
  QuoteQuoteTypeEnum
} from "$Generated/api";

import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams
} from "$Imports/MaterialUIComponents";

import {
  DisplayFormattedDatetime,
  DisplayFormattedNumber,
  QuoteLink
} from "$Imports/CommonComponents";

import {
  getDisplayedQuoteNumber
} from "$Shared/utilities/helpers";

import {
  CURRENCY_FORMAT,
  DATE_ONLY_FORMAT,
  DATE_WITH_TIME_MERIDIAN_FORMAT,
  NUMERIC_SEPARATED_FORMAT
} from "$Shared/utilities/formatUtil";

export const quoteNumberCol: GridColDef = {
  headerName: "Quote #",
  field: "quoteNumber",
  renderCell: (params: GridRenderCellParams<any, SimplifiedQuote>) => getDisplayedQuoteNumber(params.value, params.row.quoteType, undefined),
  width: 80
};

export const negotiatedRateCol: GridColDef = {
  headerName: "Negotiated Rate",
  field: "negotiatedRate",
  renderCell: (params: GridRenderCellParams<number | undefined>) =>
    params.value && <DisplayFormattedNumber value={params.value} formatString={CURRENCY_FORMAT} />,
  width: 130
};

export const milesCol: GridColDef = {
  headerName: "Miles",
  field: "miles",
  width: 80,
  renderCell: (params: GridRenderCellParams<number | undefined>) =>
    params.value && <DisplayFormattedNumber value={params.value} formatString={"0"} />
};

export const salesRepCol: GridColDef = {
  headerName: "Sales Rep",
  field: "createdBy",
  renderCell: (params: GridRenderCellParams<Employee>) => `${params.value?.firstName} ${params.value?.lastName}`,
  width: 140
};

export const statusCol: GridColDef = {
  headerName: "Status",
  field: "status",
  valueFormatter: (params: GridValueFormatterParams) => {
    switch (params.value as QuoteStatusEnum) {
      case "ApprovalNeeded":
        return "Approval Needed";
      case "InProgress":
        return "In Progress";
      case "PendingNeedsCustomers":
        return "Pending";
      case "AcceptanceRejected":
        return "Acceptance Rejected";
      default:
        return params.value;
    }
  },
  width: 100
};

export const quoteNumberWithLinkCol: GridColDef = {
  headerName: "Quote #",
  field: "quoteNumber",
  flex: 1,
  renderCell: (params: GridRenderCellParams) => {
    return (
      <QuoteLink
        quoteId={params.row.id}
        quoteNumber={getDisplayedQuoteNumber(params.value, params.row.quoteType, undefined)}
        isCustomerQuote={false}
      />
    )
  },
};

export function getQuoteDateColumn(quoteType: QuoteQuoteTypeEnum): GridColDef {
  return {
    headerName: "Quote Date",
    field: "quoteDate",
    renderCell: (params: GridRenderCellParams<Date | undefined>) => {
      return <DisplayFormattedDatetime value={params.value} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />;
    },
    width: quoteType === "Full" ? 120 : 200
  };
}

export function getFtKRatingVariableColumn(quoteType: QuoteQuoteTypeEnum): GridColDef {
  return {
    headerName: quoteType === "Full" ? "Ft / K" : "Rating Variable",
    field: "ftk",
    renderCell: (params: GridRenderCellParams<any, SimplifiedQuote>) => {
      const currentRate = params.row.currentRate;
      let value = currentRate?.overriddenRateVariable ? currentRate.overriddenRateVariable : currentRate?.calculatedRateVariable;
      let unit = " ft"; // unit is ft even when using an overridden rate variable

      if (currentRate?.rateLevelFactor === "Weight") {
        unit = " lbs";
        if (value) {
          value *= 1000;
        }
      }
      else if (currentRate?.rateLevelFactor === "Overdimensional") {
        value = 0;
      }

      return value ? <DisplayFormattedNumber value={value} formatString={NUMERIC_SEPARATED_FORMAT} postfix={unit} /> : "--";
    },
    sortable: false,
    width: quoteType === "Full" ? 60 : 125
  };
}

export function getDeliveryDateColumn(headerName: string, showTime: boolean = false): GridColDef {
  return {
    headerName: headerName,
    field: "deliveryDate",
    flex: 1,
    minWidth: 120,
    renderCell: (params: GridRenderCellParams<Date, SimplifiedQuote>) => {
      const stopHasAppointment = params.row.quoteStops ? params.row.quoteStops[params.row.quoteStops.length - 1].isConsigneeAppointmentRequired : false;
      const hideTime = !showTime || !stopHasAppointment;
      return params.value
        ? hideTime
          ? <DisplayFormattedDatetime value={params.value} formatString={DATE_ONLY_FORMAT} manualOffset={true} />
          : <DisplayFormattedDatetime value={params.value} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} manualOffset={true} showTimeZone />
        : "";
    }
  };
}