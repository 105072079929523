
import {
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  ResponseType
} from "$State/QuoteEntryFreezerService";

import {
  Quote
} from "$Generated/api";

import {
  QuestionAnswerRadioButtons
} from "./QuestionAnswerRadioButtons";

interface IOtherUpchargeReasonTextFieldBaseProps {
  viewOnly?: boolean;
  quote?: Quote;
  className?: string | undefined;
  validationErrors: ValidationError | null;
  setAnswer: (answer: ResponseType) => void;
  setAnswerText: (answerText: string) => void;
}

export class OtherUpchargeReasonTextField extends React.PureComponent<IOtherUpchargeReasonTextFieldBaseProps> {

  @bind
  setAnswer(answer: string) {
    this.props.setAnswer(answer as ResponseType);
  }

  @bind
  setAnswerText(answerText: string) {
    this.props.setAnswerText(answerText);
  }

  render() {
    
    const {
      viewOnly,
      quote,
      className,
      validationErrors
    } = this.props;

    const selection = quote?.otherFlatUpchargeReason ? "Yes" : "No";

    return (
      <div>
          <QuestionAnswerRadioButtons
            viewOnly={viewOnly}
            radioGroupName="flat upcharge option"
            questionText="Other Reason for Upcharge?"
            answer={selection}
            className={className}
            setAnswer={(questionId, answer) => this.setAnswer(answer)}
          />
        <AdvanceTextField
          label="Other Reason for Upcharge"
          disabled={(selection === "No" || viewOnly) ? true : false}
          onDebouncedChange={this.setAnswerText}
          value={quote?.flatUpchargeReason ?? ""}
          error={!!(validationErrors?.errors.length)}
          helperText={validationErrors?.errors}
          fullWidth
        /> 
      </div>
    )
  }
}