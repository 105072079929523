import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  AddEditZone
} from "./AddEditZone";

import {
  DataTable,
  IDataTableColumn,
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  Card,
  CardHeader
} from "$Imports/MaterialUIComponents";

import {
  IZoneServiceInjectedProps,
  ZoneService
} from "$State/ZoneFreezerService";

import {
  IStateServiceInjectedProps,
  StateService
} from "$State/RegionFreezerService";


import {
  Zone
} from "$Generated/api";

import {
  ActionMenu
} from "./ActionMenu";

import { getFormattedZipPostalCode } from "$Shared/utilities/helpers";


const styles: {
  tableCellTextContainer: string;
  tableFooter: string;
} = require("./ZoneViewPage.scss");

const containerStyles: {
  mainContainer: string;
  cardStyle: string;  
} = require("$Shared/styles/Modal.scss");

interface IZoneViewPageBaseProp {
}

type IZoneViewPageProp = IZoneServiceInjectedProps & IZoneViewPageBaseProp & IStateServiceInjectedProps;

class _ZoneViewPage extends React.Component<IZoneViewPageProp> {

  componentDidMount() {
    this.props.zoneService.fetchZones();
    this.props.regionService.fetchStates();
  }

  @bind
  private _onCancel() {
    this.props.zoneService.clearEditAddForm();
  }

  @bind
  private _onZipCodeChange(zipCode: string) {
    this.props.zoneService.updateZoneModal({ newZipCode: zipCode });
  }

  @bind
  private _onZipCodeEnter() {
    this.props.zoneService.onZipCodeEnter();
  }

  @bind
  private _onZoneChanged(zone: Partial<Zone>) {
    this.props.zoneService.updateZone(zone);
  }

  @bind
  private _onZipCodeDelete(zipCode: string) {
    this.props.zoneService.deleteZipCode(zipCode);
  }

  @bind
  private _onPrimaryZipCodeChange(zipCode: string) {
    this.props.zoneService.updateZoneModal({ primaryZipCode: zipCode });
  }

  @bind
  private _onSecondaryZipCodeChange(zipCode: string) {
    this.props.zoneService.updateZoneModal({ secondaryZipCode: zipCode });
  }

  @bind
  private _onDeleteConfirm() {
    this.props.zoneService.closeConfirmModal();
    this.props.zoneService.saveUpdateZone(false);
  }

  @bind
  private _onDeleteCancel() {
    this.props.zoneService.closeConfirmModal();
  }

  @bind
  private _onSave() {
    this.props.zoneService.saveZone();
  }

  @bind
  private _onMenuItemSelected(zone: Zone) {
    this.props.zoneService.editZone(zone?.id);
  }

  private readonly columns: Array<IDataTableColumn<Zone>> = [
    {
      columnName: "zoneName",
      columnFieldData: "zoneName",
      headerValue: "Zone",
      sortMethod: (d) => d.id ?? "",
    },
    {
      columnName: "states",
      columnFieldData: (d) => d.zoneRegions && <div className={styles.tableCellTextContainer}>{(d.zoneRegions.filter(zR => zR.zipPostalCode === null)
        .map(function (s) {
        if (s.region && s.region.regionName) {
          return s.region.regionName;
        }
      }).filter((value, index, self) => self.indexOf(value) === index).join(", "))}
      </div>,
      columnFieldTitle: (d) => d.zoneRegions ? d.zoneRegions.filter(zR => zR.zipPostalCode === null)
      .map(function (s) {
        if (s.region && s.region.regionName) {
          return s.region.regionName;
        }
      }).filter((value, index, self) => self.indexOf(value) === index).join(", ") : "",
      headerValue: "States",
      sortMethod: (d) => d.zoneRegions && (d.zoneRegions.filter(zR => zR.zipPostalCode === null)
      .map(function (s) {
        if (s.region && s.region.regionName) {
          return s.region.regionName;
        }
      }).filter((value, index, self) => self.indexOf(value) === index).join(", "))
    },
    {
      columnName: "zip",
      columnFieldData: (d) => d.zoneRegions && <div className={styles.tableCellTextContainer}>{(d.zoneRegions.map(function (s) {
        if (s.zipPostalCode) {
          return s.zipPostalCode;
        }
      }).filter(Boolean).sort().join(", "))}</div>,
      columnFieldTitle: (d) => d.zoneRegions ? d.zoneRegions.map(function (s) {
        if (s.zipPostalCode) {
          return s.zipPostalCode;
        }
      }).filter(Boolean).sort().join(", ") : "",
      headerValue: "3 Digits Zipcode",
      sortMethod: (d) => d.zoneRegions && (d.zoneRegions.map(function (s) {
        if (s.zipPostalCode) {
          return s.zipPostalCode;
        }
      }).filter(Boolean).sort().join(", "))
    },
    {
      columnName: "primaryZip",
      columnFieldData: (d) => getFormattedZipPostalCode(d.primaryZipPostalCode),
      headerValue: "Primary Zipcode*",
      sortMethod: (d) => getFormattedZipPostalCode(d.primaryZipPostalCode) ?? "",
    },
    {
      columnName: "secondaryZip",
      columnFieldData: (d) => getFormattedZipPostalCode(d.secondaryZipPostalCode),
      headerValue: "Secondary Zipcode*",
      sortMethod: (d) => getFormattedZipPostalCode(d.secondaryZipPostalCode) ?? "",
    },
    {
      columnName: "action",
      columnFieldData: (d) => (
        <ActionMenu
          zone={d}
          onMenuItemClick={this._onMenuItemSelected}
        />
      ),
      headerValue: "",
    }
  ];

  render() {
    const state = this.props.zoneService.getState();
    const { regionFetchResults: stateFetchResults } = this.props.regionService.getState();
    const {
      editAddZone,
      formMode,
      zoneFetchResults,
      zoneUpdateResults,
      zoneAddResults,
      zoneRegionsWithDuplicateZips,
      sortState,
      duplicateZipsFound,
      zoneModalState,
      zoneModalValidationErrors
    } = state;

    const stateData = stateFetchResults.data ?? [];
    const zoneData = zoneFetchResults.data ?? [];

    const zoneRegionsWithDuplicateZipsArray = zoneRegionsWithDuplicateZips.hasFetched ? zoneRegionsWithDuplicateZips.data : [];

    const isFetching: boolean = formMode === "add" ? zoneAddResults.isFetching :
      formMode === "edit" ? zoneUpdateResults.isFetching : false;
    return (
      <div
        className={containerStyles.mainContainer}
      >
        <Card
          className={containerStyles.cardStyle}
        >
          <CardHeader
            title="Zone Setup"
          />
          <AjaxActionIndicator
            state={[zoneFetchResults, stateFetchResults]}
          />
          <DataTable
            columns={this.columns}
            data={zoneData}
            defaultSortColumnName={sortState.sortColumnName}
            defaultSortDirection={sortState.sortDirection}
            tableFooterComponent={(
              <tr><td className={styles.tableFooter} colSpan={6} >*The Primary zip code is used for pulling DAT rates between two zones. The primary & secondary zip code is used to pull DAT rates for the same zone.</td></tr>
            )}
          />
        </Card>
        <AddEditZone
          formMode={formMode}
          zone={editAddZone}
          onCancel={this._onCancel}
          onSave={this._onSave}
          onZipCodeDelete={this._onZipCodeDelete}
          onZipCodeChange={this._onZipCodeChange}
          onZipCodeEnter={this._onZipCodeEnter}
          onPrimaryZipCodeChange={this._onPrimaryZipCodeChange}
          onSecondaryZipCodeChange={this._onSecondaryZipCodeChange}
          onDeleteConfirm={this._onDeleteConfirm}
          onDeleteCancel={this._onDeleteCancel}
          zoneRegionsWithDuplicateZips={zoneRegionsWithDuplicateZipsArray}
          zoneModalValidationErrors={zoneModalValidationErrors}
          zoneModalState={zoneModalState}
          isFetching={isFetching}
          onZoneChange={this._onZoneChanged}
          duplicateZipsFound={duplicateZipsFound}
          regionData={stateData}
        />
      </div>
    );
  }
}

export const ZoneViewPage =
  StateService.inject(
    ZoneService.inject(
      _ZoneViewPage
    )
  );
