import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  SelectedCompanyConsumer
} from "$Providers/index";

import {
  RateModelView
} from "./RateModelView";

interface IRateModelViewPageBaseProp {

}

export class RateModelViewPage extends React.Component<IRateModelViewPageBaseProp> {
  render() {
    return (
      <SelectedCompanyConsumer>
        {(companyContext) => { 
          return (
            <RateModelView companyContext={companyContext} />
          );
        }}
      </SelectedCompanyConsumer>
    );
  }
}