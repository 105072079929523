import {
  React,
  bind,
  moment
} from "$Imports/Imports";

import {
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Edit,
  Undo
} from "$Imports/MaterialUIIcons";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  Question
} from "$Generated/api";

interface IActionMenuProps {
  question: Question;
  onMenuItemClick: (question: Question) => void;
}

export class ActionMenu extends React.PureComponent<IActionMenuProps> {
  @bind
  private _onClick() {
    this.props.onMenuItemClick(this.props.question);
  }

  render() {
    const {
      question
    } = this.props;

    var now = new Date();
    now.setSeconds(0);

    return (
      <>
        <UserAccessControl roles={["questions:edit"]}>
         <IconButton
            size="small"
            onClick={this._onClick}
          >
            <Edit />
          </IconButton>
        </UserAccessControl>
      </>
    );
  }
}