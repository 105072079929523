import {
  React,
  _,
  bind,
  Helpers
} from "$Imports/Imports";

import {
  AdvanceTextField,
  IDataTableColumn,
  DataTable,
  DisplayFormattedFeet,
  DisplayFormattedNumber,
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  Commodity,
  Quote,
  QuoteFreight,
  QuoteStatusEnum
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  ThreeSixty
} from "$Imports/MaterialUIIcons";

import {
  SecurityContext
} from "$Utilities/Security/ApplicationSecuritySettings";

const styles: {
  modalContainer: string;
} = require("$Shared/styles/Modal.scss");

interface IFreightSerialModalProps {
  isOpen: boolean;
  isFetching: boolean;
  requireRetotal: boolean;
  onSave: () => void;
  onCancel: () => void;
  onChange: (quoteFreightIndex: number, quoteFreight: Partial<QuoteFreight>) => void;
  onRetotal: () => void;
  freightCommodities: QuoteFreight[];
  allCommodities: Commodity[];
  quoteStatus: QuoteStatusEnum | undefined;
}

// this is intended to be a temporary component
export class FreightSerialModal extends React.PureComponent<IFreightSerialModalProps> {
  @bind
  private _onRetotal() {
    this.props.onRetotal();
  }
  
  @bind
  private _onSave() {
    this.props.onSave();
  }

  @bind
  private _onCancel() {
    this.props.onCancel();
  }

  @bind
  private _onChange(quoteFreightIndex: number, serialRefNumber: string) {
    this.props.onChange(quoteFreightIndex, { serialRefNumber: serialRefNumber });
  }

  @bind
  private _onRotate(quoteFreightIndex: number) {
    const row = this.props.freightCommodities[quoteFreightIndex];

    this.props.onChange(quoteFreightIndex, {
      length: row.width,
      width: row.length,
      rotated: !row.rotated
    });
  }

  render() {
    const {
      isOpen,
      isFetching,
      requireRetotal,
      freightCommodities,
      allCommodities,
      quoteStatus
    } = this.props;

    let columns: Array<IDataTableColumn<QuoteFreight>> = [
      {
        columnName: "quoteStop",
        columnFieldData: (d) => d.quoteStop?.stopNumber,
        headerValue: "Stop"
      },
      {
        columnName: "commodity-name",
        columnFieldData: (d) => {
          const foundCommodity = _.find(allCommodities, co => co.id == d.commodityId);
          var commodityString: string;
  
          if (!Helpers.isNullOrUndefined(foundCommodity?.commodityShortName)){
            commodityString = foundCommodity?.commodityShortName + ` ${d.numberOfPieces && d.numberOfPieces > 1 ? `(` + d.numberOfPieces + `)` : ""}`
          } else {
            commodityString = foundCommodity?.commodityName + ` ${d.numberOfPieces && d.numberOfPieces > 1 ? `(` + d.numberOfPieces + `)` : ""}`
          }
          return commodityString
        },
        headerValue: "Commodity"
      },
      {
        columnName: "length",
        columnFieldData: (d) => <DisplayFormattedFeet value={d.length}/>,
        headerValue: "Length"
      },
      {
        columnName: "width",
        columnFieldData: (d) => <DisplayFormattedFeet value={d.width}/>,
        headerValue: "Width"
      },
      {
        columnName: "height",
        columnFieldData: (d) => <DisplayFormattedFeet value={d.height}/>,
        headerValue:  "Height"
      },
      {
        columnName: "weight",
        columnFieldData: (d) => <DisplayFormattedNumber value={d.weight} formatString={"0"} postfix={" lbs"}/>,
        headerValue: "Weight"
      },
      {
        columnName: "serialRefNumber",
        columnFieldData: (d, idx) => 
          <AdvanceTextField
            label=""
            inputProps={{ maxLength: 40 }}
            onDebouncedChange={(value) => this._onChange(idx, value)}
            value={d.serialRefNumber ?? ""}
          />
        ,
        headerValue: "Serial/Ref #",
        headerProps: {
          style: { whiteSpace: "nowrap" }
        }
      },
      {
        columnName: "isStackable",
        columnFieldData: (d) => d.isStackable && d.isStackable ? "Yes" : "No",
        headerValue: "Stack"
      },
      {
        columnName: "isSideBySide",
        columnFieldData: (d) => d.isSideBySide && d.isSideBySide ? "Yes" : "No",
        headerValue: "Sbs"
      },
      {
        columnName: "rotated",
        columnFieldData: (d) => d.rotated && d.rotated ? "Yes" : "No",
        headerValue: "Rotated"
      }
    ];

    if ((SecurityContext.isInGroup("/Admin") || SecurityContext.isInGroup("/Manager")) && quoteStatus === "ApprovalNeeded") {
      columns.push({
        columnName: "rotate",
        columnFieldData: (d, idx) => (
          <IconButton
            size="small"
            onClick={() => this._onRotate(idx)}
          >
            <ThreeSixty />
          </IconButton>
        ),
        headerValue: ""
      })
    }

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="lg"
      >
        <AjaxActionIndicator showProgress={isFetching} />
        <DialogTitle>
          Edit Freight
        </DialogTitle>
        <DialogContent
          className={styles.modalContainer}
        >
          <DataTable
            columns={columns}
            data={freightCommodities}
          />
        </DialogContent>
        <DialogActions>
          { requireRetotal &&
            <>
              <span style={{ marginRight: "0.5rem" }}>
                Measurement totals require recalculation
              </span>

              <Button
                color="primary"  
                onClick={this._onRetotal}
                disabled={isFetching}
              >
                Get Freight Totals
              </Button>
            </>            
          }

          <Button
            onClick={this._onCancel}
            variant="outlined"
            disabled={isFetching}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this._onSave}
            disabled={isFetching || requireRetotal}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}