import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  SelectedCompanyConsumer
} from "$Providers/index";

import {
  CommodityRatesView
} from "./CommodityRatesView";

interface ICommodityRatesViewPageBaseProp {

}

export class CommodityRatesViewPage extends React.Component<ICommodityRatesViewPageBaseProp> {
  render() {
    return (
      <SelectedCompanyConsumer>
        {(companyContext) => { 
          return (
            <CommodityRatesView companyId={companyContext.companyId}/>
          );
        }}
      </SelectedCompanyConsumer>
    );
  }
}