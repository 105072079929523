import {
    _
} from "$Imports/Imports";

import {
    Region,
    Zone
} from "$Generated/api";

export function renderStatesSelection(selected: Region[], regionData: Region[]): string {
    const usRegions = regionData.filter(x => x.countryId === 1);
    const canadaRegions = regionData.filter(x => x.countryId === 2);

    const allStatesSelected = selected.filter(x => x.countryId === 1).length === usRegions.length;
    const allProvincesSelected = selected.filter(x => x.countryId === 2).length === canadaRegions.length;

    if (allStatesSelected && allProvincesSelected) {
        return "All States, All Provinces";
    } else if (allStatesSelected) {
        if (selected.filter(x => x.countryId === 2).length > 0) {
            return "All States, " + selected.filter(x => x.countryId === 2).map(s => s.regionAbbreviation).sort().join(", ");
        }
        return "All States"
    } else if (allProvincesSelected) {
        if (selected.filter(x => x.countryId === 1).length > 0) {
            return "All Provinces, " + selected.filter(x => x.countryId === 1).map(s => s.regionAbbreviation).sort().join(", ");
        }
        return "All Provinces";
    } else {
        return selected.map(s => s.regionAbbreviation).sort().join(", ");
    }
}

export function renderZonesSelection(selected: Zone[], zoneDataLen: number): string {
    const allZonesSelected = selected.length === zoneDataLen;

    if (allZonesSelected) {
        return "All Zones";
    } else {
        return selected.map(s => s.zoneAbbreviation).sort().join(", ");
    }
}