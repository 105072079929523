import {
  React,
  _
} from "$Imports/Imports";

import {
  CustomerDetailView
} from "./CustomerDetailView";

import { SelectedCompanyConsumer } from "$Providers/CompanyProvider";

interface ICustomerDetailViewPageProps {
  customerId: number | undefined;
}

export class CustomerDetailViewPage extends React.Component<ICustomerDetailViewPageProps> {
  render() {
    return (
      <SelectedCompanyConsumer>
        {(companyContext) => { 
          return (
            <CustomerDetailView companyId={companyContext.companyId} customerId={this.props.customerId}/>
          );
        }}
      </SelectedCompanyConsumer>
    );
  }
}