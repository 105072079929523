import {
  React
} from "$Imports/Imports";

import {
  Avatar
} from "$Imports/MaterialUIComponents";

import {
  logisticsGreen,
  highlightGreen,
  transportBlue,
  highlightBlue
} from "$Themes/index";

type CompanyKey = "KT" | "KL" | "CP" | "CPKT" | "CPKL"; // for customer portal

interface IOwnProps {
  companyKey: string;
}

const companyColorMap: { [key in CompanyKey]: string } = {
  "KT": transportBlue,
  "KL": logisticsGreen,
  "CP": "white",
  "CPKT": highlightBlue,
  "CPKL": highlightGreen
};

const styles: {
  logo: string;
  customerPortal: string;
} = require("./CompanyIcon.scss");

class _CompanyIcon extends React.PureComponent<IOwnProps> {
  render() {
    const { companyKey } = this.props;

    const formattedCompanyKey = companyKey === "CPKT" || companyKey === "CPKL" ? "CP" : companyKey;

    return (
      <Avatar
        className={`${styles.logo} ${formattedCompanyKey === "CP" ? styles.customerPortal : ""}`}
        style={{ backgroundColor: companyColorMap[companyKey as CompanyKey] ?? "white" }}
      >
        {formattedCompanyKey}
      </Avatar>
    );
  }
}

export const CompanyIcon = _CompanyIcon;
