import {
  React,
  _
} from "$Imports/Imports";

import {
  SelectedCompanyConsumer
} from "$Providers/index";

import {
  CapacityRateView
} from "./CapacityRateView";

interface ICapacityRateViewPageProps {

}

export class CapacityRateViewPage extends React.Component<ICapacityRateViewPageProps> {
  render() {
    return (
      <SelectedCompanyConsumer>
        {(companyContext) => { 
          return (
            <CapacityRateView companyId={companyContext.companyId}/>
          );
        }}
      </SelectedCompanyConsumer>
    );
  }
}