import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from "$Imports/MaterialUIComponents";

import {
  QuoteQuoteTypeEnum,
  QuoteSearchCriteria,
  SimplifiedQuote
} from "$Generated/api";

import {
  QuoteStatus,
  CustomerLink,
  DueDateField,
  DisplayFormattedDatetime,
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  ISalesRepHomeServiceInjectedProps,
  SalesRepHomeService
} from "$State/SalesRepHomeFreezerService";

import {
  MyQuotesSearchForm
} from "./MyQuotesSearchForm";

import {
  CURRENCY_FORMAT,
  CURRENCY_NO_DECIMAL_FORMAT,
  DATE_ONLY_FORMAT,
  DATE_WITH_TIME_MERIDIAN_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  quoteNumberWithLinkCol,
  getDeliveryDateColumn
} from "$Utilities/quoteColConstants";

import { getFormattedZipPostalCode } from "$Shared/utilities/helpers";

const styles: {
  gridContainer: string;
  expiry: string;
  searchFormContainer: string;
} = require("./MyQuotes.scss");

interface IMyQuotesState { }

interface IMyQuotesBaseProps {
  companyId: number | undefined;
  quoteType: QuoteQuoteTypeEnum;
}

function getQuoteDateCol(quoteType: QuoteQuoteTypeEnum): GridColDef {
  return {
    headerName: quoteType === "Full" ? "Quote Date / Time" : "Quote Date",
    field: "quoteDate",
    renderCell: (params: GridRenderCellParams) => <DisplayFormattedDatetime value={params.value} formatString={quoteType === "Full" ? DATE_WITH_TIME_MERIDIAN_FORMAT : DATE_ONLY_FORMAT} />,
    flex: 2
  };
}

function getRateColumn(quoteType: QuoteQuoteTypeEnum): GridColDef {
  return {
    headerName: quoteType === "Full" ? "Rate" : "Neg. Rate",
    field: "negotiatedRate",
    renderCell: (params: GridRenderCellParams) => <DisplayFormattedNumber value={params.value} formatString={quoteType === "Full" ? CURRENCY_NO_DECIMAL_FORMAT : CURRENCY_FORMAT} />,
    flex: 1.5
  };
}

type IMyQuotesProps = IMyQuotesBaseProps
  & ISalesRepHomeServiceInjectedProps;

class _MyQuotes extends React.Component<IMyQuotesProps, IMyQuotesState> {

  @bind
  private _getFullQuoteColumns(): GridColDef[] {
    const {quoteType} = this.props;

    return [
      quoteNumberWithLinkCol,
      {
        headerName: "Customer Name",
        field: "customer",
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.customer?.customerName ?? "";
        },
        flex: 2,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomerLink
              customerId={params.row.customer?.id}
              customerName={params.row.customer?.customerName ?? ""}
            />
          );
        }
      },
      getQuoteDateCol(quoteType),
      getDeliveryDateColumn("Delivery Date / Time", true),
      getRateColumn(quoteType),
      {
        headerName: "Expiration Date / Time",
        field: "expirationDate",
        flex: 2.5,
        renderCell: (params: GridRenderCellParams<Date>) => <DueDateField formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} value={params.value} />
      },
      {
        headerName: "Status",
        field: "status",
        renderCell: (params: GridRenderCellParams) => <QuoteStatus quoteStatus={params.value} />,
        flex: 2
      }
    ];
  }

  @bind
  private _getQuickQuoteColumns(): GridColDef[] {
    const {quoteType} = this.props;

    return [
      quoteNumberWithLinkCol,
      getQuoteDateCol(quoteType),
      getDeliveryDateColumn("Delivery Date"),
      getRateColumn(quoteType),
      {
        headerName: "Shipper Zip",
        field: "shipperZip",
        valueGetter: (params: GridValueGetterParams<string, SimplifiedQuote>) =>
        params.row.quoteStops?.[0].addresses && params.row.quoteStops?.[0].addresses.length > 0 ? `${getFormattedZipPostalCode(params.row.quoteStops?.[0].addresses?.[0].zipPostalCode)}` : "",
        flex: 1.5
      },{
        headerName: "Consignee Zip",
        field: "consigneeZip",
        valueGetter: (params: GridValueGetterParams<string, SimplifiedQuote>) =>
        params.row.quoteStops?.[0].addresses && params.row.quoteStops?.[0].addresses.length > 0 ? `${getFormattedZipPostalCode(params.row.quoteStops?.[0].addresses?.[1].zipPostalCode)}` : "",
        flex: 1.5
      },{
        headerName: "Contact Name",
        field: "contactName",
        flex: 3
      },{
        headerName: "Phone Number",
        field: "contactPhoneNumber",
        flex: 2
      },
    ];
  }

  @bind
  private async _onSearchSubmit(search: QuoteSearchCriteria): Promise<void> {
    const {quoteType} = this.props;
    this.props.salesRepHomeService.update({
      quoteSearchCriteria: { ...search }
    });

    await this.props.salesRepHomeService.fetchQuotes(this.props.companyId, true, quoteType);
  }

  componentDidMount() {
    const {quoteType} = this.props;
    this.props.salesRepHomeService.fetchQuotes(this.props.companyId, true, quoteType);
  }

  componentDidUpdate(prev: IMyQuotesBaseProps) {
    const {quoteType} = this.props;
    if (this.props.companyId !== prev.companyId) {
      this.props.salesRepHomeService.fetchQuotes(this.props.companyId, true, quoteType);
    }
  }

  render() {
    const {quoteType} = this.props;
    const {
      quoteFetchResults,
      quickQuoteFetchResults
    } = this.props.salesRepHomeService.getState();
    const quoteData = quoteType === "Full" ? quoteFetchResults.data?.results ?? [] : quickQuoteFetchResults.data?.results ?? [];

    return (
      <div className={styles.gridContainer}>
        <div className={styles.searchFormContainer}>
          <MyQuotesSearchForm
            quoteType={quoteType}
            onSubmit={this._onSearchSubmit}
          />
        </div>

        <DataGridPro
          rows={quoteData}
          columns={quoteType === "Full" ? this._getFullQuoteColumns() : this._getQuickQuoteColumns()}
          density="compact"
          hideFooter
          initialState={{
            sorting: {
              sortModel: [{ field: quoteType === "Full" ? "expirationDate" : "deliveryDate", sort: "asc" }]
            }
          }}
        />
      </div>
    );
  }
}

export const MyQuotes = SalesRepHomeService.inject(
  _MyQuotes
);
