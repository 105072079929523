import {
  React,
  _,
  bind,
  NumberFormat,
  NumberFormatProps
} from "$Imports/Imports";

import {
  DisplayFormattedFeet
} from "$Imports/CommonComponents";

import {
  TextField,
  TextFieldProps,
  FormHelperText,
  IconButton,
  Typography
} from "$Imports/MaterialUIComponents";

import {
  Clear
} from "$Imports/MaterialUIIcons";

import SharedConstants from "$Shared/utilities/SharedConstants";

import {
  convertInToMm
} from "$Utilities/conversionUtil";

const styles: {
  table: string,
  label: string,
  iconButton: string,
  iconButtonCell: string,
} = require("./FeetInputField.scss");

interface IFeetInputFieldBasedProps {
  onFeetValueChange: (newValue?: number) => void;
  inches?: number;
  widthOverride?: string;
  label?: string;
  isMetricUnits: boolean;
}

interface IOwnState {
  stateFt: number;
  stateIn: number;
  stateM: number;
  stateCm: number;
  stateMm: number;
}

export type IFeetInputFieldProps = IFeetInputFieldBasedProps & NumberFormatProps<TextFieldProps>;

export class FeetInputField extends React.PureComponent<IFeetInputFieldProps, IOwnState> {
  state: IOwnState = {
    stateFt: 0,
    stateIn: 0,
    stateM: 0,
    stateCm: 0,
    stateMm: 0
  };

  componentDidUpdate(prevProps: Readonly<IFeetInputFieldProps>): void {
    const { isMetricUnits } = this.props;
    if (isMetricUnits !== prevProps.isMetricUnits || this.props.inches !== prevProps.inches) {
      const mm = isMetricUnits && this.props.inches ? convertInToMm(this.props.inches) : 0;
      this.setState({
        stateFt: !isMetricUnits && this.props.inches ? Math.floor(this.props.inches / 12) : 0,
        stateIn: !isMetricUnits && this.props.inches ? Math.ceil(this.props.inches % 12) : 0,
        stateM: isMetricUnits ? Math.floor(mm / 1000) : 0,
        stateCm: isMetricUnits ? Math.floor(mm / 10) % 100 : 0,
        stateMm: isMetricUnits ? mm % 10 : 0
      });
    }
  }

  private isEmpty(value: any): boolean {
    return value === 0 || value === "";
  }

  @bind
  private getTotalInches(feet?: number, inches?: number, meters?: number, cm?: number, mm?: number): number | undefined {
    const { stateFt, stateIn, stateM, stateCm, stateMm } = this.state;
    if (this.props.isMetricUnits) {
      const millimeters = (meters ?? stateM) * SharedConstants.mmsMeterConversion +
        (cm ?? stateCm) * SharedConstants.mmsCmConversion +
        (mm ?? stateMm);
      return millimeters / SharedConstants.inchesMmConversion;
    } else {
      return (feet ?? stateFt) * SharedConstants.inchesFtConversion + (inches ?? stateIn);
    }
  }

  @bind
  private _onFeetChange(e: any) {
    this.props.onFeetValueChange(this.getTotalInches(this.isEmpty(e.currentTarget.value) ? 0 : parseInt(e.currentTarget.value)));
  }

  @bind
  private _onMeterChange(e: any) {
    this.props.onFeetValueChange(this.getTotalInches(undefined, undefined, this.isEmpty(e.currentTarget.value) ? 0 : parseInt(e.currentTarget.value)));
  }

  @bind
  private _onInchChange(e: any) {
    this.props.onFeetValueChange(this.getTotalInches(undefined, this.isEmpty(e.currentTarget.value) ? 0 : parseInt(e.currentTarget.value)));
  }

  @bind
  private _onCentimeterChange(e: any) {
    this.props.onFeetValueChange(this.getTotalInches(undefined, undefined, undefined, this.isEmpty(e.currentTarget.value) ? 0 : parseInt(e.currentTarget.value)));
  }

  @bind
  private _onMillimeterChange(e: any) {
    this.props.onFeetValueChange(this.getTotalInches(undefined, undefined, undefined, undefined, this.isEmpty(e.currentTarget.value) ? 0 : parseInt(e.currentTarget.value)));
  }

  @bind 
  private _selectAllOnFocus(event: React.FocusEvent<HTMLInputElement>) {
    event.target.select();
  }

  @bind
  private _clear() {
    this.props.onFeetValueChange(undefined);
  }

  render() {
    const {
      onFeetValueChange,
      inches,
      label,
      error,
      helperText,
      widthOverride,
      isMetricUnits,
      ...passthroughProps
    } = this.props;

    const {
      stateFt,
      stateIn,
      stateM,
      stateCm,
      stateMm
    } = this.state;

    return (
      <table className={styles.table}>
        <tbody>
          <tr>
            {label ? <td className={styles.label}>
              <Typography
                variant="body2"
                color={error ? "error" : "inherit"}
              >
                {label}
              </Typography>
            </td> : null}
            {!this.props.disabled ?
              <>
                <td style={{
                  paddingRight: "5px"
                }}>
                  <NumberFormat
                    {...passthroughProps}
                    error={error}
                    label={isMetricUnits ? "m" : "ft"}
                    value={isMetricUnits ? stateM === 0 ? "" : stateM : stateFt === 0 ? "" : stateFt}
                    onBlur={isMetricUnits ? this._onMeterChange : this._onFeetChange}
                    onFocus={this._selectAllOnFocus}
                    customInput={TextField}
                    allowNegative={false}
                    decimalScale={0}
                    allowLeadingZeros={false}
                    style={{ width: widthOverride ?? "48px" }}
                  />
                </td>
                <td style={{
                  padding: 0
                }}>
                  <NumberFormat
                    {...passthroughProps}
                    error={error}
                    label={isMetricUnits ? "cm" : "in"}
                    value={isMetricUnits ? stateCm === 0 ? "" : stateCm : stateIn === 0 ? "" : stateIn}
                    onBlur={isMetricUnits ? this._onCentimeterChange : this._onInchChange}
                    onFocus={this._selectAllOnFocus}
                    customInput={TextField}
                    allowNegative={false}
                    decimalScale={0}
                    allowLeadingZeros={false}
                    style={{ width: widthOverride ?? "48px" }}
                  />
                </td>
                {
                  isMetricUnits &&
                  <td style={{
                    paddingLeft: "5px"
                  }}>
                    <NumberFormat
                      {...passthroughProps}
                      error={error}
                      label={"mm"}
                      value={stateMm === 0 ? "" : stateMm}
                      onBlur={this._onMillimeterChange}
                      onFocus={this._selectAllOnFocus}
                      customInput={TextField}
                      allowNegative={false}
                      decimalScale={0}
                      allowLeadingZeros={false}
                      style={{ width: widthOverride ?? "48px" }}
                    />
                  </td>
                }
              </> : <td colSpan={2}>
                <DisplayFormattedFeet value={stateFt} />
              </td>
            }
            <td className={styles.iconButtonCell}>
              {!this.props.disabled &&
                <IconButton
                  size="small"
                  onClick={this._clear}
                  disabled={this.props.disabled}
                >
                  <Clear className={styles.iconButton} />
                </IconButton>
              }
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <FormHelperText
                error={error}
                children={helperText ?? ""}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

