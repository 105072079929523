import {
  FreezerService,
  _,
  bind
} from "$Imports/Imports";

import { INavigationItem, mainNavigation } from "$Utilities/navigation";
import { isNullOrUndefined } from "$Shared/utilities/helpers";

import * as H from "history";
import { SitePubSubManager } from "$Utilities/pubSubUtil";

const InjectedPropName = "navigationService";

interface INavigationState {
  url?: string;
  navigationParentNavigation: INavigationItem | null;
}

class NavigationFreezerService extends FreezerService<INavigationState, typeof InjectedPropName> {
  constructor() {
    super({
      url: window.location.pathname,
      navigationParentNavigation: null,
    }, InjectedPropName);

    this.freezer.get().set({
      navigationParentNavigation: this.getParentPath(window.location.pathname),
    });

    SitePubSubManager.subscribe("application:logout", this.logoutNavigation);
  }

  private history: H.History | null = null;

  @bind
  private logoutNavigation() {
    this.navigateTo("/");
  }

  public navigateTo(url: string, replaceHistory: boolean = false) {
    if (this.history) {
      if (replaceHistory) {
        this.history.replace(url);
      } else {
        this.history.push(url);
      }
    }
    this.freezer.get().set({
      navigationParentNavigation: this.getParentPath(window.location.pathname),
    });
  }

  public setWindowsTitle(title: string) {
    document.title = title;
  }
  
  public updateTitle() {
    const navigation = this.getParentPath(window.location.pathname);

    if (navigation) {
      this.setWindowsTitle(navigation.title ? navigation.title : `Kaiser Sales Portal - ${navigation.label}`);
    } else {
      this.setWindowsTitle(`Kaiser Sales Portal`);
    }
  }

  public getNavigationDetail(): INavigationItem | null {
    let navDetails = this.freezer.get().toJS().navigationParentNavigation;
    
    if(!navDetails) {
      const strippedURL = `/${window.location.pathname.split('/')[1]}`;   
      this.updateState(strippedURL);
      navDetails = this.freezer.get().toJS().navigationParentNavigation;
    }

    return navDetails
  }

  public getCurrentParentPath(): INavigationItem | null {
    const currentPage = this.getNavigationDetail();
    if (currentPage) {
      return this.getParentPath(currentPage.url);
    }

    return null;
  }

  public getParentPath(url: string): INavigationItem | null {
    const currentPath = _.split(url, "/");

    if (currentPath.length <= 2) {
      return this.findNavigation(url);
    } else {
      // this will break if we add paths more than 2 deep
      // but it works for /salesportal/quote/{quoteId}/{viewOnly}

      var combinedString = _.join(currentPath, "/");
      var nav: INavigationItem | null = this.findNavigation(combinedString);;
      
      while (nav == null && currentPath.length > 0) {
        currentPath.pop();
        combinedString = _.join(currentPath, "/");
        nav = this.findNavigation(combinedString);
      }

      return nav;
    }
  }

  public getSideNavigationItems() {
    let currPage = this.getNavigationDetail();
    // group side navigation items by root url
    return mainNavigation.filter(n => currPage && n.rootUrl == currPage.rootUrl);
  }

  public initHistory(historyObject: H.History) {
    if (historyObject) {
      this.history = historyObject;
      this.history.listen(this._onHistoryChanged);
    }
  }

  private updateState(url: string) {
    const navRec = this.findNavigation(url);
    this.freezer.get().set({
      url,
      navigationParentNavigation: navRec,
    });
  }

  @bind
  private _onHistoryChanged(event: H.Location) {
    this.updateState(event.pathname);
  }

  private findNavigation(url: string, navigationItems: INavigationItem[] = mainNavigation): INavigationItem | null {
    let foundItem: INavigationItem | null | undefined = _.find(navigationItems, (d: INavigationItem) => {
      if(_.findIndex(d.url, ":")) {
        const splitString = d.url.split('/:');
        return splitString[0] === url;
      }

      return d.url === url;
    });

    if (foundItem) {
      return foundItem;
    }

    _.forEach(navigationItems, (m: INavigationItem) => {
      if (!isNullOrUndefined(m.childNavigation) && m.childNavigation !== undefined && m.childNavigation.length !== 0) {
        foundItem = this.findNavigation(url, m.childNavigation);
      }

      // Break the foreach each loop if navigation item found.
      if (!isNullOrUndefined(foundItem)) {
        return false;
      }
    });

    return foundItem === undefined ? null : foundItem;
  }
}

export const NavigationService = new NavigationFreezerService();
export type INavigationServiceInjectedProps = ReturnType<NavigationFreezerService["getPropsForInjection"]>;
