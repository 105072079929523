// extracted by mini-css-extract-plugin
var _1 = "CustomerDetailView__address__VhQeY";
var _2 = "CustomerDetailView__announcement__LYUj5";
var _3 = "CustomerDetailView__column__mcx_A";
var _4 = "CustomerDetailView__fieldBlock__l7eGu";
var _5 = "CustomerDetailView__fieldLabel__GoatZ";
var _6 = "CustomerDetailView__fieldPair__ySY9R";
var _7 = "CustomerDetailView__fieldValue__MPZ5u";
var _8 = "CustomerDetailView__infoContainer__c2KRZ";
var _9 = "CustomerDetailView__infoHeader__OKzrd";
var _a = "CustomerDetailView__labelValuePair__LEvdV";
var _b = "CustomerDetailView__mainContainer__eaJQc";
var _c = "CustomerDetailView__subcolumn__uykm8";
export { _1 as "address", _2 as "announcement", _3 as "column", _4 as "fieldBlock", _5 as "fieldLabel", _6 as "fieldPair", _7 as "fieldValue", _8 as "infoContainer", _9 as "infoHeader", _a as "labelValuePair", _b as "mainContainer", _c as "subcolumn" }
