import {
  React,
  bind
} from "$Imports/Imports";

import {
  AuditCustomerAuditLog
} from "$Generated/api";

import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from "$Imports/MaterialUIComponents";

import {
  CardLinedHeader,
  AjaxActionIndicator,
  DisplayFormattedDatetime,
  QuoteLink,
  TextCellTruncated
} from "$Imports/CommonComponents";

import {
  DATE_WITH_TIME_MERIDIAN_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  ActionMenu
} from "./ActionMenu";

import {
  LogDetails
} from "./LogDetails";

interface IAuditLogCardProps {
  auditLogs: AuditCustomerAuditLog[];
  isFetching: boolean;
}

interface IAuditLogCardState {
  detailAuditLog: AuditCustomerAuditLog | undefined;
  showDetails: boolean;
}

export class AuditLogCard extends React.Component<IAuditLogCardProps, IAuditLogCardState> {

  state: IAuditLogCardState = {
    detailAuditLog: undefined,
    showDetails: false
  }

  private readonly columns: GridColDef[] = [
    {
      headerName: "Datetime",
      field: "datetime",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<Date | undefined, AuditCustomerAuditLog>) => {
        return params.row.createdOn;
      },
      renderCell: (params: GridRenderCellParams<any, AuditCustomerAuditLog>) =>
        <TextCellTruncated text={<DisplayFormattedDatetime value={params.value} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} />} />
    },
    {
      headerName: "User",
      field: "user",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<string, AuditCustomerAuditLog>) => {
        return params.row.employee?.firstName + " " + params.row.employee?.lastName;
      },
      renderCell: (params: GridRenderCellParams<string>) =>
        params.value && <TextCellTruncated text={params.value} />
    },
    {
      headerName: "Element",
      field: "element",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<string, AuditCustomerAuditLog>) => {
        return params.row.log?.element;
      },
      renderCell: (params: GridRenderCellParams<string>) =>
        params.value && <TextCellTruncated text={params.value} />
    },
    {
      headerName: "Event Type",
      field: "eventType",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<string, AuditCustomerAuditLog>) => {
        return params.row.log?.eventType;
      },
      renderCell: (params: GridRenderCellParams<string>) =>
        params.value && <TextCellTruncated text={params.value} />
    },
    {
      headerName: "Activity",
      field: "activity",
      flex: 2,
      valueGetter: (params: GridValueGetterParams<string, AuditCustomerAuditLog>) => {
        return params.row.log?.activity;
      },
      renderCell: (params: GridRenderCellParams<string, AuditCustomerAuditLog>) => {
        let content: string | JSX.Element | undefined = params.value;
        let tooltip: string | JSX.Element | undefined = params.value;

        if (params.row.log?.element === "Quotes") {
          if (params.row.log.eventType === "Add") {
            // New quote *** created
            content = <>New quote&nbsp;<QuoteLink quoteId={params.row.log.recordId} quoteNumber={`Q${params.row.log.recordName}`} />&nbsp;created</>;
            tooltip = <>New quote&nbsp;<b>Q{params.row.log.recordName}</b>&nbsp;created</>;
          }
          else if (params.row.log.eventType === "Edit") {
            // Quote *** status changed
            content = <>Quote&nbsp;{<QuoteLink quoteId={params.row.log.recordId} quoteNumber={`Q${params.row.log.recordName}`} />}&nbsp;status changed</>;
            tooltip = <>Quote&nbsp;<b>Q{params.row.log.recordName}</b>&nbsp;status changed</>
          }
        }

        return content && <TextCellTruncated text={content} tooltip={tooltip} />;
      },
      sortable: false
    },
    {
      headerName: "",
      field: "actions",
      width: 56,
      resizable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<AuditCustomerAuditLog>) => (
        <ActionMenu auditLog={params.row} openDetails={this.openAuditDetails} />
      )
    }
  ];

  @bind
  private openAuditDetails(auditLog: AuditCustomerAuditLog) {
    this.setState({
      detailAuditLog: auditLog,
      showDetails: true
    });
  }

  @bind
  private closeAuditDetails() {
    this.setState({
      detailAuditLog: undefined,
      showDetails: false
    });
  }

  render() {
    const {
      auditLogs,
      isFetching
    } = this.props;

    const {
      detailAuditLog,
      showDetails
    } = this.state;

    return (
      <CardLinedHeader titleText="Activity Log">
        <AjaxActionIndicator showProgress={isFetching} />
        {
          showDetails && detailAuditLog ?
            <LogDetails
              auditLog={detailAuditLog}
              closeDetails={this.closeAuditDetails}
            />
            :
            <div style={{ height: "250px" }}>
              <DataGridPro
                rows={auditLogs}
                columns={this.columns}
                density="compact"
                hideFooter
                disableSelectionOnClick
                initialState={{
                  sorting: {
                    sortModel: [{ field: "datetime", sort: "desc" }]
                  }
                }}
              />
            </div>
        }
      </CardLinedHeader>
    )
  }
}