import {
  React,
  bind
} from "$Imports/Imports";

import {
  Popper,
  Paper
} from "$Imports/MaterialUIComponents";

import {
  Quote
} from "$Generated/api";

const styles: {
  freightBillDisplay: string,
  freightBillTooltip: string
} = require("./FreightBillTooltip.scss");

interface IFreightBillTooltipProps { 
  quote?: Quote;
}

interface IFreightBillTooltipState {
  tooltipIsOpen: boolean;
  textEl: HTMLElement | null;
}

// this has potential as a reusable component similar to TruncatedTextDisplay
// but for strictly one-line text with no explicit character limit

export class FreightBillTooltip extends React.Component<IFreightBillTooltipProps, IFreightBillTooltipState> {
  state: IFreightBillTooltipState = {
    tooltipIsOpen: false,
    textEl: null
  }

  @bind
  private _onMouseEnter(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({
      tooltipIsOpen: true,
      textEl: e.currentTarget
    });
  }

  @bind 
  private _onMouseLeave(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({
      tooltipIsOpen: false,
      textEl: null
    });
  }

  render() {
    const {
      quote
    } = this.props;
    const {
      tooltipIsOpen,
      textEl
    } = this.state;

    // NOTE: future story must include sorting by quote stop order
    var fbs = quote?.quoteStops?.map(q => q.freightBillNumber).join(", ");

    return (
      <>
        <div
          onMouseEnter={this._onMouseEnter}
          onMouseLeave={this._onMouseLeave}
          className={styles.freightBillDisplay}
        >
          {fbs}
        </div>
        <Popper
          open={tooltipIsOpen}
          placement="bottom-start"
          anchorEl={textEl}
        >
          <Paper className={styles.freightBillTooltip}>
            {fbs}
          </Paper>
        </Popper>
      </>
    );
  }
}