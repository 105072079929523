import {
  React,
  bind,
  numeral
} from "$Imports/Imports";

import {
  Person
} from "$Imports/MaterialUIIcons";

import {
  Bar,
  BarChart,
  ContentType,
  LabelList,
  LabelProps,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "$Imports/Recharts";

import {
  UserMetricsSalesLeaderboardView
} from "$Generated/api";

import {
  ISalesRepHomeServiceInjectedProps,
  SalesRepHomeService
} from "$State/SalesRepHomeFreezerService";

import {
  TerminalEnum,
  TERMINAL_MAP
} from "$Utilities/companyUtil";

import {
  SecurityContext
} from "$Utilities/Security/ApplicationSecuritySettings";

import {
  SalesLeaderboardTerminalSelector
} from "./SalesLeaderboardTerminalSelector";

interface IOwnProps { }

type OwnProps = IOwnProps
  & ISalesRepHomeServiceInjectedProps;

interface IChartDataPoint {
  salesAgent: string;
  userName: string;
  total: number;
  label: string;
  terminals: { [value in TerminalEnum]: number };
  labelledTerminalId?: TerminalEnum;
}

const styles: {
  title: string;
  terminalSelectorContainer: string;
  chart: string;
  currentUserLabel: string;
  noData: string;
} = require("./SalesLeaderboard.scss");


const getLabel = (props: LabelProps, chartData: IChartDataPoint[], terminalId: string): ContentType => {
  // HACK: props from a LabelList has index, but the typing doesn't allow us to access it
  const index = (props as any).index;
  const data = chartData[index];
  if (!data?.total || data.labelledTerminalId !== terminalId) {
    return <></>;
  }

  const { x, y, width, height } = props;

  return (
    <g transform={`translate(${Number(x) + Number(width)}, ${Number(y) - Number(height)})`}>
      <Person
        height={24}
        width={24}
        x={5}
      />
      <text
        className={data.userName === "Me" ? styles.currentUserLabel : undefined}
        alignmentBaseline="middle"
        height={height}
        textAnchor="start"
        x={34}  // icon width w/ padding
        y={Number(height) * 2}
      >
        {data.label}
      </text>
      <title>
        {data.userName}
      </title>
    </g>
  );
};

class _SalesLeaderboard extends React.Component<OwnProps> {
  componentDidMount() {
    this.props.salesRepHomeService.fetchSalesLeaderboard();
  }

  private _getChartData(sourceData: UserMetricsSalesLeaderboardView[], terminalIds: string[]): IChartDataPoint[] | undefined {
    if (!sourceData.length) {
      return undefined;
    }

    const chartData: IChartDataPoint[] = [];
    const currentSalesAgent = SecurityContext.getTMSalesAgent();

    for (const entry of sourceData) {
      // aggregate based on selected filters, with these rules:
      // 1) companyId == 3 (Kaiser Logistics) gets aggregated together, regardless of terminal (map to terminalId 0)
      // 2) companyId != 3 gets aggregated by terminalId

      const terminalId = entry.companyId === 3 ? TerminalEnum.Logistics
        : entry.terminalId?.toString() as TerminalEnum ?? TerminalEnum.Logistics;

      // include only selected terminals
      if (terminalIds.indexOf(terminalId) === -1) {
        continue;
      }

      let userData = chartData.find(x => x.salesAgent === entry.salesAgent);

      if (!userData) {
        userData = {
          salesAgent: entry.salesAgent,
          userName: entry.salesAgent === currentSalesAgent ? "Me" : entry.username,
          total: 0,
          terminals: {},
          labelledTerminalId: undefined,
          label: ""
        } as IChartDataPoint;

        chartData.push(userData);
      }

      // source data is aggregated by terminal
      // terminal "0" (Logistics) may have more than one entry in source data, fill in 0 for any missing data
      const charges = entry.charges ?? 0;

      if (charges > 0) {
        userData.total += charges;
        userData.terminals[terminalId] = (userData.terminals[terminalId] ?? 0) + charges;

        // determine which terminal bar label should be shown
        // use "right-most": highest-ID'd terminal with > 0 value (bars are stacked in terminal ID order)
        const labelledTerminalId = isNaN(Number(userData.labelledTerminalId)) ? -1 : Number(userData.labelledTerminalId) ?? -1;

        if (Number(terminalId) > labelledTerminalId) {
          userData.labelledTerminalId = terminalId;
        }

        // pre-calculate label when available to help w/ dynamic margin
        userData.label = `${numeral(userData.total).format("$0,0")} ${userData.userName}`;
      }
    }

    // sort descending by aggregated sales (charges) total
    return chartData
      .filter(x => x.total > 0)
      .sort((a, b) => a.total > b.total ? -1 : a.total < b.total ? 1 : 0);
  }

  @bind
  private _onTerminalsChange(terminalIds: string[]): void {
    this.props.salesRepHomeService.update({
      salesLeaderboardFilters: {
        selectedTerminals: [...terminalIds]
      }
    });
  }

  render() {
    const {
      salesLeaderboardFetchResults,
      salesLeaderboardFilters
    } = this.props.salesRepHomeService.getState();

    const chartData = this._getChartData(salesLeaderboardFetchResults.data ?? [], salesLeaderboardFilters.selectedTerminals);

    // HACK: get all of the bar labels that are within 90% of the top-line bar label total (should be the highest amount) and find the one with the longest label and adapt to that.
    // SVG chart does a lot of internal calculation and does not flex its total size based on labels
    // attempt to transform into a "relative" unit (~10px -> 1rem), with some extra for embedded icon
    
    const rightMargin = chartData?.length ? chartData.filter(d => d.total > chartData[0].total * .9).sort((a, b) => a.label.length > b.label.length ? -1 : a.label.length < b.label.length ? 1 : 0)[0].label.length * 10 + 5 : 0;

    // give graph a fixed size per entry, then let container manage the scrolling
    const height = chartData?.length ? chartData.length * 36 - 2 : 0;

    const selectedTerminals = salesLeaderboardFilters.selectedTerminals.map(x => x as TerminalEnum);

    return (
      <div>
        <div className={styles.title}>Today's Sales Leaderboard</div>

        {chartData === undefined ? (
          <div className={styles.noData}>
            No sales today
          </div>
        ) : (
          <>
            <div className={styles.terminalSelectorContainer}>
              <SalesLeaderboardTerminalSelector
                selectedTerminals={selectedTerminals}
                onChange={this._onTerminalsChange}
              />
            </div>

            <div className={styles.chart}>
              <ResponsiveContainer height={height} width="100%">
                <BarChart
                  barSize={8}
                  data={chartData}
                  height={height}
                  layout="vertical"
                  margin={{ right: rightMargin }}
                >
                  <XAxis dataKey="total" type="number" hide={true} />
                  <YAxis
                    type="category"
                    orientation="right"
                    hide={true}
                  />
                  {selectedTerminals.map((terminalId, idx) => (
                    <React.Fragment key={idx}>
                      <Bar
                        dataKey={`terminals[${terminalId}]`}
                        fill={TERMINAL_MAP[terminalId]?.color ?? "black"}
                        stackId="1"
                        isAnimationActive={false} // workaround for intermittent label display issues w/ animation: https://github.com/recharts/recharts/issues/829
                      >
                        <LabelList content={(props) => getLabel(props, chartData, terminalId)} />
                      </Bar>
                    </React.Fragment>
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </div>
          </>
        )}
      </div>
    );
  }
}

export const SalesLeaderboard = SalesRepHomeService.inject(_SalesLeaderboard);
