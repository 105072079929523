import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  ISortState
} from "$Imports/CommonComponents";

import {
  EquipmentType,
  EquipmentApiFactory
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

interface IEquipmentTypeModalState {
  isOpen: boolean;
  equipmentType: EquipmentType;
  validationErrors: ValidationError | null;
}

interface IEquipmentTypeServiceState {
  equipmentTypeFetchResults: IAjaxState<EquipmentType[]>;
  activeEquipmentTypes: EquipmentType[];
  equipmentTypeSaveResults: IAjaxState<EquipmentType>;
  filterActive: boolean;
  sortState: ISortState;
  equipmentTypeModalState: IEquipmentTypeModalState;
}

const InjectedPropName = "equipmentTypeService";

const EquipmentTypeValidationSchema: SchemaOf<NullableOptional<EquipmentType>> = yup.object({
  id: yup.number().notRequired(),
  name: yup.string()
    .required("Name is required")
    .max(100, "Name cannot be longer than 100 characters"),
  tmEquipmentCode: yup.string()
    .required("Equipment code is required")
    .max(10, "Equipment code cannot be longer than 10 characters"),
  isActive: yup.boolean().notRequired(), // technically it is required but it's a boolean
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  equipmentTypeValues: yup.array().notRequired(),
  quotes: yup.array().notRequired(),
  customerQuotes: yup.array().notRequired()
});

const initialState = {
  equipmentTypeFetchResults: managedAjaxUtil.createInitialState(),
  activeEquipmentTypes: [],
  equipmentTypeSaveResults: managedAjaxUtil.createInitialState(),
  filterActive: true,
  sortState: {
    sortColumnName: "name",
    sortDirection: "asc",
  },
  equipmentTypeModalState: {
    isOpen: false,
    equipmentType: {},
    validationErrors: null
  }
} as IEquipmentTypeServiceState;

class EquipmentTypeFreezerService extends FreezerService<IEquipmentTypeServiceState, typeof InjectedPropName> {

  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchEquipmentTypes(forceUpdate: boolean = false) {
    const {
      equipmentTypeFetchResults,
      filterActive
    } = this.freezer.get();

    if (equipmentTypeFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "equipmentTypeFetchResults",
      params: {
        activeOnly: filterActive
      },
      onExecute: (apiOptions, params, options) => {
        const factory = EquipmentApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getEquipmentTypes(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch equipment types.");
      },
      onOk: (data: EquipmentType[]) => {
        var activeEquipmentTypes = data.filter(e => e.isActive);
        activeEquipmentTypes = _.sortBy(activeEquipmentTypes, e => e.name?.toLowerCase());
        this.freezer.get().set({ activeEquipmentTypes });
      }
    });
  }

  public setSortState(sortState: Partial<ISortState>) {
    this.freezer.get().sortState.set(sortState);
  }

  public setFilterActive(filterActive: boolean) {
    this.freezer.get().set({ filterActive });
    this.fetchEquipmentTypes(true);
  }

  public openEditModal(equipmentType?: EquipmentType | undefined) {
    let editEquipmentType: EquipmentType = { isActive: true };
    if (equipmentType) {
      editEquipmentType = _.cloneDeep(equipmentType);
    }
    this.freezer.get().set({
      equipmentTypeModalState: {
        isOpen: true,
        equipmentType: editEquipmentType,
        validationErrors: null
      }
    });
  }

  public closeEditModal() {
    this.freezer.get().set({
      equipmentTypeModalState: initialState.equipmentTypeModalState
    });
  }

  public onChangeFromModal(value: Partial<EquipmentType>) {
    this.freezer.get().equipmentTypeModalState.equipmentType.set(value);
  }

  public async saveEquipmentType() {
    const equipmentTypeModalState = this.freezer.get().equipmentTypeModalState;
    const equipmentType = equipmentTypeModalState.equipmentType.toJS();

    const errors = await validateSchema(EquipmentTypeValidationSchema, equipmentType, {
      abortEarly: false
    });

    equipmentTypeModalState.set({
      validationErrors: errors
    });

    if (errors) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "equipmentTypeSaveResults",
      params: {
        body: equipmentType
      },
      onExecute: (apiOptions, params, options) => {
        const factory = EquipmentApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);

        if (equipmentType.id) {
          return factory.updateEquipmentType(params);
        } else {
          return factory.addEquipmentType(params);
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to save equipment type.");
      },
      onOk: (data) => {
        this.closeEditModal();
        this.fetchEquipmentTypes(true);
      }
    });
  }
}

export const EquipmentTypeService = new EquipmentTypeFreezerService();
export type IEquipmentTypeServiceInjectedProps = ReturnType<EquipmentTypeFreezerService["getPropsForInjection"]>;
