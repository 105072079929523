import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  CardActions,
  SelectChangeEvent,
  FormHelperText
} from "$Imports/MaterialUIComponents";

import {
  AdvanceTextField,
  CardLinedHeader
} from "$Imports/CommonComponents";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps,
  OtherFreightInfo
} from "$State/QuoteEntryFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  QuoteStop
} from "$Generated/api";

import { ErrorService } from "$State/ErrorFreezerService";

interface IOtherInformationCardBaseProps {
  viewOnly: boolean;
  quoteStop: QuoteStop | undefined;
  onChange: (data: Partial<QuoteStop>) => void;
}

interface IOwnState {
  disableSave: boolean
}

type IOtherInformationCardProps = IOtherInformationCardBaseProps & IQuoteEntryServiceInjectedProps;

const styles: {
  otherInfoCard: string;
  selects: string;
  errorSelects: string;
} = require('./OtherInformationCard.scss');

class _OtherInformationCard extends React.Component<IOtherInformationCardProps, IOwnState> {
  state: IOwnState = {
    disableSave: false
  };

  @bind
  private _onSiteChange(event: SelectChangeEvent) {
    this.props.onChange({ siteId: event.target.value as string });
    this.setState({ disableSave: false });
  }

  @bind
  private _onOpsCodeChange(event: SelectChangeEvent) {
    this.props.onChange({ opsCode: event.target.value as string });
    this.setState({ disableSave: false });
  }

  @bind
  private _onPoChange(poNumber: string) {
    this.props.onChange({ ponumber: poNumber });
    this.setState({ disableSave: poNumber === "" });
  }

  @bind
  private _onExternalNotesChange(externalNotes: string) {
    this.props.onChange({ externalNotes: externalNotes });
    this.setState({ disableSave: false });
  }

  @bind
  private _onPoPaste(e: React.ClipboardEvent) {
    const text = e.clipboardData.getData("text");
    if (text.length > 40) {
      ErrorService.pushErrorMessage("PO Number exceeds 40 characters");
    }
  }

  @bind
  private _onExternalNotesPaste(e: React.ClipboardEvent) {
    const text = e.clipboardData.getData("text");
    if (text.length > 500) {
      ErrorService.pushErrorMessage("External Notes exceeds 500 characters");
    }
  }

  @bind
  private async _onEditSave() {
    const { quote } = this.props.QuoteEntryService.getState();

    //If the save is before the quote is rated, just wait until
    //the initial rate/save to save the other info, else save it
    //seperately
    const delaySave = !quote.id || _.some(quote.quoteStops, qs => !qs.id);
    this.setState({ disableSave: delaySave });
    
    if (!delaySave) {
      await this.props.QuoteEntryService.saveOtherFreightInfo();
      this.props.QuoteEntryService.fetchDataChangeLogs(quote.id);
    }
  }

  render() {
    const {
      viewOnly,
      quoteStop
    } = this.props;

    const {
      disableSave
    } = this.state;

    const {
      sites,
      quote,
      opsCodes,
      otherFreightInfoValidationErrors,
      editMode,
      hasOtherInformationChanged
    } = this.props.QuoteEntryService.getState();

    const validationParserOtherInfo = new ValidationErrorParser<OtherFreightInfo>(otherFreightInfoValidationErrors);

    const canEditOtherInfo: boolean = quote.status === "Pending" || quote.status === "ApprovalNeeded" || quote.status === "PendingNeedsCustomers" || quote.status === "InProgress" || quote.status === "Convert"
      || editMode || quote.status === undefined;
    const disableOtherInfo = !canEditOtherInfo || viewOnly;

    return (
      <CardLinedHeader
        className={styles.otherInfoCard}
        titleText="Other Information"
      >
        <div>
          <FormControl className={(quote.status && !quoteStop?.siteId) ? styles.errorSelects : styles.selects}>
            <InputLabel>Site</InputLabel>
            <Select
              value={quoteStop?.siteId ?? ""}
              disabled={disableOtherInfo}
              onChange={this._onSiteChange}
              error={quote.status && !quoteStop?.siteId}
            >
              {
                _.map(sites, (s, idx) => {
                  return <MenuItem value={s.siteId} key={idx}>
                    {s.siteId}
                  </MenuItem>
                })
              }
            </Select>
            {(quote.status && !quoteStop?.siteId) && <FormHelperText style={{ color: "#f31111" }}>Site is required</FormHelperText>}
          </FormControl>
          <FormControl className={(quote.status && !quoteStop?.opsCode) ? styles.errorSelects : styles.selects}>
            <InputLabel>Ops Code</InputLabel>
            <Select
              value={quoteStop?.opsCode ?? ""}
              disabled={disableOtherInfo}
              onChange={this._onOpsCodeChange}
              error={quote.status && !quoteStop?.opsCode}
            >
              {
                _.map(opsCodes, (o, idx) => {
                  return <MenuItem value={o.opsCode} key={idx}>
                    {o.opsCode}
                  </MenuItem>
                })
              }
            </Select>
            {(quote.status && !quoteStop?.opsCode) && <FormHelperText style={{ color: "#f31111" }}>Ops Code is required</FormHelperText>}
          </FormControl>
        </div>
        <div>
          <AdvanceTextField
            value={quoteStop?.ponumber ?? ""}
            onDebouncedChange={this._onPoChange}
            onPaste={(event) => this._onPoPaste(event)}
            disabled={disableOtherInfo}
            label={"PO Number"}
            inputProps={{ maxLength: 40 }}
            error={!validationParserOtherInfo.isValid('poNumber') || (quote.status && !quoteStop?.ponumber)}
            helperText={!validationParserOtherInfo.isValid('poNumber') || (quote.status && !quoteStop?.ponumber) ? "PO Number is required" : ""}
          />
        </div>
        <div>
          <AdvanceTextField
            value={quoteStop?.externalNotes ?? ""}
            onDebouncedChange={this._onExternalNotesChange}
            onPaste={(event) => this._onExternalNotesPaste(event)}
            disabled={disableOtherInfo}
            label={"External Notes"}
            error={!validationParserOtherInfo.isValid('externalNotes')}
            helperText={validationParserOtherInfo.validationMessage('externalNotes')}
            multiline
            inputProps={{ maxLength: 500 }}
            fullWidth
          />
        </div>
        <CardActions>
          {quote.calculatedRates &&
            <Button
              color="primary"
              disabled={disableOtherInfo || !hasOtherInformationChanged || disableSave}
              onClick={this._onEditSave}
            >
              Save
            </Button>}
        </CardActions>
      </CardLinedHeader>
    );
  }
}

export const OtherInformationCard = QuoteEntryService.inject(_OtherInformationCard);