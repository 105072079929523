import {
  React,
  _
} from "$Imports/Imports";

import {
  SelectedCompanyConsumer,
  ThemeConsumer
} from "$Providers/index";

import {
  INavigationServiceInjectedProps,
  NavigationService
} from "$State/NavigationFreezerService";

import {
  SecurityContext
} from "$Utilities/Security/ApplicationSecuritySettings";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  NoCompanyAccessView
} from "./NoCompanyAccessView";

interface IMainLayoutBaseProps {
  applicationBar: JSX.Element;
  sideNavigation: JSX.Element;
}

type IMainLayoutProps = IMainLayoutBaseProps & INavigationServiceInjectedProps;

const styles: {
  applicationContainer: string;
  subContainer: string;
  mainContent: string;
  loading: string;
} = require("./MainLayout.scss");

class _MainLayout extends React.Component<IMainLayoutProps> {
  private async _onLogout() {   
    await SharedSecurityContext.logout();
  }

  render() {
    let sideNavigationItems = this.props.navigationService.getSideNavigationItems();
    const navigationItem = this.props.navigationService.getNavigationDetail();

    return (
      <ThemeConsumer>
        {(context) => {
          const theme = context.themeConfig;
          const user = SharedSecurityContext.getUser();

          if (!user) {
            this._onLogout();
          }

          if (!!user && SecurityContext.getCompanies(user).length === 0) {
            return <NoCompanyAccessView />;
          }

          return <SelectedCompanyConsumer>
            {(context) => 
              <div
                className={styles.applicationContainer}
              >
                {!context.companyId  // wait for company context to prevent downstream race condition(s)
                  ? <div className={styles.loading}>Loading...</div> 
                  : <>
                    {!navigationItem?.hideMainLayout &&
                      <div>
                        {this.props.applicationBar}
                      </div>
                    }
                    <div
                      className={styles.subContainer}
                    >
                      {!navigationItem?.hideMainLayout &&
                        <div
                          style={{
                            backgroundColor: theme.sideNavigationColor
                          }}
                          hidden={!sideNavigationItems || sideNavigationItems.length === 1 && sideNavigationItems[0].label ==='Home'} // Hide side nav menu if only one item and item.label = Home.
                        >
                          {this.props.sideNavigation}
                        </div>
                      }
                      <div
                        className={styles.mainContent}
                      >
                        {this.props.children}
                      </div>
                    </div>
                  </>
                }
              </div>
            }
          </SelectedCompanyConsumer>
        }}
      </ThemeConsumer>
    );
  }
}

export const MainLayout = NavigationService.inject(
  _MainLayout,
);

