import {
  React,
  Helpers,
  bind,
  _
} from "$Imports/Imports";

import {
  RateModel,
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell
} from "$Imports/MaterialUIComponents";

import {
  formModeType
} from "$State/RateModelFreezerService";

import {
  AjaxActionIndicator,
  DisplayFormattedNumber,
  PercentageInputField,
  ConfirmCancelModal
} from "$Imports/CommonComponents";

const styles: {
  container: string;
} = require("./EditRateModelSandboxRates.scss");


import { 
  ThemeOptions, 
  ThemeProvider, 
  createTheme 
} from "$Imports/MaterialUIStyles";

const tableCellTheme: ThemeOptions = {
  components: {
    MuiTableCell: {
      styleOverrides: {

        head: {
          borderBottom: "none"
        },
        body: {
          borderBottom: "none"
        }
      }
    }
  }
}

interface IEditRateModelSandboxRatesProps {
  formMode: formModeType;
  rateModelData: RateModel[] | null;
  dataChanged: () => any;
  isFetching?: boolean;
  onSave: () => void;
  onCancelClick: () => void;
  onRateModelsChanged: (index: number, rateModels: Partial<RateModel>) => void;
  onCancelYes: () => void;
  onCancelNo: () => void;
  cancelOpen: boolean;
  invalidForm: boolean;
  isRateInvalid: (rate: RateModel) => boolean;
  invalidRateMessage: string;
}

export class EditRateModelSandboxRates extends React.PureComponent<IEditRateModelSandboxRatesProps> {

  static defaultProps: Partial<IEditRateModelSandboxRatesProps> = {
    isFetching: false,
  };

  @bind
  private _onRateChange(index: number, value?: number) {
    this.props.onRateModelsChanged(index, { sandboxRate: value });
  }

  @bind
  private rateRequired(): boolean {
    const rateModels = this.props.rateModelData;
    if (rateModels !== null) {
      let i = 0;
      for (i = 0; i < rateModels.length; i++) {
        if (rateModels[i].sandboxRate !== undefined && rateModels[i].sandboxRate !== null) {
          return true;
        }
      }
    }
    return false;
  }

  @bind
  private clearSandBox() {
    const rateModels = this.props.rateModelData;
    if (rateModels !== null) {
      let i = 0;
      for (i = 0; i < rateModels.length; i++) {
        this.props.onRateModelsChanged(i, { sandboxRate: undefined });
      }
    }
  }

  @bind
  private setSandBoxToDefault() {
    const rateModels = this.props.rateModelData;
    if (rateModels !== null) {
      let i = 0;
      for (i = 0; i < rateModels.length; i++) {
        this.props.onRateModelsChanged(i, { sandboxRate: rateModels[i].defaultRate });
      }
    }
  }

  render() {
    const {
      formMode,
      rateModelData,
      isFetching,
      invalidForm,
      invalidRateMessage,
      isRateInvalid
    } = this.props;

    return !Helpers.isNullOrUndefined(rateModelData) ? (
      <>
        <Dialog
          open={formMode === "edit sandbox"}
          maxWidth={"md"}
        >
          <AjaxActionIndicator
            showProgress={isFetching}
          />
          <DialogTitle>
            Edit Sandbox
          </DialogTitle>
          <DialogContent className={styles.container}>
          <ThemeProvider 
              theme={(theme) => createTheme(
                tableCellTheme,
                theme
              )}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Table>
                        <TableHead>
                          <TableRow >
                            <TableCell >
                              Linear Foot
                            </TableCell>
                            <TableCell>
                              Kaiser Default TSS %
                            </TableCell>
                            <TableCell>
                              Active Kaiser TSS %
                            </TableCell>
                            <TableCell>
                              Sandbox
                            </TableCell>
                            <TableCell >
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {_.map(rateModelData, (d: RateModel, dIdx: number) => (
                            <TableRow key={dIdx}>
                              <TableCell >
                                {d.feet ? `${d.feet} feet ` : ""}
                              </TableCell>
                              <TableCell >
                                <DisplayFormattedNumber value={d.defaultRate} formatString={"0.0%"} />
                              </TableCell>
                              <TableCell >
                                <DisplayFormattedNumber value={d.activeRate} formatString={"0.0%"} />
                              </TableCell>
                              <TableCell>
                                <PercentageInputField
                                  required={this.rateRequired()}
                                  error={invalidForm || isRateInvalid(d)}
                                  helperText={(invalidForm || isRateInvalid(d)) && invalidRateMessage}
                                  decimalScale={1}
                                  value={d.sandboxRate}
                                  onPercentValueChange={(value?: number) => this._onRateChange(dIdx, value)}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableCell>
                    <TableCell>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Button
                                color="primary"
                                onClick={this.clearSandBox}
                                disabled={isFetching}
                              >
                                Clear Sandbox
                                </Button>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Button
                                 color="primary"
                                onClick={this.setSandBoxToDefault}
                                disabled={isFetching}
                              >
                                Set Sandbox To Default
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </ThemeProvider>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.onCancelClick}
              disabled={isFetching}
              variant="outlined"
            >
              Cancel
          </Button>
            <Button
               color="primary"
              disabled={isFetching}
              onClick={this.props.onSave}
            >
              Save
          </Button>
          </DialogActions>
        </Dialog >
        <ConfirmCancelModal
          isOpen={this.props.cancelOpen}
          onCancelNo={this.props.onCancelNo}
          onCancelYes={this.props.onCancelYes}
        />
      </>
    ) : null;
  }
}
