import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  moment,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf
} from "$Shared/imports/Yup";

import {
  AuditLogApiFactory,
  AuditRateEngine,
  RateEngineAuditSearchCriteria
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

export const RateEngineAuditSearchValidationSchema: SchemaOf<NullableOptional<RateEngineAuditSearchCriteria>> = yup.object({
  startDate: yup.date().typeError("Invalid date").notRequired(),
  endDate: yup.date().typeError("Invalid date").notRequired()
    .test("endDate", "${message}", (value: Date | undefined, testContext: any) => {
      const startDate = moment(testContext.parent.startDate, 'day');
      const endDate = moment(value);

      if (endDate.isBefore(startDate)) {
        return testContext.createError({ message: "To must be after From" });
      }

      return true;
    }),
  employeeIds: yup.array().notRequired(),
  eventTypes: yup.array().notRequired(),
  elementOrChange: yup.string().notRequired().max(500, "Search text cannot be longer than 500 characters"),
  sortColumn: yup.string().notRequired(),
  sortAscending: yup.boolean().notRequired(),
  startIndex: yup.number().notRequired(),
  pageSize: yup.number().notRequired()
});

interface IAuditRateEngineServiceState {
  auditFetchResults: IAjaxState<AuditRateEngine[]>;
  eventTypesFetchResults: IAjaxState<string[]>;
}

const InjectedPropName = "auditRateEngineService";

const initialState = {
  auditFetchResults: managedAjaxUtil.createInitialState(),
  eventTypesFetchResults: managedAjaxUtil.createInitialState()
} as IAuditRateEngineServiceState;

class AuditRateEngineFreezerService extends FreezerService<IAuditRateEngineServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchEventTypes() {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "eventTypesFetchResults",
      params: {},
      onExecute: (apiOptions, params, options) => {
        const factory = AuditLogApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getEventTypes(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch rate engine audit event types");
      }
    });
  }

  public fetchAuditLogs(criteria: RateEngineAuditSearchCriteria) {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "auditFetchResults",
      params: {
        body: criteria
      },
      onExecute: (apiOptions, params, options) => {
        const factory = AuditLogApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getRateEngineAuditLogs(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch rate engine audit logs");
      }
    });
  }
}

export const AuditRateEngineService = new AuditRateEngineFreezerService();
export type IAuditRateEngineServiceInjectedProps = ReturnType<AuditRateEngineFreezerService["getPropsForInjection"]>;
