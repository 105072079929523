import {
  React,
  bind,
  NumberFormat,
  Helpers,
  NumberFormatValues,
  moment,
  _
} from "$Imports/Imports";

import {
  Tarp,
  TarpValue
} from "$Generated/api";

import {
  AdvanceTextField,
  AjaxActionIndicator,
  DateTimeInputField
} from "$Imports/CommonComponents";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControlLabel,
  Switch,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Checkbox,
  TextField
} from "$Imports/MaterialUIComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import { CompanyEnum } from "$Utilities/companyUtil";

import {
  TarpValidationSchema,
  futureStartTarpRateValidationSchema,
  startNowTarpRateValidationSchema
} from "$State/TarpValidationSchema";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  ValidationError
} from "$Shared/imports/Yup";
import { FormModeType } from "./TarpViewPage";

const styles: {
  modalContainer: string;
} = require("$Shared/styles/Modal.scss");

interface IAddEditTarpProps {
  openedAt: Date | undefined,
  formMode: FormModeType,
  tarp: Tarp | undefined,
  tarpValue: Tarp | undefined,
  tarps: Tarp[];
  isFetching: boolean;
  canSave: boolean;
  onSave: (tarp: Tarp, tarpValue: TarpValue | undefined) => void;
  onCancel: () => void;
}

interface AddEditTarpModalState {
  futureStart: boolean;
  editAddTarp: Tarp | undefined;
  editAddTarpValue: TarpValue | undefined;
  tarpValidationErrors: ValidationError | null;
  tarpValueValidationErrors: ValidationError | null;
}

export class AddEditTarpModal extends React.PureComponent<IAddEditTarpProps, AddEditTarpModalState> {
  state: AddEditTarpModalState = {
    futureStart: false,
    editAddTarp: undefined,
    editAddTarpValue: undefined,
    tarpValidationErrors: null,
    tarpValueValidationErrors : null
  }

  componentDidUpdate(prev: IAddEditTarpProps) {
    if (this.props.formMode !== prev.formMode) {
      this.setState({
        editAddTarp: this.props.tarp,
        editAddTarpValue: this.props.tarpValue,
        futureStart: false
      });
    }
  }

  @bind
  private async _onValidateTarp(): Promise<boolean> {
    const { editAddTarp } = this.state;
    const tarpValidationErrors = await validateSchema(TarpValidationSchema, editAddTarp, {
      context: {
        tarps: this.props.tarps,
        tarpId: editAddTarp?.id
      },
      abortEarly: false
    });
    this.setState({
      tarpValidationErrors: tarpValidationErrors
    });
    if (tarpValidationErrors) {
      return true;
    }
    return false;
  }

  @bind
  private async _onValidateTarpValue(): Promise<boolean> {
    const { futureStart, editAddTarpValue } = this.state;
    const schema = futureStart ? futureStartTarpRateValidationSchema : startNowTarpRateValidationSchema;
    const tarpRateValidationErrors = await validateSchema(schema, editAddTarpValue, {
      context: {
        openedAt: this.props.openedAt,
      },
      abortEarly: false
    });
    this.setState({
      tarpValueValidationErrors: tarpRateValidationErrors
    });

    if (tarpRateValidationErrors) {
      return true;
    }
    return false;
  }

  @bind
  private async _onSave() {
    const { formMode } = this.props;
    const {
      editAddTarp,
      editAddTarpValue
    } = this.state;

    if(!editAddTarp || (formMode === "add" && !editAddTarpValue)) {
      return;
    }

    if (formMode === "add" && editAddTarpValue) {
      editAddTarp.tarpValues = [editAddTarpValue];
    }
    const hasTarpErrors = await this._onValidateTarp();
    const hasTarpValueErrors = await this._onValidateTarpValue();

    if (hasTarpErrors || hasTarpValueErrors) {
      return;
    }

    this.props.onSave(editAddTarp, editAddTarpValue);
  }

  @bind
  private _onCancel() {
    this.props.onCancel();
  }

  @bind
  private _onTarpChange(name: string, value: any) {
    this.setState((prev) => ({
      editAddTarp: {
        ...prev.editAddTarp,
        [name]: value
      }
    }));
  }

  @bind
  private _onTarpValueChange(name: string, value: any) {
    this.setState((prev) => ({
      editAddTarpValue: {
        ...prev.editAddTarpValue,
        [name]: value
      }
    }));
  }

  @bind
  private _onSelectCompany(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value === "0" ? null : event.target.value;
    this._onTarpChange(event.target.name, value);
  }
  
  @bind
  private _onTextInputTarpChange(e: React.ChangeEvent<HTMLInputElement>): void {
    this._onTarpChange(e.target.name, e.target.value !== "" ? e.target.value : undefined);
  }

  @bind
  private _onTarpIsActiveChange(e: React.ChangeEvent<{ checked: boolean }>) {
    this._onTarpChange("isActive", e.target.checked);
    this.setState({ futureStart: e.target.checked });
  }

  @bind
  private _onFlatRateChange(value: NumberFormatValues) {
    this._onTarpValueChange("rate", !Helpers.isNullOrUndefined(value.floatValue) ? value.floatValue : undefined);
  }

  @bind
  private _onStartDateChange(date: Date | undefined) {
    this._onTarpValueChange("startDateTime", date);
  }

  @bind
  private _onFutureStartChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.setState({ futureStart: checked });
  }

  render() {
    const {
      formMode,
      isFetching
    } = this.props;

    const {
      futureStart,
      editAddTarp,
      editAddTarpValue,
      tarpValidationErrors,
      tarpValueValidationErrors
    } = this.state;

    const tarpValidationsParser = new ValidationErrorParser<Tarp>(tarpValidationErrors);
    const rateValidationsParser = new ValidationErrorParser<TarpValue>(tarpValueValidationErrors);
    const isTarpActive = editAddTarp?.isActive ?? true;

    return (
      <Dialog
        open={formMode === "add" || formMode === "edit"}
        fullWidth={true}
        maxWidth="xs"
      >
      <AjaxActionIndicator
        showProgress={isFetching}
      />
        <DialogTitle>
        {formMode === "add" ? "Add Tarp" : "Edit Tarp"}
        </DialogTitle>
        <DialogContent
          className={styles.modalContainer}
        >
          {formMode === "edit" &&
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={editAddTarp?.isActive ?? false}
                    onChange={this._onTarpIsActiveChange}
                  />
                )
              }
              label="Active"
            />
          }
          <AdvanceTextField
            label="Name"
            required={formMode === "add"}
            name="tarpName"
            value={editAddTarp?.tarpName ?? ""}
            onChange={this._onTextInputTarpChange}
            type="text"
            error={!tarpValidationsParser.isValid("tarpName")}
            helperText={tarpValidationsParser.validationMessage("tarpName")}
            fullWidth
          />
          <AdvanceTextField
            label="Description"
            name="tarpDescription"
            value={editAddTarp?.tarpDescription ?? ""}
            onChange={this._onTextInputTarpChange}
            type="text"
            error={!tarpValidationsParser.isValid("tarpDescription")}
            helperText={tarpValidationsParser.validationMessage("tarpDescription")}
            fullWidth
            style={{ marginTop: "0.25rem" }}
          />
          <FormControl style={{ marginTop: "0.5rem" }}>
            <FormLabel>
              Company
            </FormLabel>
            <RadioGroup row name="companyId" value={editAddTarp?.companyId?.toString() ?? 0} onChange={this._onSelectCompany}>
              <FormControlLabel value={0} control={<Radio />} label="Both" />
              <FormControlLabel value={CompanyEnum.Logistics} control={<Radio />} label="Logistics" />
              <FormControlLabel value={CompanyEnum.Transport} control={<Radio />} label="Transport" />
            </RadioGroup>
          </FormControl>
          {formMode == "add" &&
            <>
              <NumberFormat
                required
                label="Initial Rate"
                name="flatRate"
                value={editAddTarpValue?.rate ?? ""}
                error={!rateValidationsParser.isValid("rate")}
                helperText={rateValidationsParser.validationMessage("rate")}
                prefix={"$"}
                thousandSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                allowNegative={false}
                onValueChange={this._onFlatRateChange}
                customInput={TextField}
                disabled={!isTarpActive}
              />
              <FormControlLabel
                label="Future start date"
                control={(
                  <Checkbox
                    checked={futureStart}
                    onChange={this._onFutureStartChange}
                    name="futureStart"
                    disabled={!isTarpActive}
                  />
                )}
              />
              <DateTimeInputField
                required
                disablePast
                dateLabel="Start Date Time"
                disabled={!futureStart || !isTarpActive}
                value={editAddTarpValue?.startDateTime ?? null}
                onChange={this._onStartDateChange}
                error={!rateValidationsParser.isValid("startDateTime")}
                helperText={rateValidationsParser.validationMessage("startDateTime")}
              />
            </>
          }
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._onCancel}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this._onSave}
            disabled={isFetching}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}