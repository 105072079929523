import {
  React,
  bind
} from "$Imports/Imports";

import {
  AuditCustomerAuditLog,
  FieldChange
} from "$Generated/api";

import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  Button
} from "$Imports/MaterialUIComponents";

import {
  ArrowBack
} from "$Imports/MaterialUIIcons";

import {
  DisplayFormattedDatetime,
  TextCellTruncated,
  QuoteLink
} from "$Imports/CommonComponents";

import {
  DATE_WITH_TIME_MERIDIAN_FORMAT
} from "$Shared/utilities/formatUtil";

interface ILogDetailsProps {
  auditLog: AuditCustomerAuditLog;
  closeDetails: () => void;
}

export class LogDetails extends React.PureComponent<ILogDetailsProps> {

  @bind
  private closeDetails() {
    this.props.closeDetails();
  }

  @bind
  private buildColumns(): GridColDef[] {
    const {
      auditLog
    } = this.props;

    const recordColumn: GridColDef[] = [
      {
        headerName: "Record",
        field: "record",
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, FieldChange>) => {
          if (auditLog.log?.element === "Demographics") {
            return "";
          }
          else if (auditLog.log?.element === "Contacts") {
            return auditLog.log?.recordName;
          }
          else if (auditLog.log?.element === "Quotes") {
            return <QuoteLink quoteId={auditLog.log?.recordId} quoteNumber={`Q${auditLog.log?.recordName}`} />;
          }
          else if (auditLog.log?.element === "Notes") {
            return auditLog.log?.recordId;
          }
          else if (auditLog.log?.element === "Sales Opportunities") {
            return auditLog.log?.recordId;
          }

          return "";
        }
      }
    ];
    const dataFieldColumn: GridColDef[] = [
      {
        headerName: "Data Field",
        field: "dataField",
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, FieldChange>) => {
          return params.row.dataField;
        }
      }
    ];
    const valueColumns: GridColDef[] = [
      {
        headerName: "Previous Value",
        field: "previousValue",
        flex: 2,
        sortable: false,
        renderCell: (params: GridRenderCellParams<string, FieldChange>) =>
          params.value && <TextCellTruncated text={params.value} />
      },
      {
        headerName: "New Value",
        field: "newValue",
        flex: 2,
        sortable: false,
        renderCell: (params: GridRenderCellParams<string, FieldChange>) =>
          params.value && <TextCellTruncated text={params.value} />
      }
    ];

    var displayColumns: GridColDef[] = [];
    // Demographics: don't show Record
    if (auditLog.log?.element === "Demographics") {
      displayColumns = displayColumns.concat(dataFieldColumn, valueColumns);
    }
    // Notes: don't show Data Field
    else if (auditLog.log?.element === "Notes") {
      displayColumns = displayColumns.concat(recordColumn, valueColumns);
    }
    else {
      displayColumns = displayColumns.concat(recordColumn, dataFieldColumn, valueColumns);
    }

    return displayColumns;
  }

  render() {
    const {
      auditLog
    } = this.props;

    const fieldChanges = auditLog.log?.fieldChanges ?? [];
    const detailColumns = this.buildColumns();

    var rowIdCounter = 0;

    return (
      <div>
        <div style={{ fontStyle: "italic", paddingBottom: "6px" }}>
          {`${auditLog.log?.element} edit details for change by ${auditLog.employee?.firstName} ${auditLog.employee?.lastName} on `}
          <DisplayFormattedDatetime value={auditLog.createdOn} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} />
        </div>
        <div style={{ height: "150px" }}>
          <DataGridPro
            rows={fieldChanges}
            columns={detailColumns}
            density="compact"
            hideFooter
            disableSelectionOnClick
            getRowId={(row) => {
              rowIdCounter++;
              return rowIdCounter;
            }}
          />
        </div>
        <Button
          color="secondary"
          variant="text"
          startIcon={<ArrowBack />}
          onClick={this.closeDetails}
          style={{ textTransform: "none", marginTop: "6px" }}
        >
          Return to full activity log
        </Button>
      </div>
    );
  }
}