import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  DeclaredValueApproval
} from "$Generated/api";

import {
  Card,
  CardActions,
  CardHeader
} from "$Imports/MaterialUIComponents";

import {
  DataTable,
  IDataTableColumn,
  DisplayFormattedNumber,
  AjaxActionIndicator,
  directionType,
  DisplayFormattedDatetime
} from "$Imports/CommonComponents";

import {
  IDeclaredValueServiceInjectedProps,
  DeclaredValueService
} from "$State/DeclaredValueFreezerService";

import {
  ActionMenu
} from "./ActionMenu";

import {
  EditDeclaredValue
} from "./EditDeclaredValue";

import { DATE_WITH_TIME_MERIDIAN_FORMAT } from "$Shared/utilities/formatUtil";

const styles: {
  mainContainer: string;
  actionArea: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface IDeclaredValueConfigViewPageBaseProps {

}

type IDeclaredValueConfigViewPageProps = IDeclaredValueConfigViewPageBaseProps
  & IDeclaredValueServiceInjectedProps;

class _DeclaredValueConfigViewPage extends React.Component<IDeclaredValueConfigViewPageProps> {

  private readonly columns: Array<IDataTableColumn<DeclaredValueApproval>> = [
    {
      columnName: "company",
      columnFieldData: (x) => x.company ? x.company.companyName : "",
      sortMethod: (x) => x.company ? x.company.companyName : "",
      headerValue: "Company"
    },
    {
      columnName: "declaredValue",
      columnFieldData: (x) => <DisplayFormattedNumber value={x.declaredValue} formatString={"$0,0"} />,
      sortMethod: (x) => x.declaredValue,
      headerValue: "Declared Value"
    },
    {
      columnName: "startDate",
      columnFieldData: (x) => <DisplayFormattedDatetime value={x.startDateTime} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />,
      sortMethod: (x) => x.startDateTime,
      headerValue: "Start Date"
    },
    {
      columnName: "endDate",
      columnFieldData: (x) => <DisplayFormattedDatetime value={x.endDateTime} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />,
      sortMethod: (x) => x.endDateTime,
      headerValue: "End Date"
    },
    {
      columnName: "createdBy",
      columnFieldData: (x) => x.createdBy ? x.createdBy.firstName + " " + x.createdBy.lastName : "",
      sortMethod: (x) => x.createdBy ? x.createdBy.firstName + " " + x.createdBy.lastName : "",
      headerValue: "Created By"
    },
    {
      columnName: "action",
      columnFieldData: (x) => (
        <ActionMenu
          declaredValue={x}
          onEdit={this._onEdit}
        />
      ),
      headerValue: "",
    }
  ];

  componentDidMount() {
    this.props.declaredValueService.fetchData();
  }

  @bind
  private _onSortChange(event: React.MouseEvent<HTMLElement>, columnName: string | undefined, direction: directionType) {
    this.props.declaredValueService.setSortState({
      sortColumnName: columnName,
      sortDirection: direction,
    });
  }

  @bind
  private _onEdit(declaredValue: DeclaredValueApproval) {
    this.props.declaredValueService.openEditModal(declaredValue);
  }

  @bind
  private _closeModal() {
    this.props.declaredValueService.closeEditModal();
  }

  @bind
  private _onSave() {
    this.props.declaredValueService.saveDeclaredValue();
  }

  @bind
  private _onChange(value: Partial<DeclaredValueApproval>) {
    this.props.declaredValueService.onChangeFromModal(value);
  }

  render() {
    const {
      declaredValueFetchResults,
      sortState,
      declaredValueModalState
    } = this.props.declaredValueService.getState();
    const declaredValueData = declaredValueFetchResults.data ?? [] as DeclaredValueApproval[];

    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader
            title="Declared Value Approval"
          />
          <CardActions
            disableSpacing={true}
            className={styles.actionArea}
          >
            
          </CardActions>
          <AjaxActionIndicator
            state={[declaredValueFetchResults]}
          />
          <DataTable
            columns={this.columns}
            data={declaredValueData}
            defaultSortColumnName={sortState.sortColumnName}
            defaultSortDirection={sortState.sortDirection}
            onSortChange={this._onSortChange}
          />
        </Card>
        <EditDeclaredValue
          isOpen={declaredValueModalState.isOpen}
          newRecord={declaredValueModalState.newRecord}
          validationErrors={declaredValueModalState.validationErrors}
          canSave={!declaredValueFetchResults.isFetching}
          onSave={this._onSave}
          onCancel={this._closeModal}
          onChange={this._onChange}
        />
      </div>
    );
  }
}

export const DeclaredValueConfigViewPage = DeclaredValueService.inject(
  _DeclaredValueConfigViewPage
);
