import {
  React,
  bind
} from "$Imports/Imports";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  Clear
} from "$Imports/MaterialUIIcons";

interface IClearButtonBaseProps {
  onClick?: () => void;
  viewOnly?: boolean
}

const styles: {
  clearButton: string,
  clearIcon: string
} = require("./ClearButton.scss");

export class ClearButton extends React.PureComponent<IClearButtonBaseProps> {

  @bind
  private _onClick() {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  render() {

    const {
      viewOnly
    } = this.props;

    return (
      <IconButton
        size="small"
        onClick={this._onClick}
        className={styles.clearButton}
        disabled={viewOnly}
      >
        <Clear className={styles.clearIcon} />
      </IconButton>
    );
  }

}