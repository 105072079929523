import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  AuditDataChange,
  AuditDataChangeAuditLog,
  QuoteStop
} from "$Generated/api";

import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  Button
} from "$Imports/MaterialUIComponents";

import {
  ArrowBack
} from "$Imports/MaterialUIIcons";

import {
  DisplayFormattedDatetime,
  DisplayFormattedNumber,
  TextCellTruncated
} from "$Imports/CommonComponents";

import {
  CURRENCY_FORMAT,
  DATE_WITH_TIME_MERIDIAN_FORMAT
} from "$Shared/utilities/formatUtil";

interface IEditHistoryDetailsProps {
  quoteStops: QuoteStop[];
  auditLog: AuditDataChangeAuditLog;
  closeDetails: () => void;
}

export class EditHistoryDetails extends React.PureComponent<IEditHistoryDetailsProps> {

  private readonly detailColumns: GridColDef[] = [
    {
      headerName: "Field",
      field: "propertyName",
      minWidth: 175,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, AuditDataChange>) => {
        if (params.row.propertyName === "Address" && params.row.message) {
          const field = params.row.message.split("~")[0];
          return field;
        }
        if (params.row.propertyName === "Acc Chg") {
          const field = params.row.message ?? params.row.newPropertyValue?.split("~")[0] ?? params.row.propertyName;
          return field;
        }
        if (params.row.tableName === 'QuoteStop') {
          const quoteStopId = _.find(params.row.relatedEntities, e => e.entityType === 'QuoteStop')?.identifier;
          const stopNumber = _.find(this.props.quoteStops, s => s.id === quoteStopId)?.stopNumber;
          return this.props.quoteStops.length > 1 && stopNumber ? `${params.row.propertyName} (Stop ${stopNumber})` : params.row.propertyName;
        }
        return params.row.propertyName;
      }
    },
    {
      headerName: "Previous Value",
      field: "oldPropertyValue",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string, AuditDataChange>) => {
        if (params.row.propertyName === "Address" && params.row.message) {
          const previousValue = params.row.message.split("~")[1];
          return previousValue;
        }
        if (params.row.propertyName === "Acc Chg") {
          const oldValue = params.row.oldPropertyValue?.split("~")[1] ?? params.row.oldPropertyValue ?? "";
          const oldNumber = Number(oldValue);
          if (!Number.isFinite(oldNumber)) {
            return <TextCellTruncated text={oldValue} />;
          }
          return <DisplayFormattedNumber prefix="Charge for " value={oldNumber} formatString={CURRENCY_FORMAT} postfix=" present" emptyDisplay={oldValue} />;
        }
        return params.value && <TextCellTruncated text={params.value} />;
      }
    },
    {
      headerName: "New Value",
      field: "newPropertyValue",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string, AuditDataChange>) => {
        if (params.row.propertyName === "Address" && params.row.message) {
          const newValue = params.row.message.split("~")[2];
          return newValue;
        }
        if (params.row.propertyName === "Acc Chg") {
          const newValue = params.row.newPropertyValue?.split("~")[1] ?? params.row.newPropertyValue ?? "";
          const newNumber = Number(newValue);
          if (!Number.isFinite(newNumber)) {
            return <TextCellTruncated text={newValue} />;
          }
          return <DisplayFormattedNumber prefix="Charge for " value={newNumber} formatString={CURRENCY_FORMAT} postfix=" added" emptyDisplay={newValue} />;
        }
        return params.value && <TextCellTruncated text={params.value} />;
      }
    }
  ];

  @bind
  private closeDetails() {
    this.props.closeDetails();
  }

  render() {
    const {
      auditLog
    } = this.props;

    const fieldChanges = auditLog.log ?? [];

    var rowIdCounter = 0;

    return (
      <div>
        <div style={{ fontStyle: "italic", paddingBottom: "6px" }}>
          {`Details for change by ${auditLog.employee?.firstName} ${auditLog.employee?.lastName} on `}
          <DisplayFormattedDatetime value={auditLog.createdOn} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} />
        </div>
        <div style={{ height: "150px" }}>
          <DataGridPro
            rows={[fieldChanges]}
            columns={this.detailColumns}
            density="compact"
            hideFooter
            disableSelectionOnClick
            getRowId={(row) => {
              rowIdCounter++;
              return rowIdCounter;
            }}
          />
        </div>
        <Button
          color="secondary"
          variant="text"
          startIcon={<ArrowBack />}
          onClick={this.closeDetails}
          style={{ textTransform: "none", marginTop: "6px" }}
        >
          Return to full activity log
        </Button>
      </div>
    );
  }
}