import {
  React,
  bind,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  DateRangePicker,
  SearchControlsContainer
} from "$Imports/CommonComponents";

import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "$Imports/MaterialUIComponents";

import {
  EntityLink,
  OpportunitySearchCriteria,
  OpportunityStatusEnum
} from "$Generated/api";

import {
  EMPTY_OPPORTUNITY_SEARCH,
  OPPORTUNITY_STATUSES
} from "$State/OpportunityFreezerService";

import {
  OpportunitySearchCriteriaValidationSchema
} from "$State/OpportunityValidationSchema"

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

interface IOwnProps {
  salesReps: EntityLink[];
  onSubmit: (search: OpportunitySearchCriteria) => void;
}

interface IOwnState {
  searchCriteria: OpportunitySearchCriteria;
  errors: ValidationError | null;
}

const styles: {
  container: string;
} = require("./OpportunitySearchForm.scss");

class _OpportunitySearchForm extends React.PureComponent<IOwnProps, IOwnState> {
  state: IOwnState = {
    searchCriteria: { ...EMPTY_OPPORTUNITY_SEARCH },
    errors: null
  };

  @bind
  private async _onSubmit(): Promise<void> {
    const { searchCriteria } = this.state;

    const errors = await validateSchema(OpportunitySearchCriteriaValidationSchema, searchCriteria, {
      abortEarly: false
    });
    this.setState({ errors: errors });

    if (errors) {
      return;
    }

    this.props.onSubmit(searchCriteria);
  }

  @bind
  private _onClear(): void {
    this.setState({
      searchCriteria: { ...EMPTY_OPPORTUNITY_SEARCH }
    });

    this.props.onSubmit(EMPTY_OPPORTUNITY_SEARCH);
  }

  @bind
  private _onStatusChange(event: SelectChangeEvent<OpportunityStatusEnum[]>): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        status: event.target.value as OpportunityStatusEnum[]
      }
    }));
  }

  @bind
  private _onSalesRepChange(salesRepId: number): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        createdById: salesRepId || undefined
      }
    }));
  }

  @bind
  private _onCloseDateRangeChange(start: Date | null, end: Date | null): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        startCloseDate: start ? moment(start).startOf('day').toDate() : undefined,
        endCloseDate: end ? moment(end).endOf('day').toDate() : undefined
      }
    }));
  }

  render() {
    const {
      searchCriteria,
      errors
    } = this.state;

    const {
      salesReps
    } = this.props;

    const validationParser = new ValidationErrorParser<OpportunitySearchCriteria>(errors);

    return (
      <SearchControlsContainer
        className={styles.container}
        onSubmit={this._onSubmit}
        onClear={this._onClear}
      >
        <FormControl style={{ flex: "1 0 8rem" }}>
          <InputLabel shrink>Status</InputLabel>
          <Select
            value={searchCriteria.status}
            onChange={this._onStatusChange}
            renderValue={(value) =>
              (!value?.length || value.length === 4)
                ? "All"
                : value.join(", ")
            }
            displayEmpty
            multiple
          >
            {OPPORTUNITY_STATUSES.map((x, idx) => (
              <MenuItem value={x} key={idx}>
                <Checkbox checked={searchCriteria.status!.findIndex(s => s === x) > -1} />
                {x}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={{ flex: "1 0 12rem" }}>
          <InputLabel shrink>Sales Representative</InputLabel>
          <Select
            value={searchCriteria.createdById ?? ""}
            onChange={(event) => this._onSalesRepChange(event.target.value as number)}
            displayEmpty
          >
            <MenuItem value={""}>All</MenuItem>
            {salesReps.map((x, idx) => (
              <MenuItem value={x.linkId} key={idx}>
                {x.linkName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div style={{ display: "inline-flex", flex: "0 0 17rem", gap: "0.5rem" }}>
          <DateRangePicker
            startDate={searchCriteria.startDate}
            startError={validationParser.validationMessage("startDate")}
            endDate={searchCriteria.endDate}
            endError={validationParser.validationMessage("endDate")}
            onChange={this._onCloseDateRangeChange}
          />
        </div>
      </SearchControlsContainer>
    );
  }
}

export const OpportunitySearchForm = _OpportunitySearchForm;