import { 
  _
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

export class ValidationErrorParser<T> {

  private _validationError: ValidationError | null | undefined;

  constructor(validationError: ValidationError | null | undefined) {
    this._validationError = validationError;  
  }

  public isValid(fieldName: keyof T, index?: number): boolean {
    if(this._validationError === null || this._validationError === undefined) {
      return true;
    }

    if (index !== undefined) {
      return !_.some(this._validationError.inner, e => this.matchPathWithIndex(e, index, fieldName));
    }
    else {
      return !_.some(this._validationError.inner, e => e.path === fieldName);    
    }
  }

  public hasErrors(): boolean {
    return this._validationError !== null && this._validationError !== undefined && _.some(this._validationError.inner)
  }

  public isIndexValid(index: number): boolean {
    if(this._validationError === null || this._validationError === undefined) {
      return true;
    }

    return !_.some(this._validationError.inner, e => this.matchPathWithIndex(e, index));
  }

  public validationMessage(fieldName: keyof T, index?: number): string | null {
    if(this._validationError === null || this._validationError === undefined) {
      return null;
    }

    var foundError: any;

    if (index !== undefined) {
      foundError = _.find(this._validationError.inner, e => this.matchPathWithIndex(e, index, fieldName));
    }
    else {
      foundError = _.find(this._validationError.inner, e => e.path === fieldName);   
    }

    return foundError === undefined ? "" : foundError.message;
  }

  private matchPathWithIndex(e: any, index: number, fieldName?: keyof T): boolean {
    // the path for an array looks like [index].fieldName
    const pathSplit: string[] = e.path.split(".");
    if (pathSplit.length >= 2) {
      const pathIndex = pathSplit[0].replace(/.*\[|\]/g, "");
      const pathField = pathSplit[1];

      return index.toString() == pathIndex && (fieldName ? fieldName == pathField : true);
    }
    
    return false;
  }

  // I am sure there's a better way to do this but I cannot figure it out quickly

  public isValidDeep(fieldName: string): boolean {
    if(this._validationError === null || this._validationError === undefined) {
      return true;
    }

    return !_.some(this._validationError.inner, e => e.path === fieldName);
  }

  public validationMessageDeep(fieldName: string): string | null {
    if(this._validationError === null || this._validationError === undefined) {
      return null;
    }

    const foundError = _.find(this._validationError.inner, e => e.path === fieldName);   

    return foundError === undefined ? "" : foundError.message;
  }

}

