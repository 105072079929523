import {
  FreezerService,
  bind,
  moment,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional,
  _
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  CustomerQuoteApiFactory,
  CustomerQuote,
  CustomerQuotesSearchCriteriaDateTypeEnum,
  CustomerQuotesSearchCriteria,
  SimplifiedCustomerQuoteSearchResult,
  CustomerQuoteFreight,
  CustomerQuotesSearchCriteriaQuoteStatusesEnum
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  CommodityService
} from "$State/CommodityFreezerService";

import {
  CustomerService
} from "$State/CustomerFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import { jsDateConverter } from "$Shared/utilities/dateTimeUtil";

const QuoteSearchValidationSchema: SchemaOf<NullableOptional<CustomerQuotesSearchCriteria>> = yup.object({
  quoteDate: yup.date().typeError("Invalid date").notRequired(),
  quoteStatuses: yup.mixed<CustomerQuotesSearchCriteriaQuoteStatusesEnum[]>().notRequired(),
  quoteNumber: yup.string().notRequired().allowEmpty(),
  quoteOrPONumber: yup.string().notRequired().allowEmpty(),
  description: yup.string().notRequired().allowEmpty().min(2, "At least 2 characters are required."),
  notes: yup.string().notRequired().allowEmpty().min(2, "At least 2 characters are required."),
  salesAgentId: yup.number().notRequired(),
  dateType: yup.mixed<CustomerQuotesSearchCriteriaDateTypeEnum>().notRequired(),
  startDate: yup.date()
    .typeError("Invalid date")
    .notRequired(),
  endDate: yup.date()
    .typeError("Invalid date")
    .notRequired()
    .when("startDate", {
      is: (value?: Date) => !!value,
      then: yup.date().typeError("Invalid date")
        .test("endDate", "${message}", (value: Date | undefined, testContext: any) => {
          const startDate = testContext.parent.startDate;

          if (value && moment(value).isBefore(moment(startDate, 'day'))) {
            return testContext.createError({ message: "To must be after From" });
          }

          return true;
        }),
        otherwise: yup.date().typeError("Invalid date").notRequired()
    }),
  companyId: yup.number().notRequired(),
  customerId: yup.number().notRequired(),
  customerName: yup.string()
    .notRequired()
    .allowEmpty()
    .min(3, "At least 3 characters are required."),
  sortColumn: yup.string().notRequired().allowEmpty(),
  sortAscending: yup.boolean().notRequired(),
  startIndex: yup.number().notRequired(),
  pageSize: yup.number().notRequired(),
  quoteListType: yup.string().notRequired().allowEmpty(),
  isReviewed: yup.boolean().notRequired()
});


interface ICustomerQuotesFreezerState {
  quotesFetchResults: IAjaxState<SimplifiedCustomerQuoteSearchResult>;
  requestedQuotesFetchResults: IAjaxState<number>;
  quoteFetchResults: IAjaxState<CustomerQuote>;
  quoteSaveResults: IAjaxState<CustomerQuote>;
  customerQuote: CustomerQuote;
  freightTotalLengthFetchResults: IAjaxState<number>;
  searchCriteria: CustomerQuotesSearchCriteria;
  searchValidationErrors: ValidationError | null;
}

const InjectedPropName = "customerQuotesService";

const EMPTY_QUOTE_SEARCH: CustomerQuotesSearchCriteria = {
  quoteNumber: undefined,
  quoteOrPONumber: undefined,
  quoteStatuses: ["Requested"],
  salesAgentId: undefined,
  isReviewed: undefined,
  customerId: undefined,
  startDate: undefined,
  endDate: undefined
};

export const DEFAULT_CUSTOMER_QUOTE_SEARCH: CustomerQuotesSearchCriteria = {
  dateType: "QuoteDate",
  quoteNumber: undefined,
  quoteOrPONumber: undefined,
  quoteStatuses: ["Requested"],
  startDate: undefined,
  endDate: undefined,
  isReviewed: false,
  customerId: undefined,
  sortColumn: "deliveryDate",
  sortAscending: false,
  startIndex: 0,
  pageSize: 1000
};

export const DEFAULT_PORTAL_QUOTE_SEARCH: CustomerQuotesSearchCriteria = {
  dateType: "QuoteDate",
  quoteOrPONumber: undefined,
  quoteStatuses: ["Requested"],
  isReviewed: undefined,
  customerId: undefined,
  sortColumn: "quoteDate",
  sortAscending: false,
  startIndex: 0,
  pageSize: 1000
};

const initialState = {
  quotesFetchResults: managedAjaxUtil.createInitialState(),
  requestedQuotesFetchResults: managedAjaxUtil.createInitialState(),
  quoteFetchResults: managedAjaxUtil.createInitialState(),
  quoteSaveResults: managedAjaxUtil.createInitialState(),
  customerQuote: {},
  freightTotalLengthFetchResults: managedAjaxUtil.createInitialState(),
  searchCriteria: {
    ...EMPTY_QUOTE_SEARCH,
    sortColumn: "quoteDate",
    sortAscending: false,
    startIndex: 0,
    pageSize: 10
  },
  searchValidationErrors: null,
} as ICustomerQuotesFreezerState;

class CustomerQuotesFreezerService extends FreezerService<ICustomerQuotesFreezerState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  @bind
  public clearRequestedQuotesCount() {
    this.freezer.get().set({
      requestedQuotesFetchResults: managedAjaxUtil.createInitialState()
    });
  }

  @bind
  public clearQuotesFetchResults() {
    this.freezer.get().set({
      quotesFetchResults: managedAjaxUtil.createInitialState()
    });
  }

  @bind
  public async fetchQuotes(companyId: number | undefined, forceUpdate: boolean = false) {
    const {
      quotesFetchResults,
      searchCriteria
    } = this.freezer.get();

    const errors = await this.validateSearchModel(searchCriteria.toJS());

    if (errors || (quotesFetchResults.hasFetched && !forceUpdate) || !companyId) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quotesFetchResults",
      params: {
        body: {
          ...searchCriteria.toJS(),
          companyId
        }
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerQuotesSearchPost(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch quotes.");
      }
    });
  }

  @bind
  public async getRequestedQuotesCount(companyId?: number, salesRepId?: number) {
    if (!salesRepId || !companyId) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "requestedQuotesFetchResults",
      params: {
        body: {
          companyId,
          salesAgentId: salesRepId
        }
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getRequestedCustomerQuotesCount(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch customer quotes count.");
      }
    });
  }

  @bind
  public async fetchQuoteById(quoteId?: string) {
    if (!quoteId) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quoteFetchResults",
      params: {
        quoteId: parseInt(quoteId)
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerQuotesQuoteIdGet(params);
      },
      onOk: (data: CustomerQuote) => {
        CommodityService.fetchCommodities(data?.companyId, true);
        CustomerService.getCustomerById(data?.customerId);

        if (data.rateVariableFactor !== "Overdimensional") {
          this.fetchFreightTotalLength(data?.customerQuoteFreights);
        }

        this.freezer.get().customerQuote.set({
          ...data,
          shipperHardTime: data.shipperHardTime?.substring(0, 5),
          consigneeHardTime: data.consigneeHardTime?.substring(0, 5),
          shipperStartDate: jsDateConverter(data.shipperStartDate),
          shipperEndDate: jsDateConverter(data.shipperEndDate),
          consigneeStartDate: jsDateConverter(data.consigneeStartDate),
          consigneeEndDate: jsDateConverter(data.consigneeEndDate)
        });
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch quote.");
      }
    });
  }

  @bind
  public async fetchFreightTotalLength(quoteFreights: CustomerQuoteFreight[] | undefined) {
    if (quoteFreights === undefined || quoteFreights.length === 0) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "freightTotalLengthFetchResults",
      params: {
        body: quoteFreights
      },
      onExecute: (apiOptions, params) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getFreightLength(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to get freight total length.")
      }
    });
  }

  @bind
  public onSearchModelChanged(searchModel: Partial<CustomerQuotesSearchCriteria>) {
    this.freezer.get().searchCriteria.set(searchModel);
  }

  @bind
  public onResetSearchModel(searchCriteria: Partial<CustomerQuotesSearchCriteria> | undefined = undefined, skipDates: boolean = false) {
    const criteria = searchCriteria ?? EMPTY_QUOTE_SEARCH;

    if (!skipDates) {
      this.onSearchModelChanged({
        ...criteria,
        startDate: moment().subtract(90, 'days').startOf('day').toDate(),
        endDate: moment().endOf('day').toDate()
      });
    } else {
      this.onSearchModelChanged({
        ...criteria
      });
    }
  }

  public reviewCustomerQuote(id: number): Promise<CustomerQuote | void> {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quoteSaveResults",
      params: {
        id: id
      },
      onExecute: (apiOptions, params) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerQuotesIdReviewedPost(params);
      },
      onOk: () => {
        ErrorService.pushErrorMessage("Quote marked reviewed.", "successful");
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to mark quote as reviewed.");
      }
    });
  }

  public async validateSearchModel(searchModel: CustomerQuotesSearchCriteria): Promise<ValidationError | null> {
    const errors = await validateSchema(QuoteSearchValidationSchema, searchModel, {
      abortEarly: false
    });

    this.freezer.get().set({ searchValidationErrors: errors });

    return errors;
  }
}

export const CustomerQuoteService = new CustomerQuotesFreezerService();
export type ICustomerQuoteServiceInjectedProps = ReturnType<CustomerQuotesFreezerService["getPropsForInjection"]>;
