import {
  FreezerService,
  bind,
  NullableOptional,
  managedAjaxUtil,
  IAjaxState
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  AuditSearchCriteria,
  AuditApiFactory,
  AuditFetchResult
} from "$Generated/api";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

const AuditSearchCriteriaSchema: SchemaOf<NullableOptional<AuditSearchCriteria>> = yup.object({
  startDate: yup.date().nullable().typeError('Invalid date').notRequired(),
  endDate: yup.date().nullable().typeError('Invalid date').notRequired(),
  sortColumn: yup.string().notRequired().allowEmpty(),
  sortAscending: yup.boolean().notRequired(),
  startIndex: yup.number().notRequired(),
  pageSize: yup.number().notRequired()
})

interface IAuditServiceState {
  auditSearchModel: AuditSearchCriteria | undefined;
  auditSearchModelValidationErrors: ValidationError | null;
  keycloakEventsFetchResults: IAjaxState<AuditFetchResult>;
}

const initialState = {
  auditSearchModel: {
    startDate: undefined,
    endDate: undefined,
    sortColumn: "eventDateTime",
    sortAscending: false,
    startIndex: 0,
    pageSize: 20
  },
  auditSearchModelValidationErrors: null,
  keycloakEventsFetchResults: managedAjaxUtil.createInitialState()
} as IAuditServiceState

const InjectedPropName = "AuditService";

class AuditFreezerService extends FreezerService<IAuditServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public updateSearchModel(searchModel: Partial<AuditSearchCriteria>) {
    this.freezer.get().auditSearchModel?.set(searchModel);
  }

  public searchPage(newPage: number) {
    const criteria = this.freezer.get().auditSearchModel?.toJS();

    this.updateSearchModel({ startIndex: (newPage - 1) * (criteria?.pageSize ?? 0) });
    this.fetchKeycloakEvents(true);
  }


  public async fetchKeycloakEvents(forceUpdate: boolean = false) {

    const keycloakEventsFetchResults = this.freezer.get().keycloakEventsFetchResults;
    if (keycloakEventsFetchResults.hasFetched && !forceUpdate) {
      return;
    }
    
    const auditSearchModel = this.freezer.get().auditSearchModel?.toJS();
    const errors = await validateSchema(AuditSearchCriteriaSchema, auditSearchModel, {
      abortEarly: false,
      context: { emailRequired: true }
    });
    this.freezer.get().set({ auditSearchModelValidationErrors: errors });

    if (errors) {
      return;
    }

    const keycloakEventSearchCriteria = this.freezer.get().auditSearchModel?.toJS();

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "keycloakEventsFetchResults",
      params: {
        body: keycloakEventSearchCriteria
      },
      onExecute: (apiOptions, params, options) => {
        const factory = AuditApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1AuditGetKeycloakEventsPost(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch audit events");
      }
    })
  }
}

export const AuditService = new AuditFreezerService();
export type IAuditServiceInjectedProps = ReturnType<AuditFreezerService["getPropsForInjection"]>;