import {
  React
} from "$Imports/Imports";

import {
  Card,
  Grid,
  GridSize
} from "$Imports/MaterialUIComponents";

interface IOwnProps {
  title: string;
  majorValue: string | JSX.Element;
  minorValues?: string[];
  xs?: boolean | GridSize;
}

const styles: {
  container: string;
  title: string;
  majorValue: string;
  minorValues: string;
} = require("./MetricCell.scss");

class _MetricCell extends React.PureComponent<IOwnProps> {
  render() {
    const {
      title,
      majorValue,
      minorValues,
      xs
    } = this.props

    return (
      <Grid item xs={xs}>
        <Card className={styles.container}>
          <div className={styles.title}>
            {title}
          </div>

          <div className={styles.majorValue}>
            {majorValue}
          </div>

          { minorValues?.length && (
            <div className={styles.minorValues}>
              { minorValues.map((x, idx) => (
                <div key={idx}>{x}</div>
              ))}
            </div>
          )}
        </Card>
      </Grid>
    );
  }
}

export const MetricCell = _MetricCell;
