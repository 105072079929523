import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  SelectedCompanyConsumer
} from "$Providers/index";

import {
  CommodityLookupView
} from "./CommodityLookupView";

interface ICommodityLookupViewPageProps {

}

export class CommodityLookupViewPage extends React.Component<ICommodityLookupViewPageProps> {
  render() {
    return (
      <SelectedCompanyConsumer>
        {(companyContext) => { 
          return (
            <CommodityLookupView companyId={companyContext.companyId}/>
          );
        }}
      </SelectedCompanyConsumer>
    );
  }
}