
import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from "$Imports/MaterialUIComponents";

import {
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  QuoteSchema
} from "$State/QuoteEntryValidationSchema";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  Quote,
  QuoteCancellationReasonEnum
} from "$Generated/api";

const styles: {
  inputContainer: string;
  textfield: string;
} = require("./CancelQuoteModal.scss");

interface ICancelQuoteModelProps {
  quote: Quote;
  isOpen: boolean;
  isSaving: boolean;
  onConfirm: (quote: Quote) => void;
  onClose: () => void;
}

interface IOwnState {
  cancelQuote: Quote;
  validationErrors: ValidationError | null;
  showCancellationReasonError: boolean;
}

export class CancelQuoteModal extends React.Component<ICancelQuoteModelProps, IOwnState> {
  state: IOwnState = {
    cancelQuote: {},
    validationErrors: null,
    showCancellationReasonError: false
  };

  componentDidUpdate(prev: ICancelQuoteModelProps) {
    if (this.props.quote && this.props.quote !== prev.quote) {
      this.setState({
        cancelQuote: {...this.props.quote}
      });
    }
  }

  @bind
  private async _onConfirm() {
    const errors = await validateSchema(QuoteSchema, this.state.cancelQuote, {
      context: {
        numberOfStops: this.state.cancelQuote.quoteStops?.length ?? 0
      },
      abortEarly: false
    });

    const validationsParser = new ValidationErrorParser<Quote>(errors);
    const cancellationReasonValid = validationsParser.isValid("cancellationReason");
    const cancellationDetailsValid = validationsParser.isValid("cancellationDetails");
    this.setState({ validationErrors: errors });

    if (!this.state.cancelQuote.cancellationReason || !cancellationReasonValid) {
      this.setState({ showCancellationReasonError: true });
      return;
    } else {
      this.setState({ showCancellationReasonError: false });
    }

    if (!cancellationDetailsValid) {
      return;
    }

    this.props.onConfirm(this.state.cancelQuote);
  }

  @bind
  private _onClose() {
    this.setState({ validationErrors: null }, this.props.onClose)
  }

  @bind
  private _onReasonChange(value: QuoteCancellationReasonEnum) {
    this.setState((prev) => ({
      cancelQuote: {
        ...prev.cancelQuote,
        cancellationReason: value,
        carrierRepId: this.state.cancelQuote.id
      }
    }));
  }

  @bind
  private _onDetailChange(value: string) {
    this.setState((prev) => ({
      cancelQuote: {
        ...prev.cancelQuote,
        cancellationDetails: value
      }
    }));
  }

  render() {
    const {
      isOpen,
      isSaving
    } = this.props;

    const {
      cancelQuote,
      validationErrors,
      showCancellationReasonError
    } = this.state;

    const validationsParser = new ValidationErrorParser<Quote>(validationErrors);

    return (
      <Dialog
        open={isOpen}
        maxWidth="md"
      >
        <DialogTitle>
          Cancel Quote
        </DialogTitle>
        <DialogContent >
          <div className={styles.inputContainer}>
            <FormControl className={styles.textfield} >
              {showCancellationReasonError ? <InputLabel style={{color:"#d32f2f"}} required>Cancellation Reason</InputLabel> :
                <InputLabel required>Cancellation Reason</InputLabel>}
              <Select<QuoteCancellationReasonEnum>
                value={cancelQuote?.cancellationReason}
                onChange={(event) => this._onReasonChange(event.target.value as QuoteCancellationReasonEnum)}
                required
                autoFocus
              >
                <MenuItem value="Customer">Customer-related</MenuItem>
                <MenuItem value="Kaiser">Kaiser-related</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
              {showCancellationReasonError && <FormHelperText style={{color:"#d32f2f"}}>Cancellation reason is required.</FormHelperText>}
            </FormControl>
            <AdvanceTextField
              label="Cancellation Details"
              onDebouncedChange={this._onDetailChange}
              value={cancelQuote.cancellationDetails ?? ""}
              required={cancelQuote.cancellationReason === "Other"}
              className={styles.textfield}
              error={!validationsParser.isValid("cancellationDetails")}
              helperText={validationsParser.validationMessage("cancellationDetails")}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._onClose}
            disabled={isSaving}
            variant="outlined"
          >
            Close
          </Button>
          <Button
            color="primary"
            onClick={this._onConfirm}
            disabled={isSaving}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}