import {
  _
} from "$Imports/Imports";

import { IThemeConfiguration } from "./themeConfiguration";

import {
  defaultTheme
} from "./defaultTheme";

export const transportBlue = "#0071EB";
export const highlightBlue = "#DDEDFF";

const blueThemeDefinition: Partial<IThemeConfiguration> = {
  themeOptions: {
    palette: {
      secondary: {
        main: transportBlue,
      }
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: {
            color: transportBlue
          }
        }        
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: highlightBlue,
              "&:hover": {
                backgroundColor: highlightBlue
              }
            }
          }
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          row: {
            "&.Mui-selected": {
              backgroundColor: highlightBlue,
              "&:hover": {
                backgroundColor: highlightBlue
              }
            }
          }
        }
      }
    }
  },
  sideNavigationMenu: {
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: transportBlue
            }
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: transportBlue
            }
          }
        }
      },     
    }

  },
  appBarNavigationMenu: {
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: transportBlue
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: transportBlue
          }
        }
      }
    }
  },
  coloredTabControl: {
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: transportBlue
            }
          }
        }
      }
    }
  }
};

// _.merge mutates the base object so you have to give it an empty object
export const blueTheme: IThemeConfiguration = _.merge({}, defaultTheme, blueThemeDefinition);
