import {
  React,
  _
} from "$Imports/Imports";

import {
  LoadingInstruction,
  CustomerLoadingInstruction
} from "$Generated/api";

const styles: {
  fieldBlock: string;
  fieldLabel: string;
} = require("./CustomerDetailView.scss");

interface ILoadingUnloadingInstructionsProps {
  loadingInstructions: LoadingInstruction[];
  customerLoadingInstructions: CustomerLoadingInstruction[];
}

export class LoadingUnloadingInstructions extends React.PureComponent<ILoadingUnloadingInstructionsProps> {

  render() {
    const {
      loadingInstructions,
      customerLoadingInstructions
    } = this.props;
    const loadingInstructionIds = customerLoadingInstructions.map((i) => i.loadingInstructionId);
    const displayInstructions = loadingInstructions.filter(i => loadingInstructionIds.includes(i.id));

    return (
      <div className={styles.fieldBlock}>
        <b>Loading/Unloading Instructions</b>
        {
          displayInstructions.length > 0 ?
            _.map(displayInstructions, (i, idx) => (
              <div className={styles.fieldLabel} key={idx}>- {i.instruction}</div>
            )) :
            <div>N/A</div>
        }
      </div>
    );
  }
}