import {
  React,
  Helpers,
  bind,
  numeral,
  NumberFormat,
  NumberFormatValues
} from "$Imports/Imports";

import {
  AdvanceTextField,
  AjaxActionIndicator,
  ConfirmCancelModal,
  DateTimeInputField
} from "$Imports/CommonComponents";

import {
  Tarp,
  TarpValue
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Switch
} from "$Imports/MaterialUIComponents";

import {
  ModalState
} from "$State/TarpRatesFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

const styles: {
  container: string;
} = require("./AddEditTarpRate.scss");

interface IAddEditTarpRateProps {
  modalState: ModalState;
  isFetching: boolean;
  onSave: () => void;
  onCancel: () => void;
  onTarpValueChange: (tarpRateChange: Partial<TarpValue>) => void;
  onTarpChange: (tarpRateChange: Partial<Tarp>) => void;
  onTarpIsActiveChange: (tarpRateChange: Partial<Tarp>) => void;
  onImmediateStartChange: (immediateStart: boolean) => void;
  cancelOpen: boolean;
  cancelResponse: (response: boolean) => void;
}

export class AddEditTarpRate extends React.PureComponent<IAddEditTarpRateProps> {
  @bind
  private _onSubmit() {
    if (this.props.onSave) {
      this.props.onSave();
    }
  }

  @bind
  private _onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  @bind
  private _onTarpChange(e: React.ChangeEvent<{ name: string; value: string; }>) {
    if (e.target.name == "tarpName") {
      this.props.onTarpChange({ tarpName: e.target.value });
    }
    else if (e.target.name == "tarpDescription") {
      this.props.onTarpChange({ tarpDescription: e.target.value });
    }
  }

  @bind
  private _onTarpIsActiveChange(e: React.ChangeEvent<{ checked: boolean }>) {
    this.props.onTarpIsActiveChange({ isActive: e.target.checked });
  }

  @bind
  private _onFlatRateChange(value: NumberFormatValues) {
    this.props.onTarpValueChange({ rate: !Helpers.isNullOrUndefined(value.floatValue) ? value.floatValue : undefined });
  }

  @bind
  private _onStartDateChange(date: Date | undefined) {
    this.props.onTarpValueChange({ startDateTime: date });
  }

  @bind
  private _onImmediateStartChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.onImmediateStartChange(checked);
  }

  render() {
    const {
      modalState,
      isFetching
    } = this.props;

    const validationsParser = new ValidationErrorParser<TarpValue>(modalState.validationErrors);  
    const isTarpActive = modalState.editAddTarpValue?.tarp?.isActive ?? true;

    return !Helpers.isNullOrUndefined(modalState.editAddTarpValue) && !Helpers.isNullOrUndefined(modalState.editAddTarpValue) ? (
      <>
        <Dialog
          open={modalState.formMode === "add" || modalState.formMode === "edit"}
          fullWidth={true}
          maxWidth="sm"
        >
          <AjaxActionIndicator
            showProgress={isFetching}
          />
          <DialogTitle>
            {modalState.formMode === "add" ? "Add Tarp Rate" : "Edit Tarp Rate"}
          </DialogTitle>
          <DialogContent
            className={styles.container}
          >
            <Grid container direction={"column"} spacing={3}>
              <Grid item>
                {modalState.formMode == "add" ?
                  <AdvanceTextField
                    label="Tarp Name"
                    name="tarpName"
                    value={modalState.originalTarpValue?.tarp?.tarpName ?? ""}
                    onChange={this._onTarpChange}
                    type="text"
                    error={!validationsParser.isValidDeep("tarp.tarpName")}
                    helperText={validationsParser.validationMessageDeep("tarp.tarpName")}
                  />
                  :
                  <div>Tarp Name: {modalState.originalTarpValue?.tarp?.tarpName ?? ""}</div>
                }
              </Grid>
              <Grid item>
                {modalState.formMode == "add" ?
                  <AdvanceTextField
                    label="Description"
                    name="tarpDescription"
                    value={modalState.originalTarpValue?.tarp?.tarpDescription ?? ""}
                    onChange={this._onTarpChange}
                    type="text"
                    fullWidth
                  />
                  :
                  <div>Description: {modalState.originalTarpValue?.tarp?.tarpDescription ?? ""}</div>
                }
              </Grid>
              {modalState.formMode == "edit" &&
              <Grid item>
                <div>Current Rate: {numeral(modalState.originalTarpValue?.rate).format("$0,0.00") ?? ""}</div>
              </Grid>}
              <Grid item>
                <FormControlLabel
                  control={(
                    <Switch
                      name="isActive"
                      color="primary"
                      checked={isTarpActive}
                      onChange={this._onTarpIsActiveChange}
                    />
                  )}
                  label="Active"
                />
              </Grid>
              <Grid item>
                <NumberFormat
                  required
                  label="Rate"
                  name="flatRate"
                  value={modalState.editAddTarpValue?.rate ?? ""}
                  error={!validationsParser.isValid("rate")}
                  helperText={validationsParser.validationMessage("rate")}
                  prefix={"$"}
                  thousandSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                  onValueChange={this._onFlatRateChange}
                  customInput={TextField}
                  disabled={!isTarpActive}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label="Immediate start"
                  control={(
                    <Checkbox
                      checked={modalState.immediateStart}
                      onChange={this._onImmediateStartChange}
                      name="immediateStart"
                      disabled={!isTarpActive}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <DateTimeInputField
                  required
                  disablePast
                  dateLabel="Start Date Time"
                  disabled={modalState.immediateStart || !isTarpActive}
                  value={modalState.editAddTarpValue?.startDateTime ?? null}
                  onChange={this._onStartDateChange}
                  error={!validationsParser.isValid("startDateTime")}
                  helperText={validationsParser.validationMessage("startDateTime")}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this._onCancel}
              disabled={isFetching}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={isFetching}
              onClick={this._onSubmit}
            >
              Save
            </Button>
            </DialogActions>
        </Dialog>
        <ConfirmCancelModal
          isOpen={this.props.cancelOpen}
          onCancelNo={() => this.props.cancelResponse(false)}
          onCancelYes={() => this.props.cancelResponse(true)}
        />
      </>
    ) : null;
  }
}