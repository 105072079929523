import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  Activity
} from "$Generated/api";

import {
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from "$Imports/MaterialUIComponents";

interface IEditNoteModalProps {
  note: Activity | undefined;
  onChange: (note: Partial<Activity>) => void;
  onSave: () => void;
  onCancel: () => void;
}

interface IEditNoteModalState {

}

export class EditNoteModal extends React.Component<IEditNoteModalProps, IEditNoteModalState> {

  @bind
  private _onSave() {
    this.props.onSave();
  }

  @bind
  private _onCancel() {
    this.props.onCancel();
  }

  @bind
  private _onTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.onChange({ noteText: e.target.value });
  }

  render() {
    const { note } = this.props;

    const noteText = note?.noteText ?? "";

    const error: boolean = noteText.length > 300 || noteText.length == 0;
    const helperText = noteText.length > 300 ? "Maximum of 300 characters" : noteText.length === 0 ? "Note text is required" : "";

    return (
      <Dialog
        open={note !== undefined}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>Edit Note</DialogTitle>
        <DialogContent>
          <AdvanceTextField
            value={note?.noteText ?? ""}
            onChange={this._onTextChange}
            error={error}
            helperText={helperText}
            multiline
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._onCancel}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this._onSave}
            disabled={error}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}