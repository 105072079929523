import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  ThemeProvider,
  createTheme,
  Theme,
  
} from "$Imports/MaterialUIStyles";

import {
  YaharaThemeProvider
} from "$Providers/index";

import {
  defaultTheme,
  blueTheme,
  transportBlue,
  highlightBlue,
  greenTheme,
  logisticsGreen,
  highlightGreen
} from "$Themes/index";

import {
  ICompanySelectServiceInjectedProps,
  CompanySelectService
} from "$State/CompanySelectFreezerService";

export interface ISelectedCompanyContext {
  companyKey: string;
  setCompany: (newCompany: string) => void;
  companyId: number | undefined;
  tmCompanyId: number | undefined;
  themeColor: string;
  highlightColor: string;
}

const defaultCompanyContext: ISelectedCompanyContext = {
  companyKey: "",
  setCompany: () => {},
  companyId: undefined,
  tmCompanyId: undefined,
  themeColor: "",
  highlightColor: ""
}

const SelectedCompanyContext = React.createContext(defaultCompanyContext);

const SelectedCompanyConsumer = SelectedCompanyContext.Consumer;

interface ISelectedCompanyProviderBaseProps { }

type ISelectedCompanyProviderProps = ISelectedCompanyProviderBaseProps
  & ICompanySelectServiceInjectedProps;

class _SelectedCompanyProvider extends React.Component<ISelectedCompanyProviderProps> {

  async componentDidMount() {
    await this.props.companySelectService.fetchData();
  }

  @bind
  setCompany(companyKey: string) {
    this.props.companySelectService.updateSelectedCompany(companyKey);
  }

  render() {
    const {
      companyContext
    } = this.props.companySelectService.getState();

    const companyTheme = companyContext.companyKey === "KL" ? greenTheme : blueTheme;
    const themeColor = companyContext.companyKey === "KL" ? logisticsGreen : transportBlue;
    const highlightColor = companyContext.companyKey === "KL" ? highlightGreen : highlightBlue;

    const muiTheme: Theme = createTheme(_.merge(defaultTheme.themeOptions, companyTheme.themeOptions));

    return (
      <SelectedCompanyContext.Provider
        value={{
          companyKey: companyContext.companyKey,
          setCompany: this.setCompany,
          companyId: companyContext.companyId,
          tmCompanyId: companyContext.tmCompanyId,
          themeColor: themeColor,
          highlightColor: highlightColor
        }}
      >
        <ThemeProvider
          theme={muiTheme}
        >
          <YaharaThemeProvider
            themeConfig={companyTheme}
          >
            {this.props.children}
          </YaharaThemeProvider>
        </ThemeProvider>
      </SelectedCompanyContext.Provider>
    );
  }
}

const SelectedCompanyProvider = 
  CompanySelectService.inject(
    _SelectedCompanyProvider
  );

export {
  SelectedCompanyProvider,
  SelectedCompanyConsumer
};
