import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  LogisticsMarkup
} from "$Generated/api";

import {
  Card,
  CardActions,
  CardHeader,
  Button
} from "$Imports/MaterialUIComponents";

import {
  DataTable,
  IDataTableColumn,
  DisplayFormattedNumber,
  AjaxActionIndicator,
  directionType,
  DisplayFormattedDatetime
} from "$Imports/CommonComponents";

import {
  ILogisticsServiceInjectedProps,
  LogisticsService
} from "$State/LogisticsFreezerService";

import {
  AddLogisticsMarkup
} from "./AddLogisticsMarkup";

import { DATE_WITH_TIME_MERIDIAN_FORMAT } from "$Shared/utilities/formatUtil";

const styles: {
  mainContainer: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface ILogisticsMarkupViewPageBaseProps {

}

type ILogisticsMarkupViewPageProps = ILogisticsMarkupViewPageBaseProps
  & ILogisticsServiceInjectedProps;

class _LogisticsMarkupViewPage extends React.Component<ILogisticsMarkupViewPageProps> {

  private readonly columns: Array<IDataTableColumn<LogisticsMarkup>> = [
    {
      columnName: "primaryRate",
      columnFieldData: (x) => <DisplayFormattedNumber value={x.primaryRate} formatString={"0.0%"} />,
      sortMethod: (x) => x.primaryRate,
      headerValue: "Primary"
    },
    {
      columnName: "secondaryRate",
      columnFieldData: (x) => <DisplayFormattedNumber value={x.secondaryRate} formatString={"0.0%"} />,
      sortMethod: (x) => x.secondaryRate,
      headerValue: "Secondary"
    },
    {
      columnName: "startDate",
      columnFieldData: (x) => <DisplayFormattedDatetime value={x.startDateTime} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />,
      sortMethod: (x) => x.startDateTime,
      headerValue: "Start Date"
    },
    {
      columnName: "endDate",
      columnFieldData: (x) => <DisplayFormattedDatetime value={x.endDateTime} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />,
      sortMethod: (x) => x.endDateTime,
      headerValue: "End Date"
    },
    {
      columnName: "createdBy",
      columnFieldData: (x) => x.createdBy ? x.createdBy.firstName + " " + x.createdBy.lastName : "",
      sortMethod: (x) => x.createdBy ? x.createdBy.firstName + " " + x.createdBy.lastName : "",
      headerValue: "Created By"
    }
  ];

  componentDidMount() {
    this.props.logisticsService.fetchData();
  }

  @bind
  private _onSortChange(event: React.MouseEvent<HTMLElement>, columnName: string | undefined, direction: directionType) {
    this.props.logisticsService.setSortState({
      sortColumnName: columnName,
      sortDirection: direction,
    });
  }

  @bind
  private _openAddModal() {
    this.props.logisticsService.openAddModal();
  }

  @bind
  private _closeAddModal() {
    this.props.logisticsService.closeAddModal();
  }

  @bind
  private _onSave() {
    this.props.logisticsService.saveNewMarkup();
  }

  @bind
  private _onChange(value: Partial<LogisticsMarkup>) {
    this.props.logisticsService.onChangeFromModal(value);
  }

  render() {
    const {
      logisticsMarkupsFetchResults,
      sortState,
      logisticsModalState
    } = this.props.logisticsService.getState();
    const logisticsData = logisticsMarkupsFetchResults.data ?? [] as LogisticsMarkup[];

    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader
            title="Logistics Market Markup"
          />
          <CardActions
            disableSpacing={true}
            style={{justifyContent: "flex-end"}}
          >
            <Button
              color="primary"
              onClick={this._openAddModal}
            >
              Add
            </Button>
          </CardActions>
          <AjaxActionIndicator
            state={[logisticsMarkupsFetchResults]}
          />
          <DataTable
            columns={this.columns}
            data={logisticsData}
            defaultSortColumnName={sortState.sortColumnName}
            defaultSortDirection={sortState.sortDirection}
            onSortChange={this._onSortChange}
          />
        </Card>
        <AddLogisticsMarkup
          isOpen={logisticsModalState.isOpen}
          newRecord={logisticsModalState.newRecord}
          validationErrors={logisticsModalState.validationErrors}
          onSave={this._onSave}
          onCancel={this._closeAddModal}
          onChange={this._onChange}
        />
      </div>
    );
  }
}

export const LogisticsMarkupViewPage = LogisticsService.inject(
    _LogisticsMarkupViewPage
  );
