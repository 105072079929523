import {
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  CustomerSource
} from "$Generated/api";

import {
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControlLabel,
  Switch
} from "$Imports/MaterialUIComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  ICustomerSourceServiceInjectedProps,
  CustomerSourceService,
  EMPTY_CUSTOMER_SOURCE
} from "$State/CustomerSourceFreezerService";

import {
  CustomerSourceValidationSchema
} from "$State/CustomerSourceValidationSchema";

const styles: {
  modalContainer: string;
} = require("$Shared/styles/Modal.scss");

interface IAddEditCustomerSourceBaseProps {
  isOpen: boolean;
  filterActive: boolean;
  customerSource: CustomerSource;
  onClose: (value?: CustomerSource) => void;
}

interface IOwnState {
  customerSource: CustomerSource;
  validationErrors: ValidationError | null;
  disableSave: boolean;
}

type IAddEditCustomerSourceProps = IAddEditCustomerSourceBaseProps &
ICustomerSourceServiceInjectedProps;

class _AddEditCustomerSourceModal extends React.PureComponent<IAddEditCustomerSourceProps, IOwnState> {
  state: IOwnState = {
    customerSource: { ...EMPTY_CUSTOMER_SOURCE, isActive: true },
    validationErrors: null,
    disableSave: false
  };

  @bind
  private async _onClose(shouldSave: boolean): Promise<void> {
    if(!shouldSave) {
      this.props.onClose();
      return;
    }
    
    const customerSourceErrors = await validateSchema(CustomerSourceValidationSchema, this.state.customerSource);
    this.setState({ validationErrors: customerSourceErrors });

    if (customerSourceErrors) {
      return;
    }

    this.setState({
      disableSave: true
    });

    const { customerSource } = this.state;
    
    try {
      await CustomerSourceService.saveCustomerSource(customerSource);
      this.props.customerSourceService.fetchCustomerSources(this.props.filterActive, true);
      this.props.onClose(customerSource);
    } catch (ex) {
      this.setState({
        disableSave: false
      });
    }
  }

  componentDidUpdate(prev: IAddEditCustomerSourceBaseProps) {
    if(prev.isOpen !== this.props.isOpen) {
      this.setState({
        disableSave: false
      });
    }

    if (this.props.customerSource !== prev.customerSource) {
      this.setState({
        customerSource: {
          ...this.props.customerSource,
        },
        validationErrors: null
      });
    }
  }

  @bind
  private _onActiveChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.setState((prev) => ({
      customerSource: {
        ...prev.customerSource,
        isActive: checked
      }
    }));
  }

  @bind
  private _onChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState((prev) => ({
      customerSource: {
        ...prev.customerSource,
        [event.target.name]: event.target.value
      }
    }));
  }

  render() {
    const {
      isOpen
    } = this.props;

    const {
      customerSource,
      disableSave,
      validationErrors
    } = this.state;

    const validationsParser = new ValidationErrorParser<CustomerSource>(validationErrors);

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
        {customerSource.id ? "Edit Customer Source" : "Add Customer Source"}
        </DialogTitle>
        <DialogContent
          className={styles.modalContainer}
        >
          <FormControlLabel
            control={
              (
                <Switch
                  color="primary"
                  checked={customerSource.isActive ?? false}
                  onChange={this._onActiveChange}
                />
              )
            }
            label="Active"
          />
          <AdvanceTextField
            required
            label="Source"
            name="name"
            value={customerSource.name ?? ""}
            onChange={this._onChange}
            inputProps={{ maxLength: 30 }}
            error={!validationsParser.isValid("name")}
            helperText={validationsParser.validationMessage("name")}
            style={{ margin: "1rem 0"}}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this._onClose(false)}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => this._onClose(true)}
            disabled={disableSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export const AddEditCustomerSourceModal = CustomerSourceService.inject(_AddEditCustomerSourceModal);