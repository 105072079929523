import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Card,
  CardActions,
  CardHeader,
  Tab,
  Tabs,
  Button,
  Grid
} from "$Imports/MaterialUIComponents";

import {
  DataTable,
  IDataTableColumn,
  AjaxActionIndicator,
  DisplayFormattedDatetime,
  DisplayFormattedNumber,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  IZoneConfigurationServiceInjectedProps,
  IZoneRateConfig,
  ZoneConfigurationService,
} from "$State/ZoneConfigurationService";

import {
  Zone,
  ZoneConfiguration
} from "$Generated/api";

import {
  EditZoneConfiguration,
} from "./EditZoneConfiguration";

import {
  ThemeConsumer
} from "$Providers/index";

import {
  ThemeProvider,
  createTheme
} from "$Imports/MaterialUIStyles";

import { DATE_WITH_TIME_MERIDIAN_FORMAT } from "$Shared/utilities/formatUtil";

const styles: {
  zoneRegions: string;
  tableFooter: string;
  tabs: string;
  subCardHeader: string;
  minPerLoadStyle: string;
} = require("./ZoneConfigurationView.scss");

const containerStyles: {
  mainContainer: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface IZoneConfigurationViewBaseProp {
  companyId: number | undefined;
}

interface IZoneConfigurationState {
  cancelOpen: boolean;
}

type IZoneConfigurationViewProps = IZoneConfigurationServiceInjectedProps & IZoneConfigurationViewBaseProp;

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

class _ZoneConfigurationView extends React.Component<IZoneConfigurationViewProps, IZoneConfigurationState> {

  state: IZoneConfigurationState = {
    cancelOpen: false
  };

  componentDidMount() { 
    this.props.zoneConfigurationService.clearFreezer();
    this.props.zoneConfigurationService.fetchZoneConfigurations(this.props.companyId, true);
  }

  componentDidUpdate(prevProps: IZoneConfigurationViewBaseProp) {
    if (this.props.companyId !== prevProps.companyId) {
      this.props.zoneConfigurationService.clearFreezer();
      this.props.zoneConfigurationService.fetchZoneConfigurations(this.props.companyId, true);      
    }
  }

  private readonly columns: Array<IDataTableColumn<ZoneConfiguration>> = [
    {
      columnName: "destZone",
      columnFieldData: (d) => d.destZone ? `${d.destZone.zoneName} (${_.map(d.destZone.zoneRegions, (r) => r.region?.regionAbbreviation).join(', ')})` : "",
      headerValue: "Destination Zone",
    },
    {
      columnName: "currentDatRate",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.currentDatRate} formatString={'$0,0.00'} emptyDisplay={'N/A'} />,
      headerValue: "Current DAT RATE",
    },
    {
      columnName: "lowRatePercentage",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.lowRatePercentage} formatString={'0.0%'} emptyDisplay={'N/A'} />,
      headerValue: "Low Percentage",
    },
    {
      columnName: "minimumQuoteAmount",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.minimumQuoteAmount} formatString={'$0,0.00'} emptyDisplay={'N/A'} />,
      headerProps: {
        className: styles.minPerLoadStyle,
      },
      headerValue: "Minimum $ per Load",
      cellProps: {
        className: styles.minPerLoadStyle
      }
    },
  ];

  @bind
  private _onOriginZoneChange(event: React.ChangeEvent<{}>, value: any) {
    this.props.zoneConfigurationService.selectOriginZone(value);
  }

  @bind
  private _onCancelClick() {
    const zoneConfigurationService = this.props.zoneConfigurationService;
    const state = zoneConfigurationService.getState();

    // only display cancel modal when data changed
    if (_.isEqual(state.zoneConfigurationsForEditFromServer, state.zoneConfigurationsForEdit)) {
      zoneConfigurationService.clearEditZoneForm();
    } else {
      this.setState({ cancelOpen: true })
    }
  }

  @bind
  private _onCancelNo() {
    this.setState({ cancelOpen: false })
  }

  @bind
  private _onCancelYes() {
    const zoneConfigurationService = this.props.zoneConfigurationService;
    this.setState({ cancelOpen: false })
    zoneConfigurationService.clearEditZoneForm();
  }

  @bind
  private _onSave() {
    this.props.zoneConfigurationService.saveZoneConfigs(this.props.companyId);
  }

  @bind
  private _onZoneConfigsChanged(index: number, zoneConfig: Partial<ZoneConfiguration>) {
    this.props.zoneConfigurationService.updateZoneConfigs(index, zoneConfig);
  }

  @bind
  private _onDefaultsChanged(newDefaults: Partial<IZoneRateConfig>) {
    this.props.zoneConfigurationService.updateDefaults(newDefaults);
  }

  @bind
  private _applyDefaults() {
    this.props.zoneConfigurationService.applyDefaults();
  }

  @bind
  private _onEditZoneConfigsOpen() {
    this.props.zoneConfigurationService.onEditZoneConfig();
  }

  render() {
    const state = this.props.zoneConfigurationService.getState();

    const {
      zoneConfigurationFetchResults,
      selectedOrigin,
      originZones,
      selectedZoneConfigurations,
      formMode,
      zoneConfigurationsForEdit,
      validationErrors,
      defaultZoneRateConfig,
      defaultValidationErrors
    } = state;

    const isFetching: boolean = zoneConfigurationFetchResults.isFetching;
    return (
      <ThemeConsumer>
        {(context) => {
          return (
            <div
              className={containerStyles.mainContainer}
            >
              <ThemeProvider
                theme={(theme) => createTheme(context.themeConfig.sideNavigationMenu, theme)}
              >
              <Card
                className={containerStyles.cardStyle}
              >
                <CardHeader
                  title="Zone Rates"
                />
                <AjaxActionIndicator
                  state={zoneConfigurationFetchResults}
                />
                <CardActions
                  disableSpacing={true}
                >
                  <Grid container spacing={0} >
                    <Grid item md={2} className={styles.subCardHeader}>
                      Origin Zones
                      </Grid>
                    <Grid item md={10} className={styles.subCardHeader}>
                      <div>{selectedOrigin?.zoneName}</div>
                      <div className={styles.zoneRegions}>{`(States: ${_.map(selectedOrigin?.zoneRegions, (r) => r.region?.regionAbbreviation).join(', ')})`}</div>
                    </Grid>
                  </Grid>
                  <UserAccessControl roles={["zone-rate:edit"]}>
                    <Button
                      color="primary"
                      onClick={this._onEditZoneConfigsOpen}
                      disabled={isFetching}
                    >
                      Edit
                    </Button>
                  </UserAccessControl>
                </CardActions>
                <Grid container spacing={0}>
                  <Grid item md={2}>
                    <Card
                      className={containerStyles.cardStyle}
                    >
                      <Tabs
                        orientation="vertical"
                        value={selectedOrigin?.id ?? ""}
                        onChange={this._onOriginZoneChange}
                        className={styles.tabs}
                      >
                        {
                          _.map(originZones, (d: Zone, dIdx: number) => (
                            <Tab label={d.zoneName} {...a11yProps(dIdx)} key={dIdx} value={d.id} />
                          ))
                        }
                      </Tabs>
                    </Card>
                  </Grid>
                  <Grid item md={10}>
                    <Card
                      className={containerStyles.cardStyle}
                    >
                      {selectedZoneConfigurations === undefined || selectedZoneConfigurations === null || selectedZoneConfigurations.length === 0 ? "Loading..." : <DataTable
                        columns={this.columns}
                        data={selectedZoneConfigurations}
                        tableFooterComponent={(
                          <tr className={styles.tableFooter}>
                            <td colSpan={4} >
                              {
                                selectedZoneConfigurations[0]?.datPollDateTime &&
                                <DisplayFormattedDatetime
                                  prefix={'***LAST DAT Update: '}
                                  value={selectedZoneConfigurations[0]?.datPollDateTime}
                                  formatString={DATE_WITH_TIME_MERIDIAN_FORMAT}
                                  showTimeZone
                                />
                              }
                            </td>
                          </tr>
                        )}
                      />}
                    </Card>
                  </Grid>
                </Grid>
              </Card>
              <EditZoneConfiguration
                formMode={formMode}
                zoneConfigData={zoneConfigurationsForEdit}
                validationErrors={validationErrors}
                defaultZoneRateConfig={defaultZoneRateConfig}
                defaultValidationErrors={defaultValidationErrors}
                onCancelClick={this._onCancelClick}
                onCancelYes={this._onCancelYes}
                onCancelNo={this._onCancelNo}
                onSave={this._onSave}
                isFetching={isFetching}
                onZoneConfigsChanged={this._onZoneConfigsChanged}
                applyDefaults={this._applyDefaults}
                onDefaultsChanged={this._onDefaultsChanged}
                cancelOpen={this.state.cancelOpen}
              />
              </ThemeProvider>
            </div>
          );
        }}
      </ThemeConsumer>
    );
  }
}

export const ZoneConfigurationView = ZoneConfigurationService.inject(
  _ZoneConfigurationView
);
