import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState
} from "$Imports/Imports";

import {
  CompanyApiFactory,
  Company
} from "$Generated/api";

import {
  SecurityContext
} from "$Utilities/Security/ApplicationSecuritySettings";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import { 
  ErrorService 
} from "./ErrorFreezerService";

import { 
  ISelectedCompanyContext 
} from "$Providers/CompanyProvider";

interface ICompanySelectFreezerServiceState {
  assignedCompanies: Company[];
  companyFetchResults: IAjaxState<Company[]>;
  companyContext: ISelectedCompanyContext;
}

const InjectedPropName = "companySelectService";

const defaultCompanyContext: ISelectedCompanyContext = {
  companyKey: "",
  setCompany: () => {},
  companyId: undefined,
  tmCompanyId: undefined,
  themeColor: "",
  highlightColor: ""
}

const initialState = {
  assignedCompanies: [],
  companyFetchResults: managedAjaxUtil.createInitialState(),
  companyContext: defaultCompanyContext
} as ICompanySelectFreezerServiceState;

class CompanySelectFreezerService extends FreezerService<ICompanySelectFreezerServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public async fetchData() {
    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "companyFetchResults",
      params: {},
      onExecute: (apiOptions, params, options) => {
        const factory = CompanyApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CompanyGet(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch companies.");
      },
      onOk: (data: Company[]) => {
        const assignedCompanies = _.filter(data, c => _.includes(SecurityContext.getCompanies(null), c.companyKey));

        this.freezer.get().set({
          assignedCompanies: assignedCompanies
        });
        this._setSelectedCompanyDataUponFetch();

        const company = _.find(data, c => c.companyKey === this.freezer.get().companyContext.companyKey);
        this.updateCompanyContext({companyId: company?.id, tmCompanyId: company?.tmcompanyId})
      }
    });
  }

  private _setSelectedCompanyDataUponFetch() {
    
    const companyKey = localStorage.getItem(`${SharedSecurityContext.getUserId()}_companyKey`);

    if (companyKey) {
      this.updateCompanyContext({ companyKey });
    }
    else if (SecurityContext.getCompanies(null).length !== 0) {
      this.updateCompanyContext({ companyKey: SecurityContext.getCompanies(null)[0]});
    }
  }

  public updateSelectedCompany(companyKey: string) {

    const company = this.getCompanyByKey(companyKey);

    this.updateCompanyContext({
      companyId: company?.id,
      tmCompanyId: company?.tmcompanyId,
      companyKey: companyKey
    });

    // update local storage
    localStorage.setItem(`${SharedSecurityContext.getUserId()}_companyKey`, companyKey);
  }

  public updateCompanyContext(companyContext: Partial<ISelectedCompanyContext>) {
    this.freezer.get().companyContext.set(companyContext)
  }

  public getCompanyByKey(companyKey: string | null) : Company | undefined {
    const {
      companyFetchResults
    } = this.freezer.get().toJS();

    if (companyFetchResults.hasFetched && companyFetchResults.data) {
      return companyFetchResults.data.find(c => c.companyKey === companyKey);
    }

  }
}

export const CompanySelectService = new CompanySelectFreezerService();
export type ICompanySelectServiceInjectedProps = ReturnType<CompanySelectFreezerService["getPropsForInjection"]>;
