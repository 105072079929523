import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  Button
} from "$Imports/MaterialUIComponents";

import {
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  CURRENCY_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  QuoteCarrierFlattened
} from "$Generated/api";

interface ICarrierSaleDetailsModalProps {
  isOpen: boolean;
  carrier: QuoteCarrierFlattened | undefined;
  onClose: () => void;
}

export class CarrierSaleDetailsModal extends React.PureComponent<ICarrierSaleDetailsModalProps> {

  @bind
  private onClose() {
    this.props.onClose();
  }

  render() {
    const {
      isOpen,
      carrier
    } = this.props;

    const fbNumber = carrier?.freightBillNumber ?? "";

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
          {fbNumber} Sale Details
        </DialogTitle>
        <DialogContent>
          <u>Sales Representative:</u> {carrier?.salesRepName ?? ""}
          <br/>
          <u>Customer Rate:</u> <DisplayFormattedNumber value={carrier?.customerNegotiatedRate} formatString={CURRENCY_FORMAT} />
          <br/>
          {
            !carrier?.isMarketPrimary &&
            <>
              <u>Carrier Rate:</u> <DisplayFormattedNumber value={carrier?.carrierRate} formatString={CURRENCY_FORMAT} />
              <br/>
            </>
          }
          <br/>
          <u>Caller:</u> {carrier?.callerCustomerName ?? ""}
          <br/>
          <u>Shipper:</u> {carrier?.shipperCustomerName ?? ""}
          <br/>
          <u>Consignee:</u> {carrier?.consigneeCustomerName ?? ""}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}