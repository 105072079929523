import {
    React,
    bind,
    _
  } from "$Imports/Imports";
  
  import {
    SelectedCompanyConsumer
  } from "$Providers/index";
  
  import {
    UpchargeQuestionsView
  } from "./UpchargeQuestionsView";
  
  interface IUpchargeQuestionsViewPageProps {
  
  }
  
  export class UpchargeQuestionsViewPage extends React.Component<IUpchargeQuestionsViewPageProps> {
    render() {
      return (
        <SelectedCompanyConsumer>
          {(companyContext) => { 
            return (
              <UpchargeQuestionsView companyId={companyContext.companyId}/>
            );
          }}
        </SelectedCompanyConsumer>
      );
    }
  }