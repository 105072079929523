
import {
  React,
  bind
} from "$Imports/Imports";

import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from "$Imports/MaterialUIComponents";

import {
  QuoteStopFreightQuestionAnswerEnum
} from "$Generated/api";

import {
  ResponseType
} from "$State/QuoteEntryFreezerService";

interface IQuestionAnswerRadioButtonsBaseProps {
  viewOnly?: boolean;
  radioGroupName?: string;
  questionText?: string;
  answer?: QuoteStopFreightQuestionAnswerEnum | ResponseType;
  autoFocus?: boolean;
  isNa?: boolean;
  className?: string | undefined;
  setAnswer: (questionId: string, answer: string) => void;
}

export class QuestionAnswerRadioButtons extends React.PureComponent<IQuestionAnswerRadioButtonsBaseProps> {

  @bind
  setAnswer(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.setAnswer(e.target.name, e.target.value);
  }

  render() {
    
    const {
      viewOnly,
      radioGroupName,
      questionText,
      answer,
      autoFocus,
      isNa,
      className,
    } = this.props;

    return (
      <Grid container direction="row" item spacing={2}>
        <Grid
          item
          className={className ?? ""}
          lg
        >
          {questionText}
        </Grid>
        <Grid item xs>
          <RadioGroup row name={radioGroupName} value={answer} onChange={this.setAnswer}>
            <FormControlLabel value={"Yes"} control={<Radio disabled={viewOnly} autoFocus={(autoFocus && answer === "Yes") ? true : false} />} label="Yes" />
            <FormControlLabel value={"No"} control={<Radio disabled={viewOnly} autoFocus={(autoFocus && answer === "No") ? true : false} />} label="No" />
            {isNa && <FormControlLabel value={"NA"} control={<Radio disabled={viewOnly} autoFocus={(autoFocus && answer === "NA") ? true : false} />} label="N/A" />}
          </RadioGroup>
        </Grid>
      </Grid>
    )
  }
}