import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  Address,
  QuoteApprovalReason,
  QuoteCalculatedRate,
  QuoteStatusEnum,
  QuoteStop
} from "$Generated/api";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from "$Imports/MaterialUIComponents";

import {
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  getCurrent,
  getPrevious
} from "$Utilities/addressUtil";

import {
  getFormattedZipPostalCode
} from "$Shared/utilities/helpers";

import {
  approvalReasonTextMap
} from "$Utilities/enumUtil";

import {
  SecurityContext
} from "$Utilities/Security/ApplicationSecuritySettings";

interface IOwnProps {
  isOpen: boolean;
  quoteStops?: QuoteStop[];
  quoteStatus?: QuoteStatusEnum;
  newRate?: QuoteCalculatedRate;
  previousRate?: QuoteCalculatedRate;
  approvalReasons?: QuoteApprovalReason[];
  negotiatedRate?: number;
  closeModal: (status: QuoteStatusEnum | "") => void;
}

interface IAddressDiscrepancy {
  quoteStopNumber: number;
  previousShipper?: Address;
  currentShipper?: Address;
  previousConsignee?: Address;
  currentConsignee?: Address;
}

const styles: {
  reasons: string;
  comparisonContainer: string;
  comparisonRow: string;
  comparisonField: string;
  comparisonValue: string;
  highlightedValue: string;
  addressContainer: string;
  addressQuoteStop: string;
  addressBlock: string;
  actions: string;
} = require("./RateDiscrepancyModal.scss");

const getAddressBlock = (address: Address): JSX.Element => (
  <>
    {address.address1 && (
      <div>
        <div>{address.address1 ?? ""}</div>
        <div>{address.address2 ?? ""}</div>
      </div>
    )}
    <div>
      {address.city ?? ""}, {address.region?.regionAbbreviation ?? ""} {getFormattedZipPostalCode(address) ?? ""}
    </div>
  </>
);

export class RateDiscrepancyModal extends React.PureComponent<IOwnProps> {
  @bind
  private _onQuoteAction(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const status = event.currentTarget.value as QuoteStatusEnum;

    this.props.closeModal(status);
  }

  render() {
    const {
      isOpen,
      quoteStops,
      quoteStatus,
      newRate,
      previousRate,
      approvalReasons,
      negotiatedRate
    } = this.props;

    const isAdminUser = SecurityContext.isInGroup("/Admin");
    const isManagerUser =  SecurityContext.isInGroup("/Manager");

    let actions: JSX.Element;
    let actionMessage: string;

    if (quoteStatus === "Accepted") {
      // add other (auto-)accept text here, when/if other conditions added
      let acceptReason: string = "";

      if (!!approvalReasons?.findIndex(x => (x.approvalNeededReasonId === "LowNegotiatedRate")
        && (x.approvalStatus === "Approved"))) {
        acceptReason = " due to previous approval for a low negotiated rate";
      }

      actionMessage = `The quote was automatically accepted${acceptReason}.`;
      actions = (<>
        <Button
          color="primary"
          value=""
          onClick={this._onQuoteAction}
        >
          Close
        </Button>
      </>);
    }
    else if (isAdminUser || isManagerUser) {
      actionMessage = "To continue, you may approve these discrepancies or reject the quote.";
      actions = (<>
        <Button
          color="primary"
          value="Accepted"
          onClick={this._onQuoteAction}
        >
          Approve
        </Button>
        <Button
          color="primary"
          value="AcceptanceRejected"
          onClick={this._onQuoteAction}
        >
          Reject
        </Button>
      </>);
    }
    else {
      actionMessage = "To continue, you may request approval for this quote or copy the quote and re-rate it.";
      actions = (<>
        <Button
          color="primary"
          value="ApprovalNeeded"
          onClick={this._onQuoteAction}
        >
          Request Approval
        </Button>
        <Button
          color="primary"
          value="AcceptanceRejected"
          onClick={this._onQuoteAction}
        >
          Reject
        </Button>
      </>);
    }

    const hasShipperDiscrepancy = !!approvalReasons?.find(x => x.approvalNeededReasonId === "ShipperZoneChanged");
    const hasConsigneeDiscrepancy = !!approvalReasons?.find(x => x.approvalNeededReasonId === "ConsigneeZoneChanged");

    let addressDiscrepancies: IAddressDiscrepancy[] = [];

    // convert backend-generated discrepancy reason(s) into address comparisons
    if (hasShipperDiscrepancy || hasConsigneeDiscrepancy) {
      _.forEach(quoteStops, (qs) => {
        const discrepancy: IAddressDiscrepancy = {
          quoteStopNumber: qs.stopNumber!
        };

        if (hasShipperDiscrepancy) {
          const current = getCurrent(qs.addresses, "Shipper");
          const previous = getPrevious(qs.addresses, "Shipper");

          if (current?.regionId !== previous?.regionId) {
            discrepancy.currentShipper = current;
            discrepancy.previousShipper = previous;
          }
        }

        if (hasConsigneeDiscrepancy) {
          const current = getCurrent(qs.addresses, "Consignee");
          const previous = getPrevious(qs.addresses, "Consignee");

          if (current?.regionId !== previous?.regionId) {
            discrepancy.currentConsignee = current;
            discrepancy.previousConsignee = previous;
          }
        }

        // if either "current" is set, display the before/after values
        if (discrepancy.currentShipper || discrepancy.currentConsignee) {
          addressDiscrepancies.push(discrepancy);
        }
      });
    }

    // don't display low negotiated rate if a negotiated rate out of range reason is present
    var displayReasons = approvalReasons;
    if (_.find(approvalReasons, r => r.approvalNeededReasonId === "NegotiatedRateOutOfRange") !== undefined) {
      displayReasons = _.filter(approvalReasons, r => r.approvalNeededReasonId !== "LowNegotiatedRate");
    }

    return (
      <Dialog open={isOpen}>
        <DialogTitle>Negotiated Rate Discrepancy</DialogTitle>
        <DialogContent>
          <div className={styles.reasons}>
            {_.map(displayReasons, (r, idx) => (
              <div key={idx}>
                {approvalReasonTextMap[r.approvalNeededReasonId ?? ""]}
              </div>
            ))}
          </div>

          <div>
            {actionMessage}
          </div>

          <div className={styles.comparisonContainer}>
            <div className={styles.comparisonRow}>
              <div className={styles.comparisonField}>
                Negotiated Rate:
              </div>
              <div className={styles.comparisonValue}>
                <DisplayFormattedNumber value={negotiatedRate} formatString={"$0,0.00"} />
              </div>
            </div>
          </div>

          <div className={styles.comparisonContainer}>
            <div className={styles.comparisonRow}>
              <div className={styles.comparisonField}>
                Original Mileage:
              </div>
              <div className={styles.comparisonValue}>
                {previousRate?.miles}
              </div>
            </div>

            <div className={styles.comparisonRow}>
              <div className={styles.comparisonField}>
                New Mileage:
              </div>
              <div className={styles.comparisonValue}>
                {newRate?.miles}
              </div>
            </div>

            <div className={styles.comparisonRow}>
              <div className={styles.comparisonField}>
                Difference:
              </div>
              <div className={styles.comparisonValue}>
                <span className={styles.highlightedValue}>+{(newRate?.miles && previousRate?.miles) ? newRate.miles - previousRate.miles : 0}</span>
              </div>
            </div>
          </div>

          <div className={styles.comparisonContainer}>
            <div className={styles.comparisonRow}>
              <div className={styles.comparisonField}>
                Original Range:
              </div>
              <div className={styles.comparisonValue}>
                <DisplayFormattedNumber value={previousRate?.lowRate} formatString={"$0,0.00"} />
                {" to "}
                <DisplayFormattedNumber value={previousRate?.highRate} formatString={"$0,0.00"} />
              </div>
            </div>

            <div className={styles.comparisonRow}>
              <div className={styles.comparisonField}>
                New Range:
              </div>
              <div className={styles.comparisonValue}>
                <span className={styles.highlightedValue}>
                  <DisplayFormattedNumber value={newRate?.lowRate} formatString={"$0,0.00"} />
                  {" to "}
                  <DisplayFormattedNumber value={newRate?.highRate} formatString={"$0,0.00"} />
                </span>
              </div>
            </div>


            {addressDiscrepancies.map(x => (
              <div className={styles.addressContainer}>
                <div className={styles.addressQuoteStop}>
                  Quote stop {x.quoteStopNumber}
                </div>
                {x.currentShipper && (
                  <>
                    <div className={styles.addressBlock}>
                      Original shipper address:
                      {getAddressBlock(x.previousShipper!)}
                    </div>

                    <div className={styles.addressBlock}>
                      New shipper address:
                      {getAddressBlock(x.currentShipper!)}
                    </div>
                  </>
                )}

                {x.currentConsignee && (
                  <>
                    <div className={styles.addressBlock}>
                      Original consignee address:
                      {getAddressBlock(x.previousConsignee!)}
                    </div>
                    <div className={styles.addressBlock}>
                      New consignee address:
                      {getAddressBlock(x.currentConsignee!)}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </DialogContent>

        <DialogActions className={styles.actions}>
          {actions}
        </DialogActions>
      </Dialog>
    )
  }
}