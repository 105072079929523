import {
  _
} from "$Imports/Imports";

import { IThemeConfiguration } from "./themeConfiguration";

export const defaultTheme: IThemeConfiguration = {
  themeOptions: {
    palette: {
      primary: {
        main: "#28343C",
      },
      secondary: {
        main: "#0071EB",
      },
      background: {
        default: "#E0E0E0"
      }
    },
    components: {
      MuiCard: {
        styleOverrides:
        {
          root: {
            fontSize: "14px",
            color: "#000000DE"
          }
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: "standard"
        }
      },
      MuiFormControl: {
        defaultProps: {
          variant: "standard"
        }
      },
      MuiCardHeader: {
        defaultProps: {
          title: {
            fontSize: "22px",
            fontWeight: "bold",
            color: "#5a5c63",
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            color: "rgba(0, 0, 0, 0.54)",
            fontSize: "14px"
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          input: {
            "&:placeholder-shown": {
              fontStyle: "italic"
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "rgba(0, 0, 0, 0.54)",
            fontSize: "14px",
            fontStyle: "italic"
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "14px"
          }
        }
      },
      MuiButton: {
        defaultProps: {
          variant: "contained"
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          row: {
            "&:nth-child(even):not(.Mui-selected)": { // specificity
              backgroundColor: "#f2f2f2"
            },
            "&:hover": {
              backgroundColor: "initial"
            }
          },
          cell: {
            borderBottom: 0,
            ":focus": {
              outline: "none"
            },
            ":focus-within": {
              outline: "none"
            }
          },
          columnHeader: {
            ":focus-within": {
              outline: "none"
            }
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:nth-child(even):not(.Mui-selected)": { // for specificity reasons. just trust me.
              backgroundColor: "#f2f2f2"
            }
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: "bold"
          },
          root: {
            padding: "10px",
            borderBottom: "0"
          }
        }
      },
      MuiSnackbarContent: {
        styleOverrides: {
          message: {
            whiteSpace: "pre"
          }
        }
      }
    }
  },
  sideNavigationColor: "#212121",
  sideNavigationMenu: {
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            maxWidth: "inherit",
            color: "white",
            fontSize: "14px",
            paddingTop: "10px",
            paddingBottom: "10px",
            "&.Mui-selected": {
              "backgroundColor": "#0071EB"
            }
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            maxWidth: "inherit",
            color: "white",
            fontSize: "14px",
            paddingTop: "10px",
            paddingBottom: "10px",
            "&.Mui-selected": {
              "backgroundColor": "#0071EB"
            }
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            maxWidth: "inherit",
            color: "white",
            fontSize: "14px",
            paddingTop: "10px",
            paddingBottom: "10px"
          }
        }
      }
    }
  },
  appBarNavigationMenu: {
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
          },
          indicator: {
            display: "none"
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: "white",
            "&.Mui-selected": {
              color: "black",
              fontWeight: "bold",
              backgroundColor: "white",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px"
            }
          },
        }
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: "48px",
            paddingTop: "8px",
            paddingLeft: "4px",
            // why the media queries are necessary: https://github.com/mui/material-ui/issues/31358
            '@media(min-width: 0px) and (orientation: landscape)': {
              minHeight: "48px",
            },
            '@media(min-width: 600px)': {
              minHeight: "48px",
              paddingLeft: "12px",
            },
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: "0 8px 8px"
          }
        }
      }
    }
  },
  coloredTabControl: {
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: "#f2f2f2",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            minHeight: "initial",
            "&:not(:last-of-type)": {
              marginRight: "6px"
            },
            "&.Mui-selected": {
              color: "white"
            }
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "initial"
          },
          indicator: {
            display: "none"
          }
        }
      }
    }
  }
};
