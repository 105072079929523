import {
  React,
  bind
} from "$Imports/Imports"

import {
  Card,
  CardHeader,
  CardActions,
  Button
} from "$Imports/MaterialUIComponents"

import {
  DataTable,
  IDataTableColumn,
  UserAccessControl,
  AjaxActionIndicator,
  DisplayFormattedNumber,
  DisplayFormattedDatetime
} from "$Imports/CommonComponents";

import {
  FuelSurchargeService,
  IFuelSurchargeServiceInjectedProps
} from "$State/FuelSurchargeFreezerService"

import {
  FuelSurcharge
} from "$Generated/api"

import {
  AddFuelSurcharge
} from "./AddFuelSurcharge"

import { DATE_WITH_TIME_MERIDIAN_FORMAT } from "$Shared/utilities/formatUtil";

const styles: {
  mainContainer: string,
  cardStyle: string
} = require("$Shared/styles/Modal.scss");

interface IFuelSurchargeViewPageBaseProp {
}

type IFuelSurchargeViewPageProp = IFuelSurchargeViewPageBaseProp & IFuelSurchargeServiceInjectedProps;

class _FuelSurchargeViewPage extends React.Component<IFuelSurchargeViewPageProp> {

  componentDidMount() {
    this.props.fuelSurchargeService.fetchFuelSurcharges();
  }

  @bind
  private _onAddClick() {
    this.props.fuelSurchargeService.openAddSurchargeModal();
  }

  @bind
  private _onCancel() {
    this.props.fuelSurchargeService.clearAddSurchargeModal();
  }

  @bind
  private _onSave() {
    this.props.fuelSurchargeService.addFuelSurchargeValue();
  }

  @bind 
  private _onFuelSurchargeChange(fuelSurchargeValue: number | undefined) {
    this.props.fuelSurchargeService.updateFuelSurchargeModal({surchargeValue: fuelSurchargeValue});
  }

  private readonly columns: Array<IDataTableColumn<FuelSurcharge>> = [
    {
      columnName: "date",
      columnFieldData: (d) => <DisplayFormattedDatetime value={d.createdOn} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />,
      headerValue: "Date",
      sortMethod: (d) => d.createdOn,
    },
    {
      columnName: "fuelSurcharge",
      columnFieldData: (d) => (
        <DisplayFormattedNumber
          value={d.fuelSurchargeValue}
          formatString={"(0.00%)"}
        />
      ),
      headerValue: "Fuel Surcharge",
      sortMethod: (d) => d.fuelSurchargeValue ?? ""
    },
    {
      columnName: "active",
      columnFieldData: (d) => (
        <div>
          {d.isActive ? "True" : ""}
        </div>
      ),
      headerValue: "Active",
      sortMethod: (d) => d.isActive
    }
  ];

  render() {

    const {
      fuelSurchargeFetchResults,
      sortState,
      formMode,
      fuelSurchargeModalState,
      fuelSurchargeValidationErrors
    } = this.props.fuelSurchargeService.getState();

    const fuelSurchargeData = fuelSurchargeFetchResults.data ?? [];
    
    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader
            title="Fuel Surcharges"
          />
          <CardActions
            disableSpacing={true}
            style={{justifyContent: "flex-end"}}
          >
            <UserAccessControl roles={["fuel-surcharge-setup:create"]}>
              <Button
                color="primary"
                onClick={this._onAddClick}
              >
                Add
              </Button>
            </UserAccessControl>
          </CardActions>
          <AjaxActionIndicator
            state={fuelSurchargeFetchResults}
          />
          <DataTable
            columns={this.columns}
            data={fuelSurchargeData ?? []}
            defaultSortColumnName={sortState.sortColumnName}
            defaultSortDirection={sortState.sortDirection}
          />
        </Card>
        <AddFuelSurcharge
          formMode={formMode}
          modalState={fuelSurchargeModalState}
          validationErrors={fuelSurchargeValidationErrors}
          onCancel={this._onCancel}
          onSave={this._onSave}
          onFuelSurchargeChange={this._onFuelSurchargeChange}
          isFetching={fuelSurchargeFetchResults.isFetching}
        />
      </div>
    );
  }
}

export const FuelSurchargeViewPage = FuelSurchargeService.inject(
    _FuelSurchargeViewPage
);
