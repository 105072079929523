import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  Zone,
  ZoneConfiguration,
  ZoneConfigurationApiFactory
} from "$Generated/api";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

export type formModeType = "edit" | "none";

export interface IZoneRateConfig {
  minimumQuoteAmount?: number;
  lowRatePercentage?: number
}

interface IZoneConfigurationState {
  zoneConfigurationFetchResults: IAjaxState<ZoneConfiguration[]>;
  zoneConfigurationEditResults: IAjaxState<ZoneConfiguration[]>;
  originZones: Zone[] | null;
  selectedOrigin: Zone | null;
  selectedZoneConfigurations: ZoneConfiguration[] | null;
  formMode: formModeType;
  zoneConfigurationsForEdit: ZoneConfiguration[] | null;
  zoneConfigurationsForEditFromServer: ZoneConfiguration[] | null;
  validationErrors: ValidationError | null;
  defaultZoneRateConfig: IZoneRateConfig;
  defaultValidationErrors: ValidationError | null;
}

const InjectedPropName = "zoneConfigurationService";

export const ZoneRatesValidationSchema: SchemaOf<NullableOptional<IZoneRateConfig>> = yup.object({
  minimumQuoteAmount: yup.number().nullable().notRequired().min(0, "The value must be greater than or equal to 0"),
  lowRatePercentage: yup.number().nullable().notRequired()
    .min(0, "The value must be greater than or equal to 0")
    .lessThan(1, "The value must be less than 100") // strictly less than
});

const ZoneConfigurationValidationSchema: SchemaOf<NullableOptional<ZoneConfiguration>[]> = yup.array(yup.object({
    id: yup.number().notRequired(),
    originZoneId: yup.number().notRequired(),
    destZoneId: yup.number().notRequired(),
    companyId: yup.number().notRequired(),
    destZone: yup.object().notRequired(),
    originZone: yup.object().notRequired(),
    currentDatRate: yup.mixed().notRequired(),
    datPollDateTime: yup.mixed().notRequired(),
    createdOn: yup.date().notRequired(),
    modifiedOn: yup.date().notRequired()
  })
  .concat(ZoneRatesValidationSchema)
);

const initialState = {
  formMode: "none",
  zoneConfigurationFetchResults: managedAjaxUtil.createInitialState(),
  zoneConfigurationEditResults: managedAjaxUtil.createInitialState(),
  originZones: null,
  selectedOrigin: null,
  selectedZoneConfigurations: null,
  zoneConfigurationsForEdit: null,
  zoneConfigurationsForEditFromServer: null,
  validationErrors: null,
  defaultZoneRateConfig: {},
  defaultValidationErrors: null
} as IZoneConfigurationState;

class ZoneConfigurationFreezerService extends FreezerService<IZoneConfigurationState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public updateZoneConfigs(index: number, zoneConfig: Partial<ZoneConfiguration>) {
    const stateArray = this.freezer.get().zoneConfigurationsForEdit;
    if (stateArray !== null) {
      stateArray[index].set(zoneConfig);
    }
  }

  public updateDefaults(defaultValues: Partial<IZoneRateConfig>) {
    this.freezer.get().defaultZoneRateConfig.set(defaultValues);
  }

  public async applyDefaults() {
    const {
      zoneConfigurationsForEdit,
      defaultZoneRateConfig
    } = this.freezer.get().toJS();

    const errors = await validateSchema(ZoneRatesValidationSchema, defaultZoneRateConfig, {
      abortEarly: false
    });

    this.freezer.get().set({
      defaultValidationErrors: errors
    });

    if (errors) {
      return;
    }

    if (zoneConfigurationsForEdit !== null) {
      let newZoneConfigs = _.map(zoneConfigurationsForEdit, (zc) => {
        return {
          ...zc,
          lowRatePercentage: defaultZoneRateConfig.lowRatePercentage ?? zc.lowRatePercentage,
          minimumQuoteAmount: defaultZoneRateConfig.minimumQuoteAmount ?? zc.minimumQuoteAmount
        } as ZoneConfiguration
      });

      this.freezer.get().set({ zoneConfigurationsForEdit: newZoneConfigs });
    }
  }

  public onEditZoneConfig() {
    const selectedZoneConfigurations = this.freezer.get().selectedZoneConfigurations;
    if (selectedZoneConfigurations) {
      this.freezer.get().set({
        zoneConfigurationsForEdit: selectedZoneConfigurations.toJS(),
        formMode: "edit",
        zoneConfigurationsForEditFromServer: selectedZoneConfigurations.toJS(),
      });
    }
  }

  public clearEditZoneForm() {
    this.freezer.get().set({
      zoneConfigurationsForEdit: null,
      formMode: "none",
      zoneConfigurationsForEditFromServer: null,
      validationErrors: null,
      defaultZoneRateConfig: {},
      defaultValidationErrors: null
    });
  }

  public selectOriginZone(id: number) {
    const { zoneConfigurationFetchResults, originZones } = this.freezer.get()
    const zoneConfigData = zoneConfigurationFetchResults.data;
    const selectedOrigin = _.find(originZones, (d) => d.id === id);
    if (selectedOrigin && zoneConfigData) {
      this.freezer.get().set({
        selectedOrigin: selectedOrigin?.toJS(),
        selectedZoneConfigurations: _.filter(zoneConfigData.toJS(), (x) => x.originZoneId === id)
      });
    }
  }

  public async saveZoneConfigs(companyId: number | undefined) {
    const { zoneConfigurationsForEdit, selectedOrigin } = this.freezer.get();

    const editedData = zoneConfigurationsForEdit?.toJS();
    const selectedOriginData = selectedOrigin?.toJS();

    const errors = await validateSchema(ZoneConfigurationValidationSchema, zoneConfigurationsForEdit, {
      abortEarly: false
    });

    this.freezer.get().set({
      validationErrors: errors
    });

    if (errors) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "zoneConfigurationEditResults",
      onExecute: (apiOptions, param, options) => {
        const factory = ZoneConfigurationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1ZoneConfigurationUpdatezoneConfigurationsPost(param);
      },
      params: {
        body: editedData,
      },
      onOk: (data) => {
        this.clearEditZoneForm();
        this.fetchZoneConfigurations(companyId, true, selectedOriginData?.id);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save zone configuration.");
      }
    });
  }

  public fetchZoneConfigurations(companyId: number | undefined, forceUpdate: boolean = false, selectedOriginId?: number) {
    const { zoneConfigurationFetchResults: stateFetchResults } = this.freezer.get();

    if ((stateFetchResults.hasFetched && !forceUpdate) || !companyId) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "zoneConfigurationFetchResults",
      onExecute: (apiOptions, param, options) => {
        const factory = ZoneConfigurationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1ZoneConfigurationGet(param);
      },
      params: {
        companyId: companyId
      },
      onOk: (data: ZoneConfiguration[]) => {
        const originZones = _.unionBy(_.map(data, "originZone"), 'id') as Zone[];
        if (originZones) {
          let selectedOrigin = originZones[0];
          if (selectedOriginId !== undefined) {
            const foundOrigin = _.find(originZones, (d) => d.id === selectedOriginId);
            if (foundOrigin) {
              selectedOrigin = foundOrigin;
            }
          }
          this.freezer.get().set({
            originZones: originZones,
            selectedOrigin: selectedOrigin,
            selectedZoneConfigurations: _.filter(data, (x) => x.originZoneId === selectedOrigin.id)
          });
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch zone configurations.");
      }
    });
  }
}

export const ZoneConfigurationService = new ZoneConfigurationFreezerService();
export type IZoneConfigurationServiceInjectedProps = ReturnType<ZoneConfigurationFreezerService["getPropsForInjection"]>;
