import {
  React,
  _
} from "$Imports/Imports";

import {
  ICompanySelectServiceInjectedProps,
  CompanySelectService
} from "$State/CompanySelectFreezerService";

interface ICompanyNameBaseProps {
  companyId?: number;
}

type ICompanyNameProps = ICompanyNameBaseProps
  & ICompanySelectServiceInjectedProps;

class _CompanyName extends React.Component<ICompanyNameProps> {

  async componentDidMount() {
    await this.props.companySelectService.fetchData();
  }

  render() {
    const {
      companyId
    } = this.props;

    const {
      companyFetchResults
    } = this.props.companySelectService.getState();
    const companies = companyFetchResults.data ?? [];

    const company = _.find(companies, (c) => c.id === companyId);

    return company ? `Kaiser ${company.companyName}` : "";
  }
}

export const CompanyName = CompanySelectService.inject(
  _CompanyName
)