import {
  React,
  _
} from "$Imports/Imports";

import {
  IQuoteEntryServiceInjectedProps,
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

import {
  CustomerQuoteService,
  ICustomerQuoteServiceInjectedProps
} from "$State/CustomerQuotesFreezerService";

import {
  CustomerService,
  ICustomerServiceInjectedProps
} from "$State/CustomerFreezerService";

import {
  StateService,
  IStateServiceInjectedProps
} from "$State/RegionFreezerService";

import { 
  NavigationService
} from "$State/NavigationFreezerService";

import {
  DeclaredValueService,
  IDeclaredValueServiceInjectedProps
} from "$State/DeclaredValueFreezerService";

interface IConvertCustomerQuoteViewPageBaseProps {
  quoteId?: string;
}

type IConvertCustomerQuoteViewPageProps = IConvertCustomerQuoteViewPageBaseProps
  & ICustomerQuoteServiceInjectedProps
  & IStateServiceInjectedProps
  & ICustomerServiceInjectedProps
  & IQuoteEntryServiceInjectedProps
  & IDeclaredValueServiceInjectedProps;

const styles: {
  loading: string;
} = require("./ConvertCustomerQuoteViewPage.scss");

class _ConvertCustomerQuoteViewPage extends React.Component<IConvertCustomerQuoteViewPageProps> {
  async componentDidMount() {
    this.props.customerQuotesService.fetchQuoteById(this.props.quoteId);
    this.props.regionService.fetchStates();
    this.props.declaredValueService.fetchData();
  }
  
  render() {
    const { quote } = this.props.QuoteEntryService.getState();
    const { customerQuote } = this.props.customerQuotesService.getState();
    const { customerFetchResults } = this.props.customerService.getState();
    const customer = customerFetchResults.data;
    if (customer && !quote.quoteType){
      QuoteEntryService.setSelectedCustomer(customer);
    }
    if (customerQuote && quote.quoteType === "Full" && !quote.companyId){
      QuoteEntryService.prepForCustomerQuoteConvert(customerQuote);
    }
    
    if (quote.quoteFreights){
      NavigationService.navigateTo("/salesportal");
      
      // forceNavigate needs to be true for navigation without warning upon selecting a customer for quick quote conversion
      // after navigating to full quote, set forceNavigate to false to resume navigation warnings
      QuoteEntryService.freezer.get().set({
        forceNavigate: false
      });
    }
    return (
      <div className={styles.loading}>Loading...</div>
    );
  }
}

export const ConvertCustomerQuoteViewPage = CustomerQuoteService.inject (
  StateService.inject(
    CustomerService.inject(
      QuoteEntryService.inject(
        DeclaredValueService.inject(
          _ConvertCustomerQuoteViewPage
        )
      )
    )
  )
);
