import {
  React,
  _,
  bind,
  moment,
  Helpers
} from "$Imports/Imports";

import {
  TimeInputField
} from "$Imports/CommonComponents";

import {
  KeyboardDatePicker,
  FormHelperText,
  TextField,
  TextFieldProps
} from "$Imports/MaterialUIComponents";

import SharedConstants from "$Shared/utilities/SharedConstants";

const styles: {
  mainContainer: string,
  rowContainer: string,
  timeInput: string,
} = require("./DateTimeInputField.scss");

interface IDateTimeInputFieldProps {
  value: Date | null | undefined;
  onChange: (date: Date | undefined, timeEmpty?: boolean) => void;
  // "passthrough" props (add others as needed)
  required?: boolean;
  dateLabel?: string;
  timeLabel?: string;
  disablePast?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
}

interface IDateTimeInputFieldState {
  time: string;
}

export class DateTimeInputField extends React.PureComponent<IDateTimeInputFieldProps, IDateTimeInputFieldState> {
  state = {
    time: ""
  };

  componentDidMount() {
    if (this.props.value) {
      this.setState({ time: moment(this.props.value).format("HH:mm") });
    }
  }

  componentDidUpdate(prevProps: IDateTimeInputFieldProps, prevState: IDateTimeInputFieldState) {
    // set the time if we're getting a value and didn't have a previous value
    const prevDate = prevProps.value;
    var nowDate = this.props.value;

    if (Helpers.isNullOrUndefined(prevDate) && !Helpers.isNullOrUndefined(nowDate)) {
      this.setState({ time: moment(nowDate).format("HH:mm") });
    };
  }

  @bind
  private _onDateChange(date: Date | null, value?: string | null | undefined): void {
    if (!this.props.onChange) return;

    const stateTime = this.state.time;

    // if it's a valid date and we already have a valid time, preserve it
    if (date && moment(date).isValid() && stateTime && SharedConstants.TimeRegex.test(stateTime)) {
      const hh = parseInt(this.state.time.split(":")[0]);
      const mm = parseInt(this.state.time.split(":")[1]);

      var dateTime = new Date(date); // convert from moment object
      dateTime.setHours(hh);
      dateTime.setMinutes(mm);

      this.props.onChange(dateTime, stateTime === "");
    }
    else {
      this.props.onChange(date ?? undefined, stateTime === "");
    }
  }

  @bind
  private _onTimeChange(newTime: string | undefined) {
    var dateTime = this.props.value;

    if (newTime && SharedConstants.TimeRegex.test(newTime) && dateTime != undefined) {
      const hh = parseInt(newTime.split(":")[0]);
      const mm = parseInt(newTime.split(":")[1]);

      dateTime = new Date(dateTime); // if it's a moment or something
      dateTime.setHours(hh);
      dateTime.setMinutes(mm);
      this.props.onChange(dateTime);
    } else if (dateTime != undefined && newTime === "") {
      this.props.onChange(dateTime, true);
    }

    this.setState({
      time: newTime ?? ""
    });
  }

  @bind
  private _onTimeBlur() {
    // if the time value is not a valid time, set it back to whatever is in the props value
    const stateTime = this.state.time;
    const propsDate = this.props.value;
    if (propsDate && stateTime && !SharedConstants.TimeRegex.test(stateTime)) {
      this.setState({ time: moment(propsDate).format("HH:mm") });
    }
  }

  render() {
    const {
      value,
      required,
      dateLabel,
      timeLabel,
      disablePast,
      disabled,
      error,
      helperText
    } = this.props;

    const {
      time
    } = this.state;

    return (
      <div className={styles.mainContainer}>
        <div className={styles.rowContainer}>
          <KeyboardDatePicker
            value={value ?? null}
            onChange={this._onDateChange}
            inputFormat="MM/DD/YYYY"         
            label={dateLabel}
            disablePast={disablePast}
            disabled={disabled}
            renderInput={(props: TextFieldProps) => (<TextField 
              {...props}
              required={required}
              error={error}
              helperText={""} // suppress MUI's "Invalid Date Format" message
            />)}
            
          />
          <div className={styles.timeInput}>
            <TimeInputField
              value={time}
              onTimeChange={this._onTimeChange}
              label={timeLabel}
              disabled={disabled}
              onBlur={this._onTimeBlur}
              error={error}
            />
          </div>
        </div>
        <FormHelperText
          error={error}
          children={helperText ?? ""}
        />
      </div>
    );
  }
}
