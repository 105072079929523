import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  AccessorialCharge
} from "$Generated/api";

import {
  ISortState
} from "$Imports/CommonComponents";

import {
  Card,
  CardActions,
  CardHeader,
  Button,
  FormControlLabel,
  Switch
} from "$Imports/MaterialUIComponents";

import {
  DataTable,
  IDataTableColumn,
  AjaxActionIndicator,
  directionType
} from "$Imports/CommonComponents";

import {
  IAccessorialChargeServiceInjectedProps,
  AccessorialChargeService,
  EMPTY_ACCESSORIAL_CHARGE
} from "$State/AccessorialChargeFreezerService";

import {
  EditAccessorialChargeModal
} from "./EditAccessorialChargeModal";

import {
  ActionMenu
} from "./ActionMenu";

const styles: {
  mainContainer: string;
  actionArea: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface IAccessorialChargeViewPageBaseProps { }

interface IAccessorialChargeViewState {
  isAccessorialChargeOpen: boolean;
  filterActive: boolean;
  accessorialCharge: AccessorialCharge;
  sortState: ISortState;
}

type IAccessorialChargeViewPageProps = IAccessorialChargeViewPageBaseProps
  & IAccessorialChargeServiceInjectedProps;

class _AccessorialChargeViewPage extends React.Component<IAccessorialChargeViewPageProps, IAccessorialChargeViewState> {
  state: IAccessorialChargeViewState = {
    isAccessorialChargeOpen: false,
    filterActive: true,
    accessorialCharge: { ...EMPTY_ACCESSORIAL_CHARGE },
    sortState: {
      sortColumnName: "tmChargeCode",
      sortDirection: "asc",
    }
  }

  private readonly columns: Array<IDataTableColumn<AccessorialCharge>> = [
    {
      columnName: "description",
      columnFieldData: (x) => x.description,
      sortMethod: (x) => x.description,
      headerValue: "Description"
    },
    {
      columnName: "tmChargeCode",
      columnFieldData: (x) => x.tmChargeCode,
      sortMethod: (x) => x.tmChargeCode,
      headerValue: "TM Charge Code"
    },
    {
      columnName: "isActive",
      columnFieldData: (x) => x.isActive ? "Yes" : "No",
      sortMethod: (x) => x.isActive,
      headerValue: "Active"
    },
    {
      columnName: "action",
      columnFieldData: (x) => (
        <ActionMenu
          accessorialCharge={x}
          onEdit={this._toggleAccessorialChargeModal}
        />
      ),
      headerValue: ""
    }
  ];

  componentDidMount() {
    this.props.accessorialChargeService.fetchAccessorialCharges(true);
  }

  @bind
  private _onSortChange(event: React.MouseEvent<HTMLElement>, columnName: string | undefined, direction: directionType) {
    this.setState({
      sortState: {
        sortColumnName: columnName,
        sortDirection: direction,
      }
    });
  }

  @bind
  private _onActiveFilterChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.setState({ filterActive: checked });
    this.props.accessorialChargeService.fetchAccessorialCharges(checked, true);
  }

  @bind
  private async _toggleAccessorialChargeModal(seed?: AccessorialCharge) {
    const { isAccessorialChargeOpen } = this.state;
    if (isAccessorialChargeOpen) {
      this.setState({
        isAccessorialChargeOpen: false,
        accessorialCharge: { ...EMPTY_ACCESSORIAL_CHARGE }
      });
    }
    else {
      let editAccessorialCharge: AccessorialCharge = { ...EMPTY_ACCESSORIAL_CHARGE, isActive: true };
      if (seed) {
        editAccessorialCharge = _.cloneDeep(seed);
      }
      this.setState({
        isAccessorialChargeOpen: true,
        accessorialCharge: editAccessorialCharge
      });
    }
  }

  render() {
    const {
      accessorialChargeFetchResults,
      accessorialChargeSaveResult
    } = this.props.accessorialChargeService.getState();
    const {
      isAccessorialChargeOpen,
      filterActive,
      accessorialCharge,
      sortState
    } = this.state;
    let accessorialChargeData = accessorialChargeFetchResults.data ?? [];

    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader title="Accessorial Charges" />
          <CardActions
            disableSpacing={true}
            className={styles.actionArea}
          >
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={filterActive}
                    onChange={this._onActiveFilterChange}
                  />
                )
              }
              label="Only show active"
            />
            <Button
              color="primary"
              onClick={() => this._toggleAccessorialChargeModal()}
            >
              Add
            </Button>
          </CardActions>
          <AjaxActionIndicator
            state={[accessorialChargeFetchResults, accessorialChargeSaveResult]}
          />
          <DataTable
            columns={this.columns}
            data={accessorialChargeData}
            defaultSortColumnName={sortState.sortColumnName}
            defaultSortDirection={sortState.sortDirection}
            onSortChange={this._onSortChange}
          />
        </Card>
        <EditAccessorialChargeModal
          isOpen={isAccessorialChargeOpen}
          filterActive={filterActive}
          accessorialCharge={accessorialCharge}
          onClose={() => this._toggleAccessorialChargeModal()}
        />
      </div>
    );
  }
}

export const AccessorialChargeViewPage = AccessorialChargeService.inject(
  _AccessorialChargeViewPage
);
