import {
  React,
  _,
  bind,
  moment,
  NumberFormat,
  NumberFormatValues
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  KeyboardDatePicker,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  TextFieldProps
} from "$Imports/MaterialUIComponents";

import {
  Opportunity,
  OpportunityStatusEnum
} from "$Generated/api";

import { 
  EmployeeService,
  IEmployeeServiceInjectedProps
} from "$State/EmployeeFreezerService";

import {
  ILeadSourceServiceInjectedProps,
  LeadSourceService
} from "$State/LeadSourceFreezerService";

import {
  EMPTY_OPPORTUNITY,
  OPPORTUNITY_STATUSES
} from "$State/OpportunityFreezerService";

import {
  SecurityContext
} from "$Utilities/Security/ApplicationSecuritySettings";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  ErrorService
} from "$State/ErrorFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  OpportunityRequireDetailsValidationSchema,
  OpportunityValidationSchema
} from "$State/OpportunityValidationSchema";

interface IOwnState {
  opportunity: Opportunity;
  errors: ValidationError | null;
  disableSave: boolean;
  isDetailRequired: boolean;
  canSetProtectedFields: boolean;
}

interface IOwnProps {
  isOpen: boolean;
  opportunity?: Opportunity;
  onClose: (value?: Opportunity) => void;
}

type OwnProps = IOwnProps
  & ILeadSourceServiceInjectedProps
  & IEmployeeServiceInjectedProps;

class _OpportunityEditModal extends React.Component<OwnProps, IOwnState> {
  state: IOwnState = {
    opportunity: {
      ...EMPTY_OPPORTUNITY
    },
    errors: null,
    disableSave: false,
    isDetailRequired: false,
    canSetProtectedFields: false
  };

  componentDidMount() {
    this.props.leadSourceService.fetchLeadSources();
    this.props.employeeService.fetchSalesReps();

    // only admin users can set target revenue or reassign users
    if (SecurityContext.isInGroup("/Admin")) {
      this.setState({ canSetProtectedFields: true });
    };
  }

  componentDidUpdate(prev: IOwnProps) {
    if (prev.isOpen !== this.props.isOpen) {
      this.setState({
        disableSave: false
      });
    }

    if (this.props.opportunity !== prev.opportunity) {
      this.setState({
        opportunity: {
          ...EMPTY_OPPORTUNITY,
          ...this.props.opportunity
        },
        errors: null
      });

      this._setIsDetailRequired(this.props.opportunity?.leadSource?.linkId);
    }
  }

  @bind
  private _setIsDetailRequired(leadSourceId?: number): void {
    if (!leadSourceId) {
      this.setState({ isDetailRequired: false });
      return;
    }

    // check for lead source detail requirement
    const { fetchResult: fetchLeadSourcesResult } = this.props.leadSourceService.getState();
    const matchedLeadSource = fetchLeadSourcesResult.data?.find(x => x.id === leadSourceId);

    this.setState({ isDetailRequired: matchedLeadSource?.isDetailRequired ?? false });
  }

  @bind
  private _onAssignedUserChange(event: SelectChangeEvent): void {
    const employeeId = Number(event.target.value);
    
    this.setState((prev) => ({
      opportunity: {
        ...prev.opportunity,
        createdBy: {
          linkId: employeeId
        }
      }
    }));
  }

  @bind
  private _onTextInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState((prev) => ({
      opportunity: {
        ...prev.opportunity,
        [event.target.name]: event.target.value
      }
    }));
  }

  @bind
  private _onCloseDateChange(value: Date | null): void {
    this.setState((prev) => ({
      opportunity: {
        ...prev.opportunity,
        closeDate: moment(value).startOf('day').toDate()
      }
    }));
  }

  @bind
  private _onConfidenceChange(value: NumberFormatValues): void {
    this.setState((prev) => ({
      opportunity: {
        ...prev.opportunity,
        confidence: value.floatValue
      }
    }));
  }

  @bind
  private _onTargetRevenueChange(value: NumberFormatValues) {
    this.setState((prev) => ({
      opportunity: {
        ...prev.opportunity,
        targetRevenue: value.floatValue
      }
    }));
  }

  @bind
  private _onStatusChange(event: SelectChangeEvent): void {
    this.setState((prev) => ({
      opportunity: {
        ...prev.opportunity,
        status: event.target.value as OpportunityStatusEnum
      }
    }));
  }

  @bind
  private _onLeadSourceChange(event: SelectChangeEvent): void {
    const leadSourceId = Number(event.target.value);

    this.setState((prev) => ({
      opportunity: {
        ...prev.opportunity,
        leadSource: {
          linkId: leadSourceId
        }
      }
    }));

    this._setIsDetailRequired(leadSourceId);
  }

  @bind
  private async _onClose(shouldSave: boolean): Promise<void> {
    if (!shouldSave) {
      this.props.onClose(undefined);
      return;
    }

    const {
      opportunity,
      isDetailRequired
    } = this.state;

    const schema = isDetailRequired ? OpportunityRequireDetailsValidationSchema : OpportunityValidationSchema;
    const errors = await validateSchema(schema, opportunity, {
        abortEarly: false
      });

    if (errors) {
      this.setState({ errors: errors });
      return;
    }

    this.setState({
      disableSave: true
    });

    // TODO: self-contained save / service usage like reminder modal?
    this.props.onClose(opportunity);
  }

  @bind
  private removeInactiveSalesRep() {
    this.setState((prev) => ({
      opportunity: {
        ...prev.opportunity,
        createdBy: {
          linkId: undefined,
          linkName: undefined
        }
      }
    }));
    ErrorService.pushErrorMessage("Inactive sales representative removed.");
  }

  render() {
    const { fetchResult: fetchLeadSourcesResult } = this.props.leadSourceService.getState();
    const {
      activeSalesReps
    } = this.props.employeeService.getState();

    const leadSources = fetchLeadSourcesResult.data ?? [];
    const availableEmployees = activeSalesReps ?? [];

    const { isOpen } = this.props;

    const {
      opportunity,
      errors,
      canSetProtectedFields,
      isDetailRequired,
      disableSave
    } = this.state;

    if (isOpen && opportunity.createdBy?.linkId && !availableEmployees.find(e => e.id === opportunity.createdBy?.linkId)) {
      this.removeInactiveSalesRep();
    }

    const validationParser = new ValidationErrorParser<Opportunity>(errors);

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          {!opportunity.id ? "Create" : "Edit"} Sales Opportunity
        </DialogTitle>

        <DialogContent>
          <Grid container direction="row" wrap="wrap" spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Company"
                value={opportunity.company?.companyName ?? ""}
                disabled
              />
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth required error={!validationParser.isValidDeep("createdBy.linkId")}>
                <InputLabel shrink>Assigned Sales Representative</InputLabel>
                <Select
                  value={opportunity.createdBy?.linkId?.toString() ?? ""}
                  onChange={this._onAssignedUserChange}
                  disabled={!canSetProtectedFields}
                >
                  {_.map(availableEmployees, (x, idx) => (
                    <MenuItem value={x.id} key={idx}>
                      {x.firstName} {x.lastName}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{validationParser.validationMessageDeep("createdBy.linkId")}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <TextField
                label="Customer"
                value={opportunity.customer?.customerName ?? ""}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={opportunity.status}
                  onChange={this._onStatusChange}
                  error={!validationParser.isValid("status")}
                  required
                >
                  {_.map(OPPORTUNITY_STATUSES, (x, idx) => (
                    <MenuItem value={x} key={idx}>
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <KeyboardDatePicker
                value={opportunity.closeDate ?? null}
                onChange={this._onCloseDateChange}
                inputFormat="MM/DD/YYYY"
                label="Close Date"
                disablePast
                renderInput={(props: TextFieldProps) => (
                  <TextField
                    {...props}
                    error={!validationParser.isValid("closeDate")}
                    helperText={validationParser.validationMessage("closeDate")}
                    required
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <NumberFormat
                allowNegative={false}
                decimalScale={0}
                label="Confidence %"
                suffix={"%"}
                max={100}
                value={opportunity.confidence ?? ""}
                onValueChange={this._onConfidenceChange}
                customInput={TextField}
                error={!validationParser.isValid("confidence")}
                helperText={validationParser.validationMessage("confidence")}
              />
            </Grid>

            <Grid item xs={6}>
              <NumberFormat
                allowNegative={false}
                decimalScale={0}
                prefix="$"
                max={2000000000}
                thousandSeparator
                label="Target Revenue"
                value={opportunity.targetRevenue ?? ""}
                disabled={!canSetProtectedFields}
                onValueChange={this._onTargetRevenueChange}
                customInput={TextField}
                error={!validationParser.isValid("targetRevenue")}
                helperText={validationParser.validationMessage("targetRevenue")}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl
                error={!validationParser.isValidDeep("leadSource.linkId")}
                fullWidth
                required
              >
                <InputLabel>Lead Source</InputLabel>
                <Select
                  value={opportunity.leadSource?.linkId?.toString() ?? ""}
                  onChange={this._onLeadSourceChange}
                >
                  <MenuItem value={0}>- Select -</MenuItem>
                  {_.map(leadSources, (x, idx) => (
                    <MenuItem value={x.id} key={idx}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{validationParser.validationMessageDeep("leadSource.linkId")}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Lead Details"
                name="leadDetails"
                value={opportunity.leadDetails}
                onChange={this._onTextInputChange}
                maxRows={3}
                inputProps={{
                  maxLength: 300
                }}
                error={!validationParser.isValid("leadDetails")}
                helperText={validationParser.validationMessage("leadDetails")}
                multiline
                fullWidth
                required={isDetailRequired}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                value={opportunity.description}
                onChange={this._onTextInputChange}
                maxRows={3}
                inputProps={{
                  maxLength: 100
                }}
                error={!validationParser.isValid("description")}
                helperText={validationParser.validationMessage("description")}
                multiline
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            disabled={disableSave}
            onClick={() => this._onClose(true)}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={() => this._onClose(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const OpportunityEditModal = LeadSourceService.inject(
  EmployeeService.inject(_OpportunityEditModal)
);
