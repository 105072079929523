import {
  React
} from "$Imports/Imports";

import {
  Card,
  CardProps
} from "$Imports/MaterialUIComponents";

import {
  SelectedCompanyConsumer
} from "$Providers/index";


interface ICardLinedHeaderBaseProps {
  titleText: JSX.Element | string;
  titleComponents?: React.ReactNode;
}

const styles: {
  header: string,
  mainContainer: string
} = require('./CardLinedHeader.scss');

type ICardLinedHeaderProps = ICardLinedHeaderBaseProps & CardProps;

export class CardLinedHeader extends React.Component<ICardLinedHeaderProps> {

  render() {
    const {
      titleText,
      titleComponents,
      ...passThroughProps
    } = this.props;

    return (
      <SelectedCompanyConsumer>
        {companyContext =>
          <Card className={styles.mainContainer} {...passThroughProps}>
            <div className={styles.header} style={{borderBottomColor: companyContext.themeColor}}>
              {titleText}
              {titleComponents}
            </div>
            {this.props.children}
          </Card>}
      </SelectedCompanyConsumer>
    );
  }

}