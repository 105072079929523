import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  Helpers,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  PerStopFeeApiFactory,
  PerStopFee
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ISortState
} from "$Imports/CommonComponents";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

interface IPerStopFeeModalState {
  isOpen: boolean;
  newRecord: PerStopFee;
  validationErrors: ValidationError | null;
}

interface IPerStopFeeServiceState {
  perStopFeeFetchResults: IAjaxState<PerStopFee[]>;
  sortState: ISortState;
  perStopFeeModalState: IPerStopFeeModalState;
  perStopFeeSaveResults: IAjaxState;
}

const PerStopFeeValidationSchema: SchemaOf<NullableOptional<PerStopFee>> = yup.object({
  id: yup.number().notRequired(),
  fee: yup.number()
    .required("Per Stop Fee is required"),
  startDateTime: yup.date().notRequired(),
  endDateTime: yup.date().nullable().notRequired(),
  createdById: yup.number().notRequired(),
  createdBy: yup.mixed().nullable().notRequired(),
  companyId: yup.number().notRequired(),
  company: yup.mixed().nullable().notRequired(),
});

const InjectedPropName = "perStopFeeService";

const initialState = {
  perStopFeeFetchResults: managedAjaxUtil.createInitialState(),
  sortState: {
    sortColumnName: "endDateTime",
    sortDirection: "desc",
  },
  perStopFeeModalState: {
    isOpen: false,
    newRecord: {},
    validationErrors: null
  },
  perStopFeeSaveResults: managedAjaxUtil.createInitialState()
} as IPerStopFeeServiceState;

class PerStopFeeFreezerService extends FreezerService<IPerStopFeeServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchData() {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "perStopFeeFetchResults",
      params: {},
      onExecute: (apiOptions, params, options) => {
        const factory = PerStopFeeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1PerStopFeeFeesGet();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch per stop fees.");
      }
    });
  }

  public getPerStopFeeForCompany(companyId: number): PerStopFee | undefined {
    const perStopFeeFetchResults = this.freezer.get().perStopFeeFetchResults.toJS();
    if (perStopFeeFetchResults.data) {
      return _.find(perStopFeeFetchResults.data, (x) => x.companyId === companyId && Helpers.isNullOrUndefined(x.endDateTime));
    }

    return undefined;
  }

  public setSortState(sortState: Partial<ISortState>) {
    this.freezer.get().sortState.set(sortState);
  }

  public openEditModal(perStopFee: PerStopFee) {
    this.freezer.get().set({
      perStopFeeModalState: {
        isOpen: true,
        newRecord: {
          fee: perStopFee.fee,
          companyId: perStopFee.companyId,
          company: perStopFee.company
        },
        validationErrors: null
      }
    });
  }

  public closeEditModal() {
    this.freezer.get().set({
      perStopFeeModalState: {
        isOpen: false,
        newRecord: {},
        validationErrors: null
      }
    });
  }

  public async savePerStopFee() {
    const perStopFeeModalState = this.freezer.get().perStopFeeModalState.toJS();
    const errors = await validateSchema(PerStopFeeValidationSchema, perStopFeeModalState, {
      abortEarly: false,
      context: {}
    });

    this.freezer.get().perStopFeeModalState.set({ validationErrors: errors });

    if (errors) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "perStopFeeSaveResults",
      params: {
        body: perStopFeeModalState.newRecord,
      },
      onExecute: (apiOptions, param, options) => {
        const factory = PerStopFeeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1PerStopFeeAddPut(param);
      },
      onOk: (data) => {
        this.closeEditModal();
        this.fetchData();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save per stop fee record.");
      }
    });
  }

  public onChangeFromModal(value: Partial<PerStopFee>) {
    this.freezer.get().perStopFeeModalState.newRecord.set(value);
  }
}

export const PerStopFeeService = new PerStopFeeFreezerService();
export type IPerStopFeeServiceInjectedProps = ReturnType<PerStopFeeFreezerService["getPropsForInjection"]>;