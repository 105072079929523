import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  SelectedCompanyConsumer
} from "$Providers/index";

import {
  QuotesView
} from "./QuotesView";

interface IQuotesViewPageProps {

}

export class QuotesViewPage extends React.Component<IQuotesViewPageProps> {
  render() {
    return (
      <SelectedCompanyConsumer>
        {(companyContext) => { 
          return (
            <QuotesView companyId={companyContext.companyId} quoteType={"Full"}/>
          );
        }}
      </SelectedCompanyConsumer>
    );
  }
}
