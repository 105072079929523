import {
  CustomerQuote,
  QuoteFreight
} from "$Generated/api";

import {
  IDataTableColumn,
  DataTable,
  DisplayFormattedNumber,
  DisplayFormattedFeet,
} from "$Imports/CommonComponents";

import {
  React,
  _
} from "$Imports/Imports";

import {
  getTotalPieceCount
} from "$Utilities/ratingUtil";

const styles: {
  title: string,
  freightTable: string,
  freightColumns: string,
  sideBySideColumn: string,
  totalRow: string
} = require("./CustomerQuoteFreightView.scss");

interface ICommodityDataEntryViewProps {
  customerQuote: CustomerQuote;
  freightTotalLength: number;
}

export class CustomerQuoteFreightView extends React.Component<ICommodityDataEntryViewProps> {

  render() {
    const {
      customerQuote,
      freightTotalLength
    } = this.props;

    const quoteFreight = customerQuote.customerQuoteFreights ?? [];

    const columns: Array<IDataTableColumn<QuoteFreight>> = [
      {
        columnName: "commodity-name",
        columnFieldData: (d) => {
          return `${(d.commodity?.commodityShortName ?? d.commodity?.commodityName)} ${d.numberOfPieces && d.numberOfPieces > 1 ? `(` + d.numberOfPieces + `)` : ""}`
        },
        cellProps: {
          className: styles.freightColumns
        },
        headerValue: "Commodity",
        headerProps: {
          className: styles.freightColumns,
          style: { width:"130px" }
        }
      },
      {
        columnName: "numberOfPieces",
        columnFieldData: (d) => {
          return d?.numberOfPieces;
        },
        cellProps: {
          className: styles.freightColumns
        },
        headerValue: "# of Pieces",
        headerProps: {
          className: styles.freightColumns,
          style: { width: "50px" }
        },
        sortMethod: (d) => _.reduce(_.map(quoteFreight, qsf => qsf.numberOfPieces), ((sum, p) => (sum ?? 0) + (p ?? 0))) ?? 0
      },
      {
        columnName: "length",
        columnFieldData: (d) => <DisplayFormattedFeet value={d.length}/>,
        cellProps: {
          className: styles.freightColumns
        },
        headerValue: "Length",
        headerProps: {
          className: styles.freightColumns,
          style: { width:"55px" }
        },
        sortMethod: (d) => d.length
      },
      {
        columnName: "width",
        columnFieldData: (d) => <DisplayFormattedFeet value={d.width}/>,
        cellProps: {
          className: styles.freightColumns
        },
        headerValue: "Width",
        headerProps: {
          className: styles.freightColumns,
          style: { width:"55px" }
        },
        sortMethod: (d) => d.width
      },
      {
        columnName: "height",
        columnFieldData: (d) => <DisplayFormattedFeet value={d.height}/>,
        cellProps: {
          className: styles.freightColumns
        },
        headerValue:  "Height",
        headerProps: {
          className: styles.freightColumns,
          style: { width:"55px" }
        },
        sortMethod: (d) => d.height
      },
      {
        columnName: "weight",
        columnFieldData: (d) => <DisplayFormattedNumber value={d.weight} formatString={"0"} postfix={" lbs"}/>,
        cellProps: {
          className: styles.freightColumns
        },
        headerValue: "Weight",
        headerProps: {
          className: styles.freightColumns,
          style: { width:"85px" }
        },
        sortMethod: (d) => d.weight
      },
      {
        columnName: "isStackable",
        columnFieldData: (d) => d.isStackable && d.isStackable ? "Yes" : "No",
        cellProps: {
          className: styles.freightColumns
        },
        headerValue: "Stackable",
        headerProps: {
          className: styles.freightColumns,
          style: { width:"50px" }
        },
        sortMethod: (d) => d.isStackable
      },
      {
        columnName: "isSideBySide",
        columnFieldData: (d) => d.isSideBySide && d.isSideBySide ? "Yes" : "No",
        cellProps: {
          className: styles.sideBySideColumn
        },
        headerValue: "SbS",
        headerProps: {
          className: styles.freightColumns,
          style: { width:"45px" }
        },
        sortMethod: (d) => d.isSideBySide
      }
    ];

    

    let totalRow: JSX.Element | undefined = undefined;
    const totalNumOfPieces = getTotalPieceCount(quoteFreight);
    if (totalNumOfPieces > 0) {
      const totalWeight = _.sum(_.map(quoteFreight, qf => (qf.numberOfPieces ?? 1) * (qf.weight ?? 0)));

      totalRow = <tr className={styles.totalRow}>
        <td>TOTAL</td>
        <td>{totalNumOfPieces}</td>
        <td>{
          customerQuote?.rateVariableFactor === "Overdimensional"
          ?
          <div style={{ whiteSpace: "nowrap" }}>
            {"--"} <span style={{ color: "red", fontSize: "12px", paddingLeft: "20px" }}>{"Over Dimensional / Requires Approval"}</span>
          </div>
          :
          <div><DisplayFormattedFeet value={freightTotalLength} /></div>
        }</td>
        <td></td>
        <td></td>
        <td><DisplayFormattedNumber value={totalWeight} formatString={"0"} postfix={" lbs"}/></td>
        <td></td>
        <td></td>
      </tr>
    }

    return (
      <>
        <div className={styles.title}>Freight Dimensions</div>
        <DataTable
          columns={columns}
          data={quoteFreight}
          tableFooterComponent={totalRow}
        />
      </>
    );
  }
}
