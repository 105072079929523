import {
  React,
  bind
} from "$Imports/Imports";

import {
  ActionMenu
} from "./ActionMenu"

import {
  AddEditTerminal
} from "./AddEditTerminal"

import {
  UserAccessControl,
  DataTable,
  IDataTableColumn,
  AjaxActionIndicator
} from "$Imports/CommonComponents"

import {
  Terminal
} from "$Generated/api"

import {
  Card,
  CardHeader,
  CardActions,
  Button
} from "$Imports/MaterialUIComponents";

import {
  TerminalFreezerService,
  ITerminalServiceInjectedProps,
  TerminalModalState
} from "$State/TerminalsFreezerService"

import {
  StateService,
  IStateServiceInjectedProps
} from "$State/RegionFreezerService"

const styles: {
  tableCellTextContainer: string,
  undefinedCellTextContainer: string,
  undefinedTerminalName: string
} = require("./TerminalsViewPage.scss");

const containerStyles: {
  mainContainer: string,
  cardStyle: string,
  actionArea: string,
} = require("$Shared/styles/Modal.scss");

interface ITerminalsViewPageBaseProp {

}

type ITerminalsViewPageProp = ITerminalsViewPageBaseProp & ITerminalServiceInjectedProps & IStateServiceInjectedProps

class _TerminalsViewPage extends React.Component<ITerminalsViewPageProp> {

  componentDidMount() {
    this.props.terminalService.fetchTerminals();
    this.props.terminalService.fetchUnknownTerminal();
    this.props.regionService.fetchStates();
  }

  @bind
  private _onTerminalUpdate(terminal: Terminal) {
    this.props.terminalService.updateTerminal(terminal);
  }

  @bind
  private _onTerminalModalChange(terminalModal: Partial<TerminalModalState>) {
    this.props.terminalService.updateTerminalModal(terminalModal);
  }

  @bind
  private _onTerminalNameChange(terminal: string) {
    this.props.terminalService.updateEditAddTerminal({
      terminalName: terminal
    });
  }

  @bind
  private _onAddClick() {
    this.props.terminalService.addTerminal();
  }

  @bind
  private _onAddEditCancel() {
    this.props.terminalService.clearEditAddForm();
  }

  @bind
  private _onAddEditSave() { 
    this.props.terminalService.addEditTerminal();
  }

  @bind 
  private _onTerminalDelete(terminal: Terminal) {
    this.props.terminalService.deleteTerminal(terminal);
  }

  @bind 
  private _onModalDeleteConfirm() {
    this.props.terminalService.onModalDeleteConfirm();
  }

  @bind 
  private _onModalDeleteCancel() {
    this.props.terminalService.onModalDeleteCancel();
  }

  private readonly columns: Array<IDataTableColumn<Terminal>> = [
    {
      columnName: "terminalName",
      columnFieldData: (d) => d.terminalName &&
       <div className={d.terminalName == "Undefined" ? styles.undefinedTerminalName : undefined}>
          {d.terminalName}
       </div>,
      headerValue: "Terminal",
      sortMethod: (t) => t.id
    },
    {
      columnName: "regionName",
      columnFieldData: (d) => d.terminalRegions && 
      <div className={d.terminalName == "Undefined" ? styles.undefinedCellTextContainer 
        : styles.tableCellTextContainer}>
        {d.terminalRegions.map(tr => tr.region?.regionAbbreviation).sort().join(", ")}
      </div>,
      headerValue: "States",
      sortMethod: (d) => d.terminalRegions && d.terminalRegions.map(tr => tr.region?.regionAbbreviation).sort().join(", ")
    },
    {
      columnName: "action",
      columnFieldData: (d) => (
        d.terminalName !== "Undefined" &&
        <ActionMenu
          terminal={d}
          onEditTask={this._onTerminalUpdate}
          onDelete={this._onTerminalDelete}
        />
      ),
      headerValue: ""
    }
  ];

  render() {

    const {
      regionFetchResults
    } = this.props.regionService.getState();

    const {
      terminalsFetchResults,
      undefinedTerminalFetchResult,
      sortState,
      formMode,
      editAddTerminal,
      terminalModalState,
      terminalValidationErrors,
      duplicateRegionsFound,
      duplicateTerminalRegionsFetchResult
    } = this.props.terminalService.getState();

    var terminalData = terminalsFetchResults.data ?? [];
    var undefinedTerminal = undefinedTerminalFetchResult.data ?? {};

    const stateData = regionFetchResults.data ?? [];

    const terminalRegionsWithDuplicateRegions = duplicateTerminalRegionsFetchResult.data;

    terminalData = [...terminalData, undefinedTerminal];

    return (
      <>
        <div className={containerStyles.mainContainer}>
          <Card className={containerStyles.cardStyle}>
            <CardHeader
              title={"Terminals"}
            />
            <CardActions
              disableSpacing={true}
              className={containerStyles.actionArea}
            >
              <UserAccessControl roles={["terminals-setup:create"]}>
                <Button
                  color="primary"
                  onClick={this._onAddClick}
                  disabled={undefined}
                >
                  Add
                </Button>
              </UserAccessControl>
            </CardActions>
            <AjaxActionIndicator
              state={[terminalsFetchResults, undefinedTerminalFetchResult]}
            />
            <DataTable
              columns={this.columns}
              data={terminalData}
              defaultSortColumnName={sortState.sortColumnName}
              defaultSortDirection={sortState.sortDirection}
            />
          </Card>
        </div>
        <AddEditTerminal
          formMode={formMode}
          terminal={editAddTerminal}
          terminalModalState={terminalModalState}
          isFetching={terminalsFetchResults.isFetching || regionFetchResults.isFetching}
          duplicateRegionsFound={duplicateRegionsFound}
          terminalRegionsWithDuplicateRegions={terminalRegionsWithDuplicateRegions}
          onTerminalNameChange={this._onTerminalNameChange}
          onTerminalModalChange={this._onTerminalModalChange}
          onCancel={this._onAddEditCancel}
          onSave={this._onAddEditSave}
          onModalDeleteConfirm={this._onModalDeleteConfirm}
          onModalDeleteCancel={this._onModalDeleteCancel}
          terminalValidationErrors={terminalValidationErrors}
          stateData={stateData}
        />
      </>
    )
  }
}

export const TerminalsViewPage = 
  StateService.inject(
    TerminalFreezerService.inject(_TerminalsViewPage)
  );