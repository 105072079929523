import {
  _
} from "$Imports/Imports";

import {
  DisplayFormattedFeet
} from "$Imports/CommonComponents";

import {
  FreightTotalData
} from "$State/QuoteEntryFreezerService";

export function getLengthContent(freightTotals: FreightTotalData, requireRetotal: boolean, highlightStyle: string): JSX.Element {
  let content: JSX.Element;
  if (freightTotals.isOverdimensional) {
    content = (<span className={highlightStyle}>{"Over Dimensional / Requires Approval"}</span>);
  }
  else if ((!freightTotals.totalLength || requireRetotal) && (freightTotals?.totalNumOfPieces ?? 0 > 0)) {
    content = (<span className={highlightStyle}>{freightTotals.ratingError ?? "Measurement totals require recalculation"}</span>);
  }
  else {
    content = (<><b>Total Length</b>: <DisplayFormattedFeet value={freightTotals.totalLength} /></>);
  }

  return content;
}

export function getWeightContent(freightTotals: FreightTotalData, highlightStyle: string): JSX.Element {
  let content: JSX.Element;
  let className: string | undefined = undefined;

  const totalWeight = `${freightTotals.totalWeight ?? 0} lbs`;

  if (freightTotals.isOverdimensional || (!freightTotals.totalWeight && (freightTotals.totalNumOfPieces ?? 0 > 0))) {
    className = highlightStyle;
  }

  return (<><b>Total Weight</b>: <span className={className}>{totalWeight}</span></>);
}

export function getTotalPieceCount<T extends { numberOfPieces?: number }>(quoteFreight: T[]): number {
  return _.sum(_.map(quoteFreight, qf => qf.numberOfPieces ?? 0))
}

export const DEFAULT_TIMEOUT: number = 30000;

export const TIMEOUT_BUFFER: number = 10000;
