import {
  React
} from "$Imports/Imports";

import {
  SelectedCompanyConsumer
} from "$Providers/index";

import {
  ZoneConfigurationView
} from "./ZoneConfigurationView";

interface IZoneConfigurationViewPageProps {

}

export class ZoneConfigurationViewPage extends React.Component<IZoneConfigurationViewPageProps> {
  render() {
    return (
      <SelectedCompanyConsumer>
        {(companyContext) => { 
          return (
            <ZoneConfigurationView companyId={companyContext.companyId} />
          );
        }}
      </SelectedCompanyConsumer>
    );
  }
}