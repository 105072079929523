import {
  bind,
  React,
  _
} from "$Imports/Imports";

import {
  ReminderEditModal
} from "$Imports/CommonComponents";

import {
  Reminder
} from "$Generated/api";

import {
  EMPTY_REMINDER,
  IReminderServiceInjectedProps, ReminderService
} from "$State/ReminderFreezerService";

import {
  ISelectedCompanyContext
} from "$Providers/CompanyProvider";

import {
  RateInfoSection
} from "./RateInfoSection";

import {
  QuoteView
} from "./QuoteView"

import {
  EmployeeService,
  IEmployeeServiceInjectedProps
} from "$State/EmployeeFreezerService";

interface ISalesPortalViewBaseProps {
  companyContext: ISelectedCompanyContext
}

type SalesPortalViewProps = ISalesPortalViewBaseProps
  & IReminderServiceInjectedProps
  & IEmployeeServiceInjectedProps;

interface ISalesPortalViewState {
  isReminderModalOpen: boolean;
  reminder: Reminder;
}

class _SalesPortalView extends React.Component<SalesPortalViewProps, ISalesPortalViewState> {
  state: ISalesPortalViewState = {
    isReminderModalOpen: false,
    reminder: {
      ...EMPTY_REMINDER
    }
  };

  @bind
  private _toggleReminderModal(seed?: Reminder): void {
    this.setState((prev) => ({
      isReminderModalOpen: !prev.isReminderModalOpen,
      reminder: seed ? {
        ...seed
      } : { ...EMPTY_REMINDER }
    }));
  }

  @bind
  private async _onCreateReminder(model?: Reminder): Promise<void> {
    this._toggleReminderModal();
  }

  render() {
    const {
      isReminderModalOpen,
      reminder
    } = this.state;

    const {
      activeSalesReps
    } = this.props.employeeService.getState();
    const salesReps = _.orderBy(activeSalesReps ?? [], s => s.lastName);

    return (
      <div style={{ display: "flex" }}>
        <QuoteView companyId={this.props.companyContext.companyId} themeColor={this.props.companyContext.themeColor} />
        <RateInfoSection
          companyId={this.props.companyContext.companyId}
          tmCompanyId={this.props.companyContext.tmCompanyId}
          onCreateReminder={this._toggleReminderModal}
        />

        <ReminderEditModal
          isOpen={isReminderModalOpen}
          reminder={reminder}
          onClose={this._onCreateReminder}
          canSelectType={!reminder.quote?.linkId}
          salesReps={salesReps}
        />
      </div>
    );
  }
}

export const SalesPortalView = ReminderService.inject(
  EmployeeService.inject(
    _SalesPortalView
  )
);
