import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  ApplicationSetting
} from "$Generated/api";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from "$Imports/MaterialUIComponents";

import {
  ThreeDigitZipEntry
} from "$Imports/CommonComponents";

interface ILocationsWithLengthRestrictionsEditModalProps {
  defaultLWLRestrictions?: ApplicationSetting; 
  isModalOpen?: boolean;
  onLWLRestrictionsChange?: (applicationSetting: Partial<ApplicationSetting>) => void;
  onSaveLWLRestrictions?: () => void; 
}

interface IOwnState {
  editedZipCodes?: string[];
  newZipCode?: string;
  error: boolean;
  errorMessage: string | null;
}

export class LocationsWithLengthRestrictionsEditModal extends React.PureComponent<ILocationsWithLengthRestrictionsEditModalProps, IOwnState> {
  state: IOwnState = {
    editedZipCodes: [],
    newZipCode: undefined,
    error: false,
    errorMessage: null
  }

  componentDidUpdate(prevProps: Readonly<ILocationsWithLengthRestrictionsEditModalProps>): void {
    if (prevProps.defaultLWLRestrictions !== this.props.defaultLWLRestrictions) {
      this.setState({ editedZipCodes: this.props.defaultLWLRestrictions?.settingsValue?.split(',') ?? []});
    }
  }

  @bind
  private _onLWLRestrictionsChange(zipCodes: string | undefined) {
    if (this.props.onLWLRestrictionsChange) {
      this.props.onLWLRestrictionsChange({ settingsValue: zipCodes });
    }
  }

  @bind
  private _onSave() {
    if (this.props.onSaveLWLRestrictions) {
      this.props.onSaveLWLRestrictions();
    }
  }

  @bind
  private _onZipCodeChange(zipCode: string) {
      this.setState({ newZipCode: zipCode });
  }

  @bind
  private _onZipCodeEnter() {
    const { defaultLWLRestrictions } = this.props;
    const {newZipCode, editedZipCodes } = this.state;
    const zipCodesString = defaultLWLRestrictions?.settingsValue;

    //control for duplicate zips
    if (_.includes(editedZipCodes, newZipCode)) {
      this.setState({
        newZipCode: "",
        error: false,
        errorMessage: null
      });

      return;
    }

    const isValid = this._isValidNewZipCode();

    if(!isValid) {
      return;
    }

    if(editedZipCodes && newZipCode) {
      const newValue = zipCodesString ? zipCodesString + ',' + newZipCode : newZipCode.toString();
      this._onLWLRestrictionsChange(newValue);
      editedZipCodes.push(newZipCode);
    }

    this.setState({
      editedZipCodes: editedZipCodes,
      newZipCode: "",
      error: false,
      errorMessage: null
    });
  }

  @bind
  private _isValidNewZipCode(): boolean {
    const { newZipCode } = this.state;
    if (newZipCode) {
      const isValidLength = newZipCode.length === 3;
      const isValidFormat = !!newZipCode.match(/^(\d+)$/);
      const message = !isValidLength ? 'Zip code must be three digits long' : !isValidFormat ? 'Zip code must be a number' : null;
      if (message) {
        this.setState({
          error: true,
          errorMessage: message
        });
        return false;
      }
    }

    this.setState({
      error: false,
      errorMessage: null
    });
    return true;
  }

  @bind
  private _onZipCodeDelete(zipCode: string) {
    const { editedZipCodes } = this.state;
    var indexOfZip = editedZipCodes?.indexOf(zipCode);

    if (indexOfZip !== undefined && indexOfZip > -1) {
      this.state.editedZipCodes?.splice(indexOfZip, 1);
      this._onLWLRestrictionsChange(editedZipCodes && editedZipCodes?.length > 0 ? editedZipCodes?.toString() : undefined);

      this.setState({
        editedZipCodes: editedZipCodes
      });
    }
  }

  render() {

    const {
      isModalOpen
    } = this.props;

    const {
      newZipCode,
      editedZipCodes,
      error,
      errorMessage
    } = this.state;

    return (
      <Dialog open={isModalOpen ?? false}>
      <DialogTitle>
        Locations with Length Restrictions
      </DialogTitle>
      <DialogContent>
        <ThreeDigitZipEntry
          label={"Zip Codes"}
          threeDigitZips={editedZipCodes ?? []}
          newZipCode={newZipCode ?? ""}
          textFieldError={error}
          helperText={error ? errorMessage : "Type each three-digit zip code and press Enter"}
          onZipCodeChange={this._onZipCodeChange}
          onZipCodeEnter={this._onZipCodeEnter}
          onZipCodeDelete={this._onZipCodeDelete}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={this._onSave}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
    )
  }
}