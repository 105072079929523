import {
  React,
  Helpers,
  bind,
  _
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  AdvanceTextField,
  AjaxActionIndicator,
  ConfirmDeleteModal,
  RegionSelectionModal
} from "$Imports/CommonComponents";

import {
  Terminal,
  Region,
  TerminalRegion
} from "$Generated/api";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Grid,
  Typography,
} from "$Imports/MaterialUIComponents";

import {
  formModeType,
  TerminalFreezerService,
  TerminalModalState
} from "$State/TerminalsFreezerService";

const styles: {
  container: string;
  stateField: string;
  stateButton: string;
} = require("./AddEditTerminal.scss");

interface IAddEditTerminalProps {
  formMode: formModeType;
  stateData: Region[];
  terminal: Terminal | null;
  terminalModalState: TerminalModalState;
  terminalValidationErrors: ValidationError | null;
  isFetching?: boolean;
  terminalWithDuplicateState?: Terminal | null | undefined;
  duplicateRegionsFound: boolean,
  terminalRegionsWithDuplicateRegions: TerminalRegion[] | null | undefined;
  onSave?: () => void;
  onCancel?: () => void;
  onModalDeleteConfirm?: () => void;
  onModalDeleteCancel?: () => void;
  onTerminalNameChange?: (terminal: string) => void;
  onTerminalModalChange?: (terminalModal: Partial<TerminalModalState>) => void
  duplicateStatesFound: boolean;
}

interface IAddEditTerminalState {
  stateSelectOpen: boolean
}

export class AddEditTerminal extends React.PureComponent<IAddEditTerminalProps> {

  state: IAddEditTerminalState = {
    stateSelectOpen: false
  }

  static defaultProps: Partial<IAddEditTerminalProps> = {
    isFetching: false,
  };

  private renderStatesSelection(selected: Region[]): string {
    const { stateData } = this.props;
    const usRegions = stateData.filter(x => x.countryId === 1);
    const canadaRegions = stateData.filter(x => x.countryId === 2);

    const allStatesSelected = selected.filter(x => x.countryId === 1).length === usRegions.length;
    const allProvincesSelected = selected.filter(x => x.countryId === 2).length === canadaRegions.length;

    if (allStatesSelected && allProvincesSelected) {
      return "All States, All Provinces"
    } else if (allStatesSelected) {
      if (selected.filter(x => x.countryId === 2).length > 0) {
        return "All States, " + selected.filter(x => x.countryId === 2).map(s => s.regionAbbreviation).sort().join(", ")
      }
      return "All States"
    } else if (allProvincesSelected) {
      if (selected.filter(x => x.countryId === 1).length > 0) {
        return "All Provinces, " + selected.filter(x => x.countryId === 1).map(s => s.regionAbbreviation).sort().join(", ")
      }
      return "All Provinces"
    } else {
      return selected.map(s => s.regionAbbreviation).sort().join(", ")
    }
  }

  @bind
  private _onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  @bind
  private _onSubmit() {
    if (this.props.onSave) {
      this.props.onSave();
    }
  }

  @bind
  private _onTerminalNameChange(terminalName: string) {
    if (this.props.onTerminalNameChange){
      this.props.onTerminalNameChange(terminalName);
    }
  }

  @bind 
  private _openStateSelect() {
    this.setState({
      stateSelectOpen: true
    });
  }

  @bind
  private _closeStateSelect() {
    this.setState({
      stateSelectOpen: false
    })

    if (this.props.onTerminalModalChange) {
      this.props.onTerminalModalChange({
        tempTerminalRegions: TerminalFreezerService.getState().terminalModalState.editedTerminalRegions
      });
    }
    
  }

  @bind
  private _onStateSelectionChange(selected: Region[]) {
    if (this.props.onTerminalModalChange) {
      this.props.onTerminalModalChange({
        tempTerminalRegions: selected.map(r => {
          return {
            regionId: r.id,
            region: r
          }
        })
      });
    }
  }

  @bind
  private _onStateSelectionSave(regions: Region[] | undefined) {
    if (regions && this.props.onTerminalModalChange) {
      const mappedRegions = regions.map(r => {
        return {
          regionId: r.id,
          region: r
        }
      });
      this.props.onTerminalModalChange({
        editedTerminalRegions: mappedRegions
      });
    }
    this._closeStateSelect()
  }

  @bind 
  private _onModalDeleteConfirm() {
    if (this.props.onModalDeleteConfirm) {
      this.props.onModalDeleteConfirm();
    }
  }

  @bind
  private _onModalDeleteCancel() {
    if (this.props.onModalDeleteCancel) {
      this.props.onModalDeleteCancel();
    }
  }

  render() {
    const {
      formMode,
      terminal,
      isFetching,
      stateData,
      terminalModalState,
      terminalValidationErrors,
      terminalRegionsWithDuplicateRegions,
      duplicateRegionsFound
    } = this.props;

    const terminalStates = terminalModalState?.tempTerminalRegions?.map(tr => tr.region!);
    const viewTerminalState = terminalModalState?.editedTerminalRegions?.map(tr => tr.region!);

    const validationsParser = new ValidationErrorParser<Terminal>(terminalValidationErrors);

    const deleteMessage = 
      <Typography variant="body1">
        <div>The following regions were found in other terminals: </div>
          {terminalRegionsWithDuplicateRegions?.map((tr) => { return <div style={{textAlign:"center"}}>{tr.region?.regionAbbreviation}</div>})}
        <div>Do you wish to delete these regions from the terminals they currently are in?</div>
      </Typography>

    return !Helpers.isNullOrUndefined(terminal) && !Helpers.isNullOrUndefined(stateData) ? (
      <>
        <Dialog
          open={formMode === "add" || formMode === "edit"}
          fullWidth={true}
          maxWidth="sm"
        >
          <AjaxActionIndicator
            showProgress={isFetching}
          />
          <DialogTitle>
            {formMode === "add" ? "Add Terminal" : `Edit Terminal`}
          </DialogTitle>
          <DialogContent
            className={styles.container}
          >
            <Grid container direction={"column"} spacing={3}>
              <Grid item>
                <AdvanceTextField
                  label="Terminal Name"
                  value={terminal?.terminalName ?? ""}
                  onDebouncedChange={this._onTerminalNameChange}
                  error={!validationsParser.isValid("terminalName")}
                  helperText={validationsParser.validationMessage("terminalName")}
                  disabled={formMode == "edit" ? true : false}
                />
              </Grid>
              <Grid item className={styles.stateButton}>
                <Button
                  color="primary"
                  onClick={this._openStateSelect}
                  style={{ textTransform: "none" }}
                  disabled={isFetching}
                >
                  Select Terminal States
                </Button>
              </Grid>
              <Grid item className={styles.stateField}>
                <TextField
                  label="Terminal States"
                  value={this.renderStatesSelection(viewTerminalState ?? [])}
                  error={!validationsParser.isValid("terminalRegions")}
                  helperText={validationsParser.validationMessage("terminalRegions")}
                  multiline
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={isFetching}
              onClick={this._onSubmit}
            >
              {formMode === "add" ? "Add" : "Save"}
            </Button>
            <Button
              variant="outlined"
              onClick={this._onCancel}
              disabled={isFetching}
            >
              Cancel
            </Button>
          </DialogActions>
          <RegionSelectionModal
            isOpen={this.state.stateSelectOpen}
            onCancel={this._closeStateSelect}
            onSave={this._onStateSelectionSave}
            onSelectedChange={this._onStateSelectionChange}
            regions={stateData}
            selected={terminalStates ?? []}
          />
        </Dialog >
        <ConfirmDeleteModal
          isOpen={duplicateRegionsFound}
          onDelete={this._onModalDeleteConfirm}
          onDeleteCancel={this._onModalDeleteCancel}
          deleteMessage={""}
          deleteTypography={deleteMessage}
        />
      </>
    ) : null;
  }
}


