import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Question,
  QuoteStopFreightQuestion,
  QuoteStopFreightQuestionAnswerEnum
} from "$Generated/api";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from "$Imports/MaterialUIComponents";

interface IOwnProps {
  commodityName: string | undefined;
  questions: Question[];
  responses: QuoteStopFreightQuestion[];
  isOpen: boolean;
  viewOnly?: boolean;
  onDone: (responses: QuoteStopFreightQuestion[]) => void;
}

interface IOwnState {
  responses: QuoteStopFreightQuestion[];
}

const styles: {
  gridWrapper: string,
  questionText: string
} = require("./QuoteStopEntryComponents/QuoteStopEntry.scss");

const modalStyles: {
  modalContainer: string
} = require("$Shared/styles/Modal.scss");

class _CommodityQuestionModal extends React.Component<IOwnProps, IOwnState> {
  state: IOwnState = {
    responses: []
  };

  componentDidUpdate(prev: IOwnProps) {
    const {
      questions,
      responses,
      viewOnly
    } = this.props;

    if ((responses !== prev.responses) || (questions !== prev.questions)) {
      let newResponses: QuoteStopFreightQuestion[];

      if (viewOnly) {
        // base on current responses only - don't filter out inactive questions
        newResponses = _.map(responses, r => {
          const matchedQuestion = _.find(questions, q => q.id === r.questionId);

          return {
            id: r.id,
            questionId: r.questionId,
            question: _.pick(matchedQuestion, "id", "questionText", "isNa"),
            answer: r.answer
          };
        });
      }
      else {
        // base on active questions, use current responses if they exist
        // default N/A if available, otherwise "No"
        newResponses = _.map(questions, q => {
          const matchedResponse = _.find(responses, r => r.questionId === q.id);

          return {
            id: matchedResponse?.id,
            questionId: q.id,
            question: _.pick(q, "id", "questionText", "isNa"),
            answer: matchedResponse
              ? matchedResponse.answer
              : q.isNa ? "NA" : "No"
          };
        });
      }

      this.setState({ responses: newResponses });
    }
  }

  @bind
  private _onChangeAnswer(event: React.ChangeEvent<HTMLInputElement>) {
    const {
      responses
    } = this.state;

    const index = responses.findIndex(r => r.questionId === parseInt(event.target.name));

    if (index > -1) {
      responses[index].answer = event.target.value as QuoteStopFreightQuestionAnswerEnum;

      this.setState({
        responses: responses
      });
    }
  }

  @bind
  private _onDone() {
    this.props.onDone(this.state.responses);
  }

  render() {
    const {
      commodityName,
      isOpen,
      viewOnly
    } = this.props;

    const { responses } = this.state;

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
      >
        <DialogTitle>Commodity Questions</DialogTitle>
        <DialogContent
          className={modalStyles.modalContainer}
        >
          <div style={{display: responses.length > 0 ? "none" : "inline"}}>There are no questions defined for the {commodityName} commodity.</div>
          <div className={styles.gridWrapper}>
            {responses.map((r, idx) => {
              const isFirstQuestion = idx === 0;

              return (
                <Grid container direction="row" item spacing={2} key={idx}>
                  <Grid
                    item
                    className={styles.questionText}
                    lg
                  >
                    {r.question?.questionText ?? ""}
                  </Grid>
                  <Grid item xs>
                    <RadioGroup row name={r.questionId?.toString()} value={r.answer} onChange={this._onChangeAnswer}>
                      <FormControlLabel
                        value="Yes"
                        control={
                          <Radio disabled={viewOnly} autoFocus={(isFirstQuestion && r.answer === "Yes") ? true : false} />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={
                          <Radio disabled={viewOnly} autoFocus={(isFirstQuestion && r.answer === "No") ? true : false} />
                        }
                        label="No"
                      />
                      {r.question?.isNa &&
                        <FormControlLabel
                          value="NA"
                          control={
                            <Radio disabled={viewOnly} autoFocus={(isFirstQuestion && r.answer === "NA") ? true : false} />
                          }
                          label="N/A"
                        />
                      }
                    </RadioGroup>
                  </Grid>
                </Grid>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this._onDone}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const CommodityQuestionModal = _CommodityQuestionModal;
