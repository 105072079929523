import {
  React,
  moment,
  numeral
} from "$Imports/Imports";

import {
  UserMetricsBookedQuotesView
} from "$Generated/api";

import {
  ISalesRepHomeServiceInjectedProps,
  SalesRepHomeService
} from "$State/SalesRepHomeFreezerService";

import {
  Bar,
  BarChart,
  Label,
  Legend,
  NameType,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  ValueType,
  XAxis,
  XAxisProps,
  YAxis
} from "$Imports/Recharts";

import {
  CURRENCY_FORMAT,
  CURRENCY_NO_DECIMAL_FORMAT
} from "$Shared/utilities/formatUtil";

interface IOwnProps { }

type OwnProps = IOwnProps
  & ISalesRepHomeServiceInjectedProps;

interface IWeekDataPoint {
  start?: Date;
  end?: Date;
  charges?: number;
  rkm?: number;
  rfm?: number;
}

interface IChartDataPoint {
  weekNumber: number;
  current: IWeekDataPoint;
  previous: IWeekDataPoint;
}

const styles: {
  title: string;
  chart: string;
  lastXValue: string;
  tooltip: string;
  tooltipTitle: string;
  tooltipSection: string;
  tooltipSubsection: string;
} = require("./BookedQuotesByWeek.scss");

// rendering custom ticks to target last tick for bold without butchering styles
const getXTick = (props: XAxisProps, lastIndex: number): React.ReactElement => {
  const { x, y } = props;

  // payload is available, but not typed on XAxisProps
  const { value, index } = (props as any).payload;

  return (
    <g transform={`translate(${x}, ${Number(y) + 16})`} textAnchor="middle">
      <text className={index === lastIndex ? styles.lastXValue : undefined}>Week {value}</text>
    </g>
  );
}

const getTooltip = (props: TooltipProps<ValueType, NameType>): React.ReactNode | null => {
  if (!props || !props.active || !props.payload?.length) {
    return null;
  }

  const payload = props.payload[0].payload as IChartDataPoint;

  const {
    current: {
      start: currentStart,
      end: currentEnd,
      rkm: currentRkm,
      rfm: currentRfm
    },
    previous: {
      start: previousStart,
      end: previousEnd,
      rkm: previousRkm,
      rfm: previousRfm
    }
  } = payload;

  const momentCurrentStart = moment.utc(currentStart);
  const momentCurrentEnd = moment.utc(currentEnd);
  const momentPreviousStart = moment.utc(previousStart);
  const momentPreviousEnd = moment.utc(previousEnd);

  return (
    <div className={styles.tooltip}>
      <div className={styles.tooltipTitle}>Week {props.label}</div>

      <div className={styles.tooltipSection}>
        <div>
          {momentCurrentStart.format("YYYY")} ({momentCurrentStart.format("MM/DD")} - {momentCurrentEnd.format("MM/DD")})
        </div>
        <div className={styles.tooltipSubsection}>
          {currentRkm ? numeral(currentRkm).format(CURRENCY_FORMAT) : "-"} Rate/K/Mile
        </div>
        <div className={styles.tooltipSubsection}>
          {currentRfm ? numeral(currentRfm).format(CURRENCY_FORMAT) : "-"} Rate/Ft/Mile
        </div>
      </div>

      <div className={styles.tooltipSection}>
        <div>
          {momentPreviousStart.format("YYYY")} ({momentPreviousStart.format("MM/DD")} - {momentPreviousEnd.format("MM/DD")})
        </div>
        <div className={styles.tooltipSubsection}>
          {previousRkm ? numeral(previousRkm).format(CURRENCY_FORMAT) : "-"} Rate/K/Mile
        </div>
        <div className={styles.tooltipSubsection}>
          {previousRfm ? numeral(previousRfm).format(CURRENCY_FORMAT) : "-"} Rate/Ft/Mile
        </div>
      </div>
    </div>
  );
};

class _BookedQuotesByWeek extends React.Component<OwnProps> {
  componentDidMount() {
    this.props.salesRepHomeService.fetchBookedQuotesByWeek();
  }

  private _getChartData(sourceData: UserMetricsBookedQuotesView[]): IChartDataPoint[] | undefined {
    if (!sourceData.length) {
      return undefined;
    }

    const chartData: IChartDataPoint[] = sourceData.map((entry) => ({
      weekNumber: entry.weekNumber!,
      current: {
        start: entry.current?.start,
        end: entry.current?.end,
        charges: entry.current?.charges,
        rkm: entry.current?.rkm,
        rfm: entry.current?.rfm
      },
      previous: {
        start: entry.previous?.start,
        end: entry.previous?.end,
        charges: entry.previous?.charges,
        rkm: entry.previous?.rkm,
        rfm: entry.previous?.rfm
      }
    }));

    return chartData;
  }

  render() {
    const {
      bookedQuotesByWeekFetchResults
    } = this.props.salesRepHomeService.getState();

    const chartData = this._getChartData(bookedQuotesByWeekFetchResults.data ?? []);

    const myAverage = chartData ?
      chartData
        .map(x => x.current?.charges ?? 0)
        .reduce((previous, current) => previous + current) / (chartData?.length ?? 1)
      : 0;

    return (
      <div>
        <div className={styles.title}>My Booked Quotes</div>

        <div className={`${styles.chart} bookedQuotesChart`}>
          {chartData &&
            <ResponsiveContainer height="100%" width="100%">
              <BarChart
                data={chartData}
                barGap={0}
                barCategoryGap={10}
                layout="horizontal"
                margin={{
                  right: 72,
                  top: 16
                }}
              >
                <XAxis
                  dataKey="weekNumber"
                  type="category"
                  orientation="bottom"
                  tick={(props) => getXTick(props, chartData.length - 1)}
                  tickLine={false}
                />
                <YAxis
                  type="number"
                  tickFormatter={(value) => numeral(value).format(CURRENCY_NO_DECIMAL_FORMAT)}
                />
                <Tooltip
                  content={getTooltip}
                  cursor={false}
                />
                <Bar
                  dataKey="current.charges"
                  fill="#008a00"
                  name="Current Year"
                />
                <Bar
                  dataKey="previous.charges"
                  fill="silver"
                  name="Previous Year"
                />
                <Legend
                  align="right"
                  layout="horizontal"
                  verticalAlign="top"
                  wrapperStyle={{
                    right: 0,
                    top: 0
                  }}
                />
                <ReferenceLine
                  y={30000}
                  ifOverflow="extendDomain"
                  stroke="black"
                  strokeWidth={2}
                >
                  <Label value="Target" position="right" fontSize={"0.75rem"} />
                </ReferenceLine>
                <ReferenceLine
                  y={myAverage}
                  stroke="black"
                  strokeDasharray="12 6"
                >
                  <Label value="My Average" position="right" fontSize={"0.75rem"} />
                </ReferenceLine>
              </BarChart>
            </ResponsiveContainer>
          }
        </div>
      </div>
    );
  }
}

export const BookedQuotesByWeek = SalesRepHomeService.inject(_BookedQuotesByWeek);