import {
  React,
  bind,
  moment,
  numeral
} from "$Imports/Imports";

import {
  CustomerActivityCriteria,
  CustomerActivityCriteriaDateRangeEnum,
  CustomerActivityHistoryData
} from "$Generated/api";

import {
  CardLinedHeader,
  MetricCell
} from "$Imports/CommonComponents";

import {
  Grid
} from "$Imports/MaterialUIComponents";

import {
  ActivityTooltip
} from "./ActivityTooltip";

import {
  Bar,
  BarChart,
  Label,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip
} from "$Imports/Recharts";

import {
  ICustomerDetailServiceInjectedProps,
  CustomerDetailService
} from "$State/CustomerDetailFreezerService";

import {
  CURRENCY_FORMAT,
  CURRENCY_NO_DECIMAL_FORMAT,
  NUMERIC_SEPARATED_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  ActivityFilters
} from "./ActivityFilters";

interface IOwnState {
  dateRange: CustomerActivityCriteriaDateRangeEnum;
}

interface IOwnProps {
  customerId?: number;
}

type OwnProps = IOwnProps
  & ICustomerDetailServiceInjectedProps;

export interface IChartDataPoint {
  label: string;
  sales: number;
  quotes: number;
}

const styles: {
  filters: string;
  chart: string;
  noHistory: string;
} = require("./ActivityCard.scss");

class _ActivityCard extends React.Component<OwnProps> {
  state: IOwnState = {
    dateRange: "CurrentMonth"
  };

  componentDidMount() {
    this._fetchActivity({ dateRange: "CurrentMonth" });
  }

  @bind
  private async _fetchActivity(filter: CustomerActivityCriteria): Promise<void> {
    const { customerId } = this.props;

    if (customerId) {
      await this.props.customerDetailService.fetchCustomerActivity(customerId, filter);
    }

    this.setState({
      dateRange: filter.dateRange
    });
  }

  private _getChartData(source: CustomerActivityHistoryData[], dateRange: CustomerActivityCriteriaDateRangeEnum): IChartDataPoint[] {
    let labelFormat: string;

    switch (dateRange) {
      case "Last30Days":
        labelFormat = "MM/DD/YY";
        break;
      // current month, last X months
      default:
        labelFormat = "MMM-YY";
        break;
    }

    return source.map((x) => ({
      label: moment.utc(x.groupKey).format(labelFormat),
      quotes: x.quotes ?? 0,
      sales: x.sales ?? 0
    }));
  }

  render() {
    const {
      customerActivityFetchResults
    } = this.props.customerDetailService.getState();

    const {
      dateRange
    } = this.state;

    const {
      acceptedQuotes,
      totalQuotes,
      totalCharges,
      averageMiles,
      averageRatePerFtPerMi,
      averageRatePerKPerMi,
      history
    } = customerActivityFetchResults.data ?? {};

    // calculate averages here
    const sales = totalCharges
      ? numeral(totalCharges).format(CURRENCY_NO_DECIMAL_FORMAT)
      : "-";

    const conversionRate = (totalQuotes && acceptedQuotes)
      ? numeral(acceptedQuotes / totalQuotes).format("0%")
      : "0%";

    const averageRateLength = averageRatePerFtPerMi
      ? numeral(averageRatePerFtPerMi).format(CURRENCY_FORMAT)
      : "-";

    const averageRateWeight = averageRatePerKPerMi
      ? numeral(averageRatePerKPerMi).format(CURRENCY_FORMAT)
      : "-";

    const averageFBMiles = averageMiles
      ? numeral(averageMiles).format(NUMERIC_SEPARATED_FORMAT)
      : "-";

    const chartData = this._getChartData(history ?? [], dateRange);

    return (
      <CardLinedHeader titleText="Sales Metrics">
        <div className={styles.filters}>
          <ActivityFilters
            onChange={this._fetchActivity}
          />
        </div>

        <Grid
          container
          justifyContent="space-between"
          spacing={2}
        >
          <MetricCell
            title="Accepted Quotes"
            majorValue={acceptedQuotes?.toString() ?? "-"}
            xs={4}
          />

          <MetricCell
            title="Sales"
            majorValue={sales}
            xs={4}
          />

          <MetricCell
            title="Conversion Rate"
            majorValue={conversionRate}
            xs={4}
          />

          <MetricCell
            title="Avg Rate/Ft/Mi"
            majorValue={averageRateLength}
            xs={4}
          />

          <MetricCell
            title="Avg Rate/K/Mi"
            majorValue={averageRateWeight}
            xs={4}
          />

          <MetricCell
            title="Avg FB Miles"
            majorValue={averageFBMiles}
            xs={4}
          />
        </Grid>

        {chartData.length ? (
          <div className={styles.chart}>
            <ResponsiveContainer height="100%" width="100%">
              <BarChart
                data={chartData}
                barGap={0}
                barCategoryGap={10}
                layout="horizontal"
                margin={{ right: 10 }}
              >
                <XAxis
                  dataKey="label"
                  type="category"
                  orientation="bottom"
                  interval="preserveStart"
                />
                <YAxis
                  yAxisId="sales"
                  orientation="right"
                  tickFormatter={(value) => numeral(value).format(CURRENCY_NO_DECIMAL_FORMAT)}
                >
                  <Label
                    angle={90}
                    offset={-2}
                    position="insideRight"                    
                    style={{ textAnchor: "middle" }}
                    value="Sales"
                  />
                </YAxis>
                <YAxis
                  yAxisId="quotes"
                  allowDecimals={false}
                  orientation="left"                  
                >
                  <Label
                    angle={-90}
                    offset={10}
                    position="insideLeft"
                    style={{ textAnchor: "middle" }}
                    value="Quotes"
                  />
                </YAxis>
                <Legend
                  align="center"
                  layout="horizontal"
                  verticalAlign="top"
                />
                <Bar
                  yAxisId="quotes"
                  dataKey="quotes"
                  fill="#8497B0"
                  name="Quotes"
                />

                <Tooltip
                  cursor={{ stroke: "#5a5c63", strokeWidth: "1", fillOpacity: "0" }}
                  content={(props: any) => {
                    return (
                      <ActivityTooltip
                        payload={props.payload?.[0]?.payload}
                        isActive={props.active}
                      />
                    );
                  }}
                />
                
                <Bar
                  yAxisId="sales"
                  dataKey="sales"
                  fill="#BFBFBF"
                  name="Sales"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className={styles.noHistory}>
            No historical data
          </div>
        )}
      </CardLinedHeader>
    );
  }
}

export const ActivityCard = CustomerDetailService.inject(_ActivityCard);