import * as PubSubJs from 'pubsub-js';

export type messageType = 'application:login:before' | 'application:login' | 'application:logout' | "application:login:refresh" | 'application:fetch'
    | 'application:exception';

export type dataType = unknown | null;

export class PubSubManager<M = string> {
    constructor() {
        this.tokenList = [];
    }
    private readonly tokenList: any[];

    public subscribe<T = any>(message: M, func: <M = string>(message: M, data: T) => void): any {
        const messageValue = typeof message === 'string' ? message : (message as unknown) as string;
        const token = PubSubJs.subscribe(messageValue, func);
        this.tokenList.push(token);
        return token;
    }

    public unsubscribe(token: any) {
        PubSubJs.unsubscribe(token);
        const arrayIndex = this.tokenList.indexOf(token);
        this.tokenList.splice(arrayIndex, 1);
    }

    public async publish<T = dataType>(message: M, data: T): Promise<boolean> {
        return this.publishMessageAsync(message, data);
    }

    public async publishSync<T = dataType>(message: M, data: T): Promise<boolean> {
        return this.publishMessageSync(message, data);
    }

    private publishMessageAsync<T = dataType>(message: M, data: T): Promise<boolean> {
        const messageValue = typeof message === 'string' ? message : (message as unknown) as string;
        return new Promise<boolean>((resolve) => resolve(PubSubJs.publish(messageValue, data)));
    }

    private publishMessageSync<T = dataType>(message: M, data: T): Promise<boolean> {
        const messageValue = typeof message === 'string' ? message : (message as unknown) as string;
        return new Promise<boolean>((resolve) => resolve(PubSubJs.publishSync(messageValue, data)));
    }
}

export const SitePubSubManager = new PubSubManager<messageType>();