import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  Terminal, 
  TerminalsApiFactory,
  TerminalRegion
} from "$Generated/api"

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ISortState
} from "$Imports/CommonComponents";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

export type formModeType = "add" | "edit" | "none";

export interface TerminalModalState {
  originalTerminalRegions: TerminalRegion[] | undefined,
  editedTerminalRegions: TerminalRegion[] | undefined,
  tempTerminalRegions: TerminalRegion[] | undefined
}

interface ITerminalState {
  terminalsFetchResults: IAjaxState<Terminal[]>,
  undefinedTerminalFetchResult: IAjaxState<Terminal>,
  terminalAddFetchResult: IAjaxState<Terminal>,
  terminalUpdateFetchResult: IAjaxState<Terminal>,
  terminalDeleteFetchResult: IAjaxState<Terminal>,
  duplicateTerminalRegionsFetchResult: IAjaxState<TerminalRegion[]>,
  duplicateRegionsFound: boolean,
  sortState: ISortState,
  formMode: formModeType,
  editAddTerminal: Terminal | null,
  terminalModalState: TerminalModalState,
  terminalValidationErrors: ValidationError | null
}

const TerminalValidationSchema: SchemaOf<NullableOptional<Terminal>> = yup.object({
  id: yup.number().notRequired(),
  terminalName: yup.string().required("Terminal name is required").max(100, "Terminal name must not exceed 100 characters"),
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  terminalRegions: yup.array().notRequired()
})

const initialState = {
  terminalsFetchResults: managedAjaxUtil.createInitialState(),
  undefinedTerminalFetchResult: managedAjaxUtil.createInitialState(),
  terminalAddFetchResult: managedAjaxUtil.createInitialState(),
  terminalUpdateFetchResult: managedAjaxUtil.createInitialState(),
  terminalDeleteFetchResult: managedAjaxUtil.createInitialState(),
  duplicateTerminalRegionsFetchResult: managedAjaxUtil.createInitialState(),
  duplicateRegionsFound: false,
  sortState: {
    sortDirection: "desc",
    sortColumnName: "terminalName"
  },
  formMode: "none",
  editAddTerminal: null,
  terminalModalState: {},
  terminalValidationErrors: null
} as ITerminalState;

const InjectedPropName = "terminalService";

class TerminalsFreezerService extends FreezerService<ITerminalState, typeof InjectedPropName> {

  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public updateEditAddTerminal(terminal: Partial<Terminal>) {
    this.freezer.get().editAddTerminal?.set(terminal);
  }

  public updateTerminalModal(terminalModal: Partial<TerminalModalState>) {
    this.freezer.get().terminalModalState?.set(terminalModal);
  }

  public updateTerminal(terminal: Partial<Terminal>) {
    const terminalRegions = terminal.terminalRegions?.map(tr => {
      return {
        regionId: tr.regionId,
        region: tr.region
      }
    });

    this.freezer.get().set({
      formMode: "edit",
      editAddTerminal: terminal,
      terminalModalState: {
        originalTerminalRegions: terminalRegions,
        editedTerminalRegions: terminalRegions,
        tempTerminalRegions: terminalRegions
      }
    });
  }

  public addTerminal() {
    this.freezer.get().set({
      formMode: "add",
      editAddTerminal: {
        terminalName: ""
      },
      terminalModalState: {
        originalTerminalRegions: [],
        editedTerminalRegions: [],
        tempTerminalRegions: []
      }
    });
  }

  public clearEditAddForm() {
    this.freezer.get().set({
      editAddTerminal: null,
      formMode: "none",
      terminalModalState: {
        originalTerminalRegions: [],
        editedTerminalRegions: [],
        tempTerminalRegions: []
      },
      terminalValidationErrors: null
    });
  }

  public onModalDeleteConfirm() {
    this.freezer.get().set({
      duplicateRegionsFound: false
    });
    this.addEditTerminal(false);
  }

  public onModalDeleteCancel() {
    this.freezer.get().set({
      duplicateRegionsFound: false
    });
  }

  public async fetchTerminals(forceUpdate: boolean = false) {
    const { terminalsFetchResults } = this.freezer.get();

    if (terminalsFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "terminalsFetchResults",
      onExecute: (apiOptions, params, options) => {
        const factory = TerminalsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1TerminalsGet();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch terminals.");
      }
    });
  }

  public async fetchUnknownTerminal(forceUpdate: boolean = false) {
    const { undefinedTerminalFetchResult } = this.freezer.get();

    if (undefinedTerminalFetchResult.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "undefinedTerminalFetchResult",
      onExecute: (apiOptions, params, options) => {
        const factory = TerminalsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1TerminalsUndefinedTerminalGet();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch terminals.");
      }
    });
  }

  public addEditTerminal(checkForDuplicates: boolean = true) {
    const formMode = this.freezer.get().formMode;

    if (formMode == "add") {
      this._addTerminal(checkForDuplicates);
    }
    else if (formMode == "edit") {
      this._editTerminal(checkForDuplicates);
    }
  }

  private async _addTerminal(checkForDuplicates: boolean = true) {
    const newTerminal = this.freezer.get().editAddTerminal?.toJS();

    const terminalModalState = this.freezer.get().terminalModalState?.toJS();
    const errors = await validateSchema(TerminalValidationSchema, terminalModalState, {
      abortEarly: false
    });
    this.freezer.get().set({ terminalValidationErrors: errors });

    if (errors) {
      return;
    }

    if (checkForDuplicates && (await this._determineTerminalRegionDuplicates())) {
      this.freezer.get().set({
        duplicateRegionsFound: true
      });
      return;
    }

    const newTerminalRegions: TerminalRegion[] | undefined = terminalModalState.editedTerminalRegions?.map(r => {
      return {
        regionId: r.regionId
      }
    });

    if (newTerminal) {
      newTerminal.terminalRegions = newTerminalRegions;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "terminalAddFetchResult",
      onExecute: (apiOptions, param, options) => {
        const factory = TerminalsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1TerminalsNewTerminalPut(param);
      },
      params: {
        body: newTerminal
      },
      onOk: (data: any) => {
        if (data.success) {
          this.fetchUnknownTerminal(true);
          this.fetchTerminals(true);
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to add terminal.");
      }
    });

    this.clearEditAddForm();
  }

  private async _editTerminal(checkForDuplicates: boolean = true) {
    const updatedTerminal = this.freezer.get().editAddTerminal?.toJS();
    
    const terminalModalState = this.freezer.get().terminalModalState?.toJS();

    if (!_.isEqual(terminalModalState.editedTerminalRegions, terminalModalState.originalTerminalRegions)) {

      if (checkForDuplicates && (await this._determineTerminalRegionDuplicates())) {
        this.freezer.get().set({
          duplicateRegionsFound: true
        });
        return;
      }
  
      const newTerminalRegions: TerminalRegion[] | undefined = terminalModalState.editedTerminalRegions?.map(r => {
        return {
          terminalId: updatedTerminal?.id,
          regionId: r.regionId
        }
      });
  
      if (updatedTerminal) {
        updatedTerminal.terminalRegions = newTerminalRegions;
      }
  
      await managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        ajaxStateProperty: "terminalUpdateFetchResult",
        onExecute: (apiOptions, param, options) => {
          const factory = TerminalsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return factory.apiV1TerminalsUpdateTerminalPut(param);
        },
        params: {
          body: updatedTerminal
        },
        onOk: (data: any) => {
          if (data.success) {
            this.fetchUnknownTerminal(true);
            this.fetchTerminals(true);
          }
        },
        onError: (err, errorMessage) => {
          ErrorService.pushErrorMessage("Failed to update terminal.");
        }
      });
    }

    this.clearEditAddForm();
  }

  public deleteTerminal(terminal: Terminal) {
    const terminalId = terminal.id;

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "terminalDeleteFetchResult",
      onExecute: (apiOptions, param, options) => {
        const factory = TerminalsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1TerminalsDeleteTerminalDelete(param);
      },
      params: {
        terminalId: terminalId
      },
      onOk: (data: any) => {
        if (data.success) {
          this.fetchUnknownTerminal(true);
          this.fetchTerminals(true);
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to add terminal.");
      }
    });

  }

  private async _determineTerminalRegionDuplicates(): Promise<boolean> {
    const terminalModalState = this.freezer.get().terminalModalState?.toJS();

    const originalTerminalRegions = terminalModalState.originalTerminalRegions;
    const editedTerminalRegions = terminalModalState.editedTerminalRegions;

    var originalTerminalRegionIds: number[] = [];
    var editedTerminalRegionIds: number[] = [];

    if (originalTerminalRegions && editedTerminalRegions) {
      originalTerminalRegionIds = originalTerminalRegions.map(tr => tr.regionId!);
      editedTerminalRegionIds = editedTerminalRegions.map(tr => tr.regionId!);
    }

    var newRegionIds: number[] = [];

    if (originalTerminalRegions && editedTerminalRegions) {
      newRegionIds = _.difference(editedTerminalRegionIds, originalTerminalRegionIds);
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "duplicateTerminalRegionsFetchResult",
      onExecute: (apiOptions, param, options) => {
        const factory = TerminalsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1TerminalsTerminalRegionsByRegionIdsPost(param);
      },
      params: {
        body: newRegionIds
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to find duplicate terminal regions");
      }
    });

    const duplicateTerminalRegions = this.freezer.get().duplicateTerminalRegionsFetchResult.data?.toJS();

    return !!duplicateTerminalRegions?.length
  }
}

export const TerminalFreezerService = new TerminalsFreezerService();
export type ITerminalServiceInjectedProps = ReturnType<TerminalsFreezerService["getPropsForInjection"]>;
