import {
  React,
  bind
} from "$Imports/Imports";

import {
  DisplayFormattedNumber,
  DataTable,
  IDataTableColumn
} from "$Imports/CommonComponents";

import {
  Button,
  Popper
} from "$Imports/MaterialUIComponents";


import {
  CapacityRate,
  Region,
  Zone
} from "$Generated/api";

import {
  renderStatesSelection,
  renderZonesSelection
} from "$Utilities/capacityModelColConstants";

const styles: {
  favoriteTable: string,
  popper: string,
  copyButton: string,
  tableCell: string
} = require("./AddEditCapacityRate.scss");


interface IFavoritedCapacityRatesToolTipPropsState {
  open: boolean;
  anchorEl: HTMLElement | null;
}

interface IFavoritedCapacityRatesToolTipProps {
  favoriteCapacityRates: CapacityRate[] | undefined;
  currentCapacityRate: CapacityRate;
  regions: Region[];
  zones: Zone[];
  onRowClick: (capacityRate: CapacityRate) => void;
}

export class FavoritedCapacityRatesToolTip extends React.PureComponent<IFavoritedCapacityRatesToolTipProps, IFavoritedCapacityRatesToolTipPropsState> {
  state = {
    open: false,
    anchorEl: null
  }

  private renderStatesSelection(selected: Region[]): string {
    const regionData = this.props.regions;
    if (!regionData) {
      return "";
    }

    return renderStatesSelection(selected, regionData);
  }

  private renderZonesSelection(selected: Zone[]): string {
    const zoneData = this.props.zones;
    if (!zoneData) {
      return "";
    }

    return renderZonesSelection(selected, zoneData.length);
  }

  @bind
  private _onClick(e: React.MouseEvent<HTMLElement>) {
    const currentOpenValue = this.state.open;
    this.setState({
      open: currentOpenValue ? false : true,
      anchorEl: e.currentTarget
    });
  }

  @bind
  private _onRowClick(data: CapacityRate) {
    const {
      favoriteName,
      isFavorite,
      ...capacityRate
    } = data;

    if (this.props.onRowClick) {
      this.props.onRowClick(capacityRate);
    }

    this.setState({
      open: false
    });
  }

  private readonly columns: Array<IDataTableColumn<CapacityRate>> = [
    {
      columnName: "favoriteName",
      columnFieldData: (d) => <div className={styles.tableCell}>{d.favoriteName}</div>,
      columnFieldTitle: (d) => d.favoriteName ?? "",
      headerValue: "Favorite Name",
      sortMethod: (d) => d.favoriteName?.toLowerCase() ?? ""
    },
    {
      columnName: "originStates",
      columnFieldData: (d) => <div className={styles.tableCell}>{ d?.originRegions && d?.originRegions?.length > 0
        ? this.renderStatesSelection(d?.originRegions)
        : d?.originZones && d?.originZones?.length > 0
        ? this.renderZonesSelection(d?.originZones)
        : "" }</div>,
      columnFieldTitle: (d) => d?.originRegions && d?.originRegions?.length > 0
        ? this.renderStatesSelection(d?.originRegions)
        : d?.originZones && d?.originZones?.length > 0
        ? this.renderZonesSelection(d?.originZones)
        : "",
      headerValue: "Origin(s)",
      sortMethod: (d) => d?.originRegions && d?.originRegions?.length > 0
        ? this.renderStatesSelection(d?.originRegions)
        : d?.originZones && d?.originZones?.length > 0
        ? this.renderZonesSelection(d?.originZones)
        : ""
    },
    {
      columnName: "destinationStates",
      columnFieldData: (d) => <div className={styles.tableCell}>{ d?.destinationRegions && d?.destinationRegions?.length > 0
        ? this.renderStatesSelection(d?.destinationRegions)
        : d?.destinationZones && d?.destinationZones?.length > 0
        ? this.renderZonesSelection(d?.destinationZones)
        : "" }</div>,
      columnFieldTitle: (d) => d?.destinationRegions && d?.destinationRegions?.length > 0
        ? this.renderStatesSelection(d?.destinationRegions)
        : d?.destinationZones && d?.destinationZones?.length > 0
        ? this.renderZonesSelection(d?.destinationZones)
        : "",
      headerValue: "Destination(s)",
      sortMethod: (d) => d?.destinationRegions && d?.destinationRegions?.length > 0
        ? this.renderStatesSelection(d?.destinationRegions)
        : d?.destinationZones && d?.destinationZones?.length > 0
        ? this.renderZonesSelection(d?.destinationZones)
        : ""
    },
    {
      columnName: "rateValue",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.rateValue} formatString={'0.00%'} />,
      headerValue: "Rate %",
      sortMethod: (d) => d.rateValue
    }
  ]


  render() {
    const {
      open,
      anchorEl
    } = this.state;

    const {
      currentCapacityRate,
      favoriteCapacityRates
    } = this.props; 

    return (
      currentCapacityRate != undefined && favoriteCapacityRates != undefined ?
        <>
          <Popper
            open={open}
            anchorEl={anchorEl}
            className={styles.popper}
            placement='bottom-end'
          >
            <div className={styles.favoriteTable}>
              <DataTable
                data={favoriteCapacityRates}
                defaultSortColumnName={"favoriteName"}
                defaultSortDirection={"asc"}
                columns={this.columns}
                onRowClick={this._onRowClick}
              />
            </div>
          </Popper>
          <Button
            color="secondary"
            onClick={this._onClick}
            className={styles.copyButton}
          >
            Copy From Favorite
          </Button>
        </>
        : null
    );
  }
}