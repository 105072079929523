import {
  React,
  Helpers,
  bind,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  AjaxActionIndicator,
  PercentageInputField,
  RegionSelectionModal,
  DateTimeInputField,
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  ZoneSelectionModal
} from "./ZoneSelectionModal";

import {
  CapacityRate,
  Region,
  Zone
} from "$Generated/api";

import {
  FavoritedCapacityRatesToolTip
} from "./FavoritedCapacityRatesToolTip";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControlLabel,
  Switch,
  Grid,
  TextField,
  Checkbox
} from "$Imports/MaterialUIComponents";

import {
  formModeType,
  CapacityRateService
} from "$State/CapacityRateFreezerService";

import {
  renderStatesSelection,
  renderZonesSelection
} from "$Utilities/capacityModelColConstants";

const styles: {
  container: string;
  stateButton: string;
  stateField: string;
  favoriteName: string;
} = require("./AddEditCapacityRate.scss");

interface IAddEditCapacityRateProps {
  formMode: formModeType;
  capacityRate: CapacityRate | null;
  tempCapacityRate: CapacityRate | null;
  immediateStart: boolean;
  validationErrors?: ValidationError | null;
  favoriteCapacityRates?: CapacityRate[];
  openTime: Date;
  isFetching?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  onCapacityRateChange?: (capacityRateChange: Partial<CapacityRate>) => void;
  onTempCapacityRateChange?: (capacityRateChange: Partial<CapacityRate>) => void;
  onChangeImmediateStart: (immediateStart: boolean) => void;
  onToolTipRowClick: (capacityRate: CapacityRate) => void;
  regionData: Region[];
  zoneData: Zone[];
  rateFromServer: CapacityRate | null;
}

interface IAddEditCapacityRateState {
  originRegionSelectOpen: boolean;
  destRegionSelectOpen: boolean;
  originZoneSelectOpen: boolean;
  destZoneSelectOpen: boolean;
}

export class AddEditCapacityRate extends React.PureComponent<IAddEditCapacityRateProps> {

  state: IAddEditCapacityRateState = {
    originRegionSelectOpen: false,
    destRegionSelectOpen: false,
    originZoneSelectOpen: false,
    destZoneSelectOpen: false
  };


  static defaultProps: Partial<IAddEditCapacityRateProps> = {
    isFetching: false,
  };

  @bind
  private _onCancel() {
    if (this.props.onCancel) {
      this.setState({
        originError: false,
        destinationError: false
      });
      this.props.onCancel();
    }
  }

  @bind
  private _onCapacityRateChanged(capacityRate: Partial<CapacityRate>) {
    if (this.props.onCapacityRateChange) {
      this.props.onCapacityRateChange(capacityRate);
    }
  }

  @bind
  private _onRateValueChanged(value?: number) {
    this._onCapacityRateChanged({ rateValue: value });
  }

  @bind
  private _onFavoriteChanged(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this._onCapacityRateChanged({ isFavorite: checked });
  }

  @bind
  private _onImmediateStartChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.onChangeImmediateStart(checked);
  }

  @bind
  private _onStartDateChanged(date: Date | undefined) {
    this._onCapacityRateChanged({ startDateTime: date });
  }

  @bind
  private _onEndDateChanged(date: Date | undefined) {
    this._onCapacityRateChanged({ endDateTime: date });
  }

  @bind
  private _onSubmit() {
    if (this.props.onSave) {
      this.props.onSave();
    }
  }

  @bind
  private openOriginStateSelect() {
    this.setState({
      originRegionSelectOpen: true
    })
  }

  @bind
  private openOriginZoneSelect() {
    this.setState({
      originZoneSelectOpen: true
    })
  }

  @bind
  private closeOriginRegionSelect() {
    this.setState({
      originRegionSelectOpen: false
    })
    
    if (this.props.onTempCapacityRateChange) {
      this.props.onTempCapacityRateChange({
        originRegions: CapacityRateService.getState().editAddCapacityRate?.originRegions
      });
    }
  }

  @bind
  private closeOriginZoneSelect() {
    this.setState({
      originZoneSelectOpen: false
    })
    
    if (this.props.onTempCapacityRateChange) {
      this.props.onTempCapacityRateChange({
        originZones: CapacityRateService.getState().editAddCapacityRate?.originZones
      });
    }
  }

  @bind
  private _onOriginRegionChanged(regions: Region[] | undefined) {
    this._onCapacityRateChanged({ originRegions: regions });
    this.closeOriginRegionSelect();
  }

  @bind
  private _onOriginZoneChanged(zones: Zone[] | undefined) {
    this._onCapacityRateChanged({ originZones: zones });
    this.closeOriginZoneSelect();
  }

  @bind
  private openDestRegionSelect() {
    this.setState({
      destRegionSelectOpen: true
    })
  }

  @bind
  private closeDestRegionSelect() {
    this.setState({
      destRegionSelectOpen: false
    })

    if (this.props.onTempCapacityRateChange) {
      this.props.onTempCapacityRateChange({
        destinationRegions: CapacityRateService.getState().editAddCapacityRate?.destinationRegions
      });
    }
  }

  @bind
  private closeDestZoneSelect() {
    this.setState({
      destZoneSelectOpen: false
    })

    if (this.props.onTempCapacityRateChange) {
      this.props.onTempCapacityRateChange({
        destinationRegions: CapacityRateService.getState().editAddCapacityRate?.destinationRegions
      });
    }
  }

  @bind
  private openDestZoneSelect() {
    this.setState({
      destZoneSelectOpen: true
    })
  }

  @bind 
  private _onSelectedOriginRegionChange(selectedRegions: Region[]) {
    if (this.props.onTempCapacityRateChange) {
      this.props.onTempCapacityRateChange({originRegions: selectedRegions});
    }
  }

  @bind
  private _onSelectedDestinationRegionChange(selectedRegions: Region[]) {
    if (this.props.onTempCapacityRateChange) {
      this.props.onTempCapacityRateChange({destinationRegions: selectedRegions});
    }
  }

  @bind 
  private _onSelectedOriginZoneChange(selectedZones: Zone[]) {
    if (this.props.onTempCapacityRateChange) {
      this.props.onTempCapacityRateChange({originZones: selectedZones});
    }
  }

  @bind
  private _onSelectedDestinationZoneChange(selectedZones: Zone[]) {
    if (this.props.onTempCapacityRateChange) {
      this.props.onTempCapacityRateChange({destinationZones: selectedZones});
    }
  }

  @bind 
  private _onReasonChange(reason: string) {
    this._onCapacityRateChanged({reason: reason});
  }

  @bind
  private _onFavoriteNameChange(name: string) {
    this._onCapacityRateChanged({favoriteName: name});
  }

  @bind
  private _onDestRegionChanged(regions: Region[] | undefined) {
    this._onCapacityRateChanged({ destinationRegions: regions });
    this.closeDestRegionSelect();
  }

  @bind
  private _onDestZoneChanged(zones: Zone[] | undefined) {
    this._onCapacityRateChanged({ destinationZones: zones });
    this.closeDestZoneSelect();
  }

  @bind 
  private _onToolTipRowClick(capacityRate: CapacityRate) {
    if (this.props.onToolTipRowClick) {
      this.props.onToolTipRowClick(capacityRate);
    }
  }

  render() {
    const {
      formMode,
      capacityRate,
      immediateStart,
      isFetching,
      regionData,
      zoneData,
      favoriteCapacityRates,
      validationErrors,
      rateFromServer,
      tempCapacityRate
    } = this.props;

    const validationsParser = new ValidationErrorParser<CapacityRate>(validationErrors);
    const isActive = formMode === 'edit' && moment(rateFromServer?.startDateTime).isBefore(moment());
    const isPastRecord = formMode === 'edit' && moment(rateFromServer?.endDateTime).isBefore(moment());

    return !Helpers.isNullOrUndefined(capacityRate) && !Helpers.isNullOrUndefined(regionData) ? (
      <Dialog
        open={formMode === "add" || formMode === "edit"}
        fullWidth={true}
        maxWidth="sm"
      >
        <AjaxActionIndicator
          showProgress={isFetching}
        />
        <DialogTitle>
          {formMode === "add" ? "Add Capacity Rate" : `Edit Capacity Rate`}
        </DialogTitle>
        <DialogContent
          className={styles.container}
          style={{ overflowY: "hidden" }}
        >
          <Grid container direction={"column"} spacing={3}>
            <Grid item>
              <FormControlLabel
                control={
                  (
                    <Switch
                      color="primary"
                      checked={capacityRate?.isFavorite}
                      onChange={this._onFavoriteChanged}
                      disabled={isPastRecord}
                    />
                  )
                }
                label="Favorite"
              />
              {capacityRate?.isFavorite &&
                <AdvanceTextField
                  label="Favorite Name"
                  onDebouncedChange={this._onFavoriteNameChange}
                  value={capacityRate.favoriteName ?? ""}
                  error={!validationsParser.isValid("favoriteName")}
                  helperText={validationsParser.validationMessage("favoriteName")}
                />
              }
              {(capacityRate && formMode === "add") &&
                <FavoritedCapacityRatesToolTip
                  regions={regionData}
                  zones={zoneData}
                  currentCapacityRate={capacityRate}
                  favoriteCapacityRates={favoriteCapacityRates}
                  onRowClick={this._onToolTipRowClick}
                />
              }
            </Grid>
            <Grid item>
              <PercentageInputField
                label="Rate %"
                required
                error={!validationsParser.isValid("rateValue")}
                helperText={validationsParser.validationMessage("rateValue")}
                decimalScale={1}
                value={capacityRate?.rateValue}
                onPercentValueChange={this._onRateValueChanged}
                disabled={isActive}
              />
            </Grid>
            <Grid item>
              <AdvanceTextField
                value={capacityRate?.reason ?? ""}
                error={!validationsParser.isValid("reason")}
                helperText={validationsParser.validationMessage("reason")}
                onDebouncedChange={this._onReasonChange}
                label="Reason"
                required
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="Immediate start"
                control={(
                  <Checkbox
                    checked={immediateStart}
                    onChange={this._onImmediateStartChange}
                    name="immediateStart"
                    disabled={isActive}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <DateTimeInputField
                value={capacityRate?.startDateTime ?? null}
                onChange={this._onStartDateChanged}
                required
                disablePast
                dateLabel="Start Date Time"
                error={!validationsParser.isValid("startDateTime")}
                helperText={validationsParser.validationMessage("startDateTime")}
                disabled={isActive || immediateStart}
              />
            </Grid>
            <Grid item>
              <DateTimeInputField
                value={capacityRate?.endDateTime ?? null}
                onChange={this._onEndDateChanged}
                required
                dateLabel="End Date Time"
                disablePast
                error={!validationsParser.isValid("endDateTime")}
                helperText={validationsParser.validationMessage("endDateTime")}
                disabled={isPastRecord}
              />
            </Grid>
            <Grid item className={styles.stateButton}>
              <Button
                color="primary"
                onClick={this.openOriginStateSelect}
                style={{ textTransform: "none" }}
                disabled={isFetching || isActive || (!!capacityRate?.originZones && capacityRate?.originZones.length > 0)}
              >
                Select Origin States
              </Button>
              <Button
                color="primary"
                onClick={this.openOriginZoneSelect}
                style={{ textTransform: "none" }}
                disabled={isFetching || isActive || (!!capacityRate?.originRegions && capacityRate?.originRegions.length > 0)}
              >
                Select Origin Zones
              </Button>
            </Grid>
            <Grid item className={styles.stateField}>
              <TextField
                label="Origin(s) *"
                value={capacityRate?.originRegions && capacityRate?.originRegions?.length > 0
                  ? renderStatesSelection(capacityRate?.originRegions, regionData)
                  : capacityRate?.originZones && capacityRate?.originZones?.length > 0
                  ? renderZonesSelection(capacityRate?.originZones, zoneData.length)
                  : ""}
                error={!validationsParser.isValid("originRegions")}
                helperText={validationsParser.validationMessage("originRegions")}
                multiline
                fullWidth
                disabled
              />
            </Grid>
            <Grid item className={styles.stateButton}>
              <Button
                color="primary"
                onClick={this.openDestRegionSelect}
                style={{ textTransform: "none" }}
                disabled={isFetching || isActive || (!!capacityRate?.destinationZones && capacityRate?.destinationZones.length > 0)}
              >
                Select Destination States
              </Button>
              <Button
                color="primary"
                onClick={this.openDestZoneSelect}
                style={{ textTransform: "none" }}
                disabled={isFetching || isActive || (!!capacityRate?.destinationRegions && capacityRate?.destinationRegions.length > 0)}
              >
                Select Destination Zones
              </Button>
            </Grid>
            <Grid item className={styles.stateField}>
              <TextField
                label="Destination(s) *"
                value={capacityRate?.destinationRegions && capacityRate?.destinationRegions?.length > 0
                  ? renderStatesSelection(capacityRate?.destinationRegions, regionData)
                  : capacityRate?.destinationZones && capacityRate?.destinationZones?.length > 0
                  ? renderZonesSelection(capacityRate?.destinationZones, zoneData.length)
                  : ""}
                error={!validationsParser.isValid("destinationRegions")}
                helperText={validationsParser.validationMessage("destinationRegions")}
                multiline
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._onCancel}
            disabled={isFetching}
            variant="outlined"
          >
            Cancel
            </Button>
          <Button
            color="primary"
            disabled={isFetching}
            onClick={this._onSubmit}
          >
            Save
          </Button>
        </DialogActions>
        <RegionSelectionModal
          isOpen={this.state.originRegionSelectOpen}
          onCancel={this.closeOriginRegionSelect}
          onSave={this._onOriginRegionChanged}
          regions={regionData}
          selected={tempCapacityRate?.originRegions ?? []}
          onSelectedChange={this._onSelectedOriginRegionChange}
        />
        <ZoneSelectionModal
          isOpen={this.state.originZoneSelectOpen}
          onCancel={this.closeOriginZoneSelect}
          onSave={this._onOriginZoneChanged}
          zones={zoneData}
          selected={tempCapacityRate?.originZones ?? []}
          onSelectedChange={this._onSelectedOriginZoneChange}
        />
        <RegionSelectionModal
          isOpen={this.state.destRegionSelectOpen}
          onCancel={this.closeDestRegionSelect}
          onSave={this._onDestRegionChanged}
          regions={regionData}
          selected={tempCapacityRate?.destinationRegions ?? []}
          onSelectedChange={this._onSelectedDestinationRegionChange}
        />
        <ZoneSelectionModal
          isOpen={this.state.destZoneSelectOpen}
          onCancel={this.closeDestZoneSelect}
          onSave={this._onDestZoneChanged}
          zones={zoneData}
          selected={tempCapacityRate?.destinationZones ?? []}
          onSelectedChange={this._onSelectedDestinationZoneChange}
        />
      </Dialog >
    ) : null;
  }
}
