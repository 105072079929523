
import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  IDataTableColumn,
  DataTable,
  AjaxActionIndicator,
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  Card,
  CardHeader
} from "$Imports/MaterialUIComponents";

import { 
  ApplicationSetting 
} from "$Generated/api";

import {
  ApplicationSettingsService,
  IApplicationSettingsServiceInjectedProps
} from "$State/ApplicationSettingsFreezerService";

import {
  ActionMenu
} from "./ActionMenu";

import { CURRENCY_FORMAT } from "$Shared/utilities/formatUtil";

import {
  AutomaticUpchargeEditModal
} from "./AutomaticUpchargesModals/AutomaticUpchargeEditModal";

const styles: {
  mainContainer: string,
  cardStyle: string
} = require("$Shared/styles/Modal.scss");

interface IAutomaticUpchargesViewPageBaseProps {

}

type IAutomaticUpchargesViewPage = IAutomaticUpchargesViewPageBaseProps
  & IApplicationSettingsServiceInjectedProps;

class _AutomaticUpchargesViewPage extends React.Component<IAutomaticUpchargesViewPage> {

  async componentDidMount() {
    await this.props.ApplicationSettingsService.fetchApplicationSettings(true);
  }

  @bind
  private _onSettingEditClick(applicationSetting: ApplicationSetting) {
    this.props.ApplicationSettingsService.openApplicationSettingEditModal(applicationSetting);
  }

  @bind
  private _onLocationFeeSettingChange(locationFee: Partial<ApplicationSetting>) {
    this.props.ApplicationSettingsService.updateLocationFeeSetting(locationFee);
  }

  @bind
  private _onSaveLocationFeeSetting() {
    this.props.ApplicationSettingsService.saveLocationFeeSetting();
  }

  @bind 
  private _translateSettingsKey(settingKey?: string): string {
    switch(settingKey) {
      case("MilitaryBaseLocationFee"):
        return "Military Base Location Fee";
      case("TWICCardLocationFee"):
        return "TWIC Card Location Fee";
      default: 
        return "";
    }
  }

  private readonly columns: Array<IDataTableColumn<ApplicationSetting>> = [
    {
      columnName: "applicationSetting",
      columnFieldData: (d) => this._translateSettingsKey(d.settingsKey),
      headerValue: "Upcharge Name"
    },
    {
      columnName: "settingValue",
      columnFieldData: (d) => {
        return d.settingsKey?.includes("LocationFee") ? <DisplayFormattedNumber value={Number(d.settingsValue)} formatString={CURRENCY_FORMAT} /> : d.settingsValue;
      },
      headerValue: "Amount"
    },
    {
      columnName: "action",
      columnFieldData: (d) => (
        <ActionMenu 
          setting={d}
          onSettingEditClick={this._onSettingEditClick}
        />
      ),
      headerValue: ""
    }
  ]

  render() {
    const {
      applicationSettingsFetchResults,
      updateApplicationSettingResults,
      isLocationFeeSettingModalOpen,
      editLocationFeeSetting
    } = this.props.ApplicationSettingsService.getState();

    const applicationSettings = applicationSettingsFetchResults.data ?? [];
    const displaySettings = _.filter(applicationSettings, a => a.settingsKey === 'MilitaryBaseLocationFee' || a.settingsKey === 'TWICCardLocationFee');

    return (
      <div className={styles.mainContainer}>
        <Card className={styles.cardStyle}>
          <CardHeader title={"Automatic Upcharges"} />
          <AjaxActionIndicator state={[applicationSettingsFetchResults, updateApplicationSettingResults]} />
          <DataTable
            columns={this.columns}
            data={displaySettings}
          />
          <AutomaticUpchargeEditModal
            isModalOpen={isLocationFeeSettingModalOpen}
            title={this._translateSettingsKey(editLocationFeeSetting?.settingsKey)}
            value={editLocationFeeSetting?.settingsValue}
            onSaveSetting={this._onSaveLocationFeeSetting}
            onChangeSetting={this._onLocationFeeSettingChange}
          />
        </Card>
      </div>
    )
  }
}

export const AutomaticUpchargesViewPage = ApplicationSettingsService.inject(_AutomaticUpchargesViewPage);