import {
  React,
  _
} from "$Imports/Imports";

import {
  Address,
  Customer,
  Place,
  Region,
} from "$Generated/api";

import { getFormattedZipPostalCode } from "$Shared/utilities/helpers";

const styles: {
  addressDiv: string
} = require("./QuoteStopEntry.scss");

interface IAddressDisplayBaseProps {
  regions?: Region[],
  address?: Address,
  customer?: Customer,
  place?: Place,
}

export class AddressDisplay extends React.PureComponent<IAddressDisplayBaseProps> {

  render() {
    const {
      regions,
      address,
      customer,
      place,
    } = this.props;

    var customerName, tmcustomerId, address1, address2, regionAbbreviation, website: string | undefined;
    var addressParts: string[] = [];
    const selectedCustomer = address ? address.customer ?? undefined : customer ?? undefined;
    const displayAddress = address ?? customer ?? undefined;
    if (selectedCustomer) {
      tmcustomerId = selectedCustomer?.tmcustomerId ?? undefined;
      customerName = (selectedCustomer?.customerName ?? "") + (tmcustomerId ? ` (${tmcustomerId})` : undefined);
      address1 = displayAddress?.address1 ?? undefined;
      address2 = displayAddress?.address2 ?? undefined;
      displayAddress?.city ? addressParts.push(displayAddress?.city) : "";
      regionAbbreviation = (displayAddress?.region?.regionAbbreviation ?? _.find(regions, r => r.id === displayAddress?.regionId)?.regionAbbreviation) ?? undefined;
      regionAbbreviation ? addressParts.push(regionAbbreviation) : "";
      const zipPostalCode = getFormattedZipPostalCode(displayAddress);
      zipPostalCode ? addressParts.push(zipPostalCode) : "";
      website = selectedCustomer?.website ?? undefined;
    } else if (place) {
      address1 = place.address ?? undefined;
      place.city ? addressParts.push(place.city) : "";
      place.stateProvince ? addressParts.push(place.stateProvince) : "";
      const zipPostalCode = getFormattedZipPostalCode(place);
      zipPostalCode ? addressParts.push(zipPostalCode) : "";
    }
    const cityAbbrevZip = addressParts.length > 0 ? addressParts.join(", ") : undefined;

    return (
      <>
        { /* prefer selected address over place (city/state lookup from zip). Prefer full selected address over partial*/
          selectedCustomer ?
            regions ?
              <>
                <div><b>{`${customerName}`}</b></div>
                <div>{address1}</div>
                <div>{address2}</div>
                <div>{cityAbbrevZip}</div>
                <div><a href={website} target="_blank">{website}</a></div>
              </>
            : <div className={styles.addressDiv}>
                <div>{address1}</div>
                <div>{address2}</div>
                <div>{cityAbbrevZip}</div>
              </div>
          : place ?
            <div className={styles.addressDiv}>
              <div>{cityAbbrevZip}</div>
            </div>
          : undefined
        }
      </>
    )
  }
}