import {
  _,
  React,
  bind,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  BinaryChoiceDialog,
  AdvanceTextField,
  AjaxActionIndicator,
  PhoneNumberInputField
} from "$Imports/CommonComponents";

import {
  Customer,
  CustomerHour,
  Region,
  Employee,
  CustomerHourDayOfWeekEnum,
  CustomerSource
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TimePicker,
  TextField,
  SelectChangeEvent
} from "$Imports/MaterialUIComponents";

import {
  ExpandMoreIcon
} from "$Imports/MaterialUIIcons";

import {
  CustomerAddressRequiredValidationSchema,
  ICustomerServiceInjectedProps,
  CustomerService
} from "$State/CustomerFreezerService";

import {
  LoadingInstructionService,
  ILoadingInstructionServiceInjectedProps
} from "$State/LoadingInstructionFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  ErrorService
} from "$State/ErrorFreezerService";

import AppConstants from "$Utilities/AppConstants";

import { appliesWhenTextMap } from "$Utilities/enumUtil";

import { getTrimmedZipPostalCode } from "$Shared/utilities/helpers";

const styles: {
  modalContainer: string;
  inputContainer: string;
  textfield: string;
  flexRow: string;
  checkboxContainer: string;
  alertContainer: string;
  accordion: string;
  templateContainer: string;
  hourRow: string;
  dayLabel: string;
  textbox: string;
  checkbox: string;
  instructionsTable: string;
} = require("./AddEditCustomerModal.scss");

interface IAddEditCustomerModalProps {
  isOpen: boolean;
  isFetching: boolean;
  customer: Customer;
  regions: Region[];
  salesReps: Employee[];
  customerSources: CustomerSource[];
  disableCallerCheckbox?: boolean;
  disableShipperCheckbox?: boolean;
  disableConsigneeCheckbox?: boolean;
  onChange: (customer: Partial<Customer>) => void;
  onHoursChange: (day: CustomerHourDayOfWeekEnum, hours: Partial<CustomerHour>) => void;
  onInstructionsChange: (loadingInstructionId: number) => void;
  onSave: () => void;
  onCancel: () => void;
}

type AddEditCustomerModalProps = IAddEditCustomerModalProps
& ICustomerServiceInjectedProps
& ILoadingInstructionServiceInjectedProps;

interface IAddEditCustomerModalState {
  confirmModalOpen: boolean;
  templateDay: CustomerHourDayOfWeekEnum | "";
  validationErrors: ValidationError | null;
}

export class _addEditCustomerModal extends React.Component<AddEditCustomerModalProps, IAddEditCustomerModalState> {

  state: IAddEditCustomerModalState = {
    confirmModalOpen: false,
    templateDay: "",
    validationErrors: null
  }

  @bind
  private _onCancel() {
    this.setState({
      templateDay: "",
      validationErrors: null
    });
    this.props.onCancel();
  }

  @bind
  private async _onValidateCustomer(): Promise<boolean> {
    const schema = CustomerAddressRequiredValidationSchema;
    const customerErrors = await validateSchema(schema, this.props.customer);
    this.setState({ validationErrors: customerErrors });
    if (customerErrors) {
      return true;
    }
    return false;
  }

  @bind
  private async _checkCustomerExists(): Promise<boolean> {
    await this.props.customerService.checkCustomerExists();

    const result = this.props.customerService.getState().customerExistsResults;
    
    return result?.data ?? false;
  }

  @bind
  private async _onCheckCustomerExists() {
    var hasErrors = await this._onValidateCustomer();

    if (hasErrors) {
      return;
    }

    this._checkCustomerExists().then((exists) => {
      this.setState({ confirmModalOpen: exists});
      if (!exists) {
        this._onSave();
      }
    });
  }

  @bind
  private async _onSave() {
    if(this.state.confirmModalOpen){
      this.setState({confirmModalOpen: false});
    } else {
      var hasErrors = await this._onValidateCustomer();

      if (hasErrors) {
        return;
      }
    }

    this.setState({ templateDay: "" });
    this.props.onSave();
  }

  @bind
  private _onChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.onChange({
      [e.target.name]: e.target.value
    });
  }

  @bind
  private _onZipPostalCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = getTrimmedZipPostalCode(e.target?.value);
    this.props.onChange({
      [e.target.name]: value
    });
  }

  @bind
  private _onCellNumberChange(newValue: string | undefined) {
    this.props.onChange({ cellNumber: newValue });
  }

  @bind
  private _onPhoneNumberChange(newValue: string | undefined) {
    this.props.onChange({ phoneNumber: newValue });
  }

  @bind
  private _onCheckboxChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    if (e.target.name === "isCaller" && !checked) {
      this.props.onChange({ salesAgentId: undefined });
    }

    this.props.onChange({
      [e.target.name]: checked
    });
  }

  @bind
  async _onInstructionCheckboxChange(loadingInstructionId: number | undefined, checked: boolean) {
    if (!loadingInstructionId) {
      return;
    }

    if (checked === true) {
      await this.props.onInstructionsChange(loadingInstructionId);
    }
    if (checked === false) {
      await this.props.onInstructionsChange(loadingInstructionId);
    }
  }

  @bind
  private _onWebsiteBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (e.target.value && !e.target.value.startsWith("http://") && !e.target.value.startsWith("https://")) {
      this.props.onChange({ website: `https://${e.target.value}` });
    }
  }

  @bind
  private _onTemplateDayChange(e: SelectChangeEvent<CustomerHourDayOfWeekEnum | "">) {
    this.setState({
      templateDay: e.target.value ? e.target.value as CustomerHourDayOfWeekEnum : ""
    });
  }

  @bind
  private _applyTemplate(includeWeekends: boolean) {
    const {
      templateDay
    } = this.state;
    if (templateDay === "") return;

    const {
      customer
    } = this.props;
    const index = _.findIndex(customer.customerHours ?? [], h => h.dayOfWeek === templateDay);
    const currentDay = customer.customerHours?.[index];

    if (currentDay !== undefined && (currentDay.allDay || currentDay.closed || currentDay.openTime || currentDay.closeTime)) {
      const dayArray = includeWeekends ? AppConstants.DayOfWeekEnumArray : _.filter(AppConstants.DayOfWeekEnumArray, day => day !== "Saturday" && day !== "Sunday");

      _.forEach(dayArray, (day) => {
        this.props.onHoursChange(day, {
          openTime: currentDay.openTime,
          closeTime: currentDay.closeTime,
          allDay: currentDay.allDay,
          closed: currentDay.closed
        });
      });
    }
    else {
      ErrorService.pushErrorMessage(`Hours of operation not set for ${templateDay}`);
    }
  }

  @bind
  private openTimeChange(dayOfWeek: CustomerHourDayOfWeekEnum, time: string | undefined, ) {
    this.props.onHoursChange(dayOfWeek, { openTime: time });
  }

  @bind
  private closeTimeChange(dayOfWeek: CustomerHourDayOfWeekEnum, time: string | undefined, ) {
    this.props.onHoursChange(dayOfWeek, { closeTime: time });
  }

  @bind
  private allDayChange(dayOfWeek: CustomerHourDayOfWeekEnum, check: boolean) {
    if (check) {
      this.props.onHoursChange(dayOfWeek, {
        allDay: check,
        closed: false,
        openTime: "",
        closeTime: ""
      });
    }
    else {
      this.props.onHoursChange(dayOfWeek, { allDay: check });
    }
  }

  @bind
  private isClosedChange(dayOfWeek: CustomerHourDayOfWeekEnum, check: boolean) {
    if (check) {
      this.props.onHoursChange(dayOfWeek, {
        closed: check,
        allDay: false,
        openTime: "",
        closeTime: ""
      });
    }
    else {
      this.props.onHoursChange(dayOfWeek, { closed: check });
    }
  }

  @bind
  private removeInactive(propName: string, name: string) {
    this.props.onChange({
      [propName]: undefined
    });
    ErrorService.pushErrorMessage(`Inactive ${name} removed.`);
  }

  componentDidUpdate() {
    const { customer, salesReps, customerSources } = this.props;
    if (customer.salesAgentId) {
      const salesRepInactive = salesReps && customer.salesAgentId ? !(salesReps.find(s => s.id === customer.salesAgentId)?.isActive) : false;
      if (salesRepInactive) {
        this.removeInactive("salesAgentId", "sales representative")
      }
    }
    if (customer.customerSourceId) {
      const sourceInactive = customerSources.length > 0 && customer?.customerSourceId ? !(customerSources.find(s => s.id === customer?.customerSourceId)?.isActive) : false;
      if (sourceInactive) {
        this.removeInactive("customerSourceId", "customer source")
      }
    }
  }

  render() {
    const {
      isOpen,
      isFetching,
      customer,
      regions,
      salesReps,
      customerSources,
      disableCallerCheckbox,
      disableShipperCheckbox,
      disableConsigneeCheckbox
    } = this.props;

    const {
      activeLoadingInstructions
    } = this.props.loadingInstructionService.getState();

    const validationsParser = new ValidationErrorParser<Customer>(this.state.validationErrors);

    return (
      <>
        <Dialog
          open={isOpen}
          maxWidth="md"
        >
          <DialogTitle>{`${customer.id ? "Edit" : "Add"} Customer`}</DialogTitle>
          <AjaxActionIndicator
            showProgress={isFetching}
          />
          <DialogContent className={styles.modalContainer}>
            <div className={styles.inputContainer}>
              <div>
                <AdvanceTextField
                  label="Customer Name"
                  name="customerName"
                  required
                  onChange={this._onChange}
                  value={customer.customerName ?? ""}
                  error={!validationsParser.isValid("customerName")}
                  helperText={validationsParser.validationMessage("customerName")}
                  className={styles.textfield}
                />
                <AdvanceTextField
                  label="Contact Name"
                  name="contactName"
                  required
                  onChange={this._onChange}
                  value={customer.contactName ?? ""}
                  error={!validationsParser.isValid("contactName")}
                  helperText={validationsParser.validationMessage("contactName")}
                  className={styles.textfield}
                />
                <AdvanceTextField
                  label="Email"
                  name="emailAddress"
                  required={customer.isCaller}
                  onChange={this._onChange}
                  value={customer.emailAddress ?? ""}
                  error={!validationsParser.isValid("emailAddress")}
                  helperText={validationsParser.validationMessage("emailAddress")}
                  className={styles.textfield}
                />
                <AdvanceTextField
                  label="Website"
                  name="website"
                  onBlur={this._onWebsiteBlur}
                  onChange={this._onChange}
                  value={customer.website ?? ""}
                  error={!validationsParser.isValid("website")}
                  helperText={validationsParser.validationMessage("website")}
                  className={styles.textfield}
                />
                <PhoneNumberInputField
                  label="Phone Number"
                  name="phoneNumber"
                  required
                  onPhoneNumberChange={this._onPhoneNumberChange}
                  captureExt
                  value={customer.phoneNumber ?? ""}
                  error={!validationsParser.isValid("phoneNumber")}
                  helperText={validationsParser.validationMessage("phoneNumber")}
                  className={styles.flexRow}
                />
                <PhoneNumberInputField
                  label="Cell Number"
                  name="cellNumber"
                  onPhoneNumberChange={this._onCellNumberChange}
                  value={customer.cellNumber ?? ""}
                  error={!validationsParser.isValid("cellNumber")}
                  helperText={validationsParser.validationMessage("cellNumber")}
                  className={styles.textfield}
                />
              </div>
              <div>
                <AdvanceTextField
                  label="Address"
                  name="address1"
                  required
                  onChange={this._onChange}
                  value={customer.address1 ?? ""}
                  error={!validationsParser.isValid("address1")}
                  helperText={validationsParser.validationMessage("address1")}
                  className={styles.textfield}
                />
                <AdvanceTextField
                  label="Address line 2"
                  name="address2"
                  onChange={this._onChange}
                  value={customer.address2 ?? ""}
                  error={!validationsParser.isValid("address2")}
                  helperText={validationsParser.validationMessage("address2")}
                  className={styles.textfield}
                />
                <AdvanceTextField
                  label="City"
                  name="city"
                  required
                  onChange={this._onChange}
                  value={customer.city ?? ""}
                  error={!validationsParser.isValid("city")}
                  helperText={validationsParser.validationMessage("city")}
                  className={styles.textfield}
                />
                <FormControl className={styles.textfield} error={!validationsParser.isValid("regionId")}>
                  <InputLabel>Region *</InputLabel>
                  <Select
                    value={customer.regionId ?? ""}
                    name="regionId"
                    required
                    onChange={(event) => this._onChange(event as React.ChangeEvent<HTMLInputElement>)}
                  >
                    {_.map(regions, (r, idx) =>
                      <MenuItem value={r.id} key={idx}>{r.regionName}</MenuItem>
                    )}
                  </Select>
                  {!validationsParser.isValid("regionId") && <FormHelperText>{validationsParser.validationMessage("regionId")}</FormHelperText>}
                </FormControl>
                <AdvanceTextField
                  label="Postal Code"
                  name="zipPostalCode"
                  required
                  onChange={this._onZipPostalCodeChange}
                  value={customer.zipPostalCode ?? ""}
                  error={!validationsParser.isValid("zipPostalCode")}
                  helperText={validationsParser.validationMessage("zipPostalCode")}
                  className={styles.textfield}
                />
                <FormControl className={styles.textfield}>
                  <InputLabel shrink>Sales Representative</InputLabel>
                  <Select
                    value={customer.salesAgentId ?? ""}
                    name="salesAgentId"
                    disabled={!(customer.isCaller ?? false)}
                    onChange={(event) => this._onChange(event as React.ChangeEvent<HTMLInputElement>)}
                    displayEmpty
                  >
                    <MenuItem value="">Not Assigned</MenuItem>
                    {salesReps.map((s, idx) => (
                      <MenuItem value={s.id} key={idx}>{`${s.firstName} ${s.lastName}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={styles.textfield}>
                  <InputLabel>Customer Source</InputLabel>
                  <Select
                    value={customer.customerSourceId ?? ""}
                    name="customerSourceId"
                    onChange={(event) => this._onChange(event as React.ChangeEvent<HTMLInputElement>)}
                  >
                    {customerSources.map((c, idx) => (
                      <MenuItem value={c.id} key={idx}>{c.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                label="Caller?"
                control={(
                  <Checkbox
                    checked={customer.isCaller ?? false}
                    onChange={this._onCheckboxChange}
                    name="isCaller"
                    disabled={disableCallerCheckbox}
                  />
                )}
              />
              <FormControlLabel
                label="Shipper?"
                disabled={disableShipperCheckbox}
                control={(
                  <Checkbox
                    checked={customer.isShipper ?? false}
                    onChange={this._onCheckboxChange}
                    name="isShipper"
                  />
                )}
              />
              <FormControlLabel
                label="Consignee?"
                disabled={disableConsigneeCheckbox}
                control={(
                  <Checkbox
                    checked={customer.isConsignee ?? false}
                    onChange={this._onCheckboxChange}
                    name="isConsignee"
                  />
                )}
              />
            </div>
            <FormHelperText error={!validationsParser.isValid("isCaller")}>{validationsParser.validationMessage("isCaller")}</FormHelperText>
            <div className={styles.alertContainer}>
              <FormControlLabel
                label="Display alert when customer is added to a quote?"
                disabled={false}
                control={(
                  <Checkbox
                    checked={customer.displayAlert ?? false}
                    onChange={this._onCheckboxChange}
                    name="displayAlert"
                  />
                )}
              />
              <AdvanceTextField
                label="Alert Text"
                name="alert"
                disabled={!customer.displayAlert}
                onChange={this._onChange}
                value={customer.alert ?? ""}
                error={!validationsParser.isValid("alert")}
                helperText={validationsParser.validationMessage("alert")}
                multiline
                className={styles.textfield}
                inputProps={{ maxLength: 500 }}
              />
            </div>
            <Accordion
              disableGutters
              elevation={2}
              className={styles.accordion}
              sx={{
                '&:before': {
                    display: 'none',
                }
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}><b>Hours of Operation</b></AccordionSummary>
              <AccordionDetails style={{ paddingTop: "0" }}>
                <div className={styles.templateContainer}>
                  <FormControl style={{ width: "150px" }}>
                    <InputLabel>Day of Week</InputLabel>
                    <Select
                      value={this.state.templateDay}
                      name="dayOfWeek"
                      onChange={this._onTemplateDayChange}
                    >
                      {_.map(AppConstants.DayOfWeekEnumArray, (day, idx) => <MenuItem value={day} key={idx}>{day}</MenuItem>)}
                    </Select>
                  </FormControl>
                  <Button onClick={() => this._applyTemplate(false)}>Apply to Weekdays</Button>
                  <Button onClick={() => this._applyTemplate(true)}>Apply to All</Button>
                </div>
                {
                  // open/close times are converted moment <--> string
                  _.map(AppConstants.DayOfWeekEnumArray, (day, idx) => {
                    const index = _.findIndex(customer.customerHours ?? [], h => h.dayOfWeek === day);
                    const currentDay = customer.customerHours?.[index];

                    return (
                      <div className={styles.hourRow} key={idx}>
                        <div className={styles.dayLabel}>{day}:</div>
                        <TimePicker
                          label="From"
                          value={currentDay?.openTime ? moment(currentDay.openTime, "HH:mm") : null}
                          onChange={(time: moment.Moment | null) => this.openTimeChange(day, time?.format("HH:mm"))}
                          disabled={currentDay?.allDay || currentDay?.closed}
                          ampm={true}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              className={styles.textbox}
                              error={!validationsParser.isValidDeep(`customerHours[${index}].openTime`)}
                              helperText={validationsParser.validationMessageDeep(`customerHours[${index}].openTime`)}
                            />
                          )}
                        />
                        <TimePicker
                          label="To"
                          value={currentDay?.closeTime ? moment(currentDay.closeTime, "HH:mm") : null}
                          onChange={(time: moment.Moment | null) => this.closeTimeChange(day, time?.format("HH:mm"))}
                          disabled={currentDay?.allDay || currentDay?.closed}
                          ampm={true}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              className={styles.textbox}
                              error={!validationsParser.isValidDeep(`customerHours[${index}].closeTime`)}
                              helperText={validationsParser.validationMessageDeep(`customerHours[${index}].closeTime`)}
                            />
                          )}
                        />
                        <div className={styles.checkbox}>
                          <FormControlLabel
                            label="All Day?"
                            control={(
                              <Checkbox
                                checked={currentDay?.allDay ?? false}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this.allDayChange(day, checked)}
                                name="allDay"
                              />
                            )}
                          />
                        </div>
                        <div className={styles.checkbox}>
                          <FormControlLabel
                            label="Closed?"
                            control={(
                              <Checkbox
                                checked={currentDay?.closed ?? false}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this.isClosedChange(day, checked)}
                                name="closed"
                              />
                            )}
                          />
                        </div>
                      </div>
                    );
                  })
                }
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters={false}
              elevation={2}
              className={styles.accordion}
              sx={{
                '&:before': {
                    display: 'none',
                }
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}><b>Loading/Unloading Instructions</b></AccordionSummary>
              <AccordionDetails style={{ paddingTop: "0" }}>
                <table className={styles.instructionsTable}>
                  <tbody>
                    <tr>
                      <th>Instruction</th>
                      <th>Applies When</th>
                    </tr>
                    {
                      _.map(activeLoadingInstructions, (i, idx) => {
                        const isChecked: boolean = customer?.customerLoadingInstructions?.find(c => c.loadingInstructionId === i.id) ? true : false;
                        return <tr key={idx}>
                          <td>
                            <FormControlLabel
                              label={i.instruction ?? ""}
                              control={(
                                <Checkbox
                                  checked={isChecked}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this._onInstructionCheckboxChange(i.id, checked)}
                                />
                              )}
                            />
                          </td>
                          <td>{appliesWhenTextMap[i.appliesTo ?? ""]}</td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this._onCancel}
              disabled={isFetching}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={customer.id ? this._onSave : this._onCheckCustomerExists}
              disabled={isFetching}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        
        <BinaryChoiceDialog
          isOpen={this.state.confirmModalOpen}
          message="Customer already exists. Click Continue to create a duplicate Customer."
          trueText={"Continue"}
          falseText={"Cancel"}
          onClick={(value) => {
            if (value) {
              this._onSave();
            } else {
              this.setState({ confirmModalOpen: false });
            }
          }}
        />
      </>
    );
  }
}
export const AddEditCustomerModal = CustomerService.inject(
  LoadingInstructionService.inject(
    _addEditCustomerModal
  )
);