import {
  React,
  bind
} from "$Imports/Imports";

import {
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent
} from "$Imports/MaterialUIComponents";

import {
  DEFAULT_TIMEOUT
} from "$Utilities/ratingUtil";

interface IOperationTimeoutModalProps {
  isOpen: boolean;
  isFetching?: boolean;
  onRetry: (timeout: number) => void;
  onCancel: () => void;
  operationText: string;
}

interface IOperationTimeoutModalState {
  timeout: number;
}

const styles: {
  inlineContainer: string;
  inlineLabel: string;
  inlineInput: string;
} = require("./OperationTimeoutModal.scss");

class _OperationTimeoutModal extends React.PureComponent<IOperationTimeoutModalProps, IOperationTimeoutModalState> {
  state: IOperationTimeoutModalState = {
    timeout: DEFAULT_TIMEOUT
  };

  componentDidUpdate(prev: IOperationTimeoutModalProps) {
    if (!this.props.isOpen && prev.isOpen) {
      this.setState({ timeout: DEFAULT_TIMEOUT });
    }
  }

  @bind
  private _onRetry(): void {
    this.props.onRetry(this.state.timeout);
  }

  @bind
  private _onCancel(): void {
    this.setState({
      timeout: DEFAULT_TIMEOUT
    });

    this.props.onCancel();
  }

  @bind
  private _onTimeoutChange(event: SelectChangeEvent): void {
    const timeout = Number(event.target.value) ?? DEFAULT_TIMEOUT;

    this.setState({ timeout: timeout });
  }

  render() {
    const {
      isOpen,
      isFetching,
      operationText
    } = this.props;

    const { timeout } = this.state;

    const description = isFetching
      ? `A ${operationText} operation is in progress.  This dialog will close automatically if the operation completes successfully.`
      : `The ${operationText} operation has timed out.  You may select a longer timeout period and try to ${operationText} again.`;

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="sm"
      >
        <AjaxActionIndicator showProgress={isFetching} />
        <DialogTitle>
          Long-Running Operation
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              {description}
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2} alignItems="center">
                <Grid item className={styles.inlineLabel}>
                  Maximum Operation Time
                </Grid>
                <Grid item className={styles.inlineInput}>
                  <FormControl fullWidth>
                    <Select
                      value={timeout.toString()}
                      onChange={this._onTimeoutChange}
                      disabled={isFetching}
                    >
                      <MenuItem value={DEFAULT_TIMEOUT}>30 seconds</MenuItem>
                      <MenuItem value={60000}>1 minute</MenuItem>
                      <MenuItem value={180000}>3 minutes</MenuItem>
                      <MenuItem value={300000}>5 minutes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this._onCancel()}
            variant="outlined"
            disabled={isFetching}
          >
            Close
          </Button>

          <Button onClick={() => this._onRetry()}
            color="primary"
            disabled={isFetching}
          >
            Retry
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const OperationTimeoutModal = _OperationTimeoutModal;