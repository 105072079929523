import {
  React,
  bind,
  _
} from "$Imports/Imports";

interface IThreeDigitZipEntryBaseProps {
  label?: string | undefined;
  threeDigitZips: string[];
  newZipCode: string;
  textFieldError: boolean;
  helperText: string | null;
  onZipCodeChange?: (zipcode: string) => void;
  onZipCodeEnter?: () => void;
  onZipCodeDelete?: (zipCode: string) => void;
}

import {
  Autocomplete,
  TextField,
  Chip
} from "$Imports/MaterialUIComponents";

export class ThreeDigitZipEntry extends React.Component<IThreeDigitZipEntryBaseProps> {

  @bind
  private _onZipCodeChange(value: string) {
    if (this.props.onZipCodeChange) {
      this.props.onZipCodeChange(value);
    }
  }

  @bind
  private _onZipCodeEnter(e: React.KeyboardEvent<HTMLDivElement>) {
    if (this.props.onZipCodeEnter) {
      if (e.key === "Enter") {
        this.props.onZipCodeEnter();
      }
    }
  }

  @bind
  private _onZipCodeDelete(zipCode: string) {
    if (this.props.onZipCodeDelete) {
      this.props.onZipCodeDelete(zipCode)
    }
  }

  render() {
    const {
      label,
      threeDigitZips,
      newZipCode,
      textFieldError,
      helperText
    } = this.props;

    return (
      <Autocomplete
        multiple
        options={[]}
        freeSolo
        value={threeDigitZips ?? ""}
        inputValue={newZipCode}                 
        onInputChange={(event,value,reason) => {
          if (reason !== "reset") {
            this._onZipCodeChange(value);
          }   
        }}
        renderTags={(value: string[], getTagProps) => 
          value.map((zipCode: string, index: number) => (
            <Chip color="primary" key={index} label={zipCode} onDelete={(e) => this._onZipCodeDelete(zipCode)}/>
          ))
        }
        renderInput={(params) => (
          <TextField {...params} 
            label={label ?? "Three Digit Zips"}
            onKeyDown={this._onZipCodeEnter}
            error={textFieldError}
            helperText={helperText ?? "Type each three-digit zip code and press Enter"}                      
          />
        )}
      />
    );
  }
}