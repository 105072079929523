import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  Helpers,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  LogisticsApiFactory,
  LogisticsMarkup
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ISortState
} from "$Imports/CommonComponents";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

interface ILogisticsModalState {
  isOpen: boolean;
  previousRecord: LogisticsMarkup | undefined;
  newRecord: LogisticsMarkup;
  validationErrors: ValidationError | null;
}

interface ILogisticsServiceState {
  logisticsMarkupsFetchResults: IAjaxState<LogisticsMarkup[]>;
  sortState: ISortState;
  logisticsModalState: ILogisticsModalState;
  logisticsMarkupSaveResults: IAjaxState;
}

const LogisticsMarkupValidationSchema: SchemaOf<NullableOptional<LogisticsMarkup>> = yup.object({
  id: yup.number().notRequired(),
  primaryRate: yup.number()
    .required("Primary Rate is required")
    .min(0, "Primary Rate must be greater than or equal to 0")
    .max(1, "Primary Rate must be less than or equal to 100%"),
  secondaryRate: yup.number()
    .required("Secondary Rate is required")
    .min(0, "Secondary Rate must be greater than or equal to 0")
    .max(1, "Secondary Rate must be less than or equal to 100%"),
  startDateTime: yup.date().notRequired(),
  endDateTime: yup.date().nullable().notRequired(),
  createdById: yup.number().notRequired(),
  createdBy: yup.mixed().nullable().notRequired()
});

const InjectedPropName = "logisticsService";

const initialState = {
  logisticsMarkupsFetchResults: managedAjaxUtil.createInitialState(),
  logisticsMarkupSaveResults: managedAjaxUtil.createInitialState(),
  sortState: {
    sortColumnName: "endDateTime",
    sortDirection: "desc",
  },
  logisticsModalState: {
    isOpen: false,
    previousRecord: undefined,
    newRecord: {},
    validationErrors: null
  }
} as ILogisticsServiceState;

class LogisticsFreezerService extends FreezerService<ILogisticsServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchData() {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "logisticsMarkupsFetchResults",
      params: {},
      onExecute: (apiOptions, params, options) => {
        const factory = LogisticsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1LogisticsMarkupsGet();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch logistics market rates.");
      }
    });
  }

  public setSortState(sortState: Partial<ISortState>) {
    this.freezer.get().sortState.set(sortState);
  }

  public openAddModal() {
    const logisticsMarkupsFetchResults = this.freezer.get().logisticsMarkupsFetchResults.toJS();

    var previousRecord: LogisticsMarkup | undefined;
    if (logisticsMarkupsFetchResults.hasFetched && logisticsMarkupsFetchResults.data) {
      previousRecord = _.find(logisticsMarkupsFetchResults.data, (x) => Helpers.isNullOrUndefined(x.endDateTime));
    }

    this.freezer.get().set({
      logisticsModalState: {
        isOpen: true,
        previousRecord: previousRecord,
        newRecord: {},
        validationErrors: null
      }
    });
  }

  public closeAddModal() {
    this.freezer.get().set({
      logisticsModalState: {
        isOpen: false,
        previousRecord: undefined,
        newRecord: {},
        validationErrors: null
      }
    });
  }

  public async saveNewMarkup() {
    const logisticsModalState = this.freezer.get().logisticsModalState.toJS();

    const errors = await validateSchema(LogisticsMarkupValidationSchema, logisticsModalState, {
      abortEarly: false,
      context: {}
    });

    this.freezer.get().logisticsModalState.set({ validationErrors: errors });

    if (errors) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "logisticsMarkupSaveResults",
      params: {
        body: logisticsModalState.newRecord,
      },
      onExecute: (apiOptions, param, options) => {
        const factory = LogisticsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1LogisticsAddPut(param);
      },
      onOk: (data) => {
        this.closeAddModal();
        this.fetchData();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save logistics market markup record.");
      }
    });
  }

  public onChangeFromModal(value: Partial<LogisticsMarkup>) {
    this.freezer.get().logisticsModalState.newRecord.set(value);
  }
}

export const LogisticsService = new LogisticsFreezerService();
export type ILogisticsServiceInjectedProps = ReturnType<LogisticsFreezerService["getPropsForInjection"]>;
