import {
  React,
  _
} from "$Imports/Imports";

import {
  LabelValueDisplay
} from "$Imports/CommonComponents";

import {
  Card
} from "$Imports/MaterialUIComponents";

import {
  RateInfoDays
} from "./RateInfoDays";

import {
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import { 
  Quote
 } from "$Generated/api";
import { CURRENCY_FORMAT, NUMERIC_SEPARATED_FORMAT } from "$Shared/utilities/formatUtil";

interface IQuoteCardSectionBaseProps {
  quote: Quote,
  isOverDimensional: boolean,
  rateEngineResult: any,
}

const styles: {
  miniCardContainer: string;
  miniCardNarrow: string;
  miniCardWide: string;
  emphasizeValue: string;
} = require('./QuoteCardSection.scss');

export class QuoteCardSection extends React.Component<IQuoteCardSectionBaseProps> {
  render() {
    const {
      quote,
      isOverDimensional,
      rateEngineResult,
    } = this.props;

    const hasRating = !isOverDimensional && !!rateEngineResult;

    return (
      <div className={styles.miniCardContainer}>
          {quote.quoteType === "Full" && 
          <>
          <Card className={styles.miniCardNarrow}>
            <LabelValueDisplay
              label={"Miles"} 
              value={quote?.miles ? <DisplayFormattedNumber value={quote?.miles} formatString={NUMERIC_SEPARATED_FORMAT} /> : ""}
              valueClass={styles.emphasizeValue} />
          </Card>
          <Card className={styles.miniCardNarrow}>
            <RateInfoDays quoteStops={quote?.quoteStops ?? []} />
          </Card>
          </>}
          <Card className={styles.miniCardWide}>
            <div>Range</div>
            <span>
              {(hasRating) &&
                <>
                  <DisplayFormattedNumber
                    value={rateEngineResult?.lowTotalRate}
                    formatString={CURRENCY_FORMAT}
                  />
                  {" to "}
                  <DisplayFormattedNumber
                    value={rateEngineResult?.highTotalRate}
                    formatString={CURRENCY_FORMAT}
                  />
                </>
              }
            </span>
          </Card>
          <Card className={styles.miniCardNarrow}>
            <LabelValueDisplay
              label={"Rate"} 
              value={hasRating ? 
                <DisplayFormattedNumber
                  value={rateEngineResult?.highTotalRate}
                  formatString={CURRENCY_FORMAT} />
                : ""}
              valueClass={styles.emphasizeValue} />
          </Card>
        </div>
    );
  }
}