import {
  React,
  _,
  moment,
} from "$Imports/Imports";

import {
  QuoteStop
} from "$Generated/api"

import {
  validateStopsFilter
} from "$Utilities/quoteStopUtil";

interface IRateInfoDaysProps { 
  quoteStops?: QuoteStop[] | null
}

const styles: {
  emphasis: string,
  flag: string,
} = require("./RateInfoSection.scss");

export class RateInfoDays extends React.PureComponent<IRateInfoDaysProps> {
  
  private determineNumberOfDays(quoteStops: QuoteStop[]): number | null {
    if (quoteStops.length === 0) {
      return null;
    }

    const firstStop = _.first(quoteStops);
    const lastStop = _.last(quoteStops);

    if(lastStop === undefined || firstStop === undefined){
      return null;
    }

    const startDate = moment(firstStop.shipperStartDate).startOf('day');
    const endDate = moment(lastStop.consigneeEndDate ?? lastStop.consigneeStartDate).startOf('days');

    return endDate.diff(startDate, 'days');
  }

  private flaggedStops(quoteStops: QuoteStop[]): boolean {
    return _.some(quoteStops, qs => (qs.isConsigneeExpedited ?? false) || 
      (qs.isConsigneeLayover ?? false) || 
      (qs.isShipperExpedited ?? false) || 
      (qs.isShipperLayover ?? false));
  }

  public render() {
    const {
      quoteStops
    } = this.props;

    const filteredStops = _.filter(quoteStops ?? [], (qs) => validateStopsFilter(qs));
    const numberOfDays = this.determineNumberOfDays(filteredStops);
    const flagged = this.flaggedStops(filteredStops);

    return (
      <>
        Days
        <span className={`${styles.emphasis} ${flagged && styles.flag}`}>
          {numberOfDays}
        </span>
      </>
    )
  }
}