import {
  React,
  bind,
  moment
} from "$Imports/Imports";

import {
  UserAccessControl
} from "$Imports/CommonComponents"

import {
  Edit,
  Delete
} from "$Imports/MaterialUIIcons";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  CapacityRate
} from "$Generated/api";


interface IActionMenuState {
}

interface IActionMenuProps {
  capacityRate: CapacityRate;
  onMenuItemClick?: (capacityRate: CapacityRate) => void;
  onDelete: (data: CapacityRate) => void;
}

export class ActionMenu extends React.PureComponent<IActionMenuProps, IActionMenuState> {

  @bind
  private _onEdit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick(this.props.capacityRate);
    }
  }

  @bind
  private _onDelete() {
    if (this.props.capacityRate) {
      this.props.onDelete(this.props.capacityRate);
    }
  }

  render() {
    const canDelete = this.props.capacityRate?.startDateTime && moment(this.props.capacityRate.startDateTime).isAfter(moment());
    const canEdit = this.props.capacityRate?.endDateTime && moment(this.props.capacityRate.endDateTime).isAfter(moment());

    return (
      <>
        <UserAccessControl roles={["capacity-model:delete"]}>
          { canDelete && <IconButton
            onClick={this._onDelete}
          >
            <Delete />
          </IconButton>}
        </UserAccessControl>
        <UserAccessControl roles={["capacity-model:edit"]}>
          { canEdit &&
          <IconButton
            size="small"
            onClick={this._onEdit}
          >
            <Edit />
          </IconButton> }
        </UserAccessControl>
        
      </>
    );
  }
}
