import {
  React,
  bind,
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  Quote
} from "$Generated/api";

import {
  TimeInputField,
} from "$Imports/CommonComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  DateEntity,
  DateType
} from "./QuoteStopEntry";

import {
  FormControlLabel,
  KeyboardDatePicker,
  TextField,
  TextFieldProps,
  Checkbox
} from "$Imports/MaterialUIComponents";

interface IQuoteStopDatesProps {
  thisQuoteStopIndex: number,
  dateEntity: DateEntity,
  startDate?: Date,
  endDate?: Date,
  isAppointmentRequired?: boolean,
  hardTime?: string,
  viewOnly?: boolean,
  isFullQuoteType: boolean,
  onDateChange?: (dateEntity: DateEntity, dateType: DateType, date: Date | null) => void,
  onHardTimeChange?: (dateEntity: DateEntity, time: string | undefined) => void,
  onApptRequiredChange?: (dateEntity: DateEntity, checked: boolean) => void,
  validationErrors?: ValidationError | null
}

const styles: {
  label: string,
  quoteStopDatesEntry: string
} = require("./QuoteStopEntry.scss");

export class QuoteStopDatesEntry extends React.PureComponent<IQuoteStopDatesProps> {
  
  @bind
  private _onDateChange(date: Date | null, dateType: DateType) {
    if (this.props.onDateChange) {
      this.props.onDateChange(this.props.dateEntity, dateType, date);
    }
  }

  @bind
  private _onHardTimeChange(time: string | undefined) {
    if (this.props.onHardTimeChange) {
      this.props.onHardTimeChange(this.props.dateEntity, time);
    }
  }

  @bind
  private _onApptRequiredChange(checked: boolean) {
    if (this.props.onApptRequiredChange) {
      this.props.onApptRequiredChange(this.props.dateEntity, checked);
    }
  }

  render() {

    const {
      startDate,
      endDate,
      isAppointmentRequired,
      hardTime,
      viewOnly,
      validationErrors,
      dateEntity,
      thisQuoteStopIndex,
      isFullQuoteType
    } = this.props;

    const validationsParserQuote = new ValidationErrorParser<Quote>(validationErrors);

    return (
      <>
        <div className={styles.label}>{`${dateEntity === "shipper" ? "Shipper" : "Consignee"} Schedule`}</div>
        <div className={styles.quoteStopDatesEntry}>
          <KeyboardDatePicker
            value={startDate ?? null}
            onChange={(date: Date | null, keyboard?: string | undefined) => this._onDateChange(date, "Start")}
            disabled={viewOnly}
            inputFormat="MM/DD/YYYY"
            disablePast={true}
            renderInput={(params: TextFieldProps) => <TextField 
              {...params}
              label="From"
              required={true}
              style={{ width: "25%" }}
              error={!validationsParserQuote.isValidDeep(`quoteStops[${thisQuoteStopIndex}].${dateEntity}StartDate`)} 
              helperText={validationsParserQuote.validationMessageDeep(`quoteStops[${thisQuoteStopIndex}].${dateEntity}StartDate`)}
            />}             
          />
          <KeyboardDatePicker
            value={endDate ?? null}
            onChange={(date: Date | null, keyboard?: string | undefined) => this._onDateChange(date, "End")}
            disabled={viewOnly}
            disablePast={true}
            inputFormat="MM/DD/YYYY"
            renderInput={(params: TextFieldProps) => <TextField 
              {...params}
              label="To"
              style={{ width: "25%" }}
              error={!validationsParserQuote.isValidDeep(`quoteStops[${thisQuoteStopIndex}].${dateEntity}EndDate`)} 
              helperText={validationsParserQuote.validationMessageDeep(`quoteStops[${thisQuoteStopIndex}].${dateEntity}EndDate`)}
            />}  
          />
          {isFullQuoteType &&
            <FormControlLabel
              label="Appointment Required"
              control={(
                <Checkbox
                  checked={isAppointmentRequired ?? false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this._onApptRequiredChange(checked)}
                  disabled={viewOnly}
                />
              )}
            />
          }
          {isAppointmentRequired &&
            <TimeInputField
              onTimeChange={this._onHardTimeChange}
              value={hardTime ?? ""}
              disabled={viewOnly}
              label="Appt Time"
              placeholder="hh:mm"
              error={!validationsParserQuote.isValidDeep(`quoteStops[${thisQuoteStopIndex}].${dateEntity}HardTime`)}
              helperText={validationsParserQuote.validationMessageDeep(`quoteStops[${thisQuoteStopIndex}].${dateEntity}HardTime`)}
            />
          }
        </div>
      </>
    );
  }

}