import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  PerStopFee
} from "$Generated/api";

import {
  Card,
  CardActions,
  CardHeader,
  Button
} from "$Imports/MaterialUIComponents";

import {
  DataTable,
  IDataTableColumn,
  DisplayFormattedNumber,
  AjaxActionIndicator,
  directionType,
  DisplayFormattedDatetime
} from "$Imports/CommonComponents";

import {
  IPerStopFeeServiceInjectedProps,
  PerStopFeeService
} from "$State/PerStopFeeFreezerService";

import {
  EditPerStopFee
} from "./EditPerStopFee";

import {
  ActionMenu
} from "./ActionMenu";

import { DATE_WITH_TIME_MERIDIAN_FORMAT } from "$Shared/utilities/formatUtil";

const styles: {
  mainContainer: string;
  actionArea: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface IPerStopFeeConfigBaseProps {
  companyId: number | undefined;
}

type IPerStopFeeConfigProps = IPerStopFeeConfigBaseProps
  & IPerStopFeeServiceInjectedProps;

class _PerStopFeeConfig extends React.Component<IPerStopFeeConfigProps> {

  private readonly columns: Array<IDataTableColumn<PerStopFee>> = [
    {
      columnName: "perStopFee",
      columnFieldData: (x) => <DisplayFormattedNumber value={x.fee} formatString={"$0,0"} />,
      sortMethod: (x) => x.fee,
      headerValue: "Per Stop Fee"
    },
    {
      columnName: "startDate",
      columnFieldData: (x) => <DisplayFormattedDatetime value={x.startDateTime} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />,
      sortMethod: (x) => x.startDateTime,
      headerValue: "Start Date"
    },
    {
      columnName: "endDate",
      columnFieldData: (x) => <DisplayFormattedDatetime value={x.endDateTime} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />,
      sortMethod: (x) => x.endDateTime,
      headerValue: "End Date"
    },
    {
      columnName: "createdBy",
      columnFieldData: (x) => x.createdBy ? x.createdBy.firstName + " " + x.createdBy.lastName : "",
      sortMethod: (x) => x.createdBy ? x.createdBy.firstName + " " + x.createdBy.lastName : "",
      headerValue: "Created By"
    },
    {
      columnName: "action",
      columnFieldData: (x) => (
        <ActionMenu
          perStopFee={x}
          onEdit={this._onEdit}
        />
      ),
      headerValue: "",
    }
  ];

  componentDidMount() {
    this.props.perStopFeeService.fetchData();
  }

  @bind
  private _onSortChange(event: React.MouseEvent<HTMLElement>, columnName: string | undefined, direction: directionType) {
    this.props.perStopFeeService.setSortState({
      sortColumnName: columnName,
      sortDirection: direction,
    });
  }

  @bind
  private _onEdit(perStopFee: PerStopFee) {
    this.props.perStopFeeService.openEditModal(perStopFee);
  }

  @bind
  private _closeModal() {
    this.props.perStopFeeService.closeEditModal();
  }

  @bind
  private _onSave() {
    this.props.perStopFeeService.savePerStopFee();
  }

  @bind
  private _onChange(value: Partial<PerStopFee>) {
    this.props.perStopFeeService.onChangeFromModal(value);
  }

  render() {
    const {
      perStopFeeFetchResults,
      sortState,
      perStopFeeModalState
    } = this.props.perStopFeeService.getState();
    const perStopFeeData = perStopFeeFetchResults.data ?
      perStopFeeFetchResults.data.filter(x => x.companyId === this.props.companyId)
      : [] as PerStopFee[];

    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader
            title="Per Stop Fee"
          />
          <CardActions
            disableSpacing={true}
            className={styles.actionArea}
          />
          <AjaxActionIndicator
            state={[perStopFeeFetchResults]}
          />
          <DataTable
            columns={this.columns}
            data={perStopFeeData}
            defaultSortColumnName={sortState.sortColumnName}
            defaultSortDirection={sortState.sortDirection}
            onSortChange={this._onSortChange}
          />
        </Card>
        <EditPerStopFee
          isOpen={perStopFeeModalState.isOpen}
          newRecord={perStopFeeModalState.newRecord}
          validationErrors={perStopFeeModalState.validationErrors}
          onSave={this._onSave}
          onCancel={this._closeModal}
          onChange={this._onChange}
        />
      </div>
    )
  }
}

export const PerStopFeeConfigView = PerStopFeeService.inject(
  _PerStopFeeConfig
);