import {
  React,
  bind
} from "$Imports/Imports";

import {
  DataTable,
  IDataTableColumn,
  AjaxActionIndicator,
  directionType,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Card,
  CardActions,
  CardHeader,
  Button,
  FormControlLabel,
  Switch
} from "$Imports/MaterialUIComponents";

import {
  Question
} from "$Generated/api";

import {
  IQuestionServiceInjectedProps,
  QuestionService
} from "$State/QuestionFreezerService";

import {
  ICommodityServiceInjectedProps,
  CommodityService
} from "$State/CommodityFreezerService"

import {
  ActionMenu
} from "./ActionMenu";

import {
  AddEditQuestion
} from "./AddEditQuestion";

const styles: {
  mainContainer: string;
  actionArea: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface IUpchargeQuestionsViewBaseProps {
  companyId: number | undefined;
}

type IUpchargeQuestionsViewProps = IUpchargeQuestionsViewBaseProps
  & ICommodityServiceInjectedProps
  & IQuestionServiceInjectedProps;

class _UpchargeQuestionsView extends React.Component<IUpchargeQuestionsViewProps> {
  private readonly columns: Array<IDataTableColumn<Question>> = [
    {
      columnName: "questionText",
      columnFieldData: (d) => d.questionText ?? "",
      headerValue: "Question",
      sortMethod: (d) => d.questionText?.toLowerCase(),
    },
    {
      columnName: "questionType",
      columnFieldData: "questionType",
      headerValue: "Type",
      sortMethod: (d) => d.questionType,
    },
    {
      columnName: "isNa",
      columnFieldData: (d) => d.isNa ? "NA / Yes / No" : "Yes / No",
      headerValue: "Answer",
      sortMethod: (d) => d.isNa,
    },
    {
      columnName: "action",
      columnFieldData: (d) => (
        <ActionMenu
          question={d}
          onMenuItemClick={this._onMenuClick}
        />
      ),
      headerValue: "",
    }
  ]

  componentDidMount() {
    this.props.questionService.fetchQuestions(this.props.companyId);
    this.props.commodityService.fetchCommodities(this.props.companyId);
  }

  @bind
  private _onShowHistoryChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.questionService.setShowHistory(checked, this.props.companyId);
  }

  @bind
  private _onSortChange(event: React.MouseEvent<HTMLElement>, columnName: string | undefined, direction: directionType) {
    this.props.questionService.setSortState({
      sortColumnName: columnName,
      sortDirection: direction,
    });
  }

  @bind
  private _onAddClick() {
    this.props.questionService.openAddQuestion();
  }

  @bind
  private _onMenuClick(question: Question) {
    this.props.questionService.openEditQuestion(question);
  }

  @bind
  private _onChange(question: Partial<Question>) {
    this.props.questionService.onChange(question);
  }

  @bind
  private _onChangeImmediateStart(immediateStart: boolean) {
    this.props.questionService.onChangeImmediateStart(immediateStart);
  }

  @bind
  private _onCancel() {
    this.props.questionService.closeAddEditModal();
  }

  @bind
  private _onSave() {
    this.props.questionService.saveQuestion(this.props.companyId);
  }

  render() {
    const {
      questionFetchResults,
      showHistory,
      sortState,
      modalState
    } = this.props.questionService.getState();
    const {
      commodityFetchResults,
      activeCommodities
    } = this.props.commodityService.getState();
    const questionData = questionFetchResults.data ?? [];

    const isFetching: boolean = questionFetchResults.isFetching || commodityFetchResults.isFetching;

    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader
            title="Questions"
          />
          <CardActions
            disableSpacing={true}
            className={styles.actionArea}
          >
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={showHistory}
                    onChange={this._onShowHistoryChange}
                  />
                )
              }
              label="Show History"
            />
            <UserAccessControl roles={["questions:create"]}>
              <Button
                color="primary"
                onClick={this._onAddClick}
                disabled={isFetching}
              >
                Add
              </Button>
            </UserAccessControl>
          </CardActions>
          <AjaxActionIndicator
            state={[questionFetchResults]}
          />
          <DataTable
            columns={this.columns}
            data={questionData}
            defaultSortColumnName={sortState.sortColumnName}
            defaultSortDirection={sortState.sortDirection}
            onSortChange={this._onSortChange}
          />
        </Card>
        <AddEditQuestion
          modalState={modalState}
          commodities={activeCommodities}
          isFetching={isFetching}
          onChange={this._onChange}
          onChangeImmediateStart={this._onChangeImmediateStart}
          onCancel={this._onCancel}
          onSave={this._onSave}
        />
      </div>
    );
  }
}

export const UpchargeQuestionsView =
  QuestionService.inject(
    CommodityService.inject(
      _UpchargeQuestionsView
    )
  );