import {
  React,
  _
} from "$Imports/Imports";

interface ILabelValueDisplayBaseProps {
  label: any;
  value: any | undefined;
  labelClass?: string | undefined;
  valueClass?: string | undefined;
  valueTitle?: string | undefined;
}

export class LabelValueDisplay extends React.Component<ILabelValueDisplayBaseProps> {
  render() {
    const {
      label,
      value,
      labelClass,
      valueClass,
      valueTitle
    } = this.props;

    return (
      <>
        <span className={labelClass ?? ""}>{label}</span>
        <span className={valueClass ?? ""} title={valueTitle ?? ""}>{value}</span>
      </>
    );
  }
}