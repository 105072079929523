import {
  React,
  bind
} from "$Imports/Imports";

import {
  AccessorialChargeValue
} from "$Generated/api";

import {
  IconButton
} from "$Imports/MaterialUIComponents"

import {
  Check,
  Delete,
  StickyNote2
} from "$Imports/MaterialUIIcons";

import {
  UserAccessControl
} from "$Imports/CommonComponents";

interface IActionMenuProps {
  accessorialChargeValue: AccessorialChargeValue;
  approveChargeValue: (accessorialCharge: AccessorialChargeValue) => void;
  deleteChargeValue: (accessorialCharge: AccessorialChargeValue) => void;
}

const styles: {
  chargeActions: string;
  icon: string;
  stickyNote: string;
  hideActions: string;
} = require("./AccessorialChargesCard.scss");

export class ActionMenu extends React.Component<IActionMenuProps> {
  @bind
  private _approveChargeValue() {
    this.props.approveChargeValue(this.props.accessorialChargeValue);
  }
  
  @bind
  private _deleteChargeValue() {
    this.props.deleteChargeValue(this.props.accessorialChargeValue);
  }

  render() {
    const {
      accessorialChargeValue: accessorialCharge
    } = this.props;

    return (
      <div className={styles.chargeActions}>
        <div className={styles.icon}>
          {accessorialCharge.notes ? 
            <StickyNote2 fontSize="small" titleAccess={accessorialCharge.notes} className={styles.stickyNote} /> : ""
          }
        </div>
        <div className={styles.icon}>
          {accessorialCharge.status === "Pending" ?
            <UserAccessControl roles={["accessorial-charge-value:approve"]}>
              <IconButton size="small" className={styles.hideActions} onClick={this._approveChargeValue} >
                <Check fontSize="small" titleAccess="Approve" />
              </IconButton>
            </UserAccessControl> : ""
          }
        </div>
        <div className={styles.icon}>
          {accessorialCharge.status !== "Approved" ? 
            <UserAccessControl roles={["accessorial-charge-value:delete"]}>
              <IconButton size="small" className={styles.hideActions} onClick={this._deleteChargeValue} >
                <Delete fontSize="small" titleAccess="Delete" />
              </IconButton>
            </UserAccessControl> : ""
          }
        </div>
      </div>
    )
  }
}