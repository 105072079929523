import {
  React,
  numeral
} from "$Imports/Imports";

interface IDisplayFormattedNumberProps {
  value?: number | null;
  formatString: string;
  emptyDisplay?: string;
  prefix?: string;
  postfix? : string;
}


export class DisplayFormattedNumber extends React.PureComponent<IDisplayFormattedNumberProps> {

  render() {
    const {
      value,
      formatString,
      emptyDisplay,
      prefix,
      postfix
    } = this.props;

    return value !== undefined && value !== null ? (prefix ?? "") + numeral(value).format(formatString) + (postfix ?? "") : emptyDisplay != undefined ? emptyDisplay : "";
  }
}