import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  Customer
} from "$Generated/api";

import {
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Link
} from "$Imports/MaterialUIComponents";

import { getFormattedZipPostalCode } from "$Shared/utilities/helpers";

interface IAddCustomerNoteModalProps {
  isOpen: boolean;
  customer: Customer | undefined;
  noteText: string;
  onChange: (noteText: string) => void;
  onClose: () => void;
}

export class AddCustomerNoteModal extends React.PureComponent<IAddCustomerNoteModalProps> {

  @bind
  private _onTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.onChange( e.target.value );
  }

  @bind
  private _onClose() {
    this.props.onClose();
  }

  render() {
    const {
      isOpen,
      customer,
      noteText
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>{customer?.customerName} Contact Information</DialogTitle>
        <DialogContent>
          <div>
            <div>
              <div style={{ fontWeight: "bold" }}>Address</div>
              <div>{customer?.address1 ?? ""}</div>
              <div>{customer?.address2 ?? ""}</div>
              <div>{`${customer?.city ?? ""}, ${customer?.region?.regionAbbreviation ?? ""}, ${getFormattedZipPostalCode(customer) ?? ""}`}</div>
            </div>
            <div style={{ margin: "10px 0" }}>
              <div style={{ fontWeight: "bold" }}>Contact Details</div>
              <div>{customer?.contactName ?? ""}</div>
              <div><u>Phone Number:</u> {customer?.phoneNumber ?? ""}</div>
              <div><u>Cell Number:</u> {customer?.cellNumber ?? ""}</div>
              <div><u>Email:</u> {customer?.emailAddress &&
                <Link
                  color="secondary"
                  href={`mailto:${customer?.emailAddress}`}
                >
                  {customer?.emailAddress}
                </Link>}
              </div>
            </div>
            <AdvanceTextField
              label={"Note"}
              value={noteText}
              onChange={this._onTextChange}
              error={noteText.length > 300 || false}
              helperText={noteText.length > 300 ? "Maximum of 300 characters" : ""}
              multiline
              fullWidth
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this._onClose} disabled={noteText !== "" && noteText.length > 300}>
            {noteText !== "" ? "Save Note & Close" : "Close"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}