import {
  bind,
  React,
  moment
} from "$Imports/Imports";

import {
  DateRangePicker,
  SearchControlsContainer,
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "$Imports/MaterialUIComponents";

import {
  ReminderSearchCriteria,
  ReminderTypeEnum
} from "$Generated/api";

import {
  EMPTY_REMINDER_SEARCH,
  IReminderServiceInjectedProps,
  ReminderService,
  REMINDER_TYPES
} from "$State/ReminderFreezerService";

interface IOwnProps {
  onSubmit: (search: ReminderSearchCriteria) => void;
}

type OwnProps = IOwnProps
  & IReminderServiceInjectedProps;

interface IOwnState {
  searchCriteria: ReminderSearchCriteria;
}

const styles: {
  container: string;
} = require("./ReminderSearchForm.scss");

class _ReminderSearchForm extends React.PureComponent<OwnProps, IOwnState> {
  state: IOwnState = {
    searchCriteria: { ...EMPTY_REMINDER_SEARCH }
  };

  @bind
  private _onTypeChange(type?: ReminderTypeEnum): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        type: type ?? undefined
      }
    }));
  }

  @bind
  private _onTextChange(text: string): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        search: text ?? undefined
      }
    }));
  }

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        startDate: start ? moment(start).startOf('day').toDate() : undefined,
        endDate: end ? moment(end).endOf('day').toDate() : undefined
      }
    }));
  }

  @bind
  private _onSubmit(): void {
    const { searchCriteria } = this.state;

    this.props.onSubmit(searchCriteria);
  }

  @bind
  private _onClear(): void {
    this.setState({
      searchCriteria: { ...EMPTY_REMINDER_SEARCH }
    });

    this.props.onSubmit(EMPTY_REMINDER_SEARCH);
  }

  componentDidMount() {
    const { searchCriteria } = this.props.reminderService.getState();

    this.setState({
      searchCriteria: { ...searchCriteria }
    });
  }

  render() {
    const {
      searchCriteria
    } = this.state;

    return (
      <SearchControlsContainer
        className={styles.container}
        onSubmit={this._onSubmit}
        onClear={this._onClear}
      >
        <AdvanceTextField
          style={{ flex: "1" }}
          label="Customer / Description"
          value={searchCriteria.search}
          onChange={(event) => this._onTextChange(event.target.value)}
        />

        <FormControl style={{ flex: "0 0 6rem" }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={searchCriteria.type}
            onChange={(event) => this._onTypeChange(event.target.value as ReminderTypeEnum)}
          >
            <MenuItem value="">All</MenuItem>
            {REMINDER_TYPES.map((x, idx) => (
              <MenuItem value={x} key={idx}>
                {x}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div style={{ display: "inline-flex", flex: "0 0 17rem", gap: "0.5rem" }}>
          <DateRangePicker
            startDate={searchCriteria.startDate}
            endDate={searchCriteria.endDate}
            onChange={this._onDateRangeChange}
          />
        </div>
      </SearchControlsContainer>
    );
  }
}

export const ReminderSearchForm = ReminderService.inject(_ReminderSearchForm);
