import {
  React,
  bind,
  NumberFormat,
  NumberFormatValues,
  _
} from "$Imports/Imports";

import {
  CardLinedHeader
} from "$Imports/CommonComponents";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps,
  NegotiatedQuoteDataEntry
} from "$State/QuoteEntryFreezerService";

import { 
  TextField
 } from "$Imports/MaterialUIComponents";

import { 
  ValidationErrorParser
 } from "$Utilities/ValidationErrorParser";

import { 
  Quote 
} from "$Generated/api";

interface IOverdimensionalApprovalCardBaseProps {
  quote: Quote;
  negotiatedQuoteDataEntry: NegotiatedQuoteDataEntry;
  approvalReasons: string | undefined;
  createReminderButton: JSX.Element | undefined;
  actionButtons: JSX.Element;
}

type IOverdimensionalApprovalCardProps = IOverdimensionalApprovalCardBaseProps & IQuoteEntryServiceInjectedProps;

const styles: {
  quoteCard: string
  status: string;
  quoteButtons: string;
} = require('./RateInfoSection.scss');

class _OverdimensionalApprovalCard extends React.Component<IOverdimensionalApprovalCardProps> {

  @bind
  private _onNegotiatedQuoteChange(value?: NumberFormatValues) {
    this.props.QuoteEntryService.updateNegotiatedQuoteDataEntry({ negotiatedRateValue: value?.floatValue });
  }

  render() {
    const {
      negotiatedRateDataEntryValidationErrors
    } = this.props.QuoteEntryService.getState();

    const {
      quote,
      negotiatedQuoteDataEntry,
      approvalReasons,
      createReminderButton,
      actionButtons
    } = this.props;

    const validationsNegotiatedRates = new ValidationErrorParser<NegotiatedQuoteDataEntry>(negotiatedRateDataEntryValidationErrors);

    return (
      <CardLinedHeader
        className={styles.quoteCard}
        titleText={`Quote ${quote.status ? `Q${quote.quoteNumber}` : ""}`}
        titleComponents={(
          <div>
            <span className={styles.status}>{approvalReasons}</span>
            {createReminderButton}
          </div>
        )}
      >
        <div style={{ textAlign: "center" }}>
          <h1>Approval Needed for Over Dimensional</h1>
        </div>
        {/** TODO: KT-1809 refactor out the table */}
        <table>
          <tbody>
            <tr style={{ width: "100%" }}>
              <td>
                <NumberFormat
                  allowNegative={false}
                  decimalSeparator="."
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix="$"
                  label="Negotiated Rate"
                  value={negotiatedQuoteDataEntry.negotiatedRateValue?.toFixed(2) ?? ""}
                  customInput={TextField}
                  onValueChange={this._onNegotiatedQuoteChange}
                  error={!validationsNegotiatedRates?.isValid("negotiatedRateValue")}
                  helperText={validationsNegotiatedRates?.validationMessage("negotiatedRateValue")}
                />
              </td>
            </tr>
            <tr>
              <td className={styles.quoteButtons}>
                {actionButtons}
              </td>
            </tr>
          </tbody>
        </table>
      </CardLinedHeader>
    );
  }
}

export const OverdimensionalApprovalCard = QuoteEntryService.inject(_OverdimensionalApprovalCard);