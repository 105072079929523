import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  ISortState
} from "$Imports/CommonComponents";

import {
  QuestionApiFactory,
  Question,
  QuestionUpdateVM,
  QuestionQuestionTypeEnum
} from "$Generated/api";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

export type formModeType = "add" | "edit" | "none";

export interface ModalState {
  formMode: formModeType;
  openedAt: Date;
  immediateStart: boolean;
  editedQuestion: Question | null;
  originalQuestion: Question | null;
  validationErrors: ValidationError | null;
}

interface IQuestionState {
  showHistory: boolean;
  questionFetchResults: IAjaxState<Question[]>;
  activeCommodityQuestionResults: IAjaxState<Question[]>;
  questionSaveResults: IAjaxState<Question>;
  sortState: ISortState;
  modalState: ModalState;
}

const InjectedPropName = "questionService";

const QuestionValidationSchema: SchemaOf<NullableOptional<Question>> = yup.object({
  id: yup.number().notRequired(),
  questionText: yup.string().required("Question is required.").max(100, "Questions cannot be longer than 100 characters."),
  questionType: yup.mixed<QuestionQuestionTypeEnum>().oneOf(["Commodity", "Upcharge"]).required("Question type is required."),
  isNa: yup.boolean().notRequired(), // technically *is* required but it's a boolean field: no response is the same as false
  isActive: yup.boolean().notRequired(), //same as above
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  quoteStopFreightQuestions: yup.array().notRequired(),
  commodityQuestions: yup.array().notRequired()
});

const initialState = {
  showHistory: false,
  questionFetchResults: managedAjaxUtil.createInitialState(),
  activeCommodityQuestionResults: managedAjaxUtil.createInitialState(),
  questionSaveResults: managedAjaxUtil.createInitialState(),
  sortState: {
    sortColumnName: "questionText",
    sortDirection: "asc",
  },
  modalState: {
    formMode: "none",
    openedAt: new Date(),
    immediateStart: false,
    editedQuestion: null,
    originalQuestion: null,
    validationErrors: null
  }
} as IQuestionState;

class QuestionFreezerService extends FreezerService<IQuestionState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchQuestions(companyId: number | undefined, forceUpdate: boolean = false) {
    const {
      questionFetchResults,
      showHistory
    } = this.freezer.get();

    if (!companyId || (questionFetchResults.hasFetched && !forceUpdate)) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "questionFetchResults",
      params: {
        showHistory,
        companyId
      },
      onExecute: (apiOptions, param, options) => {
        const factory = QuestionApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1QuestionsCompanyIdShowHistoryGet(param);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch questions.");
      }
    });
  }

  public fetchActiveCommodityQuestions(dateParam: Date) {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "activeCommodityQuestionResults",
      params: {
        
      },
      onExecute: (apiOptions, param, options) => {
        const factory = QuestionApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1QuestionsActiveCommodityGet(param);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch commodity questions.")
      }
    })
  }

  public setShowHistory(showHistory: boolean, companyId: number | undefined) {
    this.freezer.get().set({ showHistory });
    this.fetchQuestions(companyId, true);
  }

  public setSortState(sortState: Partial<ISortState>) {
    this.freezer.get().sortState.set(sortState);
  }

  public openAddQuestion() {
    var now = new Date();
    now.setSeconds(0);

    const blankQuestion: Question = {
      questionText: "",
      questionType: undefined,
      isNa: undefined,
      isActive: true
    };
    
    this.freezer.get().set({
      modalState: {
        editedQuestion: _.cloneDeep(blankQuestion),
        originalQuestion: _.cloneDeep(blankQuestion),
        validationErrors: null,
        formMode: "add",
        openedAt: now,
        immediateStart: false
      }
    });
  }

  public openEditQuestion(question: Question) {
    var now = new Date();
    now.setSeconds(0);

    var editedQuestion = _.cloneDeep(question);
    editedQuestion.id = undefined;

    this.freezer.get().set({
      modalState: {
        editedQuestion: editedQuestion,
        originalQuestion: question,
        validationErrors: null,
        formMode: "edit",
        openedAt: now,
        immediateStart: false
      }
    });
  }

  public closeAddEditModal() {
    this.freezer.get().set({
      modalState: {
        editedQuestion: null,
        originalQuestion: null,
        validationErrors: null,
        formMode: "none",
        openedAt: new Date(),
        immediateStart: false
      }
    });
  }

  public onChange(question: Partial<Question>) {
    this.freezer.get().modalState.editedQuestion?.set(question);
  }

  public onChangeImmediateStart(immediateStart: boolean) {
    this.freezer.get().modalState.set({ immediateStart });
  }

  public hasUnsavedChanges(): boolean {
    const {
      modalState
    } = this.freezer.get().toJS();

    return !(_.isEqual(modalState.editedQuestion, modalState.originalQuestion));
  }

  public async saveQuestion(companyId: number | undefined) {
    const modalState = this.freezer.get().modalState.toJS();
    var question = this.freezer.get().modalState.editedQuestion?.toJS();
    var original = this.freezer.get().modalState.originalQuestion?.toJS();

    if (question?.questionType === "Upcharge" && question.commodityQuestions) {
      question.commodityQuestions = [];
    }
    
    const errors = await validateSchema(QuestionValidationSchema, modalState, {
      abortEarly: false,
      context: {
        openAt: modalState.openedAt
      }
    });

    this.freezer.get().modalState.set({ validationErrors: errors });

    if (!companyId || errors) {
      return;
    }

    if (modalState.formMode == "edit") {
      managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        ajaxStateProperty: "questionSaveResults",
        onExecute: (apiOptions, param, options) => {
          const factory = QuestionApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return factory.apiV1QuestionsUpdatePost(param);
        },
        params: {
          body: {
            updatedQuestion: question,
            previousQuestion: original
          } as QuestionUpdateVM,
        },
        onOk: (data) => {
          this.closeAddEditModal();
          this.fetchQuestions(companyId, true);
        },
        onError: (err, errorMessage) => {
          ErrorService.pushErrorMessage("Failed to update question.");
        }
      });
    }
    else if (modalState.formMode == "add") {
      managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        ajaxStateProperty: "questionSaveResults",
        onExecute: (apiOptions, param, options) => {
          const factory = QuestionApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return factory.apiV1QuestionsAddPut(param);
        },
        params: {
          body: question,
        },
        onOk: (data) => {
          this.closeAddEditModal();
          this.fetchQuestions(companyId, true);
        },
        onError: (err, errorMessage) => {
          ErrorService.pushErrorMessage("Failed to add question.");
        }
      });
    }
  }
}

export const QuestionService = new QuestionFreezerService();
export type IQuestionServiceInjectedProps = ReturnType<QuestionFreezerService["getPropsForInjection"]>;