import {
  React,
  bind
} from "$Imports/Imports";

import {
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Edit
} from "$Imports/MaterialUIIcons";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  Zone
} from "$Generated/api";

interface IActionMenuState {
}

interface IActionMenuProps {
  zone: Zone;
  onMenuItemClick?: (zone: Zone) => void;
}

export class ActionMenu extends React.PureComponent<IActionMenuProps, IActionMenuState> {

  @bind
  private _onEditTask(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick(this.props.zone);
    }
  }

  render() {
    return (
      <UserAccessControl roles={["zone-setup:edit"]}>
        <IconButton
          size="small"
          onClick={this._onEditTask}
        >
          <Edit />
        </IconButton>
      </UserAccessControl>
    );
  }
}
