export function openCustomerQuotePreview(quoteId: number): Window | null {
  const newWindow = window.open(`/customerQuote/${quoteId}`, "_blank", "width=1000,height=800");
  if (newWindow) {
    newWindow.opener = null;
  }

  return newWindow;
}

export function printQuote(quoteId: number) {
  const newWindow = window.open(`/printQuote/${quoteId}`, "Print Quote", 'width=1000,height=800');
  if (newWindow) {
    newWindow.opener = null;
  }
}

export function openTabForUrl(url: string) {
  window.open(url, "_blank")?.focus();
}

export function openTabOnParent(quoteId: number) {
  window.parent.open(`/customerQuoteConvert/${quoteId}`, "_blank")?.focus();
}