import {
  FreezerService,
  _,
  bind,
  moment,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional,
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  QuoteApiFactory,
  QuoteSearchCriteriaStatusesEnum,
  QuoteSearchCriteria,
  QuoteSearchCriteriaDateTypeEnum,
  SimplifiedQuoteSearchResult,
  QuoteQuoteTypeEnum,
  QuoteSearchCriteriaQuoteTypeEnum
} from "$Generated/api";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

const QuoteSearchValidationSchema: SchemaOf<NullableOptional<QuoteSearchCriteria>> = yup.object({
  userId: yup.string().notRequired().allowEmpty(),
  companyId: yup.number().notRequired(),
  dateType: yup.mixed<QuoteSearchCriteriaDateTypeEnum>().notRequired(),
  startDate: yup.date()
    .typeError("Invalid date")
    .notRequired(),
  endDate: yup.date()
    .typeError("Invalid date")
    .notRequired()
    .when("startDate", {
      is: (value?: Date) => !!value,
      then: yup.date()
        .test("endDate", "${message}", (value: Date | undefined, testContext: any) => {
          const startDate = testContext.parent.startDate;

          if (moment(value).isBefore(moment(startDate, 'day'))) {
            return testContext.createError({ message: "To must be after From" });
          }

          return true;
        }),
      otherwise: yup.date().notRequired()
    }),
  quoteDate: yup.date().nullable().typeError("Invalid date").notRequired(),
  statuses: yup.mixed<QuoteSearchCriteriaStatusesEnum[]>().notRequired(),
  quoteOrFreightNumber: yup.string().notRequired().allowEmpty(),
  customerId: yup.number().notRequired(),
  customerName: yup.string()
    .notRequired()
    .allowEmpty()
    .min(3, "At least 3 characters are required."),
  sortColumn: yup.string().notRequired().allowEmpty(),
  sortAscending: yup.boolean().notRequired(),
  startIndex: yup.number().notRequired(),
  pageSize: yup.number().notRequired(),
  quoteType: yup.mixed<QuoteSearchCriteriaQuoteTypeEnum>().notRequired()
});

const QuoteSearchDateRangeRequiredValidationSchema = QuoteSearchValidationSchema.concat(
  yup.object({
    startDate: yup.date()
      .typeError("Invalid date")
      .required("From is required"),
    endDate: yup.date()
      .typeError("Invalid date")
      .required("To is required")
      .test("endDate", "${message}", (value: Date | undefined, testContext: any) => {
        const startDate = testContext.parent.startDate;

        if (moment(value).isBefore(moment(startDate, 'day'))) {
          return testContext.createError({ message: "To must be after From" });
        }

        return true;
      }),
  }));

interface IQuoteState {
  quotesFetchResults: IAjaxState<SimplifiedQuoteSearchResult>;
  quickQuotesFetchResults: IAjaxState<SimplifiedQuoteSearchResult>;
  searchCriteria: QuoteSearchCriteria;
  searchValidationErrors: ValidationError | null;
}

const InjectedPropName = "quoteService";

const EMPTY_QUOTE_SEARCH: QuoteSearchCriteria = {
  quoteOrFreightNumber: undefined,
  dateType: "QuoteDate",
  statuses: []
};

const initialState = {
  quotesFetchResults: managedAjaxUtil.createInitialState(),
  quickQuotesFetchResults: managedAjaxUtil.createInitialState(),
  searchCriteria: {
    ...EMPTY_QUOTE_SEARCH,
    sortColumn: "quoteDate",
    sortAscending: false,
    startIndex: 0,
    pageSize: 10,
    quoteType: "Full"
  },
  searchValidationErrors: null
} as IQuoteState;

class QuoteFreezerService extends FreezerService<IQuoteState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  @bind
  public async fetchQuotes(companyId: number | undefined, forceUpdate: boolean = false, quoteType: QuoteQuoteTypeEnum | undefined) {
    const {
      quotesFetchResults,
      quickQuotesFetchResults,
      searchCriteria
    } = this.freezer.get();

    if (((quotesFetchResults.hasFetched || quickQuotesFetchResults.hasFetched) && !forceUpdate) || !companyId) {
      return;
    }

    const validationErrors = await this.validateSearchModel(searchCriteria.toJS(), true);
    this.freezer.get().set({
      searchValidationErrors: validationErrors
    });

    if (validationErrors) {
      return;
    }

    const ajaxStateProperty = quoteType === "Quick" ? "quickQuotesFetchResults" : "quotesFetchResults";

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: ajaxStateProperty,
      params: {
        body: {
          ...searchCriteria.set({quoteType: quoteType}).toJS(),
          companyId
        }
      },
      onExecute: (apiOptions, params, options) => {
        const factory = QuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1QuotesQuotesPost(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch quotes.");
      }
    });
  }

  @bind
  public onSearchModelChanged(searchModel: Partial<QuoteSearchCriteria>) {
    this.freezer.get().searchCriteria.set(searchModel);
  }

  @bind
  public onResetSearchModel() {
    this.onSearchModelChanged({
      ...EMPTY_QUOTE_SEARCH,
      userId: SharedSecurityContext.getUserId(),
      startDate: moment().subtract(90, 'days').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
      sortAscending: false,
      sortColumn: "quoteDate"
    });
  }

  @bind
  public async validateSearchModel(searchModel: QuoteSearchCriteria, requireDateRange: boolean = false): Promise<ValidationError | null> {
    const schema = requireDateRange
      ? QuoteSearchDateRangeRequiredValidationSchema
      : QuoteSearchValidationSchema;

    return await validateSchema(schema, searchModel, {
      abortEarly: false
    });
  }
}

export const QuoteService = new QuoteFreezerService();
export type IQuoteServiceInjectedProps = ReturnType<QuoteFreezerService["getPropsForInjection"]>;