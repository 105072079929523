import {
  React,
  _,
  bind,
  moment
} from "$Imports/Imports";

import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  TextFieldProps
} from "$Imports/MaterialUIComponents";

import {
  TIME_FORMAT,
  TIME_MERIDIAN_FORMAT
} from "$Shared/utilities/formatUtil";

interface ITimeInputFieldBaseProps {
  onTimeChange: (newValue?: string) => void;
  value?: string | undefined;
}
interface ITimeInputFieldState {
  time: string;
  meridiem: string;
}

type ITimeInputFieldProps = ITimeInputFieldBaseProps & TextFieldProps;

export class TimeInputField extends React.PureComponent<ITimeInputFieldProps, ITimeInputFieldState> {
  state: ITimeInputFieldState = {
    time: "",
    meridiem: "AM"
  };
  componentDidMount(): void {
    if (this.props.value) {
      this.setTimeState(this.props.value);
    }
  }
  componentDidUpdate(prevProps: Readonly<ITimeInputFieldProps>, prevState: Readonly<ITimeInputFieldState>, snapshot?: any): void {
    if (this.props.value !== prevProps.value) {
      this.setTimeState(this.props.value, prevState.meridiem);
    }
  }

  private setTimeState(time: string | undefined, meridiem: string = "AM"): void {
    const formattedTime = time?.length === 5 && moment(this.props.value, TIME_FORMAT).isValid() ? moment(time, TIME_FORMAT).format("hh:mm A").toString().split(" ") : [time];
    
    this.setState({
      time: formattedTime[0] ?? "",
      meridiem: formattedTime[1] ?? meridiem
    });
  }

  @bind
  private _onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const rawValue = event.target.value;
    var setValue = this.props.value; // default action: don't change anything

    // allowed characters: empty string, digits, space, colon
    if (rawValue === "" || /^[0-9 :]+$/.test(rawValue)) {
      setValue = rawValue;

      // replace space with 0
      setValue = setValue.replace(" ", "0");

      // 1 digit followed by colon or 2 digits exceeding 12 hour format --> prepend leading 0
      if (/^[0-9]:$/.test(setValue) || (setValue.length >= 2 && parseInt(setValue.substring(0, 2)) > 12)) {
        setValue = "0" + setValue;
      }

      // 3 digits --> insert colon
      if (/^[0-9]{3}$/.test(setValue)) {
        setValue = setValue.substring(0, 2) + ":" + setValue.substring(2);
      }
      
      // longer than 5 --> truncate
      if (setValue.length > 5) {
        setValue = setValue.substring(0, 5);
      }
    }
    
    if (setValue?.length === 5 && moment(setValue, TIME_FORMAT).isValid()) {
      setValue = moment(setValue + " " + this.state.meridiem, TIME_MERIDIAN_FORMAT).format(TIME_FORMAT);
    }
    
    this.props.onTimeChange(setValue);
  }

  @bind
  private _onAmPmChange(event: SelectChangeEvent): void {
    const meridiem = event.target.value;
    this.setState({ meridiem: meridiem });
    
    if (this.state.time?.length === 5 && moment(this.state.time, TIME_FORMAT).isValid()) {
      const time = moment(this.state.time + " " + meridiem, TIME_MERIDIAN_FORMAT).format(TIME_FORMAT);
      this.props.onTimeChange(time);
    }
  }
  render() {
    const {
      time,
      meridiem
    } = this.state;

    const {
      value,
      onTimeChange,
      ref,
      ...passthroughProps
    } = this.props;

    return (
      <>
        <TextField
          style={{width: '4.5rem', marginRight: '0.5rem'}}
          {...passthroughProps}
          onChange={this._onChange}
          value={time ?? ""}
        />
        <FormControl style={{verticalAlign: 'bottom', marginTop: "auto"}}>
          <Select
            disabled={passthroughProps.disabled}
            value={meridiem}
            onChange={this._onAmPmChange}
          >
            <MenuItem value={"AM"}>AM</MenuItem>
            <MenuItem value={"PM"}>PM</MenuItem>
          </Select>
        </FormControl>
      </>
    );
  }
}
