import {
  FreezerService,
  bind,
  managedAjaxUtil,
  IAjaxState
} from "$Imports/Imports";

import {
  Opportunity,
  OpportunityApiFactory,
  OpportunitySearchCriteria
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

interface IOpportunityState {
  fetchResult: IAjaxState<Opportunity[]>;
  saveResult: IAjaxState<Opportunity>;
}

const injectedPropName = "opportunityService";

export const OPPORTUNITY_STATUSES: string[] = ["Discovery", "Interested", "Converted", "Abandoned"];

// prevent controlled/uncontrolled input warnings
export const EMPTY_OPPORTUNITY: Opportunity = {
  id: 0,
  description: "",
  leadDetails: "",
  status: "Discovery"
};

export const EMPTY_OPPORTUNITY_SEARCH: OpportunitySearchCriteria = {
  status: ["Discovery", "Interested"]
};

const initialState: IOpportunityState = {
  fetchResult: managedAjaxUtil.createInitialState(),
  saveResult: managedAjaxUtil.createInitialState()
};

class _OpportunityFreezerService extends FreezerService<IOpportunityState, typeof injectedPropName> {
  constructor() {
    super(initialState, injectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  @bind
  public fetchOpportunities(search: OpportunitySearchCriteria) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "fetchResult",
      params: {
        body: search
      },
      onExecute: (apiOptions, params, options) => {
        const factory = OpportunityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.searchOpportunities(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch sales opportunities.");
      }
    })
  }

  @bind
  public createOpportunity(model: Opportunity) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "saveResult",
      params: {
        body: model
      },
      onExecute: (apiOptions, params, options) => {
        const factory = OpportunityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.createOpportunity(params);
      },
      onOk: () => {
        ErrorService.pushErrorMessage("Sales opportunity created.", "successful");
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to create sales opportunity.");
      }
    });
  }

  @bind
  public updateOpportunity(id: number, model: Opportunity) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "saveResult",
      params: {
        id: id,
        body: model
      },
      onExecute: (apiOptions, params, options) => {
        const factory = OpportunityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.updateOpportunity(params);
      },
      onOk: () => {
        ErrorService.pushErrorMessage("Sales opportunity updated.", "successful");
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to update sales opportunity.");
      }
    });
  }
}

export const OpportunityService = new _OpportunityFreezerService();
export type IOpportunityServiceInjectedProps = ReturnType<_OpportunityFreezerService["getPropsForInjection"]>;
