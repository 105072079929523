import {
  React,
  bind,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  DateRangePicker
} from "$Components/common/DateRangePicker";

import {
  BookedSalesParametersVM
} from "$Generated/api";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "$Imports/MaterialUIComponents";

import {
  KPIDatePreset
} from "$State/SalesRepHomeFreezerService";

import {
  BookedSalesMetricsFilterValidationSchema
} from "$State/SalesRepHomeFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

interface IOwnState {
  errors: ValidationError | null;
}

interface IOwnProps {
  startDate: Date | null;
  endDate: Date | null;
  preset: KPIDatePreset;
  onChange: (startDate: Date | null, endDate: Date | null, preset: KPIDatePreset) => void;
}

const styles: {
  container: string;
  dateRange: string;
} = require("./KPIDateForm.scss")

class _KPIDateForm extends React.PureComponent<IOwnProps, IOwnState> {
  state: IOwnState = {
    errors: null
  };

  @bind
  private async _onDateRangeChange(start: Date | null, end: Date | null, newPreset: KPIDatePreset): Promise<void> {
    if (start && end) {
      const errors = await validateSchema(BookedSalesMetricsFilterValidationSchema, { startDate: start, endDate: end });

      this.setState({ errors: errors });
      if (errors) {
        return;
      }
    }

    this.props.onChange(start, end, newPreset);
  }

  @bind
  private _onDatePickerChange(start: Date | null, end: Date | null): void {
    if (!start || !end) {
      return;
    }

    this._onDateRangeChange(moment(start).startOf("day").toDate(), moment(end).endOf("day").toDate(), "Custom");
  }

  @bind
  private _onSelectPreset(newPreset: KPIDatePreset): void {
    let start: Date | null = null;
    let end: Date | null = null;

    switch (newPreset) {
      case 'Custom':
        break;
      case 'Year':
        start = moment().startOf('year').toDate();
        end = moment().endOf('year').toDate();
        break;
      case 'Month':
        start = moment().startOf('month').toDate();
        end = moment().endOf('month').toDate();
        break;
      case 'Week':
        start = moment().startOf('week').toDate();
        end = moment().endOf('week').toDate();
        break;
      case 'Today':
      default:
        start = moment().startOf('day').toDate();
        end = moment().endOf('day').toDate();
        break;
    }

    this._onDateRangeChange(start, end, newPreset);
  }

  render() {
    const {
      startDate,
      endDate,
      preset
    } = this.props;

    const {
      errors
    } = this.state;

    const validationParser = new ValidationErrorParser<BookedSalesParametersVM>(errors);
    const startError = validationParser.validationMessage("startDate");
    const endError = validationParser.validationMessage("endDate");

    return (
      <div className={styles.container}>
        <FormControl style={{ flex: "0 0 6rem" }}>
          <InputLabel></InputLabel>
          <Select
            value={preset}
            onChange={(event) => this._onSelectPreset(event.target.value as KPIDatePreset)}
          >
            <MenuItem value="Today">Today</MenuItem>
            <MenuItem value="Week">Week</MenuItem>
            <MenuItem value="Month">Month</MenuItem>
            <MenuItem value="Year">Year</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
          </Select>
        </FormControl>

        <div className={styles.dateRange}>
          <DateRangePicker
            startDate={startDate ?? undefined}
            startError={startError}
            endDate={endDate ?? undefined}
            endError={endError}
            disabled={preset !== "Custom"}
            onChange={this._onDatePickerChange}
          />
        </div>
      </div>
    );
  }
}

export const KPIDateForm = _KPIDateForm;