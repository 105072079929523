import {
  bind,
  React,
  moment,
  _
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from "$Imports/MaterialUIComponents";

import {
  Employee,
  Reminder,
  ReminderTypeEnum
} from "$Generated/api";

import {
  IReminderServiceInjectedProps,
  ReminderService,
  REMINDER_TYPES
} from "$State/ReminderFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  DateTimeInputField,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  ErrorService
} from "$State/ErrorFreezerService";

import {
  ReminderValidationSchema
} from "$State/ReminderValidationSchema";

interface IOwnState {
  reminder: Reminder;
  errors: ValidationError | null;
  disableSave: boolean;
}

interface IOwnProps {
  isOpen: boolean;
  reminder: Reminder;
  onClose: (value?: Reminder) => void;
  canSelectType: boolean;
  salesReps: Employee[];
}

type OwnProps = IOwnProps
  & IReminderServiceInjectedProps;

const initialReminder: Reminder = {
  type: "General",
  text: "",
  isActive: true,
  isComplete: false
};

class _ReminderEditModal extends React.PureComponent<OwnProps, IOwnState> {
  state: IOwnState = {
    reminder: {
      ...initialReminder
    },
    errors: null,
    disableSave: false
  };
  
  @bind
  private _onDueDateTimeChange(value: Date | null | undefined): void {
    this.setState((prev) => ({
      reminder: {
        ...prev.reminder,
        dueDate: moment(value).toDate()
      }
    }));
  }

  @bind
  private _onAssignedToChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState((prev) => ({
      reminder: {
        ...prev.reminder,
        createdById: Number(e.target.value)
      }
    }));
  }

  @bind
  private _onTypeChange(event: SelectChangeEvent): void {
    this.setState((prev) => ({
      reminder: {
        ...prev.reminder,
        type: event.target.value as ReminderTypeEnum
      }
    }));
  }

  @bind
  private _onTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState((prev) => ({
      reminder: {
        ...prev.reminder,
        text: event.target.value
      }
    }));
  }

  @bind
  private async _onClose(shouldSave: boolean): Promise<void> {
    if(!shouldSave) {
      this.props.onClose();
      return;
    }

    const errors = await validateSchema(ReminderValidationSchema, this.state.reminder);
    this.setState({ errors: errors });
    
    if (errors) {
      return;
    }

    this.setState({
      disableSave: true
    });

    const { reminder } = this.state;

    if (this.state.reminder.id === 0){
      await ReminderService.createReminder(reminder);
    } else {
      await ReminderService.updateReminder(reminder.id ?? 0, reminder);
    }

    this.props.onClose(reminder);
  }

  @bind
  private removeInactiveSalesRep() {
    this.setState((prev) => ({
      reminder: {
        ...prev.reminder,
        createdById: undefined,
        createdByName: undefined
      }
    }));
    ErrorService.pushErrorMessage("Inactive sales representative removed.");
  }

  componentDidUpdate(prev: IOwnProps) {
    if(prev.isOpen !== this.props.isOpen) {
      this.setState({
        disableSave: false
      })
    }

    if (this.props.reminder !== prev.reminder) {
      let createdById = this.props.reminder.createdById;

      if (!createdById) {
        const userId = SharedSecurityContext.getUserId();
        createdById = _.find(this.props.salesReps, (u) => u.userId === userId)?.id;
      }

      this.setState({
        reminder: {
          ...initialReminder,
          ...this.props.reminder,
          createdById: createdById
        },
        errors: null
      });
    }
  }

  render() {
    const {
      canSelectType,
      isOpen,
      salesReps
    } = this.props;

    const {
      reminder: {
        id,
        dueDate,
        type,
        text,
        customer,
        createdById
      },
      errors,
      disableSave
    } = this.state;

    const validationParser = new ValidationErrorParser<Reminder>(errors);
    const isAdd = !id;

    const salesRepInactive = salesReps && createdById ? !_.find(salesReps, (s) => s.id === createdById) : false;
    if (salesRepInactive) {
      this.removeInactiveSalesRep();
    }

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          {isAdd ? "Set" : "Edit"} Reminder
        </DialogTitle>

        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <DateTimeInputField
                value={dueDate ?? null}
                onChange={this._onDueDateTimeChange}
                dateLabel="Due Date"
                timeLabel="Due Time *"
                error={!validationParser.isValid("dueDate")}
                helperText={validationParser.validationMessage("dueDate")}
                required
                disablePast
              />
            </Grid>
            <UserAccessControl roles={["reminder:manage-others"]}>
              <Grid item>
                <FormControl 
                  style={{minWidth: "5rem"}} 
                  error={!validationParser.isValidDeep("createdById")}
                  required
                >
                  <InputLabel shrink>Assigned To</InputLabel>
                  <Select
                    value={createdById}
                    name="customerUserId"
                    onChange={(event) => this._onAssignedToChange(event as React.ChangeEvent<HTMLInputElement>)}
                  >
                    {salesReps.map((s, idx) => (
                      <MenuItem value={s.id} key={idx}>{`${s.firstName} ${s.lastName}`}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{validationParser.validationMessage("createdById")}</FormHelperText>
                </FormControl>
              </Grid>
            </UserAccessControl>
            <Grid item>
              <FormControl>
                <InputLabel>Type</InputLabel>
                <Select
                  value={type}
                  onChange={this._onTypeChange}
                  error={!validationParser.isValid("type")}
                  disabled={!canSelectType}
                  required
                >
                  {REMINDER_TYPES.map((x, idx) => (
                    <MenuItem value={x} key={idx}>
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {customer?.linkId && (
              <Grid item>
                <TextField
                  label="Customer"
                  value={customer.linkName}
                  disabled
                />
              </Grid>
            )}
            <Grid item>
              <TextField
                label="Description"
                value={text}
                onChange={this._onTextChange}
                maxRows={3}
                inputProps={{
                  maxLength: 300
                }}
                error={!validationParser.isValid("text")}
                helperText={validationParser.validationMessage("text")}
                multiline
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            disabled={disableSave}
            onClick={() => this._onClose(true)}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={() => this._onClose(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const ReminderEditModal = ReminderService.inject(_ReminderEditModal);