import {
  React,
  _,
  bind,
  moment
} from "$Imports/Imports";

import {
  Button,
  CardActions,
  Icon,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  FormHelperText
} from "$Imports/MaterialUIComponents";

import {
  Check,
  HourglassEmptyOutlined,
  Search
} from "$Imports/MaterialUIIcons";

import {
  QuoteSearchCriteria,
  QuoteSearchCriteriaStatusesEnum,
  SimplifiedCustomersQuote,
  QuoteStatusEnum,
  Reminder,
  QuoteSearchCriteriaDateTypeEnum
} from "$Generated/api";

import {
  DisplayFormattedDatetime,
  DisplayFormattedNumber,
  AdvanceTextField,
  QuoteLink,
  QuoteStatus,
  CardLinedHeader,
  UserAccessControl,
  CreateReminderButton,
  CompanyIcon,
  DateRangePicker
} from "$Imports/CommonComponents";

import {
  SecurityContext
} from "$Utilities/Security/ApplicationSecuritySettings";

import {
  CustomerDetailService,
  ICustomerDetailServiceInjectedProps
} from "$State/CustomerDetailFreezerService";

import {
  EMPTY_REMINDER
} from "$State/ReminderFreezerService";

import {
  NavigationService
} from "$State/NavigationFreezerService";

import {
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  CURRENCY_FORMAT,
  DATE_WITH_TIME_MERIDIAN_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  getDeliveryDateColumn
} from "$Utilities/quoteColConstants";

interface ICustomersQuotesBaseProps {
  quotes: SimplifiedCustomersQuote[];
  customerId?: number;
  onCreateReminder: (seed: Reminder) => void;
}

type ICustomersQuotesProps = ICustomersQuotesBaseProps & ICustomerDetailServiceInjectedProps;

const styles: {
  maxRecordsMessage: string;
  icon: string;
  reviewed: string;
} = require("./CustomersQuotes.scss");

class _CustomersQuotes extends React.Component<ICustomersQuotesProps> {

  private readonly gridColumns: GridColDef[] = [
    {
      headerName: "",
      field: "company",
      disableColumnMenu: true,
      width: 28,
      renderCell: (params: GridRenderCellParams<any, SimplifiedCustomersQuote>) => {
        return <CompanyIcon companyKey={params.row.quoteKind === "CustomerQuote" ? "CP" + (params.row.company?.companyKey ?? "") : params.row.company?.companyKey ?? ""} />
      }
    },
    {
      headerName: "Quote #",
      field: "quoteNumber",
      renderCell: (params: GridRenderCellParams<any, SimplifiedCustomersQuote>) => {
        let quoteNumber = "";
        if (params.row.quoteKind === "Quote") {
          quoteNumber = `Q${params.row.quoteNumber}`;
        } else {
          quoteNumber = `EQ${params.row.quoteNumber}`;
        }

        if (_.includes(SecurityContext.getCompanies(null), params.row.company?.companyKey)) {
          return (<QuoteLink quoteId={params.row.id} quoteNumber={quoteNumber} isCustomerQuote={params.row.quoteKind === "CustomerQuote"} />);
        } else {
          return quoteNumber;
        }
      },
      flex: 1,
      disableColumnMenu: true,
    },
    {
      headerName: "Quote Date",
      field: "quoteDate",
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any, SimplifiedCustomersQuote>) =>
        <DisplayFormattedDatetime
          value={params.row.quoteDate}
          formatString={DATE_WITH_TIME_MERIDIAN_FORMAT}
          showTimeZone
        />
    },
    {
      headerName: "Negotiated Rate",
      field: "negotiatedRate",
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any, SimplifiedCustomersQuote>) =>
        <DisplayFormattedNumber
          value={params.row.negotiatedRate}
          formatString={CURRENCY_FORMAT}
        />
    },
    getDeliveryDateColumn("Delivery Date", true),
    {
      headerName: "Origin Location",
      field: "originLocation",
      flex: 2,
      disableColumnMenu: true
    },
    {
      headerName: "Destination Location",
      field: "destinationLocation",
      flex: 2,
      disableColumnMenu: true
    },
    {
      headerName: "Expiration Date",
      field: "expirationDate",
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any, SimplifiedCustomersQuote>) => 
        <DisplayFormattedDatetime
          value={params.row.expirationDate}
          formatString={DATE_WITH_TIME_MERIDIAN_FORMAT}
          showTimeZone
        />
    },
    {
      headerName: "Status",
      field: "quoteStatus",
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any, SimplifiedCustomersQuote>) => {
        let dateDiff = moment(params.row.expirationDate).diff(new Date(), "hour");

        return <>
          <QuoteStatus quoteStatus={params.row.quoteStatus as QuoteStatusEnum} /> 
          {params.row.isReviewed &&
            <Icon title="Reviewed" className={styles.icon}>
              <Check className={styles.reviewed} />
            </Icon>
          }
          {(params.row.quoteStatus === "Pending" || params.row.quoteStatus === "PendingNeedsCustomers" && dateDiff <= 72) &&
            <Icon>
              <HourglassEmptyOutlined style={{ color: "rgb(243, 17, 17)" }} />
            </Icon>
          }
        </>
      }
    }
  ];

  @bind
  private _onQuoteFreightNumChange(e: React.ChangeEvent<{ name: string; value: string; }>) {
    this._onQuoteSearchCriteriaChange({ quoteOrFreightNumber: e.target.value });
  }
  
  @bind
  private _onDateTypeChange(event: SelectChangeEvent) {
    this._onQuoteSearchCriteriaChange({ dateType: event.target.value ? event.target.value as QuoteSearchCriteriaDateTypeEnum : undefined });
  }

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    this.props.customerDetailService.updateQuoteSearchCriteria({
      startDate: start ? moment(start).startOf('day').toDate() : undefined,
      endDate: end ? moment(end).endOf('day').toDate() : undefined
    });
  }

  @bind
  private _onQuoteStatusChange(e: SelectChangeEvent<QuoteSearchCriteriaStatusesEnum[]>) {
    // selecting "Pending" causes "PendingNeedsCustomers" to be added to the search criteria serverside
    this.props.customerDetailService.updateQuoteSearchCriteria({
      statuses: e.target.value as QuoteSearchCriteriaStatusesEnum[]
    });

    this.props.customerDetailService.fetchCustomerQuotes(this.props.customerId, true);
  }

  @bind
  private _searchFieldOnKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      this._onSearchClick();
    }
  }

  @bind
  private _onQuoteSearchCriteriaChange(criteria: Partial<QuoteSearchCriteria>) {
    this.props.customerDetailService.updateQuoteSearchCriteria(criteria);
  }

  @bind
  private _onSearchClick() {
    this.props.customerDetailService.fetchCustomerQuotes(this.props.customerId, true);
  }

  @bind
  private async _newQuoteClick() {
    const {
      customerDetailFetchResults
    } = this.props.customerDetailService.getState();

    if (customerDetailFetchResults.data?.isCaller) {
      QuoteEntryService.setSelectedCustomer(customerDetailFetchResults.data);
      NavigationService.navigateTo("/salesportal");
    }
  }

  render() {
    const {
      quotes,
      onCreateReminder
    } = this.props;

    const {
      customerDetailFetchResults,
      quoteSearchCriteria: searchCriteria,
      quoteSearchCriteriaValidationErrors,
      quoteFetchResults
    } = this.props.customerDetailService.getState();

    const validationsParser = new ValidationErrorParser<QuoteSearchCriteria>(quoteSearchCriteriaValidationErrors);

    const statusArray: QuoteSearchCriteriaStatusesEnum[] = [
      "Accepted",
      "ApprovalNeeded",
      "Canceled",
      "Declined",
      "Expired",
      "InProgress",
      "Pending",
      "Requested"
    ];

    const gridHeightCap = quotes.length === 0 ? 39 + 36 : quotes.length >= 10 ? 399 : 39 + (quotes.length * 36);

    return (
      <CardLinedHeader
        titleText="Quotes"
        titleComponents={(
          <div style={{ marginBottom: "5px" }}>
            {customerDetailFetchResults.data?.isCaller &&
              <UserAccessControl roles={["quote:create"]}>
                <Button onClick={this._newQuoteClick}>New Quote</Button>
              </UserAccessControl>
            }
            <UserAccessControl roles={["reminder:create"]}>
              <CreateReminderButton
                seed={{
                  ...EMPTY_REMINDER,
                  type: "Quote"
                }}
                onClick={onCreateReminder}
              />
            </UserAccessControl>
          </div>
        )}
      >
        <CardActions
          style={{ marginTop: "-0.5rem" }}
        >
          <AdvanceTextField
            label="Quote or FB#"
            onChange={this._onQuoteFreightNumChange}
            onKeyPress={this._searchFieldOnKeyPress}
            value={searchCriteria?.quoteOrFreightNumber ?? ""}
            error={!validationsParser.isValid("quoteOrFreightNumber")}
            helperText={validationsParser.validationMessage("quoteOrFreightNumber")}
            style={{ flex: "1 0 10rem" }}
          />
          <FormControl style={{ flex: "0 0 10rem" }} error={!validationsParser.isValid("dateType")}>
            <InputLabel>Date Type</InputLabel>
            <Select
              value={searchCriteria?.dateType ?? ""}
              onChange={this._onDateTypeChange}
              
            >
              <MenuItem value={""}>&nbsp;</MenuItem>
              <MenuItem value={"QuoteDate"}>Quote Date</MenuItem>
              <MenuItem value={"DeliveryDate"}>Delivery Date</MenuItem>
              <MenuItem value={"ExpirationDate"}>Expiration Date</MenuItem>
            </Select>
            <FormHelperText>{validationsParser.validationMessage("dateType")}</FormHelperText>
          </FormControl>
          <div style={{ display: "inline-flex", flex: "0 0 17rem", gap: "0.5rem" }}>
            <DateRangePicker
              startDate={searchCriteria?.startDate}
              startError={validationsParser.validationMessage("startDate")}
              endDate={searchCriteria?.endDate}
              endError={validationsParser.validationMessage("endDate")}
              onChange={this._onDateRangeChange}
              disabled={searchCriteria?.dateType === undefined}
            />
          </div>
          <FormControl style={{ width: "250px" }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={searchCriteria?.statuses}
              onChange={this._onQuoteStatusChange}
              multiple
              renderValue={(selected) => {
                if (selected.length === 6) {
                  return <i>All</i>;
                }
                else {
                  return _.map(selected as QuoteSearchCriteriaStatusesEnum[], (s, idx) => {
                    return (
                      <span key={idx}>
                        <QuoteStatus quoteStatus={s} />
                        <>{idx !== (selected as QuoteSearchCriteriaStatusesEnum[]).length - 1 ? ", " : ""}</>
                      </span>
                    )
                  })
                }
              }}
            >
              {
                statusArray.map((status) => {
                  return (
                    <MenuItem key={status} value={status}>
                      <Checkbox checked={_.findIndex(searchCriteria?.statuses, s => s === status) > -1} />
                      <ListItemText primary={<QuoteStatus quoteStatus={status} />} />
                    </MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
          <div style={{ alignSelf: "end" }}>
            <Button
              className="iconAsButton"
              color="primary"
              disabled={quoteFetchResults.isFetching}
              onClick={this._onSearchClick}
            >
              <Search />
            </Button>
          </div>
        </CardActions>
        <div style={{ height: `${gridHeightCap}px` }}>
          <DataGridPro
            rows={quotes}
            columns={this.gridColumns}
            density="compact"
            hideFooter
            rowBuffer={10}
            disableSelectionOnClick
            initialState={{
              sorting: {
                sortModel: [{ field: "expirationDate", sort: "asc" }]
              }
            }}
            localeText={{ noRowsLabel: "No quotes" }}
          />
        </div>
        {(quoteFetchResults.data?.numberOfRecords ?? 0) < (quoteFetchResults.data?.totalRecords ?? 0) &&
          <div className={styles.maxRecordsMessage}>
            {quoteFetchResults.data?.totalRecords} results found, {quoteFetchResults.data?.numberOfRecords} shown - please refine your search.
          </div>
        }
      </CardLinedHeader>
    )
  }
}

export const CustomersQuotes = CustomerDetailService.inject(_CustomersQuotes);