import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  SelectedCompanyConsumer
} from "$Providers/index";

import {
  SalesPortalView
} from "./SalesPortalView";

interface ISalesPortalViewPageBaseProps {

}

export class SalesPortalViewPage extends React.Component<ISalesPortalViewPageBaseProps> {
  render() {
    return (
      <SelectedCompanyConsumer>
        {(companyContext) => { 
          return (
            <SalesPortalView companyContext={companyContext} />
          );
        }}
      </SelectedCompanyConsumer>
    );
  }
}
