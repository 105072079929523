import {
  _,
  React,
  bind,
  moment,
  NumberFormat,
  NumberFormatValues
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  AdvanceTextField,
  AjaxActionIndicator,
  PhoneNumberInputField
} from "$Imports/CommonComponents";

import {
  Customer,
  Region,
  Employee,
  CustomerSource,
  ProspectIndustryTypeEnum,
  ProspectDecisionMakingTypeEnum,
  ProspectPricingTypeEnum,
  ProspectFreightHandlingEnum,
  ProspectPercentageToCloseEnum
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
  KeyboardDatePicker,
  TextField,
  TextFieldProps,
  Grid,
} from "$Imports/MaterialUIComponents";

import {
  ICustomerServiceInjectedProps,
  CustomerService,
  CustomerProspectValidationSchema
} from "$State/CustomerFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import { getTrimmedZipPostalCode } from "$Shared/utilities/helpers";

import {
  decisionMakingTypeTextMap,
  freightHandlingTextMap,
  industryTypeTextMap,
  percentageToCloseTextMap,
  pricingTypeTextMap
} from "$Utilities/enumUtil";

const styles: {
  inputContainer: string;
  textfield: string;
  flexRow: string;
  phoneFlexRow: string;
} = require("./AddEditProspectModal.scss");

interface IAddEditProspectModalProps {
  isOpen: boolean;
  isFetching: boolean;
  customer: Customer;
  salesReps: Employee[];
  regions: Region[];
  customerSources: CustomerSource[];
  onSave: (value?: Customer) => void;
  onCancel: () => void;
}

type AddEditProspectModalProps = IAddEditProspectModalProps
& ICustomerServiceInjectedProps;

interface IAddEditProspectModalState {
  customer: Customer;
  validationErrors: ValidationError | null;
}

export class _addEditCustomerModal extends React.Component<AddEditProspectModalProps, IAddEditProspectModalState> {
  state: IAddEditProspectModalState = {
    customer: this.props.customer,
    validationErrors: null
  }

  componentDidUpdate(prev: AddEditProspectModalProps) {
    if (this.props.customer !== prev.customer) {
      this.setState({
        customer: this.props.customer,
        validationErrors: null
      });
    }
  }

  @bind
  private _onCancel() {
    this.setState({
      validationErrors: null
    });
    this.props.onCancel();
  }

  @bind
  private async _onValidateCustomer(): Promise<boolean> {
    const schema = CustomerProspectValidationSchema;
    const customerErrors = await validateSchema(schema, this.state.customer, {
      context: {
        currentTime: moment(new Date()).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
      },
      abortEarly: false
    });

    this.setState({ validationErrors: customerErrors });
    if (customerErrors) {
      return true;
    }

    return false;
  }

  @bind
  private async _onSave() {
    const { customer } = this.state;
    var hasErrors = await this._onValidateCustomer();

    if (hasErrors) {
      return;
    }

    this.props.onSave(customer);
  }

  @bind
  private _onTextInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        [event.target.name]: event.target.value !== "" ? event.target.value : undefined
      }
    }));
  }

  @bind
  private _onPhoneNumberChange(newValue: string | undefined) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        phoneNumber: newValue
      }
    }));
  }

  @bind
  private _onSelectChange(event: SelectChangeEvent): void {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        [event.target.name]: event.target.value !== "" ? Number(event.target.value) : undefined
      }
    }));
  }

  @bind
  private _onRegionChange(event: SelectChangeEvent): void {
    const regionId = Number(event.target.value);
    const region = _.find(this.props.regions, r => r.id === regionId);
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        regionId: regionId,
        region: region
      }
    }));
  }

  @bind
  private _onTextInputChangeProspect(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        prospect: {
          ...prev.customer.prospect,
          [event.target.name]: event.target.value !== "" ? event.target.value : undefined
        }
      }
    }));
  }

  @bind
  private _onNumberInputChangeProspect(name: string, value: NumberFormatValues): void {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        prospect: {
          ...prev.customer.prospect,
          [name]: value?.floatValue
        }
      }
    }));
  }

  @bind
  private _onChangeIndustryType(event: SelectChangeEvent<ProspectIndustryTypeEnum >) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        prospect: {
          ...prev.customer.prospect,
          industryType: event.target.value !== "" ? event.target.value as ProspectIndustryTypeEnum : undefined
        }
      }
    }));
  }

  @bind
  private _onChangeDecisionMakingType(event: SelectChangeEvent<ProspectDecisionMakingTypeEnum >) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        prospect: {
          ...prev.customer.prospect,
          decisionMakingType: event.target.value !== "" ? event.target.value as ProspectDecisionMakingTypeEnum : undefined
        }
      }
    }));
  }

  @bind
  private _onChangePricingType(event: SelectChangeEvent<ProspectPricingTypeEnum >) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        prospect: {
          ...prev.customer.prospect,
          pricingType: event.target.value !== "" ? event.target.value as ProspectPricingTypeEnum : undefined
        }
      }
    }));
  }

  @bind
  private _onChangeFreightHandling(event: SelectChangeEvent<ProspectFreightHandlingEnum >) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        prospect: {
          ...prev.customer.prospect,
          freightHandling: event.target.value !== "" ? event.target.value as ProspectFreightHandlingEnum : undefined
        }
      }
    }));
  }

  @bind
  private _onChangePercentageToClose(event: SelectChangeEvent<ProspectPercentageToCloseEnum >) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        prospect: {
          ...prev.customer.prospect,
          percentageToClose: event.target.value !== "" ? event.target.value as ProspectPercentageToCloseEnum : undefined
        }
      }
    }));
  }

  @bind
  private _onDateChange(date: Date | null) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        prospect: {
          ...prev.customer.prospect,
          startDate: date ?? undefined
        }
      }
    }));
  }

  @bind
  private _onZipPostalCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = getTrimmedZipPostalCode(e.target?.value);
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        [e.target.name]: value
      }
    }));
  }

  @bind
  private _onWebsiteBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (e.target.value && !e.target.value.startsWith("http://") && !e.target.value.startsWith("https://")) {
      this.setState((prev) => ({
        customer: {
          ...prev.customer,
          website: `https://${e.target.value}`
        }
      }));
    }
  }

  render() {
    const {
      isOpen,
      isFetching,
      regions,
      salesReps,
      customerSources
    } = this.props;

    const {
      customer
    } = this.state;

    const validationsParser = new ValidationErrorParser<Customer>(this.state.validationErrors);

    return (
      <>
        <Dialog
          open={isOpen}
          maxWidth="md"
        >
          <DialogTitle>{`${customer.id ? "Edit" : "Add"} Prospect`}</DialogTitle>
          <AjaxActionIndicator
            showProgress={isFetching}
          />
          <DialogContent className={styles.inputContainer}>
            <Grid container direction="row" wrap="wrap" spacing={2}>
              <Grid item xs={8}>
                <AdvanceTextField
                  label="Prospect Name"
                  name="customerName"
                  required
                  onChange={this._onTextInputChange}
                  value={customer.customerName ?? ""}
                  error={!validationsParser.isValid("customerName")}
                  helperText={validationsParser.validationMessage("customerName")}
                  inputProps={{ maxLength: 40 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth error={!validationsParser.isValid("salesAgentId")}>
                  <InputLabel shrink>Sales Representative *</InputLabel>
                  <Select
                    value={customer.salesAgentId ?? ""}
                    name="salesAgentId"
                    onChange={(event) => this._onSelectChange(event as React.ChangeEvent<HTMLInputElement>)}
                    displayEmpty
                    required
                  >
                    {salesReps.map((s, idx) => (
                      <MenuItem value={s.id} key={idx}>{`${s.firstName} ${s.lastName}`}</MenuItem>
                    ))}
                  </Select>
                  {!validationsParser.isValid("salesAgentId") && <FormHelperText>{validationsParser.validationMessage("salesAgentId")}</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={8}>
                <AdvanceTextField
                  label="Address"
                  name="address1"
                  required
                  onChange={this._onTextInputChange}
                  value={customer.address1 ?? ""}
                  error={!validationsParser.isValid("address1")}
                  helperText={validationsParser.validationMessage("address1")}
                  inputProps={{ maxLength: 40 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <AdvanceTextField
                  label="Address line 2"
                  name="address2"
                  onChange={this._onTextInputChange}
                  value={customer.address2 ?? ""}
                  error={!validationsParser.isValid("address2")}
                  helperText={validationsParser.validationMessage("address2")}
                  inputProps={{ maxLength: 40 }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <AdvanceTextField
                  label="City"
                  name="city"
                  required
                  onChange={this._onTextInputChange}
                  value={customer.city ?? ""}
                  error={!validationsParser.isValid("city")}
                  helperText={validationsParser.validationMessage("city")}
                  inputProps={{ maxLength: 30 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth error={!validationsParser.isValid("regionId")}>
                  <InputLabel>Region *</InputLabel>
                  <Select
                    value={customer.regionId ?? ""}
                    name="regionId"
                    required
                    onChange={(event) => this._onRegionChange(event as React.ChangeEvent<HTMLInputElement>)}
                  >
                    {_.map(regions, (r, idx) =>
                      <MenuItem value={r.id} key={idx}>{r.regionName}</MenuItem>
                    )}
                  </Select>
                  {!validationsParser.isValid("regionId") && <FormHelperText>{validationsParser.validationMessage("regionId")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <AdvanceTextField
                  label="Postal Code"
                  name="zipPostalCode"
                  required
                  onChange={this._onZipPostalCodeChange}
                  value={customer.zipPostalCode ?? ""}
                  error={!validationsParser.isValid("zipPostalCode")}
                  helperText={validationsParser.validationMessage("zipPostalCode")}
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <PhoneNumberInputField
                  label="Phone Number"
                  name="phoneNumber"
                  required
                  onPhoneNumberChange={this._onPhoneNumberChange}
                  captureExt
                  value={customer.phoneNumber ?? ""}
                  error={!validationsParser.isValid("phoneNumber")}
                  helperText={validationsParser.validationMessage("phoneNumber")}
                  className={styles.phoneFlexRow}
                />
              </Grid>
              <Grid item xs={8}>
                <AdvanceTextField
                  label="Website"
                  name="website"
                  onBlur={this._onWebsiteBlur}
                  onChange={this._onTextInputChange}
                  value={customer.website ?? ""}
                  error={!validationsParser.isValid("website")}
                  helperText={validationsParser.validationMessage("website")}
                  inputProps={{ maxLength: 150 }}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth error={!validationsParser.isValid("customerSourceId")}>
                  <InputLabel>Lead Source *</InputLabel>
                  <Select
                    value={customer.customerSourceId ?? ""}
                    name="customerSourceId"
                    onChange={(event) => this._onSelectChange(event as React.ChangeEvent<HTMLInputElement>)}
                    required
                  >
                    {customerSources.map((c, idx) => (
                      <MenuItem value={c.id} key={idx}>{c.name}</MenuItem>
                    ))}
                  </Select>
                  {!validationsParser.isValid("customerSourceId") && <FormHelperText>{validationsParser.validationMessage("customerSourceId")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <AdvanceTextField
                  label="Current Provider"
                  name="currentProvider"
                  onChange={this._onTextInputChangeProspect}
                  value={customer.prospect?.currentProvider ?? ""}
                  error={!validationsParser.isValidDeep("prospect.currentProvider")}
                  helperText={validationsParser.validationMessageDeep("prospect.currentProvider")}
                  inputProps={{ maxLength: 100 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth error={!validationsParser.isValidDeep("prospect.industryType")}>
                  <InputLabel>Industry Type *</InputLabel>
                  <Select
                    name="industryType"
                    value={customer.prospect?.industryType ?? ""}
                    onChange={(event) => this._onChangeIndustryType(event)}
                    required
                  >
                    <MenuItem value="">&nbsp;</MenuItem>
                    <MenuItem value="Aerospace">{industryTypeTextMap["Aerospace"]}</MenuItem>
                    <MenuItem value="Equipment">{industryTypeTextMap["Equipment"]}</MenuItem>
                    <MenuItem value="GeneratorsTransformers">{industryTypeTextMap["GeneratorsTransformers"]}</MenuItem>
                    <MenuItem value="MachineTools">{industryTypeTextMap["MachineTools"]}</MenuItem>
                    <MenuItem value="Steel">{industryTypeTextMap["Steel"]}</MenuItem>
                    <MenuItem value="Other">{industryTypeTextMap["Other"]}</MenuItem>
                  </Select>
                  {!validationsParser.isValidDeep("prospect.industryType") && <FormHelperText>{validationsParser.validationMessageDeep("prospect.industryType")}</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth error={!validationsParser.isValidDeep("prospect.decisionMakingType")}>
                  <InputLabel>Decision-Making Type</InputLabel>
                  <Select
                    name="decisionMakingType"
                    value={customer.prospect?.decisionMakingType ?? ""}
                    onChange={(event) => this._onChangeDecisionMakingType(event)}
                    required
                  >
                    <MenuItem value="">&nbsp;</MenuItem>
                    <MenuItem value="Auctions">{decisionMakingTypeTextMap["Auctions"]}</MenuItem>
                    <MenuItem value="DealerNetwork">{decisionMakingTypeTextMap["DealerNetwork"]}</MenuItem>
                    <MenuItem value="Local">{decisionMakingTypeTextMap["Local"]}</MenuItem>
                    <MenuItem value="NewConstruction">{decisionMakingTypeTextMap["NewConstruction"]}</MenuItem>
                    <MenuItem value="OceanCarriers">{decisionMakingTypeTextMap["OceanCarriers"]}</MenuItem>
                    <MenuItem value="WholesalerDistributor">{decisionMakingTypeTextMap["WholesalerDistributor"]}</MenuItem>
                  </Select>
                  {!validationsParser.isValidDeep("prospect.decisionMakingType") && <FormHelperText>{validationsParser.validationMessageDeep("prospect.decisionMakingType")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth error={!validationsParser.isValidDeep("prospect.pricingType")}>
                  <InputLabel>Pricing Type</InputLabel>
                  <Select
                    name="pricingType"
                    value={customer.prospect?.pricingType ?? ""}
                    onChange={(event) => this._onChangePricingType(event)}
                    required
                  >
                    <MenuItem value="">&nbsp;</MenuItem>
                    <MenuItem value="Contracted">Contracted</MenuItem>
                    <MenuItem value="SpotQuote">{pricingTypeTextMap["SpotQuote"]}</MenuItem>
                  </Select>
                  {!validationsParser.isValidDeep("prospect.pricingType") && <FormHelperText>{validationsParser.validationMessageDeep("prospect.pricingType")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth error={!validationsParser.isValidDeep("prospect.freightHandling")}>
                  <InputLabel>Freight Handling</InputLabel>
                  <Select
                    name="freightHandling"
                    value={customer.prospect?.freightHandling ?? ""}
                    onChange={(event) => this._onChangeFreightHandling(event)}
                    required
                  >
                    <MenuItem value="">&nbsp;</MenuItem>
                    <MenuItem value="NonStackable">{freightHandlingTextMap["NonStackable"]}</MenuItem>
                    <MenuItem value="Stackable">Stackable</MenuItem>
                  </Select>
                  {!validationsParser.isValidDeep("prospect.freightHandling") && <FormHelperText>{validationsParser.validationMessageDeep("prospect.freightHandling")}</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth error={!validationsParser.isValidDeep("prospect.percentageToClose")}>
                  <InputLabel>Percent To Close *</InputLabel>
                  <Select
                    value={customer.prospect?.percentageToClose ?? ""}
                    onChange={(event) => this._onChangePercentageToClose(event)}
                    required
                  >
                    <MenuItem value="">&nbsp;</MenuItem>
                    <MenuItem value="NoContactWithDM">{percentageToCloseTextMap["NoContactWithDM"]}</MenuItem>
                    <MenuItem value="ContactedDMInterestExpressed">{percentageToCloseTextMap["ContactedDMInterestExpressed"]}</MenuItem>
                    <MenuItem value="Quoted">{percentageToCloseTextMap["Quoted"]}</MenuItem>
                    <MenuItem value="VerbalAgreement">{percentageToCloseTextMap["VerbalAgreement"]}</MenuItem>
                    <MenuItem value="OrderPlaced">{percentageToCloseTextMap["OrderPlaced"]}</MenuItem>
                  </Select>
                  {!validationsParser.isValidDeep("prospect.percentageToClose") && <FormHelperText>{validationsParser.validationMessageDeep("prospect.percentageToClose")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <KeyboardDatePicker
                  value={customer.prospect?.startDate ?? null}
                  onChange={(date: Date | null, keyboard?: string | undefined) => this._onDateChange(date)}
                  inputFormat="MM/DD/YYYY"
                  disablePast={true}
                  renderInput={(params: TextFieldProps) => <TextField 
                    {...params}
                    label="Start Date"
                    error={!validationsParser.isValidDeep("prospect.startDate")} 
                    helperText={validationsParser.validationMessageDeep("prospect.startDate")}
                    fullWidth
                  />}
                />
              </Grid>
              <Grid item xs={4}>
              </Grid>

              <Grid item xs={4}>
                <NumberFormat              
                  name="estAvgMonthlyFreightBills"
                  allowNegative={false}
                  thousandSeparator={true}
                  decimalScale={0}
                  label="Est. Avg. Monthly Freight Bills"
                  value={customer.prospect?.estAvgMonthlyFreightBills ?? ""}
                  customInput={TextField}
                  onValueChange={(value) => this._onNumberInputChangeProspect("estAvgMonthlyFreightBills", value)}
                  error={!validationsParser.isValidDeep("prospect.estAvgMonthlyFreightBills")}
                  helperText={validationsParser.validationMessageDeep("prospect.estAvgMonthlyFreightBills")}
                  inputProps={{ maxLength: 12 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <NumberFormat
                  name="estAvgRevenuePerFreightBill"
                  allowNegative={false}
                  thousandSeparator={true}
                  decimalScale={0}
                  prefix="$"
                  label="Est. Avg. Revenue per FB"
                  value={customer.prospect?.estAvgRevenuePerFreightBill ?? ""}
                  customInput={TextField}
                  onValueChange={(value) => this._onNumberInputChangeProspect("estAvgRevenuePerFreightBill", value)}
                  error={!validationsParser.isValidDeep("prospect.estAvgRevenuePerFreightBill")}
                  helperText={validationsParser.validationMessageDeep("prospect.estAvgRevenuePerFreightBill")}
                  inputProps={{ maxLength: 13 }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this._onCancel}
              disabled={isFetching}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={this._onSave}
              disabled={isFetching}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export const AddEditProspectModal = CustomerService.inject(
  _addEditCustomerModal
);