import {
  FreezerService,
  bind,
  managedAjaxUtil,
  IAjaxState,
  _
} from "$Imports/Imports";

import { 
  ApplicationSetting,
  ApplicationSettingsApiFactory 
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import { 
  ErrorService 
} from "./ErrorFreezerService";

interface IApplicationSettingsServiceState {
  applicationSettingsFetchResults: IAjaxState<Array<ApplicationSetting>>;
  updateApplicationSettingResults: IAjaxState<ApplicationSetting>;
  isDefaultMarketModalOpen: boolean;
  editDefaultMarketType: ApplicationSetting | undefined;
  isFilteredSitesModalOpen: boolean;
  editFilteredSites: ApplicationSetting | undefined;
  isBooleanSettingModalOpen: boolean;
  editBooleanSetting: ApplicationSetting | undefined;
  isLWLRestrictionsSettingModalOpen: boolean;
  editLWLRestrictions: ApplicationSetting | undefined;
  isLocationFeeSettingModalOpen: boolean;
  editLocationFeeSetting: ApplicationSetting | undefined;
}

const InjectedPropName = "ApplicationSettingsService";

const initialState = {
  applicationSettingsFetchResults: managedAjaxUtil.createInitialState(),
  updateApplicationSettingResults: managedAjaxUtil.createInitialState(),
  isDefaultMarketModalOpen: false,
  editDefaultMarketType: {},
  isBooleanSettingModalOpen: false,
  isLocationFeeSettingModalOpen: false
} as IApplicationSettingsServiceState;

class ApplicationSettingsFreezerService extends FreezerService<IApplicationSettingsServiceState, typeof InjectedPropName> {

  constructor() {
    super(initialState, InjectedPropName);
    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public openApplicationSettingEditModal(applicationSetting: ApplicationSetting) {
    if (applicationSetting.settingsKey === "DefaultMarket") {
      this.freezer.get().set({
        isDefaultMarketModalOpen: true,
        editDefaultMarketType: _.cloneDeep(applicationSetting)
      });
    }
    else if (applicationSetting.settingsKey === "FilteredSites") {
      this.freezer.get().set({
        isFilteredSitesModalOpen: true,
        editFilteredSites: _.cloneDeep(applicationSetting)
      });
    }
    else if (applicationSetting.settingsKey === "SendFuelSurchargeCharge" || applicationSetting.settingsKey === "DebugDownloadEnabled") {
      this.freezer.get().set({
        isBooleanSettingModalOpen: true,
        editBooleanSetting: _.cloneDeep(applicationSetting)
      });
    }
    else if (applicationSetting.settingsKey === "LocationsWithLengthRestrictions") {
      this.freezer.get().set({
        isLWLRestrictionsSettingModalOpen: true,
        editLWLRestrictions: _.cloneDeep(applicationSetting)
      });
    }
    else if (applicationSetting.settingsKey === "MilitaryBaseLocationFee" || applicationSetting.settingsKey === "TWICCardLocationFee") {
      this.freezer.get().set({
        isLocationFeeSettingModalOpen: true,
        editLocationFeeSetting: _.cloneDeep(applicationSetting)
      });
    }
  }

  public updateMarketTypeSetting(marketTypeSetting: Partial<ApplicationSetting>) {
    this.freezer.get().editDefaultMarketType?.set(marketTypeSetting);
  }

  public updateFilteredSitesSetting(filteredSites: Partial<ApplicationSetting>) {
    this.freezer.get().editFilteredSites?.set(filteredSites);
  }

  public updateBooleanSetting(applicationSetting: Partial<ApplicationSetting>) {
    this.freezer.get().editBooleanSetting?.set(applicationSetting);
  }

  public updateLWLRestrictionsSetting(applicationSetting: Partial<ApplicationSetting>) {
    this.freezer.get().editLWLRestrictions?.set(applicationSetting);
  }

  public updateLocationFeeSetting(applicationSetting: Partial<ApplicationSetting>) {
    this.freezer.get().editLocationFeeSetting?.set(applicationSetting);
  }

  public async saveMarketTypeSetting() {
    const updatedMarketTypeSetting = this.freezer.get().editDefaultMarketType?.toJS();
    
    await this.updateApplicationSetting(updatedMarketTypeSetting);

    this.freezer.get().set({
      isDefaultMarketModalOpen: false,
      editDefaultMarketType: undefined
    });
  }

  public async saveFilteredSitesSetting() {
    const updatedFilteredSites = this.freezer.get().editFilteredSites?.toJS();
    
    await this.updateApplicationSetting(updatedFilteredSites);

    this.freezer.get().set({
      isFilteredSitesModalOpen: false,
      editFilteredSites: undefined
    });
  }

  public async saveBooleanSetting() {
    const updatedSetting = this.freezer.get().editBooleanSetting?.toJS();

    await this.updateApplicationSetting(updatedSetting);

    this.freezer.get().set({
      isBooleanSettingModalOpen: false,
      editBooleanSetting: undefined
    });
  }

  public async saveLWLRestrictionsSetting() {
    const updatedLWLRestrictions = this.freezer.get().editLWLRestrictions?.toJS();
    
    await this.updateApplicationSetting(updatedLWLRestrictions);

    this.freezer.get().set({
      isLWLRestrictionsSettingModalOpen: false,
      editLWLRestrictions: undefined
    });
  }

  public async saveLocationFeeSetting() {
    const updatedSetting = this.freezer.get().editLocationFeeSetting?.toJS();

    await this.updateAutoMaticUpcharge(updatedSetting);

    this.freezer.get().set({
      isLocationFeeSettingModalOpen: false,
      editLocationFeeSetting: undefined
    });
  }

  public getDefaultMarketSetting(): string {
    const applicationSettings = this.freezer.get().applicationSettingsFetchResults.data;
    const defaultMarketSetting = _.find(applicationSettings, a => a.settingsKey === "DefaultMarket");

    return defaultMarketSetting?.settingsValue ?? "None";
  }

  public getDebugDownloadEnabledSetting(companyId: number | undefined): boolean {
    if (!companyId) {
      return false;
    }
    const applicationSettings = this.freezer.get().applicationSettingsFetchResults.data;
    const debugDownloadEnabledSetting = _.find(applicationSettings, a => a.settingsKey === "DebugDownloadEnabled" && a.companyId === companyId);

    return debugDownloadEnabledSetting?.settingsValue === "True";
  }

  public getLocationsWithLengthRestrictionsSetting(): string[] {
    const applicationSettings = this.freezer.get().applicationSettingsFetchResults.data;
    const locationsWithLengthRestrictionsSetting = _.find(applicationSettings, a => a.settingsKey === "LocationsWithLengthRestrictions");

    return locationsWithLengthRestrictionsSetting?.settingsValue?.split(',') ?? [];
  }

  public async fetchApplicationSettings(forceUpdate: boolean = false) {

    const applicationSettingsFetchResults = this.freezer.get().applicationSettingsFetchResults;

    if (applicationSettingsFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "applicationSettingsFetchResults",
      params: {

      },
      onExecute: (apiOptions, params, options) => {
        const factory = ApplicationSettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1ApplicationSettingsGet();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage('Unable to fetch application settings');
      }
    });
  }

  public async updateApplicationSetting(applicationSetting?: ApplicationSetting) {

    if (!applicationSetting) {
      return; 
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "updateApplicationSettingResults",
      params: {
        body: applicationSetting
      },
      onExecute: (apiOptions, params, options) => {
        const factory = ApplicationSettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1ApplicationSettingsUpsertAppSettingPost(params);
      },
      onOk: (data: ApplicationSetting) => {
        this.fetchApplicationSettings(true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage('Unable to update application setting');
      }
    });
  }

  public async updateAutoMaticUpcharge(applicationSetting?: ApplicationSetting) {

    if (!applicationSetting) {
      return; 
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "updateApplicationSettingResults",
      params: {
        body: applicationSetting
      },
      onExecute: (apiOptions, params, options) => {
        const factory = ApplicationSettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1ApplicationSettingsUpsertAutoUpchargePost(params);
      },
      onOk: (data: ApplicationSetting) => {
        this.fetchApplicationSettings(true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage('Unable to update application setting');
      }
    });
  }
}

export const ApplicationSettingsService = new ApplicationSettingsFreezerService();
export type IApplicationSettingsServiceInjectedProps = ReturnType<ApplicationSettingsFreezerService["getPropsForInjection"]>;