import {
  React,
  Helpers,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  AdvanceTextField,
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  Commodity
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControlLabel,
  Switch
} from "$Imports/MaterialUIComponents";

import {
  formModeType
} from "$State/CommodityFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

const styles: {
  container: string;
  textfield: string;
  labelLeft: string;
} = require("./AddEditCommodity.scss");

interface IAddEditCommodityProps {
  formMode: formModeType;
  commodity: Commodity | null;
  validationErrors: ValidationError | null;
  isFetching?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  onTaskChange?: (taskChange: Partial<Commodity>) => void;
}

export class AddEditCommodity extends React.PureComponent<IAddEditCommodityProps> {

  static defaultProps: Partial<IAddEditCommodityProps> = {
    isFetching: false,
  };

  @bind
  private _onSave() {
    if (this.props.onSave) {
      this.props.onSave();
    }
  }

  @bind
  private _onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  private _onCommodityChanged(commodity: Partial<Commodity>) {
    if (this.props.onTaskChange) {
      this.props.onTaskChange(commodity);
    }
  }

  @bind
  private _onNameChanged(newValue: string) {
    this._onCommodityChanged({ commodityName: newValue });
  }

  @bind
  private _onActiveChanged(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this._onCommodityChanged({ isActive: checked });
  }

  @bind
  private _onStackableChanged(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this._onCommodityChanged({ isStackable: checked });
  }

  @bind
  private _onSideBySideChanged(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this._onCommodityChanged({ isSideBySide: checked });
  }

  @bind
  private _onShortNameChanged(shortName: string) {
    this._onCommodityChanged({ commodityShortName: shortName });
  }

  @bind
  private _onTmCommodityIdChanged(tmCommodityId: string) {
    this._onCommodityChanged({ tmCommodityId: tmCommodityId});
  }

  render() {
    const {
      formMode,
      commodity,
      validationErrors,
      isFetching
    } = this.props;

    const validationsParser = new ValidationErrorParser<Commodity>(validationErrors);

    return !Helpers.isNullOrUndefined(commodity) ? (
      <Dialog
        open={formMode === "add" || formMode === "edit"}
        fullWidth={true}
        maxWidth="xs"
      >
        <AjaxActionIndicator
          showProgress={isFetching}
        />
        <DialogTitle>
          {formMode === "add" ? "Add Commodity" : `Edit Commodity: ${commodity?.commodityName}`}
        </DialogTitle>
        <DialogContent
          className={styles.container}
        >
          <div style={{whiteSpace: "nowrap"}}>
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={commodity?.isActive}
                    onChange={this._onActiveChanged}
                  />
                )
              }
              label="Active"
              className={styles.labelLeft}
            />
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={commodity?.isStackable}
                    onChange={this._onStackableChanged}
                  />
                )
              }
              className={styles.labelLeft}
              label="Stackable"
            />
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={commodity?.isSideBySide}
                    onChange={this._onSideBySideChanged}
                  />
                )
              }
              className={styles.labelLeft}
              label="Side By Side"
            />
          </div>
          <AdvanceTextField
            label="Name"
            onDebouncedChange={this._onNameChanged}
            value={commodity?.commodityName}
            className={styles.textfield}
            error={!validationsParser.isValid("commodityName")}
            helperText={validationsParser.validationMessage("commodityName")}
          />
          <AdvanceTextField
            label="TruckMate Commodity Id"
            onDebouncedChange={this._onTmCommodityIdChanged}
            value={commodity?.tmCommodityId}
            className={styles.textfield}
            error={!validationsParser.isValid("tmCommodityId")}
            helperText={validationsParser.validationMessage("tmCommodityId")}
          />
          <AdvanceTextField
            label="Short Name"
            onDebouncedChange={this._onShortNameChanged}
            value={commodity?.commodityShortName}
            className={styles.textfield}
            error={!validationsParser.isValid("commodityShortName")}
            helperText={validationsParser.validationMessage("commodityShortName")}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._onCancel}
            disabled={isFetching}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this._onSave}
            disabled={isFetching}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    ) : null;
  }
}
