import {
  bind,
  React,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  DateRangePicker,
  SearchControlsContainer
} from "$Imports/CommonComponents";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "$Imports/MaterialUIComponents";

import {
  Region,
  SalesRepCustomerSearchCriteria
} from "$Generated/api";

import {
  DEFAULT_CUSTOMER_SEARCH,
  ISalesRepHomeServiceInjectedProps,
  SalesRepHomeService,
  CustomerSearchValidationSchema
} from "$State/SalesRepHomeFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import { ValidationErrorParser } from "$Utilities/ValidationErrorParser";

import { getTrimmedZipPostalCode } from "$Shared/utilities/helpers";

interface IOwnProps {
  regions: Region[];
  onSubmit: (search: SalesRepCustomerSearchCriteria) => void;
}

type OwnProps = IOwnProps
  & ISalesRepHomeServiceInjectedProps;

interface IOwnState {
  searchCriteria: SalesRepCustomerSearchCriteria;
  errors: ValidationError | null;
}

const styles: {
  container: string;
} = require("./SalesRepSearchForm.scss");

class _MyCustomersSearchForm extends React.PureComponent<OwnProps, IOwnState> {
  state: IOwnState = {
    searchCriteria: { ...DEFAULT_CUSTOMER_SEARCH },
    errors: null
  };

  @bind
  private _onCustomerNameChange(text: string): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        customerName: text ?? ""
      }
    }));
  }

  @bind
  private _onStateChange(state: string): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        regionAbbreviation: state ?? ""
      }
    }));
  }

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        latestQuoteStartDate: start ? moment(start).startOf('day').toDate() : undefined,
        latestQuoteEndDate: end ? moment(end).endOf('day').toDate() : undefined
      }
    }));
  }

  @bind
  private _onZipChange(zip: string): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        zipPostalCode: getTrimmedZipPostalCode(zip)
      }
    }));
  }

  @bind
  private async _onSubmit(): Promise<void> {
    const { searchCriteria } = this.state;
    const errors = await validateSchema(CustomerSearchValidationSchema, searchCriteria, {
      abortEarly: false
    });
    this.setState({ errors: errors });
    if(errors) {
      return;
    }

    this.props.onSubmit(searchCriteria);
  }

  @bind
  private _onClear(): void {
    this.setState({
      searchCriteria: { ...DEFAULT_CUSTOMER_SEARCH }
    });

    this.props.onSubmit(DEFAULT_CUSTOMER_SEARCH);

    this.setState({ errors: null });
  }

  componentDidMount() {
    const { customerSearchCriteria } = this.props.salesRepHomeService.getState();

    this.setState({
      searchCriteria: { ...customerSearchCriteria }
    });
  }

  render() {
    const {
      searchCriteria,
      errors
    } = this.state;

    const stateData = this.props.regions ?? [];

    const validationParser = new ValidationErrorParser<SalesRepCustomerSearchCriteria>(errors);
    const customerError = validationParser.validationMessage("customerName");
    const startError = validationParser.validationMessage("latestQuoteStartDate");
    const endError = validationParser.validationMessage("latestQuoteEndDate");

    return (
      <SearchControlsContainer
        className={styles.container}
        onSubmit={this._onSubmit}
        onClear={this._onClear}
      >
        <TextField
          style={{ flex: "1" }}
          label="Code / Customer Name"
          error={!!customerError}
          helperText={customerError}
          value={searchCriteria.customerName}
          onChange={(event) => this._onCustomerNameChange(event.target.value)}
        />

        <FormControl style={{ flex: "0 0 4rem" }}>
          <InputLabel>State</InputLabel>
          <Select
            value={searchCriteria.regionAbbreviation}
            onChange={(event) => this._onStateChange(event.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            {stateData.map((x, idx) => (
              <MenuItem value={x.regionAbbreviation} key={idx}>
                {x.regionAbbreviation}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          style={{ flex: "0 0 6rem" }}
          label="Zip Code"
          value={searchCriteria.zipPostalCode}
          onChange={(event) => this._onZipChange(event.target.value)}
        />

        <div style={{ display: "inline-flex", flex: "0 0 17rem", gap: "0.5rem" }}>
          <DateRangePicker
            startDate={searchCriteria.latestQuoteStartDate}
            startError={startError}
            endDate={searchCriteria.latestQuoteEndDate}
            endError={endError}
            onChange={this._onDateRangeChange}
          />
        </div>
      </SearchControlsContainer>
    );
  }
}

export const MyCustomersSearchForm = SalesRepHomeService.inject(_MyCustomersSearchForm);
