
import {
  React,
  bind,
  _,
  moment
} from "$Imports/Imports";

import {
  CustomerQuoteService,
  ICustomerQuoteServiceInjectedProps
} from "$State/CustomerQuotesFreezerService";

import {
  StateService,
  IStateServiceInjectedProps
} from "$State/RegionFreezerService";

import {
  CommodityService,
  ICommodityServiceInjectedProps
} from "$State/CommodityFreezerService";

import {
  CustomerService,
  ICustomerServiceInjectedProps
} from "$State/CustomerFreezerService";

import {
  CustomerDetailService,
  ICustomerDetailServiceInjectedProps
} from "$State/CustomerDetailFreezerService";

import {
  Card,
  Button
} from "$Imports/MaterialUIComponents";

import {
  DisplayFormattedDatetime,
  DisplayFormattedNumber,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  RateEngineResult
} from "$Utilities/rateEngineUtil";

import {
  CustomerQuoteFreightView
} from "./CustomerQuoteFreightView";

import {
  openTabOnParent
} from "$Utilities/windowUtil";

import { getCompanyLogo } from "$Utilities/companyUtil";

import {
  TIME_MERIDIAN_FORMAT,
  TIME_WITH_SECONDS_FORMAT
} from "$Shared/utilities/formatUtil";

import { getFormattedZipPostalCode } from "$Shared/utilities/helpers";

const styles: {
  logoContainer: string,
  actionButton: string,
  printQuoteCard: string,
  logo: string,
  aboveSum: string,
  sum: string,
  customerCard: string,
  customerInfo: string,
  cardTitle: string,
  expired: string
} = require("./CustomerQuoteViewPage.scss");

interface ICustomerQuoteViewPageBaseProps {
  quoteId?: string;
}

type ICustomerQuoteViewProps = ICustomerQuoteViewPageBaseProps
  & ICustomerQuoteServiceInjectedProps
  & IStateServiceInjectedProps
  & ICustomerServiceInjectedProps
  & ICommodityServiceInjectedProps
  & ICustomerDetailServiceInjectedProps;

class _CustomerQuoteViewPage extends React.Component<ICustomerQuoteViewProps> {

  async componentDidMount() {
    await this.props.customerQuotesService.fetchQuoteById(this.props.quoteId);
    this.props.regionService.fetchStates();

    const {quoteFetchResults} = this.props.customerQuotesService.getState();
    if (quoteFetchResults?.data && quoteFetchResults.data.customerId) {
      this.props.customerDetailService.fetchCustomerContacts(quoteFetchResults?.data.customerId);
    }
  }

  @bind
  private printQuote() {
    window.print();
  }

  @bind
  private async createQuoteForCustomer() {
    const { customerQuote } = this.props.customerQuotesService.getState();
    const { customerFetchResults } = this.props.customerService.getState();
    const customer = customerFetchResults.data;
    if (!customer) {
      return;
    }

    const isActiveCustomer = await this.props.customerService.customerIsActiveInTruckMate(customer.tmcustomerId);
    if (isActiveCustomer) {
      if (customerQuote.id) {
        openTabOnParent(customerQuote.id)
        window.close();
      }
    }
  }

  render() {
    const {
      quoteFetchResults,
      customerQuote,
      freightTotalLengthFetchResults
    } = this.props.customerQuotesService.getState();
    const freightTotalLength = freightTotalLengthFetchResults.data ?? 0;

    const {
      regionFetchResults
    } = this.props.regionService.getState();
    const regions = regionFetchResults?.data ?? [];

    const {
      customerFetchResults
    } = this.props.customerService.getState();
    const customer = customerFetchResults.data;

    let rateEngineResults: RateEngineResult = {}
    if (customerQuote?.rateEngineResults) {
      rateEngineResults = JSON.parse(customerQuote?.rateEngineResults);
    }

    const {
      contactsFetchResults
    } = this.props.customerDetailService.getState();
    const contacts = contactsFetchResults.data ?? [];
    const selectedContact = _.find(contacts, c => c.id == customerQuote.customerContactId);

    let lineHaulRate: number | undefined = undefined;
    if (rateEngineResults.highBaseRate && rateEngineResults.fuelSurcharge) {
      lineHaulRate = rateEngineResults?.highBaseRate - rateEngineResults?.fuelSurcharge + (rateEngineResults?.equipmentTypeRate ?? 0);
    }

    const isFetching = quoteFetchResults.isFetching
      || freightTotalLengthFetchResults.isFetching
      || contactsFetchResults.isFetching;

    return (
      <Card className={styles.printQuoteCard}>
        <div className={styles.logoContainer}>
          <img title="Kaiser Logo" src={getCompanyLogo(customerQuote.companyId)} className={styles.logo} />
          <div>
            {(customerQuote.quoteStatus === "Requested" && !customerQuote.fullQuote?.id) &&
              <UserAccessControl roles={["customer-quote:convert"]}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.createQuoteForCustomer}
                  className={styles.actionButton}
                  disabled={isFetching}
                >
                  Convert To Full Quote
                </Button>
              </UserAccessControl>
            }
            <Button
              color="primary"
              variant="contained"
              onClick={this.printQuote}
              className={styles.actionButton}
              disabled={isFetching}
            >
              Print
            </Button>
          </div>
        </div>
        <Card className={styles.customerCard}>
          <div className={styles.cardTitle}>
            Customer Contact Info
          </div>
          {customer && <div className={styles.customerInfo}>
            <div style={{ marginRight: "4.688rem" }}>
              <div><b>{customer.customerName ?? ""}</b></div>
              <div><b>{customer.address1 ?? ""}</b></div>
              <div><b>{customer.address2 ?? ""}</b></div>
              <div><b>{`${customer.city ?? ""}, ${(customer.region?.regionAbbreviation ?? _.find(regions, r => r.id === customer.regionId)?.regionAbbreviation) ?? ""}, ${getFormattedZipPostalCode(customer) ?? ""}`}</b></div>
            </div>
            <div>
            <div>Contact Name: <b>{selectedContact ? `${selectedContact.firstName} ${selectedContact.lastName}` : "None"}</b></div>
            <div>Phone Number: <b>{selectedContact?.phoneNumber ? selectedContact.phoneNumber : ""}</b></div>
            <div>Email: <b>{selectedContact?.emailAddress ? selectedContact.emailAddress : ""}</b></div>
          </div>
          </div>}
        </Card>
        <table>
          <tbody>
            <tr>
              <td>Quote Number</td>
              <td>EQ{customerQuote?.quoteNumber}</td>
            </tr>
            <tr>
              <td>Quote Status</td>
              <td className={customerQuote?.quoteStatus === "Expired" ? styles.expired : undefined}>{customerQuote?.quoteStatus}</td>
            </tr>
            <tr>
              <td>PO #</td>
              <td>{customerQuote?.poNumber}</td>
            </tr>
            <tr>
              <td>Quote Date</td>
              <td><DisplayFormattedDatetime value={customerQuote?.quoteDate} formatString={'MM/DD/YYYY'} /></td>
            </tr>
            <tr>
              <td>Expiration Date</td>
              <td className={customerQuote?.quoteStatus === "Expired" ? styles.expired : undefined}><DisplayFormattedDatetime value={customerQuote?.expirationDate} formatString={'MM/DD/YYYY'} /></td>
            </tr>
            <tr>
              <td>Shipper Zipcode</td>
              <td>{customerQuote?.shipperZipPostalCode}</td>
            </tr>
            <tr>
              <td>Consignee Zipcode</td>
              <td>{customerQuote?.consigneeZipPostalCode}</td>
            </tr>
            <tr>
              <td colSpan={2} style={{ textAlign: "left" }}>
                {customerQuote &&
                  <CustomerQuoteFreightView
                    customerQuote={customerQuote}
                    freightTotalLength={freightTotalLength}
                  />
                }
              </td>
            </tr>
            <tr>
              <td>Shipper Schedule</td>
              <td>
                <DisplayFormattedDatetime value={customerQuote?.shipperStartDate} formatString={'MM/DD/YYYY'} />
                {customerQuote?.shipperEndDate ?
                  <>
                    {' to '}
                    <DisplayFormattedDatetime value={customerQuote?.shipperEndDate} formatString={"MM/DD/YYYY"} />
                  </> : ""}
              </td>
            </tr>
            {customerQuote?.isShipperAppointmentRequired &&
              <tr>
                <td>Shipper Hardtime</td>
                <td>{moment(customerQuote.shipperHardTime, TIME_WITH_SECONDS_FORMAT).format(TIME_MERIDIAN_FORMAT)}</td>
              </tr>
            }
            <tr>
              <td>Consignee Schedule</td>
              <td>
                <DisplayFormattedDatetime value={customerQuote?.consigneeStartDate} formatString={'MM/DD/YYYY'} />
                {customerQuote?.consigneeEndDate ?
                  <>
                    {' to '}
                    <DisplayFormattedDatetime value={customerQuote?.consigneeEndDate} formatString={"MM/DD/YYYY"} />
                  </> : ""}
              </td>
            </tr>
            {customerQuote?.isConsigneeAppointmentRequired &&
              <tr>
                <td>Consignee Hardtime</td>
                <td>{moment(customerQuote.consigneeHardTime, TIME_WITH_SECONDS_FORMAT).format(TIME_MERIDIAN_FORMAT)}</td>
              </tr>
            }
            <tr>
              <td>Expedited Shipping</td>
              <td>{customerQuote?.isExpedited ? "True" : "False"}</td>
            </tr>
            <tr>
              <td>Tarp Type</td>
              <td>
                {customerQuote?.tarpId ? customerQuote?.tarp?.tarpName : "N/A"}
              </td>
            </tr>
            <tr>
              <td>Declared Value</td>
              <td>
                <DisplayFormattedNumber value={customerQuote?.declaredValue} formatString={"$0,000"} />
              </td>
            </tr>
            <tr>
              <td>Mileage</td>
              <td>{customerQuote?.mileage}</td>
            </tr>
            {customerQuote?.rateVariableFactor !== "Overdimensional" && <>
              <tr>
                <td>Rating Variable</td>
                <td>
                  {customerQuote?.rateVariableFactor === "Length" ? `${customerQuote.rateVariable} ft` : `${(customerQuote?.rateVariable ?? 0) * 1000} lbs`}
                </td>
              </tr>
              <tr>
                <td>Line Haul</td>
                <td>
                  <DisplayFormattedNumber
                    value={lineHaulRate}
                    formatString={"$0,0.00"}
                  />
                </td>
              </tr>
              <tr>
                <td>Tarp</td>
                <td>
                  <DisplayFormattedNumber
                    value={rateEngineResults.tarpRate}
                    formatString={"$0,0.00"}
                  />
                </td>
              </tr>
              {rateEngineResults.locationFee ?
                <tr>
                  <td>Location Fee</td>
                  <td>
                    <DisplayFormattedNumber
                      value={rateEngineResults.locationFee}
                      formatString={"$0,0.00"}
                    />
                  </td>
                </tr> : ""}
              <tr>
                <td className={styles.aboveSum}>
                  Fuel Surcharge
                  <>
                    {" ("}
                    <DisplayFormattedNumber
                      value={rateEngineResults.fuelSurchargePercentage}
                      formatString={"(0.00%)"}
                    />
                    {")"}
                  </>
                </td>
                <td className={styles.aboveSum}>
                  <DisplayFormattedNumber
                    value={rateEngineResults.fuelSurcharge}
                    formatString={"$0,0.00"}
                  />
                </td>
              </tr>
              <tr>
                <td className={styles.sum}>Rate</td>
                <td className={styles.sum}>
                  <DisplayFormattedNumber
                    value={rateEngineResults.highTotalRate}
                    formatString={"$0,0.00"}
                  />
                </td>
              </tr>
            </>}
          </tbody>
        </table>
      </Card>
    );
  }

}

export const CustomerQuoteViewPage = CustomerQuoteService.inject (
  StateService.inject(
    CustomerService.inject(
      CommodityService.inject(
        CustomerDetailService.inject(
          _CustomerQuoteViewPage
        )
      )
    )
  )
);