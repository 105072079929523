// using png files as jsPDF does not like exporting svg files

const autoAwesomeMotion = require('../images/autoawesomemotion.png') as string;

export function getIcon (iconName: string) {
  switch (iconName)
  {
    case "autoAwesomeMotion":
      return autoAwesomeMotion;
    default:
      return "";
  }
}