import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  directionType
} from "$Imports/CommonComponents";

import {
  FuelSurcharge, FuelSurchargeApiFactory
} from "$Generated/api";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

export interface ISortState {
  sortColumnName?: string;
  sortDirection?: directionType;
}

export type formModeType = "add" | "edit" | "none";

export interface FuelSurchargeModalState {
  surchargeValue: number | undefined
}

interface IFuelSurchargeState {
  showHistory: boolean;
  sortState: ISortState;
  fuelSurchargeFetchResults: IAjaxState<FuelSurcharge[]>; 
  fuelSurchargePostResults: IAjaxState<FuelSurcharge>;
  fuelSurchargeValidationErrors: ValidationError | null
  formMode: formModeType;
  fuelSurchargeModalState: FuelSurchargeModalState;
}

const InjectedPropName = "fuelSurchargeService";

const initialState = {
  showHistory: false,
  sortState: {
    sortColumnName: "date",
    sortDirection: "desc",
  },
  fuelSurchargeFetchResults: managedAjaxUtil.createInitialState(),
  fuelSurchargePostResults: managedAjaxUtil.createInitialState(),
  fuelSurchargeValidationErrors: null,
  formMode: "none",
  fuelSurchargeModalState: {}
} as IFuelSurchargeState;

const FuelSurchargeSchema: SchemaOf<NullableOptional<FuelSurchargeModalState>> = yup.object({
  surchargeValue: yup.number().required("Fuel surcharge value is required")
});

class FuelSurchargeFreezerService extends FreezerService<IFuelSurchargeState, typeof InjectedPropName> {

  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind 
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public clearAddSurchargeModal() {
    this.freezer.get().set({
      formMode: "none",
      fuelSurchargeModalState: {
        surchargeValue: undefined
      },
      fuelSurchargeValidationErrors: null
    });
  }

  public openAddSurchargeModal() {
    this.freezer.get().set({
      formMode: "add",
      fuelSurchargeModalState: {
        surchargeValue: undefined
      }
    });
  }

  public updateFuelSurchargeModal(fuelSurchargeModal: Partial<FuelSurchargeModalState>) {
    this.freezer.get().fuelSurchargeModalState.set(fuelSurchargeModal);
  }

  public fetchFuelSurcharges(forceUpdate: boolean = false) {

    const fuelSurchargeFetchResults = this.freezer.get().fuelSurchargeFetchResults;

    if (fuelSurchargeFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "fuelSurchargeFetchResults",
      onExecute: (apiOptions) => {
        const factory = FuelSurchargeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1FuelSurchargeGet();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch fuel surcharges.");
      }
    });

  }

  public async addFuelSurchargeValue() {
    const fuelSurchargeModalState = this.freezer.get().fuelSurchargeModalState;
    const fuelSurchargeValue = fuelSurchargeModalState.toJS().surchargeValue;

    const fuelSurcharge: FuelSurcharge = {
      fuelSurchargeValue: fuelSurchargeValue
    }

    const errors = await validateSchema(FuelSurchargeSchema, fuelSurchargeModalState, {
      abortEarly: false
    });

    this.freezer.get().set({ fuelSurchargeValidationErrors: errors });

    if (errors) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "fuelSurchargePostResults",
      onExecute: (apiOptions, params) => {
        const factory = FuelSurchargeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1FuelSurchargePost(params);
      },
      params: {
        body: fuelSurcharge
      },
      onOk: (data) => {
        this.fetchFuelSurcharges(true);
        this.clearAddSurchargeModal();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to add fuel surcharge.");
      }
    });
  }
}

export const FuelSurchargeService = new FuelSurchargeFreezerService();
export type IFuelSurchargeServiceInjectedProps = ReturnType<FuelSurchargeFreezerService["getPropsForInjection"]>;