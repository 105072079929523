import {
  React,
  _
} from "$Imports/Imports";

import {
  SelectedCompanyConsumer
} from "$Providers/index";

import {
  TarpRatesView
} from "./TarpRatesView";

interface ITarpRatesViewPageProps {

}

export class TarpRatesViewPage extends React.Component<ITarpRatesViewPageProps> {
  render() {
    return (
      <SelectedCompanyConsumer>
        {(companyContext) => { 
          return (
            <TarpRatesView companyId={companyContext.companyId}/>
          );
        }}
      </SelectedCompanyConsumer>
    );
  }
}