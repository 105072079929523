import {
  bind,
  React,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  DateRangePicker,
  SearchControlsContainer
} from "$Imports/CommonComponents";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "$Imports/MaterialUIComponents";

import {
  QuoteQuoteTypeEnum,
  QuoteSearchCriteria, 
  QuoteSearchCriteriaDateTypeEnum, 
  QuoteSearchCriteriaStatusesEnum
} from "$Generated/api";

import {
  DEFAULT_QUOTE_SEARCH,
  ISalesRepHomeServiceInjectedProps,
  SalesRepHomeService
} from "$State/SalesRepHomeFreezerService";

import {
  IQuoteServiceInjectedProps,
  QuoteService
} from "$State/QuoteFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

interface IOwnProps {
  quoteType: QuoteQuoteTypeEnum;
  onSubmit: (search: QuoteSearchCriteria) => void;
}

type OwnProps = IOwnProps
  & ISalesRepHomeServiceInjectedProps
  & IQuoteServiceInjectedProps;

interface IOwnState {
  searchCriteria: QuoteSearchCriteria;
  errors: ValidationError | null;
}

const styles: {
  container: string;
} = require("./SalesRepSearchForm.scss");

class _MyQuotesSearchForm extends React.PureComponent<OwnProps, IOwnState> {
  state: IOwnState = {
    searchCriteria: { ...DEFAULT_QUOTE_SEARCH },
    errors: null
  };

  @bind
  private _onQuoteNumberChange(text: string): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        quoteOrFreightNumber: text ?? ""
      }
    }));
  }

  @bind
  private _onCustomerNameChange(text: string): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        customerName: text ?? ""
      }
    }));
  }

  @bind
  private _onDateTypeChange(type: QuoteSearchCriteriaDateTypeEnum): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        dateType: type ?? "QuoteDate" as QuoteSearchCriteriaDateTypeEnum
      }
    }));
  }

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        startDate: start ? moment(start).startOf('day').toDate() : undefined,
        endDate: end ? moment(end).endOf('day').toDate() : undefined
      }
    }));
  }

  @bind
  private _onStatusChange(status?: QuoteSearchCriteriaStatusesEnum): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        statuses: status ? [status] : []
      }
    }));
  }

  @bind
  private async _onSubmit(): Promise<void> {
    const { searchCriteria } = this.state;

    const errors = await this.props.quoteService.validateSearchModel(searchCriteria);
    this.setState({ errors: errors });
    if (errors) {
      return;
    }

    this.props.onSubmit(searchCriteria);
  }

  @bind
  private _onClear(): void {
    this.setState({
      searchCriteria: { ...DEFAULT_QUOTE_SEARCH }
    });

    this.props.onSubmit(DEFAULT_QUOTE_SEARCH);

    this.setState({ errors: null });
  }

  componentDidMount() {
    const { quoteSearchCriteria } = this.props.salesRepHomeService.getState();

    this.setState({
      searchCriteria: { ...quoteSearchCriteria }
    });
  }

  render() {
    const {quoteType} = this.props;
    const {
      searchCriteria,
      errors
    } = this.state;

    const validationParser = new ValidationErrorParser<QuoteSearchCriteria>(errors);
    const customerError = validationParser.validationMessage("customerName");
    const startError = validationParser.validationMessage("startDate");
    const endError = validationParser.validationMessage("endDate");

    return (
      <SearchControlsContainer
        className={styles.container}
        onSubmit={this._onSubmit}
        onClear={this._onClear}
      >
        <TextField
          style={{ flex: "0 0 5rem" }}
          label={quoteType === "Full" ? "Quote / FB#" : "Quote #"}
          value={searchCriteria.quoteOrFreightNumber}
          onChange={(event) => this._onQuoteNumberChange(event.target.value)}
        />

        {quoteType === "Full" && 
          <TextField
            style={{ flex: "1" }}
            label="Code / Customer Name"
            error={!!customerError}
            helperText={customerError}
            value={searchCriteria.customerName}
            onChange={(event) => this._onCustomerNameChange(event.target.value)}
          />
        }

        <FormControl style={quoteType === "Full" ? { flex: "0 0 6rem" } : { flex: "0 0 10rem" }}>
          <InputLabel>Date Type</InputLabel>
          <Select
            value={searchCriteria.dateType}
            onChange={(event) => this._onDateTypeChange(event.target.value as QuoteSearchCriteriaDateTypeEnum)}
          >
            <MenuItem value={"QuoteDate"}>Quote Date</MenuItem>
            <MenuItem value={"DeliveryDate"}>Delivery Date</MenuItem>
            {quoteType === "Full" && <MenuItem value={"ExpirationDate"}>Expiration Date</MenuItem>}
          </Select>
        </FormControl>

        <div style={{ display: "inline-flex", flex: "0 0 17rem", gap: "0.5rem" }}>
          <DateRangePicker
            startDate={searchCriteria.startDate}
            startError={startError}
            endDate={searchCriteria.endDate}
            endError={endError}
            onChange={this._onDateRangeChange}
          />
        </div>

        {quoteType === "Full" && 
          <FormControl style={{ flex: "0 0 6rem" }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={searchCriteria.statuses?.length ? searchCriteria.statuses[0] : ""}
              onChange={(event) => this._onStatusChange(event.target.value as QuoteSearchCriteriaStatusesEnum)}
            >
              <MenuItem value={""}>All</MenuItem>
              <MenuItem value={"ApprovalNeeded"}>Approval Needed</MenuItem>
              <MenuItem value={"InProgress"}>In Progress</MenuItem>
              <MenuItem value={"Pending"}>Pending</MenuItem>
            </Select>
          </FormControl>
        }
      </SearchControlsContainer>
    );
  }
}

export const MyQuotesSearchForm = SalesRepHomeService.inject(
  QuoteService.inject(
    _MyQuotesSearchForm
  )
);