import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  SelectedCompanyConsumer
} from "$Providers/index";

import {
  RateTesterView
} from "./RateTesterView";

interface IRateTesterViewPageProps {

}

export class RateTesterViewPage extends React.Component<IRateTesterViewPageProps> {
  render() {
    return (
      <SelectedCompanyConsumer>
        {(companyContext) => { 
          return (
            <RateTesterView companyContext={companyContext} />
          );
        }}
      </SelectedCompanyConsumer>
    );
  }
}