// convert enumeration (string) values into fit-for-display values
import {
  LoadingInstructionAppliesToEnum,
  QuoteApprovalReasonApprovalNeededReasonIdEnum,
  QuoteApprovalReasonApprovalStatusEnum,
  QuoteCancellationReasonEnum,
  QuoteStatusEnum
} from "$Generated/api";

export const quoteStatusTextMap: { [key in QuoteStatusEnum | ""]: string } = {
  "": "",
  "AcceptanceRejected": "Acceptance Rejected",
  "Accepted": "Accepted",
  "ApprovalNeeded": "Approval Needed",
  "Approved": "Approved",
  "Declined": "Declined",
  "Expired": "Expired",
  "InProgress": "In Progress",
  "Pending": "Pending",
  "PendingNeedsCustomers": "Pending, Needs Customers",
  "AdminRerate": "Administrative Re-rate",
  "PendingResave": "Edit",
  "Convert": "Convert To Full Quote",
  "Converted": "Converted",
  "Canceled": "Canceled",
  "Requested": "Requested",
  "Completed": "Completed"
};

export const approvalReasonTextMap: { [key in QuoteApprovalReasonApprovalNeededReasonIdEnum | ""]: string } = {
  "": "",
  "DeclaredValue": "Declared Value",
  "OverDimensional": "Over-dimensional",
  "LowNegotiatedRate": "Low Negotiated Rate",
  "MileageDifferenceOverThreshold": "Mileage Out of Range",
  "NegotiatedRateOutOfRange": "Negotiated Rate Out of Range",
  "ShipperZoneChanged": "Shipper Address Changed",
  "ConsigneeZoneChanged": "Consignee Address Changed"
};

export const approvalStatusTextMap: { [key in QuoteApprovalReasonApprovalStatusEnum | ""]: string } = {
  "": "",
  "Approved": "Approved",
  "Denied": "Denied",
  "PendingApproval": "Pending Approval"
};

export const cancellationReasonTextMap: { [key in QuoteCancellationReasonEnum | ""]: string} = {
  "": "",
  "Customer": "Customer-related",
  "Kaiser": "Kaiser-related",
  "Other": "Other"
}

export const appliesToTextMap: { [key in LoadingInstructionAppliesToEnum | ""]: string } = {
  "": "",
  "BothShippersAndConsignees": "Both Shippers & Consignees",
  "ShippersOnly": "Shippers Only",
  "ConsigneesOnly": "Consignees Only"
}

export const appliesWhenTextMap: { [key in LoadingInstructionAppliesToEnum | ""]: string } = {
  "": "",
  "BothShippersAndConsignees": "Shipper or Consignee",
  "ShippersOnly": "Shipper Only",
  "ConsigneesOnly": "Consignee Only"
}