import {
  React
} from "$Imports/Imports";

import {
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  IChartDataPoint
} from "./ActivityCard";

interface IActivityTooltipProps {
  payload?: IChartDataPoint | undefined;
  isActive?: boolean | undefined;
}

const styles: {
  tooltip: string;
  quotes: string;
  sales: string;
} = require("./ActivityCard.scss");

export class ActivityTooltip extends React.PureComponent<IActivityTooltipProps> {

  render() {
    const {
      payload,
      isActive
    } = this.props;

    return payload && isActive ?
      (
        <div className={styles.tooltip}>
          <table>
            <tbody>
              <tr>
                {payload.label}
              </tr>
              <tr className={styles.quotes}>
                Quotes: {payload.quotes}
              </tr>
              <tr className={styles.sales}>
                Sales: <DisplayFormattedNumber value={payload.sales} formatString="$0,0" />
              </tr>
            </tbody>
          </table>
        </div>
      )
      : null;
  }
}