
import {
  React,
  bind,
  moment,
  _
} from "$Imports/Imports";

import {
  Button
} from "$Imports/MaterialUIComponents"

import {
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  NavigationService
} from "$State/NavigationFreezerService";

import {
  SimplifiedQuote
} from "$Generated/api";

import {
  SharedSecurityContext
} from '$Shared/utilities/Security/ApplicationSecuritySettings';

import {
  printQuote
} from "$Utilities/windowUtil";

interface IActionMenuProps {
  quote: SimplifiedQuote
}

export class ActionMenu extends React.PureComponent<IActionMenuProps> {

  @bind
  private _onViewClick() {
    const { quote } = this.props;
    NavigationService.navigateTo(`/salesportal/quote/${quote.id}/true`);
  }

  @bind
  private _onEditClick() {
    const { quote } = this.props;
    NavigationService.navigateTo(`/salesportal/quote/${quote.id}/false`);
  }

  @bind
  private _onPrintClick() {
    const { quote } = this.props;
    
    if (quote.id) {
      printQuote(quote.id);
    }
  }

  render() {
    const { quote } = this.props;

    const showEdit = quote.createdBy?.userId == SharedSecurityContext.getUserId()
      && moment().diff(quote.createdOn, "days") <= 7
      && quote.status === "InProgress";

    const showPrint = quote.quoteType === "Quick" ? false :_.includes(["Accepted", "Pending", "PendingNeedsCustomers"], quote.status);

    return (
      <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        {showEdit ?
          <Button
            color="primary"
            onClick={this._onEditClick}
            style={{ marginRight: "0.5rem" }}
          >
            Edit
          </Button>
          : undefined
        }
        <UserAccessControl roles={["quote:print"]}>
          {showPrint &&
            <Button
              color="primary"
              onClick={this._onPrintClick}
              style={{ marginRight: "0.5em"}}
            >
              Print
            </Button>
          }
        </UserAccessControl>
        <Button
          color="primary"
          onClick={this._onViewClick}
        >
          View
        </Button>
      </div>
    );
  }
}
