import {
  React,
  bind,
  _,
  Helpers
} from "$Imports/Imports";

import {
  QuoteCarrierFlattened,
  QuoteCarrierFlattenedCarrierStatusEnum
} from "$Generated/api";

import {
  CarrierStatus,
  DisplayFormattedDatetime,
  DisplayFormattedNumber,
  SearchControlsContainer,
  TextCellTruncated,
  AdvanceTextField,
  CardLinedHeader,
  AjaxActionIndicator,
  ToggleBox,
  ReminderListView
} from "$Imports/CommonComponents"

import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  Link,
  GridRow,
  GridRowProps,
  Box,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  Checkbox,
  ListItemText
} from "$Imports/MaterialUIComponents";

import {
  NavigationService
} from "$State/NavigationFreezerService";

import {
  CarrierService,
  ICarrierServiceInjectedProps
} from "$State/CarrierFreezerService";

import {
  EmployeeService,
  IEmployeeServiceInjectedProps
} from "$State/EmployeeFreezerService";

import {
  CURRENCY_FORMAT,
  DATE_ONLY_FORMAT,
  DATE_WITH_TIME_MERIDIAN_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  CarrierActionMenu
} from "./CarrierActionMenu";

import {
  CarrierSaleDetailsModal
} from "./CarrierSaleDetailsModal";

import {
  CarrierInfoEditModal
} from "./CarrierInfoEditModal";

import {
  CarrierMetrics
} from "./CarrierMetrics";

import {
  CompanyEnum
} from "$Utilities/companyUtil";

import {
  getDeliveryDateColumn
} from "$Utilities/quoteColConstants";

const styles: {
  mainContainer: string;
  gridContainer: string;
  searchContainer: string;
  actionColumn: string;
  dashboardContainer: string;
} = require("./CarrierManagementPage.scss");

const marketTypes = ["Primary Market", "Secondary Market"];
type MarketType = typeof marketTypes[number];

interface ICarrierSearchCriteria {
  fbTripNumber: string;
  statuses: QuoteCarrierFlattenedCarrierStatusEnum[];
}

// styling grid rows is difficult due to specificity. see https://mui.com/blog/mui-x-v5/#simplified-style-customization
// using a wrapper Box is the solution given in https://mui.com/x/react-data-grid/style/#styling-rows
function CarrierRow(props: React.HTMLAttributes<HTMLDivElement> & GridRowProps) {
  const row = props.row as QuoteCarrierFlattened;

  if (row.carrierStatus === "Available") {
    return (
      <Box sx={{ backgroundColor: "#69ffad" }}>
        <GridRow
          {...props}
        />
      </Box>
    );
  }
  else if (row.carrierStatus === "OnHold") {
    return (
      <Box sx={{ backgroundColor: "#ffff00" }}>
        <GridRow
          {...props}
        />
      </Box>
    );
  }
  else {
    return (
      <GridRow
        {...props}
      />
    );
  }
}


interface ICarrierManagementPageBaseProps {

}

type ICarrierManagementPageProps = ICarrierManagementPageBaseProps
  & ICarrierServiceInjectedProps
  & IEmployeeServiceInjectedProps;

interface ICarrierManagementPageState {
  selectedMarket: MarketType;
  displayCriteria: ICarrierSearchCriteria;
  filterCriteria: ICarrierSearchCriteria;
  saleDetailsModalIsOpen: boolean;
  detailCarrier: QuoteCarrierFlattened | undefined;
  editModalIsOpen: boolean;
  editCarrier: QuoteCarrierFlattened | undefined;
}

class _CarrierManagementPage extends React.Component<ICarrierManagementPageProps, ICarrierManagementPageState> {
  state: ICarrierManagementPageState = {
    selectedMarket: "Secondary Market",
    displayCriteria: {
      fbTripNumber: "",
      statuses: []
    },
    filterCriteria: {
      fbTripNumber: "",
      statuses: []
    },
    saleDetailsModalIsOpen: false,
    detailCarrier: undefined,
    editModalIsOpen: false,
    editCarrier: undefined
  };

  private readonly columns: GridColDef[] = [
    {
      headerName: "FB #",
      field: "freightBillNumber",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<string, QuoteCarrierFlattened>) => {
        return params.row.freightBillNumber ?? "";
      },
      renderCell: (params: GridRenderCellParams<string, QuoteCarrierFlattened>) => {
        if (params.value && params.row.quoteId) {
          const quoteId = params.row.quoteId;
          return (
            <TextCellTruncated
              text={
                <Link
                  href={`/salesportal/quote/${quoteId}/true`}
                  color="secondary"
                  onClick={(event) => this.fbClick(event, quoteId)}
                >
                  {params.value}
                </Link>
              }
              tooltip={params.value}
            />
          );
        }
        return "";
      }
    },
    {
      headerName: "Trip #",
      field: "tripNumber",
      flex: 1,
      renderCell: (params: GridRenderCellParams<number | undefined, QuoteCarrierFlattened>) =>
        <TextCellTruncated text={!Helpers.isNullOrUndefined(params.value) ? `${params.value}` : ""} />
    },
    {
      headerName: "Carrier Status",
      field: "carrierStatus",
      flex: 1,
      renderCell: (params: GridRenderCellParams<QuoteCarrierFlattenedCarrierStatusEnum, QuoteCarrierFlattened>) =>
        params.value && <CarrierStatus carrierStatus={params.value} />
    },
    {
      headerName: "Carrier Rep",
      field: "carrierRepName",
      flex: 1,
      renderCell: (params: GridRenderCellParams<string, QuoteCarrierFlattened>) =>
        params.value && <TextCellTruncated text={params.value} />
    },
    {
      headerName: "Sales Rep",
      field: "salesRepName",
      flex: 1,
      renderCell: (params: GridRenderCellParams<string, QuoteCarrierFlattened>) =>
        params.value && <TextCellTruncated text={params.value} />
    },
    {
      headerName: "Carrier Name",
      flex: 1,
      field: "vendorName",
      valueGetter: (params: GridValueGetterParams<string, QuoteCarrierFlattened>) => params.row.vendor?.vendorName ?? "",
      renderCell: (params: GridRenderCellParams<string, QuoteCarrierFlattened>) =>
        params.value && <TextCellTruncated text={params.value} />
    },
    {
      headerName: "Customer Rate",
      field: "customerNegotiatedRate",
      flex: 1,
      renderCell: (params: GridRenderCellParams<number | undefined, QuoteCarrierFlattened>) =>
        <DisplayFormattedNumber value={params.value} formatString={CURRENCY_FORMAT} />,
    },
    {
      headerName: "Origin",
      field: "originCityRegion",
      flex: 1,
      renderCell: (params: GridRenderCellParams<string, QuoteCarrierFlattened>) =>
        params.value && <TextCellTruncated text={params.value} />
    },
    {
      headerName: "Destination",
      field: "destinationCityRegion",
      flex: 1,
      renderCell: (params: GridRenderCellParams<string, QuoteCarrierFlattened>) =>
        params.value && <TextCellTruncated text={params.value} />
    },
    {
      headerName: "Shipping Date",
      field: "shippingDate",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date | undefined, QuoteCarrierFlattened>) => {
        const hideTime = params.value?.toLocaleString().includes('T00:00:00Z');
        return params.value
          ? hideTime
            ? <DisplayFormattedDatetime value={params.value} formatString={DATE_ONLY_FORMAT} manualOffset={true} />
            : <DisplayFormattedDatetime value={params.value} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} manualOffset={true} showTimeZone />
          : "";
      }
    },
    getDeliveryDateColumn("Delivery Date", true),
    {
      headerName: "",
      field: "action",
      sortable: false,
      disableColumnMenu: true,
      width: 60,
      cellClassName: styles.actionColumn,
      renderCell: (params: GridRenderCellParams<any, QuoteCarrierFlattened>) =>
        <CarrierActionMenu
          carrier={params.row}
          openSaleDetails={this.openSaleDetails}
          openEdit={this.openEditModal}
        />
    }
  ];


  componentDidMount() {
    this.props.carrierService.fetchCarrierInfo();
    this.props.carrierService.fetchVendors();
    this.props.employeeService.fetchCarrierReps();
    this.props.employeeService.fetchSalesReps();
  }

  @bind
  private selectMarket(market: MarketType) {
    this.setState({ selectedMarket: market });
  }

  @bind
  private filterData(): QuoteCarrierFlattened[] {
    const {
      filterCriteria
    } = this.state;

    const {
      quoteCarrierFetchResults
    } = this.props.carrierService.getState();
    const carrierData = quoteCarrierFetchResults.data ?? [];

    return _.filter(carrierData, qc => {
      return (
        (qc.isMarketPrimary === (this.state.selectedMarket === "Primary Market"))
        &&
        (filterCriteria.fbTripNumber === "" || (_.includes(qc.freightBillNumber ?? "", filterCriteria.fbTripNumber) || _.includes(qc.tripNumber?.toString(), filterCriteria.fbTripNumber)))
        &&
        (filterCriteria.statuses.length === 0 || (_.includes(filterCriteria.statuses, qc.carrierStatus)))
      );
    });
  }

  @bind
  private onChangeFbTripNumberFilter(event: React.ChangeEvent<{ name: string; value: string; }>) {
    this.setState((prev) => ({
      displayCriteria: {
        ...prev.displayCriteria,
        fbTripNumber: event.target.value
      }
    }));
  }

  @bind
  private onChangeStatusFilter(event: SelectChangeEvent<QuoteCarrierFlattenedCarrierStatusEnum[]>) {
    this.setState((prev) => ({
      displayCriteria: {
        ...prev.displayCriteria,
        statuses: event.target.value as QuoteCarrierFlattenedCarrierStatusEnum[]
      }
    }));
  }

  @bind
  private searchFieldOnKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      this.onSearchClick();
    }
  }

  @bind
  private onSearchClick() {
    this.setState({
      filterCriteria: { ...this.state.displayCriteria }
    });
  }

  @bind
  private onSearchClear() {
    this.setState({
      displayCriteria: {
        fbTripNumber: "",
        statuses: []
      },
      filterCriteria: {
        fbTripNumber: "",
        statuses: []
      }
    });
  }

  @bind
  private fbClick(event: React.MouseEvent, quoteId: number | undefined) {
    if (quoteId) {
      NavigationService.navigateTo(`/salesportal/quote/${quoteId}/true`);
    }
    event.preventDefault();
  }

  @bind
  private openSaleDetails(detailCarrier: QuoteCarrierFlattened) {
    this.setState({
      saleDetailsModalIsOpen: true,
      detailCarrier
    });
  }

  @bind
  private closeSaleDetails() {
    this.setState({
      saleDetailsModalIsOpen: false,
      detailCarrier: undefined
    });
  }

  @bind
  private openEditModal(editCarrier: QuoteCarrierFlattened) {
    this.setState({
      editModalIsOpen: true,
      editCarrier: _.cloneDeep(editCarrier)
    });
  }

  @bind
  private async saveEdit(carrier: QuoteCarrierFlattened) {
    const carrierParams = {
      quoteCarrier: carrier,
      quoteId: carrier.quoteId,
      isMarketPrimary: carrier.isMarketPrimary
    };
    await this.props.carrierService.saveCarrierInfo(carrierParams);
    this.cancelEdit();
  }

  @bind
  private cancelEdit() {
    this.setState({
      editModalIsOpen: false,
      editCarrier: undefined
    });
  }

  render() {
    const {
      selectedMarket,
      saleDetailsModalIsOpen,
      detailCarrier,
      editModalIsOpen,
      editCarrier,
      displayCriteria
    } = this.state;

    const {
      carrierRepFetchResults,
      activeCarrierReps,
      activeSalesReps
    } = this.props.employeeService.getState();

    const {
      quoteCarrierFetchResults,
      vendorFetchResults,
      saveQuoteCarrierFetchResults
    } = this.props.carrierService.getState();

    const carrierRepData = activeCarrierReps ?? [];
    const vendorData = vendorFetchResults.data ?? [];
    const carrierData = this.filterData();

    let currentUserId: number | undefined = undefined;
    if (carrierRepData && SharedSecurityContext.getUserId()) {
      const currentEmployee = _.find(carrierRepData, (e) => e.userId?.toLowerCase() === SharedSecurityContext.getUserId()?.toLowerCase());
      currentUserId = currentEmployee?.id;
    }
    const salesReps = _.orderBy(_.uniqBy(activeSalesReps.concat(carrierRepData), 'id'), s => s.lastName);

    return <>
      <div className={styles.mainContainer}>
        <CardLinedHeader titleText={"Carrier Tracking for Logistics Shipments"}>
          <AjaxActionIndicator
            state={[vendorFetchResults, quoteCarrierFetchResults, saveQuoteCarrierFetchResults, carrierRepFetchResults]}
          />
          <div className={styles.gridContainer}>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <ToggleBox
                selectedValue={selectedMarket}
                options={marketTypes}
                toggleClick={this.selectMarket}
                companyId={CompanyEnum.Logistics}
              />
              <SearchControlsContainer
                onSubmit={this.onSearchClick}
                onClear={this.onSearchClear}
                className={styles.searchContainer}
              >
                <AdvanceTextField
                  label="FB # / Trip #"
                  onChange={this.onChangeFbTripNumberFilter}
                  value={displayCriteria.fbTripNumber ?? ""}
                  onKeyDown={this.searchFieldOnKeyPress}
                />
                <FormControl style={{ width: "200px" }}>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={displayCriteria.statuses ?? []}
                    onChange={this.onChangeStatusFilter}
                    multiple
                    renderValue={(selected: QuoteCarrierFlattenedCarrierStatusEnum[]) => {
                      if (selected.length === 4) {
                        return <i>All</i>;
                      }
                      else {
                        return _.map(selected, (s, idx) => {
                          return (
                            <span key={idx}>
                              <CarrierStatus carrierStatus={s} />
                              <>{idx !== (selected).length - 1 ? ", " : ""}</>
                            </span>
                          );
                        });
                      }
                    }}
                  >
                    <MenuItem value="Assigned">
                      <Checkbox checked={_.includes(displayCriteria.statuses, "Assigned")} />
                      <ListItemText primary={"Assigned"} />
                    </MenuItem>
                    <MenuItem value="Available">
                      <Checkbox checked={_.includes(displayCriteria.statuses, "Available")} />
                      <ListItemText primary={"Available"} />
                    </MenuItem>
                    <MenuItem value="OnHold">
                      <Checkbox checked={_.includes(displayCriteria.statuses, "OnHold")} />
                      <ListItemText primary={"On Hold"} />
                    </MenuItem>
                    <MenuItem value="Picked">
                      <Checkbox checked={_.includes(displayCriteria.statuses, "Picked")} />
                      <ListItemText primary={"Picked"} />
                    </MenuItem>
                  </Select>
                </FormControl>
              </SearchControlsContainer>
            </div>
            <DataGridPro
              rows={carrierData}
              columns={this.columns}
              density="compact"
              hideFooter
              disableSelectionOnClick
              autoHeight
              components={{
                Row: CarrierRow
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "freightBillNumber", sort: "asc" }]
                }
              }}
              columnVisibilityModel={{
                salesRepName: selectedMarket === "Primary Market",
                carrierName: selectedMarket === "Secondary Market"
              }}
            />
          </div>
        </CardLinedHeader>

        <div className={styles.dashboardContainer}>
          <ReminderListView
            salesReps={salesReps}
            companyId={CompanyEnum.Logistics}
            style={{ flex: 6 }}
          />
          <CarrierMetrics
            currentUserId={currentUserId}
            style={{ flex: 4 }}
          />
        </div>
      </div>

      <CarrierSaleDetailsModal
        isOpen={saleDetailsModalIsOpen}
        carrier={detailCarrier}
        onClose={this.closeSaleDetails}
      />

      <CarrierInfoEditModal
        isOpen={editModalIsOpen}
        isSaving={saveQuoteCarrierFetchResults.isFetching}
        currentUserId={currentUserId}
        carrier={editCarrier}
        vendors={vendorData}
        carrierReps={carrierRepData}
        onSave={this.saveEdit}
        onCancel={this.cancelEdit}
      />
    </>;
  }
}

export const CarrierManagementPage = CarrierService.inject(
  EmployeeService.inject(
    _CarrierManagementPage
  )
);
