import {
  React,
  bind
} from "$Imports/Imports";

import {
  AuditCustomerAuditLog
} from "$Generated/api";

import {
  IconButton
} from "$Imports/MaterialUIComponents"

import {
  OpenInFull
} from "$Imports/MaterialUIIcons";


interface IActionMenuProps {
  auditLog: AuditCustomerAuditLog;
  openDetails: (auditLog: AuditCustomerAuditLog) => void;
}

export class ActionMenu extends React.Component<IActionMenuProps> {
  @bind
  private openDetails() {
    this.props.openDetails(this.props.auditLog);
  }

  render() {
    const { auditLog } = this.props;

    return (
      auditLog.log?.eventType === "Edit" && 
      <IconButton size="small" onClick={this.openDetails}>
        <OpenInFull fontSize="small" />
      </IconButton>
    )
  }
}