import {
  React
} from "$Imports/Imports";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "$Imports/MaterialUIComponents";

interface IOwnProps {
  name: string;
  disabled?: boolean;
  label: string;
  value: string | boolean | number | undefined;
  emptyLabel?: string;
  emptyValue?: string | number;
  trueLabel?: string;
  trueValue: string | number;
  falseLabel?: string;
  falseValue: string | number;
  onChange: (event: SelectChangeEvent<string | number>) => void;
}

class _TriStateSelector extends React.PureComponent<IOwnProps> {
  render() {
    const {
      name,
      disabled,
      label,
      value,
      emptyLabel,
      emptyValue,
      trueLabel,
      trueValue,
      falseLabel,
      falseValue,
      onChange
    } = this.props;

    // undefined causes issues in swapping between controlled/uncontrolled input values
    const normalizedEmpty = (emptyValue === undefined ? "" : emptyValue);

    const normalizedValue = (value === undefined)
      ? normalizedEmpty
      : (value === true) ? trueValue
      : (value === false) ? falseValue
      : value;

    return (
      <FormControl style={{ width: "100%" }}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={normalizedValue}
          onChange={onChange}
          name={name}
          disabled={disabled}
        >
          <MenuItem value={normalizedEmpty}>{emptyLabel ?? <>&nbsp;</>}</MenuItem>
          <MenuItem value={trueValue}>{trueLabel ?? "True"}</MenuItem>
          <MenuItem value={falseValue}>{falseLabel ?? "False"}</MenuItem>
        </Select>
      </FormControl>
    );
  }
}

export const TriStateSelector = _TriStateSelector;