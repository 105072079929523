
import {
  React,
  bind
} from "$Imports/Imports";

import {
  Response,
} from "$State/QuoteEntryFreezerService";

import {
  Question,
  QuoteStopFreightQuestionAnswerEnum
} from "$Generated/api";

import {
  QuestionAnswerRadioButtons
} from "./QuestionAnswerRadioButtons";

interface IMappedUpchargeReasonTextFieldsBaseProps {
  questions: Question[];
  answers: Response[];
  viewOnly: boolean;
  className?: string | undefined;
  setAnswer: (questionId: string, answer: string) => void;
}

export class MappedUpchargeReasonTextFields extends React.PureComponent<IMappedUpchargeReasonTextFieldsBaseProps> {

  @bind
  setAnswer(questionId: string, answer: string) {
    this.props.setAnswer(questionId, answer);
  }

  render() {
    
    const {
      questions,
      answers,
      className,
      viewOnly
    } = this.props;

    return questions.map((q, idx) => {
      var answer: QuoteStopFreightQuestionAnswerEnum = answers.find(a => a.questionId == q.id)?.answer ?? "No";
      const isFirstQuestion = idx === 0;
      
      return (
        <QuestionAnswerRadioButtons
          key={idx}
          viewOnly={viewOnly}
          radioGroupName={`${q.id}`}
          questionText={q.questionText}
          answer={answer}
          autoFocus={isFirstQuestion}
          isNa={q.isNa}
          className={className}
          setAnswer={this.setAnswer}
        />
      );
    });
  }
}