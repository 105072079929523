import {
  bind,
  React,
  _
} from "$Imports/Imports";

import {
  DataTable,
  IDataTableColumn,
  AjaxActionIndicator,
  DisplayFormattedNumber,
  DisplayFormattedDatetime,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Card,
  CardActions,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  FormControl,
  InputLabel,
  Select,
  Button,
  Grid,
} from "$Imports/MaterialUIComponents";

import {
  IRateModelServiceInjectedProps,
  RateModelService,
  resetToDefaultDateOption
} from "$State/RateModelFreezerService";

import {
  LinearFootCalculation,
  RateModel
} from "$Generated/api";

import {
  EditRateModelDefaults
} from "./EditRateModelDefaults";

import {
  EditRateModelSandboxRates,
} from "./EditRateModelSandboxRates";

import {
  ApplySandboxRates,
} from "./ApplySandboxRates";

import {
  ResetDefaultRates,
} from "./ResetDefaultRates";

import { MoreVert } from "$Imports/MaterialUIIcons";

import { ISelectedCompanyContext } from "$Providers/CompanyProvider";

import { DATE_WITH_TIME_MERIDIAN_FORMAT } from "$Shared/utilities/formatUtil";

const styles: {
  withBorder: string;
  cardHeader: string;
  noWrap: string;
  tableCell: string;
  defaultMessage: string;
} = require("./RateModelView.scss");

const containerStyles: {
  mainContainer: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface IRateModelViewBaseProp {
  companyContext: ISelectedCompanyContext;
}

interface RateModelViewState {
  menuOpen: boolean;
  anchorEl: Element | null;
  cancelOpen: boolean;
}

type IRateModelViewProp = IRateModelServiceInjectedProps & IRateModelViewBaseProp;

class _RateModelView extends React.Component<IRateModelViewProp, RateModelViewState> {

  state: RateModelViewState = {
    anchorEl: null,
    menuOpen: false,
    cancelOpen: false
  };

  @bind
  private _onOpen(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({
      anchorEl: event.currentTarget,
      menuOpen: true
    });
  }

  @bind
  private _onClose() {
    this.setState({
      menuOpen: false
    });
  }

  @bind
  private _onEditRateModelDefaultsOpen() {
    this._onClose();
    this.props.rateModelService.editRateModels("edit default");
  }

  @bind
  private _onEditRateModelSandboxOpen() {
    this._onClose();
    this.props.rateModelService.editRateModels("edit sandbox");
  }

  @bind
  private _onSave() {
    this.props.rateModelService.saveRateModels(this.props.companyContext.companyId);
  }

  @bind
  private _onRateModelsChanged(index: number, rateModel: Partial<RateModel>) {
    this.props.rateModelService.updateRateModels(index, rateModel);
  }

  @bind
  private _onCancelClick() {
    const rateModelService = this.props.rateModelService;
    const state = rateModelService.getState();

    // only display cancel modal when data changed
    if (_.isEqual(state.dataFromServer, state.rateModelsForEdit)) {
      rateModelService.clearEditRateModelsForm();
    } else {
      this.setState({ cancelOpen: true })
    }
  }

  @bind
  private _onCancelNo() {
    this.setState({ cancelOpen: false })
  }

  @bind
  private _onCancelYes() {
    const rateModelService = this.props.rateModelService;
    this.setState({ cancelOpen: false })
    rateModelService.clearEditRateModelsForm();
  }

  @bind
  private _onApplySandboxOpen() {
    this._onClose();
    this.props.rateModelService.openApplyRateForm("apply sandbox");
  }

  @bind
  private _onApplySandboxCancel() {
    this.props.rateModelService.clearApplyRateForm();
  }

  @bind
  private _onApplySandbox() {
    this.props.rateModelService.applySandbox(this.props.companyContext.companyId);
  }

  @bind
  private _onResetToDefaultDateChange(datetime: Date) {
    this.props.rateModelService.updateResetToDefaultDate(datetime);
  }

  // disable apply sandbox if no sandbox rates
  @bind
  private disableApplySandbox(data: RateModel[]): boolean {
    let i = 0;
    if (data.length === 0) {
      return true;
    }

    for (i = 0; i < data.length; i++) {
      if (data[i] === null || data[i] === undefined || data[i].sandboxRate === null || data[i].sandboxRate === undefined) {
        return true;
      }
    }

    return false;
  }

  @bind
  private _onResetToDefaultOpen() {
    this._onClose();
    this.props.rateModelService.openApplyRateForm("reset default");
  }

  @bind
  private _onResetToDefaultCancel() {
    this.props.rateModelService.clearApplyRateForm();
  }

  @bind
  private _onResetToDefault() {
    this.props.rateModelService.resetToDefault(this.props.companyContext.companyId);
  }

  @bind
  private _onResetToDefaultDateOptionChange(option: resetToDefaultDateOption) {
    this.props.rateModelService.updateResetToDefaultDateOption(option);
  }

  @bind
  private _onOriginZoneChanged(event: any) {
    this.props.rateModelService.setLinearFootRowDataRequestData({ originZoneId: event.target.value });
  }

  @bind
  private _onDestZoneChanged(event: any) {
    this.props.rateModelService.setLinearFootRowDataRequestData({ destinationZoneId: event.target.value });
  }

  @bind
  private _onUpdateMiles() {
    this.props.rateModelService.fetchLinearFootCalculation(this.props.companyContext.companyId, true);
  }

  @bind
  private _isRateInvalid(rate: RateModel) {
    return this.props.rateModelService.isRateInvalid(rate);
  }

  componentDidMount() {
    this.props.rateModelService.clearFreezer();
    this.props.rateModelService.fetchRateModels(this.props.companyContext.companyId, true);
    this.props.rateModelService.fetchZoneOptions(this.props.companyContext.companyId, true);
  }

  componentDidUpdate(prevProps: IRateModelViewBaseProp) {
    if (this.props.companyContext.companyId !== prevProps.companyContext.companyId) {
      this.props.rateModelService.clearFreezer();
      this.props.rateModelService.fetchRateModels(this.props.companyContext.companyId, true);
      this.props.rateModelService.fetchZoneOptions(this.props.companyContext.companyId, true);     
    }
  }

  @bind
  private styles_actionArea() : React.CSSProperties {
    return {
      justifyContent: "space-between",
      color: this.props.companyContext.themeColor,
      padding: "16px"
    };
  }

  @bind
  private styles_tableFooter() : React.CSSProperties {
    return {
      color: this.props.companyContext.themeColor,
      textAlign: "right"
    };
  }

  private readonly columns: Array<IDataTableColumn<RateModel>> = [
    {
      columnName: "feet",
      columnFieldData: (d) => d.feet ? <span>{d.feet}&nbsp;feet</span> : "",
      headerValue: "Linear Foot",
      cellProps: { className: styles.tableCell }
    },
    {
      columnName: "defaultRate",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.defaultRate} formatString={"0.00%"} />,
      headerValue: "Kaiser Default TSS %",
      cellProps: { className: styles.tableCell }
    },
    {
      columnName: "activeRate",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.activeRate} formatString={"0.00%"} />,
      headerValue: "Active Kaiser TSS %",
      cellProps: { className: styles.tableCell }
    },
    {
      columnName: "sandboxRate",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.sandboxRate} formatString={"0.00%"} emptyDisplay={"----"} />,
      headerValue: "Sandbox",
      cellProps: { className: styles.tableCell }
    },
  ];

  @bind
  private rowStyle(rowData: any): React.CSSProperties {
    const d = rowData as LinearFootCalculation;

    if (_.indexOf([37, 29, 24, 12, 6], d.linearFoot) > -1) {
      return {
        borderBottom: `${this.props.companyContext.themeColor} solid 4px`
      }
    }

    return {};
  }

  private readonly linearFootGridColumns: Array<IDataTableColumn<LinearFootCalculation>> = [
    {
      columnName: "feet",
      columnFieldData: (d) => d.linearFoot ?? "",
      headerValue: "Linear Foot",
      sortMethod: (d) => d.linearFoot,
    },
    {
      columnName: "percentageOfTrailer",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.percentageOfTrailer} formatString={"0.00%"} />,
      headerValue: "% of Trailer",
    },
    {
      columnName: "tssPercentage",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.tssPercentage} formatString={"0.00%"} />,
      headerProps: {
        className: styles.noWrap,
      },
      headerValue: "TSS %",
    },
    {
      columnName: "ratePerMile",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.ratePerMile} formatString={"$0.000"} />,
      headerValue: "Rate Per Mile",
    },
    {
      columnName: "pricePerFoot",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.pricePerFoot} formatString={"$0.0000"} />,
      headerValue: "Rate Per Foot",
    },
    {
      columnName: "miles",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.miles} formatString={"0,0"} />,
      headerValue: "Miles",
    },
    {
      columnName: "totalRate",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.totalRate} formatString={"$0,0.00"} />,
      headerValue: "Total Rate",
    },
    {
      columnName: "sandboxTssPercentage",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.sandboxTSSPercentage} formatString={"0.00%"} />,
      headerValue: "Sandbox TSS Percentage",
    },
    {
      columnName: "sandboxRatePerMile",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.sandboxRatePerMile} formatString={"$0.000"} />,
      headerValue: "Sandbox Rate Per Mile",
    },
    {
      columnName: "sandboxPricePerFoot",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.sandboxPricePerFoot} formatString={"$0.0000"} />,
      headerValue: "Sandbox Price Per Foot",
    },
    {
      columnName: "sandboxTotalRate",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.sandboxTotalRate} formatString={"$0.00"} />,
      headerValue: "Sandbox Total",
    },
  ];

  render() {
    const state = this.props.rateModelService.getState();

    const {
      rateModelFetchResults,
      rateModelsForEdit,
      formMode,
      resetToDefaultDate,
      resetToDefaultDateOption,
      zoneOptionsFetchResults,
      linearFootCalculationFetchResults,
      linearFootRowDataRequestData,
      linearFootGridSortState,
      invalidForm,
      invalidRateMessage
    } = state;

    const rateModelData = rateModelFetchResults.data ?? [];
    const zoneData = zoneOptionsFetchResults.data ?? [];
    const gridData = linearFootCalculationFetchResults.data ?? [];

    const isFetching: boolean = rateModelFetchResults.isFetching && zoneOptionsFetchResults.isFetching && linearFootCalculationFetchResults.isFetching;

    return (
      <div
        className={containerStyles.mainContainer}
      >
        <Card
          className={containerStyles.cardStyle}
        >
          <CardHeader
            title={this.props.companyContext.companyId === 1 ? "Rate Model Transport" : "Rate Model Logistics"}
          />
          <AjaxActionIndicator
            state={rateModelFetchResults || zoneOptionsFetchResults || linearFootCalculationFetchResults}
          />
          <Grid container spacing={0}>
            <Grid item md={3}>
              <Card
                className={containerStyles.cardStyle}
              >
                <CardActions
                  disableSpacing={true}
                  style={this.styles_actionArea()}
                >
                  <div
                    className={styles.cardHeader}
                  >
                    TSS Model Rates
                  </div>
                  <IconButton
                    aria-haspopup="true"
                    onClick={this._onOpen}
                    color="inherit"
                  >
                    <MoreVert />
                  </IconButton>
                  <Menu
                    anchorEl={this.state.anchorEl}
                    open={this.state.menuOpen}
                    onClose={this._onClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <UserAccessControl roles={["rate-model:edit-apply-sandbox"]}>
                      <MenuItem onClick={this._onEditRateModelSandboxOpen}>Edit Sandbox</MenuItem><Divider />
                      <MenuItem onClick={this._onApplySandboxOpen} disabled={this.disableApplySandbox(rateModelData)}>Apply Sandbox</MenuItem><Divider />
                    </UserAccessControl>
                    <UserAccessControl roles={["rate-model:reset-default"]}>
                      <MenuItem onClick={this._onResetToDefaultOpen}>Reset to Kaiser Default TSS %</MenuItem><Divider />
                    </UserAccessControl>
                    <UserAccessControl roles={["rate-model:modify-default"]}>
                      <MenuItem onClick={this._onEditRateModelDefaultsOpen}>Modify Kaiser Default TSS %</MenuItem>
                    </UserAccessControl>
                  </Menu>
                </CardActions>
                <DataTable
                  columns={this.columns}
                  data={rateModelData}
                  tableFooterComponent={(
                    <tr style={this.styles_tableFooter()}>
                      <td colSpan={4}>
                        {rateModelData[0]?.resetToDefaultOn ?
                          <DisplayFormattedDatetime
                            prefix={'Reset to Default: '}
                            value={rateModelData[0]?.resetToDefaultOn}
                            formatString={DATE_WITH_TIME_MERIDIAN_FORMAT}
                            showTimeZone
                          /> : <span className={styles.defaultMessage}>Currently using default rate.</span>
                        }
                      </td>
                    </tr>
                  )}
                />
              </Card>
            </Grid>
            <Grid item md={9}>
              <Card
                className={containerStyles.cardStyle}
              >
                <CardActions
                  disableSpacing={true}
                  style={this.styles_actionArea()}
                >
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <FormControl fullWidth>
                        <InputLabel>Origin Zone</InputLabel>
                        <Select
                          value={linearFootRowDataRequestData.originZoneId ?? ""}
                          onChange={this._onOriginZoneChanged}
                        >
                          {zoneData.map((zone, i) =>
                            <MenuItem value={zone.id} key={i}>
                              {zone.zoneName}
                            </MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <FormControl fullWidth>
                        <InputLabel>Destination Zone</InputLabel>
                        <Select
                          value={linearFootRowDataRequestData.destinationZoneId ?? ""}
                          onChange={this._onDestZoneChanged}
                        >
                          {zoneData.map((zone, i) =>
                            <MenuItem value={zone.id} key={i}>
                              {zone.zoneName}
                            </MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <Button
                        color="primary"
                        onClick={this._onUpdateMiles}
                        disabled={isFetching}
                      >
                        Update Miles
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
                <DataTable
                  columns={this.linearFootGridColumns}
                  data={gridData}
                  defaultSortColumnName={linearFootGridSortState.sortColumnName}
                  defaultSortDirection={linearFootGridSortState.sortDirection}
                  tableContextProps={{
                    rowStyle: this.rowStyle
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        </Card>
        <EditRateModelDefaults
          formMode={formMode}
          rateModelData={rateModelsForEdit}
          onCancelClick={this._onCancelClick}
          onCancelYes={this._onCancelYes}
          onCancelNo={this._onCancelNo}
          onSave={this._onSave}
          isFetching={isFetching}
          onRateModelsChanged={this._onRateModelsChanged}
          cancelOpen={this.state.cancelOpen}
          invalidForm={invalidForm}
          isRateInvalid={this._isRateInvalid}
          invalidRateMessage={invalidRateMessage}
        />
        <EditRateModelSandboxRates
          formMode={formMode}
          rateModelData={rateModelsForEdit}
          onCancelClick={this._onCancelClick}
          onCancelYes={this._onCancelYes}
          onCancelNo={this._onCancelNo}
          onSave={this._onSave}
          isFetching={isFetching}
          onRateModelsChanged={this._onRateModelsChanged}
          cancelOpen={this.state.cancelOpen}
          invalidForm={invalidForm}
          isRateInvalid={this._isRateInvalid}
          invalidRateMessage={invalidRateMessage}
        />
        <ApplySandboxRates
          onApplySandbox={this._onApplySandbox}
          onApplySandboxCancel={this._onApplySandboxCancel}
          onResetToDefaultDateChange={this._onResetToDefaultDateChange}
          isFetching={isFetching}
          resetToDefaultDate={resetToDefaultDate}
          formMode={formMode}
        />
        <ResetDefaultRates
          onResetToDefault={this._onResetToDefault}
          onResetToDefaultCancel={this._onResetToDefaultCancel}
          onResetToDefaultDateChange={this._onResetToDefaultDateChange}
          isFetching={isFetching}
          resetToDefaultDate={resetToDefaultDate}
          formMode={formMode}
          resetToDefaultDateOption={resetToDefaultDateOption}
          onResetToDefaultDateOptionChange={this._onResetToDefaultDateOptionChange}
        />
      </div>
    );
  }
}

export const RateModelView = RateModelService.inject(
  _RateModelView
);
