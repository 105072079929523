import {
  React,
  bind,
  _,
  moment
} from "$Imports/Imports";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio
} from "$Imports/MaterialUIComponents";

import {
  formModeType,
  resetToDefaultDateOption,
} from "$State/RateModelFreezerService";

import {
  AjaxActionIndicator,
  DateTimeInputField
} from "$Imports/CommonComponents";

const styles: {
  container: string;
} = require("./ResetDefaultRates.scss");

interface IResetDefaultRatesProps {
  isFetching: boolean;
  formMode: formModeType;
  resetToDefaultDate: Date | null;
  onResetToDefault: () => void;
  onResetToDefaultCancel: () => void;
  onResetToDefaultDateChange: (datetime: Date) => void;
  onResetToDefaultDateOptionChange: (value: resetToDefaultDateOption) => void
  resetToDefaultDateOption: resetToDefaultDateOption | null;
}

export class ResetDefaultRates extends React.PureComponent<IResetDefaultRatesProps> {

  @bind
  private _onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.props.onResetToDefault();
  }

  @bind
  private _onResetToDefaultDateChange(date: any, value?: string | null | undefined) {
    this.props.onResetToDefaultDateChange(date);
  }

  @bind
  private resetToDefaultDateInvalid(): boolean {
    const resetToDefaultDateOption = this.props.resetToDefaultDateOption;
    if (resetToDefaultDateOption === "immediate") {
      return false;
    }

    const resetToDefaultDate = this.props.resetToDefaultDate;
    if (resetToDefaultDate == null || !moment(resetToDefaultDate).isValid()) {
      return true;
    }

    return resetToDefaultDate < new Date();
  }

  @bind private _onResetToDefaultDateOptionChange(event: React.ChangeEvent<HTMLInputElement>, value: string) {
    this.props.onResetToDefaultDateOptionChange(value as resetToDefaultDateOption);
  }

  render() {
    const {
      isFetching,
      formMode,
      resetToDefaultDate,
      onResetToDefaultCancel,
      resetToDefaultDateOption
    } = this.props;

    return <Dialog
      open={formMode === "reset default"}
      maxWidth={"md"}
    >
      <form onSubmit={this._onSubmit}>
        <AjaxActionIndicator
          showProgress={isFetching}
        />
        <DialogTitle>
          Reset Default Values
        </DialogTitle>
        <DialogContent className={styles.container}>
          <span>The active TSS % will be reset to be the default TSS %</span>
          <br />
          <FormControl component="fieldset">
            <FormLabel component="legend">Reset active TSS % to default TSS % on</FormLabel>
            <RadioGroup value={resetToDefaultDateOption} onChange={this._onResetToDefaultDateOptionChange}>
              <FormControlLabel value={"immediate"} control={<Radio />} label="Reset to default TSS % immediately" />
              <FormControlLabel value={"scheduled"} control={<Radio />} label="Reset to default TSS % on" />
            </RadioGroup>
          </FormControl>
          <DateTimeInputField
            value={resetToDefaultDate ?? null}
            onChange={this._onResetToDefaultDateChange}
            required
            error={this.resetToDefaultDateInvalid()}
            helperText={this.resetToDefaultDateInvalid() && "Datetime cannot be empty and must be in the future. "}
            disabled={resetToDefaultDateOption === "immediate"}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onResetToDefaultCancel}
            disabled={isFetching}
            variant="outlined"
          >
            Cancel
            </Button>
          <Button
            color="primary"
            disabled={isFetching || this.resetToDefaultDateInvalid()}
            type="submit"
          >
            Save
            </Button>
        </DialogActions>
      </form>
    </Dialog >;
  }
}
