import {
  React,
  bind,
  Helpers
} from "$Imports/Imports";

import {
  Edit,
  Delete
} from "$Imports/MaterialUIIcons";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  DeclaredValueApproval
} from "$Generated/api";


interface IActionMenuState {
}

interface IActionMenuProps {
  declaredValue: DeclaredValueApproval;
  onEdit: (declaredValue: DeclaredValueApproval) => void;
}

export class ActionMenu extends React.PureComponent<IActionMenuProps, IActionMenuState> {

  @bind
  private _onEdit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    this.props.onEdit(this.props.declaredValue);
  }

  render() {
    const canEdit = Helpers.isNullOrUndefined(this.props.declaredValue?.endDateTime);

    return (
      <>
        { canEdit &&
          <IconButton
            size="small"
            onClick={this._onEdit}
          >
            <Edit />
          </IconButton> }
      </>
    );
  }
}
