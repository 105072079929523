import {
  React,
  _,
  bind
} from "$Imports/Imports";
import {
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps
} from "$Imports/MaterialUIComponents";

interface IAdvanceDatetimePickerFieldBasedProps {
  debounceWaitTime?: number;
  onDebouncedChange?: (date: Date | null) => void;
}

interface IAdvanceDatetimePickerFieldState {
  currentValue?: unknown;
  lastValue?: unknown;
}

export type IAdvanceDatetimePickerFieldProps = IAdvanceDatetimePickerFieldBasedProps & KeyboardDateTimePickerProps<Date, Date>;

export class AdvanceDatetimePickerField extends React.PureComponent<IAdvanceDatetimePickerFieldProps, IAdvanceDatetimePickerFieldState> {

  static defaultProps: Partial<IAdvanceDatetimePickerFieldProps> = {
    debounceWaitTime: 250,
  };

  state = {
    currentValue: undefined,
    lastValue: undefined
  };

  componentDidMount() {
    this._setDebounceFunction(this.props.debounceWaitTime);
  }

  componentDidUpdate(prevProps: IAdvanceDatetimePickerFieldProps, prevState: IAdvanceDatetimePickerFieldState) {
    // Update the debounce function if the value changes.
    if (this.props.debounceWaitTime !== prevProps.debounceWaitTime) {
      this._setDebounceFunction(this.props.debounceWaitTime);
    }
  }

  // The value property is stored in the state to allow typing during the
  // debounce.  The function updates state if the value property changes.
  static getDerivedStateFromProps(props: IAdvanceDatetimePickerFieldProps, state: IAdvanceDatetimePickerFieldState): Partial<IAdvanceDatetimePickerFieldState> | null {

    // Update the state of the lastValue does not match the prop.
    if (state === null || props.value !== state.lastValue) {
      return {
        currentValue: props.value,
        lastValue: props.value
      };
    }

    // Do nothing if the value does not change.
    return null;
  }

  @bind
  private _onChange(date: Date | null, value: string |  undefined): void {
    
    const updatedValue = date;

    this.setState({
      currentValue: date,
    });

    if (this.props.onChange) {
      this.props.onChange(date, value);
    }

    this._raiseDebounceChange(updatedValue);
  }

  private async _raiseDebounceChange(newValue: Date | null) {
    if (this._onDebounceChange_debounce) {
      this._onDebounceChange_debounce(newValue);
    }
  }

  private _onDebounceChange_debounce: ((newValue: Date | null) => void) | null = null;

  private _onDebounceChange(newValue: Date | null) {
    if (this.props.onDebouncedChange) {
      this.props.onDebouncedChange(newValue);
    }
  }

  private _setDebounceFunction(timeout: number = 500): void {
    this._onDebounceChange_debounce = _.debounce(this._onDebounceChange, this.props.debounceWaitTime ? this.props.debounceWaitTime : 500, {
      trailing: true,
    });
  }

  render() {
    const {
      onChange,
      value,
      onDebouncedChange,
      debounceWaitTime,
      ...passthroughProps
    } = this.props;

    const {
      currentValue
    } = this.state;

    return (
      <KeyboardDateTimePicker
        {...passthroughProps}
        value={currentValue ?? null}
        onChange={this._onChange}
      />
    );
  }
}
