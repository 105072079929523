import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState
} from "$Imports/Imports";

import {
  CacheApiFactory,
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

interface ICacheServiceState {
  cacheClearResults: IAjaxState<boolean>;
}

const InjectedPropName = "cacheService";

const initialState = {
  cacheClearResults: managedAjaxUtil.createInitialState()
} as ICacheServiceState;

class CacheFreezerService extends FreezerService<ICacheServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public clearCache() {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "cacheClearResults",
      params: {},
      onExecute: (apiOptions, params, options) => {
        const factory = CacheApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.clearCache(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to clear application cache.");
      },
      onOk: (data) => {
        ErrorService.pushErrorMessage("Application cache cleared.");
      }
    })
  }
}

export const CacheService = new CacheFreezerService();
export type ICacheServiceInjectedProps = ReturnType<CacheFreezerService["getPropsForInjection"]>;
