// extracted by mini-css-extract-plugin
var _1 = "MainLayout__applicationBarTab__zw3n6";
var _2 = "MainLayout__applicationBarTabTabs__mOoeB";
var _3 = "MainLayout__applicationContainer__lZSs3";
var _4 = "MainLayout__appsIcon__n32Uv";
var _5 = "MainLayout__appsMenu__qOBE7";
var _6 = "MainLayout__appsMenuItem__VVafa";
var _7 = "MainLayout__companyContainer__t4qcR";
var _8 = "MainLayout__kaiserSalesPortal__FoGB4";
var _9 = "MainLayout__loading__b9nRP";
var _a = "MainLayout__mainContent__MWKww";
var _b = "MainLayout__subContainer__G3M2e";
var _c = "MainLayout__usernameDisplay__Ag63Q";
export { _1 as "applicationBarTab", _2 as "applicationBarTabTabs", _3 as "applicationContainer", _4 as "appsIcon", _5 as "appsMenu", _6 as "appsMenuItem", _7 as "companyContainer", _8 as "kaiserSalesPortal", _9 as "loading", _a as "mainContent", _b as "subContainer", _c as "usernameDisplay" }
