import {
  React,
  bind
} from "$Imports/Imports";

import {
  CustomerContact
} from "$Generated/api";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  Edit,
  Star,
  StarOutline
} from "$Imports/MaterialUIIcons";

import {
  UserAccessControl
} from "$Imports/CommonComponents";

const styles: {
  hideShow: string;
} = require("./CustomerContacts.scss");

interface IActionMenuProps {
  contact: CustomerContact;
  editContact: (contact: CustomerContact) => void;
  setPrimaryContact: (contact: CustomerContact) => void;
}

export class ActionMenu extends React.Component<IActionMenuProps> {
  @bind
  private _onEditClick() {
    const { contact } = this.props;
    this.props.editContact(contact);
  }

  @bind
  private _onPrimaryClick() {
    const { contact } = this.props;
    this.props.setPrimaryContact(contact);
  }

  render() {
    const { contact } = this.props;

    return (
      <UserAccessControl roles={["quote:create", "quote:edit"]}>
        <IconButton size="small" onClick={this._onPrimaryClick}>
          {
            contact.isPrimary ?
            <Star fontSize="small" style={{ verticalAlign: "middle" }} />
            :
            <div className={styles.hideShow}>
              <StarOutline fontSize="small" style={{ verticalAlign: "middle" }} />
            </div>
          }
        </IconButton>
        <div className={styles.hideShow}>
          <IconButton onClick={this._onEditClick} size="small">
            <Edit fontSize="small" style={{ verticalAlign: "middle" }} />
          </IconButton>
        </div>
      </UserAccessControl>
    )
  }
}