import {
  React,
  bind
} from "$Imports/Imports";

import {
  DialogActions,
  DialogContentText,
  DialogContent,
  Dialog,
  Button,
  DialogTitle
} from "$Imports/MaterialUIComponents";

interface INotificationModalProps {
  isOpen: boolean;
  title?: string;
  message?: string;
  confirmButtonText?: string;
  onConfirm: () => void;
}

// TODO: KT-1251, refactoring of confirm/cancel/binary choice modal could also affect this modal
export class NotificationModal extends React.PureComponent<INotificationModalProps> {
  @bind
  private _onConfirm(): void {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
      >
        {this.props.title &&
          <DialogTitle>
            {this.props.title}
          </DialogTitle>
        }
        <DialogContent>
          <DialogContentText style={{textAlign: "center"}}>
            {this.props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this._onConfirm}
          >
            {this.props.confirmButtonText ? this.props.confirmButtonText : "OK"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
