import {
  React,
  bind,
  numeral
} from "$Imports/Imports";

import {
  MetricCell
} from "$Imports/CommonComponents";

import {
  Grid
} from "$Imports/MaterialUIComponents";

import {
  ISalesRepHomeServiceInjectedProps,
  KPIDatePreset,
  SalesRepHomeService
} from "$State/SalesRepHomeFreezerService";

import {
  CURRENCY_FORMAT,
  CURRENCY_NO_DECIMAL_FORMAT,
  NUMERIC_SEPARATED_FORMAT,
  PERCENTAGE_ONE_DECIMAL
} from "$Shared/utilities/formatUtil";

import {
  KPIDateForm
} from "./KPIDateForm";

interface IOwnProps {
  companyId?: number;
  selectedMetricType: string;
}

type OwnProps = IOwnProps
  & ISalesRepHomeServiceInjectedProps;

class _KPIGrid extends React.Component<OwnProps> {
  @bind
  private _onFilterChange(startDate: Date | null, endDate: Date | null, preset: KPIDatePreset): void {
    this.props.salesRepHomeService.update({
      kpiFilters: {
        startDate: startDate ?? undefined,
        endDate: endDate ?? undefined,
        preset: preset
      }
    });

    this.props.salesRepHomeService.fetchKpis(this.props.companyId, this.props.selectedMetricType);
  }

  componentDidMount() {
    this.props.salesRepHomeService.fetchKpis(this.props.companyId, this.props.selectedMetricType);
  }

  componentDidUpdate(prev: IOwnProps) {
    if (this.props.companyId !== prev.companyId) {
      this.props.salesRepHomeService.fetchKpis(this.props.companyId, this.props.selectedMetricType);
    }
  }

  render() {
    const {
      kpiFilters,
      kpiFetchResults: {
        data: kpiData
      }
    } = this.props.salesRepHomeService.getState();

    const bookedTotal = kpiData?.charges
      ? numeral(kpiData.charges).format(CURRENCY_NO_DECIMAL_FORMAT)
      : "-";

    const bookedWeightMajor = kpiData?.totalWeight
      ? `${numeral(kpiData.totalWeight).format(NUMERIC_SEPARATED_FORMAT)} lbs`
      : "-";

    const bookedWeightMinors = [
      `${kpiData?.rk ? numeral(kpiData.rk).format(CURRENCY_FORMAT) : "-"}/K`,
      `${kpiData?.rkm ? numeral(kpiData.rkm).format(CURRENCY_FORMAT) : "-"}/K/Mile`
    ];

    const bookedLengthMajor = kpiData?.totalRollupLength
      ? `${numeral(kpiData.totalRollupLength).format(NUMERIC_SEPARATED_FORMAT)} ft`
      : "-";

    const bookedLengthMinors = [
      `${kpiData?.rf ? numeral(kpiData.rf).format(CURRENCY_FORMAT) : "-"}/ft`,
      `${kpiData?.rfm ? numeral(kpiData.rfm).format(CURRENCY_FORMAT) : "-"}/ft/mile`
    ];

    const miles = kpiData?.totalDistance
      ? numeral(kpiData.totalDistance).format(NUMERIC_SEPARATED_FORMAT)
      : "-";

    const conversionRate = kpiData?.conversionRate 
      ? numeral(kpiData?.conversionRate).format(PERCENTAGE_ONE_DECIMAL)
      : "-";

    const avgAmtPerQuote = kpiData?.avgAmountPerQuote
      ? numeral(kpiData?.avgAmountPerQuote).format(CURRENCY_FORMAT)
      : "-";

    return (
      <>
        <div style={{ marginBottom: "0.5rem" }}>
          <KPIDateForm
            startDate={kpiFilters.startDate ?? null}
            endDate={kpiFilters.endDate ?? null}
            preset={kpiFilters.preset}
            onChange={this._onFilterChange}
          />
        </div>

        <Grid
          container
          justifyContent="space-between"
          columnSpacing={3}
          rowSpacing={2}
        >
          <MetricCell
            title="Accepted Quotes"
            majorValue={kpiData?.bills?.toString() ?? "-"}
            xs={3}
          />

          <MetricCell
            title="Total Quotes"
            majorValue={kpiData?.totalBills?.toString() ?? "-"}
            xs={3}
          />

          <MetricCell
            title="Booked Amount"
            majorValue={bookedTotal}
            xs={3}
          />

          <MetricCell
            title="Conversion Rate"
            majorValue={conversionRate}
            xs={3}
          />

          <MetricCell
            title="Booked Weight"
            majorValue={bookedWeightMajor}
            minorValues={bookedWeightMinors}
            xs={3}
          />

          <MetricCell
            title="Booked Feet"
            majorValue={bookedLengthMajor}
            minorValues={bookedLengthMinors}
            xs={3}
          />

          <MetricCell
            title="Avg Amount per Quote"
            majorValue={avgAmtPerQuote}
            xs={3}
          />

          <MetricCell
            title="FB Miles"
            majorValue={miles}
            xs={3}
          />
        </Grid>
      </>
    );
  }
}

export const KPIGrid = SalesRepHomeService.inject(_KPIGrid);