import {
  React,
  _
} from "$Imports/Imports";

import {
  CardLinedHeader,
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps
} from "$State/QuoteEntryFreezerService";

import { 
  QuoteCalculatedRate
} from "$Generated/api";

import { 
  LabelValueDisplay 
} from "$Imports/CommonComponents";

import { 
  CURRENCY_FORMAT
 } from "$Shared/utilities/formatUtil";

interface IRateModelCardBaseProps {
  currentRate: QuoteCalculatedRate | undefined;
  accessorialChargesValue: number;
  actionButtons: JSX.Element;
}

type IRateModelCardProps = IRateModelCardBaseProps & IQuoteEntryServiceInjectedProps;

const styles: {
  fullCard: string;
  rateModelCard: string;
  aboveSum: string;
  sum: string;
  datMessage: string;
  quoteButtons: string;
  emphasizeLabel: string;
  emphasizeValue: string;
  rateModelContainer: string;
  labelValueDisplay: string;
} = require('./RateInfoSection.scss');

export class _RateModelCard extends React.Component<IRateModelCardProps> {
  render() {
    const {
      quote,
      rateEngineResult
    } = this.props.QuoteEntryService.getState();

    const {
      currentRate,
      accessorialChargesValue,
      actionButtons
    } = this.props;
    
    var lineHaulRate: number | undefined = undefined;
    if (rateEngineResult?.highBaseRate && rateEngineResult?.fuelSurcharge) {
      lineHaulRate = rateEngineResult?.highBaseRate - rateEngineResult?.fuelSurcharge
        + (rateEngineResult?.equipmentTypeRate ?? 0);
    }

    const rate = rateEngineResult?.highTotalRate ? rateEngineResult.highTotalRate + accessorialChargesValue : undefined;
    const showLocationFee = quote.quoteType === "Full" && !!quote.quoteStops?.some(s => s.isTwicCardRequired || s.isMilitaryBase);

    return (
      <CardLinedHeader
        className={quote.quoteType === "Quick" ? styles.fullCard : styles.rateModelCard}
        titleText="Rate Model"
        titleComponents={(
          quote.quoteType === "Quick" && quote.quoteNumber &&
          <div>
            <span>{`KQ${quote.quoteNumber}`}</span>
          </div>
        )}
      >
        <div className={styles.rateModelContainer}>
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Line Haul"}
              value={!!rateEngineResult && <DisplayFormattedNumber value={lineHaulRate} formatString={CURRENCY_FORMAT} />}
            />
          </div>
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Tarp"}
              value={!!rateEngineResult &&
                <DisplayFormattedNumber
                  value={rateEngineResult.tarpRate}
                  formatString={CURRENCY_FORMAT}
                />}
            />
          </div>
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Flat Upcharge"}
              value={!!rateEngineResult &&
                <DisplayFormattedNumber
                  value={rateEngineResult.upchargeFlatRate}
                  formatString={CURRENCY_FORMAT}
                />}
            />
          </div>
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Upcharge %"}
              value={!!rateEngineResult &&
                <DisplayFormattedNumber
                  value={rateEngineResult.upchargePercentageRate}
                  formatString={CURRENCY_FORMAT}
                />}
            />
          </div>
          {quote.quoteType === "Full" &&
            <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Multi-stop Rate"}
              value={<DisplayFormattedNumber
                value={rateEngineResult?.multiStopFee}
                formatString={CURRENCY_FORMAT}
              />}
            />
            </div>
          }
          {showLocationFee &&
            <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Location Fee"}
              value={<DisplayFormattedNumber
                value={rateEngineResult?.locationFee}
                formatString={CURRENCY_FORMAT}
              />}
            />
            </div>
          }
          <div className={styles.labelValueDisplay}>
            <LabelValueDisplay
              label={rateEngineResult ?
                  <>
                    {"Fuel Surcharge ("}
                    <DisplayFormattedNumber
                      value={rateEngineResult.fuelSurchargePercentage}
                      formatString={"(0.00%)"}
                    />
                    {")"}
                  </>
                  : "Fuel Surcharge"
                }
              value={!!rateEngineResult &&
                <DisplayFormattedNumber
                  value={rateEngineResult.fuelSurcharge}
                  formatString={CURRENCY_FORMAT}
                />}
            />
          </div>
          {quote.quoteType === "Full" && accessorialChargesValue !== 0 &&
            <div className={styles.labelValueDisplay}>
            <LabelValueDisplay 
              label={"Accessorial Charges"}
              value={<DisplayFormattedNumber
                value={accessorialChargesValue}
                formatString={CURRENCY_FORMAT}
              />}
            />
            </div>
          }
          <div className={styles.labelValueDisplay && styles.aboveSum && styles.sum}>
            <LabelValueDisplay 
              label={"Rate"}
              value={!!rateEngineResult &&
                <DisplayFormattedNumber
                  value={rate}
                  formatString={CURRENCY_FORMAT}
                />}
              labelClass={styles.emphasizeLabel}
              valueClass={styles.emphasizeValue}
            />
          </div>
        </div>

        {currentRate?.datRateMessage &&
          <span className={styles.datMessage}>
            {currentRate?.datRateMessage}
          </span>
        }
        {quote.quoteType === "Quick" &&
          <div className={styles.quoteButtons}>
            {actionButtons}
          </div>
        }
      </CardLinedHeader>
    );
  }
}

export const RateModelCard = QuoteEntryService.inject(_RateModelCard);