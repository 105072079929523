import {
  React,
  bind
} from "$Imports/Imports";

import {
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Edit
} from "$Imports/MaterialUIIcons";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  Commodity
} from "$Generated/api";


interface IActionMenuState {
}

interface IActionMenuProps {
  commodity: Commodity;
  onMenuItemClick?: (commodity: Commodity) => void;
}

export class ActionMenu extends React.PureComponent<IActionMenuProps, IActionMenuState> {

  @bind
  private _onEditTask(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick(this.props.commodity);
    }
  }
  
  render() {
    return (
      <>
        <UserAccessControl roles={["commodity-lookup:create"]}>
          <IconButton
            size="small"
            onClick={this._onEditTask}
          >
            <Edit />
          </IconButton>
        </UserAccessControl>
      </>
    );
  }
}
