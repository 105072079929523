// convert enumeration (string) values into fit-for-display values
import {
  ActivityActivityTypeEnum,
  LoadingInstructionAppliesToEnum,
  ProspectDecisionMakingTypeEnum,
  ProspectFreightHandlingEnum,
  ProspectIndustryTypeEnum,
  ProspectPercentageToCloseEnum,
  ProspectPricingTypeEnum,
  QuoteApprovalReasonApprovalNeededReasonIdEnum,
  QuoteApprovalReasonApprovalStatusEnum,
  QuoteCancellationReasonEnum,
  QuoteStatusEnum
} from "$Generated/api";

export const quoteStatusTextMap: { [key in QuoteStatusEnum | ""]: string } = {
  "": "",
  "AcceptanceRejected": "Acceptance Rejected",
  "Accepted": "Accepted",
  "ApprovalNeeded": "Approval Needed",
  "Approved": "Approved",
  "Declined": "Declined",
  "Expired": "Expired",
  "InProgress": "In Progress",
  "Pending": "Pending",
  "PendingNeedsCustomers": "Pending, Needs Customers",
  "AdminRerate": "Administrative Re-rate",
  "PendingResave": "Edit",
  "Convert": "Convert To Full Quote",
  "Converted": "Converted",
  "Canceled": "Canceled",
  "Requested": "Requested",
  "Completed": "Completed"
};

export const approvalReasonTextMap: { [key in QuoteApprovalReasonApprovalNeededReasonIdEnum | ""]: string } = {
  "": "",
  "DeclaredValue": "Declared Value",
  "OverDimensional": "Over-dimensional",
  "LowNegotiatedRate": "Low Negotiated Rate",
  "MileageDifferenceOverThreshold": "Mileage Out of Range",
  "NegotiatedRateOutOfRange": "Negotiated Rate Out of Range",
  "ShipperZoneChanged": "Shipper Address Changed",
  "ConsigneeZoneChanged": "Consignee Address Changed"
};

export const approvalStatusTextMap: { [key in QuoteApprovalReasonApprovalStatusEnum | ""]: string } = {
  "": "",
  "Approved": "Approved",
  "Denied": "Denied",
  "PendingApproval": "Pending Approval"
};

export const cancellationReasonTextMap: { [key in QuoteCancellationReasonEnum | ""]: string} = {
  "": "",
  "Customer": "Customer-related",
  "Kaiser": "Kaiser-related",
  "Other": "Other"
}

export const appliesToTextMap: { [key in LoadingInstructionAppliesToEnum | ""]: string } = {
  "": "",
  "BothShippersAndConsignees": "Both Shippers & Consignees",
  "ShippersOnly": "Shippers Only",
  "ConsigneesOnly": "Consignees Only"
}

export const appliesWhenTextMap: { [key in LoadingInstructionAppliesToEnum | ""]: string } = {
  "": "",
  "BothShippersAndConsignees": "Shipper or Consignee",
  "ShippersOnly": "Shipper Only",
  "ConsigneesOnly": "Consignee Only"
}

export const industryTypeTextMap: { [key in ProspectIndustryTypeEnum | ""]: string } = {
  "": "",
  "Other": "Other",
  "Aerospace": "Aerospace",
  "Equipment": "Equipment (Construction / Manufacturing / Farming)",
  "GeneratorsTransformers": "Generators & Transformers",
  "MachineTools": "Machine Tools",
  "Steel": "Steel"
}

export const percentageToCloseTextMap: { [key in ProspectPercentageToCloseEnum | ""]: string } = {
  "": "",
  "NoContactWithDM": "0% - No contact with DM",
  "ContactedDMInterestExpressed": "25% - Contacted DM & interest expressed",
  "Quoted": "50% - Quoted",
  "VerbalAgreement": "75% - Verbal Agreement",
  "OrderPlaced": "100% - Order Placed"
}

export const decisionMakingTypeTextMap: { [key in ProspectDecisionMakingTypeEnum | ""]: string } = {
  "": "",
  "Auctions": "Auctions",
  "DealerNetwork": "Dealer Network",
  "Local": "Local",
  "NewConstruction": "New Construction",
  "OceanCarriers": "Ocean Carriers",
  "WholesalerDistributor": "Wholesaler / Distributor"
}

export const freightHandlingTextMap: { [key in ProspectFreightHandlingEnum | ""]: string } = {
  "": "",
  "NonStackable": "Non-stackable",
  "Stackable": "Stackable"
}

export const pricingTypeTextMap: { [key in ProspectPricingTypeEnum | ""]: string } = {
  "": "",
  "Contracted": "Contracted",
  "SpotQuote": "Spot Quote"
}

export const activityTypeTextMap: { [key in ActivityActivityTypeEnum | ""]: string } = {
  "": "",
  "Note": "Note",
  "PhoneCallCold": "Phone Call (Cold)",
  "PhoneCallFirstAppt": "Phone Call (First Appt)",
  "PhoneCallFollowup": "Phone Call (Follow-up)",
  "InPersonColdCall": "In-Person (Cold Call)",
  "InPersonFirstAppt": "In-Person (First Appt)",
  "InPersonFollowup": "In-Person (Follow-up)",
  "EmailColdCall": "Email (Cold Call)",
  "EmailFollowup": "Email (Follow-up)",
  "MarketingCampaign": "Marketing Campaign",
  "Proposal": "Proposal",
  "AccountHandoff": "Account Handoff"
}