import {
  moment,
  NullableOptional
} from "$Imports/Imports";

import {
  SchemaOf
} from "$Shared/imports/Yup";

import {
  Opportunity,
  OpportunitySearchCriteria,
  OpportunitySearchCriteriaDateTypeEnum,
  OpportunityStatusEnum
} from "$Generated/api";

import yup from "$Shared/utilities/yupExtension";

export const OpportunityValidationSchema: SchemaOf<NullableOptional<Opportunity>> = yup.object({
  id: yup.number().required(),
  opportunityId: yup.number().notRequired(),
  company: yup.object({
    id: yup.number().required().min(1, "Company is required")
  }),
  customer: yup.object({
    id: yup.number().required().min(1, "Customer is required")
  }),
  leadSource: yup.object({
    linkId: yup.number().required().min(1, "Lead Source is required")
  }),
  status: yup.mixed<OpportunityStatusEnum>().required(),
  closeDate: yup.date()
    .typeError("Invalid date")
    .required("Close Date is required")
    .min(moment().startOf("day").toDate(), "Close Date must be today or later")
    .max(moment().add(1, "years").startOf("day").toDate(), "Close Date cannot be more than one year in the future"),
  confidence: yup.number().notRequired().nullable().min(0).max(100, "Confidence cannot exceed 100%"),
  description: yup.string().required("Description is required").max(300, "Length cannot exceed 300 characters"),
  leadDetails: yup.string().notRequired().allowEmpty().max(100, "Length cannot exceed 100 characters"),
  targetRevenue: yup.number().notRequired().nullable().min(0).max(2000000000, "Target revenue cannot exceed $2 billion."),
  createdBy: yup.object({
    linkId: yup.number().required("Sales Representative is required").min(1, "Sales Representative is required")
  }),
  createdOn: yup.date().notRequired()
});

export const OpportunitySearchCriteriaValidationSchema: SchemaOf<NullableOptional<OpportunitySearchCriteria>> = yup.object({
  customerId: yup.number().notRequired(),
  customerRegionId: yup.number().notRequired(),
  status: yup.mixed<OpportunityStatusEnum[]>().notRequired(),
  createdById: yup.number().notRequired(),
  dateType: yup.mixed<OpportunitySearchCriteriaDateTypeEnum>().notRequired(),
  startDate: yup.date()
    .typeError("Invalid date")
    .notRequired(),
  endDate: yup.date()
    .typeError("Invalid date")
    .notRequired()
    .mustBeAfter("startDate", "To date must be after From date")
});

export const OpportunityRequireDetailsValidationSchema: SchemaOf<NullableOptional<Opportunity>> = OpportunityValidationSchema.concat(
  yup.object({
    leadDetails: yup.string().required("Lead details are required for the lead source").max(100, "Max length cannot exceed 100 characters")
  }));