import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  Helpers
} from "$Imports/Imports";

import {
  RateModelApiFactory,
  RateModel,
  LinearFootCalculation,
  Zone,
  LinearFootRatesCalculationParams,
  ZoneApiFactory
} from "$Generated/api";

import {
  directionType
} from "$Imports/CommonComponents";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

export type formModeType = "edit sandbox" | "apply sandbox" | "reset default" | "edit default" | "none";

export type resetToDefaultDateOption = "immediate" | "scheduled";

interface IRateModelState {
  rateModelFetchResults: IAjaxState<RateModel[]>;
  rateModelEditDefaultRatesResults: IAjaxState<RateModel[]>;
  applySandboxResults: IAjaxState<RateModel[]>;
  resetToDefaultResults: IAjaxState<RateModel[]>;
  rateModelsForEdit: RateModel[] | null;
  formMode: formModeType;
  dataFromServer: RateModel[] | null;
  resetToDefaultDate: Date | null;
  resetToDefaultDateOption: resetToDefaultDateOption | null;
  zoneOptionsFetchResults: IAjaxState<Zone[]>;
  linearFootCalculationFetchResults: IAjaxState<LinearFootCalculation[]>;
  linearFootRowDataRequestData: LinearFootRatesCalculationParams;
  linearFootGridSortState: ISortState;
  invalidForm: boolean;
  invalidRateMessage: string;
}

interface ISortState {
  sortColumnName?: string;
  sortDirection?: directionType;
}

const LinearFootGridRequestCacheKey: string = "LinearFootGridRequestCacheKey";

const InjectedPropName = "rateModelService";

const initialState = {
  formMode: "none",
  rateModelsForEdit: null,
  rateModelFetchResults: managedAjaxUtil.createInitialState(),
  rateModelEditDefaultRatesResults: managedAjaxUtil.createInitialState(),
  applySandboxResults: managedAjaxUtil.createInitialState(),
  resetToDefaultResults: managedAjaxUtil.createInitialState(),
  dataFromServer: null,
  resetToDefaultDate: null,
  resetToDefaultDateOption: null,
  linearFootCalculationFetchResults: managedAjaxUtil.createInitialState(),
  zoneOptionsFetchResults: managedAjaxUtil.createInitialState(),
  linearFootRowDataRequestData: {},
  linearFootGridSortState: {
    sortColumnName: "feet",
    sortDirection: "desc",
  },
  invalidForm: false,
  invalidRateMessage: ""
} as IRateModelState;

class RateModelFreezerService extends FreezerService<IRateModelState, typeof InjectedPropName> {

  private readonly _localStorage = window.localStorage;

  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public setLinearFootGridSortState(sortState: Partial<ISortState>) {
    this.freezer.get().linearFootGridSortState.set(sortState);
  }

  public setLinearFootRowDataRequestData(requestValue: Partial<LinearFootRatesCalculationParams>) {
    this.freezer.get().linearFootRowDataRequestData.set(requestValue);
  }

  public updateRateModels(index: number, rateModel: Partial<RateModel>) {
    const stateArray = this.freezer.get().rateModelsForEdit;
    if (stateArray !== null) {
      stateArray[index].set(rateModel);
    }
  }

  public updateResetToDefaultDate(datetime: Date) {
    this.freezer.get().set({ resetToDefaultDate: datetime });
    const stateArray = this.freezer.get().rateModelsForEdit;
    if (stateArray !== null) {
      _.forEach(stateArray, (value, idx) => value.set({ resetToDefaultOn: datetime }));
    }
  }

  public updateResetToDefaultDateOption(option: resetToDefaultDateOption) {
    this.freezer.get().set({
      resetToDefaultDateOption: option,
      resetToDefaultDate: new Date(),
    });
  }

  public openApplyRateForm(formMode: formModeType) {
    const rateModelsForEdit = this.freezer.get().rateModelFetchResults.data;

    if (rateModelsForEdit) {
      const currTime = new Date();
      this.freezer.get().set({
        rateModelsForEdit: rateModelsForEdit.toJS(),
        resetToDefaultDate: currTime,
        formMode: formMode,
      });

      if (formMode === "reset default") {
        this.freezer.get().set({
          resetToDefaultDateOption: "immediate"
        });

        this.updateResetToDefaultDate(currTime);
      }
    }
  }

  public clearApplyRateForm() {
    this.freezer.get().set({
      resetToDefaultDate: null,
      rateModelsForEdit: null,
      formMode: "none",
    });
  }

  public applySandbox(companyId: number | undefined) {
    const editedData = this.freezer.get().rateModelsForEdit?.toJS();

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "applySandboxResults",
      onExecute: (apiOptions, param, options) => {
        const factory = RateModelApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1RateModelApplySandboxRatesPost(param);
      },
      params: {
        body: editedData,
      },
      onOk: (data) => {
        this.clearApplyRateForm();
        this.fetchRateModels(companyId, true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to apply sandbox rates.");
      }
    });
  }

  public resetToDefault(companyId: number | undefined) {
    const editedData = this.freezer.get().rateModelsForEdit?.toJS();

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "resetToDefaultResults",
      onExecute: (apiOptions, param, options) => {
        const factory = RateModelApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1RateModelResetToDefaultsPost(param);
      },
      params: {
        body: editedData,
      },
      onOk: (data) => {
        this.clearApplyRateForm();
        this.fetchRateModels(companyId, true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to reset default rates.");
      }
    });
  }

  public editRateModels(formMode: formModeType) {
    const rateModelsForEdit = this.freezer.get().rateModelFetchResults.data;

    if (rateModelsForEdit) {
      this.freezer.get().set({
        rateModelsForEdit: rateModelsForEdit.toJS(),
        formMode: formMode,
      });
    }
  }

  public clearEditRateModelsForm() {
    this.freezer.get().set({
      rateModelsForEdit: null,
      formMode: "none",
      invalidForm: false,
      invalidRateMessage: ""
    });
  }

  private validateEditedRates(rateModels: RateModel[]): boolean {
    const formMode = this.freezer.get().formMode;

    // if editing sandbox and some sandbox rates are present
    if (formMode == "edit sandbox" && rateModels.some(r => !Helpers.isNullOrUndefined(r.sandboxRate))) {
      // then every sandbox rate must be present
      if (!rateModels.every(r => !Helpers.isNullOrUndefined(r.sandboxRate))) {
        this.freezer.get().set({
          invalidForm: true,
          invalidRateMessage: "All sandbox rates must be filled out."
        });
        return false;
      }
    }

    // if editing default and any rates are empty
    if (formMode == "edit default" && rateModels.some(r => Helpers.isNullOrUndefined(r.defaultRate))) {
      this.freezer.get().set({
        invalidForm: true,
        invalidRateMessage: "All default rates must be filled out."
      });
      return false;
    }

    let i = 0;
    for (i = 0; i < rateModels.length; i++) {
      if (this.isRateInvalid(rateModels[i])) {
        this.freezer.get().set({
          invalidForm: true,
          invalidRateMessage: "The rate percentages must be in ascending order and can not be equal."
        });
        return false;
      }
    }
    return true;
  }

  // smaller feet should have larger rate, larger feet should have smaller rate
  public isRateInvalid(rate: RateModel): boolean {
    const rateModels = this.freezer.get().rateModelsForEdit?.toJS();
    if (rateModels !== undefined) {
      if (rate !== undefined && rate !== null && rate.feet) {
        let i = 0;
        for (i = 0; i < rateModels.length; i++) {
          const rateRecord = rateModels[i];
          if (rateRecord.feet) {
            // validate sandbox
            if (rateRecord.sandboxRate && rate.sandboxRate) {
              if (rateRecord.feet < rate.feet && rateRecord.sandboxRate <= rate.sandboxRate) {
                return true;
              }
            }
            // validate default
            if (rateRecord.defaultRate && rate.defaultRate) {
              if (rateRecord.feet < rate.feet && rateRecord.defaultRate <= rate.defaultRate) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }

  public saveRateModels(companyId: number | undefined) {
    const editedData = this.freezer.get().rateModelsForEdit?.toJS();
    if (editedData != undefined && this.validateEditedRates(editedData)) {
      managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        ajaxStateProperty: "rateModelEditDefaultRatesResults",
        onExecute: (apiOptions, param, options) => {
          const factory = RateModelApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return factory.apiV1RateModelUpdateRateModelDefaultRatesPost(param);
        },
        params: {
          body: editedData,
        },
        onOk: (data) => {
          this.clearEditRateModelsForm();
          this.fetchRateModels(companyId, true);
        },
        onError: (err, errorMessage) => {
          ErrorService.pushErrorMessage("Failed to save rate models.");
        }
      });
    }
  }

  public fetchRateModels(companyId: number | undefined, forceUpdate: boolean = false) {
    const { 
      rateModelFetchResults
    } = this.freezer.get();

    if ((rateModelFetchResults.hasFetched && !forceUpdate) || !companyId) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "rateModelFetchResults",
      params: {
        "companyId": companyId
      },
      onExecute: (apiOptions, param, options) => {
        const factory = RateModelApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1RateModelCompanyIdGet(param);
      },
      onOk: (data: RateModel[]) => {
        this.freezer.get().set({
          dataFromServer: data,
        });
        return _.orderBy(data, x => x.feet, 'desc');
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch rate models.");
      }
    });
  }

  public fetchZoneOptions(companyId: number | undefined, forceUpdate: boolean = false) {
    const { 
      zoneOptionsFetchResults
    } = this.freezer.get();

    if (zoneOptionsFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "zoneOptionsFetchResults",
      onExecute: (apiOptions, param, options) => {
        const factory = ZoneApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1ZoneGet();
      },
      onOk: (data: Zone[]) => {

        let requestData: LinearFootRatesCalculationParams = {
          originZoneId: data[0].id,
          destinationZoneId: data[0].id
        };

        const cachedRequestData = this._localStorage.getItem(LinearFootGridRequestCacheKey);
        if (cachedRequestData !== null) {
          requestData = JSON.parse(cachedRequestData);
        }
        this.freezer.get().linearFootRowDataRequestData.set(requestData);
        this.fetchLinearFootCalculation(companyId, true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch zone options.");
      }
    });
  }

  public fetchLinearFootCalculation(companyId: number | undefined, forceUpdate: boolean = false) {
    const { 
      linearFootCalculationFetchResults, 
      linearFootRowDataRequestData
    } = this.freezer.get();

    if (linearFootCalculationFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    const requestData = linearFootRowDataRequestData.toJS();

    requestData.companyId = companyId;

    this._localStorage.setItem(LinearFootGridRequestCacheKey, JSON.stringify(requestData));

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "linearFootCalculationFetchResults",
      onExecute: (apiOptions, params, options) => {
        const factory = RateModelApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1RateModelGetLinearFootGridDataPost(params);
      },
      params: {
        body: requestData
      },
      onError: (err, errorMessage) => {
        const serverMessage = err?.body?.message;
        if (serverMessage && serverMessage == "PCMiler is unavailable") {
          ErrorService.pushErrorMessage("PC Miler is not available. Contact IT Support", "high", "Retry", () => this.fetchLinearFootCalculation(companyId, true));
        }
        else if (serverMessage && serverMessage.includes("PCMiler")) {
          ErrorService.pushErrorMessage(serverMessage);
        }
        else {
          ErrorService.pushErrorMessage("Failed to fetch table data.");
        }
      }
    });
  }
}

export const RateModelService = new RateModelFreezerService();
export type IRateModelServiceInjectedProps = ReturnType<RateModelFreezerService["getPropsForInjection"]>;
