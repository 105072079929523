import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  Helpers,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  ISortState
} from "$Imports/CommonComponents";

import {
  DeclaredValueApiFactory,
  DeclaredValueApproval
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

interface IDeclaredValueModalState {
  isOpen: boolean;
  newRecord: DeclaredValueApproval;
  validationErrors: ValidationError | null;
}

interface IDeclaredValueServiceState {
  declaredValueFetchResults: IAjaxState<DeclaredValueApproval[]>;
  sortState: ISortState;
  declaredValueModalState: IDeclaredValueModalState;
  declaredValueSaveResults: IAjaxState;
}

const DeclaredValueValidationSchema: SchemaOf<NullableOptional<DeclaredValueApproval>> = yup.object({
  id: yup.number().notRequired(),
  declaredValue: yup.number()
    .required("Declared Value is required"),
  startDateTime: yup.date().notRequired(),
  endDateTime: yup.date().nullable().notRequired(),
  createdById: yup.number().notRequired(),
  createdBy: yup.mixed().nullable().notRequired(),
  companyId: yup.number().notRequired(),
  company: yup.mixed().nullable().notRequired(),
});

const InjectedPropName = "declaredValueService";

const initialState = {
  declaredValueFetchResults: managedAjaxUtil.createInitialState(),
  declaredValueSaveResults: managedAjaxUtil.createInitialState(),
  sortState: {
    sortColumnName: "endDateTime",
    sortDirection: "desc",
  },
  declaredValueModalState: {
    isOpen: false,
    newRecord: {},
    validationErrors: null
  }
} as IDeclaredValueServiceState;

class DeclaredValueFreezerService extends FreezerService<IDeclaredValueServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchData() {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "declaredValueFetchResults",
      params: {},
      onExecute: (apiOptions, params, options) => {
        const factory = DeclaredValueApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1DeclaredValueApprovalsGet();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch declared value approvals.");
      }
    });
  }

  public getThresholdForCompany(companyId: number): DeclaredValueApproval | undefined {
    const declaredValueFetchResults = this.freezer.get().declaredValueFetchResults.toJS();
    if (declaredValueFetchResults.data) {
      return _.find(declaredValueFetchResults.data, (x) => x.companyId === companyId && Helpers.isNullOrUndefined(x.endDateTime));
    }

    return undefined;
  }

  public setSortState(sortState: Partial<ISortState>) {
    this.freezer.get().sortState.set(sortState);
  }

  public openEditModal(declaredValue: DeclaredValueApproval) {
    this.freezer.get().set({
      declaredValueModalState: {
        isOpen: true,
        newRecord: {
          declaredValue: declaredValue.declaredValue,
          companyId: declaredValue.companyId,
          company: declaredValue.company
        },
        validationErrors: null
      }
    });
  }

  public closeEditModal() {
    this.freezer.get().set({
      declaredValueModalState: {
        isOpen: false,
        newRecord: {},
        validationErrors: null
      }
    });
  }

  public async saveDeclaredValue() {
    const declaredValueModalState = this.freezer.get().declaredValueModalState;
    const declaredValue = declaredValueModalState.newRecord.toJS();

    const errors = await validateSchema(DeclaredValueValidationSchema, declaredValue, {
      abortEarly: false
    });

    declaredValueModalState.set({
      validationErrors: errors
    });

    if (errors) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "declaredValueSaveResults",
      params: {
        body: declaredValue,
      },
      onExecute: (apiOptions, param, options) => {
        const factory = DeclaredValueApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1DeclaredValueAddPut(param);
      },
      onOk: (data) => {
        this.closeEditModal();
        this.fetchData();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save declared value approval record.");
      }
    });
  }

  public onChangeFromModal(value: Partial<DeclaredValueApproval>) {
    this.freezer.get().declaredValueModalState.newRecord.set(value);
  }
}

export const DeclaredValueService = new DeclaredValueFreezerService();
export type IDeclaredValueServiceInjectedProps = ReturnType<DeclaredValueFreezerService["getPropsForInjection"]>;
