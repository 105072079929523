import {
  React,
  bind
} from "$Imports/Imports";

import {
  SimplifiedCustomerQuote,
  QuoteStatusEnum
} from "$Generated/api";

import {
  AjaxActionIndicator,
  DisplayFormattedDatetime,
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  Card,
  CardHeader,
  Button,
  GridValueFormatterParams,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  DataGridPro,
  CardActions,
  Icon
} from "$Imports/MaterialUIComponents";

import {
  ICustomerQuoteServiceInjectedProps,
  CustomerQuoteService,
  DEFAULT_PORTAL_QUOTE_SEARCH
} from "$State/CustomerQuotesFreezerService";

import {
  IEmployeeServiceInjectedProps,
  EmployeeService
} from "$State/EmployeeFreezerService"

import {
  SalesRepHomeService,
  ISalesRepHomeServiceInjectedProps
} from "$State/SalesRepHomeFreezerService";

import {
  CURRENCY_FORMAT,
  DATE_WITH_TIME_MERIDIAN_FORMAT,
  NUMERIC_SEPARATED_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  openCustomerQuotePreview
} from "$Utilities/windowUtil";

import {
  CustomerQuoteSearchForm
} from "./CustomerQuoteSearchForm";

import {
  Check
} from "$Imports/MaterialUIIcons";

import {
  getDeliveryDateColumn
} from "$Utilities/quoteColConstants";

const styles: {
  mainContainer: string;
  cardStyle: string;
  resultsGrid: string;
  resultsMessage: string;
  resultCell: string;
  icon: string;
  reviewed: string;
} = require("./CustomerQuotesListView.scss");

interface ICustomerQuotesListViewBaseProps {
  companyId: number | undefined;
}

type ICustomerQuotesListViewProps = ICustomerQuotesListViewBaseProps
  & ICustomerQuoteServiceInjectedProps
  & IEmployeeServiceInjectedProps
  & ISalesRepHomeServiceInjectedProps;

export class _CustomerQuotesListView extends React.Component<ICustomerQuotesListViewProps> {
  private readonly columns: GridColDef[] = [{
    headerName: "Quote #",
    field: "quoteNumber",
    valueFormatter: (params: GridValueFormatterParams) => `EQ${params.value}`,
    width: 80
  }, {
    headerName: "Sales Rep",
    field: "salesRep",
    width: 140
  }, {
    headerName: "Customer Name",
    field: "customerName",
    flex: 1
  }, {
    headerName: "Quote Date",
    field: "quoteDate",
    renderCell: (params: GridRenderCellParams<Date | undefined>) =>
      <DisplayFormattedDatetime value={params.value} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} showTimeZone />,
    width: 200
  }, {
    headerName: "PO #",
    field: "poNumber",
    width: 80
  }, {
    headerName: "Rate",
    field: "rate",
    renderCell: (params: GridRenderCellParams<number | undefined>) =>
      <DisplayFormattedNumber value={params.value} formatString={CURRENCY_FORMAT} />,
    width: 110
  }, {
    headerName: "Ft / K",
    field: "ftk",
    renderCell: (params: GridRenderCellParams<any, SimplifiedCustomerQuote>) => {
      let unit = "";
      let value = params.row.rateVariable;

      if (params.row.rateVariableFactor === "Weight") {
        unit = " lbs";
        if (value) {
          value *= 1000;
        }
      } else if (params.row.rateVariableFactor === "Length") {
        unit = " ft";
      } else {
        value = 0
      }
      return value ? <DisplayFormattedNumber value={value} formatString={NUMERIC_SEPARATED_FORMAT} postfix={unit} /> : "Over dimensional"
    },
    sortable: false,
    width: 60
  }, {
    headerName: "Miles",
    field: "miles",
    renderCell: (params: GridRenderCellParams<number | undefined>) =>
      params.value ? <DisplayFormattedNumber value={params.value} formatString={NUMERIC_SEPARATED_FORMAT} /> : "",
    width: 80
  },
  getDeliveryDateColumn("Delivery Date", true),
  {
    headerName: "Status",
    field: "quoteStatus",
    valueFormatter: (params: GridValueFormatterParams) => {
      switch (params.value as QuoteStatusEnum) {
        case "ApprovalNeeded":
          return "Approval Needed";
        case "InProgress":
          return "In Progress";
        case "PendingNeedsCustomers":
          return "Pending";
        case "AcceptanceRejected":
          return "Acceptance Rejected";
        default:
          return params.value;
      }
    },
    width: 100
  }, {
    headerName: "",
    field: "action",
    renderCell: (params: GridRenderCellParams<any, SimplifiedCustomerQuote>) =>
      <>
        <Button
          color="primary"
          onClick={() => this._onViewClick(params.row)}
        >
          View
        </Button>
        {params.row.isReviewed && (
          <Icon title="Reviewed" className={styles.icon}>
            <Check className={styles.reviewed} />
          </Icon>
        )}
      </>,
    width: 160,
    sortable: false
  }];

  componentDidMount() {
    this.props.customerQuotesService.onResetSearchModel({companyId: this.props.companyId, ...DEFAULT_PORTAL_QUOTE_SEARCH});
    
    this.props.employeeService.fetchSalesReps();
    this.props.customerQuotesService.fetchQuotes(this.props.companyId, true);
    this.props.salesRepHomeService.fetchSimplifiedCustomers(this.props.companyId, true);
  }

  
  componentDidUpdate(prevProps: Readonly<ICustomerQuotesListViewProps>) {
    if (this.props.companyId !== prevProps.companyId) {
      this.props.customerQuotesService.fetchQuotes(this.props.companyId, true);
      this.props.salesRepHomeService.fetchSimplifiedCustomers(this.props.companyId, true);
    }
  }

  componentWillUnmount(): void {
    this.props.customerQuotesService.onResetSearchModel(undefined, true);
  }

  @bind
  private _onViewClick(quote: SimplifiedCustomerQuote) {
    if (quote.id) {
      openCustomerQuotePreview(quote.id)
    }
  }

  @bind
  private _onSortChange(sortModel: GridSortModel) {
    this.props.customerQuotesService.onSearchModelChanged({
      sortColumn: sortModel[0].field,
      sortAscending: sortModel[0].sort === "asc"
    });

    this.props.customerQuotesService.fetchQuotes(this.props.companyId, true);
  }

  render() {

    const {
      searchCriteria,
      quotesFetchResults
    } = this.props.customerQuotesService.getState();

    const {
      salesRepFetchResults
    } = this.props.employeeService.getState();

    const quoteData = quotesFetchResults.data?.results ?? [];

    const sortModel: GridSortModel = [{
      field: searchCriteria.sortColumn ?? "quoteDate",
      sort: searchCriteria.sortAscending ? "asc" : "desc"
    }];

    return (
      <div className={styles.mainContainer}>
        <Card className={styles.cardStyle}>
          <CardHeader
            title="Customer Portal Quotes"
          />

          <CardActions disableSpacing>
            <CustomerQuoteSearchForm companyId={this.props.companyId}/>
          </CardActions>

          <AjaxActionIndicator
            state={[quotesFetchResults, salesRepFetchResults]}
          />

          <div className={styles.resultsGrid}>
            {(quotesFetchResults.hasFetched && (quotesFetchResults.data?.totalRecords ?? 0) > (quotesFetchResults.data?.numberOfRecords ?? 0)) ? (
              <div className={styles.resultsMessage}>
                {quotesFetchResults.data?.totalRecords} results found, {quotesFetchResults.data?.numberOfRecords} shown - please refine your search.
              </div>
            ) : undefined}

            <DataGridPro
              columns={this.columns}
              rows={quoteData}
              classes={{
                cell: styles.resultCell
              }}
              density="compact"
              initialState={{
                sorting: { sortModel: sortModel }
              }}
              sortingMode="server"
              sortingOrder={["asc", "desc"]}
              getRowHeight={() => 64}
              onSortModelChange={this._onSortChange}
              disableColumnFilter
              disableColumnMenu
              disableSelectionOnClick
              hideFooter
            />
          </div>
        </Card>
      </div>
    );
  }
}

export const CustomerQuotesListView = EmployeeService.inject(
  CustomerQuoteService.inject(
    SalesRepHomeService.inject(
      _CustomerQuotesListView
    )
  )
);