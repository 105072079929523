import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional,
  moment
} from "$Imports/Imports";

import {
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  Customer,
  CustomerApiFactory,
  CustomerContact,
  CustomerHour,
  CustomerHourDayOfWeekEnum,
  Employee,
  NameSuffix,
  Activity,
  ContactType,
  QuoteSearchCriteria,
  CustomersQuotesSearchResult,
  CustomerActivityResult,
  CustomerActivityCriteria,
  AuditCustomerAuditLog,
  AuditLogApiFactory,
  CommodityExclusion,
  ActivityActivityTypeEnum
} from "$Generated/api";

import {
  ISortState
} from "$Imports/CommonComponents";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import yup from "$Shared/utilities/yupExtension";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  QuoteService
} from "./QuoteFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

export const CustomerContactValidationSchema: SchemaOf<NullableOptional<CustomerContact>> = yup.object({
  id: yup.number().notRequired(),
  customerId: yup.number().notRequired(), // "not required" in the sense of not user-editable
  customerUserId: yup.number().notRequired().allowNaN().nullable(),
  firstName: yup.string()
    .required("First name is required")
    .max(150, "First name cannot be longer than 150 characters"),
  lastName: yup.string()
    .required("Last name is required")
    .max(150, "Last name cannot be longer than 150 characters"),
  nameSuffixId: yup.number()
    .transform((value: any) => value || undefined) // otherwise it complains about NaN
    .notRequired()
    .nullable(),
  emailAddress: yup.string()
    .when("$emailRequired", (isEmailRequired: boolean | undefined, schema: any) => 
        isEmailRequired
          ? schema.required("Email is required")
          : schema.notRequired()
    ).email("Invalid email").max(250, "Max length can not exceed 250 characters"),
  phoneNumber: yup.string()
    .required("Phone number is required")
    .phoneNumber("Invalid phone number")
    .max(20, "Phone number cannot exceed 20 characters"),
  cellNumber: yup.string()
    .notRequired()
    .phoneNumber("Invalid cell number")
    .max(20, "Cell number cannot exceed 20 characters")
    .nullable(),
  title: yup.string()
    .required("Title is required")
    .max(60, "Title cannot be longer than 60 characters"),
  notes: yup.string()
    .notRequired()
    .nullable()
    .allowEmpty()
    .max(250, "Notes cannot be longer than 250 characters"),
  contactTypeId: yup.number()
    .required("Contact type is required"),
  isPrimary: yup.boolean().notRequired(), // only one primary enforced serverside
  isActive: yup.boolean().notRequired(),
  isHidden: yup.boolean().notRequired(),
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  // related objects
  contactType: yup.object().notRequired().nullable(),
  customer: yup.object().notRequired().nullable(),
  customerUser: yup.object().notRequired().nullable(),
  nameSuffix: yup.object().notRequired().nullable()
});

// searchString StringSchema - see github issues on jquense/yup:
// https://github.com/jquense/yup/issues/1367
// https://github.com/jquense/yup/issues/1510
const NoteFilterValidationSchema: SchemaOf<INoteSearchState> = yup.object({
  searchString: yup.string().notRequired().allowEmpty()
    .max(300, "Maximum of 300 characters"),
  authorId: yup.number().required(), // it'll be 0 if nothing is selected
  fromDate: yup.date().notRequired()
    .typeError("Invalid Date")
    .transform((value: any) => value && !isNaN(value.valueOf()) ? value : undefined),
  toDate: yup.date()
    .typeError("Invalid Date")
    .transform((value: any) => value && !isNaN(value.valueOf()) ? value : undefined)
    .when('fromDate', (fromDate: Date, schema: any) => {
      return fromDate ? schema.min(fromDate, "The To Date must be after the From Date")
        : schema.notRequired();
    })
});

export const ActivityValidationSchema: SchemaOf<NullableOptional<Activity>> = yup.object({  
  id: yup.number().notRequired(),
  customerId: yup.number().notRequired(),
  customer: yup.object().nullable().notRequired(),
  activityType: yup.mixed<ActivityActivityTypeEnum>()
    .required("Type is required"),
  noteText: yup.string()
    .required("Notes are required")
    .max(300, "Notes can not exceed 300 characters"),
  createdById: yup.number().nullable().notRequired(),
  createdBy: yup.object().nullable().notRequired(),
  createdOn: yup.date()
  .typeError("Invalid date")
  .required("Date is required")
  .test("createdOn", "${message}", (value: any, testContext: any) => {
    const timeEmpty = testContext.options.context.timeEmpty;
    return timeEmpty ? testContext.createError({ message: "Time is required" }) : true;
  }),
  isPinned: yup.boolean().required(),
  isActive: yup.boolean().required(),
});

interface ICustomerDetailModalState {
  isOpen: boolean;
  editCustomer: Customer | undefined;
}

interface IContactModalState {
  isOpen: boolean;
  editContact: CustomerContact | undefined;
  validationErrors: ValidationError | null;
  isEmailRequired: boolean;
}

export interface INoteSearchState {
  searchString?: string;
  authorId: number;
  fromDate: Date | undefined;
  toDate: Date | undefined;
}

interface ICustomerDetailServiceState {
  customerDetailFetchResults: IAjaxState<Customer>;
  contactsFetchResults: IAjaxState<CustomerContact[]>;
  nameSuffixFetchResults: IAjaxState<NameSuffix[]>;
  contactTypeFetchResults: IAjaxState<ContactType[]>;
  saveCustomerResults: IAjaxState<Customer>;
  saveContactResults: IAjaxState;
  detailModalState: ICustomerDetailModalState;
  contactModalState: IContactModalState;
  contactSortState: ISortState;
  quoteSearchCriteria: QuoteSearchCriteria | undefined;
  quoteFetchResults: IAjaxState<CustomersQuotesSearchResult>;
  quoteSearchCriteriaValidationErrors: ValidationError | null;
  auditLogFetchResults: IAjaxState<AuditCustomerAuditLog[]>;
  // notes
  notesFetchResults: IAjaxState<Activity[]>;
  allNotes: Activity[];
  filteredNotes: Activity[];
  noteSaveResults: IAjaxState<Activity>;
  noteSearchState: INoteSearchState;
  noteSearchValidationErrors: ValidationError | null;
  addNoteText: string;
  noteAuthors: Employee[];
  editNote: Activity | undefined;
  customerActivityFetchResults: IAjaxState<CustomerActivityResult>;
  // activities
  activities: Activity[];
  editActivity: Activity | undefined;
  activitySaveResults: IAjaxState<Activity>;
  // customer commodityExclusions
  commodityExclusionsFetchResults: IAjaxState<CommodityExclusion[]>;
  commodityExclusionResults: IAjaxState<boolean>;
}

const InjectedPropName = "customerDetailService";

const initialState = {
  customerDetailFetchResults: managedAjaxUtil.createInitialState(),
  contactsFetchResults: managedAjaxUtil.createInitialState(),
  nameSuffixFetchResults: managedAjaxUtil.createInitialState(),
  contactTypeFetchResults: managedAjaxUtil.createInitialState(),
  saveCustomerResults: managedAjaxUtil.createInitialState(),
  saveContactResults: managedAjaxUtil.createInitialState(),
  detailModalState: {
    isOpen: false,
    editCustomer: undefined,
  },
  contactModalState: {
    isOpen: false,
    editContact: undefined,
    validationErrors: null,
    isEmailRequired: false
  },
  contactSortState: {
    sortColumnName: "name",
    sortDirection: "asc",
  },
  quoteSearchCriteria: {
    customerId: undefined,
    pageSize: 1000,
    startIndex: 0,
    statuses: ["ApprovalNeeded", "InProgress", "Pending", "Requested"]
  },
  quoteFetchResults: managedAjaxUtil.createInitialState(),
  quoteSearchCriteriaValidationErrors: null,
  notesFetchResults: managedAjaxUtil.createInitialState(),
  allNotes: [],
  filteredNotes: [],
  addNoteText: "",
  noteSaveResults: managedAjaxUtil.createInitialState(),
  noteAuthors: [],
  noteSearchState: {
    searchString: "",
    authorId: 0,
    fromDate: undefined,
    toDate: undefined
  },
  noteSearchValidationErrors: null,
  editNote: undefined,
  activities:[],
  editActivity: undefined,
  activitySaveResults: managedAjaxUtil.createInitialState(),
  customerActivityFetchResults: managedAjaxUtil.createInitialState(),
  auditLogFetchResults: managedAjaxUtil.createInitialState(),
  commodityExclusionsFetchResults: managedAjaxUtil.createInitialState(),
  commodityExclusionResults: managedAjaxUtil.createInitialState()
} as ICustomerDetailServiceState;

class CustomerDetailFreezerService extends FreezerService<ICustomerDetailServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public async fetchNameSuffixes(forceUpdate: boolean = false) {
    const { nameSuffixFetchResults } = this.freezer.get();

    if (nameSuffixFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "nameSuffixFetchResults",
      params: {},
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getNameSuffixes();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch name suffix data.");
      }
    });
  }

  public async fetchContactTypes(forceUpdate: boolean = false) {
    const { contactTypeFetchResults } = this.freezer.get();

    if (contactTypeFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "contactTypeFetchResults",
      params: {},
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getContactTypes();
      },
      onError: (err, errMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch contact type data.");
      }
    });
  }

  public async fetchCustomerDetailData(customerId: number, forceUpdate: boolean = false) {
    const { customerDetailFetchResults } = this.freezer.get();

    if (customerDetailFetchResults.hasFetched && customerDetailFetchResults.data?.id === customerId && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "customerDetailFetchResults",
      params: {
        customerId
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getCustomerById(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch customer data.");
      }
    });
  }

  public fetchCustomerContacts(customerId: number, forceUpdate: boolean = false, showInactive: boolean = false) {
    const { contactsFetchResults } = this.freezer.get();

    if (contactsFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "contactsFetchResults",
      params: {
        customerId,
        activeOnly: !showInactive
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getContactsForCustomer(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch customer contacts.");
      }
    });
  }

  public fetchCustomerNotes(customerId: number, forceUpdate: boolean = false) {
    const {
      notesFetchResults
    } = this.freezer.get();

    if (notesFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "notesFetchResults",
      params: {
        customerId
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getNotesForCustomer(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch customer notes.");
      },
      onOk: (data: Activity[]) => {
        // available note authors
        let noteAuthors = _.uniqWith(
          _.compact(
            _.map(
              data, n => n.createdBy
            )
          ),
          (e1, e2) => e1?.id === e2?.id
        );

        const notes = _.filter(data, n => n.activityType === "Note");
        const activities = _.filter(data, n => n.activityType !== "Note");

        this.freezer.get().set({
          noteAuthors,
          noteSearchState: initialState.noteSearchState,
          allNotes: notes,
          filteredNotes: notes,
          activities: activities
        });
      }
    });
  }

  public async saveCustomer(customer?: Customer) {
    let editCustomer = customer ?? this.freezer.get().detailModalState.editCustomer?.toJS();

    if (editCustomer && editCustomer.id) {
      managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        ajaxStateProperty: "saveCustomerResults",
        params: {
          body: editCustomer
        },
        onExecute: (apiOptions, params, options) => {
          const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return factory.updateCustomer(params);
        },
        onError: (err, errorMessage) => {
          ErrorService.pushErrorMessage("Failed to save updated customer.");
        },
        onOk: (data: Customer) => {
          if (data.id) {
            this.fetchCustomerDetailData(data.id, true);
            this.fetchAuditLogs(data.id);
          }
        }
      });
    }

    this.closeDetailModal();
  }

  public openDetailModal() {
    const customerDetailFetchResults = this.freezer.get().customerDetailFetchResults.toJS();
    const customerData = customerDetailFetchResults.data;

    if (customerData?.customerHours) {
      customerData.customerHours = _.map(customerData.customerHours, (h) => ({
        ...h,
        openTime: h.openTime ? h.openTime.substring(0, 5) : h.openTime,
        closeTime: h.closeTime ? h.closeTime.substring(0, 5) : h.closeTime
      }));
    }

    if (customerData) {
      this.freezer.get().set({
        detailModalState: {
          isOpen: true,
          editCustomer: customerData
        }
      });
    }
  }

  public customerOnChange(updatedCustomer: Partial<Customer>) {
    this.freezer.get().detailModalState.editCustomer?.set(updatedCustomer);
  }

  public customerHoursOnChange(day: CustomerHourDayOfWeekEnum, businessHours: Partial<CustomerHour>) {
    var idx = _.findIndex(this.freezer.get().detailModalState.editCustomer?.customerHours, h => h.dayOfWeek === day);
    if (idx >= 0) {
      this.freezer.get().detailModalState.editCustomer?.customerHours?.[idx].set(businessHours);
    }
    else {
      this.freezer.get().detailModalState.editCustomer?.customerHours?.push({
        dayOfWeek: day,
        customerId: this.freezer.get().detailModalState.editCustomer?.id,
        ...businessHours
      });
    }
  }

  public customerLoadingInstructionsOnChange(loadingInstructionId: number) {
    const loadingInstructions = this.freezer.get().detailModalState.editCustomer?.customerLoadingInstructions;
    const idx = _.findIndex(loadingInstructions, i => i.loadingInstructionId === loadingInstructionId);
    if (idx >= 0) {
      loadingInstructions?.splice(idx, 1);
    }
    else {
      loadingInstructions?.push({
        loadingInstructionId: loadingInstructionId,
        customerId: this.freezer.get().detailModalState.editCustomer?.id
      });
    }
  }

  public closeDetailModal() {
    this.freezer.get().set({
      detailModalState: initialState.detailModalState
    });
  }

  public async fetchCustomerQuotes(customerId: number | undefined, forceUpdate: boolean = false) {

    const {
      quoteFetchResults,
      quoteSearchCriteria
    } = this.freezer.get().toJS();

    if (quoteFetchResults.hasFetched && !forceUpdate || !customerId) {
      return;
    }

    if (quoteSearchCriteria) {
      quoteSearchCriteria.customerId = customerId;

      const validationErrors = await QuoteService.validateSearchModel(quoteSearchCriteria);
      this.freezer.get().set({ quoteSearchCriteriaValidationErrors: validationErrors });
      if (validationErrors) {
        return;
      }
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quoteFetchResults",
      params: {
        body: quoteSearchCriteria
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        // post is update, put is create, and we should consider making swagger generate human-readable names
        return factory.getQuotesByCustomer(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch customer's quotes");
      }
    });
  }

  public openCustomerContactModal(contact?: CustomerContact) {
    const customer = this.freezer.get().customerDetailFetchResults.data?.toJS();

    this.freezer.get().set({
      contactModalState: {
        isOpen: true,
        isEmailRequired: (customer?.isCaller || customer?.isProspect) ?? false,
        editContact: contact !== undefined ? _.clone(contact) :
        {
          customerId: customer?.id,
          isActive: true
        },
        validationErrors: null
      }
    });
  }

  public closeCustomerContactModal() {
    this.freezer.get().set({
      contactModalState: initialState.contactModalState
    });
  }

  public setContactSortState(sortState: Partial<ISortState>) {
    this.freezer.get().contactSortState.set(sortState);
  }

  public contactOnChange(updatedContact: Partial<CustomerContact>) {
    this.freezer.get().contactModalState.editContact?.set(updatedContact);
  }

  public updateQuoteSearchCriteria(updatedCriteria: Partial<QuoteSearchCriteria>) {
    this.freezer.get().quoteSearchCriteria?.set(updatedCriteria);
  }

  public searchPage(customerId: number | undefined, newPage: number) {
    const criteria = this.freezer.get().quoteSearchCriteria?.toJS();

    this.updateQuoteSearchCriteria({ startIndex: (newPage - 1) * (criteria?.pageSize ?? 0) });
    this.fetchCustomerQuotes(customerId, true);
  }

  public async saveCustomerContact() {
    const editContact = this.freezer.get().contactModalState.editContact?.toJS();
    const isEmailRequired = this.freezer.get().contactModalState.isEmailRequired;

    const errors = await validateSchema(CustomerContactValidationSchema, editContact, {
      abortEarly: false,
      context: { emailRequired: isEmailRequired}
    })

    this.freezer.get().contactModalState?.set({ validationErrors: errors });

    if (errors) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "saveContactResults",
      params: {
        body: editContact
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        if (editContact?.id) {
          return factory.updateContact(params);
        } else {
          return factory.addContact(params);
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to add customer contact.");
      },
      onOk: () => {
        if (editContact?.customerId) {
          this.fetchCustomerContacts(editContact.customerId, true);
          this.fetchAuditLogs(editContact.customerId);
        }
      }
    });

    this.closeCustomerContactModal();
  }

  public async setPrimaryContact(contact: CustomerContact) {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "saveContactResults",
      params: {
        body: contact
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.setPrimaryContact(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to set primary contact.");
      },
      onOk: () => {
        if (contact.customerId) {
          this.fetchCustomerContacts(contact.customerId, true);
          this.fetchAuditLogs(contact.customerId);
        }
      }
    });
  }

  @bind
  public saveActivity(model: Activity) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "activitySaveResults",
      params: {
        body: model
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return !model.id ? factory.createNote(params) : factory.editNote(params);
      },
      onOk: () => {
        if (model.customerId) {
          this.fetchCustomerNotes(model.customerId, true);
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save activity.");
      }
    });
  }

  public createNote(customerId: number) {
    let { addNoteText } = this.freezer.get();
    addNoteText = addNoteText.trim();

    if (!addNoteText || addNoteText.length > 300) {
      return;
    }

    this.createNoteWithText(customerId, addNoteText);
  }

  public createNoteWithText(customerId: number, noteText: string) {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "noteSaveResults",
      params: {
        body: {
          customerId: customerId,
          noteText: noteText,
          isActive: true,
          activityType: "Note"
        } as Activity
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.createNote(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save note.");
      },
      onOk: () => {
        this.fetchCustomerNotes(customerId, true);
        this.fetchAuditLogs(customerId);
        this.freezer.get().set({ addNoteText: "" });
      }
    });
  }

  public togglePinOnNote(note: Activity) {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "noteSaveResults",
      params: {
        body: note
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.togglePinnedStatus(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save note.");
      },
      onOk: (data: Activity) => {
        const frozenNote = _.find(this.freezer.get().allNotes, (n) => n.id === data.id);
        frozenNote?.set(data);

        const frozenFilter = _.find(this.freezer.get().filteredNotes, (n) => n.id === data.id);
        frozenFilter?.set(data);
      }
    });
  }

  public deleteNote(note: Activity) {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "noteSaveResults",
      params: {
        noteId: note.id ?? 0
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.deleteNote(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to delete note.");
      },
      onOk: () => {
        this.fetchCustomerNotes(note.customerId ?? 0, true);
        this.fetchAuditLogs(note.customerId ?? 0);
      }
    });
  }

  public saveEditNote() {
    const editNote = this.freezer.get().editNote?.toJS();

    if (editNote === undefined) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "noteSaveResults",
      params: {
        body: editNote
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.editNote(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save note.");
      },
      onOk: () => {
        if (editNote.customerId) {
          this.fetchCustomerNotes(editNote.customerId, true);
          this.fetchAuditLogs(editNote.customerId);
        }
        this.freezer.get().set({ editNote: undefined });
      }
    });
  }

  public editNoteOnChange(note: Partial<Activity>) {
    this.freezer.get().editNote?.set(note);
  }

  public openEditNoteModal(note: Activity) {
    this.freezer.get().set({
      editNote: _.cloneDeep(note)
    });
  }

  public cancelEditNoteModal() {
    this.freezer.get().set({
      editNote: undefined
    });
  }

  public addNoteTextChange(noteText: string) {
    this.freezer.get().set({ addNoteText: noteText });
  }

  public setNoteSearchCriteria(search: Partial<INoteSearchState>) {
    this.freezer.get().noteSearchState.set(search);
  }

  public clearNoteSearch() {
    const { allNotes } = this.freezer.get();

    this.freezer.get().set({
      noteSearchState: initialState.noteSearchState,
      filteredNotes: allNotes.toJS() ?? [],
      noteSearchValidationErrors: null
    });
  }

  public async filterNotes() {
    const {
      noteSearchState,
      allNotes
    } = this.freezer.get().toJS();
    
    const errors = await validateSchema(NoteFilterValidationSchema, noteSearchState, {
      abortEarly: false
    });
    
    this.freezer.get().set({ noteSearchValidationErrors: errors });

    if (errors) {
      return;
    }

    let filteredNotes = allNotes ?? [];

    if (!_.isEqual(noteSearchState, initialState.noteSearchState)) {
      filteredNotes = _.filter(filteredNotes, n => {
        let isMatch = true;

        if (noteSearchState.searchString && n.noteText) {
          isMatch = isMatch && n.noteText.toLowerCase().includes(noteSearchState.searchString.toLowerCase());
        }

        if (noteSearchState.authorId !== 0) {
          isMatch = isMatch && n.createdById === noteSearchState.authorId;
        }

        if (n.createdOn) {
          if (noteSearchState.fromDate && !noteSearchState.toDate) {
            isMatch = isMatch && moment(n.createdOn).isSameOrAfter(noteSearchState.fromDate, "day");
          }
          else if (!noteSearchState.fromDate && noteSearchState.toDate) {
            isMatch = isMatch && moment(n.createdOn).isSameOrBefore(noteSearchState.toDate, "day");
          }
          else if (noteSearchState.fromDate && noteSearchState.toDate) {
            isMatch = isMatch && (
              moment(n.createdOn).isSameOrAfter(noteSearchState.fromDate, "day")
              &&
              moment(n.createdOn).isSameOrBefore(noteSearchState.toDate, "day")
            );
          }
        }

        return isMatch;
      });
    }

    this.freezer.get().set({ filteredNotes });
  }

  @bind
  public fetchCustomerActivity(customerId: number, filter: CustomerActivityCriteria) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "customerActivityFetchResults",
      params: {
        customerId: customerId,
        body: filter
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getActivityByCustomer(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch customer activity.");
      }
    })
  }

  public fetchAuditLogs(customerId: number) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "auditLogFetchResults",
      params: {
        customerId: customerId
      },
      onExecute: (apiOptions, params, options) => {
        const factory = AuditLogApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getCustomerDetailAuditLogs(params);
      }
    })
  }

  public async fetchCustomerCommodityExclusions(customerId: number | undefined, forceUpdate: boolean = false) {
    const {
      commodityExclusionsFetchResults: commodityExclusionsResults
    } = this.freezer.get();

    if (!customerId || (commodityExclusionsResults.hasFetched && !forceUpdate)) {
      return;
    }
    if (!customerId) {
      return;
    }
    
    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "commodityExclusionsFetchResults",
      params: {
        customerId
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getCommodityExclusionForCustomer(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body ?? "Failed to fetch commodity exclusions.");
      }
    })
  }

  public async addCommodityExclusion(customerId: number | undefined, commodityId: number | undefined) {
    if (!customerId || !commodityId) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "commodityExclusionResults",
      params: {
        "customerId": customerId,
        "commodityId": commodityId
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.addCommodityExclusion(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body ?? "Failed to update commodity.");
      }
    });
  }

  public async deleteCommodityExclusion(customerId: number | undefined, commodityId: number | undefined) {
    if (!customerId || !commodityId) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "commodityExclusionResults",
      params: {
        "customerId": customerId,
        "commodityId": commodityId
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.deleteCommodityExclusion(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body ?? "Failed to update commodity.");
      }
    });
  }
}


export const CustomerDetailService = new CustomerDetailFreezerService();
export type ICustomerDetailServiceInjectedProps = ReturnType<CustomerDetailFreezerService["getPropsForInjection"]>;
