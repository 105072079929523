import {
  React
} from "$Imports/Imports";

import {
  Link
} from "$Imports/MaterialUIComponents";

import {
  NavigationService
} from "$State/NavigationFreezerService";

import {
  openCustomerQuotePreview
} from "$Utilities/windowUtil";

interface IQuoteLinkProps {
  quoteId?: number;
  quoteNumber?: string;
  isCustomerQuote?: boolean;
}

export class QuoteLink extends React.PureComponent<IQuoteLinkProps> {
  private _navigateTo(event: React.MouseEvent, url: string): void {
    if (this.props.isCustomerQuote && this.props.quoteId) {
      openCustomerQuotePreview(this.props.quoteId);
    }
    else {
      // normal quotes: router performs navigation
      NavigationService.navigateTo(url);
    }

    // prevent "href" from navigating + reloading app in current window
    event.preventDefault();
  }

  render() {
    const {
      quoteId,
      quoteNumber,
      isCustomerQuote
    } = this.props;

    const url = isCustomerQuote ? `customerQuote/${quoteId}` : `/salesportal/quote/${quoteId}/true`;

    return (
      <Link
        href={url}
        color="secondary"
        onClick={(event) => this._navigateTo(event, url)}
      >
        {quoteNumber}
      </Link>
    );
  }
}