// extracted by mini-css-extract-plugin
var _1 = "PrintQuoteView__borderLeft__qKWV5";
var _2 = "PrintQuoteView__contentContainer__r3zhi";
var _3 = "PrintQuoteView__controlsContainer__LVOhK";
var _4 = "PrintQuoteView__date__OdpqD";
var _5 = "PrintQuoteView__flexbox__KYGFg";
var _6 = "PrintQuoteView__freightTable__zEoE1";
var _7 = "PrintQuoteView__headerTitle__A_Rwz";
var _8 = "PrintQuoteView__icon__jurCv";
var _9 = "PrintQuoteView__logo__STx6M";
var _a = "PrintQuoteView__mainContainer__ggIeU";
var _b = "PrintQuoteView__mainHeader__rohyi";
var _c = "PrintQuoteView__masterContainer__E4cFE";
var _d = "PrintQuoteView__pageHeaderSpace__VlFKT";
var _e = "PrintQuoteView__pageNumber__Gg6RY";
var _f = "PrintQuoteView__pdfButton__Pjgmg";
var _10 = "PrintQuoteView__quoteTable__R7dFG";
var _11 = "PrintQuoteView__rateTable__qHVxQ";
var _12 = "PrintQuoteView__redText__Bm0t8";
var _13 = "PrintQuoteView__sectionContainer__DEpqP";
var _14 = "PrintQuoteView__sectionHeader__uPOsB";
var _15 = "PrintQuoteView__sectionInfo__Wkmqg";
var _16 = "PrintQuoteView__shipperConsigneeTable__zFPs3";
var _17 = "PrintQuoteView__signature__iXGRT";
var _18 = "PrintQuoteView__signatureTable__zHxRn";
var _19 = "PrintQuoteView__termsContainer__qN1EH";
var _1a = "PrintQuoteView__textAlignTop__eRXxT";
export { _1 as "borderLeft", _2 as "contentContainer", _3 as "controlsContainer", _4 as "date", _5 as "flexbox", _6 as "freightTable", _7 as "headerTitle", _8 as "icon", _9 as "logo", _a as "mainContainer", _b as "mainHeader", _c as "masterContainer", _d as "pageHeaderSpace", _e as "pageNumber", _f as "pdfButton", _10 as "quoteTable", _11 as "rateTable", _12 as "redText", _13 as "sectionContainer", _14 as "sectionHeader", _15 as "sectionInfo", _16 as "shipperConsigneeTable", _17 as "signature", _18 as "signatureTable", _19 as "termsContainer", _1a as "textAlignTop" }
