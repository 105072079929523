import {
  React,
  bind
} from "$Imports/Imports";

import {
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Edit,
  Undo
} from "$Imports/MaterialUIIcons";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  CommodityRate
} from "$Generated/api";

const styles: {
  actionButtons: string
} = require("$Shared/styles/Modal.scss");

interface IActionMenuState {
}

interface IActionMenuProps {
  commodityRate: CommodityRate;
  showUndo: boolean;
  onMenuItemClick?: (taskName: string, commodityRate: any) => void;
}

export class ActionMenu extends React.PureComponent<IActionMenuProps, IActionMenuState> {
  @bind
  private _onUndoTask(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick("undo", this.props.commodityRate);
    }
  }
  @bind
  private _onEditTask(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick("edit", this.props.commodityRate);
    }
  }
  
  render() {
    const {
      commodityRate,
      showUndo
    } = this.props;

    var now = new Date();
    now.setSeconds(0);

    return (
      <div className={styles.actionButtons}>
        <UserAccessControl roles={["commodity-rate:delete"]}>
          {showUndo ?
          <IconButton
            size="small"
            onClick={this._onUndoTask}
          >
            <Undo />
          </IconButton>
          : <div style={{width: "30px"}}></div>}
        </UserAccessControl>
        <UserAccessControl roles={["commodity-rate:create", "commodity-rate:edit"]}>
          {commodityRate.endDateTime == null &&
          <IconButton
            size="small"
            onClick={this._onEditTask}
          >
            <Edit />
          </IconButton>}
        </UserAccessControl>
      </div>
    );
  }
}
