import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  AddEditCommodity
} from "./AddEditCommodity";

import {
  DataTable,
  IDataTableColumn,
  UserAccessControl,
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  Card,
  CardActions,
  CardHeader,
  Button,
  FormControlLabel,
  Switch
} from "$Imports/MaterialUIComponents";

import {
  ICommodityServiceInjectedProps,
  CommodityService
} from "$State/CommodityFreezerService";

import {
  Commodity
} from "$Generated/api";

import {
  ActionMenu
} from "./ActionMenu";

const styles: {
  mainContainer: string;
  actionArea: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface ICommodityLookupViewBaseProp {
  companyId: number | undefined;
}

type ICommodityLookupViewProp = ICommodityServiceInjectedProps 
& ICommodityLookupViewBaseProp;

class _CommodityLookupView extends React.Component<ICommodityLookupViewProp> {

  componentDidMount() {
    this.props.commodityService.fetchCommodities(this.props.companyId);
  }

  componentDidUpdate(prevProps: ICommodityLookupViewBaseProp) {
    if (this.props.companyId !== prevProps.companyId) {
      this.props.commodityService.fetchCommodities(this.props.companyId);
    }
  }

  @bind
  private _onAddClick() {
    this.props.commodityService.addCommodity();
  }

  @bind
  private _onCancel() {
    this.props.commodityService.clearEditAddForm();
  }

  @bind
  private _onTaskChanged(commodity: Partial<Commodity>) {
    this.props.commodityService.updateCommodity(commodity);
  }

  @bind
  private _onSave() {
    this.props.commodityService.saveCommodity(this.props.companyId);
  }

  @bind
  private _onMenuItemSelected(commodity: Commodity) {
    this.props.commodityService.editCommodity(commodity?.id);
  }

  @bind
  private _onActiveFilterChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.commodityService.setFilterActive(checked);
  }

  private readonly columns: Array<IDataTableColumn<Commodity>> = [
    {
      columnName: "commodity-name",
      columnFieldData: "commodityName",
      headerValue: "Name",
      sortMethod: (d) => d.commodityName?.toLowerCase() ?? "",
    },
    {
      columnName: "tm-commodity-id",
      columnFieldData: "tmCommodityId",
      headerValue: "TM Commodity Id",
      sortMethod: (d) => d.tmCommodityId?.toLowerCase() ?? "",
    },
    {
      columnName: "active",
      columnFieldData: (d) => d.isActive ? "True" : "False",
      headerValue: "Active",
      sortMethod: (d) => d.isActive ?? "",
    },
    {
      columnName: "stackable",
      columnFieldData: (d) => d.isStackable ? "True" : "False",
      headerValue: "Stackable",
      sortMethod: (d) => d.isStackable ?? "",
    },
    {
      columnName: "sideBySide",
      columnFieldData: (d) => d.isSideBySide ? "True" : "False",
      headerValue: "Side by Side",
      sortMethod: (d) => d.isSideBySide ?? ""
    },
    {
      columnName: "action",
      columnFieldData: (d) => (
        <ActionMenu
          commodity={d}
          onMenuItemClick={this._onMenuItemSelected}
        />
      ),
      headerValue: "",
    }
  ];

  render() {
    
    const {
      editAddCommodity,
      editAddValidationErrors,
      formMode,
      commodityFetchResults,
      commodityAddResults,
      sortState,
      filterActive
    } = this.props.commodityService.getState();;


    const commodityData = commodityFetchResults.data ?? [];
    const filteredData = filterActive ? _.filter(commodityData, x => x.isActive) as Commodity[] : commodityData;
    const isFetching: boolean = (formMode === "add" || formMode === "edit") ? commodityAddResults.isFetching : false;

    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader
            title="Commodity Table"
          />

          <CardActions
            disableSpacing={true}
            className={styles.actionArea}
          >
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={filterActive}
                    onChange={this._onActiveFilterChange}
                  />
                )
              }
              label="Active"
            />
            <UserAccessControl roles={["commodity-lookup:create"]}>
              <Button
                color="primary"
                onClick={this._onAddClick}
                disabled={isFetching}
              >
                Add
              </Button>
            </UserAccessControl>
          </CardActions>
          <AjaxActionIndicator
            state={commodityFetchResults}
          />
          <DataTable
            columns={this.columns}
            data={filteredData}
            defaultSortColumnName={sortState.sortColumnName}
            defaultSortDirection={sortState.sortDirection}
          />
        </Card>
        <AddEditCommodity
          formMode={formMode}
          commodity={editAddCommodity}
          validationErrors={editAddValidationErrors}
          onCancel={this._onCancel}
          onSave={this._onSave}
          isFetching={isFetching}
          onTaskChange={this._onTaskChanged}
        />
      </div>
    );
  }
}

export const CommodityLookupView = CommodityService.inject(
    _CommodityLookupView
);
