import {
  React,
  bind
} from "$Imports/Imports";

import {
  Edit
} from "$Imports/MaterialUIIcons";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  LoadingInstruction
} from "$Generated/api";


interface IActionMenuState {
}

interface IActionMenuProps {
  loadingInstruction: LoadingInstruction;
  onEdit: (loadingInstruction: LoadingInstruction) => void;
}

export class ActionMenu extends React.PureComponent<IActionMenuProps, IActionMenuState> {

  @bind
  private _onEdit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    this.props.onEdit(this.props.loadingInstruction);
  }

  render() {
    return (
      <>
        <IconButton
          size="small"
          onClick={this._onEdit}
        >
          <Edit />
        </IconButton>
      </>
    );
  }
}
