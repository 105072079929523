import {
  React,
  bind,
  withAuth,
  AuthContextProps,
  _
} from "$Imports/Imports";

import {
  AppBar,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Menu,
  MenuItem,
  Avatar
} from "$Imports/MaterialUIComponents";

import {
  ThemeProvider,
  createTheme
} from "$Imports/MaterialUIStyles";

import {
  Download,
  Apps,
  Equalizer
} from "$Imports/MaterialUIIcons";

import {
  ThemeConsumer,
  SelectedCompanyConsumer
} from "$Providers/index";

import {
  INavigationServiceInjectedProps,
  NavigationService,
} from "$State/NavigationFreezerService";

import {
  IAboutServiceInjectedProps,
  AboutService
} from "$State/AboutFreezerService";

import {
  ICompanySelectServiceInjectedProps,
  CompanySelectService
} from "$State/CompanySelectFreezerService";

import {
  CacheService
} from "$State/CacheFreezerService";

import {
  mainNavigation
} from "$Utilities/navigation";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  SelectedCompanyDisplay
} from "./ApplicationBar/SelectedCompanyDisplay";

import {
  AboutModal,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  ApplicationSettingsService
} from "$State/ApplicationSettingsFreezerService";

import {
  IQuoteEntryServiceInjectedProps,
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

import {
  openTabForUrl
} from "$Utilities/windowUtil";

interface IApplicationBarBaseProps {
}

interface IApplicationBarState {
  anchorEl: Element | null;
  menuOpen: boolean;
  appsAnchorEl: Element | null;
  appsMenuOpen: boolean;
}
type IApplicationBarProps = IApplicationBarBaseProps
  & INavigationServiceInjectedProps
  & IAboutServiceInjectedProps
  & ICompanySelectServiceInjectedProps
  & IQuoteEntryServiceInjectedProps
  & AuthContextProps
  ;

const styles: {
  applicationBarTab: string;
  applicationBarTabTabs: string;
  kaiserSalesPortal: string;
  companyContainer: string;
  usernameDisplay: string;
  appsIcon: string;
  appsMenu: string;
  appsMenuItem: string;
} = require("./MainLayout.scss");

import * as salesPortalIcon from "../../images/favicon.ico";

class _ApplicationBar extends React.Component<IApplicationBarProps, IApplicationBarState> {

  state: IApplicationBarState = {
    anchorEl: null,
    menuOpen: false,
    appsAnchorEl: null,
    appsMenuOpen: false
  };

  async componentDidMount() {
    this.props.aboutService.fetchData();
    await this.props.companySelectService.fetchData();

    await ApplicationSettingsService.fetchApplicationSettings(true);
  }

  @bind
  private _onChange(event: React.ChangeEvent<{}>, value: any) {
    this.props.navigationService.navigateTo(value);
  }

  @bind
  private async logout() {
    await SharedSecurityContext.logout()
  }

  @bind
  private openAbout() {
    this.closeMenu();
    this.props.aboutService.openModal();
  }

  @bind
  private closeAbout() {
    this.props.aboutService.closeModal();
  }

  @bind
  private onCompanySelect() {
    const companyKey = this.props.companySelectService.getState().companyContext.companyKey;
    const navDetails = this.props.navigationService.getParentPath(window.location.pathname);
    if (!!navDetails?.companiesToAccess && !navDetails?.companiesToAccess?.includes(companyKey)) {
      this.props.navigationService.navigateTo("/home");
    }
  }

  @bind
  private clearCache() {
    this.closeMenu();
    CacheService.clearCache();
  }

  @bind
  private downloadDebug() {
    this.closeMenu();
    this.props.QuoteEntryService.downloadFreezerState();
  }

  @bind
  private openMenu(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({
      anchorEl: event.currentTarget,
      menuOpen: true
    });
  }

  @bind
  private closeMenu() {
    this.setState({
      menuOpen: false
    });
  }

  @bind
  private openAppsMenu(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({
      appsAnchorEl: event.currentTarget,
      appsMenuOpen: true
    });
  }

  @bind
  private closeAppsMenu() {
    this.setState({
      appsMenuOpen: false
    });
  }

  @bind
  private navigateSalesPortal() {
    const aboutData = this.props.aboutService.getState().aboutFetchResults?.data;
    openTabForUrl(aboutData?.salesPortalUrl ?? "/home");
    this.setState({
      appsMenuOpen: false
    });
  }

  @bind
  private navigateReports() {
    const aboutData = this.props.aboutService.getState().aboutFetchResults?.data;
    openTabForUrl(aboutData?.reportingUrl ?? "");
    this.setState({
      appsMenuOpen: false
    });
  }

  render() {
    let navDetails = this.props.navigationService.getParentPath(window.location.pathname);
    let mainNav = _.filter(mainNavigation, (n) => !n.externalLink && n.isRoot);
    this.props.navigationService.updateTitle();

    const {
      modalOpen: aboutModalOpen,
      aboutFetchResults
    } = this.props.aboutService.getState();

    const {
      assignedCompanies
    } = this.props.companySelectService.getState();

    const {
      quote: { companyId: quoteCompanyId }
    } = this.props.QuoteEntryService.getState();

    return (
      <ThemeConsumer>{(context) => {
        return (
          <SelectedCompanyConsumer>
            {(companyContext) => {
              const debugDownloadEnabled = ApplicationSettingsService.getDebugDownloadEnabledSetting(companyContext.companyId);
              const appBarTheme = createTheme(context.themeConfig.themeOptions, context.themeConfig.appBarNavigationMenu);
              return (
                <ThemeProvider
                  theme={appBarTheme}
                >
                  <AppBar
                    position="relative"
                  >
                    <Toolbar>
                      <div className={styles.companyContainer}>
                        <span className={styles.kaiserSalesPortal}>Kaiser Sales Portal</span>
                        <SelectedCompanyDisplay
                          quoteCompanyId={quoteCompanyId}
                          companies={assignedCompanies}
                          companyContext={companyContext}
                          onCompanySelect={this.onCompanySelect}
                        />
                      </div>
                      <Tabs
                        value={navDetails === null ? '/customers' : navDetails.rootUrl}
                        onChange={this._onChange}
                        className={styles.applicationBarTab}
                      >
                        {_.map(_.filter(mainNav,
                          mN => _.intersection(mN.rolesToAccess, (SharedSecurityContext.getRoles(this.props.user ?? null))).length !== 0), (n, nIdx) => (
                            <Tab
                              className={styles.applicationBarTabTabs}
                              key={nIdx}
                              value={n.url}
                              label={n.rootUrlLabel}
                            />
                        ))}
                      </Tabs>
                      <div>
                        <IconButton
                          aria-label="quick links to Kaiser web applications"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={this.openAppsMenu}
                          color="inherit"
                        >
                          <Apps className={styles.appsIcon} />
                        </IconButton>
                        <Menu
                          anchorEl={this.state.appsAnchorEl}
                          open={this.state.appsMenuOpen}
                          onClose={this.closeAppsMenu}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <div className={styles.appsMenu}>
                            <UserAccessControl roles={["salesportal:page", "salesrep-home:page"]}>
                              <MenuItem className={styles.appsMenuItem} onClick={this.navigateSalesPortal}>
                                <IconButton><img alt="" src={salesPortalIcon} className={styles.appsIcon} /></IconButton>
                                <span>Sales Portal</span>
                              </MenuItem>
                            </UserAccessControl>
                            <UserAccessControl roles={["report-booked-sales:view", "report-lane-revenue:view", "report-sales-opportunities:view", "report-sales-history:view"]}>
                              <MenuItem className={styles.appsMenuItem} onClick={this.navigateReports}>
                                <IconButton><Equalizer className={styles.appsIcon} /></IconButton>
                                <span>Reports</span>
                              </MenuItem>
                            </UserAccessControl>
                          </div>
                        </Menu>
                        <IconButton
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={this.openMenu}
                          color="inherit"
                        >
                          <Avatar

                          >
                            {`${this.props.user?.profile?.given_name?.charAt(0)}${this.props.user?.profile?.family_name?.charAt(0)}`}
                          </Avatar>
                          <span className={styles.usernameDisplay}>{this.props.user?.profile?.name}</span>
                        </IconButton>
                        <Menu
                          anchorEl={this.state.anchorEl}
                          open={this.state.menuOpen}
                          onClose={this.closeMenu}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <MenuItem onClick={this.closeMenu}>Profile</MenuItem>
                          <UserAccessControl roles={["yahara:dev"]}>
                            <MenuItem onClick={this.clearCache}>Clear Cache</MenuItem>
                          </UserAccessControl>
                          {debugDownloadEnabled && <MenuItem onClick={this.downloadDebug}><Download /> Debug</MenuItem>}
                          <MenuItem onClick={this.openAbout}>About</MenuItem>
                          <MenuItem onClick={this.logout}>Logout</MenuItem>
                        </Menu>
                      </div>
                    </Toolbar>
                    <AboutModal
                      isOpen={aboutModalOpen}
                      aboutVM={aboutFetchResults.data}
                      onClose={this.closeAbout}
                    />
                  </AppBar>
                </ThemeProvider>
              );
            }}
          </SelectedCompanyConsumer>
          )
        }}
      </ThemeConsumer>
    );
  }
}

export const ApplicationBar = withAuth(
  NavigationService.inject(
    AboutService.inject(
      CompanySelectService.inject(
        QuoteEntryService.inject(
          _ApplicationBar
        )
      )
    )
  )
);
