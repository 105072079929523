import {
  React,
  bind
} from "$Imports/Imports";

import {
  CustomerContact,
  Reminder
} from "$Generated/api";

import {
  Button,
  Checkbox,
  DataGridPro,
  FormControlLabel,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  Link,
  Tooltip
} from "$Imports/MaterialUIComponents";

import {
  ISortState,
  directionType,
  UserAccessControl,
  CardLinedHeader,
  CreateReminderButton,
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  EMPTY_REMINDER
} from "$State/ReminderFreezerService";

import {
  ActionMenu
} from "./ActionMenu";

import {
  VisibilityOff
} from "$Imports/MaterialUIIcons";

const styles: {
  contactTable: string;
  tableRow: string;
  contactCell: string;
  fieldValue: string;
  contactName: string;
  contactNotes: string;
  inactive: string;
} = require("./CustomerContacts.scss");

interface IOwnState {
  showInactive: boolean;
}

interface IContactsCardProps {
  contacts: CustomerContact[];
  addEditContact: (contact?: CustomerContact) => void;
  setPrimaryContact: (contact: CustomerContact) => void;
  onShowInactive: (showInactive: boolean) => void;
  sortState: ISortState;
  onSortChange: (columnName: string | undefined, direction: directionType) => void;
  onCreateReminder: (seed: Reminder) => void;
  isFetching: boolean;
}

export class ContactsCard extends React.Component<IContactsCardProps> {
  state: IOwnState = {
    showInactive: false
  };

  private readonly columns: GridColDef[] = [
    {
      headerName: "Contact Information",
      field: "information",
      flex: 2,
      cellClassName: styles.contactCell,
      renderCell: (params: GridRenderCellParams<any, CustomerContact>) => (
        <>
          <div className={styles.contactName}>{params.row.firstName} {params.row.lastName}{params.row.nameSuffix ? `, ${params.row.nameSuffix.suffixValue}` : ""}</div>
          { params.row.emailAddress &&
            <Link
              color="secondary"
              href={`mailto:${params.row.emailAddress}`}
            >
              {params.row.emailAddress}
            </Link>
          }
          <div className={styles.fieldValue}>{params.row.phoneNumber}</div>
        </>
      ),
      sortable: false
    },
    {
      headerName: "Type",
      field: "type",
      flex: 2,
      cellClassName: styles.contactCell,
      renderCell: (params: GridRenderCellParams<any, CustomerContact>) => (
        <>
          <div className={styles.fieldValue}>{params.row.contactType ? params.row.contactType.type : ""}</div>
          <div className={styles.fieldValue}>{params.row.title}</div>
          {params.row.isHidden &&
            <VisibilityOff fontSize="small" titleAccess="Hidden in Customer Portal" style={{ verticalAlign: "middle" }} />
          }
        </>
      ),
      sortable: false
    },
    {
      headerName: "Notes",
      field: "notes",
      flex: 2,
      cellClassName: styles.contactCell,
      renderCell: (params: GridRenderCellParams<any, CustomerContact>) =>
        params.row.notes && (
          <Tooltip
            className={styles.contactNotes}
            title={params.row.notes}
            placement="left"
          >
            <span>{ params.row.notes }</span>
          </Tooltip>
        ),
      sortable: false
    },
    {
      headerName: "",
      field: "actions",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, CustomerContact>) => (
        <ActionMenu
          contact={params.row}
          editContact={this._editContact}
          setPrimaryContact={this._setPrimaryContact}
        />
      ),
      sortable: false
    }
  ];

  @bind
  private _addContact() {
    this.props.addEditContact();
  }

  @bind
  private _editContact(contact: CustomerContact) {
    this.props.addEditContact(contact);
  }

  @bind
  private _setPrimaryContact(contact: CustomerContact) {
    this.props.setPrimaryContact(contact);
  }

  @bind
  private _onShowInactiveChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.setState({
      showInactive: checked
    });

    this.props.onShowInactive(checked);
  }

  render() {
    const {
      showInactive
    } = this.state;

    const {
      contacts,
      onCreateReminder,
      isFetching
    } = this.props;
    // future story: filtering active/inactive

    return (
      <CardLinedHeader
        titleText="Contacts"
        titleComponents={(
          <div style={{ marginBottom: "5px" }}>
            <FormControlLabel
              label="Show inactive"
              control={(
                <Checkbox
                  checked={showInactive}
                  onChange={this._onShowInactiveChange}
                  name="isPrimary"
                />
              )}
            />
            <UserAccessControl roles={["quote:create", "quote:edit"]}>
              <Button onClick={this._addContact}>
                Add
              </Button>
            </UserAccessControl>
            <UserAccessControl roles={["reminder:create"]}>
              <CreateReminderButton
                seed={{
                  ...EMPTY_REMINDER,
                  type: "Contact"
                }}
                onClick={onCreateReminder}
              />
            </UserAccessControl>
          </div>
        )}
      >
        <AjaxActionIndicator showProgress={isFetching} />
        <DataGridPro
          className={styles.contactTable}
          rows={contacts ?? []}
          columns={this.columns}
          getRowClassName={(params: GridRowParams<CustomerContact>) => `${styles.tableRow} ${!params.row.isActive ? styles.inactive : ""}`}
          density="compact"
          getRowHeight={() => 64}
          initialState={{
            columns: {
              columnVisibilityModel: { isPrimary: false }
            },
            sorting: {
              sortModel: [{ field: "isPrimary", sort: "desc" }]
            }
          }}
          hideFooter
          disableColumnMenu
          disableColumnFilter
          disableSelectionOnClick
        />
      </CardLinedHeader>
    )
  }
}