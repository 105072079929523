import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  CustomerContact,
  NameSuffix,
  ContactType
} from "$Generated/api";

import {
  PhoneNumberInputField,
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  InputLabel,
  Switch,
  Checkbox,
  FormHelperText
} from "$Imports/MaterialUIComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

const styles: {
  flexColumn: string;
  flexRow: string;
  phoneFlexRow: string;
  notesFlexRow: string;
} = require("./CustomerContactModal.scss");

interface ICustomerContactModalProps {
  isOpen: boolean;
  contact: CustomerContact | undefined;
  validationErrors: ValidationError | null;
  onSave: () => void;
  onCancel: () => void;
  onChange: (value: Partial<CustomerContact>) => void;
  suffixes: NameSuffix[];
  contactTypes: ContactType[];
  isEmailRequired: boolean;
}

export class CustomerContactModal extends React.PureComponent<ICustomerContactModalProps> {

  @bind
  private _onSave() {
    this.props.onSave();
  }

  @bind
  private _onCancel() {
    this.props.onCancel();
  }

  @bind
  private _onChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.onChange({
      [e.target.name]: e.target.value
    });
  }

  @bind
  private _onIsPrimaryChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.onChange({ isPrimary: checked });
  }

  @bind
  private _onIsActiveChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.onChange({ isActive: checked });
  }

  @bind
  private _onPhoneNumberChange(phoneNumber: string | undefined) {
    this.props.onChange({ phoneNumber });
  }

  @bind
  private _onCellNumberChange(cellNumber: string | undefined) {
    if (cellNumber === "") {
      this.props.onChange({ cellNumber: undefined });
    } else {
      this.props.onChange({ cellNumber: cellNumber });
    }
  }

  @bind
  private _onIsHiddenChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.onChange({ isHidden: checked });
  }

  render() {
    const {
      isOpen,
      contact,
      validationErrors,
      suffixes,
      contactTypes,
      isEmailRequired
    } = this.props;

    const validationsParser = new ValidationErrorParser<CustomerContact>(validationErrors);

    return (
      <Dialog
        open={isOpen}
        maxWidth="md"
      >
        <DialogTitle>
          {contact?.id ? "Edit Contact" : "Add Contact"}
        </DialogTitle>
        <DialogContent className={styles.flexColumn}>
          <FormControlLabel
            control={
              (
                <Switch
                  color="primary"
                  checked={!!contact?.isActive}
                  onChange={this._onIsActiveChange}
                />
              )
            }
            label="Active"
          />
          <div className={styles.flexRow}>
            <AdvanceTextField
              label="First name"
              name="firstName"
              value={contact?.firstName ?? ""}
              onChange={this._onChange}
              required
              error={!validationsParser.isValid("firstName")}
              helperText={validationsParser.validationMessage("firstName")}
            />
            <AdvanceTextField
              label="Last name"
              name="lastName"
              value={contact?.lastName ?? ""}
              onChange={this._onChange}
              required
              error={!validationsParser.isValid("lastName")}
              helperText={validationsParser.validationMessage("lastName")}
            />
            <FormControl style={{ width: "80px" }}>
              <InputLabel>Suffix</InputLabel>
              <Select
                name={"nameSuffixId"}
                value={contact?.nameSuffixId ?? ""}
                onChange={(event) => this._onChange(event as React.ChangeEvent<HTMLInputElement>)}
              >
                <MenuItem value={""}>&nbsp;</MenuItem>
                {suffixes.map((s, idx) => (
                  <MenuItem value={s.id} key={idx}>{s.suffixValue}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={styles.flexRow}>
            <AdvanceTextField
              label="Title"
              name="title"
              value={contact?.title ?? ""}
              onChange={this._onChange}
              required
              error={!validationsParser.isValid("title")}
              helperText={validationsParser.validationMessage("title")}
            />
            <FormControl style={{ width: "170px" }} error={!validationsParser.isValid("contactTypeId")}>
              <InputLabel required>Contact Type</InputLabel>
              <Select
                name={"contactTypeId"}
                value={contact?.contactTypeId ?? ""}
                onChange={(event) => this._onChange(event as React.ChangeEvent<HTMLInputElement>)}
              >
                {contactTypes.map((c, idx) => (
                  <MenuItem value={c.id} key={idx}>{c.type}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{validationsParser.validationMessage("contactTypeId")}</FormHelperText>
            </FormControl>
            <FormControlLabel
              label="Primary contact"
              control={(
                <Checkbox
                  checked={!!contact?.isPrimary}
                  onChange={this._onIsPrimaryChange}
                  name="isPrimary"
                />
              )}
            />
          </div>
          <div className={styles.flexRow}>
            <AdvanceTextField
              label="Email"
              name="emailAddress"
              value={contact?.emailAddress ?? ""}
              onChange={this._onChange}
              required={isEmailRequired}
              error={!validationsParser.isValid("emailAddress")}
              helperText={validationsParser.validationMessage("emailAddress")}
            />
            <PhoneNumberInputField
              label="Phone Number"
              name="phoneNumber"
              required
              onPhoneNumberChange={this._onPhoneNumberChange}
              captureExt
              className={styles.phoneFlexRow}
              value={contact?.phoneNumber ?? ""}
              error={!validationsParser.isValid("phoneNumber")}
              helperText={validationsParser.validationMessage("phoneNumber")}
            />
          </div>
          <div className={styles.flexRow}>
            <PhoneNumberInputField
              label="Cell Number"
              name="cellNumber"
              onPhoneNumberChange={this._onCellNumberChange}
              value={contact?.cellNumber ?? ""}
              error={!validationsParser.isValid("cellNumber")}
              helperText={validationsParser.validationMessage("cellNumber")}
            />
            <AdvanceTextField
              label="Notes"
              name="notes"
              value={contact?.notes ?? ""}
              onChange={this._onChange}
              multiline
              error={!validationsParser.isValid("notes")}
              helperText={validationsParser.validationMessage("notes")}
              className={styles.notesFlexRow}
            />
          </div>
          <div className={styles.flexRow}>
            <FormControlLabel
                label="Hide contact in Customer Portal"
                control={(
                  <Checkbox
                    checked={!!contact?.isHidden}
                    onChange={this._onIsHiddenChange}
                    name="isHidden"
                    disabled={!!contact?.customerUserId}
                  />
                )}
              />
            {contact?.id && 
              <FormControl style={{width:"12.5rem"}}>
                <InputLabel shrink>Customer Portal Sales Rep</InputLabel>
                <Select
                  displayEmpty
                  disabled={true}
                >
                  <MenuItem>{contact.customerUser ? `${contact.customerUser?.firstName} ${contact.customerUser.lastName}` : "Not Assigned"}</MenuItem>
                </Select>
              </FormControl>
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._onCancel}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this._onSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
