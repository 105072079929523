import {
  React,
  bind
} from "$Imports/Imports";

import {
  Launch
} from "$Imports/MaterialUIIcons";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  Customer
} from "$Generated/api";

interface ICustomerDetailsIconState { }

interface ICustomerDetailsIconStateProps {
  customer: Customer;
  onClick?: (customer: Customer) => void;
}

export class CustomerDetailsIcon extends React.PureComponent<ICustomerDetailsIconStateProps, ICustomerDetailsIconState> {

  @bind
  private _onClick() {
    if (this.props.onClick) {
      this.props.onClick(this.props.customer);
    }
  }

  render() {
    return (
      <IconButton
        size="small"
        onClick={this._onClick}
      >
        <Launch />
      </IconButton>
    )
  }
}