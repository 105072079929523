import {
  React,
  bind
} from "$Imports/Imports";

import {
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  IconButton,
  Popper
} from "$Imports/MaterialUIComponents";

import {
  Info
} from "$Imports/MaterialUIIcons";

import {
  RateModelTest,
  RateModelTestCalculationInfo
} from "$Generated/api";

const styles: {
  calculationInfo: string
} = require("./RateTesterView.scss");

interface IRateTestTooltipState {
  open: boolean;
  anchorEl: HTMLElement | null;
}

interface IRateTestTooltipProps {
  defaultCalculationInfo?: RateModelTestCalculationInfo;
  activeCalculationInfo?: RateModelTestCalculationInfo;
  futureCalculationInfo?: RateModelTestCalculationInfo;
  sandboxCalculationInfo?: RateModelTestCalculationInfo;
  includeFuture: boolean;
}

export class RateTestTooltip extends React.PureComponent<IRateTestTooltipProps, IRateTestTooltipState> {
  state = {
    open: false,
    anchorEl: null
  }

  @bind
  buildTable() {
    const {
      defaultCalculationInfo,
      activeCalculationInfo,
      futureCalculationInfo,
      sandboxCalculationInfo,
      includeFuture
    } = this.props;

    const future = includeFuture && futureCalculationInfo != null;
    const sandbox = sandboxCalculationInfo != null;

    return (
      defaultCalculationInfo != undefined && activeCalculationInfo != undefined ?
      <table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Default</th>
            <th>Active</th>
            {future && <th>Future</th>}
            {sandbox && <th>Sandbox</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>TSS %</td>
            <td><DisplayFormattedNumber value={defaultCalculationInfo?.tssPercent} formatString={'0.00%'} /></td>
            <td><DisplayFormattedNumber value={activeCalculationInfo?.tssPercent} formatString={'0.00%'} /></td>
            {future && <td><DisplayFormattedNumber value={futureCalculationInfo?.tssPercent} formatString={'0.00%'} /></td>}
            {sandbox && <td><DisplayFormattedNumber value={sandboxCalculationInfo?.tssPercent} formatString={'0.00%'} /></td>}
          </tr>
          <tr>
            <td>Rate per Mile</td>
            <td>{defaultCalculationInfo?.ratePerMile}</td>
            <td>{activeCalculationInfo?.ratePerMile}</td>
            {future && <td>{futureCalculationInfo?.ratePerMile}</td>}
            {sandbox && <td>{sandboxCalculationInfo?.ratePerMile}</td>}
          </tr>
          <tr>
            <td>Rate per Ft/K</td>
            <td>{defaultCalculationInfo?.pricePerFoot}</td>
            <td>{activeCalculationInfo?.pricePerFoot}</td>
            {future && <td>{futureCalculationInfo?.pricePerFoot}</td>}
            {sandbox && <td>{sandboxCalculationInfo?.pricePerFoot}</td>}
          </tr>
          <tr>
            <td>Commodity %</td>
            <td><DisplayFormattedNumber value={defaultCalculationInfo?.commodityRate} formatString={'0.00%'} /></td>
            <td><DisplayFormattedNumber value={activeCalculationInfo?.commodityRate} formatString={'0.00%'} /></td>
            {future && <td><DisplayFormattedNumber value={futureCalculationInfo?.commodityRate} formatString={'0.00%'} /></td>}
            {sandbox && <td><DisplayFormattedNumber value={sandboxCalculationInfo?.commodityRate} formatString={'0.00%'} /></td>}
          </tr>
          {activeCalculationInfo?.capacityRates?.map((cr, idx) => {
            return (
              <tr key={idx}>
                <td>Capacity %</td>
                <td><DisplayFormattedNumber value={cr} formatString={'0.00%'} /></td>
                <td><DisplayFormattedNumber value={cr} formatString={'0.00%'} /></td>
                {future && <td><DisplayFormattedNumber value={null} formatString={'0.00%'} /></td>}
                {sandbox && <td><DisplayFormattedNumber value={cr} formatString={'0.00%'} /></td>}
              </tr> )
          })}
          {futureCalculationInfo?.capacityRates?.map((fcr, idx) => {
            return (
              <tr key={idx}>
                <td>Future Capacity %</td>
                <td><DisplayFormattedNumber value={null} formatString={'0.00%'} /></td>
                <td><DisplayFormattedNumber value={null} formatString={'0.00%'} /></td>
                {future && <td><DisplayFormattedNumber value={fcr} formatString={'0.00%'} /></td>}
                {sandbox && <td><DisplayFormattedNumber value={null} formatString={'0.00%'} /></td>}
              </tr> )
          })}
        </tbody>
      </table>
      : <></>
    );
  }

  @bind
  private _mouseEnter(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({
      open: true,
      anchorEl: e.currentTarget
    });
  }

  @bind
  private _mouseLeave(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({
      open: false,
      anchorEl: null
    });
  }

  render() {
    const {
      open,
      anchorEl
    } = this.state;

    const table = this.buildTable();

    return (
      this.props.defaultCalculationInfo != undefined && this.props.activeCalculationInfo != undefined ?
      <>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement='left'
        >
          <div className={styles.calculationInfo}>
            {table}
          </div>
        </Popper>
        <IconButton size={"small"} onMouseEnter={this._mouseEnter} onMouseLeave={this._mouseLeave}>
          <Info />
        </IconButton>
      </>
      : null
    );
  }
}