import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  moment,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  ISortState
} from "$Imports/CommonComponents";

import {
  TarpApiFactory,
  Tarp,
  TarpValue
} from "$Generated/api";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

export type formModeType = "add" | "edit" | "none";

export interface ModalState {
  formMode: formModeType;
  openedAt: Date;
  immediateStart: boolean;
  editAddTarpValue: TarpValue | null;
  originalTarpValue: TarpValue | null;
  validationErrors: ValidationError | null;
}

interface ITarpRatesState {
  showHistory: boolean;
  tarpRatesFetchResults: IAjaxState<TarpValue[]>;
  tarpFetchResults: IAjaxState<Tarp[]>;
  tarpSaveResult:IAjaxState<Tarp>;
  tarpRateSaveResult: IAjaxState<TarpValue>;
  sortState: ISortState;
  modalState: ModalState;
}

const InjectedPropName = "tarpRatesService";

// if I had time I'd make a TarpRate view model object like the CommodityRate one
const TarpRateValidationSchema: SchemaOf<NullableOptional<TarpValue>> = yup.object({
    id: yup.number().notRequired(),
    tarpId: yup.number().notRequired(),
    companyId: yup.number().notRequired(),
    startDateTime: yup.date()
      .typeError("Invalid date.")
      .required("Start date is a required field.")
      .test("startDateTime", "${message}", (value: any, testContext: any) => {
        const openedAt = testContext.options.context.openedAt;
        const previous = testContext.options.context.previous;
        const formMode = testContext.options.context.formMode;

        if (moment(value).isBefore(openedAt)) {
          return testContext.createError({ message: "The start date and time cannot be in the past." });
        } else if (formMode == "edit" && moment(value).isSameOrBefore(previous)) {
          return testContext.createError({ message: "The start date and time must be after the start of the previous tarp rate." });
        }

        return true;
      }),
    endDateTime: yup.date().nullable().notRequired(),
    createdOn: yup.date().notRequired(),
    modifiedOn: yup.date().notRequired(),
    company: yup.object().notRequired(),
    tarp: yup.object({
      id: yup.number().notRequired(),
      tarpName: yup.string().required("Tarp Name is a required field.")
        .max(30, "The Tarp Name cannot be longer than 30 characters.")
        .test("tarp.tarpName", "${message}", (value: any, testContext: any) => {
          if (testContext.options.context.formMode == "edit") {
            return true;
          }

          // do not allow a tarp rate to be added with a non-unique name
          const tarpRates = testContext.options.context.tarpRates;

          const nameInUse = _.find(tarpRates, (t: TarpValue) => {
            return t.tarp?.tarpName && t.tarp?.tarpName.toLocaleLowerCase() == value.toLocaleLowerCase();
          });

          if (nameInUse != undefined) {
            return testContext.createError({ message: "The Tarp Name is already in use." });
          }

          return true;
        }),
      tarpDescription: yup.string().notRequired(),
      isActive: yup.boolean().notRequired(),
      createdOn: yup.date().notRequired(),
      modifiedOn: yup.date().notRequired(),
      tarpValues: yup.array().notRequired(),
      quoteStops: yup.array().notRequired()
    }),
    rate: yup.number().when(['tarp', 'isActive'], {
      is: true,
      then: yup.number().required("Rate is a required field.").moreThan(0, "The Rate must be greater than $0.00."), // > 0
      otherwise: yup.number().notRequired()
    })
});

const initialState = {
  showHistory: false,
  tarpRatesFetchResults: managedAjaxUtil.createInitialState(),
  tarpFetchResults: managedAjaxUtil.createInitialState(),
  tarpSaveResult: managedAjaxUtil.createInitialState(),
  tarpRateSaveResult: managedAjaxUtil.createInitialState(),
  sortState: {
    sortColumnName: "rateName",
    sortDirection: "asc",
  },
  modalState: {
    formMode: "none",
    immediateStart: false,
    editAddTarpValue: null,
    originalTarpValue: null,
    openedAt: new Date(),
    validationErrors: null
  }
} as ITarpRatesState;

class TarpRatesFreezerService extends FreezerService<ITarpRatesState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchTarpRates(companyId: number | undefined, forceUpdate: boolean = false) {
    const {
      tarpRatesFetchResults,
      showHistory
    } = this.freezer.get();

    if ((tarpRatesFetchResults.hasFetched && !forceUpdate) || !companyId) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "tarpRatesFetchResults",
      params: {
        showHistory,
        "companyId": companyId
      },
      onExecute: (apiOptions, param, options) => {
        const factory = TarpApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1TarpCompanyIdShowHistoryGet(param);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch tarp rates.");
      }
    });
  }

  public fetchTarps(companyId: number | undefined, forceUpdate: boolean = false) {
    const {
      tarpFetchResults
    } = this.freezer.get();

    if ((tarpFetchResults.hasFetched && !forceUpdate) || !companyId) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "tarpFetchResults",
      params: {
        "companyId": companyId
      },
      onExecute: (apiOptions, param, options) => {
        const factory = TarpApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1TarpGetAllActiveTarpsGet(param);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch tarps.");
      }
    });

  }

  public setShowHistory(companyId: number | undefined, showHistory: boolean) {
    this.freezer.get().set({ showHistory });
    this.fetchTarpRates(companyId, true);
  }

  public setSortState(sortState: Partial<ISortState>) {
    this.freezer.get().sortState.set(sortState);
  }

  public openAddTarpRate(companyId: number | undefined) {
    var now = new Date();
    now.setSeconds(0);

    // tarp rates are 1:1 item:value, consider adding a flattened view model?
    const blankTarpValue: TarpValue = {
      startDateTime: new Date(),
      endDateTime: undefined,
      tarp: {
        companyId: companyId
      }
    }
    
    this.freezer.get().set({
      modalState: {
        editAddTarpValue: _.cloneDeep(blankTarpValue),
        originalTarpValue: _.cloneDeep(blankTarpValue),
        validationErrors: null,
        formMode: "add",
        openedAt: now,
        immediateStart: false
      }
    });
  }

  public openEditTarpRate(tarpRate: TarpValue) {
    var now = new Date();
    now.setSeconds(0);

    // if the current record's startDateTime is in the future, the edited one must start then or later
    var minStart = _.cloneDeep(now);
    if (tarpRate.startDateTime && moment(tarpRate.startDateTime).isAfter(now)) {
      minStart = _.cloneDeep(tarpRate.startDateTime);
    }
    var editAddTarpValue: TarpValue = {
      rate: tarpRate.rate,
      startDateTime: minStart,
      tarpId: tarpRate.tarpId,
      tarp: _.cloneDeep(tarpRate.tarp)
    }

    // I think cloneDeep sets undefined to null and that causes problems for typescript
    // and yup trying to validate a null value on a string | undefined type
    if (editAddTarpValue.tarp && editAddTarpValue.tarp.tarpDescription == null) {
      editAddTarpValue.tarp.tarpDescription = "";
    }

    this.freezer.get().set({
      modalState: {
        editAddTarpValue: editAddTarpValue,
        originalTarpValue: tarpRate,
        validationErrors: null,
        formMode: "edit",
        openedAt: now,
        immediateStart: false
      }
    });
  }

  public onTarpValueChange(tarpValue: Partial<TarpValue>) {
    this.freezer.get().modalState.editAddTarpValue?.set(tarpValue);
  }

  public onTarpChange(tarp: Partial<Tarp>) {
    this.freezer.get().modalState.editAddTarpValue?.tarp?.set(tarp);
  }

  public onTarpIsActiveChange(tarp: Partial<Tarp>) {
    this.freezer.get().modalState.editAddTarpValue?.tarp?.set(tarp);
  }

  public onChangeImmediateStart(immediateStart: boolean) {
    this.freezer.get().modalState.set({ immediateStart });
  }

  public clearEditAddForm() {
    this.freezer.get().set({
      modalState: {
        formMode: "none",
        editAddTarpValue: null,
        originalTarpValue: null,
        openedAt: new Date(),
        validationErrors: null,
        immediateStart: false
      }
    });
  }

  public hasUnsavedChanges(): boolean {
    const {
      modalState
    } = this.freezer.get().toJS();

    return !(_.isEqual(modalState.editAddTarpValue, modalState.originalTarpValue));
  }

  private async isValidTarpRate(): Promise<ValidationError | null> {
    const {
      modalState,
      tarpRatesFetchResults
    } = this.freezer.get().toJS();

    if (modalState.immediateStart) {
      TarpRateValidationSchema.fields.startDateTime = yup.mixed().notRequired(); // don't validate it at all
    } else {
      TarpRateValidationSchema.fields.startDateTime = yup.date()
      .typeError("Invalid date.")
      .required("Start date is a required field.")
      .test("startDateTime", "${message}", (value: any, testContext: any) => {
        const openedAt = testContext.options.context.openedAt;
        const previous = testContext.options.context.previous;
        const formMode = testContext.options.context.formMode;

        if (moment(value).isBefore(openedAt)) {
          return testContext.createError({ message: "The start date and time cannot be in the past." });
        } else if (formMode == "edit" && moment(value).isSameOrBefore(previous)) {
          return testContext.createError({ message: "The start date and time must be after the start of the previous tarp rate." });
        }
    
        return true;
      });
    }

    const errors = await validateSchema(TarpRateValidationSchema, modalState.editAddTarpValue, {
      abortEarly: false,
      context: {
        openedAt: modalState.openedAt,
        previous: modalState.originalTarpValue?.startDateTime,
        formMode: modalState.formMode,
        tarpRates: tarpRatesFetchResults.data ?? []
      }
    });

    this.freezer.get().modalState.set({ validationErrors: errors });

    return errors;
  }

  public async saveTarpRate(companyId: number | undefined) {
    const formMode = this.freezer.get().modalState.formMode;
    var tarpValue = this.freezer.get().modalState.editAddTarpValue?.toJS();
    const immediateStart = this.freezer.get().modalState.immediateStart;

    const errors = await this.isValidTarpRate();
    if (errors) {
      return;
    }

    if (tarpValue && immediateStart) {
      tarpValue.startDateTime = new Date();
    }

    if (tarpValue && tarpValue?.tarp) {
      if (tarpValue.endDateTime == null || tarpValue.endDateTime > new Date()) {
        tarpValue.tarp.isActive = true;
      }
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "tarpRateSaveResult",
      onExecute: (apiOptions, param, options) => {
        const factory = TarpApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        if (formMode == "edit") {
          return factory.apiV1TarpPut(param); // update
        } else {
          return factory.apiV1TarpPost(param); // create
        }
      },
      params: {
        body: tarpValue,
      },
      onOk: (data) => {
        this.clearEditAddForm();
        this.fetchTarpRates(companyId, true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save tarp rate.");
      }
    });
  }

  public undoTarpRate(companyId: number | undefined, tarpRate: TarpValue) {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "tarpRateSaveResult",
      params: {
        body: tarpRate
      },
      onExecute: (apiOptions, params, options) => {
        const factory = TarpApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1TarpUndoPost(params);
      },
      onOk: () => {
        this.fetchTarpRates(companyId, true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to undo tarp rate.");
      }
    });
  }

  public async saveTarp(companyId: number | undefined) {
    const tarpValue = this.freezer.get().modalState.editAddTarpValue?.toJS();
    let tarp;
    if (tarpValue?.tarp) {
      tarp = tarpValue?.tarp;
      tarpValue.tarp = undefined;
      tarp?.tarpValues?.push(tarpValue);
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "tarpSaveResult",
      params: {
        body: tarp,
      },
      onExecute: (apiOptions, param, options) => {
        const factory = TarpApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1TarpUpdateTarpPut(param);
      },
      onOk: (data) => {
        this.clearEditAddForm();
        this.fetchTarpRates(companyId, true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save tarp rate.");
      }
    });
  }
}

export const TarpRatesService = new TarpRatesFreezerService();
export type ITarpRatesServiceInjectedProps = ReturnType<TarpRatesFreezerService["getPropsForInjection"]>;