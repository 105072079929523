import {
  React,
  bind
} from "$Imports/Imports";

import {
  ApplicationSetting
} from "$Generated/api";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  FormControlLabel,
  Checkbox
} from "$Imports/MaterialUIComponents";

interface IBooleanSettingEditModalProps {
  isModalOpen?: boolean;
  title: string;
  value: string | undefined; // it's "True" or "False"
  onChangeSetting: (applicationSetting: Partial<ApplicationSetting>) => void;
  onSaveSetting: () => void;
}

export class BooleanSettingEditModal extends React.PureComponent<IBooleanSettingEditModalProps> {

  @bind
  private _onChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.onChangeSetting({ settingsValue: checked ? "True" : "False "});
  }

  @bind
  private _onSave() {
    this.props.onSaveSetting();
  }

  render() {
    const {
      isModalOpen,
      title,
      value
    } = this.props;

    return (
      <Dialog open={isModalOpen ?? false}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          label={title}
          control={(
            <Checkbox
              checked={value === "True"}
              onChange={this._onChange}
              name="setting"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={this._onSave}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
    )
  }
}