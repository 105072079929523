import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  SelectedCompanyConsumer
} from "$Providers/index";

import {
  EquipmentRatesView
} from "./EquipmentRatesView";

interface IEquipmentRatesViewPageBaseProps {

}

export class EquipmentRatesViewPage extends React.Component<IEquipmentRatesViewPageBaseProps> {
  render() {
    return (
      <SelectedCompanyConsumer>
        {(companyContext) => { 
          return (
            <EquipmentRatesView companyId={companyContext.companyId}/>
          );
        }}
      </SelectedCompanyConsumer>
    );
  }
}