import {
  React,
  Helpers,
  bind,
  NumberFormat,
  NumberFormatValues
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  AjaxActionIndicator,
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  RateModelTest,
  Commodity
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  TextField,
  FormHelperText
} from "$Imports/MaterialUIComponents";

import {
  formModeType
} from "$State/RateModelTestFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import { getTrimmedZipPostalCode } from "$Shared/utilities/helpers";

const styles: {
  container: string;
} = require("./EditAddRateModelTest.scss");

interface IEditAddRateModelTestProps {
  formMode: formModeType;
  rateModelTest: RateModelTest | null;
  isFetching?: boolean;
  onCancel: () => void;
  onSave: () => void;
  onSaveAndAdd: () => void;
  onRateModelTestChanged: (rateModelTestChange: Partial<RateModelTest>) => void;
  commodityOptions: Commodity[];
  validationErrors?: ValidationError | null;
}

export class EditAddRateModelTest extends React.PureComponent<IEditAddRateModelTestProps> {

  static defaultProps: Partial<IEditAddRateModelTestProps> = {
    isFetching: false,
  };

  @bind
  private _onCancel() {
    this.props.onCancel();
  }

  @bind
  private _onOriginZipPostalCodeChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const value = getTrimmedZipPostalCode(e.target?.value);
    this.props.onRateModelTestChanged({ originZipPostalCode: value });
  }

  @bind
  private _onDestZipPostalCodeChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const value = getTrimmedZipPostalCode(e.target?.value);
    this.props.onRateModelTestChanged({ destZipPostalCode: value });
  }

  @bind
  private _onCommodityChanged(e: any) {
    this.props.onRateModelTestChanged({ commodityId: parseInt(e.target.value) });
  }

  @bind
  private _onModelLevelChanged(value?: NumberFormatValues) {
    this.props.onRateModelTestChanged({ modelLevelId: value?.floatValue });
  }

  @bind
  private _onSubmit() {
    this.props.onSave();
  }

  @bind
  private _onSaveAndAdd() {
    this.props.onSaveAndAdd();
  }

  render() {
    const {
      formMode,
      rateModelTest,
      isFetching,
      commodityOptions,
      validationErrors
    } = this.props;

    const validationsParser = new ValidationErrorParser<RateModelTest>(validationErrors);

    return !Helpers.isNullOrUndefined(rateModelTest) && !Helpers.isNullOrUndefined(commodityOptions) ? (
      <Dialog
        open={formMode === "add" || formMode === "edit"}
        fullWidth={true}
        maxWidth="xs"
      >
        <AjaxActionIndicator
          showProgress={isFetching}
        />
        <DialogTitle>
          {formMode === "add" ? "Add Test Case" : "Edit Test Case"}
        </DialogTitle>
        <DialogContent
          className={styles.container}
        >
          <div style={{ padding: "6px" }}>
            <Grid container direction={"column"} spacing={3}>
              <Grid item>
                <AdvanceTextField
                  label="Origin"
                  value={rateModelTest?.originZipPostalCode ?? ""}
                  onChange={this._onOriginZipPostalCodeChanged}
                  error={!validationsParser.isValid("originZipPostalCode")}
                  helperText={validationsParser.validationMessage("originZipPostalCode")}
                />
              </Grid>
              <Grid item>
                <AdvanceTextField
                  label="Destination"
                  value={rateModelTest?.destZipPostalCode ?? ""}
                  onChange={this._onDestZipPostalCodeChanged}
                  error={!validationsParser.isValid("destZipPostalCode")}
                  helperText={validationsParser.validationMessage("destZipPostalCode")}
                />
              </Grid>
              <Grid item>
                <FormControl fullWidth error={!validationsParser.isValid("commodityId")}>
                  <InputLabel>Commodity</InputLabel>
                  <Select
                    value={rateModelTest?.commodityId ?? ""}
                    onChange={this._onCommodityChanged}
                    style={{ width: "195px" }}
                  >
                    {commodityOptions.map((x, i) =>
                      <MenuItem value={x.id} key={i}>
                        {x.commodityName}
                      </MenuItem>)}
                  </Select>
                  <FormHelperText>{validationsParser.validationMessage("commodityId")}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item>
                <NumberFormat
                  allowNegative={false}
                  decimalScale={0}
                  label="Length/Weight"
                  value={rateModelTest?.modelLevelId ?? ""}
                  customInput={TextField}
                  onValueChange={this._onModelLevelChanged}
                  error={!validationsParser.isValid("modelLevelId")}
                  helperText={validationsParser.validationMessage("modelLevelId")}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._onCancel}
            disabled={isFetching}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={isFetching}
            onClick={this._onSubmit}
          >
            Save
            </Button>
          {formMode === "add" && <Button
            color="primary"
            disabled={isFetching}
            type="submit"
            onClick={this._onSaveAndAdd}
          >
            Save and Add
            </Button>}
        </DialogActions>
      </Dialog >
    ) : null;
  }
}
